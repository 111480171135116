import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../../widgets/Toolbar';
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from "../../../ViewUtils";
import AlertItem from "../../../widgets/AlertItem";
import { AddEditTrancheForm } from './AddEditTrancheForm';



const TrancheRef = React.createRef();
const utils = new ViewUtils();
const toolbarRef = React.createRef();

const roleSRU = "SRU Administrator";

class AddEditTranche extends Component {

    state = {
        /*Deciding on unique identifier for existing tranche details title*/
        TrancheDetails: {
            Description: "",
            Code: ""
        },
        messageStyle: "message",
        id: null,
        showTable: true,
        TrancheID: this.props.location.state.TrancheID,
        hideFields: this.props.location.state.hideFields,
        showProgramme: true,
        TrancheCodeID: this.props.location.state.TrancheCodeID,
        searchParameters: [{ Name: "TrancheCodeID", Value: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.TrancheCodeID)) ? this.props.location.state.TrancheCodeID : null }]
    };

    toolbarConfig = {
        items: [

            {
                type: "buttonGroup",
                buttons: [
                    { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                ]
            },
            {
                type: "devider"
            },
            {

                type: "buttonGroup",
                buttons: [
                    { id: 'save', label: 'Submit', tooltip: 'Submit', icon: 'SaveIcon' },
                    { id: 'tranchedetail', label: 'Tranche Details', tooltip: 'Edit Tranche Details', disabled: this.state.hideFields },

                ]
            }

        ]
    };

    handleFormValueChange = (values) => {
        let TrancheCodeID = null;
        let newsearchParameters = [...this.state.searchParameters];

        if (!utils.isNull(values.TrancheCodeID)) {
            TrancheCodeID = values.TrancheCodeID.Id;
            let temp_element = { ...newsearchParameters[0] };
            temp_element.Value = TrancheCodeID
            newsearchParameters[0] = temp_element;
        }

        if (utils.getCurrentRole() === roleSRU) {
            console.log("Form Value = " + TrancheCodeID);
            this.setSRUSaveButton(TrancheCodeID.toString()); 
        }
    }

    setSRUSaveButton(trancheCodeID) {
        console.log("Function Value = " + trancheCodeID);
        if (trancheCodeID === "60" || trancheCodeID === "61" || trancheCodeID === "62" || trancheCodeID === "66") {
            console.log("setfalse");
            toolbarRef.current.setButtonDisabled("save", false);
            }
        else {
            console.log("settrue");
            toolbarRef.current.setButtonDisabled("save", true);
        }
    }


    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("resubmit" === buttonId) {
            this.resubmit();
        } else if ("tranchedetail" === buttonId) {
            this.props.history.push({
                pathname: '/TrancheDetails',
                state: {
                    GrantID: this.state.TrancheID,
                    TrancheCodeID: this.state.TrancheCodeID
                }
            })
        }
    }

    successCallback(response)  {
        if (response.MessageType === "SUCCESS") {
            this.setState({
                showTable: false
            }, () => {
                this.setState({
                    showTable: true,
                    message: response.Message,
                    messageStyle: response.MessageType,
                    TrancheID: response.EntityId
                })
            });
            toolbarRef.current.setButtonDisabled("resubmit", false);
            toolbarRef.current.setButtonDisabled("tranchedetail", false);
        }
        else {
            this.setState({
                message: response.Message, messageStyle: response.MessageType
            });
        }
    }

    save() {
        let formValue = TrancheRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            let input = JSON.stringify(formValue);
            let trancheData = {};
            trancheData.data = input;

            utils.invokeUrl("api/sims/GrantSetup/grantSetupDetailsSubmit", (response) => {
                utils.showMessage(this, response.Message, response.MessageType);
                this.successCallback(response)
            },
                null,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    },
                    body: JSON.stringify(trancheData)
                }
            );
        }
    }

    closeView() {
        this.props.history.push({
            pathname: '/GrantSetupSearch'
        });
    }

    RefreshProgramme = (form, TrancheCodeID) => {
        if (TrancheCodeID !== this.state.TrancheCodeID) {
            form.values["ProgrammeID"] = null;
            this.setState({
                showProgramme: false,
                TrancheCodeID: TrancheCodeID
            }, () => {
                this.setState({
                    showProgramme: true
                })
            });
        }
    }

    componentDidMount() {
        if (utils.getCurrentRole() === roleSRU) {
            console.log("Did Mount = " + this.props.location.state.TrancheCodeID);
            this.setSRUSaveButton(this.props.location.state.TrancheCodeID)
        }
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
            <p className="breadcrumbs">
                {"Grant Setup Details"}
            </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    title=""
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    ref={toolbarRef}
                                    config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "80vh" }}>
                                    {this.state.showTable === true && (
                                        <AddEditTrancheForm ref={TrancheRef}
                                            viewRef={this}
                                            id={this.state.TrancheID}
                                            hideFields={this.state.hideFields}
                                            valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                            RefreshProgramme={this.RefreshProgramme}
                                            showProgramme={this.state.showProgramme }                                        />
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(AddEditTranche);

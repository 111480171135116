import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function Prompt(props) {
    const [open, setOpen] = React.useState(false);
    const [content, setContent] = React.useState(props.promptContent)
    const [title, setTitle] = React.useState(props.promptTitle)
    const [okPrompt, setokPrompt] = React.useState(props.okPrompt)

    const handleNo = () => {
        setOpen(false)
        props.closePrompt()
    }

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    React.useEffect(() => {
        setContent(props.promptContent);
    }, [props.promptContent]);

    React.useEffect(() => {
        setTitle(props.promptTitle);
    }, [props.promptTitle]);

    function NewlineText(msg) {
        const text = msg.text;
        return text.split('\n').map(str => <p>{str}</p>);
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleNo}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className="breadcrumbs" disableTypography id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <NewlineText text={content} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {okPrompt ? 
                        <div>
                            <Button onClick={props.handleOk} color="primary">
                                Ok
                    </Button></div>
                        :
                        <div>
                    <Button onClick={handleNo} color="primary">
                            No
                    </Button>
                    <Button onClick={props.handleYes} color="primary" autoFocus>
                                Yes
                    </Button></div>
                        }
                </DialogActions>
            </Dialog>
        </div>
    );
}

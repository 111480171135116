import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import ViewUtils from "./../../ViewUtils";
import { AudienceOptionsForm } from './AudienceOptionsForm';
import { ViewContainer } from "./../../View.jsx";
import AlertItem from "../../widgets/AlertItem";

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: "edit", label: '', tooltip: 'Edit', icon: 'EditIcon' },
                { id: "cancel", label: 'CANCEL', tooltip: 'Cancel Edit'},
            ]
        },
        {
            type: "devider"
        },
        {
            type: "buttonGroup",
            buttons: [
                { id: 'save', label: '', tooltip: 'Save', icon: 'SaveIcon', disabled: true },
            ]
        }
    ]
};

const formRef = React.createRef();
const utils = new ViewUtils();
const toolbarRef = React.createRef();

class AudienceOptions extends Component {
    constructor(props) {
        super(props)
    this.state = {
        id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
        canEdit: false
    };
}

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        }
        else if ("edit" === buttonId)  {
            this.edit();
        }
        else if ("cancel" === buttonId) {
            this.cancel();
        }
    }

    save() {
        let formValue = formRef.current.getValue();
       
        if (formValue !== null && typeof formValue !== "undefined") {
            let input = JSON.stringify(formValue);

            console.log(formValue)
            utils.invokeUrl("api/sims/audienceoptions/saveAudienceOptions", (response) => {
                this.successCallback(response);
            }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: input
            });
        }
    }

    successCallback(response) {
        this.setState({
            message: response.Message, messageStyle: response.MessageType
        });

    }

    errorCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }
    
    edit() {
        this.setState({ canEdit: true });
        toolbarRef.current.setButtonDisabled("save", false);
    }

    cancel() {
        this.setState({ canEdit: false });
        toolbarRef.current.setButtonDisabled("save", true);
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"Audience Options"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Audience Options" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} ref={toolbarRef}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={true} config={toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <AudienceOptionsForm ref={formRef} canEdit={this.state.canEdit}
                                    id={0} entityLookupController={'audienceoptions'}
                                    valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(AudienceOptions);

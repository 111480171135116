import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Skills.css";
import SearchView from "../../widgets/SearchView";
import CardLayout from '../../widgets/Card';
import SkillsRejectionReasonSearchForm from './SkillsRejectionReasonSearchForm';
import ViewUtils from '../../ViewUtils';
const utils = new ViewUtils();

const headCells = [
    { id: 'FinancialYear', numeric: false, disablePadding: true, label: 'Financial Year' },
    { id: 'LetterType', numeric: false, disablePadding: true, label: 'Letter Type' },
    { id: 'Name', numeric: false, disablePadding: true, label: 'Name' },
    { id: 'Description', numeric: false, disablePadding: true, label: 'Description' }
];
const filterParameters = []

class SkillsRejectionReasonSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                Name: "",
                Description: ""


            },
            parameters: {},
            keyUpSwitch: false,
            addDialogOpen: false,
            selectedRejectReason: null
        };
        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        {
                            id: 'add',
                            label: '',
                            tooltip: 'Add',
                            icon: 'AddIcon',
                            visible: true//this.props.menuPermissionItem.Add
                        },
                        {
                            id: 'edit',
                            label: '',
                            tooltip: 'Edit',
                            icon: 'EditIcon',
                            disabled: true,
                            visible: true//this.props.menuPermissionItem.Edit
                        },
                        // {
                        //     id: 'delete',
                        //     label: '',
                        //     tooltip: 'Delete reject reason from records',
                        //     icon: 'DeleteIcon',
                        //     disabled: true,
                        //     visible: true//this.props.menuPermissionItem.Delete
                        // }
                    ]
                }
            ]
        };

        this.searchParameters = [
            { Name: "Name", Value: "" },
            { Name: "Description", Value: "" }


        ]
    }


    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }
    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedRejectReason: selection })
        }
        else {
            this.setState({ selectedRejectReason: null })
        }
    }


    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.setState({ addDialogOpen: true });
        }
    }

    postLinkHandler(linked) {
        this.setState({ linkDialogOpen: false, selectedLinkType: null, selectedLinkStandards: null });
    }

    render() {
        return (
            <CardLayout title='Skills Maintenance > Rejection Reasons' >
                <SearchView headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/skillsrejectionreasons/getsearch"}
                    entityViewPath='/SkillsRejectionReason'
                    title='Rejection Reasons'
                    model={this.state.model}
                    toolbarConfig={this.toolbarConfig}
                    searchParameters={this.searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    hasButtonClickHandler={true}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    descriptionProp="Skills"
                    mode={this.props.mode}
                    history={this.props.history}
                    filterParameters={filterParameters}
                    keyUpSwitch={this.state.keyUpSwitch}
                    deleteUrl={"api/sims/skillsrejectionreasons/removerejectionreasons"}
                    useDeletePost={true}
                    promptTitle={"Remove Reject Reason"}
                    promptContent={"Are you sure you want to remove this Reject Reason?"}
                    setSelection={(selection) => { this.handleSelection(selection) }}

                >
                    <SkillsRejectionReasonSearchForm data={this.state.model} onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }} />

                </SearchView>
            </CardLayout >
        );
    }
}

export default withRouter(
    connect()(SkillsRejectionReasonSearch)
);

import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "../../View.css";
import "../../Grid.css";
import "../../Form.css";
import "../../App.css";
import SearchView from "../../widgets/SearchView";
import BursaryApplicationEvaluationSearchForm from './BursaryApplicationEvaluationSearchForm';
import { ViewContainer } from "../../View.jsx";
import ViewUtils from '../../ViewUtils';
import withMenuPermission from "../../widgets/withMenuPermission";


const utils = new ViewUtils();

const headCells = [
    { id: 'FirstName', numeric: false, disablePadding: true, label: 'First Name' },
    { id: 'Surname', numeric: false, disablePadding: true, label: 'Surname' },
    { id: 'IDNo', numeric: false, disablePadding: true, label: 'ID Number' },
    { id: 'InstitutionName', numeric: false, disablePadding: true, label: 'University / TVET' },
    { id: 'Qualification', numeric: false, disablePadding: true, label: 'Qualification' },
    { id: 'ApplicationYear', numeric: false, disablePadding: true, label: 'Application Year' },
    { id: 'BursaryApplicationStatus', numeric: false, disablePadding: true, label: 'Status' }
];

const searchParameters = [
    { Name: "AcademicApplicationYear", Value: "0" },
    { Name: "BursaryApplicationStatusID", Value: "0" },
    { Name: "BursaryApplicationTypeID", Value: "0" },
    { Name: "BursaryInstitutionID", Value: "0" },
    { Name: "BursaryQualificationID", Value: "0" },
    { Name: "IDNo", Value: "" }
]

const filterParameters = [
    { dataField: "IDNo", text: "IDNo" }
]

class BursaryApplicationEvaluationSearch extends Component {

    constructor(props) {
        super(props)

        this.searchParameters = this.searchParameters = !utils.isNull(this.props.programmeId) ?

            [
                { Name: "AcademicApplicationYearID", Value: "0" },
                { Name: "BursaryApplicationStatusID", Value: "0" },
                { Name: "BursaryApplicationTypeID", Value: "0" },
                { Name: "BursaryInstitutionID", Value: "0" },
                { Name: "BursaryQualificationID", Value: "0" },
                { Name: "IDNo", Value: "" }
            ]
            :

            [
                { Name: "AcademicApplicationYearID", Value: "0" },
                { Name: "BursaryApplicationStatusID", Value: "0" },
                { Name: "BursaryApplicationTypeID", Value: "0" },
                { Name: "BursaryInstitutionID", Value: "0" },
                { Name: "BursaryQualificationID", Value: "0" },
                { Name: "IDNo", Value: "" }
            ]

        if (!utils.isNull(this.props.programmeId)) {
            this.state = {
                model: {
                    IDNo: "",
                    ID: this.props.programmeId,
                },
                keyUpSwitch: false,
                selectedApplication: null
            };
        }
        else {
            this.state = {
                model: {
                    IDNo: ""
                },
                keyUpSwitch: false,
                selectedApplication: null
            };
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit }
                    ]
                }
            ]
        };
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedApplication: selection })
        } else {
            this.setState({ selectedApplication: null })
        }
    }

    handleToolbarButtonClick = (buttonId) => {

    }

    render() {
        return (
            <ViewContainer title="">
                <SearchView headCells={headCells} dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/BursaryApplicationEvaluation/GetBursaryApplications"}
                    entityViewPath='/bursaryApplicationEvaluation' model={this.state.model}
                    toolbarConfig={this.toolbarConfig} searchParameters={this.searchParameters}
                    filterParameters={filterParameters}
                    mode={this.props.mode}
                    title='Bursary Application Evaluation Dashboard'
                    multiSelect={this.props.multiSelect}
                    onLookupValueChange={this.props.onLookupValueChange} descriptionProp="FirstName" SecondDescriptionProp="Surname"
                    history={this.props.history}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}  useDeletePost={false}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                    searchLabel="SEARCH"
                >
                    <BursaryApplicationEvaluationSearchForm data={this.state.model} onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }} />
                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(BursaryApplicationEvaluationSearch));


import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "../../Grid.css";
import "../../Form.css";
import "../../App.css";
import "./BursaryApplicationSocioEcoSelect.css";
import withMenuPermission from "../../widgets/withMenuPermission";
import { Button } from "react-bootstrap";
import LoadingIndicatorLogin from '../../LoadingIndicatorLogin';


const bursaryApplicationSocioEconomicStatuses = [
    { Id: "employed", Name: "Employed" },
    { Id: "unemployed", Name: "Unemployed" }
]


class BursaryApplicationSocioEcoSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedApplicationSocioEconomicStatus: "-1",
            message: "",
            loading: true
        };
    }

    back() {
        this.props.history.push({
            pathname: "/login"
        })
    }

    select() {
        this.setState({ message: "" });
        this.props.history.push({
            pathname: "/AddBursaryApplicationFlow",
            state: {
                selectedApplicationSocioEconomicStatus: this.state.selectedApplicationSocioEconomicStatus
            }
        })
    }

    render() {
        return (
            <div className="OnlineApplication-container">
                <div className="OnlineApplicationSelect">
                    <p className="errorMessage">{this.state.message}</p>
                    <p>Please select one of the following:</p>
                    <form className="style-2" id="scroll">
                        {bursaryApplicationSocioEconomicStatuses.map(AppSocioEcoStatus => (
                            <div className="form-check" key={AppSocioEcoStatus.Id}>
                                <label>
                                    <input type="radio"
                                        value={AppSocioEcoStatus.Id}
                                        checked={this.state.selectedApplicationSocioEconomicStatus === AppSocioEcoStatus.Id.toString()}
                                        onChange={(ev) => this.setState({ selectedApplicationSocioEconomicStatus: ev.target.value })}
                                    />
                                    <span className="label-text"> {AppSocioEcoStatus.Name} </span>
                                </label>
                            </div>
                        ))}
                    </form>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <Button
                                        block
                                        onClick={this.back.bind(this)}
                                        type="button"
                                    >
                                        BACK
                                    </Button>
                                </td>
                                <td>
                                    <Button
                                        block
                                        onClick={this.select.bind(this)}
                                        className="OnlineApplication-btn"
                                        disabled={this.state.selectedApplicationSocioEconomicStatus === "-1" || this.props.isLoading}
                                        type="button"
                                    >
                                        NEXT
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                </div>
                <LoadingIndicatorLogin show={this.props.isLoading} />
            </div>
        );
    }
}


export default withRouter(withMenuPermission(BursaryApplicationSocioEcoSelect));

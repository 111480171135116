import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTextField } from '../../widgets/CustomTextField';
import { EntityForm } from '../../widgets/Forms';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    MuiSelectSelectMenu: {
        '& .MuiSelect-selectMenu': { paddingRight: "150px !important" }
    }
}));

function QualificationSearchForm(props) {
    const [tabValue, setTabValue] = React.useState(0);
    const classes = useStyles();

    return (
        <EntityForm ref={React.createRef()} viewId="bursQual" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form => <form className={form.classes.container} id="qualificatoinsetup">
                <div className="row search-form-container">
                    <div className="col form-col">
                        <CustomTextField
                            id="QualificationName"
                            label="Qualification Name"
                            className="w-100"
                            onKeyPress={props.onKeyUp}
                            InputProps={{
                                readOnly: false,
                            }}
                            form={form}
                        />
                    </div>
                </div>
            </form>
            }
        </EntityForm>
    );
}

export default QualificationSearchForm;

import React from 'react';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from '../../../ViewUtils';
import { makeStyles } from '@material-ui/core/styles';

const utils = new ViewUtils();
export const LinkAccountForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function getTrancheType(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Progress") {
                return false
            }
            else {
                return true
            }
        }
    }

    return (
        <EntityForm ref={ref}
            dataURL='api/sims/LevyAccount/GetFind'
            values={props.data}
            entityLookupId={props.id}
            entityLookupController={props.entityLookupController} org="saseta"
            useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="LinkAccountDetailForm">

                    <table cellPadding="2" width="80%">
                        <tbody>
                            <tr>
                            <td>
                                    <SelectItem
                                        dataUrl={"api/sims/LevyAccount/GetLookupList?listId=FinancialYear"}
                                        id="FinancialYearID"
                                        label="Financial Year"
                                        className="w-100"
                                        form={form}
                                        InputProps={{
                                            readOnly: props.disableFields
                                        }}
                                        required
                                    />
                                </td>
                                <td>
                                    <SelectItem
                                        dataUrl={"api/sims/LevyAccount/GetLookupList?listId=LevyField"}
                                        id="LevyFieldID"
                                        label="Levy Field"
                                        className="w-100"
                                        form={form}
                                        InputProps={{
                                            readOnly: props.disableFields
                                        }}
                                        required
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField
                                        id="MainGLAccountNumber"
                                        key={tabValue}
                                        label="Main GL Account Number"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                        required
                                    />
                                </td>
                            <td>
                                    <CustomTextField
                                        id="ContraGLAccountNumber"
                                        key={tabValue}
                                        label="Contra GL Account Number"
                                        className="w-100 "
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                        required
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})

import React from 'react';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from '../../../ViewUtils';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();


export const InstitutionForm = React.forwardRef((props, ref) => {

    return (
        <EntityForm ref={ref}
            dataURL={props.dataURL}
            viewId="Institution"
            values={props.data}
            entityLookupId={props.id}
            entityLookupController={props.entityLookupController} org="saseta"
            useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="InstitutionForm">

                    <table cellPadding="2" width="80%">
                        <tbody>
                            <tr>
                                <td>
                                    <CustomTextField
                                        id="InstitutionName"
                                        label="Institution Name"
                                        className="w-100"
                                        required
                                        InputProps={{
                                            readOnly: props.disableFields,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})

export default InstitutionForm;

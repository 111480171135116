import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import { LearnerHETLecturerSupportForm } from '../../etqa/program/registration/LearnerHETLecturerSupportForm';
import withMenuPermission from "../../widgets/withMenuPermission";




const HETLecturerSupportRef = React.createRef();
const utils = new ViewUtils();

class AddLearnerHETLecturerSupport extends Component {

    state = {
        HETLecturerSupportDetails: {
            HETLecturerSupportCode: "",
            HETLecturerSupportTitle: ""
        },
        messageStyle: "message",
        id: null,
        Learner: this.props.location.state.LearnerID,
        CurrentProgramme: this.props.location.state.HETLecturerSupportID,
        CreateNewFlag: this.props.location.state.hideFields,
        showTable: true,
        model: {},
    };

    toolbarConfig = {
        items: [
            {
                type: "buttonGroup",
                buttons: [
                    { id: 'close', label: '', tooltip: 'Close', icon: 'CloseIcon' }
                ]
            }
            ,
            {
                type: "devider"
            },
            {
                type: "buttonGroup",
                buttons: [
                    { id: 'save', label: '', tooltip: 'Save', icon: 'SaveIcon' },
                    { id: 'documents', label: 'DOCUMENTS', tooltip: 'Documents', disabled: (this.props.location.state.hideFields), visible: !(this.props.location.state.hideFields) }
                ]
            }
        ]
    }



    componentDidMount() {
        if (utils.isNull(this.props.location.state.HETLecturerSupportID) || this.props.location.state.HETLecturerSupportID === 0)
        {
            this.defaultSponsorship()
        }
    }

    handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {

            this.setState({
                HETLecturerSupportetupDetails: {
                    HETLecturerSupportCode: values.HETLecturerSupportCode,
                    HETLecturerSupportTitle: values.HETLecturerSupportTitle
                },
                Learner: this.props.location.state.LearnerID
            });

            this.ECCheck(values);
        }
    }

    ECCheck(values) {
        if (!utils.isNull(values)) {
            if (!utils.isNull(values.EmployerID)) {
                let url = "api/sims/learner/EmployerContactCheck?EmployerID=" + values.EmployerID.Id;
                utils.invokeUrl(url, (response) => {
                    if (response.Result != "") {
                        this.setState({
                            message: response.Result,
                            messageStyle: "ERROR",
                        })
                    }
                },
                    null,
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        }
                    }
                )
            }
        }
    }


    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("documents" === buttonId) {
            this.props.history.push({
                pathname: '/learnerHETLecturerSupportDocuments',
                state: {
                    LearnerID: this.props.location.state.LearnerID,
                    LearnerHETLecturerSupportID: this.props.location.state.HETLecturerSupportID,
                    hideFields: false,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.location.state.permissions
                }
            });
        }
    }


    successCallback = (response) => {
        if (response.MessageType === "SUCCESS") {
            this.setState({
                showTable: false
            }, () => {
                this.setState({
                    showTable: true,
                    message: response.Message,
                    messageStyle: response.MessageType,
                    CurrentProgramme: response.EntityId,
                    CreateNewFlag: false
                })
            });
        }
        else {
            this.setState({
                message: response.Message, messageStyle: response.MessageType
            });
        }
    }

    defaultSponsorship = () => {
        let url = "api/sims/LearnerHETLecturerSupport/GetCurrentRole";
        utils.invokeUrl(url, (response) => {
            if (response.Result === "Provider" || response.Result === "AET Agent" || response.Result === "Agent") {
                this.setState({
                    model: {
                        SponsorshipID: { Id: 2, Description: "Industry Funded" }
                    }
                })
            }
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    dateChange = (form, date) => {

        if (!utils.isNull(form.values.CommencementDate)) {
            utils.autoSetDate(this, HETLecturerSupportRef, form.values.CommencementDate, 3, 0, 0, "CompletionDate", "CompletionDate")
        }
    }

    save() {
        let formValue = HETLecturerSupportRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            formValue.id = this.props.location.state.LearnerID;
            let input = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/learnerHETLecturerSupport/LinkLearnerHETLecturerSupport", (response) => {
                this.successCallback(response);
            }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: input
            });
        }
    }

    closeView() {
        console.clear()
        console.log(this.state.CurrentProgramme)
        this.props.history.push({
            pathname: '/LearnerHETLecturerSupportSearch',
            state: {
                learnerID: this.props.location.state.LearnerID,
                learnerNames: this.props.location.state.learnerNames
            }
        });
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {
                        (this.props.location.state.hideFields) ?
                            `Learner > Link New HET Lecturer Support > ${this.props.location.state.learnerNames}`
                            :
                            `Learner > Update HET Lecturer Support > ${this.props.location.state.learnerNames}`
                    }
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    title=""
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    {this.state.showTable === true && (
                                        <LearnerHETLecturerSupportForm ref={HETLecturerSupportRef}
                                            viewRef={this}
                                            id={this.state.CurrentProgramme}
                                            editPermission={this.props.location.state.permissions}
                                            viewOnly={this.props.location.state.viewOnly}
                                            hideFields={this.state.CreateNewFlag}
                                            valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                            dateChange={this.dateChange}
                                            model={this.state.model}
                                            data={this.state.model} />
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(AddLearnerHETLecturerSupport));


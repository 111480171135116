import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import AlertItem from "../../../widgets/AlertItem";
import ApproveClaimSearchForm from './ApproveClaimSearchForm';
import SearchView from "../../../widgets/SearchView";
import withMenuPermission from "../../../widgets/withMenuPermission";
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from "../../../ViewUtils";

const utils = new ViewUtils();
const toolbarRef = React.createRef();

const roleSRU = "SRU Administrator";

const headCells = [
    { id: 'ClaimNumber', numeric: false, disablePadding: false, label: 'Claim Number' },
    { id: 'ClaimDate', numeric: false, disablePadding: false, label: 'Claim Date' },
    { id: 'OrganisationSDLNumber', numeric: false, disablePadding: false, label: 'Vendor Number' },
    { id: 'OrganisationLegalName', numeric: false, disablePadding: false, label: 'Vendor Name' }
];


class ApproveClaimSearch extends Component {

    constructor(props) {
        super(props);

        

        this.state = {
            selectedClaims: null,
            model: {
                FinancialYearID: null,
                EmployerID: null,
                TrancheCodeID: null,
                BursaryInstitutionID: null,
                WAID: null,
            },
            searchParameters: [{ Name: "TrancheCodeID", Value: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.TrancheCodeID)) ? this.props.location.state.TrancheCodeID : null }],
            keyUpSwitch: false

        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        {id: 'approveClaim', label: 'Approve Claim', icon: 'ApproveIcon', tooltip: 'Approve Claim For Selected Records',disabled: utils.isNull(this.state.selectedClaims)},
                        {id: 'downloadClaim', label: 'Download Claim Form', icon: 'DownloadIcon', tooltip: 'Download claim form for selected record',disabled: utils.isNull(this.state.selectedClaims)}
                    ]
                }
            ]
        };

    }

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    downloadClaim = () => {

        let data = {
            Id: this.state.selectedClaims[0].Id,
            TrancheCodeID: this.state.model.TrancheCodeID.Id
        }
        let url = "api/sims/ApproveClaim/GrantClaimApproveDownload";
        utils.invokeUrl(url, (response) => {
            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
            toolbarRef.current.setButtonDisabled("downloadClaim", true);
        },
            null,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        )
    };

    claimApprove = () => {
        const IDs = this.state.selectedClaims.map(e => e.Id);
        let data = {
            IDs: IDs
        }
        let url = "api/sims/ApproveClaim/GrantClaimApprove";
        utils.invokeUrl(url, (response) => {
            this.successCallback(response);
        },
            null,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        )
    };

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelections = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedClaims: selection, Message: "" },
                () => {
                    if (utils.getCurrentRole() === roleSRU) {
                        if (this.state.TrancheCodeID !== "60" && this.state.TrancheCodeID !== "61" && this.state.TrancheCodeID !== "62" && this.state.TrancheCodeID !== "66") {
                            toolbarRef.current.setButtonDisabled("approveClaim", true);
                            toolbarRef.current.setButtonDisabled("downloadClaim", true);
                        }
                        else {
                            toolbarRef.current.setButtonDisabled("approveClaim", false);
                            toolbarRef.current.setButtonDisabled("downloadClaim", false);
                        }
                    }
                    else {
                        toolbarRef.current.setButtonDisabled("approveClaim", selection.length === 0);
                        toolbarRef.current.setButtonDisabled("downloadClaim", selection.length === 0);
                    }
                }
            )
        }
        else {
            this.setState({ selectedClaims: null, Message: "" },
                () => {
                    toolbarRef.current.setButtonDisabled("approveClaim", selection.length === 0);
                    toolbarRef.current.setButtonDisabled("downloadClaim", selection.length === 0);
                }
            )
        }
    }

    handleFormValueChange = (values) => {
        let TrancheCodeID = null;
        let newsearchParameters = [...this.state.searchParameters];

        if (!utils.isNull(values.TrancheCodeID)) {
            TrancheCodeID = values.TrancheCodeID.Id;
            let temp_element = { ...newsearchParameters[0] };
            temp_element.Value = TrancheCodeID
            newsearchParameters[0] = temp_element;
        }

        this.setState({
            searchParameters: JSON.parse(JSON.stringify(newsearchParameters)),
            TrancheCodeID: TrancheCodeID
        });

        if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
            this.setState({ model: values, selectedGrant: null });
        }

        if (utils.getCurrentRole() === roleSRU) {
            if (TrancheCodeID !== "60" && TrancheCodeID !== "61" && TrancheCodeID !== "62" && TrancheCodeID !== "66") {
                toolbarRef.current.setButtonDisabled("approveClaim", true);
            }
            else { toolbarRef.current.setButtonDisabled("approveClaim", false); }
        }
    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "close") {
            this.props.history.push({
                pathname: '/ProcessingNav'
            })
        } else if ("approveClaim" === buttonId) {
            this.claimApprove();
            toolbarRef.current.setButtonDisabled("approveClaim", true);
        } else if ("downloadClaim" === buttonId) {
            this.downloadClaim();
            toolbarRef.current.setButtonDisabled("downloadClaim", true);
        }
    }

    successCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }


    render() {

        return (
            <ViewContainer title={''}>
                <p className="breadcrumbs">
                    {"Grant Processing > Approve Claims"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <SearchView headCells={headCells} dataUrl="api/sims/ApproveClaim/GrantApproveClaimSearch" 
                    model={this.state.model} toolbarConfig={this.toolbarConfig} searchParameters={this.searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange} 
                    toolbarRef={toolbarRef}
                    history={this.props.history}
                    searchLabel={"SEARCH"}
                    autoFetchData={false}
                    multiSelect={true}
                    handleObject={true}
                    keyUpSwitch={this.state.keyUpSwitch}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    setSelection={(selection) => { this.handleSelections(selection) }}
                >
                    <ApproveClaimSearchForm data={this.state.model}
                        valueChangeHandler={(values) => this.handleFormValueChange(values)}  />
                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(ApproveClaimSearch));




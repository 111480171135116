import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Agent.css";
import { AgentForm } from './AgentForm';
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";



const agentRef = React.createRef();
const utils = new ViewUtils();

class Agent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            AgentDetails: {
                LegalName: "",
                SDLNumber: "",
                AccreditationCheck: false
            },
            RequiredFields: null,
            toolbarConfig:{
                items: [
                    {
                        type: "buttonGroup",
                        buttons: [
                            { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                        ]
                    },
            
                    {
                        type: "devider"
                    },
            
                    {
                        type: "buttonGroup",
                            buttons: [
                                { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.menuPermissionItem.Edit},
                                { id: 'contacts', label: 'Contacts', tooltip: '' },
                                { id: 'users', label: 'Users', tooltip: '' },
                                {
                                    contextMenu: [
                                        { id: "aet", description: "AET" },
                                        { id: "we", description: "Work Experience" },
                                        { id: "int", description: "Internship" },
                                        { id: "can", description: "Candidacy" },
                                        { id: "mc", description: "Mine Community" },
                                        { id: "ss", description: "Small Scale" },
                                        { id: "uyd", description: "Unemployed Youth Development" },
                                        { id: "medp", description: "Management and Executive Development Programme" },
                                        { id: "wc", description: "Workplace Coaches" },
                                        { id: "ls", description: "Learnership" },
                                        { id: "can", description: "Candidacy" },
                                        { id: "tvetncv", description: "TVET NCV Support" },
                                        { id: "qls", description: "QCTO Learnership" },
                                        { id: "scppi", description: "Short Courses Provided by Public Institution Programme" }
                                    ],
                                    id: 'programs', label: 'Programmes'
                                },
                                { id: 'learners', label: 'Learners', tooltip: '' }
                            ]
                    }
                ]
            }
            
        };
    }

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ AgentDetails: values});
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        }
        else if ("close" === buttonId) {
            this.closeView();
        }
        else if ("contacts" === buttonId) {
            this.props.history.push({
                pathname: '/agentContacts',
                state: {
                    selection: this.props.location.state.selection,
                    agentName: this.state.AgentDetails.LegalName,
                    agentSDLNumber: this.state.AgentDetails.SDLNumber
                }
            });
        }
        else if ("users" === buttonId) {
            this.props.history.push({
                pathname: '/agentUsers',
                state: {
                    selection: this.props.location.state.selection,
                    agentName: this.state.AgentDetails.LegalName,
                    agentSDLNumber: this.state.AgentDetails.SDLNumber
                }
            })
        } else if ("learners" === buttonId) {
            this.props.history.push({
                pathname: '/agentLearner',
                state: {
                    selection: this.props.location.state.selection,
                    agentName: this.state.AgentDetails.LegalName,
                    agentSDLNumber: this.state.AgentDetails.SDLNumber
                }
            })
        }
    }

    handleToolbarButtonMenuClick(menu) {
       
        if ("aet" === menu.id) {
            this.props.history.push({
                pathname: "/agentAETSearch",
                state: {
                    selection: this.props.location.state.selection,
                    agentName: this.state.AgentDetails.LegalName,
                    isAccredited: this.state.AgentDetails.AccreditationCheck,
                    agentSDLNumber: this.state.AgentDetails.SDLNumber
                }
            })
        }
        else if ("we" === menu.id) {
            this.props.history.push({
                pathname: "/agentWorkExperienceSearch",
                state: {
                    selection: this.props.location.state.selection,
                    agentName: this.state.AgentDetails.LegalName,
                    isAccredited: this.state.AgentDetails.AccreditationCheck,
                    agentSDLNumber: this.state.AgentDetails.SDLNumber
                }
            })
        }
        else if ("int" === menu.id) {
            this.props.history.push({
                pathname: "/agentInternshipSearch",
                state: {
                    selection: this.props.location.state.selection,
                    agentName: this.state.AgentDetails.LegalName,
                    isAccredited: this.state.AgentDetails.AccreditationCheck,
                    agentSDLNumber: this.state.AgentDetails.SDLNumber
                }
            })
        } else if ("uyd" === menu.id) {
            this.props.history.push({
                pathname: "/agentUnemployedYouthDevelopmentSearch",
                state: {
                    selection: this.props.location.state.selection,
                    agentName: this.state.AgentDetails.LegalName,
                    isAccredited: this.state.AgentDetails.AccreditationCheck,
                    agentSDLNumber: this.state.AgentDetails.SDLNumber
                }
            })
        }
        else if ("mc" === menu.id) {
            this.props.history.push({
                pathname: "/agentMineCommunitySearch",
                state: {
                    selection: this.props.location.state.selection,
                    agentName: this.state.AgentDetails.LegalName,
                    isAccredited: this.state.AgentDetails.AccreditationCheck,
                    agentSDLNumber: this.state.AgentDetails.SDLNumber
                }
            })
        }
        else if ("ss" === menu.id) {
            this.props.history.push({
                pathname: "/agentSmallScaleSearch",
                state: {
                    selection: this.props.location.state.selection,
                    agentName: this.state.AgentDetails.LegalName,
                    isAccredited: this.state.AgentDetails.AccreditationCheck,
                    agentSDLNumber: this.state.AgentDetails.SDLNumber
                }
            })
        }
        else if ("can" === menu.id) {
            this.props.history.push({
                pathname: "/agentCandidacySearch",
                state: {
                    selection: this.props.location.state.selection,
                    agentName: this.state.AgentDetails.LegalName,
                    isAccredited: this.state.AgentDetails.AccreditationCheck,
                    agentSDLNumber: this.state.AgentDetails.SDLNumber
                }
            })
        }
        else if ("medp" === menu.id) {
            this.props.history.push({
                pathname: "/agentMEDPSearch",
                state: {
                    selection: this.props.location.state.selection,
                    agentName: this.state.AgentDetails.LegalName,
                    isAccredited: this.state.AgentDetails.AccreditationCheck,
                    agentSDLNumber: this.state.AgentDetails.SDLNumber
                }
            })
        }
        else if ("wc" === menu.id) {
            this.props.history.push({
                pathname: "/agentWorkplaceCoachesSearch",
                state: {
                    selection: this.props.location.state.selection,
                    agentName: this.state.AgentDetails.LegalName,
                    isAccredited: this.state.AgentDetails.AccreditationCheck,
                    agentSDLNumber: this.state.AgentDetails.SDLNumber
                }
            })
        } else if ("ls" === menu.id) {
            this.props.history.push({
                pathname: "/agentLearnershipSearch",
                state: {
                    selection: this.props.location.state.selection,
                    agentName: this.state.AgentDetails.LegalName,
                    isAccredited: this.state.AgentDetails.AccreditationCheck,
                    agentSDLNumber: this.state.AgentDetails.SDLNumber
                }
            })
        }
        else if ("tvetncv" === menu.id) {
            this.props.history.push({
                pathname: "/agentTVETNCVSearch",
                state: {
                    selection: this.props.location.state.selection,
                    agentName: this.state.AgentDetails.LegalName,
                    isAccredited: this.state.AgentDetails.AccreditationCheck,
                    agentSDLNumber: this.state.AgentDetails.SDLNumber
                }
            })
        } else if ("qls" === menu.id) {
            this.props.history.push({
                pathname: "/agentQCTOLearnershipSearch",
                state: {
                    selection: this.props.location.state.selection,
                    agentName: this.state.AgentDetails.LegalName,
                    isAccredited: this.state.AgentDetails.AccreditationCheck,
                    agentSDLNumber: this.state.AgentDetails.SDLNumber
                }
            })
        }
        else if ("scppi" === menu.id) {
            this.props.history.push({
                pathname: "/agentSCPPISearch",
                state: {
                    selection: this.props.location.state.selection,
                    agentName: this.state.AgentDetails.LegalName,
                    isAccredited: this.state.AgentDetails.AccreditationCheck,
                    agentSDLNumber: this.state.AgentDetails.SDLNumber
                }
            })
        }
    }

    successCallback = () => {

        this.setState({ message: "Agent updated successfully", messageStyle: "SUCCESS" });
    }

    save() {
        let formValue = agentRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {

            utils.invokeUrl("api/sims/agentdetails/UpdateAgent", this.successCallback, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(formValue)
            });
        }
    }

    closeView() {
        this.props.history.push({
            pathname: '/agentSearch',
            state: { selection: this.props.location.state.agentID }
        });
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">

                <p className="breadcrumbs">
                    {"Agent " + (this.state.AgentDetails.LegalName !== null ? "> " + this.state.AgentDetails.LegalName + (this.state.AgentDetails.SDLNumber !== null ? " - " + this.state.AgentDetails.SDLNumber : "") : "> Add")}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Agent" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={true} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "55vh", maxHeight: "55vh" }}>
                                    <AgentForm
                                        ref={agentRef}
                                        id={this.props.location.state.selection}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                        requiredFields={this.state.RequiredFields}
                                        editPermission={this.props.menuPermissionItem.Edit}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(Agent));


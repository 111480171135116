import React from 'react';
import "../../View.css";
import "../../Grid.css";
import "../../Form.css";
import "../../App.css";
import "./BursaryApplicationDocuments.css"
import EnhancedTable from '../../widgets/ResponsiveTable';

const headCells = [
    { id: 'DocumentType', numeric: false, disablePadding: true, label: 'Document Type' },
    { id: 'UploadedBy', numeric: false, disablePadding: true, label: 'Uploaded By' },
    { id: 'UploadDate', numeric: false, disablePadding: true, label: 'Upload Date' },
    { id: 'DocumentName', numeric: false, disablePadding: true, label: 'Document Name', contentType: 'document' }
]

export default function BursaryApplicationDocuments(props, ref) {
    return (
        <EnhancedTable headCells={headCells} onCellSelectionChange={(rowid, columnid) => { props.handleCell(rowid, columnid) }} checkRefreshToken={props.checkRefreshToken} searchParameters={[{ Name: `${props.moduleName}ID`, Value: props.selection }]} paged={false}
            dataUrl={props.documentsDataUrl !== null ? props.documentsDataUrl : null} 
        />
    );
}

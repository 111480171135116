import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import SearchView from "../../widgets/SearchView";
import ViewUtils from '../../ViewUtils';
import AETSetupSearchForm from '../program/AETSetupSearchForm';
import withMenuPermission from "../../widgets/withMenuPermission";
import AlertItem from "../../widgets/AlertItem";


const headCells = [
    { id: 'ProgrammeCode', numeric: false, disablePadding: true, label: 'AET Programme Code' },
    { id: 'ProgrammeTitle', numeric: false, disablePadding: true, label: 'AET Programme Description' },
    { id: 'CommencementDate', numeric: false, disablePadding: true, label: 'Start Date' },
    { id: 'CompletionDate', numeric: false, disablePadding: true, label: 'End Date' },
    { id: 'ProgrammeStatus', numeric: false, disablePadding: true, label: 'Programme Status' }
];



const utils = new ViewUtils();
const toolbarRef = React.createRef();

class LearnerAETAssessment extends Component {
    constructor(props) {
        super(props);

        this.searchParameters = !utils.isNull(this.props.location.state.learnerID) ? [
            { Name: "AETProgrammeCode", Value: "" },
            { Name: "AETProgrammeDescription", Value: "" },
            { Name: "LearnerID", Value: this.props.location.state.learnerID }
        ]
            :
            [
                { Name: "AETProgrammeCode", Value: "" },
                { Name: "AETProgrammeDescription", Value: "" },
                { Name: "LearnerID", Value: this.props.location.state.learnerID }
            ];

        let model = !utils.isNull(this.props.location.state.learnerID) ?
            {
                AETProgrammeCode: "",
                AETProgrammeDescription: "",
                LearnerID: this.props.location.state.learnerID

            }
            :
            {
                AETProgrammeCode: "",
                AETProgrammeDescription: "",
                LearnerID: this.props.location.state.learnerID
            }

        this.state = !utils.isNull(this.props.location.state.learnerID) ?
            {
                model: model,
                keyUpSwitch: false,
                toolbarConfig: {
                    items: [
                        {
                            type: "buttonGroup",
                            buttons: [
                                { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                            ]
                        }
                        ,
                        {
                            type: "devider"
                        },
                        {
                            type: "buttonGroup",
                            buttons: [
                                { id: 'edit', label: '', tooltip: 'View', icon: 'EditIcon', visible: this.props.menuPermissionItem.View }
                            ]
                        }
                    ]
                }
            }
            :
            {
                model: model,
                keyUpSwitch: false
            };

    }


    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/learner',
                state: {
                    selection: this.props.location.state.learnerID
                }
            });
        }
        else if (buttonId === "edit") {
            this.props.history.push({
                pathname: '/ViewAETAssessment',
                state: {
                    LearnerID: this.props.location.state.learnerID,
                    AETID: this.state.selectedLearnerAET[0].Id,
                    ProgrammeCode: this.state.selectedLearnerAET[0].ProgrammeCode,
                    learnerNames: this.props.location.state.learnerNames,
                    permissionItem: this.props.menuPermissionItem,
                    CreditBased:this.state.CreditBased
                }
            })
        }

    }

    checkCreditBased(id) {
        let url = "api/sims/LearnerAETAssessment/CheckAETCreditBased?Id=" + id;
        utils.invokeUrl(url, (response) => {
            this.setState({ CreditBased: response.Result === "1" ? true : false })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedLearnerAET: selection })

            let url = "api/sims/LearnerAETAssessment/CheckCanAssessProgramme?Id=" + selection[0].Id;
            utils.invokeUrl(url, (response) => {
                toolbarRef.current.setButtonDisabled("edit", response.Result !== "" ? true : false)
                this.setState({
                    message: response.Result,
                    messageStyle: "WARNING"
                })
            },
                (e) => {
                    toolbarRef.current.setButtonDisabled("edit", true)
                },
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    }
                }
            )

            this.checkCreditBased(selection[0].Id)
        }
        else {
            this.setState({ selectedLearnerAET: null })
            toolbarRef.current.setButtonDisabled("edit", true)
        }
    }


    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (

            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {`Learner > AET Assessments > ${this.props.location.state.learnerNames}`}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <SearchView headCells={headCells} dataUrl={utils.isNull(this.props.dataUrl) ? "api/sims/LearnerAETAssessment/getLearnerAETAssessments" : this.props.dataUrl}
                    entityViewPath='/ViewAETAssessment'
                    model={this.state.model} toolbarConfig={this.state.toolbarConfig}
                    toolbarRef={toolbarRef}
                    searchParameters={this.searchParameters} onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="AETProgrammeDescription" mode={this.props.mode}
                    history={this.props.history} multiSelect={this.props.multiSelect}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                >
                    <AETSetupSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(LearnerAETAssessment));

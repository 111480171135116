import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';

const utils = new ViewUtils();
export const SDProviderAddUserForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
<EntityForm ref={ref} viewId="sdproviderAddUser" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
    {form =>
    <form className={form.classes.container} id="sdproviderAddUser">
        <table cellPadding="2" className="w-100">
            <tbody>
                            <tr>

                                <td>
                                    <CustomTextField id="FirstName"
                                        key={tabValue}
                                        label="First Name"
                                        required={true}
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                                <td>
                                    <CustomTextField id="Surname"
                                        required={true}
                                        key={tabValue}
                                        label="Surname"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                </tr>
                <tr>
                                <td>
                                    <CustomTextField id="IDNo"
                                        required={true}
                                        key={tabValue}
                                        label="ID Number"
                                        validationRegex={/^[a-zA-Z0-9]/g}
                                        validationMessage="The ID Number must consist of Alpha Numeric characters"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                                <td>
                                    <CustomTextField id="TelephoneNumber"
                                        required={true}
                                        key={tabValue}
                                        label="Telephone Number"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        validationGroup="contactNumbers"
                                        validator={{
                                            script: (formValues) => {

                                                if (!utils.isNull(formValues)) {
                                                    return !utils.isNull(formValues.CellphoneNumber) && !utils.isStringEmpty(formValues.CellphoneNumber) ||
                                                        !utils.isNull(formValues.FaxNumber) && !utils.isStringEmpty(formValues.FaxNumber) ||
                                                        !utils.isNull(formValues.TelephoneNumber) && !utils.isStringEmpty(formValues.TelephoneNumber)
                                                }
                                            },
                                            message: "Atleast one contact number needs to be filled in"
                                        }}
                                        form={form} />
                                </td>
                </tr>
                <tr>
                                <td>
                                    <CustomTextField id="CellphoneNumber"
                                        required={true}
                                        key={tabValue}
                                        label="Cellphone Number"
                                        validationGroup="contactNumbers"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                    <td>
                        <CustomTextField id="FaxNumber"
                                         key={tabValue}
                                        label="Fax Number"
                                        validationGroup="contactNumbers"
                                         className="w-100"
                                         InputProps={{
                                            readOnly: false,
                                        }}
                                         form={form} />
                    </td>
                </tr>
                <tr>
                                <td>
                                    <CustomTextField id="Email"
                                        key={tabValue}
                                        required={true}
                                        label="EMail"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                </tr>
            </tbody>
        </table>
    </form>
    }
</EntityForm>
    );
})

import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from '../../../ViewUtils';
import OFOSearch from '../OFOSearch';
import { LookupField } from '../../../widgets/LookupField';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
export const QCTOModuleSetupForm = React.forwardRef((props, ref) => {

    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="QCTOModuleSetup"
            dataURL='api/sims/QCTOmoduleSetupDetails/getfind'
            values={props.data}
            entityLookupController={props.entityLookupController} org="mqa"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="QCTOModuleDetailsForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="QCTO Module Details" {...a11yProps(0)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ModuleCode"
                                                key={tabValue}
                                                label="Module Code"
                                                className="w-100 "
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={props.id}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ModuleTitle"
                                                key={tabValue}
                                                label="Module Title"
                                                className="w-100 "
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/QCTOmoduleSetupDetails/GetLookupList?listId=QualityAssuranceBody"
                                                id="QualityAssuranceBodyID"
                                                key={tabValue}
                                                label="Quality Assurance Body"
                                                className="w-100"
                                                required
                                                form={form}
                                                disabled={!props.editPermission} />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="Credits"
                                                validationRegex={/^[0-9]+$/g}
                                                validationMessage="This field must be numeric"
                                                key={tabValue}
                                                label="Credits"
                                                className="w-100 "
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="RegistrationStartDate"
                                                key={tabValue}
                                                label="Registration Start Date"
                                                className='{classes.textField} w-100'
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="RegistrationEndDate"
                                                key={tabValue}
                                                label="Registration End Date"
                                                className='{classes.textField} w-100'
                                                required
                                                validator={{
                                                    script: (formValues) => {
                                                        return !utils.isNull(formValues)
                                                            && !utils.isNull(formValues.RegistrationStartDate)
                                                            && formValues.RegistrationStartDate.getTime() < formValues.RegistrationEndDate.getTime()
                                                    }, message: "Start date must be before the end date"
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="LastEnrolmentDate"
                                                validationGroup="enrolmentDates"
                                                key={tabValue}
                                                label="Last Enrolment Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="LastAchievementDate"
                                                validationGroup="enrolmentDates"
                                                key={tabValue}
                                                label="Last Achievement Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                validator={{
                                                    script: (formValues) => {
                                                        return !utils.isNull(formValues)
                                                            && !utils.isNull(formValues.LastEnrolmentDate)
                                                            && formValues.LastEnrolmentDate.getTime() < formValues.LastAchievementDate.getTime()
                                                    }, message: "Last achievement date must be greater than last enrolment date"
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/QCTOmoduleSetupDetails/GetLookupList?listId=nqfLevel"
                                                id="NQFLevelID"
                                                key={tabValue}
                                                label="NQF Level"
                                                className="w-100"
                                                required
                                                form={form} 
                                                disabled={!props.editPermission} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/QCTOmoduleSetupDetails/GetLookupList?listId=LearningType"
                                                id="LearningTypeID"
                                                key={tabValue}
                                                label="Learning Type"
                                                className="w-100"
                                                required
                                                form={form} 
                                                disabled={!props.editPermission} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/QCTOmoduleSetupDetails/GetLookupList?listId=ModuleType"
                                                id="ModuleTypeID"
                                                key={tabValue}
                                                label="Module Type"
                                                className="w-100"
                                                required
                                                form={form} 
                                                disabled={!props.editPermission} />
                                        </td>
                                        <td> 
                                            <LookupField
                                                id="OFOOccupationID"
                                                label="OFO Code"
                                                className="w-100"
                                                form={form} disabled={!props.editPermission}
                                            >
                                                <OFOSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/QCTOmoduleSetupDetails/getofooccupationsearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("OFOOccupationID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})

import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./User.css";
import ViewUtils from "./../../ViewUtils";
import { UserDetailForm } from './UserDetailForm';
import { ViewContainer } from "./../../View.jsx";
import AlertItem from "../../widgets/AlertItem";
import Prompt from '../../widgets/Prompt';
import withMenuPermission from "../../widgets/withMenuPermission";


const formRef = React.createRef();
const utils = new ViewUtils();

class UserDetails extends Component {
    constructor(props) {
        super(props)
    this.state = {
        UserDetails: {
            FirstName: "",
            Surname: "",
            passwordDialogOpen: false,
        },
        title: "",
        id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
        FirstName: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.FirstName)) ? this.props.location.state.FirstName : null,
        Surname: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.Surname)) ? this.props.location.state.Surname : null,
        counter: 0,
        promptOpen: false,
        promptTitle: "Reset Password",
        promptContent: "Are you sure you want to reset the current User's Password?",
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: '', tooltip: 'Save', icon: 'SaveIcon' },
                        { id: "Password", label: '', tooltip: 'Reset Password', icon: 'PasswordIcon' },
                        { id: "Unlock", label: '', tooltip: 'Unlock User', icon: 'LockOpenIcon' },
                        { id: "userRoles", label: 'User Roles', tooltip: '', visible: this.props.onMenuSpecificPermissionItem('/userrole').View },
                    ]
                }
            ]
        }
    };
}

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ UserDetails: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("userRoles" === buttonId) {
            this.props.history.push({
                pathname: '/userRole',
                state: { userID: this.props.location.state.selection, FirstName: this.state.FirstName, Surname: this.state.Surname }
            });
        } else if ("Password" === buttonId) {
            this.setState({ promptOpen: true });
        } else if ("Unlock" === buttonId) {
            this.UnlockAccount();
        }
         else {
            this.close();
        }
    }

    save = () => {
        let formValue = formRef.current.getValue();

        if (formValue !== null && typeof formValue !== 'undefined') {
            let isAdd = true;
            if (!utils.isNull(this.state.id)) {
                formValue.id = this.state.id;
                isAdd = false;
            }

            if (isAdd) {
                const url = `api/sims/user/insertuser`;
                utils.saveForm(this, formRef, url, (response) => {
                    this.setState({ id: response.EntityId });
                    utils.showMessage(this, response.Message, response.MessageType);
                    response.MessageType === "ERROR" ? this.errorCallback(response) : this.successCallback(response);
                }, this.errorCallback);
            } else {
                const url = `api/sims/user/updateuser`;
                utils.updateForm(this, formRef, url, (response) => {
                    this.setState({ id: response.EntityId});
                    utils.showMessage(this, response.Message, response.MessageType);
                    response.MessageType === "ERROR" ? this.errorCallback(response) : this.successCallback(response);
                }, this.errorCallback);
            }
        }
    }

    resetPassword() {

        this.setState({ promptOpen: false })
        let data = {};
        data.ID = this.state.id
        let url = "api/sims/user/ResetPassword";

        utils.invokeUrl(url, (response) => {
            this.successCallback(response);
        },
            (response) => {
                this.error(response);
            },
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        )
    }

    UnlockAccount() {
        let data = {};
        data.ID = this.state.id
        let url = "api/sims/user/UnlockAccount";

        utils.invokeUrl(url, (response) => {
            this.successCallback(response);
        },
            (response) => {
                this.error(response);
            },
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        )
    }

    successCallback(response) {
        this.setState({
            message: response.Message, messageStyle: response.MessageType, counter: this.state.counter + 1
        });

    }
    errorCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }
    
    close() {
        this.props.history.push({
            pathname: '/userMaintenanceSearch',
        });
    }

    postCloseHandler = () => {
        this.setState({ passwordDialogOpen: false })
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"User Maintenance > User > " + (this.state.FirstName !== null ? this.state.FirstName + " " + this.state.Surname : "> Add")}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="User" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={true} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <UserDetailForm ref={formRef}
                                    key={this.state.counter}
                                    id={this.state.id !== null ? this.state.id : typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null}
                                    valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                            </td>
                        </tr>
                    </tbody>
                </table>

                    <Prompt open={this.state.promptOpen}
                        promptTitle={this.state.promptTitle}
                        promptContent={this.state.promptContent}
                        handleYes={() => { this.resetPassword() }}
                        closePrompt={this.closePrompt}
                    />
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(UserDetails));

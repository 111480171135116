import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { LookupField } from '../../widgets/LookupField';
import SchoolEMISSearch from '../SchoolEMISSearch';
import STATSSAAreaCodeSearch from '../STATSSAAreaCodeSearch';
import { AddressCodeItem } from '../../widgets/AddressCodeItem';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import { GPSCoordinatesAPI } from '../../widgets/GPSCoordinatesAPI';
import QualificationSetupSearch from '../program/QualificationSetupSearch';
import OFOSearch from '../program/OFOSearch';
import InstitutionSearch from '../program/InstitutionSearch';
import { Button } from "antd";
import EducationalHistoryDocuments from "./EducationalHistoryDocuments";
import LearnerDocumentDialog from "./LearnerDocumentDialog";
import ExperientialLearningDocuments from "./ExperientialLearningDocuments";
import ExperientialLearningDialog from "./ExperientialLearningDialog";
import EmploymentHistoryDocuments from "./EmploymentHistoryDocuments";
import EmploymentHistoryDialog from "./EmploymentHistoryDialog";
import PersonSearch from '../person/PersonSearch';
import ImpactOfLearningDocuments from "./ImpactOfLearningDocuments";
import LearnerImpactImageDialog from "./LearnerImpactImageDialog";
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles({
    TextArea: {
        '& .MuiOutlinedInput-inputMultiline': {
            height: "150px !important",
            width: "600px !important"
        }
    }
})
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
export const LearnerForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [postalCodeValue, setPostalCodeValue] = React.useState(null);
    const [documentsDialogOpen, setdocumentsDialogOpen] = React.useState(false);
    const [ELdocumentsDialogOpen, setELdocumentsDialogOpen] = React.useState(false);
    const [EHdocumentsDialogOpen, setEHdocumentsDialogOpen] = React.useState(false);
    const [imagesDialogOpen, setImagesDialogOpen] = React.useState(false);
    const [DocURL, setDocURL] = React.useState(null);
    const [ImgURL, setImgURL] = React.useState(null);
    const [showTable, setshowTable] = React.useState(true);
    const [elDocURL, setelDocURL] = React.useState(null);
    const [ehDocURL, setehDocURL] = React.useState(null);
    const [physicalCodeValue, setPhysicalCodeValue] = React.useState(null);
    const [parentpostalCodeValue, setParentPostalCodeValue] = React.useState(null);
    const [parentphysicalCodeValue, setParentPhysicalCodeValue] = React.useState(null);

    const [showLeanerDialog, setShowLeanerDialog] = React.useState(false);
    const [showExperientialDialog, setShowExperientialDialog] = React.useState(false);
    const [showEmploymentHistoryDialog, setshowEmploymentHistoryDialog] = React.useState(false);
    const [showImpactofLearningDialog, setShowImpactofLearningDialog] = React.useState(false);
    
    
    const [leanerFileName, setLeanerFileName] = React.useState('');
    const [imageFileName, setImageFileName] = React.useState('');
    const [experientFileName, setExperientFileName] = React.useState('');
    const [employmentFileName, setEmploymentFileName] = React.useState('');

    React.useEffect(() => {
        if (props.populateAddressDetails) {
            setParentPhysicalCodeValue(props.data.ParentPersonPhysicalCode);
            setParentPostalCodeValue(props.data.ParentPersonPostalCode);
            props.onChangePopulateAddressDetails();
        }
    }, [props.populateAddressDetails])

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        console.log(newValue);
        if (newValue == 6) {
            setImgURL("api/sims/learnerdetails/GetImpactofLearningDocuments");
        }
        else if (newValue == 7) {
            setDocURL("api/sims/learnerdetails/GetPostSchoolDocuments");
        }
        else if (newValue == 8) {
            setelDocURL("api/sims/learnerdetails/GetExperientialLearningDocuments");
        }
        else if (newValue == 9) {
            setehDocURL("api/sims/learnerdetails/GetEmploymentHistoryDocuments");
        }
        else {
            setDocURL(null);
            setelDocURL(null);
            setehDocURL(null);
            setImgURL(null);
        }
    };

    const setDocumentDialogTabs=(type)=>{
        setShowLeanerDialog(false)
        setShowExperientialDialog(false)
        setshowEmploymentHistoryDialog(false)
        setShowImpactofLearningDialog(false)
        if (type === 'showLeanerDialog') {
            setShowLeanerDialog(true)
        } else if (type ==='showImpactofLearningDialog'){
            setShowImpactofLearningDialog(true)
        }else if(type==='showExperientialDialog'){
            setShowExperientialDialog(true)
        }else  if(type==='showEmploymentHistoryDialog'){
            setshowEmploymentHistoryDialog(true)
        }else{

        }
    }
    const handleUsePhysicalAddressForPostal = form => event => {


        if (event.target.checked === true) {
            form.values['PostalAddressLine1'] = form.values["PhysicalAddress1"];
            form.values['PostalAddressLine2'] = form.values["PhysicalAddress2"];
            form.values['PostalAddressLine3'] = form.values["PhysicalAddress3"];
            form.values['PostalCode'] = form.values["PhysicalCode"];
            setPostalCodeValue(form.values["PhysicalCode"]);
        }
        form.updateValue('UsePhysicalAsPostal', event.target.checked);


    }
    const handleUsePhysicalAddressForPostalParent = form => event => {


        if (event.target.checked === true) {
            form.values['ParentPersonPostalAddressLine1'] = form.values["ParentPersonPhysicalAddress1"];
            form.values['ParentPersonPostalAddressLine2'] = form.values["ParentPersonPhysicalAddress2"];
            form.values['ParentPersonPostalAddressLine3'] = form.values["ParentPersonPhysicalAddress3"];
            form.values['ParentPersonPostalCode'] = form.values["ParentPersonPhysicalCode"];
            setParentPostalCodeValue(form.values["ParentPersonPhysicalCode"]);
        }
        form.updateValue('ParentUsePhysicalAsPostal', event.target.checked);


    }
    const updateGPSAddress = (address, form) => {
        form.values["GPSCoordinates"] = address.gpscoordinates.coordinate;
        form.values["PhysicalAddress1"] = address.streetnumberpar + ' ' + address.routepar;
        form.values["PhysicalAddress2"] = address.sublocalitylevel1par;
        form.values["PhysicalAddress3"] = address.localitypar;
        form.values["PhysicalCode"] = address.postalcodepar;
        setPhysicalCodeValue(form.values["PhysicalCode"]);
    }
    const updateGPSAddressParent = (address, form) => {
        form.values["ParentGPSCoordinates"] = address.gpscoordinates.coordinate;
        form.values["ParentPhysicalAddress1"] = address.streetnumberpar + ' ' + address.routepar;
        form.values["ParentPhysicalAddress2"] = address.sublocalitylevel1par;
        form.values["ParentPhysicalAddress3"] = address.localitypar;
        form.values["ParentPhysicalCode"] = address.postalcodepar;
        setParentPhysicalCodeValue(form.values["ParentPhysicalCode"]);
    }

    function getDescription(values, form) {
        if (values !== undefined && values !== null) {
            if (values["SecondDescription"] !== undefined) {
                form.values["QualificationTitle"] = values["SecondDescription"];
            }
        }
    }

    function getOFODescription(values, form) {
        if (values !== undefined && values !== null) {
            if (values["SecondDescription"] !== undefined) {
                form.values["LinkedOFODescription"] = values["SecondDescription"];
            }
        }
    }

    const config = {
        moduleName: "Learner",

        dataUrls: {
            documents: null,
        },

        postUrls: {
            documents: "api/sims/learnerdetails/AddEducationHistoryDocument"
        }
    };

    const ELconfig = {
        moduleName: "Learner",

        dataUrls: {
            documents: null,
        },

        postUrls: {
            documents: "api/sims/learnerdetails/AddExperientialLearningDocuments"
        }
    };

    const EHconfig = {
        moduleName: "Learner",

        dataUrls: {
            documents: null,
        },

        postUrls: {
            documents: "api/sims/learnerdetails/AddEmploymentHistoryDocument"
        }
    };

    const ILconfig = {
        moduleName: "Learner",

        dataUrls: {
            documents: null,
        },

        postUrls: {
            documents: "api/sims/learnerdetails/AddImpactofLearningDocument"
        }
    };


    const setDialogOpen = () => {
        setdocumentsDialogOpen(true);
    };

    const setimgDialogOpen = () => {
        setImagesDialogOpen(true);
    };

    const setelDialogOpen = () => {
        setELdocumentsDialogOpen(true);
    };

    const setehDialogOpen = () => {
        setEHdocumentsDialogOpen(true);
    };

    const handleCellSelect = (rowid, headCell, section) => {

        let url = "";

        if (headCell.contentType === "document") {

            if (section === "i") { url = "api/sims/learnerdetails/DownloadImpactofLearningImage?Id=" + rowid; }
            else {
                 url = "api/sims/learnerdetails/getEvidenceofQualification?documentID=" + rowid; }
            utils.invokeUrl(url, (response) => {
                var blob = new Blob([base64ToArrayBuffer(response.Base64)], { type: response.Type });
                var url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = response.Name;
                link.target = '_blank';
                link.click();

            }
            );
        }

    };

    const base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    const postUploadHandler = (save, cancel) => {
        //Successful upload
        if (save === true) {
            setshowTable(false);
            setdocumentsDialogOpen(false);
            setELdocumentsDialogOpen(false);
            setEHdocumentsDialogOpen(false);
            setImagesDialogOpen(false);
            setshowTable(true);
        }
        //Close dialog
        else if (cancel === true) {
            setdocumentsDialogOpen(false);
            setELdocumentsDialogOpen(false);
            setEHdocumentsDialogOpen(false);
            setImagesDialogOpen(false);
        }
        //Error on upload
        else {
            setdocumentsDialogOpen(false);
            setELdocumentsDialogOpen(false);
            setEHdocumentsDialogOpen(false);
            setImagesDialogOpen(false);
        }
    };

    const dataArrivedHandler = (values) => {

        if (!utils.isNull(values)) {
            setParentPhysicalCodeValue(values.ParentPersonPhysicalCode);
            setParentPostalCodeValue(values.ParentPersonPostalCode);
        }
    }

    return (
        <EntityForm ref={ref} viewId="learner" values={props.data} dataURL='api/sims/learnerdetails/getfind'
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler} dataArrivedHandler={dataArrivedHandler} >
            {form =>
                <form className={form.classes.container} id="learnerForm">
                    <div className="row">
                        <div className="col w-100">
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=Title"
                                                id="TitleID"
                                                label="Title"
                                                className="w-100"
                                                required={true}
                                                allowEmptyValue={true}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                                 />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="FirstName"
                                                label="First Name"
                                                className="w-100"
                                                required
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                                validationRegex={/^(?=.*[a-zA-Z]{3})[a-zA-Z]*$/g}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="MiddleName"
                                                label="Middle Name"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                                validationRegex={/^(?:[a-zA-Z]{3,}|)$/g}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="MiddleName2"
                                                label="Middle Name 2"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="Surname"
                                                label="Surname"
                                                className="w-100"
                                                required
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                                validationRegex={/^(?:[a-zA-Z\s]{3,}|)$/g}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1', paddingTop:10, minWidth:50 }}>
                            <Tabs  value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs" variant="scrollable" scrollButtons="auto">
                                <Tab label="General Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null, fontSize:12, fontWeight:500,  }} />
                                <Tab label="Contact Details" {...a11yProps(1)} style={{ color: form.fieldParentErrors[1] === true ? "red" : null, fontSize:12, fontWeight:500 }} />
                                <Tab label="Health Functioning Values" wrapped {...a11yProps(2)} style={{ color: form.fieldParentErrors[2] === true ? "red" : null, width: "50px" }} />
                                <Tab label="Address Details" {...a11yProps(3)} style={{ color: form.fieldParentErrors[3] === true ? "red" : null, fontSize: 12, fontWeight: 500 }} />
                                <Tab label="Parent/Guardian Details" {...a11yProps(4)} style={{ color: form.fieldParentErrors[4] === true ? "red" : null, fontSize: 12, fontWeight: 500 }} />
                                <Tab label="Educational Details" {...a11yProps(5)} style={{ color: form.fieldParentErrors[5] === true ? "red" : null, fontSize: 12, fontWeight: 500 }} />
                                <Tab onClick={() => { setDocumentDialogTabs('showImpactofLearningDialog') }} label="Impact Of Learning" {...a11yProps(6)} style={{ color: form.fieldParentErrors[6] === true ? "red" : null, fontSize: 12, fontWeight: 500 }} />
                                <Tab label="Post School Educational Details" {...a11yProps(7)}  style={{ color: form.fieldParentErrors[7] === true ? "red" : null, fontSize:12, fontWeight:500 }} />
                                <Tab onClick={()=>{setDocumentDialogTabs('showLeanerDialog')}} label="Educational History" {...a11yProps(8)} style={{ color: form.fieldParentErrors[8] === true ? "red" : null, fontSize:12, fontWeight:500 }} />
                                <Tab onClick={()=>{setDocumentDialogTabs('showExperientialDialog')}} label="Experiential Learning" {...a11yProps(9)} style={{ color: form.fieldParentErrors[9] === true ? "red" : null, fontSize:12, fontWeight:500 }} />
                                <Tab onClick={()=>{setDocumentDialogTabs('showEmploymentHistoryDialog')}} label="Employment History" {...a11yProps(10)} style={{ color: form.fieldParentErrors[10] === true ? "red" : null, fontSize:12, fontWeight:500 }} />
                                <Tab label="CV" {...a11yProps(11)} style={{ color: form.fieldParentErrors[11] === true ? "red" : null, fontSize:12, fontWeight:500, textAlign:'start'}} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=HasSouthAfricanID"
                                                id="HasSouthAfricanID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Does the Person have a South African ID Number?"
                                                className="w-100"
                                                form={form} 
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=AlternateIDType"
                                                id="AlternateIDTypeID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Alternate ID Type"
                                                className="w-100"
                                                form={form} 
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="IDNo"
                                                key={tabValue}
                                                parentId={0}
                                                label="ID Number"
                                                className="w-100"
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=Verified"
                                                id="VerifiedID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Proof Of Life"
                                                className="w-100"
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="Initials"
                                                key={tabValue}
                                                parentId={0}
                                                label="Initials"
                                                className="w-100"
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="DateofBirth"
                                                parentId={0}
                                                key={tabValue}
                                                label="Date of Birth"
                                                className='{classes.textField} w-100'
                                                required

                                                form={form} 
                                                disabled={!props.editPermission}
                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=Gender"
                                                id="GenderID"
                                                parentId={0}
                                                key={tabValue}
                                                label="Gender"
                                                className="{classes.select} w-100"
                                                required={true}
                                                allowEmptyValue={true}
                                                InputProps={{
                                                    disabled: !props.editPermission
                                                }}
                                                form={form} 
                                                />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=Equity"
                                                id="EquityID"
                                                required
                                                parentId={0}
                                                key={tabValue}
                                                label="Equity"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form} 
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=SocioEconomicStatus"
                                                id="SocioEconomicStatusID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Socio Economic Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=HomeLanguage"
                                                id="HomeLanguageID"
                                                required
                                                parentId={0}
                                                key={tabValue}
                                                label="Home Language"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form} 
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=CitizenResidentialStatus"
                                                id="CitizenResidentialStatusID"
                                                required
                                                parentId={0}
                                                key={tabValue}
                                                label="Citizen Residential Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form} 
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=Nationality"
                                                id="NationalityID"
                                                required
                                                parentId={0}
                                                key={tabValue}
                                                label="Nationality"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form} 
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="HighestEducation"
                                                parentId={0}
                                                key={tabValue}
                                                required={true}
                                                label="Highest Education"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="CurrentOccupation"
                                                parentId={0}
                                                key={tabValue}

                                                label="Current Occupation"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                required
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="YearsInOccupation"
                                                parentId={0}
                                                key={tabValue}

                                                label="Year In Occupation"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                required
                                                validationRegex={/^((?!0)\d{1,18}|0|\.\d{1,2})($|\.$|\.\d{1,2}$)|^[0-9]*$/g}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="Experience"
                                                parentId={0}
                                                key={tabValue}

                                                label="Experience"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                required
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=ImmigrantStatus"
                                                id="ImmigrantStatusID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Immigrant Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="TelephoneNumber"
                                                parentId={1}
                                                key={tabValue}
                                                label="Telephone Number"
                                                validationRegex="^$|\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                className="w-100"
                                                form={form} 
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="CellPhoneNumber"
                                                required
                                                parentId={1}
                                                key={tabValue}
                                                label="Cell Phone Number"
                                                validationRegex="^$|\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                className='{classes.textField} w-100'
                                                form={form} 
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="FaxNumber"
                                                parentId={1}
                                                key={tabValue}
                                                label="Fax Number"
                                                validationRegex="^$|\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                className="w-100"
                                                form={form} 
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="EMail"
                                                required
                                                parentId={1}
                                                key={tabValue}
                                                label="E Mail"
                                                validationRegex="^(?!.*unknown)[a-zA-Z0-9_\-\.]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                                className='{classes.textField} w-100'
                                                form={form} 
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=HealthFunctioningRating"
                                                id="SeeingID"
                                                key={tabValue}
                                                label="Seeing"
                                                className="w-100"
                                                required={true}
                                                parentId={2}
                                                form={form}
                                                disabled={!props.editPermission} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=HealthFunctioningRating"
                                                id="HearingID"
                                                required={true}
                                                parentId={2}
                                                key={tabValue}
                                                label="Hearing	"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=HealthFunctioningRating"
                                                id="CommunicatingID"
                                                key={tabValue}
                                                parentId={2}
                                                required={true}
                                                label="Communicating"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=HealthFunctioningRating"
                                                id="WalkingID"
                                                parentId={2}
                                                required={true}
                                                key={tabValue}
                                                label="Walking"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=HealthFunctioningRating"
                                                id="RememberingID"
                                                parentId={2}
                                                required={true}
                                                key={tabValue}
                                                label="Remembering"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=HealthFunctioningRating"
                                                id="SelfcareID"
                                                parentId={2}
                                                required={true}
                                                key={tabValue}
                                                label="Selfcare"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission} />
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={3}>
                            <table cellPadding="2" width="70%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomCheckbox
                                                id="UsePhysicalAsPostal"
                                                key={tabValue}
                                                form={form}
                                                onChange={handleUsePhysicalAddressForPostal}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Use Physical Address for Postal Address?"
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>
                                            <GPSCoordinatesAPI
                                                id="Address"
                                                key={tabValue}
                                                className="w-100"
                                                label="Address"
                                                form={form}
                                                updateGPSAddress={updateGPSAddress}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="GPSCoordinates"
                                                key={tabValue}
                                                label="GPS Coordinates"
                                                className="w-100"
                                                form={form}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <CustomTextField
                                                parentId={3}
                                                id="PhysicalAddress1"
                                                required
                                                key={tabValue}
                                                label="Physical Address 1"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                validationRegex="(^(?!.*\b([Uu][Nn][Kk][Nn][Oo][Ww][Nn]|[Nn][Oo][Nn][Ee]|[Nn][Aa])\b)[^-\/]*$)"
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="PostalAddressLine1"
                                                key={tabValue}
                                                required
                                                label="Postal Address Line 1"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                validationRegex="(^(?!.*\b([Uu][Nn][Kk][Nn][Oo][Ww][Nn]|[Nn][Oo][Nn][Ee]|[Nn][Aa])\b)[^-\/]*$)"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                parentId={3}
                                                id="PhysicalAddress2"
                                                key={tabValue}
                                                required
                                                label="Physical Address 2"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                validationRegex="(^(?!.*\b([Uu][Nn][Kk][Nn][Oo][Ww][Nn]|[Nn][Oo][Nn][Ee]|[Nn][Aa])\b)[^-\/]*$)"
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="PostalAddressLine2"
                                                required
                                                key={tabValue}
                                                label="Postal Address Line 2"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                validationRegex="(^(?!.*\b([Uu][Nn][Kk][Nn][Oo][Ww][Nn]|[Nn][Oo][Nn][Ee]|[Nn][Aa])\b)[^-\/]*$)"
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <CustomTextField
                                                parentId={3}
                                                id="PhysicalAddress3"
                                                key={tabValue}
                                                label="Physical Address 3"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                validationRegex="(^(?!.*\b([Uu][Nn][Kk][Nn][Oo][Ww][Nn]|[Nn][Oo][Nn][Ee]|[Nn][Aa])\b)[^-\/]*$)"
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="PostalAddressLine3"
                                                key={tabValue}
                                                label="Postal Address Line 3"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                validationRegex="(^(?!.*\b([Uu][Nn][Kk][Nn][Oo][Ww][Nn]|[Nn][Oo][Nn][Ee]|[Nn][Aa])\b)[^-\/]*$)"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <AddressCodeItem
                                                id="PhysicalCode"
                                                required={true}
                                                addressId="Physical"
                                                value={physicalCodeValue}
                                                key={tabValue}
                                                validationRegex={/^[0-9]{4,5}$/g}
                                                validationMessage="The code must be 4 or 5 digits long"
                                                provinceId={!utils.isNull(form.values) ? form.values.PhysicalProvinceID : null}
                                                municipalityId={!utils.isNull(form.values) ? form.values.PhysicalMunicipalityID : null}
                                                districtId={!utils.isNull(form.values) ? form.values.PhysicalDistrictID : null}
                                                urbanRuralId={!utils.isNull(form.values) ? form.values.PhysicalUrbanRuralID : null}
                                                suburbId={!utils.isNull(form.values) ? form.values.PhysicalSuburbID : null}
                                                cityId={!utils.isNull(form.values) ? form.values.PhysicalCityID : null}
                                                className="w-100"
                                                label="Physical Code"
                                                hasMunicipalityID={true}
                                                hasProvinceID={true}
                                                hasCityID={true}
                                                hasDistrictID={false}
                                                hasUrbanRuralID={true}
                                                hasSuburbID={true}
                                                form={form}
                                            />
                                        </td>

                                        <td>
                                            <AddressCodeItem
                                                id="PostalCode"
                                                required={true}
                                                addressId="Postal"
                                                value={postalCodeValue}
                                                key={tabValue}
                                                validationRegex={/^[0-9]{4,5}$/g}
                                                validationMessage="The code must be 4 or 5 digits long"
                                                provinceId={!utils.isNull(form.values) ? form.values.PostalProvinceID : null}
                                                municipalityId={!utils.isNull(form.values) ? form.values.PostalMunicipalityID : null}
                                                districtId={!utils.isNull(form.values) ? form.values.PostalDistrictID : null}
                                                urbanRuralId={!utils.isNull(form.values) ? form.values.PostalUrbanRuralID : null}
                                                suburbId={!utils.isNull(form.values) ? form.values.PostalSuburbID : null}
                                                cityId={!utils.isNull(form.values) ? form.values.PostalCityID : null}
                                                className="w-100"
                                                label="Postal Code"
                                                hasMunicipalityID={true}
                                                hasProvinceID={true}
                                                hasCityID={true}
                                                hasDistrictID={false}
                                                hasUrbanRuralID={true}
                                                hasSuburbID={true}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={4}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="ParentPersonID"
                                                label="Parent Person"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <PersonSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerdetails/GetPersonParent"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("ParentPersonID", value)
                                                        props.onChangePersonIDHandler(form, value, null);
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ParentPersonFirstName"
                                                label="First Name"
                                                className="w-100"
                                                required={!utils.isNull(form.values['ParentPersonIDNo']) ? form.values['ParentPersonIDNo'] !== "" : false}
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ParentPersonMiddleName"
                                                label="Middle Name"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ParentPersonSurname"
                                                label="Surname"
                                                className="w-100"
                                                required={!utils.isNull(form.values['ParentPersonIDNo']) ? form.values['ParentPersonIDNo'] !== "" : false}
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=Title"
                                                id="ParentPersonTitleID"
                                                label="Title"
                                                className="w-100"
                                                required={!utils.isNull(form.values['ParentPersonIDNo']) ? form.values['ParentPersonIDNo'] !== "" : false}
                                                allowEmptyValue={true}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=AlternateIDType"
                                                id="ParentPersonAlternateIDTypeID"
                                                parentId={0}
                                                required={!utils.isNull(form.values['ParentPersonIDNo']) ? form.values['ParentPersonIDNo'] !== "" : false}
                                                key={tabValue}
                                                label="Alternate ID Type"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ParentPersonIDNo"
                                                key={tabValue}
                                                parentId={0}
                                                label="ID Number"
                                                className="w-100"
                                                required={!utils.isNull(form.values['ParentPersonSurname']) ? form.values['ParentPersonSurname'] !== "" : false}
                                                form={form}
                                                disabled={!props.editPermission || !utils.isNull(form.values['ParentPersonID']) ? form.values['ParentPersonID'] > 0 : false}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ParentPersonInitials"
                                                key={tabValue}
                                                parentId={0}
                                                label="Initials"
                                                className="w-100"
                                                required={!utils.isNull(form.values['ParentPersonIDNo']) ? form.values['ParentPersonIDNo'] !== "" : false}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="ParentPersonDateofBirth"
                                                parentId={0}
                                                key={tabValue}
                                                label="Date of Birth"
                                                className='{classes.textField} w-100'
                                                required={!utils.isNull(form.values['ParentPersonIDNo']) ? form.values['ParentPersonIDNo'] !== "" : false}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=Gender"
                                                id="ParentPersonGenderID"
                                                parentId={0}
                                                key={tabValue}
                                                label="Gender"
                                                className="{classes.select} w-100"
                                                required={!utils.isNull(form.values['ParentPersonIDNo']) ? form.values['ParentPersonIDNo'] !== "" : false}
                                                allowEmptyValue={true}
                                                InputProps={{
                                                    disabled: !props.editPermission
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=Equity"
                                                id="ParentPersonEquityID"
                                                required={!utils.isNull(form.values['ParentPersonIDNo']) ? form.values['ParentPersonIDNo'] !== "" : false}
                                                parentId={0}
                                                key={tabValue}
                                                label="Equity"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=SocioEconomicStatus"
                                                id="ParentPersonSocioEconomicStatusID"
                                                parentId={0}
                                                required={!utils.isNull(form.values['ParentPersonIDNo']) ? form.values['ParentPersonIDNo'] !== "" : false}
                                                key={tabValue}
                                                label="Socio Economic Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=HomeLanguage"
                                                id="ParentPersonHomeLanguageID"
                                                required={!utils.isNull(form.values['ParentPersonIDNo']) ? form.values['ParentPersonIDNo'] !== "" : false}
                                                parentId={0}
                                                key={tabValue}
                                                label="Home Language"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=CitizenResidentialStatus"
                                                id="ParentPersonCitizenResidentialStatusID"
                                                required={!utils.isNull(form.values['ParentPersonIDNo']) ? form.values['ParentPersonIDNo'] !== "" : false}
                                                parentId={0}
                                                key={tabValue}
                                                label="Citizen Residential Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=Nationality"
                                                id="ParentPersonNationalityID"
                                                required={!utils.isNull(form.values['ParentPersonIDNo']) ? form.values['ParentPersonIDNo'] !== "" : false}
                                                parentId={0}
                                                key={tabValue}
                                                label="Nationality"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ParentPersonTelephoneNumber"
                                                parentId={1}
                                                key={tabValue}
                                                label="Telephone Number"
                                                validationRegex="^$|\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ParentPersonCellPhoneNumber"
                                                required={!utils.isNull(form.values['ParentPersonIDNo']) ? form.values['ParentPersonIDNo'] !== "" : false}
                                                parentId={1}
                                                key={tabValue}
                                                label="Cell Phone Number"
                                                validationRegex="^$|\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ParentPersonFaxNumber"
                                                parentId={1}
                                                key={tabValue}
                                                label="Fax Number"
                                                validationRegex="^$|\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ParentPersonEMail"
                                                required={!utils.isNull(form.values['ParentPersonIDNo']) ? form.values['ParentPersonIDNo'] !== "" : false}
                                                parentId={1}
                                                key={tabValue}
                                                label="E Mail"
                                                validationRegex="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomCheckbox
                                                id="ParentPersonUsePhysicalAsPostal"
                                                key={tabValue}
                                                form={form}
                                                onChange={handleUsePhysicalAddressForPostalParent}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Use Physical Address for Postal Address?"
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>
                                            <CustomTextField
                                                parentId={3}
                                                id="ParentPersonPhysicalAddress1"
                                                required={!utils.isNull(form.values['ParentPersonIDNo']) ? form.values['ParentPersonIDNo'] !== "" : false}
                                                key={tabValue}
                                                label="Physical Address 1"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ParentPersonPostalAddressLine1"
                                                key={tabValue}
                                                required={!utils.isNull(form.values['ParentPersonIDNo']) ? form.values['ParentPersonIDNo'] !== "" : false}
                                                label="Postal Address Line 1"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                parentId={3}
                                                id="ParentPersonPhysicalAddress2"
                                                key={tabValue}
                                                required={!utils.isNull(form.values['ParentPersonIDNo']) ? form.values['ParentPersonIDNo'] !== "" : false}
                                                label="Physical Address 2"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ParentPersonPostalAddressLine2"
                                                required={!utils.isNull(form.values['ParentPersonIDNo']) ? form.values['ParentPersonIDNo'] !== "" : false}
                                                key={tabValue}
                                                label="Postal Address Line 2"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <CustomTextField
                                                parentId={3}
                                                id="ParentPersonPhysicalAddress3"
                                                key={tabValue}
                                                label="Physical Address 3"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ParentPersonPostalAddressLine3"
                                                key={tabValue}
                                                label="Postal Address Line 3"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <AddressCodeItem
                                                id="ParentPersonPhysicalCode"
                                                required={!utils.isNull(form.values['ParentPersonIDNo']) ? form.values['ParentPersonIDNo'] !== "" : false}
                                                addressId="ParentPhysical"
                                                value={parentphysicalCodeValue}
                                                key={tabValue}
                                                validationRegex={/^[0-9]{4,5}$/g}
                                                validationMessage="The code must be 4 or 5 digits long"
                                                provinceId={!utils.isNull(form.values) ? form.values.ParentPersonPhysicalProvinceID : null}
                                                municipalityId={!utils.isNull(form.values) ? form.values.ParentPersonPhysicalMunicipalityID : null}
                                                districtId={!utils.isNull(form.values) ? form.values.ParentPersonPhysicalDistrictID : null}
                                                urbanRuralId={!utils.isNull(form.values) ? form.values.ParentPersonPhysicalUrbanRuralID : null}
                                                suburbId={!utils.isNull(form.values) ? form.values.ParentPersonPhysicalSuburbID : null}
                                                cityId={!utils.isNull(form.values) ? form.values.ParentPersonPhysicalCityID : null}
                                                className="w-100"
                                                label="Physical Code"
                                                hasMunicipalityID={true}
                                                hasProvinceID={true}
                                                hasCityID={true}
                                                hasDistrictID={false}
                                                hasUrbanRuralID={true}
                                                hasSuburbID={true}
                                                form={form}
                                            />
                                        </td>

                                        <td>
                                            <AddressCodeItem
                                                id="ParentPersonPostalCode"
                                                required={!utils.isNull(form.values['ParentPersonIDNo']) ? form.values['ParentPersonIDNo'] !== "" : false}
                                                addressId="ParentPostal"
                                                value={parentpostalCodeValue}
                                                key={tabValue}
                                                validationRegex={/^[0-9]{4,5}$/g}
                                                validationMessage="The code must be 4 or 5 digits long"
                                                provinceId={!utils.isNull(form.values) ? form.values.ParentPersonPostalProvinceID : null}
                                                municipalityId={!utils.isNull(form.values) ? form.values.ParentPersonPostalMunicipalityID : null}
                                                districtId={!utils.isNull(form.values) ? form.values.ParentPersonPostalDistrictID : null}
                                                urbanRuralId={!utils.isNull(form.values) ? form.values.ParentPersonPostalUrbanRuralID : null}
                                                suburbId={!utils.isNull(form.values) ? form.values.ParentPersonPostalSuburbID : null}
                                                cityId={!utils.isNull(form.values) ? form.values.ParentPersonPostalCityID : null}
                                                className="w-100"
                                                label="Postal Code"
                                                hasMunicipalityID={true}
                                                hasProvinceID={true}
                                                hasCityID={true}
                                                hasDistrictID={false}
                                                hasUrbanRuralID={true}
                                                hasSuburbID={true}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={5}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td width="50%">
                                            <LookupField
                                                id="SchoolEMISID"
                                                parentId={5}
                                                required
                                                label="Last School EMIS"
                                                className="w-100"
                                                form={form} 
                                                disabled={!props.editPermission}
                                            >
                                                <SchoolEMISSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerdetails/GetLastSchoolEMIS"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("SchoolEMISID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>

                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=LastSchoolYearID"
                                                id="LastSchoolYearID"
                                                parentId={5}
                                                required
                                                key={tabValue}
                                                label="Last School Year"
                                                className="{classes.select} w-100"
                                                form={form} 
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="STATSSAAreaCodeID"
                                                parentId={5}
                                                label="STATSSA Area Code"
                                                className="w-100"
                                                required
                                                form={form} 
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                disabled={props.disableFields}
                                            >
                                                <STATSSAAreaCodeSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerdetails/GetSTATSSAAreaCode"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("STATSSAAreaCodeID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=POPIActStatus"
                                                id="POPIActStatusID"
                                                parentId={5}
                                                key={tabValue}
                                                required
                                                label="POPI Act Status"
                                                className="{classes.select} w-100"
                                                form={form} 
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <CustomDatePicker
                                                id="POPIActStatusDate"
                                                required
                                                parentId={5}
                                                key={tabValue}
                                                label="POPI Act Status Date"
                                                className='{classes.textField} w-100'
                                                form={form} 
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={6}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ImpactofLearning"
                                                key={tabValue}
                                                parentId={5}
                                                label="Impact of Learning"
                                                className="w-100"
                                                multiline
                                                styles={styles()}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission} />
                                        </td>
                                    </tr>
                                    <br />
                                </tbody>
                            </table>
                            {showImpactofLearningDialog && (
                                <div>
                                    <LearnerImpactImageDialog
                                        open={imagesDialogOpen}
                                        closeHandler={(save, cancel) => postUploadHandler(save, cancel)}
                                        postUrl={ILconfig.postUrls.documents}
                                        EntityID={form.values["IDNo"]}
                                        buttonLabel={"Upload Photo"}
                                        ImpactofLearning={form.values["ImpactofLearning"]}
                                        fileName={imageFileName}
                                        _setFilename={(name) => { setImageFileName(name) }}
                                    />
                                </div>
                            )}
                            <p></p>
                            {showTable == true && (
                                <ImpactOfLearningDocuments handleCell={(rowid, headCell) => handleCellSelect(rowid, headCell, "i")}
                                    documentsDataUrl={ImgURL} moduleName={ILconfig.moduleName} selection={form.values["IDNo"]} />

                            )}
                        </TabPanel>
                        <TabPanel value={tabValue} index={7}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="QualificationID"
                                                label="Qualification"
                                                className="w-100"
                                                parentId={4}
                                                form={form} 
                                                disabled={!props.editPermission}
                                                onChange={getDescription(form.values["QualificationID"], form)}
                                            >
                                                <QualificationSetupSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerdetails/LearnerQualificationSearch"
                                                    onLookupValueChange={(values) => form.setLookupSelection("QualificationID", values)} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="QualificationTitle"
                                                key={tabValue}
                                                parentId={5}
                                                label="Qualification Name"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="DateAchieved"
                                                key={tabValue}
                                                label="Date Achieved"
                                                parentId={5}
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <LookupField
                                                id="OFOOccupationID"
                                                label="Linked OFO Code"
                                                className="w-100"
                                                onChange={getOFODescription(form.values["OFOOccupationID"], form)}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            >
                                                <OFOSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnerinternship/getofooccupationsearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("OFOOccupationID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="LinkedOFODescription"
                                                key={tabValue}
                                                parentId={5}
                                                label="Linked OFO Description"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={8}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=HighestEducationLevel"
                                                id="HighestEducationLevelID"
                                                parentId={6}
                                                key={tabValue}
                                                label="Highest Education Level"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form} 
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="EducationTitle"
                                                key={tabValue}
                                                parentId={6}
                                                label="Education Title / Description"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission} />
                                        </td>
                                    </tr>
                                        <tr>
                                            <td>
                                                <LookupField
                                                    id="InstitutionID"
                                                    label="Institution"
                                                    className="w-100"
                                                    form={form} 
                                                    disabled={!props.editPermission}
                                                >
                                                <InstitutionSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnerinternship/GetInstitutionSearch'
                                                        onLookupValueChange={(value) => {
                                                            form.setLookupSelection("InstitutionID", value)
                                                        }} autoFetchData={true} selection={props.selection} />
                                                </LookupField>
                                            </td>
                                    </tr>
                                    <br/>
                                </tbody>
                            </table>
                           {showLeanerDialog && (
                             <div>
                                <LearnerDocumentDialog
                                    open={documentsDialogOpen}
                                    closeHandler={(save, cancel) => postUploadHandler(save, cancel)}
                                    postUrl={config.postUrls.documents}
                                    EntityID={form.values["IDNo"]}
                                    buttonLabel={"Evidence of Qualification"}
                                    EducationTitle={form.values["EducationTitle"]}
                                    HighestEducation={form.values["HighestEducationLevelID"]}
                                    Institute={form.values["InstitutionID"]}
                                    fileName={leanerFileName}
                                    _setFilename={(name)=>{setLeanerFileName(name)}}
                                />
                            </div>  
                           )}
                            
                                {/* <Button onClick={setDialogOpen} color="primary">
                                    Save Educational History Record
                                         </Button> */}
                            <p></p>
                            {showTable == true && (
                                <EducationalHistoryDocuments handleCell={(rowid, headCell) => handleCellSelect(rowid, headCell, "e")}
                                    documentsDataUrl={DocURL} moduleName={config.moduleName} selection={form.values["IDNo"]} />
                                
                             )}
                               
                            
                            
                           
                        </TabPanel>
                        <TabPanel value={tabValue} index={9}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <br />
                                    Experiential Learning:
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ExperienceWork"
                                                key={tabValue}
                                                parentId={7}
                                                label="Experience Work"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="ExperienceWorkDateFrom"
                                                key={tabValue}
                                                label="Date From"
                                                parentId={7}
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="ExperienceWorkDateTo"
                                                key={tabValue}
                                                label="Date To"
                                                parentId={7}
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="NatureOfWork"
                                                key={tabValue}
                                                parentId={7}
                                                label="Nature Of Work"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <br />
                                    Contactable Reference:
                                    <tr>
                                          <td>
                                              <SelectItem
                                                    dataUrl="api/sims/learnerdetails/GetLookupList?listId=Title"
                                                    id="ReferenceTitleID"
                                                    label="Title"
                                                    className="w-100"
                                                parentId={7}
                                                    allowEmptyValue={true}
                                                    InputProps={{
                                                        readOnly: props.disableFields
                                                    }}
                                                    form={form} 
                                                    disabled={!props.editPermission} />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                id="ReferenceName"
                                                label="Name"
                                                parentId={7}
                                                    className="w-100"
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form} 
                                                    disabled={!props.editPermission}
                                                />
                                            </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ReferenceSurname"
                                                label="Surname"
                                                parentId={7}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ReferenceContactNumber"
                                                parentId={7}
                                                key={tabValue}
                                                label="Contact Number"
                                                validationRegex="^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                className='{classes.textField} w-100'
                                                form={form} 
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <br />
                                    Reference:
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="WhereExperientialWorWasUndertaken"
                                                label="Employer where experiential work was undertaken"
                                                parentId={7}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <br />
                                </tbody>
                            </table>
                                <div>
                                    {showExperientialDialog && (
<div>
                                    <ExperientialLearningDialog
                                        open={ELdocumentsDialogOpen}
                                        closeHandler={(save, cancel) => postUploadHandler(save, cancel)}
                                        postUrl={ELconfig.postUrls.documents}
                                            EntityID={form.values["IDNo"]}
                                        buttonLabel={"Reference Letter"}
                                        ExperienceWork={form.values["ExperienceWork"]}
                                        ExperienceWorkDateFrom={form.values["ExperienceWorkDateFrom"]}
                                        ExperienceWorkDateTo={form.values["ExperienceWorkDateTo"]}
                                        ReferenceTitleID={form.values["ReferenceTitleID"]}
                                        NatureOfWork={form.values["NatureOfWork"]}
                                        ReferenceName={form.values["ReferenceName"]}
                                        ReferenceSurname={form.values["ReferenceSurname"]}
                                        ReferenceContactNumber={form.values["ReferenceContactNumber"]}
                                        WhereExperientialWorWasUndertaken={form.values["WhereExperientialWorWasUndertaken"]}
                                    
                                        fileName={experientFileName}
                                        _setFilename={(name)=>{setExperientFileName(name)}}
                                    />
                                </div>

                                    )}
                                
                                    {/* <Button onClick={setelDialogOpen} color="primary">
                                    Save Experiential Learning Record
                                         </Button> */}
                                <p></p>
                                    <ExperientialLearningDocuments  handleCell={(rowid, headCell) => handleCellSelect(rowid, headCell, "l")}
                                    documentsDataUrl={elDocURL} moduleName={ELconfig.moduleName} selection={form.values["IDNo"]} />
                                    
                            </div>
                            <br />
                                
                        </TabPanel>
                        <TabPanel value={tabValue} index={10}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                 <tr>
                                    <td>
                                        <CustomTextField
                                            id="EmploymentHistoryPosition"
                                            label="Position"
                                            parentId={8}
                                            className="w-100"
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form} 
                                            disabled={!props.editPermission}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="EmploymentHistoryCompany"
                                            label="Company"
                                            parentId={8}
                                            className="w-100"
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form} 
                                            disabled={!props.editPermission}
                                        />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="EmploymentHistoryDuration"
                                                label="Duration"
                                                parentId={8}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="EmploymentHistoryLocation"
                                                label="Location"
                                                parentId={8}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="EmploymentHistoryDescription"
                                                label="Description"
                                                parentId={8}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <br />
                                </tbody>
                            </table>
                            <div>
                              
                              {showEmploymentHistoryDialog && 
                              (

                                  <div>
                                <EmploymentHistoryDialog
                                    open={EHdocumentsDialogOpen}
                                    closeHandler={(save, cancel) => postUploadHandler(save, cancel)}
                                    postUrl={EHconfig.postUrls.documents}
                                    EntityID={form.values["IDNo"]}
                                    Position={form.values["EmploymentHistoryPosition"]}
                                    Company={form.values["EmploymentHistoryCompany"]}
                                    Duration={form.values["EmploymentHistoryDuration"]}
                                    Location={form.values["EmploymentHistoryLocation"]}
                                    Description={form.values["EmploymentHistoryDescription"]}
                                
                                    fileName={employmentFileName}
                                    _setFilename={(name)=>{setEmploymentFileName(name)}}
                                />
                            </div> 
                              )
                              }
                               
                                <p></p>
                                <EmploymentHistoryDocuments handleCell={(rowid, headCell) => handleCellSelect(rowid, headCell, "h")}
                                    documentsDataUrl={ehDocURL} moduleName={EHconfig.moduleName} selection={form.values["IDNo"]} />

                            </div>
                            <br />
                            
                        </TabPanel>
                        <TabPanel value={tabValue} index={11}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <Button onClick={setehDialogOpen} color="primary">
                                        Upload Photo
                                         </Button>
                                    <p></p>
                                    <Button color="primary">
                                        Download CV
                                         </Button>
                                    <p></p>
                                </tbody>
                            </table>
                        </TabPanel>
                        
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})

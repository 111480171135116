import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { LookupField } from '../../../widgets/LookupField';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import BursarySetupSearch from '../../program/Bursary/BursarySetupSearch';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from "../../../ViewUtils";
import BursaryInstitutionSearch from '../../bursaryApplication/BursaryInstitutionSearch';
import QualificationSearch from '../../bursaryApplication/QualificationSearch';


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function GetIDFromValue(value) {
    if (value !== undefined && value !== null) {
        return value.Id
    }
    else {
        return "0"
    }
}

const utils = new ViewUtils();
export const LearnerBursaryForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [physicalCodeValue, setPhysicalCodeValue] = React.useState(null);
    const [dependency, setDependency] = React.useState(props.dependency);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    React.useEffect(() => {
        setDependency(props.dependency);
    }, [props.dependency]);


    function OtherDisciplineCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Other") {
                return true
            }
            else {
                return false
            }
        }
    }

    return (
        <EntityForm ref={ref} values={props.data} viewId="BursarySetup" dataURL="api/sims/learnerbursary/GetLearnerLinkedBursaryDetails"
            entityLookupId={props.id} org="saseta" useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="learnerBursaryForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Bursary Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="BursaryID"
                                                label="Bursary"
                                                className="w-100"
                                                disabled={true}
                                                form={form}
                                            >
                                                <BursarySetupSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnerbursary/BursarySearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("BursaryID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="BursaryStartDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="Bursary Start Date"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="BursaryEndDate"
                                                validationGroup="registrationDates"
                                                validator={{
                                                    script: (formValues) => {
                                                        return !utils.isNull(formValues)
                                                            && !utils.isNull(formValues.BursaryStartDate)
                                                            && formValues.BursaryStartDate.getTime() < formValues.BursaryEndDate.getTime()
                                                    }, message: "Start date must be before the end date"
                                                }}
                                                key={tabValue}
                                                label="Bursary End Date"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <LookupField
                                                id="BursaryInstitutionID"
                                                required
                                                label="University / TVET"
                                                className="w-100"
                                                form={form}
                                                disabled={true}
                                            >
                                                <BursaryInstitutionSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerbursary/GetBursaryInstitution"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("BursaryInstitutionID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerbursary/GetLookupList?listId=BursaryDiscipline"
                                                id="BursaryDisciplineID"
                                                label="Discipline"
                                                className="w-100"
                                                required={true}
                                                form={form}
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={true} />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="OtherBursaryDiscipline"
                                                key={1}
                                                parentId={1}
                                                required={OtherDisciplineCheck(form.values["BursaryDisciplineID"])}
                                                label="Other Discipline"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="BursaryQualificationID"
                                                required
                                                key={4}
                                                parentId={4}
                                                label="Qualification"
                                                className="w-100"
                                                form={form}
                                                disabled={true}
                                            >
                                                <QualificationSearch showTitle={true} mode='lookup' checkRefreshToken={!props.IsExternal} dataUrl="api/sims/learnerbursary/GetBursaryQualification"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("BursaryQualificationID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerbursary/GetLookupList?listId=BursaryQualificationType"
                                                id="BursaryQualificationTypeID"
                                                label="Qualification Type"
                                                disabled={true}
                                                className="w-100"
                                                allowEmptyValue={true}
                                                form={form} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerbursary/GetLookupList?listId=BursaryFollowingYrYearOfStudy"
                                                id="FollowingYrYearOfStudyID"
                                                required={true}
                                                label="Year Of Study"
                                                className="w-100"
                                                form={form}
                                                disabled={true} />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="StatusEffectiveDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="Status Effective Date"
                                                disabled={true}
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    {
                                    (!props.hideFields) ?
                                    <tr>
                                        <td>
                                            <SelectItem
                                                 dataUrl={'api/sims/learnerbursary/GetBusaryStatusByRole?parameter=[{"Name":"BursaryStatusID","Value":' + GetIDFromValue(form.values["BursaryStatusID"]) + '},{"Name":"BursaryID","Value":' + props.id + '}]'}
                                                id="BursaryStatusID"
                                                parentId={0}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !props.editPermission.Edit}
                                                key={tabValue}
                                                label="Bursary Status"
                                                className="{classes.select} w-100"
                                                required
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl={'api/sims/learnerbursary/GetLearnerInterventionGrantType?parameter=[{"Name":"GrantTypeID","Value":' + GetIDFromValue(form.values["GrantTypeID"]) + '}]'}
                                                id="GrantTypeID"
                                                required
                                                parentId={0}
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !props.editPermission.Edit}
                                                label="Grant Type"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                         </td>
                                    </tr>
                                        :
                                        ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="CaptureDate"
                                                key={tabValue}
                                                label="Capture Date"
                                                className='{classes.textField} w-100 '
                                                disabled
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="CapturedByUser"
                                                key={tabValue}
                                                label="Captured By User"
                                                disabled
                                                className="w-100 "
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="LastDateUpdated"
                                                key={tabValue}
                                                label="Last Date Updated"
                                                className='{classes.textField} w-100 '
                                                disabled
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="UpdatedByUser"
                                                key={tabValue}
                                                label="Last Updated By User"
                                                disabled
                                                className="w-100 "
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="RegistrationDate"
                                                key={tabValue}
                                                label="Registration Date"
                                                className='{classes.textField} w-100 '
                                                disabled
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="RegisteredBy"
                                                key={tabValue}
                                                label="Registered By"
                                                disabled
                                                className="w-100 "
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})

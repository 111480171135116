import React, { useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from "../../../ViewUtils";
import *  as InterventionSearch from './InterventionIndex';


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
export const AddEditTrancheForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const [firstRender, setFirstRender] = React.useState(true);
    useEffect(() => { if (firstRender) { setFirstRender(false); } }, [firstRender]);
    const handleChange = (form, value) => {
        if (!firstRender) {
            RefreshProgramme(form, value);
        }
    };

    function handleSelectionCallbackMode(value) {
        let objectReference = {};
        objectReference.Id = value[0].Id;
        objectReference.Description = value[0].Description;
        objectReference.toString = () => { return value[0].Description };
        return objectReference
    }

    function InterventionTypeChange(value, form) {
        if (typeof value !== 'undefined' &&  value !== null) {
            if (value.Description === "Learnership") {
                var type = 'LearnershipSetupSearch';
                const ComponentToRender = InterventionSearch[type];
                return <ComponentToRender showTitle={false} mode='lookup'
                    onLookupValueChange={(value) => form.setLookupSelection("InterventionID", value)} dataUrl="api/sims/GrantSetup/LearnershipSearch" autoFetchData={false} selection={props.selection} />
            } else if (value.Description === "Skills Programme") {
                var type = 'SkillsProgramSetupSearch';
                const ComponentToRender = InterventionSearch[type];
                return <ComponentToRender showTitle={false} mode='lookup'
                    onLookupValueChange={(value) => form.setLookupSelection("InterventionID", value)} dataUrl="api/sims/GrantSetup/SkillsProgrammeSearch" autoFetchData={false} selection={props.selection}/>
            } else if (value.Description === "AET") {
                var type = 'AETSetupSearch';
                const ComponentToRender = InterventionSearch[type];
                return <ComponentToRender showTitle={false} mode='lookup'
                    onLookupValueChange={(value) => form.setLookupSelection("InterventionID", value)} dataUrl="api/sims/GrantSetup/AETSearch" autoFetchData={false} selection={props.selection}/>
            }
        }
    }

    function LookupValidation(value) {
        if (value !== undefined && value !== null) {
            return false
        }
        else {
            return true
        }
    }

    function InterventionTypeRender(intervention, checkBValue) {
        if (intervention !== null && checkBValue !== null) {
            if (typeof intervention !== 'undefined' && intervention !== null) {
                if (intervention.Description === "Learnership" ||
                    intervention.Description === "Skills Programme" ||
                    intervention.Description === "AET") {
                    if (checkBValue == false) { return false }
                    else { return true }
                }
                else { return true; }
            }
            else { return true; }
        } else if (intervention !== null && (checkBValue == null || checkBValue == undefined)) {
            if (intervention.Description === "Learnership" ||
                intervention.Description === "Skills Programme" ||
                intervention.Description === "AET") {
                return false;
            } else { return true; } }
        else { return true; }
    }

    function manageReqFlag(intervention, checkBValue) {
        if (intervention !== null && checkBValue !== null) {
            if (checkBValue == false) {
                if (intervention.Description === "Learnership" ||
                    intervention.Description === "Skills Programme" ||
                    intervention.Description === "AET") {
                    return true;
                } else { return false; }
            }
            else { return false; }
        } else if (intervention !== null && (checkBValue == null || checkBValue == undefined)) {
            if (intervention.Description === "Learnership" ||
                intervention.Description === "Skills Programme" ||
                intervention.Description === "AET") {
                return true;
            } else { return false; } }
        else { return false; }
    }

    useEffect(() => {

    }, [])

    function GetIDFromValue(value) {
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }

    function RefreshProgramme(form, TrancheCodeID) {
        if (TrancheCodeID !== undefined) {
            props.RefreshProgramme(form, TrancheCodeID);
        }
    }

    return (
        <EntityForm ref={ref} values={props.data} viewId="TrancheSetup" dataURL="api/sims/GrantSetup/GetTrancheSetupDetails"
            entityLookupId={props.id} org="saseta" useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="addEditTrancheForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Setup Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/GrantSetup/GetLookupList?listId=TrancheCode"
                                                id="TrancheCodeID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Tranche Code"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                required
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                onChange={(e) => handleChange(form, form.values["TrancheCodeID"])}
                                            />
                                        </td>
                                        <td>
                                            {
                                                (props.showProgramme) ?
                                            <SelectItem
                                                dataUrl={'api/sims/GrantSetup/GetTrancheCodeProgrammes?parameter=[{"Name":"TrancheCodeID","Value":' + GetIDFromValue(form.values["TrancheCodeID"]) + '}]'}
                                                id="ProgrammeID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Programme"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                required
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                    />
                                                    :
                                                    ""
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/GrantSetup/GetLookupList?listId=FinancialYear"
                                                id="FinancialYearID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Financial Year"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                required
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl={'api/sims/GrantSetup/GetSchedule?parameter=[{"Name":"TrancheCodeID","Value":' + GetIDFromValue(form.values["TrancheCodeID"]) + '}]'}
                                                id="ScheduleID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Schedule"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                required
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="TrancheBudget"
                                                key={tabValue}
                                                label="Tranche Budget"
                                                validationRegex={/^((?!0)\d{1,18}|0|\.\d{1,2})($|\.$|\.\d{1,2}$)/g}
                                                validationMessage="Please enter a valid amount."
                                                className="w-100 "
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})

import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import BatchSearchForm from './BatchSearchForm';
import SearchView from "../../../widgets/SearchView";
import withMenuPermission from "../../../widgets/withMenuPermission";
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from "../../../ViewUtils";
import AlertItem from "../../../widgets/AlertItem";

const utils = new ViewUtils();
const toolbarRef = React.createRef();

const headCells = [
    { id: 'ImportID', numeric: false, disablePadding: false, label: 'Batch Number' }
];


class BatchSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            model: {
                EmployerID: null,
                TrancheCodeID: null
            },
            parameters: {},
            keyUpSwitch: false,
            selectedBatch: null
        }

        this.toolbarConfig = {
            items: [
            ]
        };

    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelections = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedBatch: selection, Message: "" }
            )
        }
        else {
            this.setState({ selectedBatch: null, Message: "" }
            )
        }
    }

    //handleFormValueChange = (values) => {
    //    if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
    //        this.setState({ model: values, selectedGrant: null });
    //    }
    //}

    successCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    render() {

        return (
            <ViewContainer title={''}>
                <p className="breadcrumbs">
                    {"ETQA Reports > Batch Search"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <SearchView headCells={headCells} dataUrl="api/sims/ETQAReportsDetails/GetETQAReportsBatchSearch"
                    model={this.state.model} toolbarConfig={this.toolbarConfig} searchParameters={this.searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    toolbarRef={toolbarRef}
                    history={this.props.history}
                    searchLabel={"SEARCH"}
                    multiSelect={false}
                    handleObject={true}
                    autoFetchData={false}
                    descriptionProp="ImportID"
                    keyUpSwitch={this.state.keyUpSwitch}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    setSelection={(selection) => { this.handleSelections(selection) }}
                >
                    <BatchSearchForm data={this.state.model} controllerName='GenerateBatch'
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }} />
                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(BatchSearch));




import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import SearchView from "../../widgets/SearchView";
import WSPATREvaluationSheetSearchForm from './WSPATREvaluationSheetSearchForm';
import { ViewContainer } from "../../View.jsx";
import ViewUtils from '../../ViewUtils';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import WSPATREvaluationSheet from './WSPATREvaluationSheetForm';

const utils = new ViewUtils();
const toolbarRef = React.createRef();

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class WSPATREvalautionSheetSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            model: model,
            keyUpSwitch: false,
            selectedWSPATR: null,
            openPrintDialog: false,
            dataObject: null
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'download', label: '', icon: 'DownloadIcon', tooltip: 'Download Evaluation Sheet', disabled: true },
                    ]
                }
            ]
        }

        this.searchParameters =
            [
                { Name: "SDLNumber", Value: "" },
                { Name: "FinancialYearID", Value: "" },

            ]

        this.headCells =
            [
                { id: 'SDLNumber', numeric: false, disablePadding: true, label: 'SDL Number' },
                { id: 'CompanyName', numeric: false, disablePadding: true, label: 'Company Name' },
                { id: 'FinancialYear', numeric: false, disablePadding: true, label: 'Financial Year' },
                { id: 'WSPATRStatus', numeric: false, disablePadding: true, label: 'WSP/ATR Status' },

            ]

        let model =
        {
            SDLNumber: "",
            FinancialYearID: ""
        }
    }
    GetDefaultFinYear()
    {
        let url = "api/sims/WSPATREvaluationSheet/GetDefaultFinYearFind?Id=0";
        utils.invokeUrl(url, (response) => {

            this.setState(prevState => ({
                model: {
                    ...prevState.model,  
                    FinancialYearID: {Id : response.FinancialYearID.Id , Description : response.FinancialYearID.Description}
                }
            }));
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    componentDidMount()
    {
        this.GetDefaultFinYear()
    }
    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
                this.setState({ selectedWSPATR: selection[0].Id });
                toolbarRef.current.setButtonDisabled("download", !utils.isNull(this.state.dataObject) ? true : false); 
        }
        else {
            this.setState({ selectedWSPATR: null });
            toolbarRef.current.setButtonDisabled("download", true);
        }
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("download" === buttonId) {
            this.getData();
        }      
    }

    CloseForm = () => {
        this.setState({
            openPrintDialog: false
        });
    };

    getData= () => {
        let url = "api/sims/WSPATREvaluationSheet/GetWSPATREvaluationReport?WSPATRID=" + this.state.selectedWSPATR;
        utils.invokeUrl(url, (response) => {
            this.successCallback(response);
        },
        (response) => {
            this.error(response);
        },
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    successCallback(response) {
        this.setState({ dataObject: response,openPrintDialog: true});
    }
    error(response) {
        this.setState({ dataObject: null, openPrintDialog: false });
    }

    render() {
        return (

            <ViewContainer title={""}>
                <SearchView headCells={this.headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/WSPATREvaluationSheet/getsearch"}
                    model={this.state.model}
                    title='WSP/ATR EvalautionSheet'
                    toolbarConfig={this.toolbarConfig}
                    searchParameters={this.searchParameters}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    multiSelect={false}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="LegalName"
                    selectionCallbackMode={this.props.selectionCallbackMode}
                    history={this.props.history}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                    toolbarRef={toolbarRef}
                    autoFetchData={false}
                >
                    <WSPATREvaluationSheetSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>

                <Dialog open={this.state.openPrintDialog} onClose={this.CloseForm} TransitionComponent={Transition} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description" maxWidth="sm">
                    <WSPATREvaluationSheet data={this.state.dataObject}>
                    </WSPATREvaluationSheet>
                    <DialogActions>
                        <Button onClick={this.CloseForm} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog> 
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(WSPATREvalautionSheetSearch)
);



import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import UnitStandardsTable from '../UnitStandardsTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import UnitStandardSetupSearch from './UnitStandardSetupSearch';
import ViewUtils from "./../../ViewUtils";
import { ViewContainer } from "./../../View.jsx";
import Prompt from '../../widgets/Prompt';
import AlertItem from "../../widgets/AlertItem";
import LinkDialog from "../LinkDialog";
import withMenuPermission from "../../widgets/withMenuPermission";

const utils = new ViewUtils();

const headCells = [
    { id: 'SAQAQualificationID', numeric: false, disablePadding: true, label: 'SAQA Unit Standard ID' },
    { id: 'SAQAQualificationTitle', numeric: false, disablePadding: true, label: 'SAQA Unit Standard Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' },
    { id: 'UnitStandardType', numeric: false, disablePadding: true, label: 'Unit Standard Type' }
];

const toolbarRef = React.createRef();
class LearnershipUnitStandardSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            linkDialogOpen: false,
            selectedLinkType: null,
            selectedLinkStandards: null,
            selectedUnitStandard: null,
            propmtOpen: false,
            promptTitle: "Unlink Unit Standard From Learnership",
            promptContent: "Are you sure you want to unlink this unit standard from the learnership?",
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "LearnershipID", Value: props.location.state.LearnershipID }],
            LearnershipType: props.location.state.LearnershipType
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        {
                            contextMenu: [
                                { id: 1, description: "Core" },
                                { id: 2, description: "Fundamental" },
                                { id: 3, description: "Elective" },
                                { id: 4, description: "Compulsory Elective" }
                            ],
                            id: 'link', label: 'Link', contextIcon: 'LinkIcon', tooltip: 'Link the selected unit standard to the learnership', width: "60px", visible: this.props.menuPermissionItem.Add
                        },
                        { id: 'unlink', label: 'Un-Link', icon: 'LinkOffIcon', tooltip: 'Un-link the selected unit standard from the learnership', visible: this.props.menuPermissionItem.Delete, disabled: utils.isNull(this.state.selectedUnitStandard) }
                    ]
                }
            ]
        };
    }
   

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/LearnershipSetup',
                state: { selection: this.props.location.state.LearnershipID, id: this.props.location.state.LearnershipID }
            });
        } else if ("unlink" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    successCallback = (response) => {
        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedUnitStandard: null,
                selectedLinkType: null,
                selectedLinkStandards: null,
                message: response.Message, messageStyle: response.MessageType.toLowerCase()
            })
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
        else if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    unlinkUnitstandard(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("unlink", true);
        })
        let fullUrl = `${url}?id=${this.state.selectedUnitStandard}&learnershipId=${this.props.location.state.LearnershipID}`
        utils.invokeUrlDelete(fullUrl, this.successCallback, null);
    }

    handleToolbarButtonMenuClick(menu) {
        this.setState({ linkDialogOpen: true, selectedLinkType: menu });
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false, selectedUnitStandard: null
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({
                selectedUnitStandard: selection[0].Id,
                message: "",
                messageStyle: ""
            },
                () => {
                    toolbarRef.current.setButtonDisabled("unlink", parseInt(this.state.LearnershipType) === 3)
                }
            )
        }
        else {
            this.setState({
                selectedUnitStandard: null,
                message: "",
                messageStyle: ""
            },
                () => {
                    toolbarRef.current.setButtonDisabled("unlink", parseInt(this.state.LearnershipType) === 3)
                }
            )
        }
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    render() {
        return (
            <ViewContainer>
                <p className="breadcrumbs">
                    {"Learnership > Unit Standards"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <div className="searchResults">
                    <EnhancedToolbar highlight={false} ref={toolbarRef}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)}
                        config={this.toolbarConfig} />
                    <UnitStandardsTable headCells={headCells} onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        dataUrl="api/sims/LearnershipSetupUnitStandard/GetSearch"
                        searchParameters={this.state.searchParameters} paged={true} />
                </div>

                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title={this.props.location.state.LearnershipTitle}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={`Link Unit Standards to Learnership: ${this.props.location.state.LearnershipTitle}`}
                    linkName={"UnitStandards"} postUrl={"api/sims/LearnershipSetupUnitStandard/linkUnitStandards"} usePost={true}
                    programmeId={this.props.location.state.LearnershipID} programmeType="Q"
                >
                    <UnitStandardSetupSearch dataUrl="api/sims/LearnershipSetupUnitStandard/GetSearchLinkableUnitStandards"
                        multiSelect={true} showTitle={false}
                        programmeId={this.props.location.state.LearnershipID} programmeType="L"
                        mode="lookup"
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => this.unlinkUnitstandard(`api/sims/LearnershipSetupUnitStandard/unlinkLearnershipUnitstandard`)}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(LearnershipUnitStandardSearch));

import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import "../LearnershipSetup.css";
import SearchView from "../../../widgets/SearchView";
import LearnershipSetupSearchForm from '../LearnershipSetupSearchForm';
import ViewUtils from '../../../ViewUtils';
import { ViewContainer } from "./../../../View.jsx";
import withMenuPermission from "../../../widgets/withMenuPermission";


const utils = new ViewUtils();
const headCells = [
    { id: 'LearnershipCode', numeric: false, disablePadding: true, label: 'Learnership Code' },
    { id: 'LearnershipTitle', numeric: false, disablePadding: true, label: 'Learnership Title' }
];

class QCTOLearnershipSetupSearch extends Component {

    constructor(props) {
        super(props)

        let model = { LearnershipCode: "", LearnershipTitle: "" };

        this.searchParameters = !utils.isNull(this.props.programmeId) ?
            [
                { Name: "id", Value: this.props.programmeId },
                { Name: "LearnershipCode", Value: "" },
                { Name: "LearnershipTitle", Value: "" }
            ]
            :
            [
                { Name: "LearnershipCode", Value: "" },
                { Name: "LearnershipTitle", Value: "" },
            ]

        if (!utils.isNull(props.exclusions)) {
            model.Exclusions = props.exclusions;
            this.searchParameters.push({ Name: "Exclusions", Value: props.exclusions });
        }

        if (!utils.isNull(props.personId)) {
            model.PersonID = props.personId;
            this.searchParameters.push({ Name: "PersonID", Value: props.personId })
        }

        this.state = {
            model: !utils.isNull(this.props.programmeId) ? { ...model, id: this.props.programmeId } : model,
            keyUpSwitch: false,
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: "delete", label: '', tooltip: 'Remove QCTO Learnership', icon: 'DeleteIcon', disabled: true, visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        };
    }

    clearSearch(e) {
        this.setState({
            model: {
                LearnershipCode: "", LearnershipTitle: ""
            }
        });
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <SearchView headCells={headCells} dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/QCTOLearnershipSetup/getsearch"}
                    entityViewPath='/qctoLearnershipSetup'
                    title='QCTO Learnerships'
                    model={this.state.model} toolbarConfig={this.toolbarConfig}
                    clearPress={(e) => { this.clearSearch(e) }} allowClear={this.props.allowClear}
                    searchParameters={this.searchParameters} onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="LearnershipTitle" mode={this.props.mode}
                    history={this.props.history} multiSelect={this.props.multiSelect}
                    keyUpSwitch={this.state.keyUpSwitch}
                    deleteUrl={"api/sims/QCTOLearnershipSetup/RemoveLearnership"} useDeletePost={true}
                    promptTitle={"Remove QCTO Learnership"} selectionCallbackMode={this.props.selectionCallbackMode}
                    promptContent={"Removing the QCTO learnership is a permanent action and the QCTO learnership will not be available going forward. Are you sure you want to remove this QCTO learnership?"}
                    editPermission={this.props.menuPermissionItem.Edit}
                >
                    <LearnershipSetupSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(QCTOLearnershipSetupSearch));



import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import ViewUtils from '../../ViewUtils';

const headCells = [
    { id: 'AgreementReferenceNumber', numeric: false, disablePadding: true, label: 'Agreement Reference No.' },
    { id: 'ProgrammeCode', numeric: false, disablePadding: true, label: 'ProgrammeCode' },
    { id: 'ProgrammeTitle', numeric: false, disablePadding: true, label: 'ProgrammeTitle' },
    { id: 'CommencementDate', numeric: false, disablePadding: true, label: 'Commencement Date' },
    { id: 'CompletionDate', numeric: false, disablePadding: true, label: 'Completion Date' },
    { id: 'RequiredCredits', numeric: false, disablePadding: true, label: 'Required Credits' },
    { id: 'TotalCredits', numeric: false, disablePadding: true, label: 'Total Credits' },
    { id: 'TotalCreditsAchieved', numeric: false, disablePadding: true, label: 'Total Credits Achieved' },
    { id: 'CertificateNumber', numeric: false, disablePadding: true, label: 'Certificate Number' },
    { id: 'ProgrammeStatus', numeric: false, disablePadding: true, label: 'Programme Status' },
    { id: 'TrainingProvider', numeric: false, disablePadding: true, label: 'Training Provider' }
];



const utils = new ViewUtils();
const toolbarRef = React.createRef();

class LearnerLearnershipAchievement extends Component {
    constructor(props) {
        super(props);

       

        let model = !utils.isNull(this.props.location.state.learnerID) ?
            {
                LearnershipProgrammeCode: "",
                LearnershipProgrammeDescription: "",
                ProgrammeID: this.props.programmeId,
                LearnerID: this.props.location.state.learnerID

            }
            :
            {
                LearnershipProgrammeCode: "",
                LearnershipProgrammeDescription: "",
                LearnerID: this.props.location.state.learnerID
            }

        this.state = 
            {
                model: model,
                showTable: true,
                selectedLearnership: null,
                searchParameters: [{ Name: "LearnerID", Value: this.props.location.state.learnerID }],
                keyUpSwitch: false
            }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [{
                        id: 'achievements', label: 'ACHIEVEMENTS', tooltip: 'View achieved details',
                        disabled: utils.isNull(this.state.selectedLearnership)
                    }
                    ]
                }
            ]
        };
    }

   

    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/learner',
                state: {
                    selection: this.props.location.state.learnerID
                }
            });
        }
        else if (buttonId === "achievements") {
            this.props.history.push({
                pathname: '/ViewLearnershipAchievementUS',
                state: {
                    LearnerID: this.props.location.state.learnerID,
                    LearnerLearnershipID: this.state.selectedLearnership,
                    learnerNames: this.props.location.state.learnerNames
                }
            })
        }


    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedLearnership: selection[0].Id });
        }
        else {
            this.setState({ selectedLearnership: null });
        }
        toolbarRef.current.setButtonDisabled("achievements", selection === null || selection.length === 0);
    }


    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {`Learner > Learnership Achievements > ${this.props.location.state.learnerNames}`}
                </p>
                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    {this.state.showTable === true && (
                        <EnhancedTable
                            headCells={headCells}
                            dataUrl={`api/sims/LearnerLearnershipAchievement/GetLearnerAchievedLearnershipsSearch`}
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />)}

                </div>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(LearnerLearnershipAchievement)
);

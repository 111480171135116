import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";
import Prompt from '../../widgets/Prompt';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import FormDialog from "../FormDialog";
import { OutComeForm } from "../program/OutcomeForm";
import SDProviderQCTOQualificationSearchModule from "../program/QCTOQualification/SDProviderQCTOQualificationSearchModule";
import LinkDialog from "../LinkDialog";
import SDProviderInterventionAudit from '../sdprovider/SDProviderInterventionAudit';
import Dialog from '@material-ui/core/Dialog';
import { Typography } from "antd";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import withMenuPermission from "../../widgets/withMenuPermission";

const headCells = [
    { id: 'SAQAQualificationID', numeric: false, disablePadding: false, label: 'Qualification ID' },
    { id: 'SAQAQualificationTitle', numeric: false, disablePadding: false, label: 'Qualification Title' },
    { id: 'QualificationType', numeric: false, disablePadding: true, label: 'Qualification Type' },
    { id: 'Credits', numeric: false, disablePadding: false, label: 'Credits', widthRation: .05 },
    { id: 'RegistrationStartDate', numeric: false, disablePadding: false, label: 'Registration Start Date' },
    { id: 'RegistrationEndDate', numeric: false, disablePadding: false, label: 'Registration End Date' },
    { id: 'LastDateForEnrolment', numeric: false, disablePadding: false, label: 'Last Enrolment Date' },
    { id: 'LastDateForAchievement', numeric: false, disablePadding: false, label: 'Last Achievement Date' },
    { id: 'ProgramOutcome', numeric: false, disablePadding: false, label: 'Program Outcome' },
    { id: 'ModeOfDelivery', numeric: false, disablePadding: false, label: 'Mode Of Delivery' },
    { id: 'Specialisation', numeric: false, disablePadding: false, label: 'Specialisation' }
];
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const USheadCells = [
    { id: 'ModuleCode', numeric: false, disablePadding: true, label: 'Module Code' },
    { id: 'ModuleTitle', numeric: false, disablePadding: true, label: 'Module Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' },
    { id: 'ModuleType', numeric: false, disablePadding: true, label: 'Module Type' },
    { id: 'LearningType', numeric: false, disablePadding: true, label: 'Learning Type' },
    { id: 'NQFLevel', numeric: false, disablePadding: true, label: 'NQF Level' }
];


const useStyles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        color: 'white',
    },
    table: {
        minWidth: 700,
    },
});

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class AssessmentCentreQCTOQualificationSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedQualification: null,
            linkDialogOpen: false,
            formDialogOpen: false,
            auditDialogOpen: false,
            searchParameters: [{ Name: "AssessmentCentreID", Value: this.props.location.state.selection }],
            propmtOpen: false,
            promptTitle: "Remove QCTO Qualification from Assessment Centre",
            promptContent: "Are you sure you want to remove this QCTO Qualification from Assessment Centre?",
            removeMessage: "",
            removeMessageStyle: "",
            selectedQualificationTitle: null,
            showTable: true,
            searchParameters2: [{ Name: "Id", Value: 0 }],
            OpenDialog: false,

        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: "link", label: '', icon: 'LinkIcon', tooltip: "Link existing QCTO Qualification to Assessment Centre", visible: this.props.menuPermissionItem.Add },
                        {
                            id: 'update', label: '', icon: 'UpdateIcon', tooltip: 'Update Outcome and Mode of Delivery',
                            disabled: utils.isNull(this.state.selectedQualification), visible: this.props.menuPermissionItem.Edit
                        },
                        {
                            id: 'audit', label: '', icon: 'AuditIcon', tooltip: 'View Record Audit Trail',
                            disabled: utils.isNull(this.state.selectedQualification)
                        },
                        {
                            id: 'remove', label: '', icon: 'DeleteIcon', tooltip: 'Remove QCTO Qualification from Assessment Centre',
                            disabled: utils.isNull(this.state.selectedQualification), visible: this.props.menuPermissionItem.Delete
                        },
                        { id: 'view', label: '', tooltip: 'View Modules', icon: 'ViewIcon', disabled: utils.isNull(this.state.selectedQualification) }]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        {
                            id: 'module', label: '', tooltip: 'Link module/s to the Assessment Centres qcto qualification', icon: 'AddIcon',
                            disabled: utils.isNull(this.state.selectedQualification), visible: this.props.menuPermissionItem.Add
                        }
                    ]
                }
            ]
        };

    }
    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/AssessmentCentre',
                state: { selection: this.props.location.state.selection }
            });
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
        else if ("update" === buttonId) {
            this.setState({ removeMessage: "" }, this.viewRecords());
        }
        else if ("audit" === buttonId) {
            this.setState({ auditDialogOpen: true, message: "" })
        }
        else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, removeMessage: "" })
        }
        else if ("module" === buttonId) {
            this.props.history.push({
                pathname: '/AssessmentCentreQCTOQualificationModuleSearch',
                state: {
                    AssessmentCentreID: this.props.location.state.selection,
                    AssessmentCentreQualificationID: this.state.selectedQualification,
                    QualificationTitle: this.state.selectedQualificationTitle,
                    AssessmentCentreName: this.props.location.state.AssessmentCentreName,
                    AssessmentCentreSDLNumber: this.props.location.state.AssessmentCentreSDLNumber
                }
            });
        }
        else if ("view" === buttonId) {
            this.setState({ OpenDialog: true, message: "" })
        }
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {

            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                formDialogOpen: false, auditDialogOpen: false, selectedSiteVisit: null, linkDialogOpen: false
            }, () => {
                this.setState({ showTable: true })
            });
        }
        else if (isCancel === true) {
            this.setState({
                formDialogOpen: false,
                linkDialogOpen: false,
                auditDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            let newsearchParameters2 = [...this.state.searchParameters2];
            let temp_element = { ...newsearchParameters2[0] };
            temp_element.Value = selection[0].Id
            newsearchParameters2[0] = temp_element;

            this.setState({ searchParameters2: JSON.parse(JSON.stringify(newsearchParameters2)), selectedQualification: selection[0].Id, removeMessage: "", selectedQualificationTitle: selection[0].SAQAQualificationTitle });
        }
        else {
            this.setState({ searchParameters2: [{ Name: "Id", Value: 0 }], selectedQualification: null, removeMessage: "", selectedQualificationTitle: null });
        }
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("update", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("audit", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("module", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("view", selection === null || selection.length === 0);
    }

    successfulRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedQualification: null,
                removeMessage: response.Message,
                removeMessageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                removeMessage: response.Message,
                removeMessageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    removeQualifications(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
            toolbarRef.current.setButtonDisabled("update", true);
            toolbarRef.current.setButtonDisabled("audit", true);
            toolbarRef.current.setButtonDisabled("module", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedQualification}&AssessmentCentreId=${this.props.location.state.selection}`;
        utils.invokeUrlDelete(fullUrl, this.successfulRemoveCallback, this.unsuccessfulRemoveCallback);
    }

    viewRecords = () => {
        this.setState({ formDialogOpen: true })
    }


    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    CloseForm = () => {
        this.setState({
            OpenDialog: false,
            selectedId: null,
            clearSelectedRow: true,
            selectedQualification: null
        });
        toolbarRef.current.setButtonDisabled("audit", true);
        toolbarRef.current.setButtonDisabled("update", true);
        toolbarRef.current.setButtonDisabled("unlink", true);
        toolbarRef.current.setButtonDisabled("updateDate", true);
        toolbarRef.current.setButtonDisabled("view", true);
    };

    render() {
        const { classes } = this.props;
        return (
            <ViewContainer mode={this.props.mode} title="">

                <p className="breadcrumbs">
                    {"Assessment Centre > QCTO Qualifications " + (this.props.location.state.AssessmentCentreName !== null ? "> " + this.props.location.state.AssessmentCentreName + (this.props.location.state.AssessmentCentreSDLNumber !== null ? " - " + this.props.location.state.AssessmentCentreSDLNumber : "") : "> Add")}
                </p>

                <AlertItem message={this.state.removeMessage} alertStyle={this.state.removeMessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    {this.state.showTable === true && (
                        <EnhancedTable
                            headCells={headCells}
                            dataUrl={`api/sims/AssessmentCentreQualification/GetAssessmentCentreQualifications`}
                            height="60vh"
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />

                    )}
                </div>

                <FormDialog
                    open={this.state.formDialogOpen}
                    dialogTitle={"Update Outcome and Mode of Delivery"}
                    viewRef={this}
                    entityId={this.state.selectedQualification}
                    formRef={addFormRef}
                    saveUrl={"api/sims/AssessmentCentreQualification/UpdateQualificationModeAndDelivery"} usePut={true}
                    controllerName={"AssessmentCentreQualification"}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                >
                    <OutComeForm
                        ref={addFormRef}
                        id={this.state.selectedQualification}
                        controllerName={"AssessmentCentreQualification"}
                        dataURL='api/sims/AssessmentCentreQualification/GetFindQualificationMode'
                    />
                </FormDialog>

                <LinkDialog open={this.state.auditDialogOpen}
                    title={"Audit Trail"}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    dialogTitle={"Audit Trail"}
                    selectionId={this.props.location.state.selectedQualification}
                >

                    <SDProviderInterventionAudit dataUrl={"api/sims/AssessmentCentreQualification/GetAssessmentCentreQualificationAudit"}
                        multiSelect={false} showTitle={false}
                        Id={this.state.selectedQualification}
                    />
                </LinkDialog>

                <LinkDialog
                    open={this.state.linkDialogOpen}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    dialogTitle={`Link QCTO Qualifications to Assessment Centre > ${this.props.location.state.AssessmentCentreName} - ${this.props.location.state.AssessmentCentreSDLNumber}`}
                    linkName={"qualifications"} postUrl={"api/sims/AssessmentCentreQualification/LinkAssessmentCentreQualification"} usePost={true}
                    selectionId={this.props.location.state.selection}
                >
                    <SDProviderQCTOQualificationSearchModule
                        dataUrl="api/sims/AssessmentCentreQualification/GetSearchLinkableQualifications"
                        mode="lookup"
                        multiSelect={true} showTitle={false}
                        programmeId={this.props.location.state.selection} programmeType="Q"
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeQualifications("api/sims/AssessmentCentreQualification/RemoveAssessmentCentreQualification") }}
                    closePrompt={this.closePrompt}
                />

                <Dialog fullScreen open={this.state.OpenDialog} onClose={this.CloseForm} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.CloseForm} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h1" className={classes.title}>
                                {"QCTO Qualification Modules"}
                            </Typography>

                        </Toolbar >
                    </AppBar >
                    <br /><br />
                    <EnhancedTable
                        headCells={USheadCells}
                        dataUrl={"api/sims/AssessmentCentreQualification/GetAssessmentCentreQModule"}
                        makeRowBold={true}
                        searchParameters={this.state.searchParameters2} paged={false} autoFetchData={true} />
                </Dialog >
            </ViewContainer>
        );
    }
}

export default withRouter(withStyles(useStyles())(connect()(withMenuPermission(AssessmentCentreQCTOQualificationSearch))));

import React from 'react';
import { CustomTextField } from '../../widgets/CustomTextField';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { makeStyles } from '@material-ui/core/styles';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';

const utils = new ViewUtils();
const ScheduleItemValues = ["1", "2", "3", "4", "5", "6"];
export const LearnerProgrammeGrantTrancheAmountForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref}
            dataURL='api/sims/learnergrants/getfindtranche'
            values={props.data}
            entityLookupId={props.id}
            entityLookupController={props.entityLookupController} org="saseta"
            useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="LinkTrancheDetailForm">

                    <table cellPadding="2" width="80%">
                        <tbody>
                            <tr>
                                <td>
                                    <CustomTextField
                                        id="ScheduleItem"
                                        key={tabValue}
                                        label="Tranche"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        form={form}
                                    />
                                </td>
                                <td>
                                    <CustomTextField
                                        id="TrancheAmount"
                                        key={tabValue}
                                        label="Tranche Amount"
                                        className="w-100 "
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                        validationRegex={/^((?!0)\d{1,18}|0|\.\d{1,2})($|\.$|\.\d{1,2}$)/g}
                                        validationMessage="Please enter a valid amount."
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomDatePicker
                                            id="DueDate"
                                            key={tabValue}
                                            label="Due Date"
                                            className='{classes.textField} w-100'
                                            required={props.canEditDueDate && !ScheduleItemValues.includes(form.values["ScheduleItem"])}
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form}
                                            disabled={!(props.canEditDueDate && !ScheduleItemValues.includes(form.values["ScheduleItem"])) }
                                    />
                                </td>
                                <td>
                                    <CustomTextField
                                        id="Comments"
                                        key={tabValue}
                                        label="Reason Comment"
                                            className="w-100"
                                            required={props.canEditDueDate && !ScheduleItemValues.includes(form.values["ScheduleItem"])}
                                            form={form}
                                            disabled={!(props.canEditDueDate && !ScheduleItemValues.includes(form.values["ScheduleItem"]))}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})

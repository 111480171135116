import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "../../Grid.css";
import "../../Form.css";
import "../../App.css";
import { BursaryApplicationPersonForm } from './BursaryApplicationPersonForm';
import { ViewContainer } from "../../View";
import ViewUtils from "../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";


const utils = new ViewUtils();


const bursaryRef = React.createRef();

const lowertoolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'back', label: 'BACK', tooltip: 'Back to Busary Application Evaluation search', theme: true },
                {
                    id: 'next', label: 'NEXT', tooltip: 'Proceed to Tertiary Education', theme: true }
            ]
        }
    ]
};

class BursaryApplicationEvaluation extends Component {

    state = {
        PersonDetails: {
            Person: {
                FirstName: "",
                Surname: "",
                IDNo: ""
            }
        },
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' },

                    ]
                }
            ]
        }
    };

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ PersonDetails: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.close();
        }
    }

    handleLowerToolbarButtonClick(buttonId) {
        if ("next" === buttonId) {
            this.props.history.push({
                pathname: '/BursaryApplicationTypeEvaluation',
                state: {
                    selection: this.props.location.state.selection//,
                    //selectedQualificationRows: !utils.isNull(this.props.location.state.selectedQualificationRows) ? this.props.location.state.selectedQualificationRows : [],
                    //selectedSkillsProgrammeRows: !utils.isNull(this.props.location.state.selectedSkillsProgrammeRows) ? this.props.location.state.selectedSkillsProgrammeRows : []
                }
            });
        } else if ("back" === buttonId) {
            this.close();
        }
    }

    handleToolbarButtonMenuClick(menu) {
    }

    close() {
        this.props.history.push('/BursaryApplicationEvaluationSearch');
    }

    successCallback = () => {

        this.setState({ message: "Application updated successfully", messageStyle: "SUCCESS" });
    }

    save() {
    }

    render() {

        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"Bursary Application Evaluation " + (this.state.PersonDetails.FirstName !== null ? "> " + this.state.PersonDetails.FirstName + " " + this.state.PersonDetails.Surname + (this.state.PersonDetails.IDNo !== null ? " - " + this.state.PersonDetails.IDNo : "") : "> Add")}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Assessor" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={true} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "64vh", maxHeight: "64vh" }}>
                                    <BursaryApplicationPersonForm ref={bursaryRef} id={this.props.location.state.selection} valueChangeHandler={(values) => this.handleFormValueChange(values)} editPermission={this.props.menuPermissionItem.Edit} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="90%">
                            </td>
                            <td width="20%" align="right">
                                <div style={{ width: "120px", marginTop: "16px" }}>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleLowerToolbarButtonClick(buttonId)} config={lowertoolbarConfig} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(BursaryApplicationEvaluation));

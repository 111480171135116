import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "../../View.css";
import "../../Grid.css";
import "../../Form.css";
import "../../App.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import { BursaryApplicationTypeSelectForm } from './BursaryApplicationTypeSelectForm';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "../../View.jsx";

const utils = new ViewUtils();

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'back', label: 'BACK', tooltip: 'Back to document upload', theme: true },
                { id: 'next', label: 'NEXT', tooltip: 'Proceed to add application details', theme: true }
            ]
        }
    ]
};

const formRef = React.createRef();
class AddBursaryApplicationTypeSelect extends Component {
    constructor(props) {
        super(props)
        let data = {};

        if (!utils.isNull(props.data) && !utils.isNull(props.data.bursaryApplicationType)) {
            if (!utils.isNull(props.data.bursaryApplicationType)) {
                data = props.data.bursaryApplicationType;
            }
            else {
                data = props.data.bursaryApplicationType;
            }
        } else if (!utils.isNull(props.data) && !utils.isNull(props.data.identityDetails)) {
            data = props.data.identityDetails.bursaryApplicationType ? props.data.identityDetails.bursaryApplicationType : props.data.identityDetails.formData
        } else {
            data.bursaryApplicationType = {};
        }

        this.state = {
            model: data,
            ID: props.data.bursaryApplication ? props.data.bursaryApplication.ID : null
        }
    }

    handleFormValueChange(values) {
    }

    handleToolbarButtonClick(buttonId) {
        let formValue = formRef.current.getValue();
        if ("back" === buttonId) {
            this.props.stepHandler(2, false, formValue);
        } else if ("next" === buttonId) {
            if (!utils.isNull(formValue)) {
                this.props.stepHandler(2, true, formValue);
            }
        }
    }
    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    render() {
        return (
            <ViewContainer topPadding="0">
                <p className="legend">
                    {this.Capitalize(this.props.socioEconomicStatus) +' Bursary Application Details'}  
                </p>
                <div style={{ height: "50%", maxHeight: "42vh" }}>
                    <BursaryApplicationTypeSelectForm ref={formRef} id={this.state.ID} data={this.state.model} socioEconomicStatus={this.props.socioEconomicStatus } IsExternal={this.props.IsExternal} editPermission={true} valueChangeHandler={(values) => this.handleFormValueChange(values)} dataUrl={"api/sims/BursaryApplication/getBursaryApplicationTypeSearch"} />
                </div>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="90%">
                            </td>
                            <td width="20%" align="right">
                                <div style={{ width: "120px", marginTop: "16px" }}>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} config={toolbarConfig} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(AddBursaryApplicationTypeSelect)
);

import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Learner.css";
import { LearnerForm } from './LearnerForm';
import ViewUtils from "./../../ViewUtils";
import { ViewContainer } from "./../../View.jsx";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";


const utils = new ViewUtils();


const learnerRef = React.createRef();
class Learner extends Component {

    state = {
        LearnerDetails: {
            FirstName: "",
            Surname: ""
        },
        model: {
            PersonParent: null
        },
        populateAddressDetails: false,
        title: "",
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' },
                        
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.menuPermissionItem.Edit },
                        {
                            contextMenu: [
                                { id: "registrationUnitStandard", description: "Unit Standard", visible: this.props.onMenuSpecificPermissionItem('/LearnerUnitStandardSearch').View },
                                { id: "registrationSkillsProgramme", description: "Skills Programme", visible: this.props.onMenuSpecificPermissionItem('/LearnerSkillsProgrammeSearch').View },
                                { id: "registrationLearnerships", description: "Learnerships", visible: this.props.onMenuSpecificPermissionItem('/LearnerLearnershipSearch').View },
                                { id: "registrationInternships", description: "Internships", visible: this.props.onMenuSpecificPermissionItem('/LearnerInternshipsSearch').View },
                                { id: "registrationBursaries", description: "Bursary", visible: this.props.onMenuSpecificPermissionItem('/LearnerBursarySearch').View },
                                { id: "registrationAET", description: "AET", visible: this.props.onMenuSpecificPermissionItem('/LearnerAETSearch').View },
                                { id: "registrationFETPlacement", description: "TVET Placement", visible: this.props.onMenuSpecificPermissionItem('/LearnerFETSearch').View },
                                { id: "registrationCandidacyProgramme", description: "Candidacy Programme", visible: this.props.onMenuSpecificPermissionItem('/LearnerCandidacySearch').View },
                                { id: "registrationTrades", description: "Artisan", visible: this.props.onMenuSpecificPermissionItem('/LearnerTradeSearch').View },
                                { id: "registrationWE", description: "Work Experience", visible: this.props.onMenuSpecificPermissionItem('/LearnerWorkExperienceSearch').View },
                                { id: "registrationMC", description: "Mine Community", visible: this.props.onMenuSpecificPermissionItem('/LearnerMineCommunitySearch').View },
                                { id: "registrationSS", description: "Small Scale", visible: this.props.onMenuSpecificPermissionItem('/LearnerSmallScaleSearch').View },
                                { id: "registrationUYD", description: "Unemployed Youth Development", visible: this.props.onMenuSpecificPermissionItem('/LearnerUnemployedYouthDevelopmentSearch').View },
                                { id: "registrationQCTOLearnerships", description: "QCTO Learnerships", visible: this.props.onMenuSpecificPermissionItem('/LearnerQCTOLearnershipSearch').View },
                                { id: "registrationQCTOArtisans", description: "QCTO Artisans", visible: this.props.onMenuSpecificPermissionItem('/LearnerQCTOArtisanSearch').View },
                                { id: "registrationQCTOSkillsProgramme", description: "QCTO Skills Programme", visible: this.props.onMenuSpecificPermissionItem('/LearnerQCTOSkillsProgrammeSearch').View },
                                { id: "registrationHET", description: "HET Lecturer Support", visible: this.props.onMenuSpecificPermissionItem('/LearnerHETLecturerSupportSearch').View },
                                { id: "registrationMEDP", description: "Management and Executive Development Programme", visible: this.props.onMenuSpecificPermissionItem('/LearnerMEDPSearch').View },
                                { id: "registrationWC", description: "Workplace Coaches", visible: this.props.onMenuSpecificPermissionItem('/LearnerWorkplaceCoachesSearch').View },
                                { id: "registrationTVETNCV", description: "TVET NCV Support", visible: this.props.onMenuSpecificPermissionItem('/LearnerTVETNCVSearch').View },
                                { id: "registrationIIBTCP", description: "Inhouse/Industry/Based Training", visible: this.props.onMenuSpecificPermissionItem('/LearnerIIBTCPSearch').View },
                                { id: "registrationSCPPI", description: "Short Courses Provided by Public Institution Programme" },

                            ],
                            id: 'registrations', label: 'REGISTRATIONS', tooltip: 'Registered Programmes'
                        },
                        { id: 'eisaenrol', label: 'EISA ENROLMENT', tooltip: 'EISA Enrolment' },
                        {
                            contextMenu: [
                                { id: "assessmentsSkillsProgramme", description: "Skills Programme" },
                                { id: "assessmentsLearnerships", description: "Learnerships" },
                                { id: "assessmentsTrades", description: "Artisans" },
                                { id: "assessmentsAET", description: "AET" },
                                { id: "assessmentsMineCommunity", description: "Mine Community" },
                                { id: "assessmentsSmallScale", description: "Small Scale" },
                                { id: "assessmentsUYD", description: "Unemployed Youth Development" },
                                { id: "assessmentsQCTOLearnerships", description: "QCTO Learnerships" },
                                { id: "assessmentsQCTOArtisans", description: "QCTO Artisans" },
                                { id: "assessmentsQCTOSkillsProgramme", description: "QCTO Skills Programme" },
                                { id: "assessmentsTVETNCV", description: "TVET NCV Support" }
                            ],
                            id: 'assessments', label: 'ASSESSMENTS', tooltip: 'Assessed Programmes'
                        },
                        {
                            contextMenu: [
                                { id: "achievementsUnitStandards", description: "Unit Standards" },
                                { id: "achievementsQualifications", description: "Qualifications" },
                                { id: "achievementsSkillsProgramme", description: "Skills Programme" },
                                { id: "achievementsLearnerships", description: "Learnerships" },
                                { id: "achievementsMineCommunity", description: "Mine Community" },
                                { id: "achievementsSmallScale", description: "Small Scale" },
                                { id: "achievementsUYD", description: "Unemployed Youth Development" },
                                { id: "achievementsQCTOLearnerships", description: "QCTO Learnerships" },
                                { id: "achievementsQCTOSkillsProgramme", description: "QCTO Skills Programme" }
                            ],
                            id: 'achievements', label: 'ACHIEVEMENTS', tooltip: 'Achieved Programmes'
                        },
                        { id: 'crm', label: 'CRM', tooltip: 'Client Relations Management' },
                        { id: 'programmeGrants', label: 'GRANTS', tooltip: 'Programme Grants' },
                        { id: 'ac', label: 'ASSESSMENT CENTRE', tooltip: 'Assessment Centre' },
                        { id: 'wa', label: 'WORKPLACE APPROVAL', tooltip: 'Workplace Approval' },
                        { id: 'sdp', label: 'SKILLS DEVELOPMENT PROVIDER', tooltip: 'Skills Development Provider' },
                    ]
                }
            ]
        }
    };

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ LearnerDetails: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("crm" === buttonId) {
            this.props.history.push({
                pathname: '/learnerCRM',
                state: {
                    LearnerID: this.state.LearnerDetails.ID,
                    LearnerName: `${this.state.LearnerDetails.FirstName} ${this.state.LearnerDetails.Surname}`
                }
            })
        }
        else if ("programmeGrants" === buttonId) {
            this.props.history.push({
                pathname: '/learnergrants',
                state: {
                    LearnerID: this.state.LearnerDetails.ID,
                    LearnerName: `${this.state.LearnerDetails.FirstName} ${this.state.LearnerDetails.Surname}`
                }
            })
        }
        else if ("ac" === buttonId) {
            this.props.history.push({
                pathname: '/learnerAssessmentCentre',
                state: {
                    LearnerID: this.state.LearnerDetails.ID,
                    LearnerName: `${this.state.LearnerDetails.FirstName} ${this.state.LearnerDetails.Surname}`
                }
            })
        }
        else if ("wa" === buttonId) {
            this.props.history.push({
                pathname: '/learnerWorkplaceApproval',
                state: {
                    LearnerID: this.state.LearnerDetails.ID,
                    LearnerName: `${this.state.LearnerDetails.FirstName} ${this.state.LearnerDetails.Surname}`
                }
            })
        }
        else if ("sdp" === buttonId) {
            this.props.history.push({
                pathname: '/learnerSDProvider',
                state: {
                    LearnerID: this.state.LearnerDetails.ID,
                    LearnerName: `${this.state.LearnerDetails.FirstName} ${this.state.LearnerDetails.Surname}`
                }
            })
        }
        else if ("eisaenrol" === buttonId) {
            this.props.history.push({
                pathname: '/learnerEISAEnrolment',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: `${this.state.LearnerDetails.FirstName} ${this.state.LearnerDetails.Surname}`
                }
            })
        }
    }

    handleToolbarButtonMenuClick(menu) {
        if ("registrationUnitStandard" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerUnitStandardSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationSkillsProgramme" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerSkillsProgrammeSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationBursaries" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerBursarySearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationLearnerships" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerLearnershipSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationTrades" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerTradeSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationInternships" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerInternshipsSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationAET" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerAETSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationFETPlacement" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerFETSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationCandidacyProgramme" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerCandidacySearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationWE" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerWorkExperienceSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationMC" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerMineCommunitySearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationSS" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerSmallScaleSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationUYD" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerUnemployedYouthDevelopmentSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationQCTOLearnerships" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerQCTOLearnershipSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationHET" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerHETLecturerSupportSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationQCTOArtisans" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerQCTOArtisanSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationTVETNCV" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerTVETNCVSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationQCTOSkillsProgramme" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerQCTOSkillsProgrammeSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationMEDP" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerMEDPSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationWC" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerWorkplaceCoachesSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationIIBTCP" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerIIBTCPSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationSCPPI" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerSCPPISearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("achievementsLearnerships" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerLearnershipAchievements',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("achievementsUYD" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerUnemployedYouthDevelopmentAchievements',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("achievementsSkillsProgramme" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerSkillsProgrammeAchievements',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("achievementsQualifications" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerQualificationAchievements',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("achievementsUnitStandards" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerUnitStandardAchievements',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("achievementsMineCommunity" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerMineCommunityAchievements',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("achievementsSmallScale" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerSmallScaleAchievements',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("achievementsQCTOLearnerships" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerQCTOLearnershipAchievements',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("achievementsQCTOSkillsProgramme" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerQCTOSkillsProgrammeAchievements',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("assessmentsLearnerships" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerLearnershipAssessments',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("assessmentsSkillsProgramme" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerSkillsProgrammeAssessment',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("assessmentsTrades" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerTradeAssessments',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("assessmentsAET" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerAETAssessments',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("assessmentsMineCommunity" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerMineCommunityAssessment',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("assessmentsSmallScale" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerSmallScaleAssessment',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("assessmentsUYD" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerUnemployedYouthDevelopmentAssessment',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("assessmentsQCTOLearnerships" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerQCTOLearnershipAssessments',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("assessmentsQCTOArtisans" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerQCTOArtisanAssessments',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("assessmentsQCTOSkillsProgramme" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerQCTOSkillsProgrammeAssessment',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("assessmentsTVETNCV" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerTVETNCVAssessments',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
    }

    onChangePopulateAddressDetails = () => {
        this.setState({ populateAddressDetails : false})
    }

    onChangePersonIDHandler = (form, value, event) => {
        let newModel = { ...form.values }

        if (!utils.isNull(value)) {
            let url = "api/sims/LearnerDetails/GetFindPersonParentDetails?id=" + value.Id;
            utils.invokeUrl(url, (response) => {
                newModel.ParentPersonFirstName = response.FirstName;
                newModel.ParentPersonMiddleName = response.MiddleName;
                newModel.ParentPersonSurname = response.Surname;
                newModel.ParentPersonInitials = response.Initials;
                newModel.ParentPersonTitleID = response.TitleID;
                newModel.ParentPersonIDNo = response.IDNo;
                newModel.ParentPersonAlternateIDTypeID = response.AlternateIDTypeID;
                newModel.ParentPersonDateofBirth = response.DateofBirth;
                newModel.ParentPersonGenderID = response.GenderID;
                newModel.ParentPersonEquityID = response.EquityID;
                newModel.ParentPersonSocioEconomicStatusID = response.SocioEconomicStatusID;
                newModel.ParentPersonHomeLanguageID = response.HomeLanguageID;
                newModel.ParentPersonNationalityID = response.NationalityID
                newModel.ParentPersonCitizenResidentialStatusID = response.CitizenResidentialStatusID;
                newModel.ParentPersonTelephoneNumber = response.TelephoneNumber;
                newModel.ParentPersonCellPhoneNumber = response.CellPhoneNumber;
                newModel.ParentPersonFaxNumber = response.FaxNumber;
                newModel.ParentPersonEMail = response.EMail;
                newModel.ParentPersonPhysicalAddress1 = response.PhysicalAddress1;
                newModel.ParentPersonPhysicalAddress2 = response.PhysicalAddress2;
                newModel.ParentPersonPhysicalAddress3 = response.PhysicalAddress3;
                newModel.ParentPersonPhysicalCode = response.PhysicalCode;
                newModel.ParentPersonPhysicalMunicipalityID = response.PhysicalMunicipalityID;
                newModel.ParentPersonPhysicalDistrictID = response.PhysicalDistrictID;
                newModel.ParentPersonPhysicalUrbanRuralID = response.PhysicalUrbanRuralID;
                newModel.ParentPersonPhysicalLocation = response.PhysicalLocation;
                newModel.ParentPersonPostalAddressLine1 = response.PostalAddressLine1;
                newModel.ParentPersonPostalAddressLine2 = response.PostalAddressLine2;
                newModel.ParentPersonPostalAddressLine3 = response.PostalAddressLine3;
                newModel.ParentPersonPostalCode = response.PostalCode;
                newModel.ParentPersonPostalMunicipalityID = response.PostalMunicipalityID;
                newModel.ParentPersonPostalDistrictID = response.PostalDistrictID;
                newModel.ParentPersonPostalUrbanRuralID = response.PostalUrbanRuralID;
                newModel.ParentPersonPostalLocation = response.PostalLocation;

                this.setState({ model: newModel, populateAddressDetails: true })
            },
                null,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    }
                }
            )
        }
    }

    successCallback = () => {

        this.setState({ message: "Learner updated successfully", messageStyle: "SUCCESS" });
    }

    save() {
        let formValue = learnerRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            let input = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/learnerdetails/UpdateLearner", this.successCallback, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: input
            });
        }
    }

    closeView() {
        this.props.history.push('/learners');
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">

                <p className="breadcrumbs">
                    {"Learner " + (this.state.LearnerDetails.FirstName !== null ? "> " + this.state.LearnerDetails.FirstName + (this.state.LearnerDetails.Surname !== null ? "  " + this.state.LearnerDetails.Surname : "") : "> Add")}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    title="Learner"
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={false}
                                    config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "80vh", width: "95%"}}>
                                    <LearnerForm ref={learnerRef} id={typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)} editPermission={this.props.menuPermissionItem.Edit}
                                        onChangePersonIDHandler={this.onChangePersonIDHandler}
                                        model={this.state.model}
                                        data={this.state.model}
                                        populateAddressDetails={this.state.populateAddressDetails}
                                        onChangePopulateAddressDetails={this.onChangePopulateAddressDetails}    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(Learner));


import React from 'react';
import { EntityForm } from '../../../widgets/Forms';
import { SelectItem } from '../../../widgets/SelectItem';
import ViewUtils from '../../../ViewUtils';
import { LookupField } from '../../../widgets/LookupField';
import EmployerSearch from '../../employer/EmployerSearch';
import BursaryInstitutionSearch from '../../program/BursaryInstitutionSearch';
import "./BulkAssociation.css";
import WorkplaceApprovalSearch from '../../workplaceapproval/WorkplaceApprovalSearch';


export default function BulkAssociationSearchForm(props) {
    function EmployerInstitutionWACheck(value, value2) {
        if ((value !== undefined && value !== null) || (value2 !== undefined && value2 !== null)) {
            return true
        }
        else {
            return false
        }
    }

    return (
        <EntityForm ref={React.createRef()} viewId="GrantSetup" values={props.data}
            useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                    <form className={form.classes.container} id="GrantSearchForm">   
                    <td>
                        <LookupField
                            id="EmployerID"
                            label="Employer"
                            className="filterContainer"
                            form={form}
                            disabled={EmployerInstitutionWACheck(form.values["BursaryInstitutionID"], form.values["WAID"])}
                            dohandleChangeClose={true}
                        >
                            <EmployerSearch showTitle={false} mode='lookup' dataUrl={"api/sims/" + props.controllerName + "/EmployerSearch"}
                                onLookupValueChange={(value) => {
                                    form.setLookupSelection("EmployerID", value)
                                }} autoFetchData={false} selection={props.selection} />
                        </LookupField>
                    </td>
                    <td>
                        <LookupField
                            id="WAID"
                            label="Workplace Approval"
                            className="w-100"
                            form={form}
                            disabled={EmployerInstitutionWACheck(form.values["BursaryInstitutionID"], form.values["EmployerID"])}
                            dohandleChangeClose={true}
                        >
                            <WorkplaceApprovalSearch showTitle={false} mode='lookup' dataUrl="api/sims/GenerateClaim/WorkplaceApprovalSearch"
                                onLookupValueChange={(value) => {
                                    form.setLookupSelection("WAID", value)
                                }} autoFetchData={false} selection={props.selection} />
                        </LookupField>
                    </td>
                    <td>
                        <LookupField
                            id="BursaryInstitutionID"
                            label="Bursary Institution"
                            className="filterContainer"
                            form={form}
                            disabled={EmployerInstitutionWACheck(form.values["EmployerID"], form.values["WAID"])}
                            dohandleChangeClose={true}
                        >
                            <BursaryInstitutionSearch showTitle={false} mode='lookup' dataUrl={"api/sims/BursaryApplication/GetBursaryInstitution"}
                                onLookupValueChange={(value) => {
                                    form.setLookupSelection("BursaryInstitutionID", value)
                                }} autoFetchData={false} selection={props.selection} />
                        </LookupField>
                    </td>
                    <td>
                        <SelectItem
                            dataUrl={"api/sims/" + props.controllerName + "/GetLookupList?listId=FinancialYear"}
                            id="FinancialYearID"
                            label="Financial Year"
                            className="filterContainer"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                        />
                    </td>
                    <td>
                        <SelectItem
                            dataUrl={"api/sims/" + props.controllerName + "/GetLookupList?listId=DiscretionaryGrantType"}
                            id="DiscretionaryGrantTypeID"
                            label="Intervention Type"
                            className="filterContainer"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                        />
                    </td>
                    <td>
                        <SelectItem
                            dataUrl={"api/sims/" + props.controllerName + "/GetLookupList?listId=SocioEconomicStatus"}
                            id="SocioEconomicStatusID"
                            label="Socio Economic Status"
                            className="filterContainer"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                        />
                    </td>
                    <br>
                        </br>
                </form>
            }
        </EntityForm>
    );
}

import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Person.css";
import PersonSearchForm from './PersonSearchForm';
import SearchView from "../../widgets/SearchView";
import CardLayout from '../../widgets/Card';
import ViewUtils from '../../ViewUtils';

const headCells = [
    { id: 'FirstName', numeric: false, disablePadding: true, label: 'First Name' },
    { id: 'MiddleName', numeric: false, disablePadding: true, label: 'Middle Name' },
    { id: 'Surname', numeric: false, disablePadding: true, label: 'Surname' },
    { id: 'IdNumber', numeric: false, disablePadding: true, label: 'ID Number' }
];

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon' },
                { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon' }
            ]
        }
    ]
};

const utils = new ViewUtils();


class PersonSearch extends Component {

    constructor(props) {
        super(props)
        console.log(SearchView);
    }

    state = {
        model: {},
        searchParameters: [{ Name: "FirstName", Value: "" },
        { Name: "MiddleName", Value: "" },
        { Name: "Surname", Value: "" },
        { Name: "IDNo", Value: "" }],
        selection: [],
        keyUpSwitch: false
    };

    onkeyup(e) {
        //console.log(e.charCode)
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {

        return (
            <CardLayout title='Person'>
                <SearchView headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/person/GetSearch"}
                    entityViewPath='/person' addViewPath='/addPersonFlow'
                    model={this.state.model} toolbarConfig={toolbarConfig} searchParameters={this.state.searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="FirstName"
                    SecondDescriptionProp="Surname" mode={this.props.mode}
                    history={this.props.history}
                    keyUpSwitch={this.state.keyUpSwitch}
                    multiSelect={this.props.multiSelect}
                    title='Person' 
                >
                    <PersonSearchForm model={this.state.model} onKeyUp={(e) => { this.onkeyup(e) }} />
                </SearchView>
            </CardLayout>
        );
    }
}

export default withRouter(
    connect()(PersonSearch)
);

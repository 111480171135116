import React from 'react';
import { EntityForm } from '../../../widgets/Forms';
import { SelectItem } from '../../../widgets/SelectItem';
import { LookupField } from '../../../widgets/LookupField';
import EmployerSearch from '../../employer/EmployerSearch';

export default function BatchSearchForm(props) {

    return (
        <EntityForm ref={React.createRef()} viewId="BatchSearchForm" values={props.data}
            useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="BatchSearchForm">
                    <tr>
                        <td>
                            <LookupField
                                id="EmployerID"
                                label="Employer"
                                className="w-100"
                                form={form}
                            >
                                <EmployerSearch showTitle={false} mode='lookup' dataUrl={"api/sims/" + props.controllerName + "/EmployerSearch"}
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("EmployerID", value)
                                    }} autoFetchData={false} selection={props.selection} />
                            </LookupField>
                        </td>
                    </tr>
                    <tr>
                    <td>
                        <SelectItem
                                dataUrl={"api/sims/" + props.controllerName + "/GetLookupList?listId=TrancheCode"}
                                id="TrancheCodeID"
                                label="Grant Type"
                            className="w-250"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                        />
                    </td>
                    </tr>
                    <br>
                    </br>
                </form>
            }
        </EntityForm>
    );
}

import { withRouter } from 'react-router-dom';
import React, { Component } from "react";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "../../View.jsx";
import ViewUtils from '../../ViewUtils';
import EmployerLearnerProgrammesSearchForm from './EmployerLearnerProgrammesSearchForm';
import EnhancedToolbar from '../../widgets/Toolbar';
import EnhancedTable from '../../widgets/ResponsiveTable';
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";

import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const utils = new ViewUtils();
const toolbarRef = React.createRef();


const headCells = [
    { id: 'FirstName', numeric: false, disablePadding: true, label: 'First Name' },
    { id: 'Surname', numeric: false, disablePadding: true, label: 'Surname' },
    { id: 'IDNumber', numeric: false, disablePadding: true, label: 'ID Number' },
    { id: 'ProgrammeCode', numeric: false, disablePadding: true, label: 'Programme Code' },
    { id: 'ProgrammeTitle', numeric: false, disablePadding: true, label: 'Programme Title' },
    { id: 'ProgrammeStatus', numeric: false, disablePadding: true, label: 'Programme Status' },
];

const useStyles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        color: 'white',
    },
    table: {
        minWidth: 700,
    },
});

class EmployerLearnerProgrammes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: {
                Id: this.props.location.state.selection
            },
            editDialogOpen: false,
            selectedId: null,
            searchParameters: [{ Name: "EmployerID", Value: this.props.location.state.employerId }, { Name: "InterventionID", Value: 0 }],
            searchParameters2: [{ Name: "Id", Value: 0 }, { Name: "InterventionID", Value: 0 }],
            clearSelectedRow: false
        }

        this.toolbarConfig = {
            items: [

                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
            ]
        }
    }


    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/employer',
                state: {
                    selection: this.props.location.state.employerId
                }
            });
        } else {
            // this.setState({ editDialogOpen: true });
        }
    }

    handleFormValueChange(values) {

        let InterventionID = values.InterventionID.Id;
        let newsearchParameters = [...this.state.searchParameters];
        let newsearchParameters2 = [...this.state.searchParameters2];
        let temp_element = { ...newsearchParameters[1] };
        let temp_element2 = { ...newsearchParameters2[1] };
        temp_element.Value = InterventionID
        temp_element2.Value = InterventionID
        newsearchParameters[1] = temp_element;
        newsearchParameters2[1] = temp_element2;

        this.setState({
            searchParameters: JSON.parse(JSON.stringify(newsearchParameters)),
            searchParameters2: JSON.parse(JSON.stringify(newsearchParameters2)),
            InterventionID: InterventionID,
            selectedId: null,
            Message: "",
            MessageStyle: ""
        });
        toolbarRef.current.setButtonDisabled("view", true);
        toolbarRef.current.setButtonDisabled("edit", true);
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {

            let newsearchParameters2 = [...this.state.searchParameters2];
            let temp_element = { ...newsearchParameters2[0] };
            temp_element.Value = selection[0].Id
            newsearchParameters2[0] = temp_element;

            this.setState({
                searchParameters2: JSON.parse(JSON.stringify(newsearchParameters2)),
                selectedId: selection[0].Id, Message: "", clearSelectedRow: false
            }, () => {
                toolbarRef.current.setButtonDisabled("generate", selection === null || selection.length === 0);
                    let url = "api/sims/learnergrants/CheckHasGrants?Id=" + this.state.selectedId + "&DiscretionaryGrantTypeId=" + this.state.DiscretionaryGrantTypeID;
                    utils.invokeUrl(url, (response) => {
                        this.setState({
                            TrancheObject: response.Result
                        }, () => {
                                toolbarRef.current.setButtonDisabled("generate", this.state.TrancheObject==="1");
                        })
                    },
                        null,
                        {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': "Bearer " + localStorage.getItem("token")
                            }
                        }
                    )
            });
        }
        else
        {
            this.setState({
                searchParameters2: [{ Name: "Id", Value: 0 }, { Name: "InterventionID", Value: 0 }],
                selectedId: null, Message: "", clearSelectedRow: false
            })
        }
        toolbarRef.current.setButtonDisabled("view", selection === null || selection.length === 0);
        
    }

    postAddHandler(cancel, save, response) {
        if (save === true) {
            if (response.MessageType === "SUCCESS") {
                this.setState({
                    searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                    editDialogOpen: false,
                    selectedId: null,
                    Message: response.Message,
                    MessageStyle: "success",
                    clearSelectedRow: true
                });
            }
            else if (response.MessageType === "ERROR") {
                this.setState({
                    searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                    editDialogOpen: false,
                    selectedId: null,
                    Message: response.Message,
                    MessageStyle: "warning"
                });
            }
        }
        else if (cancel === true) {
            this.setState({
                editDialogOpen: false,
                selectedId: null,
                clearSelectedRow: true
            })
        }
    }

    associateGrant() {
        this.setState({ promptOpen: false, clearSelectedRow: true, selectedId: null }, () => {
            toolbarRef.current.setButtonDisabled("edit", true);
        })

        let url = "api/sims/learnergrants/AssociateLearnerGrant?Id=" + this.state.selectedId + "&DiscretionaryGrantTypeId=" + this.state.DiscretionaryGrantTypeID;
        utils.invokeUrl(url, (response) => {
            this.successCallback(response);
        },
            (response) => {
                this.error(response);
            },
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
        toolbarRef.current.setButtonDisabled("generate", true);
    }

    closePrompt = () => {
        toolbarRef.current.setButtonDisabled("edit", true);
        toolbarRef.current.setButtonDisabled("generate", true);
        this.setState({ promptOpen: false, clearSelectedRow: true, selectedId: null })
    }

    successCallback(response) {
        this.setState({ Message: response.Message, MessageStyle: response.MessageType });
    }
    error(response) {
        this.setState({ Message: response.Message, MessageStyle: response.MessageType });
    }

    onClearSelectedRow = () => {
        this.setState({ clearSelectedRow: false });
    }

    render() {

        const { classes } = this.props;
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="legend">
                    {"Employer > Learner Programmes " + (this.props.location.state !== null ? " > " + this.props.location.state.employerName + (this.props.location.state.employerSDLNo !== null ? " - " + this.props.location.state.employerSDLNo : "") : "> Add")}
                </p>

                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />

                <div className="searchResults">
                    < EmployerLearnerProgrammesSearchForm
                        valueChangeHandler={(values) => this.handleFormValueChange(values)}
                    />

                    <EnhancedToolbar highlight={false}
                        title="Learner Programmes"
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig}
                    />

                    <EnhancedTable
                        headCells={headCells}
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        dataUrl="api/sims/EmployerLearnerProgrammes/GetSearch"
                        searchParameters={this.state.searchParameters}
                        onCellSelectionChange={(rowid, headCell) => { this.FileDownload(rowid, headCell) }}
                        clearSelectedRow={this.state.clearSelectedRow} onClearSelectedRow={this.onClearSelectedRow}
                        paged={true} />
                </div>

            </ViewContainer>
        );
    }
}

export default withRouter(withStyles(useStyles())(withMenuPermission(EmployerLearnerProgrammes)));
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { LearnerMCUploadForm } from './LearnerMCUploadForm';
import DocumentUpload from '../../widgets/DocumentUpload';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import AlertItem from "../../widgets/AlertItem";
import ViewUtils from '../../ViewUtils';


const utils = new ViewUtils();

export default function LearnerMCUploadDialog(props) {

    const [open, setOpen] = React.useState(props.open);
    const [AddDiasbaled, setAddDiasbaled] = React.useState(true);
    const [Message, setMessage] = React.useState("");
    const [MessageStyle, setMessageStyle] = React.useState("");
    const formRef = React.createRef();
    const [filename, setFilename] = React.useState("");
    const [size, setSize] = React.useState(null);
    const [base64, setBase64] = React.useState(null);
    const [type, setType] = React.useState(null);

    const [model, setModel] = React.useState({
        TrancheID: null
    });
    const [clearOptions, setClearOptions] = React.useState(false);

    const handleClose = (cancel, save, response) => {
        setFilename("");
        setOpen(false);
        props.closeHandler(cancel, save, response);
    };

    const cancel = () => {
        handleClose(true, false);
    };

    const add = () => {
        let formValue = formRef.current.getValue();
        formValue.Document = {};
        formValue.Document.Name = filename;
        formValue.Document.size = size;
        formValue.Document.type = type;
        formValue.Document.base64 = base64;
        formValue.LearnerMineCommunityID = props.LearnerMineCommunityID;

        if (formValue !== null && typeof formValue !== 'undefined') {

            const url = `api/sims/LearnerMineCommunityDocument/addLearnerProgrammeDocument`;

            utils.invokeUrl(url, (response) => {
                handleClose(false, true, response);
            }, () => { handleClose(false, false); },
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    },
                    body: JSON.stringify(formValue)
                }
            );
        }
    }

    const onUploadHandler = (data) => {
        if (data) {
            setFilename(data.name);
            setSize(data.size);
            setBase64(data.base64);
            setType(data.type);
            setAddDiasbaled(false);
        }
    }

    const onChangeTrancheIDHandler = (form, id, event) => {
        let newModel = { ...form.values }
        newModel.LearnerProgrammeDocumentTypeID = null;
        setClearOptions(true);
        setModel(newModel);
    }

    const clearOptionsHandler = () => {
        setClearOptions(false);
    }


    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <div className="w-100 lookup">
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md" disableBackdropClick={true}>
                <div style={{ padding: "32px" }}>
                    <p className="legend">
                        Upload Document
                    </p>

                    <div style={{ paddingLeft: "24px", paddingRight: "44px" }}>
                        <AlertItem message={Message} alertStyle={MessageStyle} />
                    </div>

                    <div style={{ height: "80%" }}>
                        <LearnerMCUploadForm ref={formRef} className="h-100"
                            clearOptions={clearOptions}
                            clearOptionsHandler={clearOptionsHandler}
                            model={model}
                            data={model}
                            onChangeTrancheIDHandler={onChangeTrancheIDHandler}
                            LearnerMineCommunityID={props.LearnerMineCommunityID} />


                        <DocumentUpload showViewer={false} onUploadHandler={onUploadHandler} filename={filename} />
                    </div>
                    <DialogActions>
                        <Button onClick={add} color="primary" disabled={AddDiasbaled}>
                            Save
                        </Button>
                        <Button onClick={cancel} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import LinearFlowHeading from '../../widgets/LinearFlowHeading';
import AddPersonRoleSearch from './../person/AddPersonRoleSearch';
import AddCoachMentorDetails from './AddCoachMentorDetails';
import AddCoachMentorRegistrationDetails from './AddCoachMentorRegistrationDetails';
import AddCoachMentorProgrammeDetails from './AddCoachMentorProgrammeDetails';
import AddCoachMentorWorkplaceApproval from './AddCoachMentorWorkplaceApproval';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import withMenuPermission from "../../widgets/withMenuPermission";


const steps = [
    { name: "Identity Details", tooltip: "Search the system for an existing person. If one does not exist, click proceed to create a learner with a new person" },
    { name: "Coach/Mentor Details", tooltip: "Capture coach mentor details" },
    { name: "Registration Details", tooltip: "Capture registration details" },
    { name: "Workplace Approval", tooltip: "Link workplace approvals" },
    { name: "Scope", tooltip: "Capture scope details" }
];

const utils = new ViewUtils();
class AddCoachMentorFlow extends Component {
    state = {
        currentStepIndex: 0,
        data: null,
        documents: {},
        saveMessage: "",
        hasSaveError: false
    }

    navigate(index, forward) {
        if (forward) {
            if (index !== 4) {
                this.setState({ currentStepIndex: index + 1 });
            }
        } else {
            this.setState({ currentStepIndex: index - 1 });
        }
    }

    stepHandler(index, forward, data) {
        if (index === 0) {
            if (!utils.isNull(this.state.data)
                && !utils.isNull(this.state.data.personRoleDetails) && !forward) {
                this.setState({
                    data: {
                        ...this.state.data,
                        personRoleDetails: {
                            ...this.state.data.personRoleDetails,
                            Person: data.Person
                        }
                    }
                }, () => { this.navigate(index, forward) });
            } else {
                this.setState({
                    data: data
                }, () => {this.navigate(index, forward) });
            }
        } else if (index === 1) {
            if (!utils.isNull(data)) {
                this.setState({
                    data: { ...this.state.data, personRoleDetails: data }
                }, () => { this.navigate(index, forward) });
            } else if (!forward) {
                this.navigate(index, forward);
            }
        } else if (index === 2) {
            if (!utils.isNull(data)) {
                this.setState({
                    data: { ...this.state.data, registrationDetails: data }
                }, () => { this.navigate(index, forward) });
            } else if (!forward) {
                this.navigate(index, forward);
            }
        } else if (index === 3) {
            if (!utils.isNull(data)) {
                this.setState({
                    data: { ...this.state.data, workplaceApprovalDetails: data }
                }, () => { this.navigate(index, forward)});
            } else if (!forward) {
                this.navigate(index, forward);
            }
        } else if (index === 4) {
            if (!utils.isNull(data) && forward) {
                let CoachMentorData = {};
                CoachMentorData.Person = this.state.data.personRoleDetails;
                CoachMentorData.RegistrationDetails = this.state.data.registrationDetails;
                CoachMentorData.Scope = data;
                CoachMentorData.Scope.selectedIntervension = null;
                CoachMentorData.WorkplaceApprovals = this.state.data.workplaceApprovalDetails.workplaceApprovals;

                utils.invokeUrl("api/sims/coachmentor/addCoachMentor", (data) => {
                    this.props.history.push({
                        pathname: '/CoachMentorSearch'
                    })
                }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        },
                        body: JSON.stringify(CoachMentorData)
                    });
            } else {
                this.setState({
                    data: { ...this.state.data, scopeDetails: data }
                }, () => { this.navigate(index, forward) });
            }
        }
    }

    handleToolbarButtonClick(buttonId) {
    }

    componentDidMount() {
        utils.setLegend("Add Coach/Mentor");
    }

    render() {
        return (
            <ViewContainer topPadding="0">   
                <Row>
                <Col>
                <Row style={{marginBottom:"20px"}}>
                   <Col> <LinearFlowHeading steps={steps} currentIndex={this.state.currentStepIndex} /></Col>
                </Row>
                {this.state.currentStepIndex === 0 ?
                        
                        <AddPersonRoleSearch role="CoachMentor"
                           data={this.state.data}
                           alreadyExistErrorMessage="An Coach/Mentor with the specified criteria already exist"
                            stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} />
                          : null}
                        {this.state.currentStepIndex === 1 ? <AddCoachMentorDetails permissionItem={this.props.onMenuSpecificPermissionItem('/coachmentor')} data={this.state.data} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
                        {this.state.currentStepIndex === 2 ? <AddCoachMentorRegistrationDetails permissionItem={this.props.onMenuSpecificPermissionItem('/coachmentorregistrationdetails')} data={this.state.data} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
                        {this.state.currentStepIndex === 3 ? <AddCoachMentorWorkplaceApproval permissionItem={this.props.onMenuSpecificPermissionItem('/coachmentorworkplaceapproval')} data={this.state.data} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
                        {this.state.currentStepIndex === 4 ? <AddCoachMentorProgrammeDetails data={this.state.data} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
            </Col>
            </Row>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(AddCoachMentorFlow));

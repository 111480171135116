import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import SearchView from "../../../widgets/SearchView";
import withMenuPermission from "../../../widgets/withMenuPermission";
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from "../../../ViewUtils";
import AlertItem from "../../../widgets/AlertItem";
import { LinkTrancheForm } from "./LinkTrancheForm";
import FormDialog from "../../FormDialog";

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();
const updateFormRef = React.createRef();

const roleSRU = "SRU Administrator";

const headCells = [
    { id: 'ScheduleItem', numeric: false, disablePadding: false, label: 'Tranche' },
    { id: 'MonthOffset', numeric: false, disablePadding: false, label: 'Month Offset' },
    { id: 'MainGLAccountNumber', numeric: false, disablePadding: false, label: 'Main GL Account Number' },
    { id: 'ContraGLAccountNumber', numeric: false, disablePadding: false, label: 'Contra GL Account Number' },
    { id: 'TrancheAmount', numeric: false, disablePadding: false, label: 'Tranche Amount' }
];


class TrancheDetails extends Component {

    constructor(props) {
        super(props)

        this.state = {
            keyUpSwitch: false,
            showTable: true,
            linkDialogOpen: false,
            updateDialogOpen: false,
            TrancheHistoryID: this.props.location.state.GrantID,
            TrancheCodeID: this.props.location.state.TrancheCodeID,
            listedTranches: 0,
            propmtOpen: false,
            message: null,
            messageStyle: null,
            totalAmount: 0,
            selectedTrancheRec: null,
            searchParameters: [{ Name: "TrancheSetupID", Value: this.props.location.state.GrantID }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        {id: 'close', label: 'close', tooltip: 'Go back to setup', icon: 'CloseIcon'}
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        {id: 'edittranche', label: 'Edit Tranche', icon: 'EditIcon', tooltip: 'Edit Tranche Record', disabled: utils.isNull(this.state.selectedTrancheRec)}
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("linktranche" === buttonId) {
            this.setState({ linkDialogOpen: true, removeMessage: "" })
        } else if ("edittranche" === buttonId) {
            this.setState({ updateDialogOpen: true, removeMessage: "" })
        } 
        else if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/AddEditTranche',
                state: {
                    TrancheID: this.state.TrancheHistoryID,
                    hideFields: false
                }
            });
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedTrancheRec: selection[0].Id })
        }
        else {
            this.setState({ selectedTrancheRec: null, Message: "", clearSelectedRow: false })
        }
        if (utils.getCurrentRole() === roleSRU) {
            console.log(this.state.TrancheCodeID)
            if (this.state.TrancheCodeID !== "60" && this.state.TrancheCodeID !== "61" && this.state.TrancheCodeID !== "62" && this.state.TrancheCodeID !== "66") {
                toolbarRef.current.setButtonDisabled("edittranche", true);
            }
            else { toolbarRef.current.setButtonDisabled("edittranche", false); }
        } else { 
        toolbarRef.current.setButtonDisabled("edittranche", selection === null || selection.length === 0);}
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false, selectedTrancheRec: null, updateDialogOpen: false
            }, () => {
                this.setState({ showTable: true })
                //this.GetTotalAmounts();
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false,
                updateDialogOpen: false
            })
        }
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }
    

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleFormValueChange = (values) => {
      
    }


    render() {
        return (
            <ViewContainer title="">
                <p className="breadcrumbs">
                    {"Grant Setup > Tranche Details"}
                </p>
                {this.state.showTable === true && (
                <SearchView headCells={headCells} dataUrl="api/sims/TrancheDetail/GetTrancheDetailSearch" 
                    toolbarConfig={this.toolbarConfig} searchParameters={this.state.searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange} deleteUrl={"api/sims/TrancheDetail/deleteTrancheSetupPeriod"}
                    useDeletePost={true}
                    toolbarRef={toolbarRef}
                    history={this.props.history}
                    autoFetchData={true}
                    search={false}
                    keyUpSwitch={this.state.keyUpSwitch}
                    promptTitle={"Remove Tranche Link"}
                    promptContent={"Are you sure you want to unlink this record?"}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                >

                        <FormDialog open={this.state.updateDialogOpen}
                            title={'Update Tranche Record'}
                            formRef={updateFormRef}
                            viewRef={this}
                            closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                            dialogTitle={'Update Tranche Record'}
                            linkName={"Tranches"} saveUrl={"api/sims/TrancheDetail/TrancheSetupPeriodUpdate"}
                            usePost={true} id={this.props.location.state.GrantID}
                        >

                            <LinkTrancheForm ref={updateFormRef} dataURL='api/sims/TrancheDetail/GetTrancheSetupPeriodDetails' id={this.state.selectedTrancheRec} />
                        </FormDialog>
                </SearchView>
                )}
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(TrancheDetails));


import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import withMenuPermission from "../../../widgets/withMenuPermission";
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from "../../../ViewUtils";
import AlertItem from "../../../widgets/AlertItem";
import { LinkAccountForm } from "./LinkAccountForm";
import FormDialog from "../../../etqa/FormDialog";
import EnhancedToolbar from '../../../widgets/Toolbar';
import EnhancedTable from '../../../widgets/ResponsiveTable';

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();
const updateFormRef = React.createRef();

const headCells = [
    { id: 'Chamber', numeric: false, disablePadding: false, label: 'Chamber' },
    { id: 'MainGLAccountNumber', numeric: false, disablePadding: false, label: 'Main GL Account Number' },
    { id: 'ContraGLAccountNumber', numeric: false, disablePadding: false, label: 'Contra GL Account Number' }
];


class GrantTypeAccountDetails extends Component {

    constructor(props) {
        super(props)

        this.state = {
            keyUpSwitch: false,
            showTable: true,
            updateDialogOpen: false,
            GrantTypeID: this.props.location.state.GrantTypeID,
            propmtOpen: false,
            Message: null,
            MessageStyle: null,
            totalAmount: 0,
            selectedAccount: null,
            searchParameters: [{ Name: "GrantTypeID", Value: this.props.location.state.GrantTypeID }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        {id: 'close', label: 'close', tooltip: 'Go back to setup', icon: 'CloseIcon'}
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        {id: 'editaccount', label: 'Edit Account', icon: 'EditIcon', tooltip: 'Edit Account Record', disabled: utils.isNull(this.state.selectedAccount)}
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("editaccount" === buttonId) {
            this.setState({ updateDialogOpen: true, removeMessage: "" })
        } 
        else if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/GrantTypeDetails',
                state: {
                    GrantTypeID: this.state.GrantTypeID,
                    hideFields: false
                }
            });
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
        this.setState({ selectedAccount: selection[0].Id })
        }
        else {
            this.setState({ selectedAccount: null, Message: "", clearSelectedRow: false })
        }
        toolbarRef.current.setButtonDisabled("editaccount", selection === null || selection.length === 0);
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                selectedAccount: null, updateDialogOpen: false,
                clearSelectedRow: true
            }, () => {
                this.setState({ showTable: true })
            });
        }
        else if (isCancel === true) {
            this.setState({
                updateDialogOpen: false,
                selectedAccount: null,
                clearSelectedRow: true
            })
        }
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }
    

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleFormValueChange = (values) => {
      
    }

    onClearSelectedRow = () => {
        this.setState({ clearSelectedRow: false });
    }


    render() {
        return (
            <ViewContainer title="">
                <p className="breadcrumbs">
                    {"Grant Type > Account Details"}
                </p>
                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />
                <EnhancedToolbar highlight={false}
                    title="Grant Type Account Details"
                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                    ref={toolbarRef}
                    config={this.toolbarConfig}
                />

                {this.state.showTable === true && (
                    <EnhancedTable
                        headCells={headCells}
                        onSelectionChange={(selection) => { this.handleSelection(selection) }}
                        dataUrl="api/sims/GrantTypeAccountDetails/GetGrantTypeAccount"
                        searchParameters={this.state.searchParameters}
                        clearSelectedRow={this.state.clearSelectedRow} onClearSelectedRow={this.onClearSelectedRow}
                        paged={false} />
                )}
                        <FormDialog open={this.state.updateDialogOpen}
                            title={'Update Account Record'}
                            formRef={updateFormRef}
                            viewRef={this}
                            closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                            dialogTitle={'Update Account Record'}
                            linkName={"Account"} saveUrl={"api/sims/GrantTypeAccountDetails/GrantTypeAccountUpdate"}
                            usePut={true} id={this.state.selectedAccount}
                        >

                            <LinkAccountForm ref={updateFormRef} id={this.state.selectedAccount} />
                        </FormDialog>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(GrantTypeAccountDetails));


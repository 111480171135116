import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import EnhancedToolbar from '../../../widgets/Toolbar';
import ViewUtils from "./../../../ViewUtils";
import { ViewContainer } from "./../../../View.jsx";
import AlertItem from "../../../widgets/AlertItem";
import EnhancedTable from '../../../widgets/ResponsiveTable';
import withMenuPermission from "../../../widgets/withMenuPermission";

const utils = new ViewUtils();

const headCells = [
    { id: 'LearnershipCode', numeric: false, disablePadding: true, label: 'Learnership Code' },
    { id: 'LearnershipTitle', numeric: false, disablePadding: true, label: 'Learnership Title' }
];

const toolbarRef = React.createRef();
class QCTOQualificationLearnershipSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            addDialogOpen: false,
            selectedLearnership: null,
            searchParameters: [{ Name: "QualificationID", Value: props.location.state.qualificationId }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
            ]
        };
    }


    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/qctoQualificationSetup',
                state: { selection: this.props.location.state.qualificationId, id: this.props.location.state.qualificationId }
            });
        }
    }

    handleToolbarButtonMenuClick(menu) {
        this.setState({ linkDialogOpen: true, selectedLinkType: menu });
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedLearnership: selection[0].Id, })
        }
        else {
            this.setState({ selectedLearnership: null, })
        }
    }

    render() {
        return (
            <ViewContainer>
                <p className="breadcrumbs">
                    {"QCTO Qualification > Learnerships"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <div className="searchResults">
                    <EnhancedToolbar highlight={false} ref={toolbarRef}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)}
                        config={this.toolbarConfig} />
                    <EnhancedTable headCells={headCells} onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        dataUrl="api/sims/QCTOQualificationSetupLearnership/GetQCTOQualificationLearnerships"
                        searchParameters={this.state.searchParameters}
                        onCellSelectionChange={(rowid, headCell) => { this.FileDownload(rowid, headCell) }}
                        paged={true} />
                </div>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(QCTOQualificationLearnershipSearch));
import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../../../widgets/Toolbar';
import "./../../../../Grid.css";
import "./../../../../Form.css";
import "./../../../../App.css";
import '../../Style.css'
import { ProvincialDetailsForm } from './ProvincialDetailsForm';
import ViewUtils from '../../../../ViewUtils';
import AlertItem from "../../../../widgets/AlertItem";
import Button from '@material-ui/core/Button';
import EnhancedTable from '../../../../widgets/ResponsiveTable';
import { ViewContainer } from "./../../../../View.jsx";
import Prompt from '../../../../widgets/Prompt';


const utils = new ViewUtils();

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'Back to WSP/ATR Forms', tooltip: '' }
            ]
        }
    ]
};

const formRef = React.createRef();

const headCells = [
    { id: 'WesternCape', numeric: false, disablePadding: true, label: 'Western Cape', widthRatio: .100 },
    { id: 'NorthernCape', numeric: false, disablePadding: true, label: 'Northern Cape', widthRatio: .100 },
    { id: 'EasternCape', numeric: false, disablePadding: true, label: 'Eastern Cape', widthRatio: .100 },
    { id: 'FreeState', numeric: false, disablePadding: true, label: 'Free State', widthRatio: .100 },
    { id: 'Gauteng', numeric: false, disablePadding: true, label: 'Gauteng', widthRatio: .100 },
    { id: 'Mpumalanga', numeric: false, disablePadding: true, label: 'Mpumalanga', widthRatio: .100 },
    { id: 'Limpopo', numeric: false, disablePadding: true, label: 'Limpopo', widthRatio: .100 },
    { id: 'NorthWest', numeric: false, disablePadding: true, label: 'North West', widthRatio: .100 },
    { id: 'KZN', numeric: false, disablePadding: true, label: 'Kwa Zulu-Natal', widthRatio: .100 },
    { id: 'Total', numeric: false, disablePadding: true, label: 'Total', widthRatio: .100 },

];

class ProvincialDetails extends Component {

    state = {
        organisationDetails: {
            LegalName: "",
        },
        message: "",
        messageStyle: "",
        title: "",
        id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
        organisationID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.organisationID)) ? this.props.location.state.organisationID : null,
        WSPATRID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.WSPATRID)) ? this.props.location.state.WSPATRID : null,
        WSPATRFormsID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.WSPATRFormsID)) ? this.props.location.state.WSPATRFormsID : null,
        WSPATRFormName: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.WSPATRFormName)) ? this.props.location.state.WSPATRFormName : null,
        RecordID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.RecordID)) ? this.props.location.state.RecordID : null,
        NQFLevel: null,
        open: null,
        searchParameters: [{ Name: "WSPATRID", Value: this.props.location.state.WSPATRID }, { Name: "WSPATRFormsID", Value: this.props.location.state.WSPATRFormsID }],
        adddisabled: false,
        editdisabled: true,
        canceldisabled: true,
        EditFormData: null,
        formPermission: "Enabled",
        addDefaultObject: {
            WesternCape: 0,
            NorthernCape: 0,
            EasternCape: 0,
            FreeState: 0,
            Gauteng: 0,
            Mpumalanga: 0,
            Limpopo: 0,
            NorthWest: 0,
            KZN: 0,
            Total: 0
        }
    };

    add = () => {

        let formValue = formRef.current.getValue();

        if (formValue !== null && typeof formValue !== 'undefined') {

            const ProvincialDetails = this.CheckProvincialDetailsRules(formValue);

            if (ProvincialDetails) {

                formValue.WSPATRID = this.state.WSPATRID;
                formValue.WSPATRFormsID = this.state.WSPATRFormsID;

                const url = `api/sims/WSPATRForms2022/AddUpdateWSPATRProvincialDetails`;
                utils.saveForm(this, formRef, url, (response) => {

                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response);
                    this.edit();
                }, this.error);
            }
        }
    }

    CheckProvincialDetailsRules = (formValue) => {
        let ProvincialDetails = true;
        let ErrorMessage = "";

        if (parseInt(formValue.WesternCape) + parseInt(formValue.NorthernCape) + parseInt(formValue.EasternCape) + parseInt(formValue.FreeState) +
            parseInt(formValue.Gauteng) + parseInt(formValue.Mpumalanga) + parseInt(formValue.Limpopo) + parseInt(formValue.NorthWest) + parseInt(formValue.KZN) === 0) {
            ErrorMessage = "Provincial Details: Cannot be zero.";
            ProvincialDetails = false;
        }

        if ((parseInt(formValue.WesternCape) < 0) || (parseInt(formValue.NorthernCape) < 0) || (parseInt(formValue.EasternCape) < 0) || (parseInt(formValue.FreeState) < 0) ||
            (parseInt(formValue.Limpopo) < 0) || (parseInt(formValue.NorthWest) < 0) || (parseInt(formValue.NorthWest) < 0) || (parseInt(formValue.KZN) < 0)) {
            ErrorMessage = "Provincial Details: Negative numbers are not allowed.";
            ProvincialDetails = false;
        }

        if (!ProvincialDetails) { this.setState({ message: ErrorMessage, messageStyle: "warning" }) }

        return ProvincialDetails;
    }

    edit = () => {

        utils.invokeUrl("api/sims/WSPATRForms2022/GetWSPATRProvincialDetailsFind?WSPATRID=" + this.state.WSPATRID + "&WSPATRFormsID=" + this.state.WSPATRFormsID, (response) => {
            response.WSPATRID = this.state.WSPATRID;
            response.WSPATRFormsID = this.state.WSPATRFormsID;
            this.setState({
                RecordID: JSON.parse(JSON.stringify(this.state.RecordID)),
                adddisabled: false,
                editdisabled: true,
                canceldisabled: false,
                EditFormData: response
            });

        }, this.error);
    }

    cancel = () => {
        this.setState({
            adddisabled: false,
            editdisabled: true,
            canceldisabled: true,
            EditFormData: null,
            clearSelectedRow: true,
            RecordID: null,
            message: "",
            messageStyle: ""
        });
    }

    successCallback(response) {
        if (response.MessageType === "SUCCESS") {
            this.setState({
                addDefaultObject: JSON.parse(JSON.stringify(this.state.addDefaultObject)),
                message: response.Message, messageStyle: response.MessageType,
                adddisabled: false,
                editdisabled: true,
                canceldisabled: true,
                EditFormData: null
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }
    error(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    handleFormValueChange(values) {
        this.setState({
            organisationDetails: {
                values
            }
        });
    }

    handleFormValueChangeAdd(values) {

        this.setState({
            organisationDetails: {
                values
            }
        });
    }

    handleSelectionChange = (selection) => {
        if (selection[0].Id > "0") {

            this.setState({
                RecordID: selection[0].Id, Message: "",
                adddisabled: false,
                editdisabled: false,
                canceldisabled: false,
                reportName: "",
                message: "",
                messageStyle: ""
            });
        }
        else {
            this.setState({
                Message: "",
                adddisabled: false,
                editdisabled: true,
                canceldisabled: true
            });
        }

        if (this.state.formPermission !== "Enabled") {
            this.setState({
                adddisabled: true,
                editdisabled: true,
                canceldisabled: true
            })
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else {
            this.close();
        }
    }


    close() {
        this.props.history.push({
            pathname: '/WspAtrForms',
            state: {
                organisationID: this.state.organisationID,
                organisationName: this.state.OrganisationName,
                sdlnumber: this.state.SDLNumber,
                WSPATRID: this.props.location.state.WSPATRID
            }
        });
    }


    dataArrivedHandler = (values) => {
        console.log(values);
        if (!utils.isNull(values["ID"]) && values["ID"] !== 0) {
            this.setState({ id: values["ID"] });
        }
    }

    componentDidMount() {

        this.getFormHead()
        this.GetWSPATRFormPermissionCheck()
        this.edit()
    }

    getFormHead() {
        let url = "api/sims/wspatr/GetFindHeader?WSPATRID=" + this.state.WSPATRID;
        utils.invokeUrl(url, (response) => {
            this.setState({
                FinancialYear: response.FinancialYear,
                OrganisationName: response.OrganisationName,
                WSPStatus: response.WSPStatus,
                SDLNumber: response.SDLNumber
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }
    GetWSPATRFormPermissionCheck() {
        let url = "api/sims/wspatr/GetWSPATRFormPermissionCheck?WSPATRID=" + this.state.WSPATRID + "&WSPATRFormsID=" + this.state.WSPATRFormsID;
        utils.invokeUrl(url, (response) => {
            if (response.Result !== "Enabled") {
                this.setState({
                    adddisabled: true,
                    removedisabled: true,
                    removealldisabled: true,
                    editdisabled: true,
                    updatedisabled: true,
                    canceldisabled: true,
                    formPermission: response.Result
                })
            }

        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    render() {

        let CaptureFormEdit = <ProvincialDetailsForm
            ref={formRef}
            WSPATRID={this.state.WSPATRID}
            valueChangeHandler={(values) => this.handleFormValueChange(values)} dataArrivedHandler={(values) => this.dataArrivedHandler(values)}
            data={this.state.EditFormData} />;

        let CaptureFormAdd = <ProvincialDetailsForm
            ref={formRef}
            WSPATRID={this.state.WSPATRID}
            valueChangeHandler={(values) => this.handleFormValueChangeAdd(values)}
            data={this.state.addDefaultObject} />;

        if (this.state.EditFormData === null) {
            CaptureFormEdit = null;
        }
        else {
            CaptureFormAdd = null;
        }
        if (this.state.formPermission !== "Enabled") { CaptureFormAdd = null; CaptureFormEdit = null; }

        return (
            <ViewContainer mode={this.props.mode} title="">
                <div className="view w-100 h-100">
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                    <br />
                    <table width="100%" class="FormsTableHeader">
                        <tbody>
                            <tr>
                                <td>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={toolbarConfig} />
                                </td>
                                <td align="center">
                                    <b>{this.state.WSPATRFormName}</b><br />
                                    <b>{this.state.FinancialYear}</b>
                                </td>
                                <td align="right">
                                    <b>{this.state.OrganisationName} - {this.state.SDLNumber}</b>&nbsp;&nbsp;&nbsp;
                            </td>
                            </tr>
                        </tbody>
                    </table>
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td colSpan="2" className="formContainer">
                                    {CaptureFormAdd}
                                    {CaptureFormEdit}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Button variant="outlined" size="small" id="btnAdd" color="#01579B" hidden={this.state.adddisabled} onClick={this.add}>Save</Button>&nbsp;
                                <Button variant="outlined" size="small" id="btnCancel" color="#01579B" hidden={this.state.canceldisabled} onClick={this.cancel}>Cancel</Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ViewContainer>
        );
    }
}

export default withRouter(ProvincialDetails);
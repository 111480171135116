import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import { CustomTextField } from '../../widgets/CustomTextField';
import { LookupField } from '../../widgets/LookupField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from "../../ViewUtils";
import { SelectItem } from '../../widgets/SelectItem';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const utils = new ViewUtils();
export const EISADetailsHeaderForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} values={props.data} viewId="Assessment" dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            entityLookupId={props.id} org="saseta" useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="EISADetailsForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="EISA Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="LearnerName"
                                                label="Learner Name"
                                                required
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                disabled={true}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="LearnerID"
                                                label="Learner ID Number"
                                                required
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                disabled={true}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ProgramDescription"
                                                label="Programme Title"
                                                required
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                    <hr />
                                    <h5 className="w-100">Capture EISA Details Below:</h5>
                                    <br />
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="TradeTestDate"
                                                parentId={0}
                                                className="w-100"
                                                key={tabValue}
                                                label="EISA Date"
                                                required
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/LearnerEISADetails/GetLookupList?listId=ArtisansTradeTestsResults"
                                                id="ResultsID"
                                                parentId={0}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Result"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})

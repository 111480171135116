import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import LinkDialog from "../LinkDialog";
import SearchView from "../../widgets/SearchView";
import Prompt from '../../widgets/Prompt';
import QCTOSkillsProgramSetupSearchForm from '../program/QCTOSkillsProgramme/QCTOSkillsProgramSetupSearchForm';
import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";


const headCells = [
    { id: 'SkillsProgrammeCode', numeric: false, disablePadding: true, label: 'Skills Programme Title' },
    { id: 'SkillsProgrammeTitle', numeric: false, disablePadding: true, label: 'Skills Programme Title' },
    { id: 'ProgrammeStatus', numeric: false, disablePadding: true, label: 'Programme Status' },
    { id: 'SkillsProgrammeRegistrationStartDate', numeric: false, disablePadding: true, label: 'Commencement Date' },
    { id: 'SkillsProgrammeRegistrationEndDate', numeric: false, disablePadding: true, label: 'Completion Date' }
];



const utils = new ViewUtils();
const toolbarRef = React.createRef();

class LearnerQCTOSkillsProgramme extends Component {
    constructor(props) {
        super(props);

        //console.log(this.props.programmeId);
        this.searchParameters = !utils.isNull(this.props.location.state.learnerID) ? [
            { Name: "QCTOSkillsProgrammeID", Value: "" },
            { Name: "SkillsProgramTitle", Value: "" },
            { Name: "ProgrammeID", Value: this.props.programmeId },
            { Name: "LearnerID", Value: this.props.location.state.learnerID }
        ]
            :
            [
                { Name: "QCTOSkillsProgrammeID", Value: "" },
                { Name: "SkillsProgramTitle", Value: "" },
                { Name: "LearnerID", Value: this.props.location.state.learnerID }
            ];

        let model = !utils.isNull(this.props.location.state.learnerID) ?
            {
                QCTOSkillsProgrammeID: "",
                SkillsProgramTitle: "",
                ProgrammeID: this.props.programmeId,
                LearnerID: this.props.location.state.learnerID

            }
            :
            {
                QCTOSkillsProgrammeID: "",
                SkillsProgramTitle: "",
                LearnerID: this.props.location.state.learnerID
            }


        if (!utils.isNull(props.exclusions)) {
            model.Exclusions = props.exclusions;
            this.searchParameters.push({ Name: "Exclusions", Value: props.exclusions });
        }


        this.state =
        {
            model: model,
            keyUpSwitch: false,
            selectedLearnerSkillsProgram: null,
            clearSelectedRow: false

        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'view', label: '', tooltip: 'View', icon: 'ViewIcon', visible: this.props.menuPermissionItem.View, disabled: utils.isNull(this.state.selectedLearnerSkillsProgram) },
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add, disabled: utils.isNull(this.state.selectedLearnerSkillsProgram) },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', visible: this.props.menuPermissionItem.Edit, disabled: utils.isNull(this.state.selectedLearnerSkillsProgram) },
                        { id: "delete", label: "", tooltip: "Remove QCTO Skills Programme", icon: "DeleteIcon", visible: this.props.menuPermissionItem.Delete, disabled: utils.isNull(this.state.selectedLearnerSkillsProgram) },
                        { id: 'generate', label: 'Generate Skills Agreement', icon: 'DownloadIcon', path: false, tooltip: 'Generate Skills Agreement', disabled: utils.isNull(this.state.selectedLearnerSkillsProgram) },
                        { id: 'sor', label: 'Download SOR', icon: 'AssessmentIcon', path: false, tooltip: 'Download SOR', visible: this.props.menuPermissionItem.Edit, disabled: utils.isNull(this.state.selectedLearnerSkillsProgram) }
                    ]
                }
            ]
        }

        //TODO: Add url for unit standard removal
        this.deleteUrl = ""
    }


    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/learner',
                state: {
                    selection: this.props.location.state.learnerID
                }
            });
        }
        else if (buttonId === "add") {
            this.props.history.push({
                pathname: '/AddLearnerQCTOSkillsProgramme',
                state: {
                    LearnerID: this.props.location.state.learnerID,
                    QCTOSkillsProgrammeID: null,
                    hideFields: true,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.menuPermissionItem
                }
            })
        }
        else if (buttonId === "edit") {
            this.props.history.push({
                pathname: '/AddLearnerQCTOSkillsProgramme',
                state: {
                    LearnerID: this.props.location.state.learnerID,
                    QCTOSkillsProgrammeID: this.state.selectedLearnerSkillsProgram[0].Id,
                    hideFields: false,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.menuPermissionItem
                }
            })
        }
        else if (buttonId === "view") {
            this.props.history.push({
                pathname: '/AddLearnerQCTOSkillsProgramme',
                state: {
                    LearnerID: this.props.location.state.learnerID,
                    QCTOSkillsProgrammeID: this.state.selectedLearnerSkillsProgram[0].Id,
                    hideFields: false,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.menuPermissionItem,
                    viewOnly: true
                }
            })
        }

        else if (buttonId === "generate") {
            this.generateDocuments();
            toolbarRef.current.setButtonDisabled("edit", true);
            toolbarRef.current.setButtonDisabled("delete", true);
            toolbarRef.current.setButtonDisabled("generate", true);
            toolbarRef.current.setButtonDisabled("sor", true);
        }
        else if (buttonId === "sor") {
            if (!utils.isNull(this.state.selectedLearnerSkillsProgram)) {
                this.generateSOR();
                toolbarRef.current.setButtonDisabled("edit", true);
                toolbarRef.current.setButtonDisabled("delete", true);
                toolbarRef.current.setButtonDisabled("generate", true);
                toolbarRef.current.setButtonDisabled("sor", true);
            }
            else {
                this.setState({ selectedLearnerLearnership: null, clearSelectedRow: true })
            }
        }

    }

    generateSOR = () => {

        let url = "api/sims/learnerqctoskillsprogramme/GenerateSOR?Id=" + this.state.selectedLearnerSkillsProgram[0].Id;
        utils.invokeUrl(url, (response) => {
            utils.showMessage(this, response.Message, response.MessageType);
            let documentInfo = JSON.parse(response.Message);
            this.FileDownload(documentInfo.OriginalFileName);
            this.setState({ selectedLearnerSkillsProgram: null, clearSelectedRow: true })
        },
            null,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    };

    generateDocuments = () => {

        let url = "api/sims/learnerqctoskillsprogramme/GenerateLearnerSPAgreement?Id=" + this.state.selectedLearnerSkillsProgram[0].Id;
        utils.invokeUrl(url, (response) => {
            utils.showMessage(this, response.Message, response.MessageType);
            let documentInfo = JSON.parse(response.Message);
            this.FileDownload(documentInfo.OriginalFileName);
            this.setState({ selectedLearnerSkillsProgram: null, clearSelectedRow: true })
        },
            null,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    };

    FileDownload = (documentName) => {

        utils.invokeUrl("api/sims/learnerqctoskillsprogramme/DownloadLearnerAgreement?FileName=" + documentName, (response) => {

            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );

    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {

            this.setState({ selectedLearnerSkillsProgram: selection })

            if (selection[0].ProgrammeStatus === "Completed") {
                toolbarRef.current.setButtonDisabled("delete", true)
            }
            else {
                toolbarRef.current.setButtonDisabled("delete", false)
            }

            let url = "api/sims/LearnerQCTOSkillsProgramme/CheckCanEditProgramme?Id=" + selection[0].Id;
            utils.invokeUrl(url, (response) => {
                toolbarRef.current.setButtonDisabled("edit", response.Result === "0" ? true : false)
                if (selection[0].ProgrammeStatus === "Completed" || selection[0].ProgrammeStatus === "Registered") {
                    toolbarRef.current.setButtonDisabled("delete", true)
                }
                else {
                    toolbarRef.current.setButtonDisabled("delete", false)
                }
                if (selection[0].ProgrammeStatus === "Completed" ) {
                    toolbarRef.current.setButtonDisabled("sor", false)
                }
                else {
                    toolbarRef.current.setButtonDisabled("sor", true)
                }
            },
                (e) => {
                    toolbarRef.current.setButtonDisabled("edit", true)
                },
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    }
                }
            )
            //toolbarRef.current.setButtonDisabled("sor", false)
        }
        else {
            this.setState({ selectedLearnerSkillsProgram: null })
            toolbarRef.current.setButtonDisabled("edit", true)
            toolbarRef.current.setButtonDisabled("sor", true)
        }
    }


    onkeyup(e) {
        //console.log(e.charCode)
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    onClearSelectedRow = () => {
        this.setState({ clearSelectedRow: false });
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {`Learner > QCTO Skills Programmes > ${this.props.location.state.learnerNames}`}
                </p>
                <SearchView headCells={headCells} dataUrl={utils.isNull(this.props.dataUrl) ? "api/sims/learnerqctoskillsprogramme/GetSearch" : this.props.dataUrl}
                    deleteUrl={"api/sims/learnerqctoskillsprogramme/unlinkLearnerSkillsProgramme"} useDeletePost={true}
                    model={this.state.model} toolbarConfig={this.toolbarConfig}
                    toolbarRef={toolbarRef}
                    searchParameters={this.searchParameters} onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="SkillsProgramTitle" mode={this.props.mode}
                    history={this.props.history} multiSelect={this.props.multiSelect}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                    promptTitle={"Remove Learner QCTO Skills Programme"}
                    promptContent={"Are you sure you want to remove the learner QCTO Skills Programme record?"} clearSelectedRow={this.state.clearSelectedRow} onClearSelectedRow={this.onClearSelectedRow}
                >
                    <QCTOSkillsProgramSetupSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(LearnerQCTOSkillsProgramme));


import React from 'react';
import { SelectItem } from '../../../widgets/SelectItem';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from '../../../ViewUtils';
import { CustomCheckbox } from '../../../widgets/CustomCheckbox';
import { CustomTextField } from '../../../widgets/CustomTextField';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();


export const GrantImportForm = React.forwardRef((props, ref) => {
    const [dependency, setDependency] = React.useState(props.dependency);

    React.useEffect(() => {
        setDependency(props.dependency);
    }, [props.dependency]);

    return (
        <EntityForm ref={ref}
            viewId="GrantImport"
            values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            useCustomSettings={false}
            entityLookupId={null}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}
            dataURL='api/sims/GrantImport/getfind'>
            {form =>
                <form className={form.classes.container} id="GrantImportForm">

                    <table cellPadding="2" width="80%">
                        <tbody>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/sims/GrantImport/GetLookupList?listId=FinancialYear"
                                        id="FinancialYearID"
                                        label="Financial Year"
                                        className="w-100"
                                        required={true}
                                        form={form}
                                        hasDefault={true}
                                    />
                                </td>
                                <td>
                                    <SelectItem
                                        dataUrl="api/sims/GrantImport/GetLookupListGrantType?parameter="
                                        id="GrantTypeID"
                                        required={true}
                                        label="Grant Type"
                                        className="w-100"
                                        form={form}
                                        hasDependency={true}
                                        dependency={dependency}
                                        hasDefault={true}
                                        disabled={false} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField
                                        id="MinimumAmount"
                                        label="Minimum Amount"
                                        validationRegex={/^((?!0)\d{1,18}|0|\.\d{1,2})($|\.$|\.\d{1,2}$)/g}
                                        validationMessage="Please enter a valid amount."
                                        className="w-100 "
                                        required={false}
                                        form={form}
                                    />
                                </td>
                                <td>
                                    <CustomCheckbox
                                        id="IncludeNegativeValues"
                                        form={form}
                                        disabled={props.disableFields}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                        label="Include Negative Values"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})

export default GrantImportForm;

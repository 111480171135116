import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomCheckbox } from '../../../widgets/CustomCheckbox';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { LookupField } from '../../../widgets/LookupField';
import EmployerSearch from '../../employer/EmployerSearch';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../../widgets/Forms';
import LearnerNonNQFQualificationSearch from '../../program/LearnerNonNQFQualificationSearch';
import InstitutionSearch from '../../program/InstitutionSearch';
import ProgramDocuments from "../../crm/ProgramDocuments";
import ProgramDocumentsDialog from "../../crm/ProgramDocumentsDialog";
import ViewUtils from "../../../ViewUtils";
import WorkExperienceSetupSearch from '../../program/WorkExperience/WorkExperienceSetupSearch';


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



const utils = new ViewUtils();
export const LearnerWorkExperienceForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function GetIDFromValue(value) {
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }

    function CreatedStatusCheck(value, viewOnly) {

        const viewValue = !utils.isNull(viewOnly) ? viewOnly : false;

        if (viewValue) {
            return viewValue;
        }
        else if (value !== undefined && value !== null) {
            if (value.Description === "Created") {
                return false
            }
            else if (value.Description !== "Created") {
                return true
            }
        }
        else {
            return false;
        }
    }


    return (
        <EntityForm ref={ref} values={props.data} viewId="WorkExperienceSetup" dataURL="api/sims/learnerWorkExperience/GetLearnerLinkedWorkExperienceDetails"
            entityLookupId={props.id} org="saseta" useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="learnerWorkExperienceForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Work Experience Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <LookupField
                                                required={true}
                                                id="WorkExperienceID"
                                                label="Work Experience"
                                                className="w-100"
                                                disabled={!utils.isNull(props.id) ? CreatedStatusCheck(form.values["WorkExperienceStatusID"], props.viewOnly) : !utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <WorkExperienceSetupSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerWorkExperience/WorkExperienceSearch"
                                                    onLookupValueChange={(value) => form.setLookupSelection("WorkExperienceID", value)} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="StartDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Start Date"
                                                className='{classes.textField} w-100 '
                                                onChange={props.dateChange}
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="EndDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                disabled
                                                label="End Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                required={true}
                                                id="EmployerID"
                                                label="Lead Employer"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <EmployerSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerWorkExperience/EmployerSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("EmployerID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerWorkExperience/GetLookupList?listId=YesNo"
                                                id="LevyYesNoID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Employer Skills Levy"
                                                className="{classes.select} w-100"
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="AlternateEmployerID"
                                                label="Alternate Employer"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <EmployerSearch showTitle={false} mode='lookup' dataUrl="api/sims/learnerWorkExperience/EmployerSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("AlternateEmployerID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <LookupField
                                                required={true}
                                                id="QualificationID"
                                                label="Qualification"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <LearnerNonNQFQualificationSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnerWorkExperience/NonNQFQualificationSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("QualificationID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="InstitutionID"
                                                label="Institution"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="w-100"
                                                form={form}
                                                required
                                            >
                                                <InstitutionSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnerWorkExperience/GetInstitutionSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("InstitutionID", value);
                                                        form.values['PublicPrivateID'] = { Id: 1, Description: 'Public' };
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        {
                                            (!props.hideFields) ?
                                                <td >
                                                    <SelectItem
                                                        dataUrl={'api/sims/learnerWorkExperience/GetWorkExperienceStatusByRole?parameter=[{"Name":"WorkExperienceStatusId","Value":' + GetIDFromValue(form.values["WorkExperienceStatusID"]) + '}]'}
                                                        id="WorkExperienceStatusID"
                                                        parentId={0}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !props.editPermission.Edit}
                                                        required
                                                        key={tabValue}
                                                        label="Work Experience Status"
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                                :
                                                ""
                                        }
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerWorkExperience/GetLookupList?listId=YesNo"
                                                id="EmpContract"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Is the learner’s contract of employment specific to the period of the agreement?"
                                                className="{classes.select} w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerWorkExperience/GetLookupList?listId=YesNo"
                                                id="EmpContractCopy"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Does the learner have a copy of the contract of employment?"
                                                className="{classes.select} w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ResponsibleSETA"
                                                key={tabValue}
                                                label="SETA responsible for agreement"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                                required
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="RegSAQA"
                                                key={tabValue}
                                                label="Designation registered with SAQA if applicable"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="QCTO"
                                                key={tabValue}
                                                label="QCTO appointed Assessment Quality Partner (AQP) associated with the workplace-based agreement"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="CurRegNumber"
                                                key={tabValue}
                                                label="Curriculum registration number associated with the agreement"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomCheckbox
                                                id="DocumentsReceived"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="SA ID, SOR and degree/diploma received?"
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="AssPartner"
                                                key={tabValue}
                                                label="Assessment Quality Partner"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                                required
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="Occupation"
                                                key={tabValue}
                                                label="Occupation that this qualification or part qualification is related to (as per the Organizing Framework of Occupations(OFO))"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerWorkExperience/GetLookupList?listId=YesNo"
                                                id="PrevEmployed"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Were you employed by your employer before concluding this agreement?"
                                                className="{classes.select} w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerWorkExperience/GetLookupList?listId=YesNo"
                                                id="WPAgreement"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Were you party to a workplace-based learning programme agreement at any time in the past before concluding this agreement?"
                                                className="{classes.select} w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>

                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})

import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import EnhancedTable from '../../widgets/ResponsiveTable';
import ViewUtils from '../../ViewUtils';
import { TVETNCVTradeTestHeaderForm } from './TVETNCVTradeTestHeaderForm';
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";

const headCells = [
    { id: 'TradeTestDate', numeric: false, disablePadding: true, label: 'Trade Test Date' },
    { id: "TradeTestNumber", numeric: false, disablePadding: true, label: "Trade Test Number" },
    { id: "TradeTestResult", numeric: false, disablePadding: true, label: "Result" },
]


const utils = new ViewUtils();
const toolbarRef = React.createRef();
const learnerRef = React.createRef();

class ViewTVETNCVTradeTest extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                LearnerTVETNCVID: null
            },
            selectedTradeTest: null,
            keyUpSwitch: false,
            messsageAlert: null,
            messageStyle: null,
            showTable: true,
            searchParameters: [{ Name: "LearnerTVETNCVID", Value: this.props.location.state.TVETNCVID }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'submit', label: '', tooltip: 'Submit Trade Test', icon: 'UpdateIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: 'cancel', label: '', tooltip: 'Cancel', icon: 'CancelIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: 'namb', label: '', tooltip: 'NAMB', icon: 'ApproveIcon', disabled: true }
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("submit" === buttonId) {
            this.updateModules();
        }
        else if (buttonId === "close") {
            this.props.history.push({
                pathname: '/ViewTVETNCVAssessment',
                state: {
                    LearnerID: this.props.location.state.learnerID,
                    TVETNCVID: this.props.location.state.TVETNCVID,
                    learnerNames: this.props.location.state.learnerNames
                }
            })
        }
        else if ("namb" === buttonId) {
            this.props.history.push({
                pathname: '/ViewTVETNCVNAMB',
                state: {
                    TVETNCVID: this.props.location.state.TVETNCVID,
                    LearnerID: this.props.location.state.learnerID,
                    learnerNames: this.props.location.state.learnerNames
                }
            });
        }
        else if (buttonId === "edit") {
            this.edit();
        }
        else if (buttonId === "cancel") {
            this.cancel();
        }
    }

    handleToolbarButtonClickClose = (buttonId) => {
        if ("close" === buttonId) {

        }
    }

    cancel = () => {
        this.setState({ model: null, selectedTradeTest: null, showTable: false }, () => {
            this.setState({ showTable: true })
        })
        toolbarRef.current.setButtonDisabled("submit", true);
        toolbarRef.current.setButtonDisabled("edit", true);
        toolbarRef.current.setButtonDisabled("cancel", true);
    }

    edit = () => {

        utils.invokeUrl("api/sims/LearnerTVETNCVTradeTest/GetTradeTestDetails?ID=" + this.state.selectedTradeTest[0].Id, (response) => {
            this.setState({
                model: response,
                message: "",
                messageStyle: ""
            });

        }, this.error);
        toolbarRef.current.setButtonDisabled("edit", true);
        toolbarRef.current.setButtonDisabled("edit", false);
        toolbarRef.current.setButtonDisabled("cancel", false);
    }

    CheckAddTradeTest() {
        let url = "api/sims/LearnerTVETNCVTradeTest/CheckCanAddTradeTest?ID=" + this.props.location.state.TVETNCVID;
        utils.invokeUrl(url, (response) => {
            if (response.Result === "0" && utils.isNull(this.state.selectedTradeTest)) {
                toolbarRef.current.setButtonDisabled("submit", true);
                if (utils.getCurrentRole() != "Provider" && utils.getCurrentRole() != "Agent") {
                    toolbarRef.current.setButtonDisabled("namb", false);
                }
            }

        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    CheckEditTradeTest = (tradetestId) => {
        let url = "api/sims/LearnerTVETNCVTradeTest/CheckCanEditTradeTest?ID=" + tradetestId;
        utils.invokeUrl(url, (response) => {
            if (response.Result !== "1") {
                toolbarRef.current.setButtonDisabled("edit", true);
                if (utils.getCurrentRole() != "Provider" && utils.getCurrentRole() != "Agent") {
                    toolbarRef.current.setButtonDisabled("namb", false);
                }
            }

        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    componentDidMount() {
        this.CheckAddTradeTest();
    }

    successCallback = (response) => {
        this.setState({
            showTable: false,
            message: response.Message, messageStyle: response.MessageType
        }, () => {
            this.setState({ showTable: true, message: response.Message, messageStyle: response.MessageType })
        });
        toolbarRef.current.setButtonDisabled("submit", true);
        toolbarRef.current.setButtonDisabled("edit", true);
        toolbarRef.current.setButtonDisabled("cancel", true);
    }

    updateModules() {
        const TradeTestID = this.state.selectedTradeTest;
        let data = {
            ID: TradeTestID,
            LearnerTVETNCVID: this.props.location.state.TVETNCVID,
            ...this.state.model
        }
        let input = JSON.stringify(data);

        utils.invokeUrl("api/sims/LearnerTVETNCVTradeTest/SubmitTradeTest", (response) => {
            this.successCallback(response);
        }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: input
        });
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedTradeTest: selection, Message: "" },
                () => {
                    toolbarRef.current.setButtonDisabled("edit", selection.length === 0);
                }
            )
            this.CheckEditTradeTest(selection[0].Id);
        }
        else {
            this.setState({ selectedTradeTest: null, Message: "" },
                () => {
                    toolbarRef.current.setButtonDisabled("edit", selection.length === 0);
                }
            )
        }
    }

    handleFormValueChange = (values) => {
        let newValues = null;
        if (!utils.isNull(values)) {
            newValues = { ...values };
        }
        if (!utils.isNull(newValues)) {
            if (JSON.stringify(this.state.model) !== JSON.stringify(newValues)) {
                if (!utils.isNull(newValues.TradeTestDate)) {
                    toolbarRef.current.setButtonDisabled("submit", false);
                    this.CheckAddTradeTest();
                }
                this.setState({
                    model: newValues,
                    Message: ""
                });
            }
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {`Learner > TVET NCV Trade Test > ${this.props.location.state.learnerNames}`}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                {this.state.showTable === true && (
                    <TVETNCVTradeTestHeaderForm
                        ref={learnerRef}
                        viewRef={this}
                        id={this.props.location.state.TVETNCVID}
                        valueChangeHandler={(values) => this.handleFormValueChange(values)}
                        controllerName={"LearnerTradeTest"}
                        data={this.state.model}
                        dataURL='api/sims/LearnerTVETNCVTradeTest/GetLearnerTradeTestHeaderDetails' />
                )}
                <td>
                    <EnhancedToolbar
                        ref={toolbarRef}
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />
                </td>

                <div>
                    {this.state.showTable === true && (
                        <EnhancedTable
                            headCells={headCells}
                            dataUrl="api/sims/LearnerTVETNCVTradeTest/GetLearnerTradeTests"
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}
                </div>
            </ViewContainer>
        );
    }
}

export default withRouter(connect()(withMenuPermission(ViewTVETNCVTradeTest)));
import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import LinearFlowHeading from '../../../widgets/LinearFlowHeading';
import AddPersonRoleSearch from './AddPersonRoleSearch';
import AddOnlineAssessorDetails from './AddOnlineAssessorDetails';
import OnlineAssessorQualification from './OnlineAssessorQualification';
import OnlineAssessorSkillsProgramme from './OnlineAssessorSkillsProgramme';
import AddOnlineAssessorDocuments from './AddOnlineAssessorDocuments';
import "../../../Grid.css";
import "../../../Form.css";
import "../../../App.css";
import ViewUtils from '../../../ViewUtils';
import { ViewContainer } from "../../../View.jsx";
import withMenuPermission from "../../../widgets/withMenuPermission";
import LoadingIndicator from '../../../../components/LoadingIndicator';

const steps = [
    { name: "Identity Details", tooltip: "Search the system for an existing assessor. If one does not exist, click proceed to register a new assessor" },
    { name: "Assessor Details", tooltip: "Capture assessor details" },
    { name: "Apply for Scope: Qualification", tooltip: "Capture qualification scope details" },
    { name: "Apply for Scope: Skills Programme", tooltip: "Capture skills programme scope details" },
    { name: "Documents", tooltip: "Upload the required documents to be able to submit your application" }
];

const utils = new ViewUtils();
class AddOnlineAssessorFlow extends Component {
    state = {
        currentStepIndex: 0,
        data: null,
        documents: {},
        saveMessage: "",
        key: Math.floor(100000000 + Math.random() * 900000000),
        hasSaveError: false,
        IsExternal: utils.isNull(this.props.IsExternal) ? false : this.props.IsExternal
    }

    navigate(index, forward) {
        if (forward) {
            if (index !== 4) {
                this.setState({ currentStepIndex: index + 1 });
            }
        } else {
            this.setState({ currentStepIndex: index - 1 });
        }
    }

    stepHandler(index, forward, data) {
        if (index === 0) {
            if (!utils.isNull(this.state.data) && !utils.isNull(this.state.data.personRoleDetails) && !forward) {
                this.setState({
                    data: {  ...this.state.data, personRoleDetails: { ...this.state.data.personRoleDetails, Person: data.PersonDetails }
                    }
                }, () => { this.navigate(index, forward) });
            } else {
                this.setState({ data: data }, () => { this.navigate(index, forward) });
            }
        } else if (index === 1) {
            if (!utils.isNull(data)) {
                this.setState({
                    data: { ...this.state.data, personRoleDetails: data }
                }, () => { this.navigate(index, forward) });
            } else if (!forward) {
                this.navigate(index, forward);
            }
        } else if (index === 2) {
            if (!utils.isNull(data)) {
                this.setState({
                    data: { ...this.state.data, qualifications: data }
                }, () => { this.navigate(index, forward) });
            } else if (!forward) {
                this.navigate(index, forward);
            }
        }
        else if (index === 3) {
            if (!utils.isNull(data)) {
                this.setState({
                    data: { ...this.state.data, skillsprogrammes: data }
                }, () => { this.navigate(index, forward) });
            } else if (!forward) {
                this.navigate(index, forward);
            }
        } else if (index === 4) {
            if (!utils.isNull(data) && forward) {
                let assessorData = {};
                assessorData.person = this.state.data.personRoleDetails;
                assessorData.qualifications = this.state.data.qualifications.qualifications;
                assessorData.skillsprogrammes = this.state.data.skillsprogrammes.skillsprogrammes;
                assessorData.documents = data;
                assessorData.GUID = this.state.key;
                assessorData.RegistrationType = this.props.location.state.selectedRegistrationSecondaryType;

                utils.invokeUrlExternal("api/sims/OnlineAssessorRegistration/AddOnlineAssessor", (data) => {
                    this.props.history.push({
                        pathname: '/onlineRegistrationTypeSelect'
                    })
                }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                    body: JSON.stringify(assessorData)
                });

            } else {
                this.setState({
                    data: { ...this.state.data, documents: data }
                }, () => { this.navigate(index, forward) });
            }
        }
    }

    handleToolbarButtonClick(buttonId) {
    }

    componentDidMount() {
        utils.setLegend("Add Assessor");
    }

    render() {
        return (
            <ViewContainer topPadding="0">
              {this.state.IsExternal ? <LoadingIndicator /> : null}
                <Row>
                    <Col>
                        <Row style={{ marginBottom: "20px" }}>
                            <Col> <LinearFlowHeading steps={steps} currentIndex={this.state.currentStepIndex} /></Col>
                        </Row>
                        {this.state.currentStepIndex === 0 ?

                            <AddPersonRoleSearch role="OnlineAssessorRegistration" registrationType={this.props.location.state.selectedRegistrationSecondaryType}
                                data={this.state.data} IsExternal={this.props.IsExternal}
                                alreadyExistErrorMessage="An assessor with the specified criteria already exist"
                                stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} />
                            : null}
                        {this.state.currentStepIndex === 1 ? <AddOnlineAssessorDetails IsExternal={this.props.IsExternal} data={this.state.data} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
                        {this.state.currentStepIndex === 2 ? <OnlineAssessorQualification regType={this.props.location.state.selectedRegistrationSecondaryType} data={this.state.data} IsExternal={this.props.IsExternal} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
                        {this.state.currentStepIndex === 3 ? <OnlineAssessorSkillsProgramme regType={this.props.location.state.selectedRegistrationSecondaryType} data={this.state.data} IsExternal={this.props.IsExternal} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
                        {this.state.currentStepIndex === 4 ? <AddOnlineAssessorDocuments data={this.state.data} ukey={this.state.key} IsExternal={this.props.IsExternal} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
                    </Col>
                </Row>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(AddOnlineAssessorFlow));

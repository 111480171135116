import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import LinkDialog from "../../LinkDialog";
import EnhancedToolbar from '../../../widgets/Toolbar';
import ModuleSetupSearch from '../Module/ModuleSetupSearch';
import ViewUtils from "./../../../ViewUtils";
import { ViewContainer } from "./../../../View.jsx";
import Prompt from '../../../widgets/Prompt';
import AlertItem from "../../../widgets/AlertItem";
import EnhancedTable from '../../../widgets/ResponsiveTable';
import withMenuPermission from "../../../widgets/withMenuPermission";

const utils = new ViewUtils();

const headCells = [
    { id: 'ModuleCode', numeric: false, disablePadding: true, label: 'Module Code' },
    { id: 'ModuleTitle', numeric: false, disablePadding: true, label: 'Module Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' },
    { id: 'CompulsoryYesNo', numeric: false, disablePadding: true, label: 'Compulsory' }
];

const toolbarRef = React.createRef();
class TradeQualificationModuleSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            linkDialogOpen: false,
            selectedLinkType: null,
            selectedLinkModules: null,
            selectedModule: null,
            propmtOpen: false,
            promptTitle: "Unlink Module From Trade Qualification",
            promptContent: "Are you sure you want to unlink this Module from the Trade Qualification?",
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "TradeQualificationID", Value: props.location.state.TradeQualificationID }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        {
                            contextMenu: [
                                { id: 1, description: "Not Compulsory" },
                                { id: 2, description: "Compulsory" }
                            ],
                            id: 'link', label: 'Link', contextIcon: 'LinkIcon', tooltip: 'Link the selected module/s to the trade qualification', width: "60px", visible: this.props.menuPermissionItem.Add
                        },
                        { id: 'unlink', label: '', icon: 'LinkOffIcon', tooltip: 'Un-link the selected module/s from the trade qualification', visible: this.props.menuPermissionItem.Delete, disabled: utils.isNull(this.state.selectedModule) }
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/tradeQualificationSetup',
                state: { selection: this.props.location.state.TradeQualificationID, id: this.props.location.state.TradeQualificationID }
            });
        } else if ("unlink" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    successCallback = (response) => {
        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedModule: null,
                selectedLinkType: null,
                selectedLinkModules: null,
                selectedModule: null,
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
        else if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    unlinkModule(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("unlink", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedModule}&tradeQualificationId=${this.props.location.state.TradeQualificationID}`
        utils.invokeUrlDelete(fullUrl, this.successCallback, null);
    }

    handleToolbarButtonMenuClick(menu) {
        this.setState({ linkDialogOpen: true, selectedLinkType: menu });
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false, selectedModule: null
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({
                selectedModule: selection[0].Id,
                message: "",
                messageStyle: "",
            });
        }
        else {
            this.setState({
                selectedModule: null,
                message: "",
                messageStyle: "",
            });
        }
        toolbarRef.current.setButtonDisabled("unlink", selection === null || selection.length === 0);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    render() {
        return (
            <ViewContainer>
                <p className="legend">
                    {"Trade Qualification > Modules"}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar highlight={false}
                        ref={toolbarRef}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)}
                        config={this.toolbarConfig} />
                    <EnhancedTable headCells={headCells} onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        dataUrl="api/sims/TradeQualificationSetupModule/GetSearch"
                        searchParameters={this.state.searchParameters} paged={true} />
                </div>

                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title={this.props.location.state.TradequalificationTitle}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={`Link Modules to Trade Qualification: ${this.props.location.state.TradequalificationTitle}`}
                    linkName={"Modules"} postUrl={"api/sims/TradeQualificationSetupModule/linkModules"} usePost={true}
                    programmeId={this.props.location.state.TradeQualificationID}
                >
                    <ModuleSetupSearch dataUrl="api/sims/TradeQualificationSetupModule/GetSearchLinkableModules"
                        multiSelect={true} showTitle={false}
                        TradeQualificationID={this.props.location.state.TradeQualificationID}
                        mode="lookup"
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => this.unlinkModule(`api/sims/TradeQualificationSetupModule/unlinkTradeQualificationModule`)}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(TradeQualificationModuleSearch));

import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import { SelectItem } from '../../../widgets/SelectItem';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from '../../../ViewUtils';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTextField } from '../../../widgets/CustomTextField';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CommentCheck(value) {
    if (value !== undefined && value !== null) {
        if (value.Description === "Declined") {
            return true
        }
        else if (value.Description !== "Declined") {
            return false
        }
    }
}

const utils = new ViewUtils();
const GrantBudgetEditForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);

    const styles = makeStyles({
        TextArea: {
            '& .MuiOutlinedInput-inputMultiline': {
                height: "200px !important",
                width: "600px !important"
            }
        }
    })


    return (
        <EntityForm ref={ref}
            dataURL='api/sims/grantbudget/getfind'
            values={props.data}
            entityLookupId={props.id}
            entityLookupController={props.entityLookupController} org="saseta"
            useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="grantbudgetform">

                    <table cellPadding="2" width="80%">
                        <tbody>
                            <tr>
                                <td>
                                    <CustomTextField
                                        id="GrantCode"
                                        key={tabValue}
                                        label="Grant Code"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        form={form}
                                    />
                                </td>
                                <td>
                                    <CustomTextField
                                        id="Amount"
                                        key={tabValue}
                                        label="Amount"
                                        className="w-100 "
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})

export default GrantBudgetEditForm;
import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Agent.css";
import SearchView from "../../widgets/SearchView";
import AgentSearchForm from './AgentSearchForm';
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from '../../ViewUtils';
import OrganisationSearch from "../../skills/organisation/OrganisationSearch";
import OrganisationLinkDialog from "../../skills/organisation/OrganisationLinkDialog";
import withMenuPermission from "../../widgets/withMenuPermission";


export const headCells = [
    { id: 'LegalName', numeric: false, disablePadding: true, label: 'Legal Name' },
    { id: 'TradeName', numeric: false, disablePadding: true, label: 'Trade Name' },
    { id: 'SDLNumber', numeric: false, disablePadding: true, label: 'SDL Number' },
    { id: "Status", numeric: false, disablePadding: true, label: "Status" }
];

const utils = new ViewUtils();

class AgentSearch extends Component {

    constructor(props) {
        super(props);

        this.searchParameters = !utils.isNull(this.props.programmeId) ?
            //For search of agents that can be linked to a particular entity
            [
                { Name: "id", Value: this.props.programmeId },
                { Name: "LegalName", Value: "" },
                { Name: "SDLNumber", Value: "" },
                { Name: "TradeName", Value: "" }
            ]
            :
            //For search on the agent search screen
            [
                { Name: "LegalName", Value: "" },
                { Name: "SDLNumber", Value: "" },
                { Name: "TradeName", Value: "" }
            ]

        let model = !utils.isNull(this.props.programmeId) ?
            {
                LegalName: "",
                SDLNumber: "",
                TradeName: "",
                id: this.props.programmeId
            }
            :
            {
                LegalName: "",
                SDLNumber: "",
                TradeName: ""
            }


        if (!utils.isNull(props.exclusions)) {
            model.Exclusions = props.exclusions;
            this.searchParameters.push({ Name: "Exclusions", Value: props.exclusions });
        }

        this.state = {
            model: model,
            keyUpSwitch: false,
            selectedAgent: null,
            linkDialogOpen: false
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: "delete", label: "", tooltip: "Delete", icon: "Delete", disabled: true, visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        }

    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedAgent: selection })
        }
        else {
            this.setState({ selectedAgent: null })
        }
    }


    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.setState({ linkDialogOpen: true })
        }
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    render() {

        return (
            <ViewContainer title="">

                <SearchView
                    headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/agent/GetSearch"}
                    entityViewPath='/agent'
                    model={this.state.model}
                    addViewPath={"/agentSearch"}
                    toolbarConfig={this.toolbarConfig}
                    searchParameters={this.searchParameters}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    mode={this.props.mode}
                    multiSelect={this.props.multiSelect}
                    onLookupValueChange={this.props.onLookupValueChange}
                    deleteUrl={"api/sims/Agent/RemoveAgent"}
                    useDeletePost={true}
                    descriptionProp="LegalName"
                    selectionCallbackMode={this.props.selectionCallbackMode}
                    history={this.props.history}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                    promptTitle={"Remove Agent"}
                    promptContent={"Are you sure you want to remove this agent?"}
                    title='Agent'
                >
                    <AgentSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />

                </SearchView>

                <OrganisationLinkDialog
                    open={this.state.linkDialogOpen}
                    dialogTitle={"Link Organisation To Agent"}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    history={this.props.history}
                    entityViewPath={"/agent"}
                    linkName={"Agent"}
                    postUrl={"api/sims/agent/LinkOrganisationToAgent"}
                    usePost={true}
                >
                    <OrganisationSearch
                        isLinkSearch={true}
                        dataUrl="api/sims/agent/GetLinkableOrganisations"
                        mode="lookup"
                        showTitle={false}
                    />
                </OrganisationLinkDialog>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(AgentSearch));


import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./UnitStandardSetup.css";
import { UnitStandardForm } from './UnitStandardSetupForm';
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";


const unitStandardRef = React.createRef();
const utils = new ViewUtils();

class UnitStandardSetup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            unitStandardDetails: {
                SAQAUnitStandardTitle: "",
                SAQAUnitStandardCode: ""
            },
            messageStyle: "message",
            model: {
                ReplacementUnitStandardID: null,
                QualityAssuranceBodyID: { Id: 57, Description: "MQA" }
            },
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.menuPermissionItem.Edit }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'documents', label: 'DOCUMENTS', tooltip: 'Unit Standard Documents', disabled: utils.isNull(this.state.id) }
                    ]
                }
            ]
        }
    }

    handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {

            //TODO: To Be Refactored

            if (!utils.isNull(values.RegistrationStartDate)) {
                utils.autoSetDate(this, unitStandardRef, values.RegistrationStartDate, 6, 0, -1, "LastEnrolmentDate", "RegistrationEndDate")
            }
            if (!utils.isNull(values.LastEnrolmentDate)) {
                utils.autoSetDate(this, unitStandardRef, values.LastEnrolmentDate, 3, 0, -1, "LastAchievementDate", "LastEnrolmentDate")
            }

            if (!utils.isNull(values.NewRegistrationStartDate)) {
                utils.autoSetDate(this, unitStandardRef, values.NewRegistrationStartDate, 5, 0, 1, "NewRegistrationEndDate", "NewRegistrationStartDate")
            }

            if (!utils.isNull(values.NewRegistrationEndDate)) {
                utils.autoSetDate(this, unitStandardRef, values.NewRegistrationEndDate, 1, 0, -1, "NewLastEnrolmentDate", "NewRegistrationEndDate")
            }

            if (!utils.isNull(values.NewLastEnrolmentDate)) {
                utils.autoSetDate(this, unitStandardRef, values.NewLastEnrolmentDate, 3, 0, -1, "NewLastAchievementDate", "NewLastEnrolmentDate")
            }

            this.setState({
                unitstandardSetupDetails: {
                    SAQAUnitStandardCode: values.SAQAUnitStandardID,
                    SAQAUnitStandardTitle: values.SAQAUnitStandardTitle
                }
            });
        }
    }


    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("documents" === buttonId) {
            this.props.history.push({
                pathname: '/unitstandarddocuments',
                state: {
                    UnitStandardID: this.props.location.state.selection
                }
            });
        }
    }

    save() {
        //utils.saveForm(this, unitStandardRef, `api/indicium/unitStandardSetup/updateUnitStadardSetup`, null, null, "/unitStandardSetup");
        let formValue = unitStandardRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            let isAdd = true;
            if (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) {
                formValue.id = this.props.location.state.selection;
                isAdd = false;
            }
            formValue.Document = this.state.Document;
            const data = JSON.stringify(formValue);
            let url = "api/sims/unitstandardsetupdetails/updateunitstandard";
            if (!isAdd) {
                utils.invokeUrl(url, (response) => {
                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response)
                },
                    null,
                    {
                        method: 'PUT',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        },
                        body: data
                    }
                )
            } else {
                url = "api/sims/unitstandardsetupdetails/insertunitstandard";
                utils.invokeUrl(url, (response) => {
                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response)
                },
                    null,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        },
                        body: data
                    }
                )
            }
        }
    }

    closeView() {
        this.props.history.push('/unitstandardsetupSearch');
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="legend">
                    {"Unit Standard Details"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    title=""
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    <UnitStandardForm ref={unitStandardRef} data={this.state.model}
                                        id={
                                            this.state.id !== null ? this.state.id : 
                                            typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)} editPermission={this.props.menuPermissionItem.Edit } />
                                    </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(UnitStandardSetup));


import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import { LearnerSCPPIForm } from '../../etqa/program/registration/LearnerSCPPIForm';
import withMenuPermission from "../../widgets/withMenuPermission";




const SCPPIRef = React.createRef();
const utils = new ViewUtils();

class AddLearnerSCPPI extends Component {

    state = {
        SCPPIDetails: {
            SCPPIDescription: "",
            SCPPICode: ""
        },
        messageStyle: "message",
        id: null,
        showTable: true,
        Learner: this.props.location.state.LearnerID,
        CurrentProgramme: this.props.location.state.SCPPIID,
        CreateNewFlag: this.props.location.state.hideFields,
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: '', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: '', tooltip: 'Save', icon: 'SaveIcon' },
                        { id: 'documents', label: 'DOCUMENTS', tooltip: 'Documents', disabled: (this.props.location.state.hideFields), visible: !(this.props.location.state.hideFields) }
                    ]
                }
            ]
        },
        model: {},
    };

    componentDidMount() {
        if (utils.isNull(this.props.location.state.SCPPIID) || this.props.location.state.SCPPIID === 0) {
            this.defaultSponsorship()
        }

    }

    handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {

            this.setState({
                SCPPISetupDetails: {
                    SCPPICode: values.SCPPIID,
                    SCPPIDescription: values.SCPPITitle
                },
                Learner: this.props.location.state.LearnerID
            });

            this.ECCheck(values);
        }
    }

    ECCheck(values) {
        if (!utils.isNull(values)) {
            if (!utils.isNull(values.EmployerID)) {
                let url = "api/sims/learner/EmployerContactCheck?EmployerID=" + values.EmployerID.Id;
                utils.invokeUrl(url, (response) => {
                    if (response.ECMessage != "") {
                        this.setState({
                            message: response.Result,
                            messageStyle: "ERROR",
                        })
                    }
                },
                    null,
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        }
                    }
                )
            }
        }
    }


    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("documents" === buttonId) {
            this.props.history.push({
                pathname: '/learnerSCPPIDocuments',
                state: {
                    LearnerID: this.props.location.state.LearnerID,
                    LearnerSCPPIID: this.props.location.state.SCPPIID,
                    hideFields: false,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.location.state.permissions,
                    viewOnly: this.props.location.state.viewOnly
                }
            });
        }
    }

    defaultSponsorship = () => {
        if (utils.getCurrentRole() === "Provider" || utils.getCurrentRole() === "SCPPI Agent" || utils.getCurrentRole() === "Agent") {
            this.setState({
                model: {
                    SponsorshipID: { Id: 2, Description: "Industry Funded" }
                }
            })
        }
    }

    save() {
        let formValue = SCPPIRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            formValue.id = this.props.location.state.LearnerID;
            let input = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/learnerSCPPI/LinkLearnerSCPPI", (response) => {
                this.successCallback(response);
            }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: input
            });
        }
    }

    successCallback = (response) => {
        if (response.MessageType === "SUCCESS") {
            this.setState({
                showTable: false
            }, () => {
                this.setState({
                    showTable: true,
                    message: response.Message,
                    messageStyle: response.MessageType,
                    CurrentProgramme: response.EntityId,
                    CreateNewFlag: false
                })
            });
        }
        else {
            this.setState({
                message: response.Message, messageStyle: response.MessageType
            });
        }
    }

    closeView() {

        this.props.history.push({
            pathname: '/LearnerSCPPISearch',
            state: {
                learnerID: this.props.location.state.LearnerID,
                learnerNames: this.props.location.state.learnerNames
            }
        });
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {
                        (this.props.location.state.hideFields) ?
                            `Learner > Link New Short Courses Provided by Public Institution Programme > ${this.props.location.state.learnerNames}`
                            :
                            `Learner > Update Short Courses Provided by Public Institution Programme > ${this.props.location.state.learnerNames}`
                    }
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    title=""
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    {this.state.showTable === true && (
                                        <LearnerSCPPIForm ref={SCPPIRef}
                                            viewRef={this}
                                            id={this.state.CurrentProgramme}
                                            hideFields={this.state.CreateNewFlag}
                                            editPermission={this.props.location.state.permissions}
                                            viewOnly={this.props.location.state.viewOnly}
                                            valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                            model={this.state.model}
                                            data={this.state.model} />
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(AddLearnerSCPPI));

import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import { CustomTextField } from '../../widgets/CustomTextField';
import { Row, Col } from 'react-bootstrap';
import { SelectItem } from '../../widgets/SelectItem';



export default function LearnerBursarySearchForm(props) {

    return (
        <EntityForm ref={React.createRef()} viewId="BursarySetup" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form => <form className={form.classes.container} id="Bursary">

                <div className="row search-form-container">
                    <div className="col form-col">
                        <SelectItem
                            dataUrl="api/sims/learnerbursary/GetLookupList?listId=AcademicYearOfApplication"
                            id="AcademicApplicationYearID"
                            label="Academic Year"
                            className="w-100"
                            form={form} />
                    </div>
                </div>
                <br />
                <div className="row search-form-container">
                    <div className="col form-col">
                        <CustomTextField
                            id="BursaryCode"
                            label="Bursary Code"
                            className="w-100"
                            onKeyPress={props.onKeyUp}
                            InputProps={{
                                readOnly: false,
                            }}
                            form={form}
                        />
                    </div>
                    <div className="col form-col">
                        <CustomTextField
                            id="BursaryTitle"
                            label="Bursary Title"
                            className="w-100"
                            onKeyPress={props.onKeyUp}
                            InputProps={{
                                readOnly: false,
                            }}
                            form={form}
                        />
                    </div>
                </div>
            </form>
            }
        </EntityForm>
    );
}

import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import UnitStandardSetupSearch from '../program/UnitStandardSetupSearch';
import ViewUtils from "./../../ViewUtils";
import { ViewContainer } from "./../../View.jsx";
import Prompt from '../../widgets/Prompt';
import AlertItem from "../../widgets/AlertItem";
import LinkDialog from "../LinkDialog";
import EnhancedTable from '../../widgets/ResponsiveTable';

const utils = new ViewUtils();

const headCells = [
    { id: 'SAQAUnitStandardID', numeric: false, disablePadding: true, label: 'SAQA Unit Standard ID' },
    { id: 'SAQAUnitStandardTitle', numeric: false, disablePadding: true, label: 'SAQA Unit Standard Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' },
    { id: 'UnitStandardType', numeric: false, disablePadding: true, label: 'Unit Standard Type' }
];

const toolbarRef = React.createRef();
class AssessorLearnershipUSSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            linkDialogOpen: false,
            selectedLinkType: null,
            selectedLinkStandards: null,
            selectedUnitStandard: null,
            propmtOpen: false,
            promptTitle: "Unlink Unit Standard From Assessor Learnership",
            promptContent: "Are you sure you want to unlink this unit standard from the assessor learnership record?",
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "AssessorLearnershipID", Value: this.props.location.state.AssessorLearnershipID }],
            LearnershipType: props.location.state.LearnershipType,
            selectedLinkTypeDesc: null
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        {
                            contextMenu: [
                                { id: 1, description: "Elective" },
                                { id: 2, description: "Compulsory Elective" }
                            ],
                            id: 'link', label: 'Link', tooltip: 'link the selected unit standard to the assessor learnership', width: "60px"
                        },
                        { id: 'unlink', label: 'Un-Link', tooltip: 'Un-link the selected unit standard from the assessor learnership', disabled: utils.isNull(this.state.selectedUnitStandard) }
                    ]
                }
            ]
        };
    }
   

    handleToolbarButtonClick(buttonId) {
        if ("unlink" === buttonId) {
            this.setState({ promptOpen: true })
        }
        else if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/Assessorlearnerships',
                state: {
                    AssessorLearnershipID: this.state.selectedLearnership,
                    AssessorID: this.props.location.state.AssessorID,
                    AssessorName: this.props.location.state.AssessorName,
                    AssessorIDNo: this.props.location.state.AssessorIDNo
                }
            })
        }

    }

    successCallback = (response) => {
        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedUnitStandard: null,
                selectedLinkType: null,
                selectedLinkStandards: null,
                message: response.Message, messageStyle: response.MessageType.toLowerCase()
            })
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
        else if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    unlinkUnitstandard(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("unlink", true);
        })
        let fullUrl = `${url}?id=${this.state.selectedUnitStandard}&AssessorLearnershipUSID=${this.state.selectedUnitStandard}`
        utils.invokeUrlDelete(fullUrl, this.successCallback, null);
    }

    handleToolbarButtonMenuClick(menu) {
        this.setState({ linkDialogOpen: true, selectedLinkType: menu, selectedLinkTypeDesc: menu.description });
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false, selectedUnitStandard: null
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            
        this.setState({
            selectedUnitStandard: selection[0].Id,
            message: "",
            messageStyle: ""
        },
            () => {
                toolbarRef.current.setButtonDisabled("unlink", parseInt(this.state.LearnershipType) === 3)
            }
        )
        }
        else {
        this.setState({
            selectedUnitStandard: null,
            message: "",
            messageStyle: ""
        },
            () => {
                toolbarRef.current.setButtonDisabled("unlink", parseInt(this.state.LearnershipType) === 3)
            }
        )
        }
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    render() {
        return (
            <ViewContainer>
                <p className="breadcrumbs">
                    {"Assessor > Learnership > Unit Standards"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <div className="searchResults">
                    <EnhancedToolbar highlight={false} ref={toolbarRef}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)}
                        config={this.toolbarConfig} />
                    <EnhancedTable headCells={headCells} onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        dataUrl={`api/sims/assessorlearnership/GetSearchAssessorLearnershipUnitStandards`}
                        searchParameters={this.state.searchParameters} paged={false} />
                </div>

                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title={this.props.location.state.LearnershipTitle}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={`Link Unit Standards to Learnership: ${this.props.location.state.LearnershipTitle}`}
                    linkName={"UnitStandards"} postUrl={"api/sims/assessorlearnership/linkAssessorLearnershipUnitStandards"} usePost={true}
                    programmeId={this.props.location.state.AssessorLearnershipID} programmeType={this.state.selectedLinkType}
                >
                    <UnitStandardSetupSearch dataUrl="api/sims/assessorlearnership/GetSearchAssessorLinkableUnitStandards"
                        multiSelect={true} showTitle={false}
                        programmeId={this.props.location.state.AssessorLearnershipID} programmeType={this.state.selectedLinkTypeDesc}
                        mode="lookup"
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => this.unlinkUnitstandard(`api/sims/assessorlearnership/unlinkAssessorLearnershipUnitstandard`)}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(AssessorLearnershipUSSearch)
);
import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./QualificationSetup.css";
import SearchView from "../../widgets/SearchView.js";
import IIBTCPSetupSearchForm from './IIBTCP/IIBTCPSetupSearchForm.jsx';
import ViewUtils from '../../ViewUtils.js';
import { ViewContainer } from "../../View.jsx";
import withMenuPermission from "../../widgets/withMenuPermission.js";

const utils = new ViewUtils();
const headCells = [
    { id: 'Code', numeric: false, disablePadding: true, label: 'Code' },
    { id: 'Title', numeric: false, disablePadding: true, label: 'Title' }
];

class ProviderIIBTCPSearchModule extends Component {

    constructor(props) {
        super(props)

        let model = { IIBTCPCode: "", IIBTCPDescription: "", id: this.props.programmeId };

        this.searchParameters =
            [
                { Name: "id", Value: this.props.programmeId },
                { Name: "IIBTCPCode", Value: "" },
                { Name: "IIBTCPDescription", Value: "" },]


        this.state = {
            model: model,
            keyUpSwitch: false,
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: "delete", label: "", tooltip: "Remove Qualification", icon: "DeleteIcon", disabled: true, visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        };
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title={utils.isNull(this.props.showTitle) || this.props.showTitle === true ? 'Inhouse/Industry/Based Training Course' : null}>
                <SearchView headCells={headCells} dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/IIBTCPsetup/getsearch"}
                    entityViewPath='/IIBTCPsetup'
                    title='Inhouse/Industry/Based Training Course Setup' 
                    model={this.state.model} toolbarConfig={this.toolbarConfig}
                    searchParameters={this.searchParameters} onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="IIBTCPDescription" mode={this.props.mode}                   
                    keyUpSwitch={this.state.keyUpSwitch}
                    history={this.props.history}
                    multiSelect={this.props.multiSelect}
                    deleteUrl={"api/sims/IIBTCPSetup/unlinkIIBTCP"} useDeletePost={true}
                    promptTitle={"Remove Inhouse/Industry/Based Training Course Programme"} selectionCallbackMode={this.props.selectionCallbackMode}
                    promptContent={"Removing the Inhouse/Industry/Based Training Course Programme is a permanent action and the Inhouse/Industry/Based Training Course Programme will not be available going forward. Are you sure you want to remove this Inhouse/Industry/Based Training Course Programme?"}
                    >
                    <IIBTCPSetupSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(ProviderIIBTCPSearchModule));


import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from '../../../ViewUtils';
import { LookupField } from '../../../widgets/LookupField';
import OFOSearch from '../OFOSearch';
import QCTOQualificationSetupSearch from "../QCTOQualification/QCTOQualificationSetupSearch";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const utils = new ViewUtils();
export const QCTOSkillsProgrammeSetup = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const [leadsToQualification, setLeadsToQualification] = React.useState(props.leadsToQualification);

    React.useEffect(() => {
        setLeadsToQualification(props.leadsToQualification);
    }, [props.leadsToQualification]);

    return (
        <EntityForm ref={ref} viewId="skillsProgrammeSetup"
            dataURL='api/sims/QCTOskillsprogrammesetupdetails/getfind'
            values={props.data}
            entityLookupController={props.entityLookupController}
            org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="skillsProgrammeSetupForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="QCTO Skills Programme Details" {...a11yProps(0)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="SkillsProgrammeCode"
                                                key={tabValue}
                                                label="Skills Programme Code"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={props.id}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="SkillsProgrammeTitle"
                                                key={tabValue}
                                                label="Skills Programme Title"
                                                className="w-100"
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}

                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/QCTOskillsprogrammesetupdetails/GetLookupList?listId=NQFLevelID"
                                                id="NQFLevelID"
                                                required
                                                key={tabValue}
                                                label="NQF Level"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission} />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="MinimumElectiveCredits"
                                                key={tabValue}
                                                label="Minimum Elective Credits"
                                                className="w-100"
                                                required
                                                validationRegex={/^[0-9]+$/g}
                                                validationMessage="This field must be numeric"
                                                validator={{
                                                    script: (formValues) => {
                                                        return !utils.isNull(formValues)
                                                            && formValues.MinimumElectiveCredits <= 1000
                                                    }, message: "This value must be less than 1000"
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="RegistrationStartDate"
                                                key={tabValue}
                                                label="Registration Start Date"
                                                className='{classes.textField} w-100'
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="RegistrationEndDate"
                                                key={tabValue}
                                                label="Registration End Date"
                                                className='{classes.textField} w-100'
                                                required
                                                validator={{
                                                    script: (formValues) => {
                                                        return !utils.isNull(formValues)
                                                            && !utils.isNull(formValues.RegistrationStartDate)
                                                            && formValues.RegistrationStartDate.getTime() < formValues.RegistrationEndDate.getTime()
                                                    }, message: "Start date must be before the end date"
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/QCTOskillsprogrammesetupdetails/GetLookupList?listId=QualityAssuranceBodyID"
                                                id="QualityAssuranceBodyID"
                                                required
                                                key={tabValue}
                                                label="Quality Assurance Body"
                                                className="{classes.select} w-100"
                                                form={form}
                                                disabled={!props.editPermission} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/QCTOskillsprogrammesetupdetails/GetLookupList?listId=SkillsProgrammeTypeID"
                                                id="SkillsProgrammeTypeID"
                                                required
                                                key={tabValue}
                                                label="Skills Programme Type"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission} />
                                        </td>

                                    </tr>

                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="Credits"
                                                key={tabValue}
                                                label="Total Minimum Programme Credits"
                                                className="w-100"
                                                required
                                                validationRegex={/^[0-9]+$/g}
                                                validationMessage="This field must be numeric"
                                                validator={{
                                                    script: (formValues) => {
                                                        return !utils.isNull(formValues)
                                                            && formValues.Credits <= 1000
                                                    }, message: "This value must be less than 1000"
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />

                                        </td>
                                        <td>
                                            <LookupField
                                                id="OFOOccupationID"
                                                label="OFO Occupation"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            >
                                                <OFOSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/QCTOskillsprogrammesetupdetails/getofooccupationsearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("OFOOccupationID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <LookupField
                                                id="QualificationID"
                                                label="Qualification"
                                                className="w-100"
                                                form={form}

                                                required={leadsToQualification}
                                                disabled={leadsToQualification === false || !props.editPermission}
                                            >
                                                <QCTOQualificationSetupSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/QCTOskillsprogrammesetupdetails/getqualificationsearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("QualificationID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="LastEnrolmentDate"
                                                key={tabValue}
                                                label="Last Enrolment Date"
                                                className='{classes.textField} w-100'
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})

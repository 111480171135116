import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { Button } from "react-bootstrap";
import * as actions from '../../store/actions/auth';
import "./AgentSelect.css";
import { withAlert } from 'react-alert';
import LoadingIndicatorLogin from '../LoadingIndicatorLogin';
import ViewUtils from "../ViewUtils";



const utils = new ViewUtils();

class AgentSelect extends Component {

    state = {
        selectedRole: "-1",
        message: "",
        loading: true
    };

    showAlert(msg, msgType) {
        this.props.alert.show(msg, {
            timeout: 3000, 
            type: msgType,
            onOpen: () => {
               
            }, 
            onClose: () => {
                
            }
        })
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps.roleSet !== this.props.roleSet && this.props.roleSet) {
            clearTimeout(this.props.userLoginTimer);
            this.props.onGetMenuPermissions();
        }

        if (prevProps.menuPermissions !== this.props.menuPermissions && this.props.menuPermissions) {
            this.props.history.push(this.props.dashboardURL);
        }

        if (prevProps.loginRedirectToLogin !== this.props.loginRedirectToLogin && this.props.loginRedirectToLogin) {
            this.props.history.push(this.props.loginRedirectToLoginPath);
        }

        if (prevProps.loginErrorMessage !== this.props.loginErrorMessage && this.props.loginErrorMessage) {
            this.showAlert(this.props.loginErrorMessage, 'error');
        }

        if (prevProps.loginMessage !== this.props.loginMessage && this.props.loginMessage) {
            this.showAlert(this.props.loginMessage, 'error');
        }
    }

    back() {
        clearTimeout(this.props.userLoginTimer);
        this.props.history.push('/role');
    }

    select() {
        this.setState({ message: "" });
        this.props.onSelectAgent(this.props.userDetails.UserKey, this.props.userDetails.RoleKey, this.state.selectedAgent);
    }

    render() {
        return (
            <div className="role-container">
                <div className="RoleSelect">
                    <p className="errorMessage">{this.state.message}</p>
                    <p>Select a Agent</p>
                    <form className="style-2" id="scroll">
                        {this.props.userAgents.map(userAgent => (
                            <div className="form-check" key={userAgent.Id}>
                                <label>
                                    <input type="radio"
                                        value={userAgent.Id}
                                        checked={this.state.selectedAgent === userAgent.Id.toString()}
                                        onChange={(ev) => this.setState({ selectedAgent: ev.target.value })}
                                    />
                                    <span className="label-text"> {userAgent.Name} </span>
                                </label>
                            </div>
                        ))}
                    </form>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <Button
                                        block
                                        onClick={this.back.bind(this)}
                                        type="button"
                                    >
                                        BACK
                                </Button>
                                </td>
                                <td>
                                    <Button
                                        block
                                        onClick={this.select.bind(this)}
                                        className="role-btn"
                                        disabled={this.state.selectedAgent === "-1" || this.props.isLoading}
                                        type="button"
                                    >
                                        NEXT
                                </Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                </div>
                <LoadingIndicatorLogin show={this.props.isLoading} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.auth.isLoading,
        userDetails: state.auth.userDetails,
        authenticated: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.Authenticated : false,
        otpSent: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.OTPSent : false,
        loginMessage: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.Message : null,
        userAgents: !utils.isNull(state.auth.userDetails) ? !utils.isNull(state.auth.userDetails.Agents) ? state.auth.userDetails.Agents : [] : [],
        loginErrorMessage: state.auth.errorMessage,
        loginRedirectToLogin: state.auth.redirectToLogin,
        userLoginTimer: state.auth.userLoginTimer,
        roleSet: !utils.isNull(state.auth.userDetails) ? !utils.isNull(state.auth.userDetails.Token) ? true : false : false,
        menuPermissions: !utils.isNull(state.auth.menuPermissionsDetails) ? true : false,
        loginRedirectToLoginPath: state.auth.loginRedirectToLoginPath,
        dashboardURL: !utils.isNull(state.auth.menuPermissionsDetails) ? state.auth.menuPermissionsDetails.DashboardInfo.DashboardURL : null,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSelectAgent: (UserKey, RoleKey, AgentKey) => dispatch(actions.authAgentSelect(UserKey, RoleKey, AgentKey)),
        onGetMenuPermissions: () => dispatch(actions.authMenuPermission())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAlert()(AgentSelect)));

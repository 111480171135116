import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import EnhancedToolbar from '../../../widgets/Toolbar';
import LearningAreaSetupSearch from '../LearningArea/LearningAreaSetupSearch';
import LinkDialog from "../../LinkDialog";
import ViewUtils from "./../../../ViewUtils";
import { ViewContainer } from "./../../../View.jsx";
import Prompt from '../../../widgets/Prompt';
import AlertItem from "../../../widgets/AlertItem";
import EnhancedTable from '../../../widgets/ResponsiveTable';
import withMenuPermission from "../../../widgets/withMenuPermission";


const utils = new ViewUtils();


const toolbarRef = React.createRef();

class AETLearningAreaSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            linkDialogOpen: false,
            selectedLinkLearningAreas: null,
            selectedLearningArea: null,
            keyUpSwitch: false,
            propmtOpen: false,
            promptTitle: "Unlink Learning Area From AET",
            promptContent: "Are you sure you want to unlink this Learning Area from the AET programme?",
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "AETID", Value: props.location.state.AETID }],
            IsCreditBased:props.location.state.CreditBased
        }

        
        this.headCells = this.state.IsCreditBased ?
        [
            { id: 'LearningAreaCode', numeric: false, disablePadding: true, label: 'Learning Area Code' },
            { id: 'LearningAreaTitle', numeric: false, disablePadding: true, label: 'Learning Area Title' },
            { id: 'RegistrationStartDate', numeric: false, disablePadding: true, label: 'Registration Star tDate' },
            { id: 'RegistrationEndDate', numeric: false, disablePadding: true, label: 'Registration End Date' },
            { id: 'LearningAreaType', numeric: false, disablePadding: true, label: 'Learning Area Type' }
        ]
        :
        [
            { id: 'LearningAreaCode', numeric: false, disablePadding: true, label: 'Learning Area Code' },
            { id: 'LearningAreaTitle', numeric: false, disablePadding: true, label: 'Learning Area Title' },
            { id: 'RegistrationStartDate', numeric: false, disablePadding: true, label: 'Registration Star tDate' },
            { id: 'RegistrationEndDate', numeric: false, disablePadding: true, label: 'Registration End Date' }
        ];

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                this.state.IsCreditBased ?
                {
                    type: "buttonGroup",
                    buttons: [
                        {   
                        contextMenu: [
                            { id: 1, description: "Compulsory" },
                            { id: 2, description: "Elective" }
                        ],
                            
                            id: 'link', label: 'Link', contextIcon: 'LinkIcon', tooltip: 'Link the selected Learning Area to the AET programme' },
                        { id: 'unlink', label: 'UN-LINK', icon: 'LinkOffIcon', tooltip: 'Un-link the selected Learning Area from the AET programme', disabled: utils.isNull(this.state.selectedLearningArea) }
                    ]
                }
                :    
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'link', label: 'Link', contextIcon: 'LinkIcon', tooltip: 'Link the selected Learning Area to the AET programme', visible: this.props.menuPermissionItem.Add },
                        { id: 'unlink', label: 'UN-LINK', icon: 'LinkOffIcon', tooltip: 'Un-link the selected Learning Area from the AET programme', visible: this.props.menuPermissionItem.Delete, disabled: utils.isNull(this.state.selectedLearningArea) }
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/AETSetup',
                state: { selection: this.props.location.state.AETID }
            });
        } else if ("unlink" === buttonId) {
            this.setState({ promptOpen: true })
        } else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true })
        }
    }

    successCallback = (response) => {
        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedLearningArea: null,
                selectedLinkLearningAreas: null,
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            })
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
        else if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            })
        }
    }


    unlinkUnitstandard(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("unlink", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedUnitStandard}&AETId=${this.props.location.state.AETID}`
        utils.invokeUrlDelete(fullUrl, this.successCallback, null);
    }

    handleToolbarButtonMenuClick(menu) {
        this.setState({ linkDialogOpen: true, selectedLinkType: menu });
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false, selectedUnitStandard: null
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({
                selectedUnitStandard: selection[0].Id,
                message: "",
                messageStyle: ""
            });
        }
        else {
            this.setState({
                selectedUnitStandard: null,
                message: "",
                messageStyle: ""
            });
        }
        toolbarRef.current.setButtonDisabled("unlink", selection === null || selection.length === 0);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    render() {
        return (
            <ViewContainer>
                <p className="breadcrumbs">
                    {"AET > Learning Areas"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <div className="searchResults">
                    <EnhancedToolbar highlight={false}
                        ref={toolbarRef}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)}
                        config={this.toolbarConfig} />
                    <EnhancedTable headCells={this.headCells} onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        dataUrl="api/sims/AETSetupLearningArea/GetSearch"
                        searchParameters={this.state.searchParameters} paged={true} />
                </div>

                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title={this.props.location.state.AETTitle}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={`Link Learning Areas to AET Programme: ${this.props.location.state.AETTitle}`}
                    linkName={"LearningAreas"} postUrl={"api/sims/AETSetupLearningArea/linkLearningAreas"} usePost={true}
                    programmeId={this.props.location.state.AETID}
                >
                    <LearningAreaSetupSearch dataUrl="api/sims/AETSetupLearningArea/GetSearchLinkableLearningAreas"
                        multiSelect={true} showTitle={false}
                        AETID={this.props.location.state.AETID}
                        mode="lookup"
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => this.unlinkUnitstandard(`api/sims/AETSetupLearningArea/unlinkAETLearningArea`)}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>

        );
    }
}

export default withRouter(withMenuPermission(AETLearningAreaSearch));

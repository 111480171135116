import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { EntityForm } from '../../../widgets/Forms';
import { SelectItem } from '../../../widgets/SelectItem';
import ViewUtils from '../../../ViewUtils';
import { LookupField } from '../../../widgets/LookupField';
import EmployerSearch from '../../employer/EmployerSearch';
import BursaryInstitutionSearch from '../../program/BursaryInstitutionSearch';


export default function GrantAllocationSearchForm(props) {

    return (
        <EntityForm ref={React.createRef()} viewId="GrantAllocation" values={props.data}
            useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="GrantAllocationSearchForm">
                    <table cellPadding="2">
                        <tbody>
                            <td>
                                <SelectItem
                                    dataUrl={"api/sims/GrantAllocation/GetLookupListTrancheCode"}
                                    id="TrancheCodeID"
                                    label="Tranche Code"
                                    className="w-150"
                                    form={form}
                                    InputProps={{
                                        readOnly: props.disableFields
                                    }}
                                    required
                                />
                            </td>
                            <td>
                                <SelectItem
                                    dataUrl={"api/sims/GrantAllocation/GetLookupList?listId=FinancialYear"}
                                    id="FinancialYearID"
                                    label="Financial Year"
                                    className="w-150"
                                    form={form}
                                    InputProps={{
                                        readOnly: props.disableFields
                                    }}
                                    filterOptions={option => {
                                        return option.Id >= 24;
                                    }}
                                    required
                                />
                            </td>
                            <td>
                                <LookupField
                                    id="EmployerID"
                                    label="Employer"
                                    className="filterContainer"
                                    form={form}
                                    required
                                >
                                    <EmployerSearch showTitle={false} mode='lookup' dataUrl={"api/sims/" + props.controllerName + "/EmployerSearch"}
                                        onLookupValueChange={(value) => {
                                            form.setLookupSelection("EmployerID", value)
                                        }} autoFetchData={false} selection={props.selection} />
                                </LookupField>
                            </td>
                            {/*
                            <td>
                                <LookupField
                                    id="BursaryInstitutionID"
                                    label="Bursary Institution"
                                    className="filterContainer"
                                    form={form}
                                >
                                    <BursaryInstitutionSearch showTitle={false} mode='lookup' dataUrl={"api/sims/BursaryApplication/GetBursaryInstitution"}
                                        onLookupValueChange={(value) => {
                                            form.setLookupSelection("BursaryInstitutionID", value)
                                        }} autoFetchData={false} selection={props.selection} />
                                </LookupField>
                            </td>
                            */}
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
}

import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import SearchView from "../../widgets/SearchView";
import NonNQFQualificationSetupSearchForm from './NonNQFQualificationSetupSearchForm';
import { ViewContainer } from "./../../View.jsx";
import CardLayout from '../../widgets/Card';
import ViewUtils from '../../ViewUtils';

const utils = new ViewUtils();

const headCells = [
    { id: "Code", numeric: false, disablePadding: true, label: "Code" },
    { id: "Title", numeric: false, disablePadding: true, label: "Title" }
]

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'info', label: '', tooltip: 'Select a Qualification from the table and click save to add', icon: 'InfoIcon' }
            ]
        }
    ]
}

const searchParameters = [
    { Name: "Code", Value: "" },
    { Name: "Title", Value: "" }
]

class LearnerNonNQFQualificationSearch extends Component {
    state = {
        model: {
            SAQAQualificationID: "",
            SAQAQualificationTitle: ""
        },
        keyUpSwitch: false
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title={this.props.showTitle === true || utils.isNull(this.props.showTitle) ? 'Qualifications' : null}>
                <SearchView
                    config={toolbarConfig}
                    mode={this.props.mode}
                    headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/indicium/learner/nonnqfqualificationsearch"}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="Code"
                    SecondDescriptionProp="Title"
                    model={this.state.model}
                    searchParameters={searchParameters}
                    keyUpSwitch={this.state.keyUpSwitch}
                >
                    <NonNQFQualificationSetupSearchForm onKeyUp={(e) => { this.onkeyup(e) }} model={this.state.model} valueChangeHandler={(values) => {
                        if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                            this.setState({ model: values });
                        }
                    }} />
                </SearchView>
            </ViewContainer>
        )
    }
}

export default withRouter(
    connect()(LearnerNonNQFQualificationSearch)
);
import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import './Style.css'
import ViewUtils from '../../../ViewUtils';
import AlertItem from "../../../widgets/AlertItem";
import Button from '@material-ui/core/Button';
import { ViewContainer } from "./../../../View.jsx";
import EmployerFileImportDialog from './EmployerFileImportDialog';


const utils = new ViewUtils();

const formRef = React.createRef();

class EmployerFileImport extends Component {

    state = {
        message: "",
        messageStyle: "",
        title: "",
        id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
        open: null,
        bulkimportdisabled: false,
        promptOpen: false,
        promptTitle: "",
        promptContent: "",
        bulkImportOpen: false,
        showReport: false,
        reportName: ""
    };

    bulkimport = () => {
        this.setState({
            bulkimportdisabled: false,
            bulkImportOpen: true,
            showReport: false,
            reportName: "",
            message: "",
            messageStyle: ""
        });
    }


    bulkimportImport = (cancel, save, response) => {
        console.log(save);
        console.log(response);
        if (save === true) {
            if (response.MessageType === "SUCCESS") {
                this.setState({
                    bulkimportdisabled: false,
                    bulkImportOpen: false,
                    message: "Import Completed",
                    messageStyle: "success",
                    showReport: true,
                    reportName: response.Message
                });
            }
            else if (response.MessageType === "ERROR") {
                this.setState({
                    bulkimportdisabled: false,
                    bulkImportOpen: false,
                    message: response.Message,
                    messageStyle: "ERROR"
                });
            }
        }
        else if (cancel === true) {
            this.setState({
                bulkimportdisabled: false,
                RecordID: null,
                bulkImportOpen: false
            });
        }
    }

    ReportDownload() {

        utils.invokeUrl("api/sims/EmployerFileImport/BulkImportGetReport?FileName=" + this.state.reportName, (response) => {

            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();

        }, (e) => {
            this.setState({ message: 'Report Download Failed', messageStyle: 'ERROR' });
        }
        );

    };

    newemployersreport = () => {
        const url = `api/sims/EmployerFileImport/RunNewEmployersReport`;

        utils.invokeUrl(url, (response) => {
            this.newemployerreportdone(response);
        }, () => {
                this.newemployerreportdone(null);
        },
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify()
            }
        );
    }

    newemployerreportdone = (response) => {
        if (response.MessageType === "SUCCESS") {
            this.setState({
                bulkimportdisabled: false,
                bulkImportOpen: false,
                message: "Report Run Successfully",
                messageStyle: "success",
                showReport: true,
                reportName: response.Message
            });
        }
        else if (response.MessageType === "ERROR") {
            this.setState({
                bulkimportdisabled: false,
                bulkImportOpen: false,
                message: response.Message,
                messageStyle: "ERROR"
            });
        }
    }

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    render() {

        return (
            <ViewContainer mode={this.props.mode} title="">
                <div className="view w-100 h-100">
                    <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                    <br />
                    <table width="100%" class="FormsTableHeader">
                        <tbody>
                            <tr>
                                <td align="center">
                                    <b>{"Employer File Import"}</b>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <br />
                    <table width="100%" align="center">
                        <tbody>
                            <tr>
                                <td>
                                    <Button variant="outlined" size="small" id="btnAdd" color="#01579B" onClick={this.bulkimport}>Bulk Import</Button>&nbsp;
                                    <Button variant="outlined" size="small" id="btnAdd" color="#01579B" onClick={this.newemployersreport}>New Employers Report</Button>&nbsp;
                                    
                                    <div style={{ height: "80%" }}>
                                        {this.state.showReport ? <a
                                            onClick={() => { this.ReportDownload() }}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            download
                                            style={{ textDecoration: "underline" }}
                                        >Download Report</a> : null}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <EmployerFileImportDialog
                    open={this.state.bulkImportOpen}
                    closeHandler={(cancel, save, response) => this.bulkimportImport(cancel, save, response)}
                    viewRef={this}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(EmployerFileImport);
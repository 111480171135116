 import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { AddressCodeItem } from '../../widgets/AddressCodeItem';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { makeStyles } from '@material-ui/core/styles';
import { LookupField } from '../../widgets/LookupField';
import BursaryQualificationSearch from '../program/BursaryQualificationSearch';
import BursaryInstitutionSearch from '../program/BursaryInstitutionSearch';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    MuiSelectSelectMenu: {
        '& .MuiSelect-selectMenu': { paddingRight: "150px !important" }
    }
}));

function GetAppTypeID(value) {
    if (value === "employed" && value !== null) {
        return "2"
    }
    else {
        return "1"
    }
}

function OtherDisciplineCheck(value) {
    if (value !== undefined && value !== null) {
        if (value.Description === "Other") {
            return true
        }
        else {
            return false
        }
    }
}

function OtherSocialStatusCheck(value) {
    if (value !== undefined && value !== null) {
        if (value.Description === "Other") {
            return true
        }
        else if (value.Description !== "Other") {
            return false
        }
    }
}

function CurrentlyHaveBursaryNSFASYesDetailsCheck(value) {
    if (value !== undefined && value !== null) {
        if (value.Description === "Yes") {
            return true
        }
        else if (value.Description !== "No") {
            return false
        }
    }
}

function isStudentNumberRequired(value) {
    if (value !== undefined && value !== null) {
        if (value.Description === "Grade 12 learner") {
            return false
        }
        else {
            return true
        }
    }
}

function isApplicantMinor(idno) {

    var _dob2 = idno.substring(0, 2); //year
    const _dob3 = idno.substring(2, 4); //month
    const _dob4 = idno.substring(4, 6); //day

    if (Number(_dob2) <= 99 && Number(_dob2) > 80) {
        _dob2 = "19" + _dob2;
    }
    else { _dob2 = "20" + _dob2; }

    const dob = new Date(_dob3 + "/" + _dob4 + "/" + _dob2);
    const now = new Date();
    const diff = Math.abs(now - dob);
    const age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));

    if (age >= 18) {
        return false
    }
    else {
        return true
    }
    return
}

const utils = new ViewUtils();
export const BursaryApplicationUnemployedForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [IDNoValue, setIDNoValue] = React.useState(null);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const classes = useStyles();

    return (
        <EntityForm ref={ref} viewId="bursaryapplication" values={props.data} dataURL='api/sims/BursaryApplication/getBursaryApplicationDetails'
            entityLookupController={props.entityLookupController} org="saseta" IsExternal={true} socioEconomicStatus={props.socioEconomicStatus}
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="BursaryApplicationUnemplyedForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Tertiary Education: 2024 Year" wrapped {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                                <Tab label="Tertiary Education: 2025 Year" wrapped {...a11yProps(1)} style={{ color: form.fieldParentErrors[1] === true ? "red" : null }} />
                                <Tab label="Parent(s) / *Guardian(s) / Next of kin " wrapped {...a11yProps(2)} style={{ color: form.fieldParentErrors[2] === true ? "red" : null, width: "50px" }} />
                                <Tab label="Declaration" wrapped {...a11yProps(3)} style={{ color: form.fieldParentErrors[3] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel className="w-100" value={tabValue} index={0}>
                            <table cellPadding="2" width="80%" index={0}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl={'api/sims/BursaryApplication/GetBursaryApplicationType?parameter=[{"Name":"BursaryApplicationTypeID","Value":' + GetAppTypeID(props.socioEconomicStatus) + '}]'}
                                                id="BursaryApplicationTypeID"
                                                parentId={0}
                                                key={0}
                                                label="Programme"
                                                className="{classes.select} w-100"
                                                required={true}
                                                allowEmptyValue={true}
                                                form={form}
                                                checkRefreshToken={!props.IsExternal}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/BursaryApplication/GetLookupList?listId=YearOfApplicationActivity"
                                                id="YearOfApplicationActivityID"
                                                parentId={0}
                                                key={0}
                                                label="Year Of Application Activity"
                                                className="{classes.select} w-100"
                                                required={true}
                                                allowEmptyValue={true}
                                                form={form}
                                                checkRefreshToken={!props.IsExternal}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <CustomTextField
                                                id="StudentNumber"
                                                key={1}
                                                parentId={1}
                                                label="Student Number"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                                required={isStudentNumberRequired(form.values["YearOfApplicationActivityID"]) }

                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td colSpan="2">
                                            <b>SECTION B.2 - TERTIARY EDUCATION: 2025 YEAR</b> <br />
                                            <b>Name of University or TVET College which you have applied to or will continue to study at during 2025 academic year as well as your qualification</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="50%">
                                            <LookupField
                                                id="BursaryInstitutionID"
                                                required
                                                key={4}
                                                parentId={4}
                                                label="Name of Institution"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            >
                                                <BursaryInstitutionSearch showTitle={true} mode='lookup' IsExternal={true} dataUrl="api/sims/BursaryApplication/GetBursaryInstitution"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("BursaryInstitutionID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td width="50%">
                                            <LookupField
                                                id="QualificationID"
                                                required
                                                key={4}
                                                parentId={4}
                                                label="Qualification"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            >
                                                <BursaryQualificationSearch showTitle={true} mode='lookup' IsExternal={true} dataUrl="api/sims/BursaryApplication/GetBursaryQualification"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("QualificationID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/BursaryApplication/GetLookupList?listId=BursaryDiscipline"
                                                id="BursaryDisciplineID"
                                                key={2}
                                                label="Discipline"
                                                className="w-100"
                                                required={true}
                                                parentId={2}
                                                form={form}
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={!props.editPermission} />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="OtherBursaryDiscipline"
                                                key={1}
                                                parentId={1}
                                                required={OtherDisciplineCheck(form.values["BursaryDisciplineID"])}
                                                label="Other Discipline"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/BursaryApplication/GetLookupList?listId=BursaryQualificationType"
                                                id="BursaryQualificationTypeID"
                                                key={2}
                                                label="Qualification Type"
                                                className="w-100"
                                                required={true}
                                                parentId={2}
                                                form={form}
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={!props.editPermission} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/BursaryApplication/GetLookupList?listId=BursaryFollowingYrYearOfStudy"
                                                id="FollowingYrYearOfStudyID"
                                                required={true}
                                                parentId={2}
                                                key={2}
                                                label="Year Of Study in Following Year"
                                                className="w-100"
                                                form={form}
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={!props.editPermission} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            {/*<SelectItem*/}
                                            {/*    dataUrl="api/sims/BursaryApplication/GetLookupList?listId=BursaryApplicationStatus"*/}
                                            {/*    id="BursaryApplicationStatusID"*/}
                                            {/*    key={2}*/}
                                            {/*    parentId={2}*/}
                                            {/*    required={true}*/}
                                            {/*    label="Application Status"*/}
                                            {/*    className="w-100"*/}
                                            {/*    form={form}*/}
                                            {/*    checkRefreshToken={!props.IsExternal}*/}
                                            {/*    disabled={!props.editPermission} />*/}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <table cellPadding="2" width="70%">
                                <tbody>
                                    <tr>
                                        <td colSpan="2">
                                            <b>SECTION C: PARENT(S) / *GUARDIAN(S) / NEXT OF KIN</b> <br />
                                            (person responsible for the applicant: (Please Note: that a Guardian is a person(s) who are legally responsible for a minor)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            Mother
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="MotherFirstname"
                                                required
                                                key={tabValue}
                                                label="Firstname"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="MotherSurname"
                                                key={tabValue}
                                                required
                                                label="Surname"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="MotherIDNumber"
                                                key={tabValue}
                                                required
                                                label="ID Number"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                            <td>
                                                <SelectItem
                                                dataUrl="api/sims/BursaryApplication/GetLookupList?listId=SocialStatus"
                                                id="MotherEmploymentStatusID"
                                                    parentId={2}
                                                    required={true}
                                                    key={2}
                                                    label="Social Status"
                                                    className="w-100"
                                                    form={form}
                                                    checkRefreshToken={!props.IsExternal}
                                                    disabled={!props.editPermission} />
                                            </td>
                                    </tr>
                                    <tr>
                                            <td colspan="2">
                                                <CustomTextField
                                                    id="OtherSocialStatusM"
                                                    key={tabValue}
                                                    label="Other"
                                                    className="w-100 "
                                                    required={OtherSocialStatusCheck(form.values["MotherEmploymentStatusID"])}
                                                    form={form}
                                                    disabled={!utils.isNull(props.editPermission) ? !props.editPermission : !OtherSocialStatusCheck(form.values["MotherEmploymentStatusID"])}
                                                    />
                                            </td>
                                        
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            Father
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="FatherFirstname"
                                                required
                                                key={tabValue}
                                                label="Firstname"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="FatherSurname"
                                                key={tabValue}
                                                required
                                                label="Surname"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="FatherIDNumber"
                                                key={tabValue}
                                                required
                                                label="ID Number"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/BursaryApplication/GetLookupList?listId=SocialStatus"
                                                id="FatherEmploymentStatusID"
                                                parentId={2}
                                                required={true}
                                                key={2}
                                                label="Social Status"
                                                className="w-100"
                                                form={form}
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={!props.editPermission} />
                                        </td>
                                    </tr>
                                    <tr>
                                            <td colspan="2">
                                                <CustomTextField
                                                    id="OtherSocialStatusF"
                                                    key={tabValue}
                                                    label="Other"
                                                    className="w-100 "
                                                    required={OtherSocialStatusCheck(form.values["FatherEmploymentStatusID"])}
                                                    disabled={!utils.isNull(props.editPermission) ? !props.editPermission : !OtherSocialStatusCheck(form.values["FatherEmploymentStatusID"])}
                                                    form={form}
                                                    />
                                            </td>
                                        
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            Guardian
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="GuardianFirstname"
                                                required={isApplicantMinor(props.IDNumber) }
                                                key={tabValue}
                                                label="Firstname"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="GuardianSurname"
                                                required={isApplicantMinor(props.IDNumber)}
                                                key={tabValue}
                                                label="Surname"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="GuardianIDNumber"
                                                required={isApplicantMinor(props.IDNumber)}
                                                key={tabValue}
                                                label="ID Number"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/BursaryApplication/GetLookupList?listId=SocialStatus"
                                                id="GuardianEmploymentStatusID"
                                                required={isApplicantMinor(props.IDNumber)}
                                                parentId={2}
                                                key={2}
                                                label="Social Status"
                                                className="w-100"
                                                form={form}
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={!props.editPermission} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="RelationshipApplicantGuardian"
                                                required={isApplicantMinor(props.IDNumber)}
                                                key={tabValue}
                                                label="Relationship of Applicant to Guardian"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                                <CustomTextField
                                                    id="OtherSocialStatusG"
                                                    key={tabValue}
                                                    label="Other"
                                                    className="w-100 "
                                                    required={OtherSocialStatusCheck(form.values["GuardianEmploymentStatusID"])}
                                                    disabled={!utils.isNull(props.editPermission) ? !props.editPermission : !OtherSocialStatusCheck(form.values["GuardianEmploymentStatusID"])}
                                                    form={form}
                                                    />
                                            </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/BursaryApplication/GetLookupList?listId=AnnualHouseholdIncome"
                                                id="AnnualHouseholdIncomeID"
                                                parentId={2}
                                                required={true}
                                                key={2}
                                                label="Total Annual Household Income"
                                                className="w-100"
                                                form={form}
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={!props.editPermission} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/BursaryApplication/GetLookupList?listId=YesNo"
                                                id="SiblingsCurrentlyFundedMQAbursaryYesNo"
                                                parentId={2}
                                                required={true}
                                                key={2}
                                                label="Are any of your siblings being currently funded with an MQA bursary?"
                                                className="w-100"
                                                form={form}
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={!props.editPermission} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <SelectItem
                                                dataUrl="api/sims/BursaryApplication/GetLookupList?listId=YesNo"
                                                id="SiblingsCurrentlyStudyingAtUniversityTVETYesNo"
                                                parentId={2}
                                                required={true}
                                                key={2}
                                                label="Are any of your siblings currently studying or will be studying at a University or TVET College during 2025?"
                                                className="w-100"
                                                form={form}
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={!props.editPermission} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/BursaryApplication/GetLookupList?listId=YesNo"
                                                id="CurrentlyHaveBursaryNSFASYesNo"
                                                parentId={2}
                                                required={true}
                                                key={2}
                                                label="Do you currently have a bursary/NSFAS or have you been approved for a bursary/NSFAS for the 2025 academic year?"
                                                className="w-100"
                                                form={form}
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={!props.editPermission} />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="CurrentlyHaveBursaryNSFASYesDetails"
                                                key={tabValue}
                                                label="If yes, then give details of bursary"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                                required={CurrentlyHaveBursaryNSFASYesDetailsCheck(form.values["CurrentlyHaveBursaryNSFASYesNo"])}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={3}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <b><u>SECTION C: DECLARATION</u></b><br /><br />

                                            By submitting this completed form, I confirm that all the information provided is <b>TRUE AND CORRECT</b>, and I am aware that providing incorrect information and documents will result in the disqualification of my application. I also acknowledge that the awarding of a bursary is at the absolute sole discretion of the MQA, and the MQA reserves the right not to award me the bursary. I further authorise the MQA to access, from the university/ university of technology/ TVET College, all necessary academic, financial and any other relevant information that has a bearing on this application and continue to access the said information/documents should my bursary application be successful. The MQA may also share my personal information with all relevant organisations/institutions for reporting purposes.
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="DeclarationDate"
                                                key={tabValue}
                                                label="Declaration Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})


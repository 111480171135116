import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Assessor.css";
import SearchView from "../../widgets/SearchView";
import AssessorSearchForm from './AssessorSearchForm';
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from '../../ViewUtils';
import withMenuPermission from "../../widgets/withMenuPermission";


const utils = new ViewUtils();

const headCells = [
    { id: 'FirstName', numeric: false, disablePadding: true, label: 'First Name' },
    { id: 'MiddleName', numeric: false, disablePadding: true, label: 'Middle Name' },
    { id: 'Surname', numeric: false, disablePadding: true, label: 'Surname' },
    { id: 'IdNumber', numeric: false, disablePadding: true, label: 'ID Number' }
];

const searchParameters = [
    { Name: "FirstName", Value: "" },
    { Name: "MiddleName", Value: "" },
    { Name: "Surname", Value: "" },
    { Name: "IDNo", Value: "" },
    { Name: "RegistrationNumber", Value: "" },
    { Name: "ProvinceID", Value: "0" },
    { Name: "AssessorRegistrationStatusID", Value: "0" }
]

const filterParameters = [
    { dataField: "FirstName", text: "FirstName" },
    { dataField: "MiddleName", text: "MiddleName" },
    { dataField: "Surname", text: "Surname" },
    { dataField: "IDNo", text: "IDNo" },
    { dataField: "RegistrationNumber", text: "RegistrationNumber" }
]

class AssessorSearch extends Component {

    constructor(props) {
        super(props)

        this.searchParameters = this.searchParameters = !utils.isNull(this.props.programmeId) ?

            [
                { Name: "ID", Value: this.props.programmeId },
                { Name: "FirstName", Value: "" },
                { Name: "MiddleName", Value: "" },
                { Name: "Surname", Value: "" },
                { Name: "IDNo", Value: "" },
                { Name: "RegistrationNumber", Value: "" },
                { Name: "ProvinceID", Value: "0" },
                { Name: "AssessorRegistrationStatusID", Value: "0" }
            ]
            :

            [
                { Name: "FirstName", Value: "" },
                { Name: "MiddleName", Value: "" },
                { Name: "Surname", Value: "" },
                { Name: "IDNo", Value: "" },
                { Name: "RegistrationNumber", Value: "" },
                { Name: "ProvinceID", Value: "0" },
                { Name: "AssessorRegistrationStatusID", Value: "0" }
            ]

        if (!utils.isNull(this.props.programmeId)) {
            this.state = {
                model: {
                    FirstName: "",
                    MiddleName: "",
                    Surname: "",
                    IDNo: "",
                    RegistrationNumber: "",
                    ID: this.props.programmeId,
                },
                keyUpSwitch: false,
                selectedAssessor: null
            };
        }
        else {
            this.state = {
                model: {
                    FirstName: "",
                    MiddleName: "",
                    Surname: "",
                    IDNo: "",
                    RegistrationNumber: ""
                },
                keyUpSwitch: false,
                selectedAssessor: null
            };
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: "delete", label: "", tooltip: "Remove Assessor", icon: "DeleteIcon", disabled: true, visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        };
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedAssessor: selection })
        }
        else {
            this.setState({ selectedAssessor: null })
        }
    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.props.history.push({
                pathname: '/AddAssessorFlow'
            })
        }
    }

    render() {
        return (
            <ViewContainer title="">
                <SearchView headCells={headCells} dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/assessor/GetSearch"}
                    entityViewPath='/assessor' model={this.state.model}
                    toolbarConfig={this.toolbarConfig} searchParameters={this.searchParameters}
                    filterParameters={filterParameters}
                    mode={this.props.mode}
                    title='Assessor' 
                    multiSelect={this.props.multiSelect}
                    onLookupValueChange={this.props.onLookupValueChange} descriptionProp="FirstName" SecondDescriptionProp="Surname"
                    history={this.props.history}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch} deleteUrl={"api/sims/assessor/RemoveAssessor"} useDeletePost={true}
                    promptTitle={"Remove Assessor"} promptContent={"Are you sure you want to remove this assessor?"}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                >
                    <AssessorSearchForm data={this.state.model} onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }} />
                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(AssessorSearch));



import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import AlertItem from "../../../widgets/AlertItem";
import "./Institution.css";
import InstitutionSearchForm from "./InstitutionSearchForm";
import InstitutionForm from "./InstitutionForm";
import ViewUtils from '../../../ViewUtils';
import { ViewContainer } from "../../../View";
import FormDialog from "../../../etqa/FormDialog";
import Prompt from '../../../widgets/Prompt';
import SearchView from "../../../widgets/SearchView";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import withMenuPermission from "../../../widgets/withMenuPermission";

const headCells = [
    { id: 'InstitutionName', numeric: false, disablePadding: true, label: 'Institution Name' },
];

const utils = new ViewUtils();
const addFormRef = React.createRef();
const toolbarRef = React.createRef();

const useStyles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        color: 'white',
    },
    table: {
        minWidth: 700,
    },
});

class InstitutionSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: {
                InstitutionName: ""
            },
            keyUpSwitch: false,
            addDialogOpen: false,
            editDialogOpen: false,
            selectedInstitution: null,
            showTable: true,
            promptOpen: false,
            promptTitle: "Remove Institution",
            promptContent: "Are you sure you want to remove this Institution?",
            searchParameters: [{ Name: "InstitutionName", Value: "" }],
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'addInstitution', label: '', tooltip: 'Capture New Institution', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Update Current Institution', disabled: utils.isNull(this.state.selectedInstitution), icon: 'EditIcon', visible: this.props.menuPermissionItem.Edit },
                        { id: 'remove', label: '', tooltip: 'Remove Institution', disabled: utils.isNull(this.state.selectedInstitution), icon: "Delete", visible: this.props.menuPermissionItem.Delete },
                    ]
                }
            ]
        }
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("addInstitution" === buttonId) {
            this.setState({ addDialogOpen: true, Message: "" })
        }
        else if ("edit" === buttonId) {
            this.setState({ editDialogOpen: true, Message: "" })
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    postAddHandler(cancel, save) {
        if (save === true) {
            this.setState({
                selectedInstitution: null,
                Message: "Institution successfully saved",
                MessageStyle: "success",
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                showTable: false
            }, () => {
                this.setState({
                    showTable: true,
                    addDialogOpen: false,
                    editDialogOpen: false
                })
            });

            toolbarRef.current.setButtonDisabled("edit", true);
            toolbarRef.current.setButtonDisabled("remove", true);
        }
        else if (cancel === true) {
            this.setState({
                addDialogOpen: false,
                editDialogOpen: false,
                selectedInstitution: null,
                Message: ""
            })
            toolbarRef.current.setButtonDisabled("edit", true);
            toolbarRef.current.setButtonDisabled("remove", true);
        }
        else {
            this.setState({
                addDialogOpen: false,
                editDialogOpen: false,
                selectedInstitution: null,
                Message: "A system error has occured, please try again later or contact your administrator",
                MessageStyle: "warning"
            })
            toolbarRef.current.setButtonDisabled("edit", true);
            toolbarRef.current.setButtonDisabled("remove", true);
        }
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }


    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedInstitution: selection[0].Id }, () => {
            });
        }
        else {
            this.setState({ selectedInstitution: null }, () => {
            });
        }
        toolbarRef.current.setButtonDisabled("edit", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
    }

    successfulApplicationRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedInstitution: null, Message: response.Message, MessageStyle: response.MessageType.toLowerCase() });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulApplicationRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ Message: response.Message, MessageStyle: response.MessageType.toLowerCase() })
        }
    }

    removeApplication(url) {
        this.setState({ showTable: false }, () => {
            this.setState({ promptOpen: false, showTable: true }, () => {
                toolbarRef.current.setButtonDisabled("remove", true);
                toolbarRef.current.setButtonDisabled("edit", true);
            });
        })

        let fullUrl = `${url}?id=${this.state.selectedInstitution}`;
        utils.invokeUrlDelete(fullUrl, this.successfulApplicationRemoveCallback, this.unsuccessfulApplicationRemoveCallback);
    }
    closePrompt = () => {
        this.setState({ promptOpen: false })
    }

    render() {
        const { classes } = this.props;
        return (
            <ViewContainer title="">
                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />
                {this.state.showTable === true && (
                    <SearchView
                        headCells={headCells}
                        dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/institution/GetSearch"}
                        model={this.state.model}
                        toolbarConfig={this.toolbarConfig}
                        searchParameters={this.state.searchParameters}
                        mode={this.props.mode}
                        title='Institution'
                        multiSelect={this.props.multiSelect}
                        onLookupValueChange={this.props.onLookupValueChange}
                        descriptionProp="InstitutionName"
                        history={this.props.history}
                        buttonClickHandler={this.handleToolbarButtonClick}
                        keyUpSwitch={this.state.keyUpSwitch}
                        deleteUrl={"api/sims/institution/RemoveInstitution"} useDeletePost={true}
                        promptTitle={"Remove Institution"} promptContent={"Are you sure you want to remove this Institution?"}
                        setSelection={(selection) => { this.handleSelection(selection) }}
                        toolbarRef={toolbarRef}
                        autoFetchData={true}
                    >
                        <InstitutionSearchForm data={this.state.model} onKeyUp={(e) => { this.onkeyup(e) }}
                            valueChangeHandler={(values) => {
                                if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                    this.setState({ model: values });
                                }
                            }} />
                    </SearchView>
                )}
                <FormDialog
                    open={this.state.addDialogOpen}
                    dialogTitle={"Create Institution"}
                    viewRef={this}
                    formRef={addFormRef}
                    saveUrl={"api/sims/institution/InsertInstitution"}
                    usePost={true}
                    closeHandler={(cancel, save) => this.postAddHandler(cancel, save)}
                >
                    <InstitutionForm ref={addFormRef} />
                </FormDialog>

                <FormDialog
                    open={this.state.editDialogOpen}
                    dialogTitle={"Edit Institution"}
                    viewRef={this}
                    formRef={addFormRef}
                    entityId={this.state.selectedInstitution}
                    saveUrl={"api/sims/institution/UpdateInstitution"}
                    usePut={true}
                    closeHandler={(cancel, save) => this.postAddHandler(cancel, save)}
                >
                    <InstitutionForm
                        ref={addFormRef}
                        id={this.state.selectedInstitution}
                        dataURL='api/sims/institution/GetFind'
                    />
                </FormDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeApplication("api/sims/institution/RemoveInstitution") }}
                    closePrompt={this.closePrompt}
                />

            </ViewContainer>
        );
    }
}

export default withRouter(withStyles(useStyles())(withMenuPermission(InstitutionSearch)));

import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { AddressCodeItem } from '../../widgets/AddressCodeItem';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { makeStyles } from '@material-ui/core/styles';
import { LookupField } from '../../widgets/LookupField';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    MuiSelectSelectMenu: {
        '& .MuiSelect-selectMenu': { paddingRight: "150px !important" }
    }
}));

const utils = new ViewUtils();
export const BursaryApplicationTypeEvaluationForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [postalCodeValue, setPostalCodeValue] = React.useState(null);
    const [physicalCodeValue, setPhysicalCodeValue] = React.useState(null);
    const [nonIDFlag, setNonIDFlag] = React.useState(null);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };


    const classes = useStyles();

    return (
        <EntityForm ref={ref} viewId="bursaryapplicationtype" values={props.data} dataURL='api/sims/BursaryApplicationEvaluation/GetBursaryApplicationTypeFind'
            entityLookupController={props.entityLookupController} org="saseta" IsExternal={true}
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="BursaryApplicationTypeEvaluationForm">
                    <div className="row" style={{ width: "90%" }}>
                        <div className="col w-100">
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/BursaryApplication/GetLookupList?listId=BursaryApplicationType"
                                                id="BursaryApplicationTypeID"
                                                key={tabValue}
                                                label="Application Type"
                                                className="w-100"
                                                disabled={true}
                                                allowEmptyValue={true}
                                                form={form}
                                                checkRefreshToken={!props.IsExternal}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/BursaryApplication/GetLookupList?listId=AcademicYearOfApplication"
                                                id="AcademicApplicationYearID"
                                                key={0}
                                                label="Academic Year Application"
                                                parentId={0}
                                                disabled={true}
                                                className="w-100"
                                                form={form}
                                                checkRefreshToken={!props.IsExternal} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
            }
        </EntityForm>
    );
})
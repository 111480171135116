import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import SearchView from "../../widgets/SearchView";
import BursaryInstitutionSearchForm from './BursaryInstitutionSearchForm';
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from '../../ViewUtils';
import withMenuPermission from "../../widgets/withMenuPermission";

const utils = new ViewUtils();

const headCells = [
    { id: "Description", numeric: false, disablePadding: true, label: "Institution Name" }]

class BursaryInstitutionSearch extends Component {

    constructor(props) {
        super(props)

        this.searchParameters = !utils.isNull(this.props.programmeId) ?
            [
                { Name: "id", Value: this.props.programmeId },
                { Name: "InstitutionName", Value: "" }
            ]
            :
            [
                { Name: "InstitutionName", Value: "" }
            ]

        let model = !utils.isNull(this.props.programmeId) ?
            {
                InstitutionName: "",
                id: this.props.programmeId
            }
            :
            {
                InstitutionName: ""
            }

        this.state = {
            model: model,
            keyUpSwitch: false,
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'info', label: '', tooltip: 'Select Institution from the table and click save to add', icon: 'InfoIcon' }
                    ]
                }
            ]
        }
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            e.preventDefault();
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title={this.props.showTitle === true || utils.isNull(this.props.showTitle) ? 'Bursary Institution' : null}>
                <SearchView
                    headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/indicium/BursaryApplication/GetBursaryInstitution"}
                    model={this.state.model}
                    toolbarConfig={this.toolbarConfig}
                    searchParameters={this.searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp={"Description"}
                    mode={this.props.mode}
                    history={this.props.history}
                    checkRefreshToken={!this.props.IsExternal}
                    keyUpSwitch={this.state.keyUpSwitch}
                    selectionCallbackMode={this.props.selectionCallbackMode}
                >
                    <BursaryInstitutionSearchForm
                    data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }} 
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                     />
                </SearchView>
            </ViewContainer>
            )
    }
}

export default withRouter(withMenuPermission(BursaryInstitutionSearch));
import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable.jsx';
import EnhancedToolbar from '../../widgets/Toolbar.jsx';
import AlertItem from "../../widgets/AlertItem.jsx";
import Prompt from '../../widgets/Prompt.jsx';
import ViewUtils from '../../ViewUtils.js';
import { ViewContainer } from "../../View.jsx";
import ProviderIIBTCPSearchModule from "../program/ProviderIIBTCPSearchModule.js";
import LinkDialog from "../LinkDialog.jsx";
import Dialog from '@material-ui/core/Dialog';
import { Typography } from "antd";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const headCells = [
    { id: 'Code', numeric: false, disablePadding: false, label: 'Code' },
    { id: 'Title', numeric: false, disablePadding: false, label: 'Title' },
    { id: 'RegistrationStartDate', numeric: false, disablePadding: false, label: 'Registration Start Date' },
    { id: 'RegistrationEndDate', numeric: false, disablePadding: false, label: 'Registration End Date' }
];
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        color: 'white',
    },
    table: {
        minWidth: 700,
    },
});

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class ProviderIIBTCPSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedQualification: null,
            linkDialogOpen: false,
            formDialogOpen: false,
            searchParameters: [{ Name: "ProviderID", Value: this.props.location.state.selection }],
            propmtOpen: false,
            promptTitle: "Remove Inhouse/Industry/Based Training Course from Provider",
            promptContent: "Are you sure you want to remove this trade qualification from the provider?",
            removeMessage: "",
            removeMessageStyle: "",
            selectedQualificationTitle: null,
            showTable: true,
            searchParameters2: [{ Name: "Id", Value: 0 }],
            OpenDialog: false,

        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: "link", label: '', icon: 'LinkIcon', tooltip: "Link existing Inhouse/Industry/Based Training Course to Provider" },
                        {
                            id: 'remove', label: '', icon: 'DeleteIcon', tooltip: 'Remove Inhouse/Industry/Based Training Course from Provider',
                            disabled: utils.isNull(this.state.selectedQualification)
                        }]
                }
            ]
        };

    }
    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/provider',
                state: { selection: this.props.location.state.selection }
            });
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
        else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, removeMessage: "" })
        }
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {

            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                formDialogOpen: false, selectedSiteVisit: null, linkDialogOpen: false
            }, () => {
                this.setState({ showTable: true })
            });
        }
        else if (isCancel === true) {
            this.setState({
                formDialogOpen: false,
                linkDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            let newsearchParameters2 = [...this.state.searchParameters2];
            let temp_element = { ...newsearchParameters2[0] };
            temp_element.Value = selection[0].Id
            newsearchParameters2[0] = temp_element;

            this.setState({ searchParameters2: JSON.parse(JSON.stringify(newsearchParameters2)), selectedQualification: selection[0].Id, removeMessage: "", selectedQualificationTitle: selection[0].SAQAQualificationTitle });
        }
        else {
            this.setState({ searchParameters2: [{ Name: "Id", Value: 0 }], selectedQualification: null, removeMessage: "", selectedQualificationTitle: null });
        }
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
    }

    successfulRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedQualification: null,
                removeMessage: response.Message,
                removeMessageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                removeMessage: response.Message,
                removeMessageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    removeQualifications(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedQualification}&providerId=${this.props.location.state.selection}`;
        utils.invokeUrlDelete(fullUrl, this.successfulRemoveCallback, this.unsuccessfulRemoveCallback);
    }

    viewRecords = () => {
        this.setState({ formDialogOpen: true })
    }


    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    CloseForm = () => {
        this.setState({
            OpenDialog: false,
            selectedId: null,
            clearSelectedRow: true,
            selectedQualification: null
        });
        toolbarRef.current.setButtonDisabled("unlink", true);
    };

    render() {
        const { classes } = this.props;
        return (
            <ViewContainer mode={this.props.mode} title="">

                <p className="breadcrumbs">
                    {"Provider > Inhouse/Industry/Based Training Courses " + (this.props.location.state.providerName !== null ? "> " + this.props.location.state.providerName + (this.props.location.state.providerSDLNumber !== null ? " - " + this.props.location.state.providerSDLNumber : "") : "> Add")}
                </p>

                <AlertItem message={this.state.removeMessage} alertStyle={this.state.removeMessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    {this.state.showTable === true && (
                        <EnhancedTable
                            headCells={headCells}
                            dataUrl={`api/sims/provideriibtcp/GetProviderIIBTCP`}
                            height="60vh"
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />

                    )}
                </div>

                <LinkDialog
                    open={this.state.linkDialogOpen}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    dialogTitle={`Link Inhouse/Industry/Based Training Courses to Provider > ${this.props.location.state.providerName} - ${this.props.location.state.providerSDLNumber}`}
                    linkName={"iibtcp"} postUrl={"api/sims/provideriibtcp/LinkProviderIIBTCP"} usePost={true}
                    selectionId={this.props.location.state.selection}
                >
                    <ProviderIIBTCPSearchModule
                        dataUrl="api/sims/provideriibtcp/GetSearchLinkableIIBTCP"
                        mode="lookup"
                        multiSelect={true} showTitle={false}
                        programmeId={this.props.location.state.selection} programmeType="Q"
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeQualifications("api/sims/provideriibtcp/RemoveProviderIIBTCP") }}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(withStyles(useStyles())(connect()(ProviderIIBTCPSearch)));

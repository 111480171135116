import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import { ViewContainer } from "../../View";
import ViewUtils from "./../../ViewUtils";


const utils = new ViewUtils();

const headCells = [
    { id: 'ScopeType', numeric: false, disablePadding: true, label: 'Scope Type' },
    { id: 'ScopeCode', numeric: false, disablePadding: true, label: 'Scope Code' },
    { id: 'ScopeTitle', numeric: false, disablePadding: true, label: 'Scope Title' }
];

const toolbarConfig = {
    items: [
        {

        }
        

    ]
};

class AuditTrialSearch  extends Component {

    state = {
        linkDialogOpen: false,
        selectedLinkType: null,
        selectedLinkStandards: null
    }


    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/coachmentor',
                state: { selection: this.props.location.state.CoachMentorID }
            });
        }
    }

    handleToolbarButtonMenuClick(menu) {
        this.setState({ linkDialogOpen: true, selectedLinkType: menu });
    }

    postLinkHandler(linked) {
        //alert(linked + " " + JSON.stringify(this.state.selectedLinkType) + " " + JSON.stringify(this.state.selectedLinkStandards));
        this.setState({ linkDialogOpen: false, selectedLinkType: null, selectedLinkStandards: null });
    }

    render() {
        return (
            <ViewContainer title={""}>
                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)}
                        config={toolbarConfig} />
                    <EnhancedTable
                        headCells={headCells}
                        height="50vh"
                        dataUrl={utils.isNull(this.props.dataUrl) ? "" : this.props.dataUrl}
                        searchParameters={[{ Name: "ID", Value: this.props.Id }]} paged={true} />
                    
                </div>

            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(AuditTrialSearch)
);

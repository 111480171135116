import React, { useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import { CustomTextField } from '../../widgets/CustomTextField';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { GPSCoordinatesAPI } from '../../widgets/GPSCoordinatesAPI';
import './SDF.css'
import { AddressCodeItem } from '../../widgets/AddressCodeItem';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import DocumentUpload from '../../widgets/DocumentUpload';

import clsx from 'clsx';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
const useStyles = makeStyles({
    root: {
        //   width: '90%',
        margin: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',

        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#01579B',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#01579B',
        },
    },
});

function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

export const SDFForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [scopeSelected, setScopeSelected] = React.useState(1)
    const [usePostalAddress, setUsePostalAddress] = React.useState(false)
    const [postalCodeValue, setPostalCodeValue] = React.useState(null);
    const [physicalCodeValue, setPhysicalCodeValue] = React.useState(null);
    const [form, setForm] = React.useState(null);
    const [file, setFile] = React.useState(null);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const handleScopeSelected = (event) => {
        if (event.target.value != scopeSelected) {
            setScopeSelected(event.target.value)

            // this.state.model.scopeSelected = event.target.value;

        }
    }
    const [filename, setFilename] = React.useState("");
    const [size, setSize] = React.useState(null);
    const [base64, setBase64] = React.useState(null);
    const [type, setType] = React.useState(null);


    const fileUploadHandler = (data) => {
        setForm({});
        setFile(data);
    }

    const onUploadHandler = (data) => {
        if (data) {
            setFilename(data.name);
            setSize(data.size);
            setBase64(data.base64);
            setType(data.type);
        }
    }

    const processFileHandler = () => {
        //this.setState({ message: "", hasError: false });
        if (props.IsExternal) {
            utils.invokeUrlExternal('dms/ocr/id/card', (data) => {
                //console.log(data);
                if (data) {
                    data.SDFIDCopy = { ...form.SDFIDCopy }
                    setForm(data);
                    //form.values['IDNo'] = data.IDNo;
                } else {
                    //this.setState({ message: "The file cannot be extracted", hasError: true });
                }
            }, () => {
                //this.setState({ message: "The file cannot be extracted", hasError: true });
            }, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(file)
            });
        } else {
            utils.invokeUrl('dms/ocr/id/card', (data) => {
                if (data) {
                    data.SDFIDCopy = { ...form.SDFIDCopy }
                    setForm(data);
                    //form.values['IDNo'] = data.IDNo;
                } else {
                    //this.setState({ message: "The file cannot be extracted", hasError: true });
                }
            }, () => {
                //this.setState({ message: "The file cannot be extracted", hasError: true });
            }, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(file)
            });
        }
    }

    function FileDownload(id,) {
        utils.invokeUrl("api/sims/sdfdetails/DownloadSDFCertificate?id=" + id, (response) => {

            var blob = new Blob([base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );
    };

    function base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };


    function DocRequiredCheck(value) {
        if (value !== undefined && value !== null && value !== "") {
            return false
        }
        else if (value === undefined || value === null || value === "") {
            return true
        }
    };


    const handleFormValueChange = (values) => {

        if (JSON.stringify(form) !== JSON.stringify(values)) {
            if (!utils.isNull(values)) {
                let idnumberDetails = { ...values };
                if (!utils.isNull(values.IDNo) && values.IDNo.length == 13) {
                    if (utils.isNull(values.CitizenResidentialStatusID) && utils.isNull(values.DateofBirth) && utils.isNull(values.GenderID) && utils.isNull(values.NationalityID)) {
                        try {
                            const residentailStatus = idnumberDetails.IDNo.charAt(10);
                            if (residentailStatus == "0") {
                                idnumberDetails.CitizenResidentialStatusID = { Id: "1", Description: "South Africa" };
                                idnumberDetails.NationalityID = { Id: "1", Description: "South Africa" };
                            }
                            else if (residentailStatus == "1") {
                                idnumberDetails.CitizenResidentialStatusID = { Id: "4", Description: "Permanent Resident" }
                            }
                            var date = new Date('19' + idnumberDetails.IDNo.slice(0, 2), idnumberDetails.IDNo.slice(2, 4) - 1, idnumberDetails.IDNo.slice(4, 6));
                            idnumberDetails.DateofBirth = date;
                            const gender = parseInt(idnumberDetails.IDNo.slice(6, 10));
                            if (gender >= 0 && gender <= 4999) {
                                idnumberDetails.GenderID = { Id: "2", Description: "Female" }
                            } else if (gender >= 5000 && gender <= 9999) {
                                idnumberDetails.GenderID = { Id: "1", Description: "Male" }
                            }
                            idnumberDetails.AlternateIDTypeID = { Id: "11", Description: "RSA ID Number" }

                            setForm({ ...form, ...idnumberDetails });
                            //props.valueChangeHandler(values);
                            //this.setState((prevState, props) => {
                            //    return {
                            //        form: { ...prevState.formData, ...idnumberDetails }
                            //    }
                            //});
                        } catch (err) {

                        }
                    }
                }
                //if (!utils.isNull(values.FirstName)) {
                //    idnumberDetails.Initials = values.FirstName.charAt(0).toUpperCase();
                //}
                setForm({ ...form, ...idnumberDetails });
            }
            props.valueChangeHandler(values);
        }
    }

    const onTextChange = (form, id, value) => {
        let Initials = '';
        if (!utils.isNull(form.values.FirstName)) {
            Initials += form.values.FirstName.charAt(0).toUpperCase();
        }
        if (!utils.isNull(form.values.MiddleName)) {
            Initials += form.values.MiddleName.charAt(0).toUpperCase();
        }
        if (Initials !== '') {
            form.updateValue('Initials', Initials);
        }
    }

    const handleUsePhysicalAddressForPostal = form => event => {


        if (event.target.checked === true) {
            form.values['PostalAddressLine1'] = form.values["PhysicalAddress1"];
            form.values['PostalAddressLine2'] = form.values["PhysicalAddress2"];
            form.values['PostalAddressLine3'] = form.values["PhysicalAddress3"];
            form.values['PostalCode'] = form.values["PhysicalCode"];
            setPostalCodeValue(form.values["PhysicalCode"]);
        }
        form.updateValue('UsePhysicalAsPostal', event.target.checked);


    }
    const updateGPSAddress = (address, form) => {
        form.values["GPSCoordinates"] = address.gpscoordinates.coordinate;
        form.values["PhysicalAddress1"] = address.streetnumberpar + ' ' + address.routepar;
        form.values["PhysicalAddress2"] = address.sublocalitylevel1par;
        form.values["PhysicalAddress3"] = address.localitypar;
        form.values["PhysicalCode"] = address.postalcodepar;
        setPhysicalCodeValue(form.values["PhysicalCode"]);
    }
    const classes = useStyles();
    const handleCheckboxChange = (e) => {

        if (e !== true) {
            setUsePostalAddress(true)
        } else {
            setUsePostalAddress(false)
        }
    }

    const dataArrivedHandler = (values) => {

        if (!utils.isNull(props.dataArrivedHandler)) {
            props.dataArrivedHandler(values);
        }

        if (!DocRequiredCheck(values.SDFCertificateDocumentName)) {
            ref.current.setValue("SDFCertificate", { Base64: "" });
        }
    }
    return (

        <Card className={classes.root} variant="outlined">

            <CardContent>
                <EntityForm ref={ref} viewId="provider" values={form}
                    dataURL={props.dataUrl}
                    entityLookupController={props.entityLookupController} org="saseta"
                    entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={handleFormValueChange}
                    entityLookupController={props.entityLookupController} dataArrivedHandler={dataArrivedHandler}
                    IsExternal={props.IsExternal}
                >
                    {form =>
                        <form className={form.classes.container} id="providerForm">
                            <div className="row" style={{ width: "90%" }}>
                                <div className="col w-100">
                                    <table cellPadding="2" className="w-100">
                                        <tbody>
                                            <tr className="w-100">

                                                {/*!props.id ?*/
                                                    //<td className="w-25">
                                                    //<FormControl style={{ paddingLeft: 5 }}> 

                                                    //    <RadioGroup onChange={(event)=>{handleScopeSelected(event)}} defaultValue="1" aria-label="linkscope" name="scope module"  row>  

                                                    //            <FormControlLabel value="1" control={<StyledRadio />} label="Primary SDF" /><br />
                                                    //            <FormControlLabel value="2" control={<StyledRadio />} label="Secondary SDF" />
                                                    //    </RadioGroup>
                                                    //        </FormControl>
                                                    //    </td> : null
                                                }

                                                <td className="w-25">
                                                    <CustomTextField
                                                        id="FirstName"
                                                        key={tabValue}
                                                        label="First Name"
                                                        className="w-100"
                                                        required
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                        onChange={onTextChange}
                                                    />
                                                </td>
                                                <td className="w-25">
                                                    <CustomTextField
                                                        id="MiddleName"
                                                        key={tabValue}
                                                        label="Middle Name"
                                                        className="w-100"

                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                        onChange={onTextChange}
                                                    />
                                                </td>
                                                <td className="w-25">
                                                    <CustomTextField
                                                        id="Surname"
                                                        key={tabValue}
                                                        label="Surname"
                                                        className="w-100"
                                                        required
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row w-100" style={{ overflowY: "auto", maxHeight: "65vh" }}>
                                <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                                    <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                        <Tab label="Person Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null, fontSize: 12, fontWeight: 500, }} />
                                        <Tab label="Contact Details" {...a11yProps(1)} style={{ color: form.fieldParentErrors[1] === true ? "red" : null, fontSize: 12, fontWeight: 500, }} />
                                        <Tab label="Address Details" {...a11yProps(2)} style={{ color: form.fieldParentErrors[2] === true ? "red" : null, fontSize: 12, fontWeight: 500, }} />
                                        <Tab label="Education & Experience" {...a11yProps(3)} style={{ color: form.fieldParentErrors[3] === true ? "red" : null, fontSize: 12, fontWeight: 500, }} />
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={tabValue} index={0} className='col w-100'>
                                    <div className="col w-100">
                                        <table cellPadding="2" className="w-100">
                                            <tbody>
                                                {!props.id ?
                                                    <tr>
                                                        <td valign="top" height="20%" colSpan="3">
                                                            ID Document Upload<br />
                                                            <DocumentUpload showViewer={true}
                                                                onUploadHandler={(data) => fileUploadHandler(data)}
                                                                onProcessHandler={() => processFileHandler()}
                                                                imageStyle={true}
                                                                parentId={0}
                                                                form={form}
                                                                id="SDFIDCopy"
                                                            />
                                                        </td>
                                                    </tr> : null}
                                                <tr>
                                                    <td>
                                                        <SelectItem
                                                            dataUrl={props.LookupUrl + "Title"}
                                                            id="TitleID"
                                                            parentId={0}
                                                            name="TitleID"
                                                            label="Title"
                                                            className="w-100"
                                                            allowEmptyValue={true}
                                                            required
                                                            form={form}
                                                            checkRefreshToken={!props.IsExternal}
                                                        />
                                                    </td>
                                                    <td>
                                                        <CustomTextField
                                                            id="IDNo"
                                                            parentId={0}
                                                            key={tabValue}
                                                            parentId={0}
                                                            label="ID No"
                                                            className="w-100"
                                                            required
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            form={form}
                                                            disabled={props.id}
                                                        />
                                                    </td>

                                                    <td>
                                                        <CustomTextField
                                                            id="Initials"
                                                            key={tabValue}
                                                            parentId={0}
                                                            label="Initials"
                                                            className="w-100"
                                                            required
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            form={form}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <CustomDatePicker
                                                            id="DateofBirth"
                                                            parentId={0}
                                                            label="Date of Birth"
                                                            className='w-100'
                                                            required
                                                            form={form}
                                                        />
                                                    </td>

                                                    <td>
                                                        <SelectItem
                                                            dataUrl={props.LookupUrl + "Gender"}
                                                            id="GenderID"
                                                            parentId={0}
                                                            name="GenderID"
                                                            label="Gender"
                                                            className="w-100"
                                                            allowEmptyValue={true}
                                                            required
                                                            form={form}
                                                            checkRefreshToken={!props.IsExternal}
                                                        />
                                                    </td>
                                                    <td>
                                                        <SelectItem
                                                            dataUrl={props.LookupUrl + "Equity"}
                                                            id="EquityID"
                                                            required
                                                            parentId={0}
                                                            key={tabValue}
                                                            label="Equity"
                                                            className="{classes.select} w-100"
                                                            allowEmptyValue={true}
                                                            form={form}
                                                            checkRefreshToken={!props.IsExternal}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <SelectItem
                                                            dataUrl={props.LookupUrl + "Disability"}
                                                            id="DisabilityID"
                                                            required
                                                            parentId={0}
                                                            key={tabValue}
                                                            label="Disability"
                                                            className="{classes.select} w-100"
                                                            allowEmptyValue={true}
                                                            form={form}
                                                            checkRefreshToken={!props.IsExternal}
                                                        />
                                                    </td>
                                                    <td>
                                                        <SelectItem
                                                            dataUrl={props.LookupUrl + "HomeLanguage"}
                                                            id="HomeLanguageID"
                                                            required
                                                            parentId={0}
                                                            key={tabValue}
                                                            label="Home Language"
                                                            className="{classes.select} w-100"
                                                            allowEmptyValue={true}
                                                            form={form}
                                                            checkRefreshToken={!props.IsExternal}
                                                        />
                                                    </td>

                                                    <td>
                                                        <SelectItem
                                                            dataUrl={props.LookupUrl + "CitizenResidentialStatus"}
                                                            id="CitizenResidentialStatusID"
                                                            required
                                                            parentId={0}
                                                            key={tabValue}
                                                            label="Citizen Residential Status"
                                                            className="{classes.select} w-100"
                                                            allowEmptyValue={true}
                                                            form={form}
                                                            checkRefreshToken={!props.IsExternal}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <SelectItem
                                                            dataUrl={props.LookupUrl + "AlternateIDType"}
                                                            id="AlternateIDTypeID"
                                                            required
                                                            parentId={0}
                                                            key={tabValue}
                                                            label="Alternate ID Type"
                                                            className="{classes.select} w-100"
                                                            allowEmptyValue={true}
                                                            form={form}
                                                            checkRefreshToken={!props.IsExternal}
                                                        />
                                                    </td>


                                                    <td>
                                                        <SelectItem
                                                            dataUrl={props.LookupUrl + "Nationality"}
                                                            id="NationalityID"
                                                            required
                                                            parentId={0}
                                                            key={tabValue}
                                                            label="Nationality"
                                                            className="{classes.select} w-100"
                                                            allowEmptyValue={true}
                                                            form={form}
                                                            checkRefreshToken={!props.IsExternal}
                                                        />
                                                    </td>


                                                    <td colSpan={2}>
                                                        <SelectItem
                                                            dataUrl={props.LookupUrl + "SocioEconomicStatus"}
                                                            id="SocioEconomicStatusID"
                                                            parentId={0}
                                                            required
                                                            key={tabValue}
                                                            label="Socio Economic Status"
                                                            className="{classes.select} w-100"
                                                            allowEmptyValue={true}
                                                            form={form}
                                                            checkRefreshToken={!props.IsExternal}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </TabPanel>
                                <TabPanel className="providerFormTabPanel" value={tabValue} index={1}>
                                    <div className="col w-100">
                                        <table cellPadding="2" className="w-100">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <CustomTextField
                                                            id="TelephoneNumber"
                                                            parentId={1}
                                                            key={tabValue}
                                                            label="Telephone Number"
                                                            className="w-100"
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            form={form}
                                                            validationRegex="^$|\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                        />
                                                    </td>
                                                    <td>
                                                        <CustomTextField
                                                            id="CellPhoneNumber"
                                                            parentId={1}
                                                            key={tabValue}
                                                            label="Cell Phone Number"
                                                            className="w-100"
                                                            required
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            form={form}
                                                            validationRegex="^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <CustomTextField
                                                            id="FaxNumber"
                                                            parentId={1}
                                                            key={tabValue}

                                                            label="Fax Number"
                                                            className="w-100"
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            form={form}
                                                            validationRegex="^$|\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                        />
                                                    </td>
                                                    <td>
                                                        <CustomTextField
                                                            id="EMail"
                                                            parentId={1}
                                                            key={tabValue}
                                                            required
                                                            label="E Mail"
                                                            className="w-100"
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            form={form}
                                                            validationRegex="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </TabPanel>
                                <TabPanel value={tabValue} index={2} className='col w-100'>
                                    <div className="col w-100">
                                        <table cellPadding="2" className="w-100">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <CustomCheckbox
                                                            id="UsePhysicalAsPostal"
                                                            parentId={2}
                                                            key={tabValue}
                                                            form={form}
                                                            // disabled={!props.editPermission}
                                                            onChange={handleUsePhysicalAddressForPostal}
                                                            inputProps={{
                                                                'aria-label': 'primary checkbox',
                                                            }}
                                                            label="Use Physical Address for Postal Address?"
                                                        />
                                                    </td>
                                                </tr>


                                                <tr>
                                                    <td>
                                                        <GPSCoordinatesAPI
                                                            id="Address"
                                                            parentId={2}
                                                            key={tabValue}
                                                            className="w-100"
                                                            label="Address"
                                                            form={form}
                                                            // disabled={!props.editPermission}
                                                            updateGPSAddress={updateGPSAddress}
                                                        />
                                                    </td>
                                                    <td>
                                                        <CustomTextField
                                                            id="GPSCoordinates"
                                                            parentId={2}
                                                            key={tabValue}
                                                            label="GPS Coordinates"
                                                            className="w-100"
                                                            form={form}
                                                        // disabled={!props.editPermission}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <CustomTextField
                                                            parentId={2}
                                                            id="PhysicalAddress1"
                                                            required
                                                            key={tabValue}
                                                            label="Physical Address 1"
                                                            className="w-100"
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            form={form}
                                                        // disabled={!props.editPermission}
                                                        />
                                                    </td>
                                                    <td>
                                                        <CustomTextField
                                                            id="PostalAddressLine1"
                                                            parentId={2}
                                                            key={tabValue}
                                                            required
                                                            label="Postal Address Line 1"
                                                            className="w-100"
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            form={form}
                                                        // disabled={!props.editPermission}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <CustomTextField
                                                            parentId={2}
                                                            id="PhysicalAddress2"
                                                            key={tabValue}
                                                            required
                                                            label="Physical Address 2"
                                                            className="w-100"
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            form={form}
                                                        // disabled={!props.editPermission}
                                                        />
                                                    </td>
                                                    <td>
                                                        <CustomTextField
                                                            id="PostalAddressLine2"
                                                            parentId={2}
                                                            required
                                                            key={tabValue}
                                                            label="Postal Address Line 2"
                                                            className="w-100"
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            form={form}
                                                        // disabled={!props.editPermission}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <CustomTextField
                                                            parentId={2}
                                                            id="PhysicalAddress3"
                                                            key={tabValue}
                                                            label="Physical Address 3"
                                                            className="w-100"
                                                            required
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            form={form}
                                                        // disabled={!props.editPermission}
                                                        />
                                                    </td>
                                                    <td>
                                                        <CustomTextField
                                                            id="PostalAddressLine3"
                                                            parentId={2}
                                                            key={tabValue}
                                                            label="Postal Address Line 3"
                                                            className="w-100"
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            form={form}
                                                        // disabled={!props.editPermission}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <AddressCodeItem
                                                            id="PhysicalCode"
                                                            parentId={2}
                                                            required={true}
                                                            addressId="Physical"
                                                            value={physicalCodeValue}
                                                            key={tabValue}
                                                            validationRegex={/^[0-9]{4,5}$/g}
                                                            validationMessage="The code must be 4 or 5 digits long"
                                                            provinceId={!utils.isNull(form.values) ? form.values.PhysicalProvinceID : null}
                                                            municipalityId={!utils.isNull(form.values) ? form.values.PhysicalMunicipalityID : null}
                                                            districtId={!utils.isNull(form.values) ? form.values.PhysicalDistrictID : null}
                                                            urbanRuralId={!utils.isNull(form.values) ? form.values.PhysicalUrbanRuralID : null}
                                                            suburbId={!utils.isNull(form.values) ? form.values.PhysicalSuburbID : null}
                                                            cityId={!utils.isNull(form.values) ? form.values.PhysicalCityID : null}
                                                            className="w-100"
                                                            label="Physical Code"
                                                            hasMunicipalityID={true}
                                                            hasProvinceID={true}
                                                            hasCityID={false}
                                                            hasDistrictID={false}
                                                            hasUrbanRuralID={true}
                                                            hasSuburbID={false}
                                                            form={form}
                                                            checkRefreshToken={!props.IsExternal}
                                                        // disabled={!props.editPermission}
                                                        />
                                                    </td>

                                                    <td>
                                                        <AddressCodeItem
                                                            id="PostalCode"
                                                            parentId={2}
                                                            required={true}
                                                            addressId="Postal"
                                                            value={postalCodeValue}
                                                            key={tabValue}
                                                            validationRegex={/^[0-9]{4,5}$/g}
                                                            validationMessage="The code must be 4 or 5 digits long"
                                                            provinceId={!utils.isNull(form.values) ? form.values.PostalProvinceID : null}
                                                            municipalityId={!utils.isNull(form.values) ? form.values.PostalMunicipalityID : null}
                                                            districtId={!utils.isNull(form.values) ? form.values.PostalDistrictID : null}
                                                            urbanRuralId={!utils.isNull(form.values) ? form.values.PostalUrbanRuralID : null}
                                                            suburbId={!utils.isNull(form.values) ? form.values.PostalSuburbID : null}
                                                            cityId={!utils.isNull(form.values) ? form.values.PostalCityID : null}
                                                            className="w-100"
                                                            label="Postal Code"
                                                            hasMunicipalityID={true}
                                                            hasProvinceID={true}
                                                            hasCityID={false}
                                                            hasDistrictID={false}
                                                            hasUrbanRuralID={true}
                                                            hasSuburbID={false}
                                                            form={form}
                                                            checkRefreshToken={!props.IsExternal}
                                                        // disabled={!props.editPermission}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </TabPanel>
                                <TabPanel value={tabValue} index={3} className='col w-100'>
                                    <div className="col w-100">
                                        <table cellPadding="2" className="w-100">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <SelectItem
                                                            dataUrl={props.LookupUrl + "SDFHighestEducation"}
                                                            id="SDFHighestEducationID"
                                                            parentId={3}
                                                            required
                                                            key={tabValue}
                                                            label="Highest Education"
                                                            className="w-100"
                                                            form={form}
                                                            checkRefreshToken={!props.IsExternal}
                                                        />
                                                    </td>
                                                    <td>
                                                        <CustomTextField
                                                            id="HighestEducationDescription"
                                                            parentId={3}
                                                            key={tabValue}

                                                            label="Highest Education Description"
                                                            className="w-100"
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            form={form}
                                                        />
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        <CustomTextField
                                                            id="NameOfAccreditedTrainingProvider"
                                                            parentId={3}
                                                            key={tabValue}

                                                            label="Name Of Accredited Training Provider"
                                                            className="w-100"
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            form={form}
                                                        />
                                                    </td>
                                                    <td>
                                                        <CustomTextField
                                                            id="Experience"
                                                            parentId={3}
                                                            key={tabValue}

                                                            label="Experience"
                                                            className="w-100"
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            form={form}
                                                            required
                                                        />
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        <CustomTextField
                                                            id="CurrentOccupation"
                                                            parentId={3}
                                                            key={tabValue}

                                                            label="Current Occupation"
                                                            className="w-100"
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            form={form}
                                                            required
                                                        />
                                                    </td>
                                                    <td>
                                                        <CustomTextField
                                                            id="YearsInOccupation"
                                                            parentId={3}
                                                            key={tabValue}

                                                            label="Year In Occupation"
                                                            className="w-100"
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            form={form}
                                                            required
                                                            validationRegex={/^[0-9]+$/g}
                                                        />
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <CustomTextField
                                                            id="GeneralComments"
                                                            key={tabValue}
                                                            parentId={3}
                                                            label="General Comments"
                                                            className="w-100"
                                                            form={form}
                                                            required
                                                        />
                                                    </td>
                                                </tr>
                                                {props.id ?
                                                    <tr>
                                                        <td>
                                                            <CustomDatePicker
                                                                parentId={3}
                                                                id="RegistrationDate"
                                                                label="Registration Date"
                                                                className='w-100'
                                                                form={form}
                                                                disabled
                                                            />
                                                        </td>
                                                    </tr> : null}
                                            </tbody>
                                        </table>
                                    </div>
                                </TabPanel>

                            </div>
                        </form >
                    }
                </EntityForm >
            </CardContent>
        </Card>

    );
})
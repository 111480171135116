import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import EnhancedToolbar from '../../../widgets/Toolbar';
import ViewUtils from "./../../../ViewUtils";
import { ViewContainer } from "./../../../View.jsx";
import Prompt from '../../../widgets/Prompt';
import AlertItem from "../../../widgets/AlertItem";
import EnhancedTable from '../../../widgets/ResponsiveTable';

const utils = new ViewUtils();

const headCells = [
    { id: 'FirstName', numeric: false, disablePadding: true, label: 'FirstName' },
    { id: 'Surname', numeric: false, disablePadding: true, label: 'Surname' },
    { id: 'IDNo', numeric: false, disablePadding: true, label: 'IDNo' },
    { id: 'AgreementReferenceNumber', numeric: false, disablePadding: true, label: 'Agreement Reference Number' },
    { id: 'ProgrammeStatus', numeric: false, disablePadding: true, label: 'Programme Status' },
    { id: 'CommencementDate', numeric: false, disablePadding: true, label: 'Commencement Date' },
    { id: 'CompletionDate', numeric: false, disablePadding: true, label: 'Completion Date' }
];

const toolbarRef = React.createRef();
class QCTOLearnershipAgreementSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            addDialogOpen: false,
            selectedDocument: null,
            searchParameters: [{ Name: "LearnershipID", Value: props.location.state.LearnershipID }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
            ]
        };
    }


    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/qctoLearnershipSetup',
                state: { selection: this.props.location.state.LearnershipID, id: this.props.location.state.LearnershipID }
            });
        }
    }

    handleToolbarButtonMenuClick(menu) {
        this.setState({ linkDialogOpen: true, selectedLinkType: menu });
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({
                selectedDocument: selection[0].Id,
                message: "",
                messageStyle: ""
            },
                () => {
                    toolbarRef.current.setButtonDisabled("remove", selection[0].Id === null || selection[0].Id.length === 0)
                }
            )
        }
        else {
            this.setState({
                selectedDocument: null,
                message: "",
                messageStyle: ""
            },
                () => {
                    toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0)
                }
            )
        }
    }

    render() {
        return (
            <ViewContainer>
                <p className="breadcrumbs">
                    {"QCTO Learnership > Agreements"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <div className="searchResults">
                    <EnhancedToolbar highlight={false} ref={toolbarRef}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)}
                        config={this.toolbarConfig} />
                    <EnhancedTable headCells={headCells} onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        dataUrl="api/sims/QCTOLearnershipSetup/GetAgreements"
                        searchParameters={this.state.searchParameters}
                        onCellSelectionChange={(rowid, headCell) => { this.FileDownload(rowid, headCell) }}
                        paged={true} />
                </div>
            </ViewContainer>
        );
    }
}

export default withRouter(QCTOLearnershipAgreementSearch);
import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "../../View.jsx";
import SearchView from "./../../widgets/SearchView";
import ViewUtils from '../../ViewUtils';
import EnhancedToolbar from '../../widgets/Toolbar';
import EnhancedTable from '../../widgets/ResponsiveTable';
import SDFComplianceLetterForm from './SDFComplianceLetterForm'
import AlertItem from "../../widgets/AlertItem";


const utils = new ViewUtils();
const toolbarRef = React.createRef();

const headCells = [
    { id: 'SDLNumber', numeric: false, disablePadding: true, label: 'SDL Number' },
    { id: 'LegalName', numeric: false, disablePadding: true, label: 'Legal Name' },
    { id: 'FinancialYear', numeric: false, disablePadding: true, label: 'Financial Year' },
    { id: 'WSPStatus', numeric: false, disablePadding: true, label: 'WSP Status' }
];

class SDFComplianceLetter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: {
                Id: this.props.location.state.selection
            },
            addDialogOpen: false,
            selected: false,
            searchParameters: [{ Name: "OrganisationID", Value: this.props.location.state.organisationID }, { Name: "FinancialYearID", Value: 0 }],
            clearSelectedRow: false,
            selectedID: null,
            clearSelectedRow: false
        }

        this.toolbarConfig = {
            items: [

                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },

                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'email', label: '', tooltip: 'Email SDF Compliance Letter', icon: 'EmailIcon', disabled: !this.selected },
                        { id: 'download', label: '', tooltip: 'Download SDF Compliance Letter', icon: 'DownloadIcon', disabled: !this.selected }
                    ]
                }
            ]
        }
    }


    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "email") {
            this.FileEmail(this.state.selectedID)
        } if (buttonId === "download") {
            this.FileDownload(this.state.selectedID);
        } else if (buttonId === "close") {
            this.props.history.push({
                pathname: "/skillsOrganisation",
                state: { selection: this.props.location.state.organisationID }
            })
        }
    }

    handleFormValueChange(values) {
        let FinancialYearID = values.FinancialYearID.Id;
        let newsearchParameters = [...this.state.searchParameters];
        let temp_element = { ...newsearchParameters[1] };
        temp_element.Value = FinancialYearID
        newsearchParameters[1] = temp_element;

        this.setState({
            searchParameters: JSON.parse(JSON.stringify(newsearchParameters)),
            FinancialYearID: FinancialYearID
        });
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedID: selection[0].Id, Message: "" });
        }
        else {
            this.setState({ selectedID: null, Message: "" });
        }
        toolbarRef.current.setButtonDisabled("email", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("download", selection === null || selection.length === 0);
    }

    FileDownload = () => {

        const url = `api/sims/SDFComplianceLetter/ViewSDFComplianceLetter`;

        utils.invokeApiPut(
            JSON.stringify({
                "OrganisationID": this.props.location.state.organisationID,
                "FinancialYearID": this.state.FinancialYearID
            }),
            url,
            (response) => {

            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
                link.click();

                if (!utils.isNull(response.Name) && response.Name !== "") {
                    this.setState({
                        Message: "SDF Compliance Letter successfully downloaded",
                        MessageStyle: "SUCCESS",
                        showMessage: true,
                        clearSelectedRow: true
                    });
                }
                else {
                    this.setState({
                        Message: "Error downloading SDF Compliance Letter.",
                        MessageStyle: "ERROR",
                        showMessage: true,
                        clearSelectedRow: true
                    });
                }

            toolbarRef.current.setButtonDisabled("email", true);
            toolbarRef.current.setButtonDisabled("download", true);
            }
        );
    };

    FileEmail = () => {

        const url = `api/sims/SDFComplianceLetter/EmailSDFComplianceLetter`;

        utils.invokeApiPut(
            JSON.stringify({
                "OrganisationID": this.props.location.state.organisationID,
                "FinancialYearID": this.state.FinancialYearID
            }),
            url,
            (response) => {
                this.setState({
                    Message: response.Message,
                    MessageStyle: response.MessageType,
                    showMessage: true,
                    clearSelectedRow: true
                });
                toolbarRef.current.setButtonDisabled("email", true);
                toolbarRef.current.setButtonDisabled("download", true);
            },
            this.error
        );
    }

    
    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    onClearSelectedRow = () => {
        this.setState({ clearSelectedRow: false });
    };

    render() {
        return (
            <ViewContainer title={""}>
                <p className="legend">
                    {"Organisation > SDF Compliance Letter " + (this.props.location.state.organisationName !== null ? "> " + this.props.location.state.organisationName + (this.props.location.state.sdlnumber !== null ? " - " + this.props.location.state.sdlnumber : "") : "> Add")}
                </p>

                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />

                <SDFComplianceLetterForm
                    valueChangeHandler={(values) => this.handleFormValueChange(values)}
                />
                <EnhancedToolbar highlight={false}
                    title="SDF Compliance Letter"
                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                    ref={toolbarRef}
                    config={this.toolbarConfig}
                />

                <EnhancedTable
                    headCells={headCells}
                    onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                    dataUrl="api/sims/SDFComplianceLetter/GetSearch"
                    searchParameters={this.state.searchParameters}
                    paged={true}
                    clearSelectedRow={this.state.clearSelectedRow}
                    onClearSelectedRow={this.onClearSelectedRow} />


            </ViewContainer>
        );
    }
}

export default withRouter(SDFComplianceLetter);

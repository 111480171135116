import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { makeStyles } from '@material-ui/core/styles';
import DocumentUpload from "../../widgets/DocumentUpload";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();

export const HelpdeskRequestAddForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [UploadFilename, setUploadFilename] = React.useState("");
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const styles = makeStyles({
        TextArea: {
            '& .MuiOutlinedInput-inputMultiline': {
                height: "200px !important",
                width: "600px !important"
            }
        }
    })

    const onUploadHandler = (data) => {
        if (data) {
            setUploadFilename(data.name);
        }
    };

    return (
        <EntityForm ref={ref} viewId="helpdesk" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="helpdeskrequest">

                    <div className="row w-100">
                        <div className="col w-100">
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="Title"
                                                key={tabValue}
                                                label="Title"
                                                className="w-100"
                                                form={form}
                                                required={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                parentId={0}
                                                dataUrl="api/sims/helpdeskrequest/GetLookupList?listId=HelpdeskRequestType"
                                                id="RequestTypeID"
                                                key={tabValue}
                                                label="Request Type"
                                                className="w-100"
                                                form={form}
                                                required={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                parentId={0}
                                                dataUrl="api/sims/helpdeskrequest/GetLookupList?listId=HelpdeskIssuePriorities"
                                                id="PriorityID"
                                                key={tabValue}
                                                label="Priority"
                                                className="w-100"
                                                form={form}
                                                required={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        {
                                            (utils.getCurrentRole() == "Primary SDF" || utils.getCurrentRole() == "Secondary SDF") ?
                                                <td>
                                                    <SelectItem
                                                        parentId={0}
                                                        dataUrl="api/sims/helpdeskrequest/GetLookupList?listId=HelpdeskIssueModules"
                                                        id="ModuleID"
                                                        key={tabValue}
                                                        label="Module"
                                                        className="w-100"
                                                        form={form}
                                                        required={true}
                                                        filterOptions={option => {
                                                            return option.Id == 1 && option.Description == "Skills Module";
                                                        }}
                                                    />
                                                </td>
                                                :
                                                <td>
                                                    <SelectItem
                                                        parentId={0}
                                                        dataUrl="api/sims/helpdeskrequest/GetLookupList?listId=HelpdeskIssueModules"
                                                        id="ModuleID"
                                                        key={tabValue}
                                                        label="Module"
                                                        className="w-100"
                                                        form={form}
                                                        required={true}
                                                    />
                                                </td>
                                        }
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="Message"
                                                key={tabValue}
                                                label="Message"
                                                className="w-100"
                                                required={true}
                                                inputProps={{
                                                    maxLength: 500
                                                }}
                                                multiline
                                                styles={styles()}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <DocumentUpload
                                                showViewer={false}
                                                onUploadHandler={onUploadHandler}
                                                buttonLabel="Attachment"
                                                filename={UploadFilename}
                                                form={form}
                                                id="RequestDocument"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form >
            }
        </EntityForm >
    );
})
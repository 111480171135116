import React from 'react';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';



const utils = new ViewUtils();
export const SDProviderTrainingEndDateForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);

    return (
        <EntityForm ref={ref} viewId="SDProviderTrainingEndDateForm"
            dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="SDProviderTrainingEndDateForm">
                    <table cellPadding="2" width="100%">
                        <tbody>
                            <tr>
                                <td>
                                    <CustomDatePicker
                                        id="TrainingEndDate"
                                        key={tabValue}
                                        label="Skills Development Provider Training End Date"
                                        className='{classes.textField} w-100'
                                        form={form}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </form>
            }
        </EntityForm>
    );
})

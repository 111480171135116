import React from 'react';
import { EntityForm } from '../../../widgets/Forms';
import { SelectItem } from '../../../widgets/SelectItem';


export default function GrantSearchForm(props) {

    return (
        <EntityForm ref={React.createRef()} viewId="GrantType" values={props.data}
            useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="GrantTypeSearchForm">
                    <td>
                        <SelectItem
                            dataUrl={"api/sims/GrantType/GetLookupList?listId=FinancialYear"}
                            id="FinancialYearID"
                            label="Financial Year"
                            className="w-150"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                        />
                    </td>
                </form>
            }
        </EntityForm>
    );
}

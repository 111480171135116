import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "../../View.css";
import "../../Grid.css";
import "../../Form.css";
import "../../App.css";
import SearchView from "../../widgets/SearchView";
import EmployerSearchForm from './EmployerSearchForm';
import { ViewContainer } from "../../View.jsx";
import ViewUtils from '../../ViewUtils';

const utils = new ViewUtils();

const headCells = [
    { id: "SDLNumber", numeric: false, disablePadding: true, label: "SDL Number" },
    { id: "LegalName", numeric: false, disablePadding: true, label: "Legal Name" },
    { id: "TradeName", numeric: false, disablePadding: true, label: "Trade Name" }
]

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'info', label: '', tooltip: 'Select Employer from the table and click save to add', icon: 'InfoIcon' }
            ]
        }
    ]
}

class EmployerSearch extends Component {
    constructor(props) {
        super(props);

        this.searchParameters = 
            //For search on the Employer search screen
            [
                { Name: "SDLNumber", Value: "" },
                { Name: "LegalName", Value: "" },
                { Name: "TradeName", Value: "" }
            ]

        let model = 
            {
                SDLNumber: "",
                LegalName: "",
                TradeName: ""
            }

        this.state = {
            model: model,
            keyUpSwitch: false,
            selectedEmployer: null,
            linkDialogOpen: false
        }
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title={this.props.showTitle === true || utils.isNull(this.props.showTitle) ? 'Employer' : null}>
                <SearchView
                    config={toolbarConfig}
                    mode={this.props.mode}
                    headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/DGApplicationForms2022/GetDGFormEmployerSearch"}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="SDLNumber"
                    SecondDescriptionProp="TradeName"
                    model={this.state.model}
                    searchParameters={this.searchParameters}
                    keyUpSwitch={this.state.keyUpSwitch}
                >
                    <EmployerSearchForm onKeyUp={(e) => { this.onkeyup(e) }} model={this.state.model} />
                </SearchView>
            </ViewContainer>
        )
    }
}

export default withRouter(
    connect()(EmployerSearch)
);
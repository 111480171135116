import { withRouter } from 'react-router-dom';
import React, { Component } from "react";
import "../Grid.css";
import "../Form.css";
import "../App.css";
import { ViewContainer } from "../View.jsx";
import ViewUtils from '../ViewUtils';
import EnhancedToolbar from '../widgets/Toolbar';
import EnhancedTable from '../widgets/ResponsiveTable';
import AlertItem from "../widgets/AlertItem";
import withMenuPermission from "../widgets/withMenuPermission";

const utils = new ViewUtils();
const toolbarRef = React.createRef();

const headCells = [
    { id: 'LegalName', numeric: false, disablePadding: true, label: 'Organisation Name' },
    { id: 'SDLNumber', numeric: false, disablePadding: true, label: 'SDL Number' },
    { id: 'SDFStatus', numeric: false, disablePadding: true, label: 'SDF Status' },
];

class SDFDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedId: null,
            searchParameters: [{ Name: "SDFID", Value: null }],
            clearSelectedRow: false,
            formArray: [],
            SDFID: null,
            sdfName: null,
            IDNo: null,
            OrganisationId: null
        }

        this.toolbarConfig = {
            items: [

                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'view', label: '', tooltip: 'View Organisation', icon: 'InfoIcon', disabled: true },
                        { id: 'edit', label: 'Edit SDF Details', tooltip: '' },
                        { id: 'link', label: 'Link/Remove Organisations', tooltip: '' },
                    ]
                }
            ]
        }
    }

    componentDidMount() {

        this.getDashboardDetails()
    }
    getDashboardDetails() {
        let url = "api/sims/SDFDashboard/GetSDFDashboardDetails";
        utils.invokeUrl(url, (response) => {
            this.setState({
                formArray: response,
                sdfName: response.FirstName + " " + response.Surname,
                IDNo: response.IDNo,
                SDFID: response.ID,
                searchParameters: [{ Name: "SDFID", Value: response.ID }]
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }


    handleToolbarButtonClick = (buttonId) => {
        //console.clear();
        //console.log(this.state.SDFID);
        if ("view" === buttonId) {
            this.props.history.push({
                pathname: "/skillsOrganisation",
                state: {
                    selection: this.state.OrganisationId
                }
            })
        }
        else if ("edit" === buttonId) {
            this.props.history.push({
                pathname: "/sdf",
                state: {
                    selection: this.state.SDFID,
                    IsDashboard: true
                }
            })
        }
        else if ("link" === buttonId) {
            let LookupUrl = `api/sims/sdforganisationdetails/`;
            this.props.history.push({
                pathname: '/sdfOrgDetails',
                state: {
                    SDFID: this.state.SDFID,
                    sdfName: this.state.sdfName,
                    IDNo: this.state.IDNo,
                    IsDashboard: true,
                    LookupUrl: LookupUrl
                }
            });
        }
    }

    handleFormValueChange(values) {

        let newsearchParameters = [...this.state.searchParameters];

        this.setState({
            searchParameters: JSON.parse(JSON.stringify(newsearchParameters)),
        });
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            if (selection[0].SDFStatus === "Approved" || selection[0].SDFStatus === "Pending") {
                this.setState({ selectedId: selection[0].Id, Message: "", clearSelectedRow: false, OrganisationId: selection[0].OrganisationID });
                toolbarRef.current.setButtonDisabled("view", selection === null || selection.length === 0);
            }
            else {
                this.setState({ selectedId: null, Message: "", clearSelectedRow: false, OrganisationId: selection[0].OrganisationID });
                toolbarRef.current.setButtonDisabled("view", true);
            }
        }
        else {
            this.setState({ selectedId: null, Message: "", clearSelectedRow: false, OrganisationId: null });
            toolbarRef.current.setButtonDisabled("view", true);
        }
    }

    postAddHandler(cancel, save) {
        if (save === true) {
            this.setState({
                addDialogOpen: false,
                selectedId: null,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                showTable: false,
                clearSelectedRow: true
            }, () => {
                this.setState({ showTable: true })
            });
        }
        else if (cancel === true) {
            this.setState({
                addDialogOpen: false,
                selectedId: null,
                Message: "",
                MessageStyle: "",
                clearSelectedRow: true
            })
        }
        else {
            this.setState({
                addDialogOpen: false,
                selectedId: null,
                clearSelectedRow: true
            })
        }
    }

    remove(url) {
        this.setState({ promptOpen: false, clearSelectedRow: true, selectedId: null }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedId}`;
        utils.invokeUrlDelete(fullUrl, this.successfulRemoveCallback, this.unsuccessfulRemoveCallback);
    }

    closePrompt = () => {
        this.setState({ promptOpen: false, clearSelectedRow: true, selectedId: null })
    }

    successfulRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedId: null, Message: response.Message, MessageStyle: response.MessageType.toLowerCase(),
                clearSelectedRow: true
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ Message: response.Message, MessageStyle: response.MessageType.toLowerCase() })
        }
    }

    onClearSelectedRow = () => {
        this.setState({ clearSelectedRow: false });
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />
                <br />
                <table width="100%" class="FormsTableHeader" color="#FFFFFF" backgroundColor="#01579B">
                    <tbody>
                        <tr>
                            <td align="center" bgcolor="#01579B">
                                <font color="#FFFFFF" size="4"><b>{"SDF Dashboard - " + (this.state.sdfName !== null ? this.state.sdfName + " - " + this.state.IDNo : "")}</b></font>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="searchResults">

                    <EnhancedToolbar highlight={false}
                        title="Organisations"
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig}
                    />
                    {this.state.SDFID ?
                        <EnhancedTable
                            headCells={headCells}
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            dataUrl={"api/sims/SDFDashboard/GetSDFDashboardOrganisations"}
                            searchParameters={this.state.searchParameters}
                            clearSelectedRow={this.state.clearSelectedRow} onClearSelectedRow={this.onClearSelectedRow}
                            paged={true} /> : null
                    }
                </div>

            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(SDFDashboard));
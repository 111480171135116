import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { ViewContainer } from "../../View.jsx";
import ViewUtils from '../../ViewUtils';
import Button from '@material-ui/core/Button';
import withMenuPermission from "../../widgets/withMenuPermission";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";
import './Style.css';
import { WSPATRImportForm } from './WSPATRImportForm';
import EnhancedTable from '../../widgets/ResponsiveTable';
import FormDialog from "../../etqa/FormDialog";

const utils = new ViewUtils();
const formRef = React.createRef();
const toolbarRef = React.createRef();
const headCells = [
    { id: 'ImportDate', numeric: false, disablePadding: false, label: 'Import Date' },
    { id: 'ImportFileName', numeric: false, disablePadding: false, label: 'Import FileName' },
    { id: 'ImportProgress', numeric: false, disablePadding: false, label: 'Import Progress' },
    { id: 'ImportStatusCode', numeric: false, disablePadding: false, label: 'Import Status Code' },
    { id: 'ImportStatus', numeric: false, disablePadding: false, label: 'Import Status' },
    { id: 'ImportReport', numeric: false, disablePadding: false, label: 'Report', contentType: 'document' }
];

const toolbarConfigDashboard = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'Back to WSP/ATR Dashboard', tooltip: '' }
            ]
        }
    ]
};

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#01579B',
        color: theme.palette.common.white,
        border: '1px solid white',
    },
    body: {
        fontSize: 11,
    },
}))(TableCell);

const StyledTableTextCell = withStyles((theme) => ({
    body: {
        fontSize: 14,
        fontWeight: 500
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            height: theme.palette.action.main
        },
    },
}))(TableRow);

const useStyles = theme => ({
    table: {
        minWidth: 700,
    },
});

class WspAtrForms extends Component {
    constructor(props) {
        super(props)
        this.state = {
            objectProps: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.object)) ? this.props.location.state.object : null,
            formArray: [],
            FinancialYear: '',
            OrganisationName: '',
            Status: '',
            model: {
                Name: "",
            },
            title: "",
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.WSPATRID)) ? this.props.location.state.WSPATRID : null,
            organisationID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.organisationID)) ? this.props.location.state.organisationID : null,
            showTable: true,
            searchParameters: [{ Name: "WSPATRID", Value: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.WSPATRID)) ? this.props.location.state.WSPATRID : null }],
            UploadDialogOpen: false,
            clearSelectedRow: false,
            Message: "",
            MessageStyle: ""

        };
    }

    toolbarConfig = {
        items: [
            {
                type: "buttonGroup",
                buttons: [
                    { id: 'import', label: 'Import', tooltip: 'Import WSPATR File', icon: 'ImportExportIcon', visible: true },
                    { id: 'refresh', label: 'Refresh', tooltip: 'Check Import Progress', icon: 'RefreshIcon', visible: true },
                    { id: 'template', label: 'Template', tooltip: 'Download WSP ATR Import Template', icon: 'DownloadIcon', visible: true }
                ]
            }
        ]
    };

    componentDidMount() {
        //this.getFormHead("1");
        this.getFormHeader(this.state.id)
        this.GetWSPATRFormSubmissionCheck(this.state.id);
        this.GetWSPATRFormPermissionCheck(this.state.id, "1");
    }

    GetWSPATRFormPermissionCheck(id, pageLoad) {
        let url = "api/sims/wspatr/GetWSPATRFormPermissionCheck?WSPATRID=" + this.state.id + "&WSPATRFormsID=0";
        utils.invokeUrl(url, (response) => {
            if (response.Result !== "Enabled") {
                this.setState({
                    adddisabled: true,
                    bulkimportdisabled: true,
                    removedisabled: true,
                    editdisabled: true,
                    updatedisabled: true,
                    canceldisabled: true,
                    formPermission: response.Result
                })
                toolbarRef.current.setButtonDisabled("import", true)
                toolbarRef.current.setButtonDisabled("refresh", true)
            }
            else { this.getFormHead(pageLoad); }
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    
    getFormHeader(id) {
        let url = "api/sims/wspatr/GetFindHeader?WSPATRID=" + this.state.id;
        utils.invokeUrl(url, (response) => {
            this.setState({
                FinancialYear: response.FinancialYear,
                OrganisationName: response.OrganisationName,
                WSPStatus: response.WSPStatus,
                SDLNumber: response.SDLNumber
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    getFormHead = (pageLoad) => {
        let url = "api/sims/WSPATRImport/CheckImportStatus?WSPATRID=" + this.state.id;
        utils.invokeUrl(url, (response) => {
            if (response.Result === "In Progress") {
                toolbarRef.current.setButtonDisabled("import", true);
            }
            else if (response.Result === "Completed") {
                toolbarRef.current.setButtonDisabled("import", false);
            }
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
        if(pageLoad === "0")
        {
        this.setState({
            showTable: false
        }, () => {
            this.setState({ showTable: true })
        });
    }
        
    }
    
    GetWSPATRFormSubmissionCheck(id) {
        let url = "api/sims/wspatr/GetWSPATRFormSubmissionCheck?WSPATRID=" + this.state.id;
        utils.invokeUrl(url, (response) => {
            this.setState({
                CanSubmit: response.Result
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }
    submit(id) {
        let url = "api/sims/wspatr/WSPATRSubmit?WSPATRID=" + this.state.id;
        utils.invokeUrl(url, (response) => {
            this.successCallback(response);
        },
            (response) => {
                this.error(response);
            },
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    successCallback(response) {
        this.setState({ Message: response.Message, MessageStyle: response.MessageType });
        //this.getFormHead("0");
        this.GetWSPATRFormPermissionCheck(this.state.id, "0");
    }
    error(response) {
        this.setState({ Message: response.Message, MessageStyle: response.MessageType });
    }

    close() {
        this.props.history.push({
            pathname: '/WspAtrDashboard',
            state: {
                organisationID: this.state.organisationID,
                organisationName: this.state.OrganisationName,
                sdlnumber: this.state.SDLNumber
            }
        });
    }

    handleToolbarButtonClick(buttonId) {
        if ("import" === buttonId) {
            this.setState({ UploadDialogOpen: true });
        }
        else if ("refresh" === buttonId) {
            this.CheckProgress();
        }
        else if ("template" === buttonId) {
            this.TemplateDownload();
        } else {
            this.close();
        }
    }

    buttonSubmitClickHandler() {
        this.submit();
    }

    handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {
        }
    }

    handleSelectionChange = (selection) => {
    }

    CheckProgress = () => {
        this.setState({Message: "", MessageStyle: ""})
        this.getFormHead("0");
    }

    postAddHandler(cancel, save) {
        if (save === true) {
            this.setState({
                UploadDialogOpen: false,
                selectedId: null,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                showTable: false,
                clearSelectedRow: true
            }, () => {
                this.setState({ showTable: true })
            });
        }
        else if (cancel === true) {
            this.setState({
                UploadDialogOpen: false,
                selectedId: null,
                Message: "",
                MessageStyle: "",
                clearSelectedRow: true
            })
        }
        else {
            this.setState({
                UploadDialogOpen: false,
                selectedId: null,
                clearSelectedRow: true
            })
        }
    }
    onClearSelectedRow = () => {
        this.setState({ clearSelectedRow: false });
    }

    ReportDownload = (rowid, headCell) => {
        if (headCell.contentType === "document" && headCell.id === "ImportReport") {
            this.downloadImportReport("api/sims/WSPATRImport/DownloadImportReport?WSPATRImportID=" + rowid);
        }
    };

    TemplateDownload = () => {
            this.downloadTemplate("api/sims/WSPATRImport/DownloadTemplate?WSPATRID=" + this.state.id);
        
    };

    downloadImportReport = (url) => {
            //console.log(headCell);
            utils.invokeUrl(url, (response) => {

                var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
                var url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = response.Name;
                link.target = '_blank';
                link.click();
            }, (e) => {
                this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
            }
            );
    }

    downloadTemplate = (url) => {
        utils.invokeUrl(url, (response) => {

            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );
}

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    render() {

        const { classes } = this.props;
        
        const submitrow = this.state.CanSubmit == "1" ?
            <Button variant="outlined" size="small" color="#01579B" onClick={() => this.buttonSubmitClickHandler()} className={classes.margin}>SUBMIT WSP/ATR</Button>
            :
            <b>WSP/ATR Status : {this.state.WSPStatus}</b>;

        return (
            <ViewContainer topPadding="0" title="">
                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />
                <br>
                </br>
                <table width="100%" class="FormsTableHeader">
                    <tbody>
                        <tr>
                            <td>
                            <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={toolbarConfigDashboard} />
                            </td>
                            <td align="center">
                                <b>WSP & ATR Forms</b><br />
                                <b>{this.state.FinancialYear}</b>
                            </td>
                            <td align="right">
                                <b>{this.state.OrganisationName} - {this.state.SDLNumber}</b>&nbsp;&nbsp;&nbsp;
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="100%">
                    <tbody>
                        <tr>
                        <td>
                        <EnhancedToolbar ref={toolbarRef} highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={this.toolbarConfig} />
                        </td>
                            <td colSpan="3" align="right" className="formContainer">
                                {submitrow}
                            </td>
                        </tr>
                    </tbody>
                </table>

                 <div className="searchResults"> 
                 {this.state.showTable === true && ( 
                        <EnhancedTable
                        headCells={headCells}
                        dataUrl={`api/sims/WSPATRImport/GetSearch`}
                        onCellSelectionChange={(rowid, headCell) => { this.ReportDownload(rowid, headCell) }}
                        searchParameters={this.state.searchParameters} paged={true} 
                        clearSelectedRow={this.state.clearSelectedRow} onClearSelectedRow={this.onClearSelectedRow}/>
                 )}
                    </div>  

                <FormDialog
                    open={this.state.UploadDialogOpen}
                    dialogTitle={"Import WSPATR"}
                    viewRef={this}
                    entityInfo={{ WSPATRID: this.state.id }}
                    formRef={formRef}
                    saveUrl={"api/sims/WSPATRImport/WSPATRImport"}
                    usePost={true}
                    closeHandler={(cancel, save) => this.postAddHandler(cancel, save)}
                >
                    
                <WSPATRImportForm
                     ref={formRef} WSPATRID={this.state.id}
                     valueChangeHandler={(values) => this.handleFormValueChange(values)} open={this.state.UploadDialogOpen}/>
                </FormDialog>


            </ViewContainer>

        );
    }
}
export default withRouter(withStyles(useStyles())(withMenuPermission(WspAtrForms)));

import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import LinkDialog from "../LinkDialog";
import Prompt from '../../widgets/Prompt';
import AlertItem from "../../widgets/AlertItem";
import SkillsProgramSetupSearch from '../program/SkillsProgramSetupSearch';
import ViewUtils from '../../ViewUtils';
import { OutComeStatusForm } from '../coachmentor/OutComeStatusForm';
import FormDialog from "../FormDialog";
import CoachMentorInterventionAudit from '../coachmentor/CoachMentorInterventionAudit';
//import { CoachMentorAssessmentEndDateForm } from "../common/CoachMentorAssessmentEndDateForm";
import Dialog from '@material-ui/core/Dialog';
import { Typography } from "antd";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import withMenuPermission from "../../widgets/withMenuPermission";


const headCells = [
    { id: 'SkillsProgrammeCode', numeric: false, disablePadding: true, label: 'QCTO Skills Programme ID' },
    { id: 'SkillsProgrammeTitle', numeric: false, disablePadding: true, label: 'QCTO Skills Programme Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' },
    { id: 'RegistrationStartDate', numeric: false, disablePadding: true, label: 'Registration Start Date' },
    { id: 'RegistrationEndDate', numeric: false, disablePadding: true, label: 'Registration End Date' },
    { id: 'AssessmentEndDate', numeric: false, disablePadding: true, label: 'Coach/Mentor Assessment End Date' },
    { id: 'Outcome', numeric: false, disablePadding: true, label: 'Outcome' },
    { id: 'Status', numeric: false, disablePadding: true, label: 'Status' }
];
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const QUSheadCells = [
    { id: 'ModuleCode', numeric: false, disablePadding: true, label: 'Module Code' },
    { id: 'ModuleTitle', numeric: false, disablePadding: true, label: 'Module Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' },
    { id: 'ModuleType', numeric: false, disablePadding: true, label: 'Module Type' },
    { id: 'LearningType', numeric: false, disablePadding: true, label: 'Learning Type' },
    { id: 'NQFLevel', numeric: false, disablePadding: true, label: 'NQF Level' }
];


const useStyles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        color: 'white',
    },
    table: {
        minWidth: 700,
    },
});

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();
const updateFormRef = React.createRef();

class CoachMentorSkillsProgrammeSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                SAQASkillsProgrammeID: "",
                SAQASkillsProgrammeTitle: "",
                CoachMentorID: this.props.location.state.CoachMentorID
            },
            showTable: true,
            linkDialogOpen: false,
            auditDialogOpen: false,
            updateDialogOpen: false,
            updateDialogOpen: false,
            updateDateformDialogOpen: false,
            selectedSkillsProgramme: null,
            propmtOpen: false,
            promptTitle: "Unlink QCTO Skills Programme From Coach/Mentor",
            promptContent: "Are you sure you want to unlink this QCTO Skills Programme from the Coach/Mentor?",
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "CoachMentorID", Value: this.props.location.state.CoachMentorID }],
            searchParameters2: [{ Name: "Id", Value: 0 }],
            OpenDialog: false,
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'link', label: '', tooltip: 'Link QCTO Skills Programme/s to the Coach/Mentor', icon: 'LinkIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'update', label: '', tooltip: 'Update the status and outcome of the selected QCTO skills programme', disabled: utils.isNull(this.state.selectedSkillsProgramme), icon: 'UpdateIcon', visible: this.props.menuPermissionItem.Edit },
                        { id: 'audit', label: '', tooltip: 'View Record Audit Trail', disabled: utils.isNull(this.state.selectedSkillsProgramme) , icon: 'AuditIcon' },
                        { id: 'unlink', label: '', tooltip: 'Un-link selected QCTO skills programme from the coachmentor', disabled: utils.isNull(this.state.selectedSkillsProgramme), icon: 'LinkOffIcon', visible: this.props.menuPermissionItem.Delete },
                        { id: 'updateDate', label: 'Update Coach/Mentor Assessment End Date', tooltip: 'Update the Coach/Mentor Assessment End Date', disabled: utils.isNull(this.state.selectedSkillsProgramme), visible: this.props.menuPermissionItem.Edit },
                        { id: 'view', label: '', tooltip: 'View Modules', icon: 'ViewIcon', disabled: utils.isNull(this.state.selectedSkillsProgramme) },
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/CoachMentor',
                state: { selection: this.props.location.state.CoachMentorID }
            });
        }
        else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, message: "" })
        }
        else if ("update" === buttonId) {
            this.setState({ updateDialogOpen: true, message: "" })
        }
        else if ("audit" === buttonId) {
            this.setState({ auditDialogOpen: true, message: "" })
        }
        else if ("unlink" === buttonId) {
            this.setState({ promptOpen: true })
        }
        else if ("updateDate" === buttonId) {
            this.setState({ updateDateDialogOpen: true, message: "" })
        }
        else if ("view" === buttonId) {
            this.setState({ OpenDialog: true, message: "" })
        }
    }

    successCallback = (response) => {
        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedSkillsProgramme: null,
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
            })
        }
        else if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    successUnlinkCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedSkillsProgramme: null, removeMessage: response.Message, removeMessageStyle: response.MessageType.toLowerCase() });
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unlinkSkillsProgramme(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("unlink", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedSkillsProgramme}&coachmentorId=${this.props.location.state.CoachMentorID}`;
        utils.invokeUrlDelete(fullUrl, this.successUnlinkCallback, this.unsuccessfulUnlinkCallback);
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false, auditDialogOpen: false, selectedSkillsProgramme: null
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false,
                auditDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            let newsearchParameters2 = [...this.state.searchParameters2];
            let temp_element = { ...newsearchParameters2[0] };
            temp_element.Value = selection[0].Id
            newsearchParameters2[0] = temp_element;
    
            this.setState({
                searchParameters2: JSON.parse(JSON.stringify(newsearchParameters2)),
                selectedSkillsProgramme: selection[0].Id,
                message: "",
                messageStyle: ""
            });
        }
        else {this.setState({
            searchParameters2: [{ Name: "Id", Value: 0 }],
            selectedSkillsProgramme: null,
            message: "",
            messageStyle: ""
        });
        }
        toolbarRef.current.setButtonDisabled("unlink", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("update", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("updateDate", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("audit", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("view", selection === null || selection.length === 0);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    unsuccessfulUnlinkCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ removeMessage: response.Message, removeMessageStyle: response.MessageType.toLowerCase() })
        }
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                updateDialogOpen: false, updateDateDialogOpen: false, selectedSiteVisit: null, linkDialogOpen: false, auditDialogOpen: false, selection: this.props.location.state.selection, selectedSkillsProgramme: null
            }, () => {
                this.setState({ showTable: true })
            });
        }
        else if (isCancel === true) {
            this.setState({
                updateDialogOpen: false,
                updateDateDialogOpen: false,
                linkDialogOpen: false,
                auditDialogOpen: false
            })
        }
    }

    dialogUpdateActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                updateDialogOpen: false, updateDateDialogOpen: false, selectedSiteVisit: null, linkDialogOpen: false, auditDialogOpen: false, selection: this.props.location.state.selection, selectedSkillsProgramme: null
            }, () => {
                this.setState({ showTable: true })
            });
        }
        else if (isCancel === true) {
            this.setState({
                updateDialogOpen: false,
                updateDateDialogOpen: false,
                linkDialogOpen: false,
                auditDialogOpen: false
            })
        }
    }

    CloseForm = () => {
        this.setState({
            OpenDialog: false,
            selectedId: null,
            clearSelectedRow: true,
            selectedSkillsProgramme: null
        });
        toolbarRef.current.setButtonDisabled("audit", true);
        toolbarRef.current.setButtonDisabled("update", true);
        toolbarRef.current.setButtonDisabled("unlink", true);
        toolbarRef.current.setButtonDisabled("updateDate", true);
        toolbarRef.current.setButtonDisabled("view", true);
    };

    render() {
        const { classes } = this.props;
        return (

            <ViewContainer title="">

                <p className="breadcrumbs">
                    {"Coach/Mentor > QCTO Skills Programmes " + (this.props.location.state.CoachMentorName !== null ? "> " + this.props.location.state.CoachMentorName : "> Add")}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        ref={toolbarRef}
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />

                    {this.state.showTable === true && (

                        <EnhancedTable
                            height="50vh"
                            headCells={headCells}
                            dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/CoachMentorSkillsProgramme/GetCoachMentorSkillsProgrammes"}
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}
                </div>

                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title={this.props.location.state.SkillsProgrammeTitle}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={`Link QCTO Skills Programmes to Coach/Mentor > ${this.props.location.state.CoachMentorName}`}
                    linkName={"SkillsProgrammes"} postUrl={"api/sims/CoachMentorSkillsProgramme/linkCoachMentorSkillsProgrammes"}
                    selectionId={this.props.location.state.CoachMentorID} usePost={true}
                >

                    <SkillsProgramSetupSearch dataUrl="api/sims/CoachMentorSkillsProgramme/GetCoachMentorLinkableSkillsProgrammes"
                        multiSelect={true} showTitle={false}
                        programmeId={this.props.location.state.CoachMentorID}
                        mode="lookup"
                    />
                </LinkDialog>

                <FormDialog
                    open={this.state.updateDialogOpen}
                    dialogTitle={"Update Outcome and Status"}
                    viewRef={this}
                    entityId={this.state.selectedSkillsProgramme}
                    formRef={addFormRef}
                    usePut={true}
                    saveUrl={"api/sims/CoachMentorSkillsProgramme/updateCoachMentorSkillsProgramme"}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                >
                    <OutComeStatusForm
                        ref={addFormRef}
                        id={this.state.selectedSkillsProgramme}
                        controllerName={"CoachMentorSkillsProgramme"}
                        dataURL='api/sims/CoachMentorSkillsProgramme/GetFindSkillsProgrammeOutcome'
                    />
                </FormDialog>

                {/*<FormDialog*/}
                {/*    open={this.state.updateDateDialogOpen}*/}
                {/*    dialogTitle={"Update Assessor Assessment End Date"}*/}
                {/*    viewRef={this}*/}
                {/*    entityId={this.state.selectedUnitStandard}*/}
                {/*    formRef={updateFormRef}*/}
                {/*    usePut={true}*/}
                {/*    saveUrl={"api/sims/CoachMentorSkillsProgramme/UpdateSkillsProgrammeAssessmentEndDate"}*/}
                {/*    closeHandler={(isCancelled, linked) => this.dialogUpdateActionHandler(isCancelled, linked)}*/}
                {/*>*/}
                {/*    <CoachMentorAssessmentEndDateForm ref={updateFormRef} id={this.state.selectedSkillsProgramme} dataURL='api/sims/CoachMentorSkillsProgramme/GetFindSkillsProgrammeAssessmentEndDate' controllerName='coachmentorskillsprogramme' />*/}
                {/*</FormDialog>*/}

                <LinkDialog open={this.state.auditDialogOpen}
                    title={"Audit Trail"}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={"Audit Trail"}
                    selectionId={this.props.location.state.selectedSkillsProgramme}
                >

                    <CoachMentorInterventionAudit dataUrl={"api/sims/CoachMentorSkillsProgramme/getCoachMentorSkillsProgrammeAudit"}
                        multiSelect={false} showTitle={false}
                        Id={this.state.selectedSkillsProgramme}
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => this.unlinkSkillsProgramme("api/sims/CoachMentorSkillsProgramme/RemoveCoachMentorSkillsProgramme")}
                    closePrompt={this.closePrompt}
                />

                <Dialog fullScreen open={this.state.OpenDialog} onClose={this.CloseForm} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.CloseForm} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h1" className={classes.title}>
                                {"QCTO Skills Programme Modules"}
                            </Typography>

                        </Toolbar >
                    </AppBar >
                    <br /><br />
                    <EnhancedTable
                        headCells={QUSheadCells}
                        dataUrl={"api/sims/coachmentorskillsprogramme/GetCoachMentorSPUS"}
                        makeRowBold={true}
                        searchParameters={this.state.searchParameters2} paged={false} autoFetchData={true} />
                </Dialog >
            </ViewContainer>
        );
    }
}

export default withRouter(withStyles(useStyles())(connect()(withMenuPermission(CoachMentorSkillsProgrammeSearch))));

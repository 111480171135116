import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Learner.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import ViewUtils from '../../ViewUtils';
import { EntityForm } from '../../widgets/Forms';
import { SelectItem } from '../../widgets/SelectItem';
import { LearnerLearnershipForm } from '../program/registration/LearnerLearnershipForm';
import { LearnerSkillsProgramForm } from '../program/registration/LearnerSkillsProgramForm';
import { LearnerUnitStandardForm } from '../program/registration/LearnerUnitStandardForm';
import { LearnerQualificationForm } from '../program/registration/LearnerQualificationForm';
import { LearnerTradeForm } from "../program/registration/LearnerTradeForm";
import { LearnerInternshipForm } from "../program/registration/LearnerInternshipForm";
import { LearnerBursaryForm } from "../program/registration/LearnerBursaryForm";
import { LearnerAETForm } from "../program/registration/LearnerAETForm";
import { LearnerWorkExperienceForm } from "../program/registration/LearnerWorkExperienceForm";
import { LearnerCandidacyForm } from "../program/registration/LearnerCandidacyForm";
import { LearnerMineCommunityForm } from "../program/registration/LearnerMineCommunityForm";
import { LearnerSmallScaleForm } from "../program/registration/LearnerSmallScaleForm";
import { LearnerUnemployedYouthDevelopmentForm } from "../program/registration/LearnerUnemployedYouthDevelopmentForm";
import { LearnerQCTOLearnershipForm } from '../program/registration/LearnerQCTOLearnershipForm';
import { LearnerQCTOArtisanForm } from '../program/registration/LearnerQCTOArtisanForm';
import { LearnerQCTOSkillsProgrammeForm } from '../program/registration/LearnerQCTOSkillsProgrammeForm';
import { LearnerHETLecturerSupportForm } from "../program/registration/LearnerHETLecturerSupportForm";
import { LearnerMEDPForm } from "../program/registration/LearnerMEDPForm";
import { LearnerWorkplaceCoachesForm } from "../program/registration/LearnerWorkplaceCoachesForm";
import { LearnerTVETNCVForm } from "../program/registration/LearnerTVETNCVForm";
import { LearnerSCPPIForm } from "../program/registration/LearnerSCPPIForm";
import AlertItem from "../../widgets/AlertItem";

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'back', label: 'BACK', tooltip: 'Back to Search', theme: true },
                { id: 'finish', label: 'FINISH', tooltip: 'Save and Finish Process', theme: true }
            ]
        }
    ]
};

const learnershipFormRef = React.createRef();
const skillsProgramFormRef = React.createRef();
const unitStandardFormRef = React.createRef();
const qualificationFormRef = React.createRef();

const tradeFormRef = React.createRef();
const internshipFormRef = React.createRef();
const bursaryFormRef = React.createRef();
const aetFormRef = React.createRef();
const fetFormRef = React.createRef();
const candidacyFormRef = React.createRef();
const mCFormRef = React.createRef();
const sSFormRef = React.createRef();
const uYDFormRef = React.createRef();
const hetFormRef = React.createRef();
const medpFormRef = React.createRef();
const wCFormRef = React.createRef();
const tVETFormRef = React.createRef();
const sCPPIFormRef = React.createRef();

const qctolearnershipFormRef = React.createRef();
const qctoartisanFormRef = React.createRef();
const qctoskillsProgramFormRef = React.createRef();

const utils = new ViewUtils();

class AddLearnerProgram extends Component {

    state = {
        model: {},
        searchParameters: null,
        message: "",
        dependency: null,
        formValue: { Intervension: null },
        selection: {},
        model: {
            ProviderID: null,
            EmployerID: null
        },
        selectedIntervension: null
    };

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ model: values });

            this.ECCheck(values);
            this.WACCheck(values);
        }
    }

    handleBursaryFormValueChange = (values) => {

        if (!utils.isNull(values)) {
            if (!utils.isNull(values.BursaryTypeID) && JSON.stringify(this.state.dependency) !== JSON.stringify(values.BursaryTypeID)) {
                const bursaryTypeID = {
                    Name: "BursaryTypeID",
                    Value: values.BursaryTypeID.Id
                }
                if (!utils.isNull(bursaryTypeID.Value)) {
                    this.setState({ dependency: bursaryTypeID })
                }
            };
            this.setState({ model: values });
        }
    }

    handleInternshipFormValueChange = (values) => {

        if (!utils.isNull(values)) {
            if (!utils.isNull(values.InternshipStartDate)) {
                utils.autoSetDate(this, internshipFormRef, values.InternshipStartDate, 2, 0, -1, "InternshipEndDate", "InternshipStartDate")
            }
            this.setState({ model: values });

            this.ECCheck(values);
        }
    }

    ECCheck(values) {
        if (!utils.isNull(values)) {
            if (!utils.isNull(values.EmployerID)) {
                let url = "api/sims/learner/EmployerContactCheck?EmployerID=" + values.EmployerID.Id;
                utils.invokeUrl(url, (response) => {
                    if (response.ECMessage != "") {
                        this.setState({
                            message: response.Result,
                            messageStyle: "ERROR",
                        })
                    }
                },
                    null,
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        }
                    }
                )
            }

            if (!utils.isNull(values.LeadEmployerID)) {
                let url = "api/sims/learner/EmployerContactCheck?EmployerID=" + values.LeadEmployerID.Id;
                utils.invokeUrl(url, (response) => {
                    if (response.ECMessage != "") {
                        this.setState({
                            message: response.Result,
                            messageStyle: "ERROR",
                        })
                    }
                },
                    null,
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        }
                    }
                )
            }
        }
    }

    WACCheck(values) {
        if (!utils.isNull(values)) {
            if (!utils.isNull(values.LeadWAID)) {
                let url = "api/sims/learner/WorkplaceApprovalContactCheck?WAID=" + values.LeadWAID.Id;
                utils.invokeUrl(url, (response) => {
                    if (response.Result != "") {
                        this.setState({
                            message: response.Result,
                            messageStyle: "ERROR",
                        })
                    }
                },
                    null,
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        }
                    }
                )
            }

            if (!utils.isNull(values.WAID)) {
                let url = "api/sims/learner/WorkplaceApprovalContactCheck?WAID=" + values.WAID.Id;
                utils.invokeUrl(url, (response) => {
                    if (response.ECMessage != "") {
                        this.setState({
                            message: response.Result,
                            messageStyle: "ERROR",
                        })
                    }
                },
                    null,
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        }
                    }
                )
            }
        }
    }

    handleWorkExperienceFormValueChange = (values) => {

        if (!utils.isNull(values)) {
            if (!utils.isNull(values.StartDate)) {
                if (values.WorkExperienceID.Description.includes("PTL5")) {
                    utils.autoSetDate(this, fetFormRef, values.StartDate, 1, 6, 0, "EndDate", "StartDate")
                }
                else if (values.WorkExperienceID.Description.includes("PTL1")) {
                    utils.autoSetDate(this, fetFormRef, values.StartDate, 1, 0, 0, "EndDate", "StartDate")
                }
                else if (values.WorkExperienceID.Description.includes("PTL2")) {
                    utils.autoSetDate(this, fetFormRef, values.StartDate, 1, 0, 0, "EndDate", "StartDate")
                }
                else if (values.WorkExperienceID.Description.includes("PTL3")) {
                    utils.autoSetDate(this, fetFormRef, values.StartDate, 0, 6, 0, "EndDate", "StartDate")
                }
                else if (values.WorkExperienceID.Description.includes("PTL4")) {
                    utils.autoSetDate(this, fetFormRef, values.StartDate, 0, 6, 0, "EndDate", "StartDate")
                }
                else if (values.WorkExperienceID.Description.includes("PTL")) {
                    utils.autoSetDate(this, fetFormRef, values.StartDate, 1, 0, 0, "EndDate", "StartDate")
                }
                else if (values.WorkExperienceID.Description.includes("VW")) {
                    utils.autoSetDate(this, fetFormRef, values.StartDate, 0, 2, 0, "EndDate", "StartDate")
                }
                else if (values.WorkExperienceID.Description.includes("TVET")) {
                    utils.autoSetDate(this, fetFormRef, values.StartDate, 2, 0, 0, "EndDate", "StartDate")
                }
                else {
                    utils.autoSetDate(this, fetFormRef, values.StartDate, 0, 6, 0, "EndDate", "StartDate")
                }
            }
            this.setState({ model: values });

            this.ECCheck(values);
        }
    }

    handleCandidacyFormValueChange = (values) => {

        if (!utils.isNull(values)) {
            if (!utils.isNull(values.CommencementDate)) {
                utils.autoSetDate(this, fetFormRef, values.CommencementDate, 3, 0, 0, "CompletionDate", "CommencementDate")
            }
            this.setState({ model: values });

            this.ECCheck(values);
        }
    }

    handleProgrammeFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({
                selectedIntervension: values.Intervension,
                message: "",
                messageStyle: "" });
        }
    }

    onChangeProviderIDHandler = (form, id, event) => {
        let newModel = { ...form.values }
        newModel.ProviderContactID = null;
        this.setState({ model: newModel, clearOptions: true })
    }

    onChangeEmployerIDHandler = (form, id, event) => {
        let newModel = { ...form.values }
        newModel.EmployerContactID = null;
        this.setState({ model: newModel, clearOptions: true })
    }

    onChangeSDProviderIDHandler = (form, id, event) => {
        let newModel = { ...form.values }
        newModel.SDProviderContactID = null;
        this.setState({ model: newModel, clearOptions: true })
    }

    onChangeWAIDHandler = (form, id, event) => {
        let newModel = { ...form.values }
        newModel.WAContactID = null;
        this.setState({ model: newModel, clearOptions: true })
    }

    onChangeACIDHandler = (form, id, event) => {
        let newModel = { ...form.values }
        newModel.ACContactID = null;
        this.setState({ model: newModel, clearOptions: true })
    }

    handleWorkplaceCoachesFormValueChange = (values) => {

        if (!utils.isNull(values)) {
            if (!utils.isNull(values.CommencementDate)) {
                utils.autoSetDate(this, wCFormRef, values.CommencementDate, 1, 0, -1, "CompletionDate", "CommencementDate")
            }
            this.setState({ model: values });

            this.ECCheck(values);
        }
    }

    handleToolbarButtonClick(buttonId) {

        if ("back" === buttonId) {
            this.props.stepHandler(3, false, this.state.model);
        } else if ("finish" === buttonId) {
            let formValue = null;

            if (!utils.isNull(this.state.selectedIntervension)) {
                if ((1 == this.state.selectedIntervension.Id)) {
                    formValue = learnershipFormRef.current.getValue();
                } else if ((2 == this.state.selectedIntervension.Id)) {
                    formValue = skillsProgramFormRef.current.getValue();
                } else if ((3 == this.state.selectedIntervension.Id)) {
                    formValue = unitStandardFormRef.current.getValue();
                } else if ((4 == this.state.selectedIntervension.Id)) {
                    formValue = qualificationFormRef.current.getValue();
                } else if ((5 == this.state.selectedIntervension.Id)) {
                    formValue = tradeFormRef.current.getValue();
                } else if ((6 == this.state.selectedIntervension.Id)) {
                    formValue = internshipFormRef.current.getValue();
                } else if ((7 == this.state.selectedIntervension.Id)) {
                    formValue = bursaryFormRef.current.getValue();
                } else if ((8 == this.state.selectedIntervension.Id)) {
                    formValue = aetFormRef.current.getValue();
                } else if ((9 == this.state.selectedIntervension.Id)) {
                    formValue = fetFormRef.current.getValue();
                } else if ((10 == this.state.selectedIntervension.Id)) {
                    formValue = candidacyFormRef.current.getValue();
                } else if ((11 == this.state.selectedIntervension.Id)) {
                    formValue = mCFormRef.current.getValue();
                } else if ((12 == this.state.selectedIntervension.Id)) {
                    formValue = sSFormRef.current.getValue();
                } else if ((13 == this.state.selectedIntervension.Id)) {
                    formValue = uYDFormRef.current.getValue();
                } else if ((18 == this.state.selectedIntervension.Id)) {
                    formValue = qctolearnershipFormRef.current.getValue();
                } else if ((19 == this.state.selectedIntervension.Id)) {
                    formValue = qctoartisanFormRef.current.getValue();
                } else if ((20 == this.state.selectedIntervension.Id)) {
                    formValue = qctoskillsProgramFormRef.current.getValue();
                } else if ((14 == this.state.selectedIntervension.Id)) {
                    formValue = hetFormRef.current.getValue();
                } else if ((15 == this.state.selectedIntervension.Id)) {
                    formValue = medpFormRef.current.getValue();
                } else if ((16 == this.state.selectedIntervension.Id)) {
                    formValue = wCFormRef.current.getValue();
                } else if ((17 == this.state.selectedIntervension.Id)) {
                    formValue = tVETFormRef.current.getValue();
                } else if ((21 == this.state.selectedIntervension.Id)) {
                    formValue = sCPPIFormRef.current.getValue();
                }
                if (!utils.isNull(formValue)) {
                    formValue.programmeType = this.state.selectedIntervension.Id;
                    this.props.stepHandler(3, true, formValue);
                }
            }
            else {
                formValue = {};
                this.props.stepHandler(3, true, formValue);
            }
        }
    }

    render() {
        return (
            <div style={{ paddingTop: "0 !important", paddingLeft: "32px", height: "100%"}}>
                {
                    !utils.isStringEmpty(this.props.saveMessage) ?
                        <p className={this.props.hasSaveError ? "errorMessage" : "message"}>
                            {this.props.saveMessage}
                        </p>
                        :
                        ""
                }
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <EntityForm viewId="learnerProgramIntervension" values={this.state.formValue} useCustomSettings={false} valueChangeHandler={(values) => { this.handleProgrammeFormValueChange(values); }}>
                    {form =>
                        <form className={form.classes.container} id="learnerProgramIntervensionForm">
                            <div className="row w-50">
                                <div className="col w-100">
                                    <SelectItem
                                        dataUrl="api/sims/learner/GetLearnerInterventionsByRole"
                                        id="Intervension"
                                        label="Programme Type"
                                        className="{classes.select} w-100"
                                        form={form} />
                                </div>
                            </div>
                        </form>
                    }
                </EntityForm>
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 1 ?
                    <div style={{
                        margin: "8px 0 16px 8px",
                        height: "38%",
                        //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 1 ? 'block' : 'none'
                    }}>
                        <p className="legend">
                            Enroll to a Learnership
                            </p>
                        <div style={{ height: "100%"}}>
                                <LearnerLearnershipForm data={this.props.data} hideFields={true} ref={learnershipFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }}
                                    onChangeProviderIDHandler={this.onChangeProviderIDHandler}
                                    onChangeEmployerIDHandler={this.onChangeEmployerIDHandler} />
                        </div>
                    </div>
                    :
                    ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 2 ?
                    <div style={{
                        margin: "8px 0 16px 8px",
                        height: "38%",
                        //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 2 ? 'block' : 'none'
                    }}>
                        <p className="legend">
                            Enroll to a Skills Programme
                            </p>
                        <div style={{ height: "90%", overflow: "auto" }}>
                            <LearnerSkillsProgramForm  data={this.props.data} hideFields={true} ref={skillsProgramFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                        </div>
                    </div>
                    :
                    ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 3 ?
                    <div style={{
                        margin: "8px 0 16px 8px",
                        height: "38%",
                        //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 3 ? 'block' : 'none'
                    }}>
                        <p className="legend">
                            Enroll to a Unit Standard
                            </p>
                        <div style={{ height: "90%", overflow: "auto" }}>
                            <LearnerUnitStandardForm data={this.props.data} hideFields={true} ref={unitStandardFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                        </div>
                    </div>
                    :
                    ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 4 ?
                    <div style={{
                        margin: "8px 0 16px 8px",
                        height: "38%",
                        //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                    }}>
                        <p className="legend">
                            Enroll to a Qualification
                            </p>
                        <div style={{ height: "90%", overflow: "auto" }}>
                            <LearnerQualificationForm data={this.props.data} hideFields={true} ref={qualificationFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                        </div>
                    </div>
                    :
                    ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 5 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a Trade
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerTradeForm data={this.props.data} hideFields={true} ref={tradeFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }}
                                    onChangeProviderIDHandler={this.onChangeProviderIDHandler}
                                    onChangeEmployerIDHandler={this.onChangeEmployerIDHandler} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 6 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a Internship
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerInternshipForm data={this.props.data} hideFields={true} ref={internshipFormRef} valueChangeHandler={(values) => { this.handleInternshipFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 7 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a Bursary
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerBursaryForm data={this.props.data} hideFields={true} dependency={this.state.dependency} ref={bursaryFormRef} valueChangeHandler={(values) => { this.handleBursaryFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 8 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to an AET
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerAETForm data={this.props.data} hideFields={true} ref={aetFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 9 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a Work Experience
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerWorkExperienceForm data={this.props.data} hideFields={true} ref={fetFormRef} valueChangeHandler={(values) => { this.handleWorkExperienceFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 10 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a Candidacy Programme
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerCandidacyForm data={this.props.data} hideFields={true} ref={candidacyFormRef} valueChangeHandler={(values) => { this.handleCandidacyFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 11 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a Mine Community Programme
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerMineCommunityForm data={this.props.data} hideFields={true} ref={mCFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 12 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a Small Scale Programme
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerSmallScaleForm data={this.props.data} hideFields={true} ref={mCFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
				{
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 13 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to an Unemployed Youth Development Programme
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerUnemployedYouthDevelopmentForm data={this.props.data} hideFields={true} ref={uYDFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 14 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a HET Lecturer Support Programme
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerHETLecturerSupportForm data={this.props.data} hideFields={true} ref={hetFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }


                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 18 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a QCTO Learnership Programme
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerQCTOLearnershipForm data={this.props.data} hideFields={true} ref={qctolearnershipFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }}
                                    onChangeSDProviderIDHandler={this.onChangeSDProviderIDHandler}
                                    onChangeWAIDHandler={this.onChangeWAIDHandler}
                                    onChangeACIDHandler={this.onChangeACIDHandler} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 15 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a Management and Executive Development Programme
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerMEDPForm data={this.props.data} hideFields={true} ref={medpFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }

				{
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 19 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a QCTO Artisan Programme
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerQCTOArtisanForm data={this.props.data} hideFields={true} ref={qctoartisanFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }}
                                    onChangeSDProviderIDHandler={this.onChangeSDProviderIDHandler}
                                    onChangeWAIDHandler={this.onChangeWAIDHandler}
                                    onChangeACIDHandler={this.onChangeACIDHandler} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 16 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a Workplace Coaches Programme
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerWorkplaceCoachesForm data={this.props.data} hideFields={true} ref={wCFormRef} valueChangeHandler={(values) => { this.handleWorkplaceCoachesFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }

				{
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 20 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a QCTO Skills Programme
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerQCTOSkillsProgrammeForm data={this.props.data} hideFields={true} ref={qctoskillsProgramFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 17 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a TVET NCV Support Programme
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerTVETNCVForm data={this.props.data} hideFields={true} ref={tVETFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }}
                                    onChangeProviderIDHandler={this.onChangeProviderIDHandler}
                                    onChangeEmployerIDHandler={this.onChangeEmployerIDHandler} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 21 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a Short Courses Provided by Public Institution Programme
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerSCPPIForm data={this.props.data} hideFields={true} ref={aetFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td width="90%">
                                    </td>
                                    <td width="20%" align="right">
                                        <div style={{ width: "120px", marginTop: "16px" }}>
                                            <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} config={toolbarConfig} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
            </div>
        );
    }
}

export default withRouter(
    connect()(AddLearnerProgram)
);

import React from 'react';
import { SelectItem } from '../../../widgets/SelectItem';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from '../../../ViewUtils';


const utils = new ViewUtils();


export const GrantTransactionForm = React.forwardRef((props, ref) => {

    return (
        <EntityForm ref={ref}
            viewId="GrantTransaction"
            values={props.data}
            entityLookupId={props.id}
            entityLookupController={props.entityLookupController} org="saseta"
            useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}
            dataURL='api/sims/GrantTransaction/getfind'>
            {form =>
                <form className={form.classes.container} id="GrantImportForm">
    
                        <table cellPadding="2" width="80%">
                            <tbody>
                                <tr>
                                    <td>
                                        <SelectItem
                                            dataUrl="api/sims/GrantTransaction/GetGrantCode"
                                            id="GrantCodeID"
                                            label="Grant Type"
                                            className="w-100"
                                            required={true}
                                            form={form} 
                                            hasDefault={props.hasDefault}/>
                                    </td> 
                                </tr>
                            </tbody>
                        </table>
                </form>
            }
        </EntityForm>
    );
})

export default GrantTransactionForm;

import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import LinkDialog from "../LinkDialog";
import SearchView from "../../widgets/SearchView";
import Prompt from '../../widgets/Prompt';
import AETSetupSearchForm from '../program/AETSetupSearchForm';
import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";


const headCells = [
    { id: 'AETProgrammeCode', numeric: false, disablePadding: true, label: 'AET Programme Code' },
    { id: 'AETProgrammeDescription', numeric: false, disablePadding: true, label: 'AET Programme Title' },
    { id: 'AETTimePeriod', numeric: false, disablePadding: true, label: 'Time Period' },
    { id: 'Status', numeric: false, disablePadding: true, label: 'Programme Status' },
    { id: 'StartDate', numeric: false, disablePadding: true, label: 'Start Date' },
    { id: 'EndDate', numeric: false, disablePadding: true, label: 'End Date' }
];



const utils = new ViewUtils();
const toolbarRef = React.createRef();

class LearnerAET extends Component {
    constructor(props) {
        super(props);

        console.log(this.props.programmeId);
        this.searchParameters = !utils.isNull(this.props.location.state.learnerID) ? [
            { Name: "AETProgrammeCode", Value: "" },
            { Name: "AETProgrammeDescription", Value: "" },
            { Name: "ProgrammeID", Value: this.props.programmeId },
            { Name: "LearnerID", Value: this.props.location.state.learnerID }
        ]
            :
            [
                { Name: "AETProgrammeCode", Value: "" },
                { Name: "AETProgrammeDescription", Value: "" },
                { Name: "LearnerID", Value: this.props.location.state.learnerID }
            ];

        let model = !utils.isNull(this.props.location.state.learnerID) ?
            {
                AETProgrammeCode: "",
                AETProgrammeDescription: "",
                ProgrammeID: this.props.programmeId,
                LearnerID: this.props.location.state.learnerID

            }
            :
            {
                AETProgrammeCode: "",
                AETProgrammeDescription: "",
                LearnerID: this.props.location.state.learnerID
            }


        if (!utils.isNull(props.exclusions)) {
            model.Exclusions = props.exclusions;
            this.searchParameters.push({ Name: "Exclusions", Value: props.exclusions });
        }


        this.state = !utils.isNull(this.props.location.state.learnerID) ?
            {
                model: model,
                keyUpSwitch: false,
                selectedLearnerAET: null,
                CurrentUserRole: null
            }
            :
            {
                model: model,
                keyUpSwitch: false,
                selectedLearnerAET: null,
                CurrentUserRole: null
            };


        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'view', label: '', tooltip: 'View', icon: 'ViewIcon', visible: this.props.menuPermissionItem.View },
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', visible: this.props.menuPermissionItem.Edit },
                        { id: "delete", label: "", tooltip: "Remove AET", icon: "DeleteIcon", visible: this.props.menuPermissionItem.Delete },
                        { id: 'generate', label: 'Generate AET Enrolment Form', icon: 'DownloadIcon', path: false, tooltip: 'Generate AET Enrolment Form', disabled: utils.isNull(this.state.selectedLearnerAET) }
                    ]
                }
            ]
        }

        //TODO: Add url for AET removal
        this.deleteUrl = ""
    }


    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/learner',
                state: {
                    selection: this.props.location.state.learnerID
                }
            });
        }
        else if (buttonId === "add") {
            this.props.history.push({
                pathname: '/AddLearnerAET',
                state: {
                    LearnerID: this.props.location.state.learnerID,
                    AETID: null,
                    hideFields: true,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.menuPermissionItem
                }
            })
        }
        else if (buttonId === "edit") {
            this.props.history.push({
                pathname: '/AddLearnerAET',
                state: {
                    LearnerID: this.props.location.state.learnerID,
                    AETID: this.state.selectedLearnerAET[0].Id,
                    hideFields: false,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.menuPermissionItem,
                    viewOnly: this.state.selectedLearnerAET[0].Status === "Completed" ? true : false
                }
            })
        }
        else if (buttonId === "view") {
            this.props.history.push({
                pathname: '/AddLearnerAET',
                state: {
                    LearnerID: this.props.location.state.learnerID,
                    AETID: this.state.selectedLearnerAET[0].Id,
                    hideFields: false,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.menuPermissionItem,
                    viewOnly: true
                }
            })
        }

        else if (buttonId === "generate") {
            this.generateDocuments();
            toolbarRef.current.setButtonDisabled("edit", true);
            toolbarRef.current.setButtonDisabled("delete", true);
            toolbarRef.current.setButtonDisabled("generate", true);
        }

    }

    generateDocuments = () => {

        let url = "api/sims/learnerAET/GenerateLearnerAgreement?Id=" + this.state.selectedLearnerAET[0].Id;
        utils.invokeUrl(url, (response) => {
            utils.showMessage(this, response.Message, response.MessageType);
            let documentInfo = JSON.parse(response.Message);
            this.FileDownload(documentInfo.OriginalFileName);
            this.setState({ selectedLearnerSkillsProgram: null, clearSelectedRow: true })
        },
            null,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    };

    FileDownload = (documentName) => {

        utils.invokeUrl("api/sims/learnerAET/DownloadLearnerAgreement?FileName=" + documentName, (response) => {

            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );

    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedLearnerAET: selection })

            let url = "api/sims/LearnerAET/CheckCanEditProgramme?Id=" + selection[0].Id;
            utils.invokeUrl(url, (response) => {
                toolbarRef.current.setButtonDisabled("edit", response.Result === "0" ? true : false)
                if (selection[0].Status === "Completed" || selection[0].Status === "Registered") {
                    toolbarRef.current.setButtonDisabled("delete", true)
                }
                else {
                    toolbarRef.current.setButtonDisabled("delete", false)
                }

            },
                (e) => {
                    toolbarRef.current.setButtonDisabled("edit", true)
                },
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    }
                }
            )
        }
        else {
            this.setState({ selectedLearnerAET: null })
        }
    }


    onkeyup(e) {
        //console.log(e.charCode)
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {`Learner > AET's > ${this.props.location.state.learnerNames}`}
                </p>
                <SearchView headCells={headCells} dataUrl={utils.isNull(this.props.dataUrl) ? "api/sims/learneraet/GetLearnerAETSearch" : this.props.dataUrl}
                    deleteUrl={"api/sims/learneraet/unlinkLearnerAET"} useDeletePost={true}
                    entityViewPath='/AddLearnerAET'
                    model={this.state.model} toolbarConfig={this.toolbarConfig}
                    toolbarRef={toolbarRef}
                    searchParameters={this.searchParameters} onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="AETProgrammeDescription" mode={this.props.mode}
                    history={this.props.history} multiSelect={this.props.multiSelect}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                    promptTitle={"Remove Learner AET"}
                    promptContent={"Are you sure you want to remove the learner AET record?"}
                >
                    <AETSetupSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                        role={utils.getCurrentRole()}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(LearnerAET));


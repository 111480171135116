import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../../widgets/Toolbar';
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import { ViewContainer } from "../../../View.jsx";
import ViewUtils from "../../../ViewUtils";
import AlertItem from "../../../widgets/AlertItem";
import { GrantTypeForm } from './GrantTypeForm';
import withMenuPermission from "../../../widgets/withMenuPermission";



const GrantTypeRef = React.createRef();
const utils = new ViewUtils();
const toolbarRef = React.createRef();

class GrantTypeDetails extends Component {

    state = {
        /*Deciding on unique identifier for existing tranche details title*/
        TrancheDetails: {
            Description: "",
            Code: ""
        },
        messageStyle: "message",
        id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.GrantTypeID)) ? this.props.location.state.GrantTypeID : null,
        showTable: true,
        hideFields: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.hideFields)) ? this.props.location.state.hideFields : null,
        counter: 0
    };

    toolbarConfig = {
        items: [

            {
                type: "buttonGroup",
                buttons: [
                    { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                ]
            },
            {
                type: "devider"
            },
            {

                type: "buttonGroup",
                buttons: [
                    { id: 'save', label: 'Submit', tooltip: 'Submit', icon: 'SaveIcon', visible: (this.props.menuPermissionItem.Add || this.props.menuPermissionItem.Edit) },
                    { id: 'account', label: '', tooltip: 'Grant Type Account Details', icon: 'AccountTreeOutlinedIcon', visible: !this.state.hideFields },

                ]
            }

        ]
    };

    handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {
        }
    }


    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("account" === buttonId) {
            this.props.history.push({
                pathname: '/GrantTypeAccountDetails',
                state: {
                    GrantTypeID: this.state.id
                }
            })
        }
    }

    successCallback(response) {
        this.setState({
            message: response.Message, messageStyle: response.MessageType, counter: this.state.counter + 1
        });

    }
    errorCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    save = () => {
        let formValue = GrantTypeRef.current.getValue();

        if (formValue !== null && typeof formValue !== 'undefined') {
            let isAdd = true;
            if (!utils.isNull(this.state.id)) {
                formValue.id = this.state.id;
                isAdd = false;
            }

            if (isAdd) {
                const url = `api/sims/GrantTypeDetails/GrantTypeInsert`;
                utils.saveForm(this, GrantTypeRef, url, (response) => {
                    this.setState({ id: response.EntityId });
                    utils.showMessage(this, response.Message, response.MessageType);
                    response.MessageType === "ERROR" ? this.errorCallback(response) : this.successCallback(response);
                }, this.errorCallback);
            } else {
                const url = `api/sims/GrantTypeDetails/GrantTypeUpdate`;
                utils.updateForm(this, GrantTypeRef, url, (response) => {
                    this.setState({ id: response.EntityId});
                    utils.showMessage(this, response.Message, response.MessageType);
                    response.MessageType === "ERROR" ? this.errorCallback(response) : this.successCallback(response);
                }, this.errorCallback);
            }
        }
    }

    closeView() {
        this.props.history.push({
            pathname: '/GrantTypeSearch'
        });
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
            <p className="breadcrumbs">
                {"Grant Type > Details"}
            </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    title=""
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    ref={toolbarRef}
                                    config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "80vh" }}>
                                    {this.state.showTable === true && (
                                        <GrantTypeForm ref={GrantTypeRef}
                                            viewRef={this}
                                            id={this.state.id}
                                            hideFields={this.state.hideFields}
                                            valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                            data={!utils.isNull(this.props.location.state.data) ? this.props.location.state.data : null} />
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(GrantTypeDetails));

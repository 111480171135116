import * as actionTypes from './actionTypes';

export const authUserStart = () => {
    return {
        type: actionTypes.AUTH_USER_START
    };
};

export const authUserSuccess = (authUserData, timer, loginPath) => {
    return {
        type: actionTypes.AUTH_USER_SUCCESS,
        authUserData: authUserData,
        authUserLoginTimer: timer,
        loginRedirectToLoginPath: loginPath
    };
};

export const authUserFail = (authUserError) => {
    return {
        type: actionTypes.AUTH_USER_FAIL,
        authUserError: authUserError
    };
};

export const authUserRedirectToLogin = () => {
    return {
        type: actionTypes.AUTH_USER_REDIRECTTOLOGIN
    };
};

function CheckError(response) {
    if (response.status >= 200 && response.status <= 299) {
        return response.json();
    } else {
        throw Error(response.statusText);
    }
}

export const authUser = (username, password, loginPath) => {
    return dispatch => {
        dispatch(authUserStart());
        const fetchConfig = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ Username: username, Password: password })
        };
        fetch(`${process.env.PUBLIC_URL}/api/sims/security/login`, fetchConfig)
            .then(CheckError)
            .then((responseJson) => {
                let response = typeof responseJson === "object" ? responseJson : JSON.parse(responseJson);
                let timer;
                if (response.Authenticated || response.OTPSent) {
                    timer = setTimeout(() => {
                        dispatch(authUserRedirectToLogin());
                    }, 300000);
                }
                dispatch(authUserSuccess(response, timer, loginPath));
            }).catch((error) => {
                dispatch(authUserFail("A system error has occured. Please contact your system administrator"));
            });
    };
};

export const authADUser = (/*username, password,*/ loginPath, Token) => {
    return dispatch => {
        dispatch(authUserStart());
        const fetchConfig = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + Token
            }/*,
            body: JSON.stringify({ Username: username, Password: password })*/
        };
        fetch(`${process.env.PUBLIC_URL}/api/sims/ADsecurity/login`, fetchConfig)
            .then(CheckError)
            .then((responseJson) => {
                let response = typeof responseJson === "object" ? responseJson : JSON.parse(responseJson);
                let timer;
                if (response.Authenticated || response.OTPSent) {
                    timer = setTimeout(() => {
                        dispatch(authUserRedirectToLogin());
                    }, 300000);
                }
                dispatch(authUserSuccess(response, timer, loginPath));
            }).catch((error) => {
                dispatch(authUserFail("A system error has occured. Please contact your system administrator"));
            });
    };
};

export const authOTPStart = () => {
    return {
        type: actionTypes.AUTH_OTP_START
    };
};

export const authOTPSuccess = (authUserData) => {
    return {
        type: actionTypes.AUTH_OTP_SUCCESS,
        authUserData: authUserData
    };
};

export const authOTPFail = (authOTPError) => {
    return {
        type: actionTypes.AUTH_OTP_FAIL,
        authUserError: authOTPError
    };
};

export const authOTP = (OTP, UserKey) => {
    return dispatch => {
        dispatch(authOTPStart());
        const fetchConfig = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ OTP: OTP, UserKey: UserKey })
        };
        fetch(`${process.env.PUBLIC_URL}/api/sims/security/confirmOTP`, fetchConfig)
            .then(CheckError)
            .then((responseJson) => {
                let response = typeof responseJson === "object" ? responseJson : JSON.parse(responseJson);
                dispatch(authOTPSuccess(response));
            }).catch((error) => {
                dispatch(authOTPFail("A system error has occured. Please contact your system administrator"));
            });
    };
};

export const authRoleGetStart = () => {
    return {
        type: actionTypes.AUTH_ROLEGET_START
    };
};

export const authRoleGetSuccess = (authUserData) => {
    return {
        type: actionTypes.AUTH_ROLEGET_SUCCESS,
        authUserData: authUserData
    };
};

export const authRoleGetFail = (authRoleGetError) => {
    return {
        type: actionTypes.AUTH_ROLEGET_FAIL,
        authUserError: authRoleGetError
    };
};

export const authRoleGet = (UserKey) => {
    return dispatch => {
        dispatch(authRoleGetStart());
        const fetchConfig = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ UserKey: UserKey })
        };
        fetch(`${process.env.PUBLIC_URL}/api/sims/security/roles`, fetchConfig)
            .then(CheckError)
            .then((responseJson) => {
                let response = typeof responseJson === "object" ? responseJson : JSON.parse(responseJson);
                dispatch(authRoleGetSuccess(response));
            }).catch((error) => {
                dispatch(authRoleGetFail("A system error has occured. Please contact your system administrator"));
            });
    };
};

export const authRoleSelectStart = () => {
    return {
        type: actionTypes.AUTH_ROLESELECT_START
    };
};

export const authRoleSelectSuccess = (authUserData, timer) => {
    return {
        type: actionTypes.AUTH_ROLESELECT_SUCCESS,
        authUserData: authUserData,
        userLogoutTimer: timer
    };
};

export const authRoleSelectFail = (authRoleGetError) => {
    return {
        type: actionTypes.AUTH_ROLESELECT_FAIL,
        authUserError: authRoleGetError
    };
};

export const authLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const authRoleSelect = (UserKey, RoleKey) => {
    return dispatch => {
        dispatch(authRoleSelectStart());
        const fetchConfig = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ UserKey: UserKey, RoleKey: RoleKey })
        };
        fetch(`${process.env.PUBLIC_URL}/api/sims/security/setRole`, fetchConfig)
            .then(CheckError)
            .then((responseJson) => {
                let response = typeof responseJson === "object" ? responseJson : JSON.parse(responseJson);
                let timer = null;
                if (response.Token) {
                    //const expirationDate = new Date(response.Token.ExpiresIn);
                    const expirationDate = new Date(new Date().getTime() + response.Token.ExpiresInMin * 60000);
                    localStorage.setItem('token', response.Token.AccessToken);
                    localStorage.setItem('expirationDate', expirationDate);
                    //let ExpiresIn = (expirationDate.getTime() - new Date().getTime());
                    //timer = setTimeout(() => {
                    //    dispatch(authLogout());
                    //}, ExpiresIn);
                }
                dispatch(authRoleSelectSuccess(response, timer));
            }).catch((error) => {
                dispatch(authRoleSelectFail("A system error has occured. Please contact your system administrator"));
            });
    };
};

export const authProviderSelectStart = () => {
    return {
        type: actionTypes.AUTH_PROVIDERSELECT_START
    };
};

export const authProviderSelectSuccess = (authUserData, timer) => {
    return {
        type: actionTypes.AUTH_PROVIDERSELECT_SUCCESS,
        authUserData: authUserData,
        userLogoutTimer: timer
    };
};

export const authProviderSelectFail = (authRoleGetError) => {
    return {
        type: actionTypes.AUTH_PROVIDERSELECT_FAIL,
        authUserError: authRoleGetError
    };
};

export const authProviderSelect = (UserKey, RoleKey, ProviderKey) => {
    return dispatch => {
        dispatch(authProviderSelectStart());
        const fetchConfig = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ UserKey: UserKey, RoleKey: RoleKey, ProviderKey: ProviderKey })
        };
        fetch(`${process.env.PUBLIC_URL}/api/sims/security/setProviderRole`, fetchConfig)
            .then(CheckError)
            .then((responseJson) => {
                let response = typeof responseJson === "object" ? responseJson : JSON.parse(responseJson);
                let timer = null;
                if (response.Token) {
                    //const expirationDate = new Date(response.Token.ExpiresIn);
                    const expirationDate = new Date(new Date().getTime() + response.Token.ExpiresInMin * 60000);
                    localStorage.setItem('token', response.Token.AccessToken);
                    localStorage.setItem('expirationDate', expirationDate);
                    //let ExpiresIn = (expirationDate.getTime() - new Date().getTime());
                    //timer = setTimeout(() => {
                    //    dispatch(authLogout());
                    //}, ExpiresIn);
                }
                dispatch(authProviderSelectSuccess(response, timer));
            }).catch((error) => {
                dispatch(authProviderSelectFail("A system error has occured. Please contact your system administrator"));
            });
    };
};

export const authSkillsDevelopmentProviderSelectStart = () => {
    return {
        type: actionTypes.AUTH_SKILLSDEVELOPMENTPROVIDERSELECT_START
    };
};

export const authSkillsDevelopmentProviderSelectSuccess = (authUserData, timer) => {
    return {
        type: actionTypes.AUTH_SKILLSDEVELOPMENTPROVIDERSELECT_SUCCESS,
        authUserData: authUserData,
        userLogoutTimer: timer
    };
};

export const authSkillsDevelopmentProviderSelectFail = (authRoleGetError) => {
    return {
        type: actionTypes.AUTH_SKILLSDEVELOPMENTPROVIDERSELECT_FAIL,
        authUserError: authRoleGetError
    };
};

export const authSkillsDevelopmentProviderSelect = (UserKey, RoleKey, SkillsDevelopmentProviderKey) => {
    return dispatch => {
        dispatch(authSkillsDevelopmentProviderSelectStart());
        const fetchConfig = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ UserKey: UserKey, RoleKey: RoleKey, SkillsDevelopmentProviderKey: SkillsDevelopmentProviderKey })
        };
        fetch(`${process.env.PUBLIC_URL}/api/sims/security/setSkillsDevelopmentProviderRole`, fetchConfig)
            .then(CheckError)
            .then((responseJson) => {
                let response = typeof responseJson === "object" ? responseJson : JSON.parse(responseJson);
                let timer = null;
                if (response.Token) {
                    //const expirationDate = new Date(response.Token.ExpiresIn);
                    const expirationDate = new Date(new Date().getTime() + response.Token.ExpiresInMin * 60000);
                    localStorage.setItem('token', response.Token.AccessToken);
                    localStorage.setItem('expirationDate', expirationDate);
                    //let ExpiresIn = (expirationDate.getTime() - new Date().getTime());
                    //timer = setTimeout(() => {
                    //    dispatch(authLogout());
                    //}, ExpiresIn);
                }
                dispatch(authSkillsDevelopmentProviderSelectSuccess(response, timer));
            }).catch((error) => {
                dispatch(authProviderSelectFail("A system error has occured. Please contact your system administrator"));
            });
    };
};

export const authAgentSelectStart = () => {
    return {
        type: actionTypes.AUTH_AGENTSELECT_START
    };
};

export const authAgentSelectSuccess = (authUserData, timer) => {
    return {
        type: actionTypes.AUTH_AGENTSELECT_SUCCESS,
        authUserData: authUserData,
        userLogoutTimer: timer
    };
};

export const authAgentSelectFail = (authRoleGetError) => {
    return {
        type: actionTypes.AUTH_AGENTSELECT_FAIL,
        authUserError: authRoleGetError
    };
};

export const authAgentSelect = (UserKey, RoleKey, AgentKey) => {
    return dispatch => {
        dispatch(authAgentSelectStart());
        const fetchConfig = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ UserKey: UserKey, RoleKey: RoleKey, AgentKey: AgentKey })
        };
        fetch(`${process.env.PUBLIC_URL}/api/sims/security/setAgentRole`, fetchConfig)
            .then(CheckError)
            .then((responseJson) => {
                let response = typeof responseJson === "object" ? responseJson : JSON.parse(responseJson);
                let timer = null;
                if (response.Token) {
                    const expirationDate = new Date(new Date().getTime() + response.Token.ExpiresInMin * 60000);
                    localStorage.setItem('token', response.Token.AccessToken);
                    localStorage.setItem('expirationDate', expirationDate);
                }
                dispatch(authAgentSelectSuccess(response, timer));
            }).catch((error) => {
                dispatch(authAgentSelectFail("A system error has occured. Please contact your system administrator"));
            });
    };
};

export const authEmployerSelectStart = () => {
    return {
        type: actionTypes.AUTH_EMPLOYERSELECT_START
    };
};

export const authEmployerSelectSuccess = (authUserData, timer) => {
    return {
        type: actionTypes.AUTH_EMPLOYERSELECT_SUCCESS,
        authUserData: authUserData,
        userLogoutTimer: timer
    };
};

export const authEmployerSelectFail = (authRoleGetError) => {
    return {
        type: actionTypes.AUTH_EMPLOYERSELECT_FAIL,
        authUserError: authRoleGetError
    };
};

export const authEmployerSelect = (UserKey, RoleKey, EmployerKey) => {
    return dispatch => {
        dispatch(authEmployerSelectStart());
        const fetchConfig = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ UserKey: UserKey, RoleKey: RoleKey, EmployerKey: EmployerKey })
        };
        fetch(`${process.env.PUBLIC_URL}/api/sims/security/setEmployerRole`, fetchConfig)
            .then(CheckError)
            .then((responseJson) => {
                let response = typeof responseJson === "object" ? responseJson : JSON.parse(responseJson);
                let timer = null;
                if (response.Token) {
                    //const expirationDate = new Date(response.Token.ExpiresIn);
                    const expirationDate = new Date(new Date().getTime() + response.Token.ExpiresInMin * 60000);
                    localStorage.setItem('token', response.Token.AccessToken);
                    localStorage.setItem('expirationDate', expirationDate);
                    //let ExpiresIn = (expirationDate.getTime() - new Date().getTime());
                    //timer = setTimeout(() => {
                    //    dispatch(authLogout());
                    //}, ExpiresIn);
                }
                dispatch(authEmployerSelectSuccess(response, timer));
            }).catch((error) => {
                dispatch(authEmployerSelectFail("A system error has occured. Please contact your system administrator"));
            });
    };
};

export const authWorkplaceApprovalSelectStart = () => {
    return {
        type: actionTypes.AUTH_WORKPLACEAPPROVALSELECT_START
    };
};

export const authWorkplaceApprovalSelectSuccess = (authUserData, timer) => {
    return {
        type: actionTypes.AUTH_WORKPLACEAPPROVALSELECT_SUCCESS,
        authUserData: authUserData,
        userLogoutTimer: timer
    };
};

export const authWorkplaceApprovalSelectFail = (authRoleGetError) => {
    return {
        type: actionTypes.AUTH_WORKPLACEAPPROVALSELECT_FAIL,
        authUserError: authRoleGetError
    };
};

export const authWorkplaceApprovalSelect = (UserKey, RoleKey, WorkplaceApprovalKey) => {
    return dispatch => {
        dispatch(authWorkplaceApprovalSelectStart());
        const fetchConfig = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ UserKey: UserKey, RoleKey: RoleKey, WorkplaceApprovalKey: WorkplaceApprovalKey })
        };
        fetch(`${process.env.PUBLIC_URL}/api/sims/security/setWorkplaceApprovalRole`, fetchConfig)
            .then(CheckError)
            .then((responseJson) => {
                let response = typeof responseJson === "object" ? responseJson : JSON.parse(responseJson);
                let timer = null;
                if (response.Token) {
                    //const expirationDate = new Date(response.Token.ExpiresIn);
                    const expirationDate = new Date(new Date().getTime() + response.Token.ExpiresInMin * 60000);
                    localStorage.setItem('token', response.Token.AccessToken);
                    localStorage.setItem('expirationDate', expirationDate);
                    //let ExpiresIn = (expirationDate.getTime() - new Date().getTime());
                    //timer = setTimeout(() => {
                    //    dispatch(authLogout());
                    //}, ExpiresIn);
                }
                dispatch(authWorkplaceApprovalSelectSuccess(response, timer));
            }).catch((error) => {
                dispatch(authWorkplaceApprovalSelectFail("A system error has occured. Please contact your system administrator"));
            });
    };
};

export const authAssessmentCentreSelectStart = () => {
    return {
        type: actionTypes.AUTH_ASSESSMENTCENTRESELECT_START
    };
};

export const authAssessmentCentreSelectSuccess = (authUserData, timer) => {
    return {
        type: actionTypes.AUTH_ASSESSMENTCENTRESELECT_SUCCESS,
        authUserData: authUserData,
        userLogoutTimer: timer
    };
};

export const authAssessmentCentreSelectFail = (authRoleGetError) => {
    return {
        type: actionTypes.AUTH_ASSESSMENTCENTRESELECT_FAIL,
        authUserError: authRoleGetError
    };
};

export const authAssessmentCentreSelect = (UserKey, RoleKey, AssessmentCentreKey) => {
    return dispatch => {
        dispatch(authAssessmentCentreSelectStart());
        const fetchConfig = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ UserKey: UserKey, RoleKey: RoleKey, AssessmentCentreKey: AssessmentCentreKey })
        };
        fetch(`${process.env.PUBLIC_URL}/api/sims/security/setAssessmentCentreRole`, fetchConfig)
            .then(CheckError)
            .then((responseJson) => {
                let response = typeof responseJson === "object" ? responseJson : JSON.parse(responseJson);
                let timer = null;
                if (response.Token) {
                    //const expirationDate = new Date(response.Token.ExpiresIn);
                    const expirationDate = new Date(new Date().getTime() + response.Token.ExpiresInMin * 60000);
                    localStorage.setItem('token', response.Token.AccessToken);
                    localStorage.setItem('expirationDate', expirationDate);
                    //let ExpiresIn = (expirationDate.getTime() - new Date().getTime());
                    //timer = setTimeout(() => {
                    //    dispatch(authLogout());
                    //}, ExpiresIn);
                }
                dispatch(authAssessmentCentreSelectSuccess(response, timer));
            }).catch((error) => {
                dispatch(authAssessmentCentreSelectFail("A system error has occured. Please contact your system administrator"));
            });
    };
};

export const authSwitchProfileStart = () => {
    return {
        type: actionTypes.AUTH_SWITCHPROFILE_START
    };
};

export const authSwitchProfileSuccess = (authUserData, timer, loginPath) => {
    return {
        type: actionTypes.AUTH_SWITCHPROFILE_SUCCESS,
        authUserData: authUserData,
        authUserLoginTimer: timer,
        loginRedirectToLoginPath: loginPath
    };
};

export const authSwitchProfileFail = (authUserError) => {
    return {
        type: actionTypes.AUTH_SWITCHPROFILE_FAIL,
        authUserError: authUserError
    };
};

export const authSwitchProfile = (loginPath) => {
    return dispatch => {
        dispatch(authSwitchProfileStart());
        const url = 'api/sims/security/SwitchProfile';
        let fetchConfig = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        };

        const ExpirationDate = new Date(localStorage.getItem("expirationDate"));
        const currentDate = new Date();
        var secondBetweenTwoDate = ((ExpirationDate - currentDate.getTime()) / 1000);

        if (secondBetweenTwoDate < 10) {
            const refreshFetchConfig = {
                method: 'POST'
            }
            fetch(`${process.env.PUBLIC_URL}/api/sims/security/SetRefreshToken`, refreshFetchConfig)
                .then(CheckError)
                .then((responseJson) => {
                    let response = typeof responseJson === "object" ? responseJson : JSON.parse(responseJson);
                    const expirationDate = new Date(new Date().getTime() + response.ExpiresInMin * 60000);
                    localStorage.setItem('token', response.AccessToken);
                    localStorage.setItem('expirationDate', expirationDate);
                    fetchConfig.headers.Authorization = 'Bearer ' + localStorage.getItem("token");
                    fetch(`${process.env.PUBLIC_URL}/` + url, fetchConfig)
                        .then(CheckError)//.then(res => res.json())
                        .then((responseJson) => {
                            let response = typeof responseJson === "object" ? responseJson : JSON.parse(responseJson);
                            let timer;
                            if (response.Authenticated || response.OTPSent) {
                                timer = setTimeout(() => {
                                    dispatch(authUserRedirectToLogin());
                                }, 300000);
                            }
                            dispatch(authSwitchProfileSuccess(response, timer, loginPath));
                        }).catch((e) => {
                            dispatch(authSwitchProfileFail("A system error has occured. Please contact your system administrator"));
                        });
                }).catch((e) => {
                    console.log('Error');
                    console.log(e);
                    const store = window.globalAppRXStore;
                    const auth = store.getState().auth;
                    //Logout Logic
                    clearTimeout(auth.userLogoutTimer);
                    if (auth.loginRedirectToLoginPath !== '/ADlogin') {
                        store.dispatch(authLogout());
                    }
                    else {
                        const adStore = window.globalAppADStore;
                        const isAuthenticated = adStore.getAllAccounts().length > 0;
                        if (isAuthenticated) {
                            adStore.logoutPopup().then((loginResponse) => {
                                store.dispatch(authLogout());
                            }).catch((error) => {
                                console.log(error);
                            });
                        } else {
                            store.dispatch(authLogout());
                        }
                    }
                });
        }
        else {
            fetch(`${process.env.PUBLIC_URL}/` + url, fetchConfig)
                .then(CheckError)//.then(res => res.json())
                .then((responseJson) => {
                    let response = typeof responseJson === "object" ? responseJson : JSON.parse(responseJson);
                    let timer;
                    if (response.Authenticated || response.OTPSent) {
                        timer = setTimeout(() => {
                            dispatch(authUserRedirectToLogin());
                        }, 300000);
                    }
                    dispatch(authSwitchProfileSuccess(response, timer, loginPath));
                }).catch((e) => {
                    dispatch(authSwitchProfileFail("A system error has occured. Please contact your system administrator"));
                });
        }
    };
};

export const authForgotPasswordStart = () => {
    return {
        type: actionTypes.AUTH_FORGOTPASSWORD_START
    };
};

export const authForgotPasswordSuccess = (authUserData) => {
    return {
        type: actionTypes.AUTH_FORGOTPASSWORD_SUCCESS,
        authUserData: authUserData
    };
};

export const authForgotPasswordFail = (authRoleGetError) => {
    return {
        type: actionTypes.AUTH_FORGOTPASSWORD_FAIL,
        authUserError: authRoleGetError
    };
};

export const authForgotPassword = (Username) => {
    return dispatch => {
        dispatch(authForgotPasswordStart());
        const fetchConfig = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ Username: Username })
        };
        fetch(`${process.env.PUBLIC_URL}/api/sims/security/forgotpassword`, fetchConfig)
            .then(CheckError)
            .then((responseJson) => {
                let response = typeof responseJson === "object" ? responseJson : JSON.parse(responseJson);
                dispatch(authForgotPasswordSuccess(response));
            }).catch((error) => {
                dispatch(authForgotPasswordFail("A system error has occured. Please contact your system administrator"));
            });
    };
};

export const authResetPasswordStart = () => {
    return {
        type: actionTypes.AUTH_RESETPASSWORD_START
    };
};

export const authResetPasswordSuccess = (authUserData) => {
    return {
        type: actionTypes.AUTH_RESETPASSWORD_SUCCESS,
        authUserData: authUserData
    };
};

export const authResetPasswordFail = (authRoleGetError) => {
    return {
        type: actionTypes.AUTH_RESETPASSWORD_FAIL,
        authUserError: authRoleGetError
    };
};

export const authResetPassword = (Username, Password, NewPassword, ConfirmPassword) => {
    return dispatch => {
        dispatch(authResetPasswordStart());
        const fetchConfig = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ Username: Username, Password: Password, NewPassword: NewPassword, ConfirmPassword: ConfirmPassword })
        };
        fetch(`${process.env.PUBLIC_URL}/api/sims/security/reset`, fetchConfig)
            .then(CheckError)
            .then((responseJson) => {
                let response = typeof responseJson === "object" ? responseJson : JSON.parse(responseJson);
                dispatch(authResetPasswordSuccess(response));
            }).catch((error) => {
                dispatch(authResetPasswordFail("A system error has occured. Please contact your system administrator"));
            });
    };
};

export const authMenuPermissionStart = () => {
    return {
        type: actionTypes.AUTH_MENUPERMISSION_START
    };
};

export const authMenuPermissionSuccess = (authMenuPermissionData) => {
    return {
        type: actionTypes.AUTH_MENUPERMISSION_SUCCESS,
        authMenuPermissionData: authMenuPermissionData
    };
};

export const authMenuPermissionFail = (authMenuPermissionError) => {
    return {
        type: actionTypes.AUTH_MENUPERMISSION_FAIL,
        authMenuPermissionError: authMenuPermissionError
    };
};

export const authMenuPermission = () => {
    return dispatch => {
        dispatch(authMenuPermissionStart());
        const fetchConfig = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        };
        fetch(`${process.env.PUBLIC_URL}/api/sims/security/getmenus`, fetchConfig)
            .then(CheckError)
            .then((responseJson) => {
                let response = typeof responseJson === "object" ? responseJson : JSON.parse(responseJson);
                dispatch(authMenuPermissionSuccess(response));
            }).catch((error) => {
                dispatch(authMenuPermissionFail("A system error has occured. Please contact your system administrator"));
            });
    };
};

export const interfaceVersionCheck = (interfaceVersionUpdateConfirm) => {
    return {
        type: actionTypes.INTERFACE_VERSION_CHECK,
        interfaceVersionUpdateConfirm: interfaceVersionUpdateConfirm
    };
};

export const interfaceVersionShouldCheck = (interfaceVersionShouldBeChecked) => {
    return {
        type: actionTypes.INTERFACE_VERSION_CHECK_UPDATE,
        interfaceVersionShouldBeChecked: interfaceVersionShouldBeChecked
    };
};
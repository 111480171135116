import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import LinkDialog from "../LinkDialog";
import Prompt from '../../widgets/Prompt';
import TradeQualificationSetupSearch from '../program/TradeQualification/TradeQualificationSetupSearch';
import AlertItem from "../../widgets/AlertItem";
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';

export const qualificationHeadCells = [
    { id: 'Code', numeric: false, disablePadding: false, label: 'Code' },
    { id: 'Title', numeric: false, disablePadding: false, label: 'Title' },
    { id: 'RegistrationStartDate', numeric: false, disablePadding: false, label: 'Registration Start Date' },
    { id: 'RegistrationEndDate', numeric: false, disablePadding: false, label: 'Registration End Date' }
]
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const useStyles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        color: 'white',
    },
    table: {
        minWidth: 700,
    },
});

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();
class AssessorTradeQualificationSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                Code: "",
                Title: "",
                AssessorID: this.props.location.state.AssessorID
            },
            showTable: true,
            linkDialogOpen: false,
            formDialogOpen: false,
            selectedQualification: null,
            propmtOpen: false,
            promptTitle: "Unlink Trade Qualification From Assessor",
            promptContent: "Are you sure you want to unlink this trade qualification from the assessor?",
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "AssessorID", Value: this.props.location.state.AssessorID }],
            searchParameters2: [{ Name: "Id", Value: 0 }],
            OpenDialog: false,
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'link', label: '', tooltip: 'Link Qualification/s to the assessor', icon: 'LinkIcon' },
                        { id: 'unlink', label: '', tooltip: 'Un-link selected qualification from the assessor', disabled: utils.isNull(this.state.selectedQualification), icon: 'LinkOffIcon' },
                    ]
                }
            ]
        };

    }

    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/assessor',
                state: { selection: this.props.location.state.AssessorID }
            });
        }
        else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, message: "" })
        }
        else if ("unlink" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    successCallback = (response) => {
        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedQualification: null,
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
        else if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    unlinkQualification(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("unlink", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedQualification}&assessorId=${this.props.location.state.AssessorID}`
        utils.invokeUrlDelete(fullUrl, this.successCallback, null);
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                linkDialogOpen: false, selectedQualification: null,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters))
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            let newsearchParameters2 = [...this.state.searchParameters2];
            let temp_element = { ...newsearchParameters2[0] };
            temp_element.Value = selection[0].Id
            newsearchParameters2[0] = temp_element;

            this.setState({
                searchParameters2: JSON.parse(JSON.stringify(newsearchParameters2)),
                selectedQualification: selection[0].Id,
                message: "",
                messageStyle: ""
            });

        }
        else {
            this.setState({
                searchParameters2: [{ Name: "Id", Value: 0 }],
                selectedQualification: null,
                message: "",
                messageStyle: ""
            });
        }
        toolbarRef.current.setButtonDisabled("unlink", selection === null || selection.length === 0);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                updateDialogOpen: false, selectedSiteVisit: null, auditDialogOpen: false, linkDialogOpen: false, selection: this.props.location.state.selection, selectedQualification: null
            }, () => {
                this.setState({ showTable: true })
            });
        }
        else if (isCancel === true) {
            this.setState({
                updateDialogOpen: false,
                linkDialogOpen: false
            })
        }
    }

    CloseForm = () => {
        this.setState({
            OpenDialog: false,
            selectedId: null,
            clearSelectedRow: true,
            selectedQualification: null
        });
        toolbarRef.current.setButtonDisabled("unlink", true);
    };

    render() {
        const { classes } = this.props;
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {"Assessor > Trade Qualifications" + (this.props.location.state !== null ? " > " + this.props.location.state.AssessorName + " - " + this.props.location.state.AssessorIDNo : "> Add")}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        ref={toolbarRef}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />
                    {this.state.showTable === true && (
                        <EnhancedTable
                            headCells={qualificationHeadCells}
                            dataUrl={`api/sims/assessortradequalification/GetAssessorTradeQualifications`}
                            height="50vh"
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}
                </div>

                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title={this.props.location.state.Title}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={`Link Trade Qualification to Assessor: ${this.props.location.state.AssessorName}`}
                    linkName={"TradeQualifications"} postUrl={"api/sims/assessortradequalification/LinkAssessorTradeQualification"} usePost={true}
                    selectionId={this.props.location.state.AssessorID}
                >
                    <TradeQualificationSetupSearch
                        dataUrl={"api/sims/assessortradequalification/GetSearchLinkableTradeQualifications"}
                        multiSelect={true} showTitle={false} customMessage={"No Trade Qualifications with this search criteria have been found."}
                        programmeId={this.props.location.state.AssessorID} programmeType="Q" allowClear={true}
                        mode="lookup"
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => this.unlinkQualification(`api/sims/assessortradequalification/RemoveAssessorTradeQualification`)}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}


export default withRouter(withStyles(useStyles())(connect()(AssessorTradeQualificationSearch)));
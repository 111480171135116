import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../../widgets/Toolbar';
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import "./LevyImport.css";
import { LevyImportForm } from './LevyImportForm';
import ViewUtils from '../../../ViewUtils';
import AlertItem from "../../../widgets/AlertItem";
import { ViewContainer } from "./../../../View.jsx";
import withMenuPermission from "../../../widgets/withMenuPermission";
import EnhancedTable from '../../../widgets/ResponsiveTable';


const utils = new ViewUtils();
const formRef = React.createRef();
const toolbarRef = React.createRef();
const headCells = [
    { id: 'ImportDate', numeric: false, disablePadding: false, label: 'Import Date' },
    { id: 'ImportFileName', numeric: false, disablePadding: false, label: 'Import FileName' },
    { id: 'ImportProgress', numeric: false, disablePadding: false, label: 'Import Progress' },
    { id: 'ImportStatusCode', numeric: false, disablePadding: false, label: 'Import Status Code' },
    { id: 'ImportStatus', numeric: false, disablePadding: false, label: 'Import Status' }
];


class LevyImport extends Component {

    state = {
        message: "",
        messageStyle: "",
        title: "",
        id: null,
        showTable: true,
        searchParameters: [{ Name: "ID", Value: 0 }],
        model: {FinancialYearID: null}
    };

    toolbarConfig = {
        items: [
            {
                type: "buttonGroup",
                buttons: [
                    { id: 'save', label: 'SAVE', tooltip: 'Import Levy File', icon: 'ImportExportIcon', visible: utils.isNull(this.state.id) ? this.props.menuPermissionItem.Edit : this.props.menuPermissionItem.Add },
                    { id: 'refresh', label: 'REFRESH', tooltip: 'Check Import Progress', icon: 'RefreshIcon', visible: utils.isNull(this.state.id) ? this.props.menuPermissionItem.Edit : this.props.menuPermissionItem.Add }
                ]
            }
        ]
    };

    GetDefaultFinYear()
    {
        let url = "api/sims/LevyImport/GetDefaultFinYearFind?Id=0";
        utils.invokeUrl(url, (response) => {

            this.setState({
                model: {
                    FinancialYearID: {Id : response.FinancialYearID.Id , Description : response.FinancialYearID.Description}
                }
            });
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    save = () => {
        let formValue = formRef.current.getValue();

        if (formValue !== null && typeof formValue !== 'undefined') {
                const url = `api/sims/LevyImport/LevyImport`;
                utils.saveForm(this, formRef, url, (response) => {
                    this.setState({ id: response.EntityId });
                    utils.showMessage(this, response.Message, response.MessageType);
                    response.MessageType === "ERROR" ? this.errorCallback(response) : this.successCallback(response);
                }, this.errorCallback);
            }
        }
    componentDidMount() {
            this.getFormHead("1");
            this.GetDefaultFinYear();
        }

    getFormHead = (pageLoad) => {
        let url = "api/sims/LevyImport/CheckImportStatus";
        utils.invokeUrl(url, (response) => {
            if (response.Result === "In Progress") {
                toolbarRef.current.setButtonDisabled("save", true);
            }
            else if (response.Result === "Completed") {
                toolbarRef.current.setButtonDisabled("save", false);
            }
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
        if(pageLoad === "0")
        {
        this.setState({
            showTable: false
        }, () => {
            this.setState({ showTable: true })
        });
    }
        
    }

    successCallback(response) {
        this.setState({
            message: response.Message, messageStyle: response.MessageType
        });
        this.getFormHead("0");

    }
    errorCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        }
        else if ("refresh" === buttonId) {
            this.CheckProgress();
        }  
    }

    CheckProgress = () => {
        this.setState({message: "", messageStyle: ""})
        this.getFormHead("0");
    }

    handleSelectionChange = (selection) => {
    }

    render() {
        return (

                <ViewContainer  title="">
                <p className="breadcrumbs">
                    {"Import Levy"}
                </p>
                    <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                
                <td><EnhancedToolbar 
                ref={toolbarRef} highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} 
                enabled={true} config={this.toolbarConfig} />
                </td>
                
                <LevyImportForm
                     ref={formRef} id={this.state.id}
                     valueChangeHandler={(values) => this.handleFormValueChange(values)} data={this.state.model} />

                 <div className="searchResults"> 
                 {this.state.showTable === true && ( 
                        <EnhancedTable
                        headCells={headCells}
                        dataUrl={`api/sims/LevyImport/GetSearch`}
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        searchParameters={this.state.searchParameters} paged={false} />
                 )}
                    </div>  
                </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(LevyImport));
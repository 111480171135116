import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./CoachMentor.css";
import { Alert } from "react-bootstrap";
import ViewUtils from "./../../ViewUtils";
import { CoachMentorForm } from './CoachMentorForm';
import { ViewContainer } from "../../View";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";


const utils = new ViewUtils();


const coachmentorRef = React.createRef();

class CoachMentor extends Component {

    state = {
        CoachMentorDetails: {
            Person: {
                FirstName: "",
                Surname: ""
            }
        },
        toolbarConfig: {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' },
                
            ]
        },
        {
            type: "devider"
        },
        {
            type: "buttonGroup",
            buttons: [
                { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.menuPermissionItem.Edit },
                {
                    contextMenu: [
                        { id: "registrationDetails", description: "Registration Details", disabled: !this.props.onMenuSpecificPermissionItem('/coachmentorRegistrationDetails').View },
                        { id: "registrationHistory", description: "Registration History", disabled: !this.props.onMenuSpecificPermissionItem('/coachmentorRegistrationHistory').View }
                    ],
                    id: "registration", label: "Registration"
                },

                {
                    contextMenu: [
                        { id: "qualification", description: "QCTO Qualification", disabled: !this.props.onMenuSpecificPermissionItem('/coachmentorQualification').View },
                        { id: "skillsProgramme", description: "QCTO Skills Programme", disabled: !this.props.onMenuSpecificPermissionItem('/coachmentorSkillsProgramme').View },
                        { id: "leanership", description: "QCTO Learnership", disabled: !this.props.onMenuSpecificPermissionItem('/coachmentorLearnership').View }
                    ],
                    id: 'programs', label: 'Programmes'
                },

                { id: 'workplaceapproval', label: 'Workplace Approval', tooltip: 'Workplace Approval', visible: this.props.onMenuSpecificPermissionItem('/coachmentorWorkplaceApproval').View },
                //{ id: 'employer', label: 'Employer', tooltip: '', visible: this.props.onMenuSpecificPermissionItem('/coachmentorEmployerSearch').View },
                { id: 'crm', label: 'CRM', tooltip: 'Client Relationship Management', visible: this.props.onMenuSpecificPermissionItem('/coachmentorCRM').View }

            ]
        }
    ]
}
    };

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ CoachMentorDetails: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        }
        else if("close" === buttonId) {
            this.close();
        }
        else if ("crm" === buttonId)
        {
            this.props.history.push({
                pathname: '/coachmentorCRM',
                state: {
                    CoachMentorID: this.props.location.state.selection,
                    CoachMentorName: `${this.state.CoachMentorDetails.FirstName} ${this.state.CoachMentorDetails.Surname}`
                }
            })
        }
        else if ("workplaceapproval" === buttonId) {
            this.props.history.push({
                pathname: '/coachmentorWorkplaceApproval',
                state: {
                    CoachMentorID: this.props.location.state.selection,
                    CoachMentorName: `${this.state.CoachMentorDetails.FirstName} ${this.state.CoachMentorDetails.Surname}`
                }
            })
        //} else if ("employer" === buttonId) {
        //    this.props.history.push({
        //        pathname: '/coachmentorEmployerSearch',
        //        state: {
        //            CoachMentorID: this.props.location.state.selection,
        //            CoachMentorName: `${this.state.CoachMentorDetails.FirstName} ${this.state.CoachMentorDetails.Surname}`,
        //            CoachMentorIDNo: `${this.state.CoachMentorDetails.IDNo}`
        //        }
        //    })
        }
    }

    handleToolbarButtonMenuClick(menu)
    {
        if ("registrationDetails" === menu.id) {
            this.props.history.push({
                pathname: '/coachmentorRegistrationDetails',
                state: {
                    selection: this.props.location.state.selection,
                    CoachMentorName: `${this.state.CoachMentorDetails.FirstName} ${this.state.CoachMentorDetails.Surname}`
                }
            })
        } else if ("registrationHistory" === menu.id) {
            this.props.history.push({
                pathname: '/coachmentorRegistrationHistory',
                state: {
                    CoachMentorID: this.props.location.state.selection,
                    CoachMentorName: this.state.CoachMentorDetails.FirstName + " " + this.state.CoachMentorDetails.Surname
                }
            })
        }   else if ("skillsProgramme" === menu.id) {
            this.props.history.push({
                pathname: '/coachmentorSkillsProgramme',
                state: {
                    CoachMentorID: this.props.location.state.selection,
                    CoachMentorName: this.state.CoachMentorDetails.FirstName + " " + this.state.CoachMentorDetails.Surname
                }
            })
        } else if ("leanership" === menu.id) {
            this.props.history.push({
                pathname: '/coachmentorlearnership',
                state: {
                    CoachMentorID: this.props.location.state.selection,
                    CoachMentorName: this.state.CoachMentorDetails.FirstName + " " + this.state.CoachMentorDetails.Surname
                }
            })
        } else if ("qualification" === menu.id) {
            this.props.history.push({
                pathname: '/coachmentorQualification',
                state: {
                    CoachMentorID: this.props.location.state.selection,
                    CoachMentorName: this.state.CoachMentorDetails.FirstName + " " + this.state.CoachMentorDetails.Surname,
                    CoachMentorIDNo: `${this.state.CoachMentorDetails.IDNo}`
                }
            })
        } else if ("tradequalification" === menu.id) {
            this.props.history.push({
                pathname: '/coachmentorTradeQualification',
                state: {
                    CoachMentorID: this.props.location.state.selection,
                    CoachMentorName: this.state.CoachMentorDetails.FirstName + " " + this.state.CoachMentorDetails.Surname,
                    CoachMentorIDNo: `${this.state.CoachMentorDetails.IDNo}`
                }
            })
        }
    }

    successCallback = () => {

        this.setState({ message: "Coach/Mentor updated successfully", messageStyle: "SUCCESS" });
    }

    save() {
        let formValue = coachmentorRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            let input = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/coachmentordetails/UpdateCoachMentor", this.successCallback, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: input
            });
        }
    }

    close() {
        this.props.history.push('/coachmentorSearch');
    }

    render() {

        
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"Coach/Mentor " + (this.state.CoachMentorDetails.FirstName !== null ? "> " + this.state.CoachMentorDetails.FirstName + (this.state.CoachMentorDetails.Surname !== null ? "  " + this.state.CoachMentorDetails.Surname : "") : "> Add")}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="CoachMentor" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={true} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "60vh", maxHeight: "60vh" }}>
                                <CoachMentorForm ref={coachmentorRef} id={this.props.location.state.selection} valueChangeHandler={(values) => this.handleFormValueChange(values)} editPermission={this.props.menuPermissionItem.Edit }/>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(CoachMentor));


import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../../widgets/Toolbar';
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import ViewUtils from "./../../../ViewUtils";
import AlertItem from "../../../widgets/AlertItem";
import { BursarySetupForm } from './BursarySetupForm';
import { ViewContainer } from "./../../../View.jsx";
import withMenuPermission from "../../../widgets/withMenuPermission";

const BursaryRef = React.createRef();
const utils = new ViewUtils();

const toolbarRef = React.createRef();
class BursarySetup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            BursarySetupDetails: {
                BursaryProgrammeID: "",
                BursaryProgrammeDescription: ""
            },
            title: "",
            model: {
                BursaryCode: ""
            },

            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.menuPermissionItem.Edit }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'documents', label: 'DOCUMENTS', tooltip: 'Bursary Documents', disabled: utils.isNull(this.state.id) }
                    ]
                },
            ]
        };
    }

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ BursarySetupDetails: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("documents" === buttonId) {
            this.props.history.push({
                pathname: '/Bursarydocuments',
                state: {
                    BursaryID: this.props.location.state.selection
                }
            });
        }
    }

    save() {

        let formValue = BursaryRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            let isAdd = true;
            if (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) {
                formValue.id = this.props.location.state.selection;
                isAdd = false;
            }
            formValue.Document = this.state.Document;
            const data = JSON.stringify(formValue);
            let url = "api/sims/Bursarysetupdetails/updateBursary";
            if (!isAdd) {
                utils.invokeUrl(url, (response) => {
                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response)
                },
                    null,
                    {
                        method: 'PUT',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        },
                        body: data
                    }
                )
            } else {
                url = "api/sims/Bursarysetupdetails/insertBursary";
                utils.invokeUrl(url, (response) => {
                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response)
                },
                    null,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        },
                        body: data
                    }
                )
            }
        }
    }

    closeView() {
        this.props.history.push('/BursarySetupSearch');
    }

    successCallback = (response) => {
        if (!utils.isNull(response.EntityId) && response.EntityId !== 0) {
            this.setState({ id: response.EntityId }, () => {
                this.props.history.push({
                    pathname: "/Bursarysetup",
                    state: { selection: this.state.id }
                })
            })
        }
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"Bursary Details"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    ref={toolbarRef}
                                    title="BursarySetup"
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    <BursarySetupForm ref={BursaryRef} data={this.state.model }
                                        id={this.state.id !== null ? this.state.id : typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                        editPermission={this.props.menuPermissionItem.Edit} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(BursarySetup));

import React from 'react';
import { SelectItem } from '../../../widgets/SelectItem';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from '../../../ViewUtils';
import DocumentUpload from "../../../widgets/DocumentUpload";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();


export const LevyImportForm = React.forwardRef((props, ref) => {
    const [dependency, setDependency] = React.useState(props.dependency);
    const [Levyfilename, setLevyFilename] = React.useState("");

    React.useEffect(() => {
        setDependency(props.dependency);
    }, [props.dependency]);

    function DocRequiredCheck (value){
        if (value !== undefined && value !== null && value !== "") {
            return false
        }
        else if (value === undefined || value === null || value === "") {
            return true
        }
    };

    const onUploadHandler = (data) => {
        if (data) {
            setLevyFilename(data.name);
        }
    };

    return (
        <EntityForm ref={ref}
            viewId="LevyImport"
            values={props.data}
            entityLookupId={props.id}
            entityLookupController={props.entityLookupController} org="saseta"
            useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="LevyImportForm">
                        <table cellPadding="2" width="80%">
                            <tbody>
                                <tr>
                                    <td>
                                        <SelectItem
                                            dataUrl="api/sims/LevyImport/GetLookupList?listId=FinancialYear"
                                            id="FinancialYearID"
                                            label="Financial Year"
                                            className="w-100"
                                            required={true}
                                            form={form} />
                                    </td> 
                                    <td>
                                        <DocumentUpload
                                            showViewer={false}
                                            onUploadHandler={onUploadHandler}
                                            buttonLabel="Upload Levy File"
                                            filename={Levyfilename}
                                            form={form}
                                            required={DocRequiredCheck(form.values["LevyImportDocumentName"])}
                                            id="Document"/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                </form>
            }
        </EntityForm>
    );
})

export default LevyImportForm;

import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./CoachMentor.css";
import ViewUtils from "./../../ViewUtils";
import { ViewContainer } from "../../View";
import WorkplaceApprovalSearch from "../workplaceapproval/WorkplaceApprovalSearch";
import { headCells } from "../workplaceapproval/WorkplaceApprovalSearch";
import { format } from "date-fns";
import EnhancedTable from '../../widgets/ResponsiveTable';
import LinkDialog from "../LinkDialog";

const linkToolbarRef = React.createRef();
const toolbarRef = React.createRef();
const utils = new ViewUtils();

class AddCoachMentorWorkplaceApproval extends Component {
    constructor(props) {
        super(props);

        let nextButtonDisabled = true;
        if (!utils.isNull(this.props.data) && !utils.isNull(this.props.data.workplaceApprovalDetails)) {
            nextButtonDisabled = this.props.data.workplaceApprovalDetails.workplaceApprovals.length === 0;
        }

        let workplaceApprovalIds = "";
        if (!utils.isNull(props.data) && !utils.isNull(props.data.workplaceApprovalDetails)) {
            workplaceApprovalIds = this.getIdList(props.data.workplaceApprovalDetails.workplaceApprovals);
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'back', label: 'BACK', tooltip: 'Back to Search', theme: true },
                        { id: 'next', label: 'NEXT', tooltip: 'Proceed to Workplace Approval', theme: true }
                    ]
                }
            ]
        };

        this.linkToolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'link', label: 'Link', tooltip: 'link the selected workplace approval to the Coach/Mentor' },
                        { id: 'unlink', label: 'Unlink', tooltip: 'Un-link the selected workplace approval from the Coach/Mentor', disabled: true }
                    ]
                }
            ]
        };
        this.state = {
            model: !utils.isNull(props.data) && !utils.isNull(props.data.workplaceApprovalDetails) ? props.data.workplaceApprovalDetails : { workplaceApprovals: [] },
            searchParameters: null,
            message: "",
            workplaceApprovalIds: workplaceApprovalIds,
            linkDialogOpen: false,
            propmtOpen: false,
            ModeratorName: this.props.data.personRoleDetails.FirstName + " " + this.props.data.personRoleDetails.Surname
        };

    }

    postLinkHandler(isCancel, linked) {
        this.setState({ linkDialogOpen: !isCancel && !linked })
    }

    getIdList(programmes) {
        let exclusions = "";

        for (let i = 0; i < programmes.length; i++) {
            exclusions += programmes[i].Id;
            if (i < programmes.length - 1) {
                exclusions += ","
            }
        }

        return exclusions;
    }

    saveWorkplaceApproval(selection) {

        for (let i = 0; i < selection.length; i++) {
            selection[i].ModeratorAssessmentEndDate = format(this.props.data.registrationDetails.RegistrationEndDate, "yyyy/MM/dd HH:mm:ss");
            this.state.model.workplaceApprovals.push(selection[i]);
        }

        let workplaceApprovalIdList = "";
        workplaceApprovalIdList = this.getIdList(this.state.model.workplaceApprovals);

        this.setState({
            workplaceApprovalIds: workplaceApprovalIdList
        }
        )

        //toolbarRef.current.setButtonDisabled("next", this.state.model.workplaceApprovals.length === 0);
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedWorkplaceApprovalId: selection[0].Id });
        }
        else {
            this.setState({ selectedWorkplaceApprovalId: null });
        }
        //linkToolbarRef.current.setButtonDisabled("unlink", selection === null || selection.length === 0);
    }

    handleToolbarButtonClick(buttonId) {
        if ("link" === buttonId) {
            console.clear();
            console.log(this.props.data);
            this.setState({ linkDialogOpen: true });
        }
        else if ("unlink" === buttonId) {
            if (!utils.isNull(this.state.selectedWorkplaceApprovalId)) {
                let workplaceApprovals = this.state.model.workplaceApprovals;
                let newWorkplaceApprovals = [];

                for (let i = 0; i < workplaceApprovals.length; i++) {
                    if (workplaceApprovals[i].Id !== this.state.selectedWorkplaceApprovalId) {
                        newWorkplaceApprovals.push(workplaceApprovals[i]);
                    }
                }

                let newIds = this.getIdList(newWorkplaceApprovals);
                this.setState({ workplaceApprovalIds: newIds });

                //this.setState({ model: { ...this.state.model, workplaceApprovals: newWorkplaceApprovals } }, () => {
                //    toolbarRef.current.setButtonDisabled("next", newIds.length === 0);
                //});
            }
        } else if ("back" === buttonId) {
            this.props.stepHandler(3, false, this.state.model);
        } else if ("next" === buttonId) {
            this.props.stepHandler(3, true, this.state.model);
        }
    }

    render() {
        return (
            <ViewContainer topPadding="0">
                <div style={{ margin: "8px 0 32px 8px", height: "37vh" }}>
                    <p className="legend">
                        Workplace Approvals
                    </p>
                    <div style={{ margin: "8px 0 16px 0px", width: "99%", paddingLeft: "8px", paddingRight: "8px" }}>
                        <EnhancedToolbar
                            highlight={false}
                            multiSelect={false}
                            buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                            ref={linkToolbarRef} config={this.linkToolbarConfig} />
                    </div>
                    <div style={{ height: "90%", overflow: "auto", width: "99%", paddingLeft: "8px", paddingRight: "8px" }}>
                        <EnhancedTable
                            headCells={headCells} autoFetchData={false} multiSelect={false}
                            rows={this.state.model.workplaceApprovals}
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={false} />
                    </div>
                    <LinkDialog open={this.state.linkDialogOpen} linkHandler={(selection) => { this.saveWorkplaceApproval(selection) }}
                        closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                        dialogTitle={`Link Workplace Approval to Coach/Mentor: ${this.state.ModeratorName}`}
                    >
                        <WorkplaceApprovalSearch dataUrl="api/sims/CoachMentorDetails/getCoachMentorWorkplaceApproval"
                            multiSelect={true} showTitle={false} selectionCallbackMode="full"
                            programmeType="Q" exclusions={this.state.workplaceApprovalIds}
                            mode="lookup"
                        />
                    </LinkDialog>
                </div>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="90%">
                            </td>
                            <td width="20%" align="right">
                                <div style={{ width: "120px", marginTop: "16px" }}>
                                    <EnhancedToolbar ref={toolbarRef} highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} config={this.toolbarConfig} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(AddCoachMentorWorkplaceApproval);

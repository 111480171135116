import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./User.css";
import SearchView from "../../widgets/SearchView";
import UserSearchForm from './UserSearchForm';
import { ViewContainer } from "./../../View.jsx";

const headCells = [
    { id: 'FirstName', numeric: false, disablePadding: true, label: 'First Name' },
    { id: 'Surname', numeric: false, disablePadding: true, label: 'Surname' },
    { id: 'UserName', numeric: false, disablePadding: true, label: 'Username' },
    { id: 'IDNo', numeric: false, disablePadding: true, label: 'ID No' },
    { id: 'PasswordExpiryDate', numeric: false, disablePadding: true, label: 'Password Expirey Date' }
];

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon' },
                { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon' },
                { id: 'delete', label: '', tooltip: 'Delete selected user from record', icon: 'DeleteIcon', disabled: true }
            ]
        }
        
    ]
};

const searchParameters = [
    { Name: "FirstName", Value: "" },
    { Name: "Surname", Value: "" },
    { Name: "IDNo", Value: "" }

]

class UserSearch extends Component {

    state = {
        model: {
            FirstName: "",
            Surname: "",
            IDNo: ""

        }
    };

    handleToolbarButtonMenuClick(menu) {
        this.setState({ linkDialogOpen: true, selectedLinkType: menu });
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("add" === buttonId) {
            this.props.history.push('/userDetails');
        } else if ("edit" === buttonId) {
            this.props.history.push({
                pathname: "/userDetails",
                state: {
                    selection: this.state.selectedUser,
                    FirstName: this.state.selectedFirstName,
                    Surname: this.state.selectedSurname
                }
            })
        }
    }

    postLinkHandler(linked) {
        this.setState({ linkDialogOpen: false, selectedLinkType: null, selectedLinkStandards: null });
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ 
                selectedUser: selection[0].Id,
                selectedFirstName: selection[0].FirstName,
                selectedSurname: selection[0].Surname 
            });
        }
        else {
            this.setState({ 
                selectedUser: null,
                selectedFirstName: null,
                selectedSurname: null
            });
        }
    }

    render() {
        return (
            <ViewContainer title={""}>
                <p className="breadcrumbs">
                    {"User Maintenance > Users"}
                </p>
                <SearchView headCells={headCells} dataUrl="api/sims/user/getsearch" entityViewPath='/userDetails'
                model={this.state.model} toolbarConfig={toolbarConfig} searchParameters={searchParameters}
                onLookupValueChange={this.props.onLookupValueChange}
                descriptionProp="Surname" mode={this.props.mode}
                history={this.props.history}
                buttonClickHandler={this.handleToolbarButtonClick}
                keyUpSwitch={this.state.keyUpSwitch} deleteUrl={"api/sims/user/RemoveUser"} useDeletePost={true}
                promptTitle={"Remove User"} promptContent={"Are you sure you want to remove this User?"}
                setSelection={(selection) => { this.handleSelection(selection) }}
            >
                <UserSearchForm model={this.state.model} />
                </SearchView>
                </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(UserSearch)
);

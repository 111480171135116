import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";
import Prompt from '../../widgets/Prompt';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import FormDialog from "../FormDialog";
import { ProviderSiteVisitForm } from "./ProviderSiteVisitForm";


const headCells = [
    { id: "SiteVisitDate", numeric: false, disablePadding: true, label: "Site Visit Date" },
    { id: "EvaluatorName", numeric: false, disablePadding: true, label: "Evaluator Name" },
    { id: "ReasonForSiteVisit", numeric: false, disablePadding: true, label: "Reason For Site Visit" },
    { id: "Outcomes", numeric: false, disablePadding: true, label: "Outcome" }
];


const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class ProviderSiteVisitsSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            formDialogOpen: false,
            selectedSiteVisit: null,
            propmtOpen: false,
            promptTitle: "Remove site visit from provider",
            promptContent: "Are you sure you want to remove this site visit from the provider?",
            unlinkMessage: "",
            unlinkMessageStyle: "",
            searchParameters: [{ Name: "providerID", Value: this.props.location.state.selection }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: 'Add Site Visit', tooltip: 'Add site visit to provider' },
                        { id: 'remove', label: 'Remove site visit', tooltip: 'Remove site visit from provider', disabled: utils.isNull(this.state.selectedSiteVisit) }
                    ]
                }
            ]
        };

    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/provider',
                state: { selection: this.props.location.state.selection }
            });
        }
        else if ("add" === buttonId) {
            this.setState({ formDialogOpen: true })
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    postAddHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                formDialogOpen: false, selectedSiteVisit: null
            });
        }
        else if (isCancel === true) {
            this.setState({
                formDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedSiteVisit: selection[0].Id, removeMessage: "" });
        }
        else {
            this.setState({ selectedSiteVisit: null, removeMessage: "" });
        }
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }


    successfulRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedSiteVisit: null, removeMessage: response.Message, removeMessageStyle: response.MessageType.toLowerCase() });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ removeMessage: response.Message, removeMessageStyle: response.MessageType.toLowerCase() })
        }
    }

    removeSiteVisit(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedSiteVisit}&providerId=${this.props.location.state.selection}`;
        utils.invokeUrl(fullUrl, this.successfulRemoveCallback, this.unsuccessfulRemoveCallback);
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title={`Providers > ${this.props.location.state.providerName} > Site Visits`}>

                <AlertItem message={this.state.removeMessage} alertStyle={this.state.removeMessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    <EnhancedTable
                        headCells={headCells}
                        dataUrl={`api/indicium/Provider/getSiteVisits`}
                        height="50vh"
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        searchParameters={this.state.searchParameters} paged={false} />
                </div>

                <FormDialog
                    open={this.state.formDialogOpen}
                    dialogTitle={"Add Provider Site Visit"}
                    viewRef={this}
                    formRef={addFormRef}
                    saveUrl={"api/indicium/provider/saveProviderSiteVisit"}
                    closeHandler={(isCancelled, linked) => this.postAddHandler(isCancelled, linked)}
                >
                    <ProviderSiteVisitForm ref={addFormRef} />
                </FormDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeSiteVisit("api/indicium/provider/removeSiteVisit")}}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(ProviderSiteVisitsSearch)
);

import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import GrantTypeSearchForm from './GrantTypeSearchForm';
import SearchView from "../../../widgets/SearchView";
import withMenuPermission from "../../../widgets/withMenuPermission";
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from "../../../ViewUtils";

const utils = new ViewUtils();
const toolbarRef = React.createRef();

const headCells = [
    { id: 'FinancialYear', numeric: false, disablePadding: false, label: 'Financial Year' },
    { id: 'GrantCode', numeric: false, disablePadding: false, label: 'Grant Code' },
    { id: 'GrantName', numeric: false, disablePadding: false, label: 'Grant Name' },
    { id: 'GrantDescription', numeric: false, disablePadding: false, label: 'Grant Description' },
    { id: 'GrantPercentage', numeric: false, disablePadding: false, label: 'Grant Percentage' },
    { id: 'IsPayable', numeric: false, disablePadding: false, label: 'IsPayable' },
    { id: 'MinimumAmount', numeric: false, disablePadding: false, label: 'MinimumAmount' }
];


class GrantTypeSearch extends Component {

    constructor(props) {
        super(props);

        

        this.state = {
            selectedGrantType: null,
            model: {
                FinancialYearID: null
            },
            keyUpSwitch: false

        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Create New', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', visible: this.props.menuPermissionItem.Edit, disabled: utils.isNull(this.state.selectedGrantType) },
                        { id: 'delete', label: '', tooltip: 'Remove Grant', icon: 'DeleteIcon', visible: this.props.menuPermissionItem.Delete, disabled: utils.isNull(this.state.selectedGrantType) }
                    ]
                }
            ]
        };

    }

    GetDefaultFinYear()
    {
        let url = "api/sims/GrantType/GetDefaultFinYearFind?Id=0";
        utils.invokeUrl(url, (response) => {

            this.setState({
                model: {
                    FinancialYearID: {Id : response.FinancialYearID.Id , Description : response.FinancialYearID.Description}
                }
            });
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    componentDidMount()
    {
        this.GetDefaultFinYear()
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedGrantType: selection })
        }
        else {
            this.setState({ selectedGrantType: null })
        }
        toolbarRef.current.setButtonDisabled("edit", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("delete", selection === null || selection.length === 0);
    }

    handleFormValueChange = (values) => {
        if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
            this.setState({ model: values, selectedGrantType: null });
            toolbarRef.current.setButtonDisabled("edit", true);
            toolbarRef.current.setButtonDisabled("delete", true);
        }
    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.props.history.push({
                pathname: '/GrantTypeDetails',
                state: {
                    GrantTypeID: null,
                    hideFields: true,
                    data: this.state.model
                }
            })
        }
        else if (buttonId === "edit") {
            this.props.history.push({
                pathname: '/GrantTypeDetails',
                state: {
                    GrantTypeID: this.state.selectedGrantType[0].Id,
                    hideFields: false
                }
            })
        }
    }

    render() {

        return (
            <ViewContainer  title="">
                <SearchView headCells={headCells} dataUrl="api/sims/GrantType/GetSearch" entityViewPath='/grant' addViewPath='/addGrant'
                    model={this.state.model} toolbarConfig={this.toolbarConfig} searchParameters={this.searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange} deleteUrl={"api/sims/GrantType/DeleteGrantType"}
                    useDeletePost={true}
                    toolbarRef={toolbarRef}
                    history={this.props.history}
                    title='Grant Type' 
                    handleObject={true}
                    autoFetchData={false}
                    keyUpSwitch={this.state.keyUpSwitch}
                    promptTitle={"Remove Grant Type"}
                    promptContent={"Are you sure you want to remove this grant type?"}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                >
                    <GrantTypeSearchForm data={this.state.model}
                        valueChangeHandler={(values) => this.handleFormValueChange(values)}  />
                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(GrantTypeSearch));




import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "../Helpdesk.css";
import { HelpdeskRequestForm } from './HelpdeskRequestForm';
import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";
import { ViewContainer } from "../../View";
import withMenuPermission from "../../widgets/withMenuPermission";

const utils = new ViewUtils();
const userRef = React.createRef();
const toolbarRef = React.createRef();

class HelpdeskRequest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: "",
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
            model: {
                ModuleID: null
            },

        };

        

        this.currentRole = utils.getCurrentRole(); 
        this.rolesAllowedToSeeColumn = ["Primary SDF","Secondary SDF"];
   
        this.model = this.rolesAllowedToSeeColumn.includes(this.currentRole) ?
        {
            ModuleID: { Id: 1, Description: "Skills Module" }
        }
        :
        {
            ModuleID: null
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon' }
                    ]
                }
            ]
        };

    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.close()
        } else if ("save" === buttonId) {
            this.save(); 
        }
    }

    handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {
            this.setState({
                Name: values.Name
            });
        }
    }


    save() {
        let formValue = userRef.current.getValue();

        if (formValue !== null && typeof formValue !== 'undefined') {
                const url = `api/sims/HelpDeskRequest/UpdateHelpdeskRequest`;
                utils.updateForm(this, userRef, url, (response) => {
                    this.setState({ message: response.Message, messageStyle: response.MessageType });
                }, this.error);

            this.setState({
                OpenDialog: false,
                formViewURL: "/HelpdeskRequestSearch",
                formName: ""
            });
            //this.props.location('/HelpdeskRequestSearch');
        }
    }

    successCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }
    error(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }
    close() {
        this.props.history.push('/HelpdeskRequestSearch');
    }

    dataArrivedHandler = (values) => {
        if (!utils.isNull(values["StatusID"])) {
            if(values.StatusID.Description === "Complete")
            {
                toolbarRef.current.setButtonDisabled("save", true);
            }
            else
            {
                toolbarRef.current.setButtonDisabled("save", false);
            }
        }
    }

    render() {
        return (
            <ViewContainer title="">
                <p className="breadcrumbs">
                    {"Helpdesk > Requests > Detail"}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />


                <div className="searchResults">
                            <EnhancedToolbar
                                highlight={false}
                                buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                ref={toolbarRef}
                                config={this.toolbarConfig} />

                                <HelpdeskRequestForm ref={userRef}
                                    valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                    id={this.state.id}
                                    editPermission={this.props.menuPermissionItem.Edit}
                                    dataArrivedHandler={(values) => this.dataArrivedHandler(values)}
                                    data={this.model} 
                                />
                    </div>    
            </ViewContainer>
        );
    }
}
export default withRouter(withMenuPermission(HelpdeskRequest));
// export default withRouter(HelpdeskRequest);

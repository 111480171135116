import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "../../View.css";
import "../../Grid.css";
import "../../Form.css";
import "../../App.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import { BursaryApplicationDocumentsForm } from './BursaryApplicationDocumentsForm';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "../../View.jsx";
import AlertItem from "../../widgets/AlertItem";

const utils = new ViewUtils();


const formRef = React.createRef();
const toolbarRef = React.createRef();

class AddBursaryApplicationDocuments extends Component {
    constructor(props) {
        super(props)
        let data = {};

        this.state = {
            message: "Note: Please upload all required documents to be able to submit your application ",
            model: props.data,
            messageStyle: "warning",
            nextButtonDisabled: true,
            PersonID: props.data.personRoleDetails ? props.data.personRoleDetails.ID : 0,
            BursaryApplicationTypeID: props.data.bursaryApplicationType.BursaryApplicationTypeID ? props.data.bursaryApplicationType.BursaryApplicationTypeID.Id : 0
        }

        this.documentUploadValidation();

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'back', label: 'BACK', tooltip: 'Back to Bursary Application', theme: true },
                        { id: 'finish', label: 'SUBMIT', tooltip: 'Save Application', theme: true, disabled: true }
                    ]
                }
            ]
        };
    }

    documentUploadValidation = () => {
        let url = "api/sims/BursaryApplication/GetOutstandingDocuments?BursaryApplicationKey=" + this.props.ukey + "&BursaryApplicationTypeID=" + this.state.BursaryApplicationTypeID ;
        utils.invokeUrlExternal(url, (response) => {
            this.successCallback(response);
        },
            (response) => {
                this.error(response);
            },
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    successCallback = (response) => {
        if (response.Status === "pass") {
            this.setState({
            }, () => {
                this.setState({
                    message: "All required documents uploaded!", messageStyle: "SUCCESS", nextButtonDisabled: false
                })
                toolbarRef.current.setButtonDisabled("finish", false);
            });
        }
        else if (response.Status === "fail") {
            this.setState({
                message: response.OutstandingList, messageStyle: "WARNING", nextButtonDisabled: true
            });
        }
    }

    handleFormValueChange() {
    }

    handleToolbarButtonClick(buttonId) {
        let formValue = formRef.current.getValue();
        if ("back" === buttonId) {
            this.props.stepHandler(4, false, formValue);
        } else if ("finish" === buttonId) {
            if (!utils.isNull(formValue)) {
                this.props.stepHandler(4, true, formValue);
            }
        }
    }

    render() {
        return (
            <ViewContainer topPadding="0">
                <p className="legend">
                    Mandatory Documents
                </p>
                <AlertItem message={'Please ensure that all documents are in pdf format, legible and clear to read.' + this.state.message} alertStyle={this.state.messageStyle} />
                  <br></br>
                  <br></br>
                <div style={{ height: "50%", maxHeight: "42vh" }}>
                    <BursaryApplicationDocumentsForm ref={formRef} id={this.state.PersonID} data={this.state.model} tempKey={this.props.ukey} IsExternal={this.props.IsExternal} editPermission={true} verifyDocumentCompletion={this.documentUploadValidation} valueChangeHandler={(values) => this.handleFormValueChange(values)} dataURL='api/sims/BursaryApplication/getBursaryApplicationEditDocuments' />
                </div>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="90%">
                            </td>
                            <td width="20%" align="right">
                                <div style={{ width: "120px", marginTop: "16px" }}>
                                    <EnhancedToolbar ref={toolbarRef} highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} config={this.toolbarConfig} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(AddBursaryApplicationDocuments)
);

import React from "react";
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import Menu from '@material-ui/core/Menu';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { withRouter, Link } from 'react-router-dom';
import mqalogo from '../../content/images/logos/mqa_logo.png'
import { connect } from 'react-redux';
import * as actions from '../../store/actions/auth';
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import ViewUtils from '../ViewUtils';
import Typography from '@material-ui/core/Typography';

const utils = new ViewUtils();

const header = (props) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(props.open);
    const classes = props.classes;
    const { accounts, inProgress, instance } = useMsal();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        //window.location.href = 'http://simsbat.solugrowth.com/';
    };

    const handleSwitchRole = () => {
        clearTimeout(props.userLogoutTimer);
        props.history.push('/role');
        props.onAuthSwitchProfile(props.loginRedirectToLoginPath);
    };

    const handleLogout = () => {
        clearTimeout(props.userLogoutTimer);
        let url = "api/sims/security/SetRevokeToken";
        const data = { Token: null };
        utils.invokeUrl(url, (response) => {
            if (props.loginRedirectToLoginPath !== '/ADlogin') {
                props.onAuthLogout();
                props.history.push(props.loginRedirectToLoginPath);
            }
            else {
                const isAuthenticated = accounts.length > 0;
                if (isAuthenticated && inProgress === InteractionStatus.None) {
                    instance.logoutPopup().then((loginResponse) => {
                        props.onAuthLogout();
                        props.history.push(props.loginRedirectToLoginPath);
                    }).catch((error) => {
                        console.log(error);
                    });
                } else {
                    props.onAuthLogout();
                    props.history.push(props.loginRedirectToLoginPath);
                }
            }
        },
            null,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        )
    }


    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <AppBar position="fixed" className={clsx(classes.appBar)}>
            <Toolbar variant="dense">
                <IconButton
                    edge="start"
                    aria-label="menu"
                    color="inherit"
                    onClick={props.handleOpenMenu}
                >
                    <MenuIcon />
                </IconButton>
                {/*<img src={simslogoLoading} style={{ height: "40px" }} />*/}
                <Typography style={{ marginRight: "auto", marginLeft: "20px", fontWeight: "bold" }} >
                    Mining Qualifications Authority (MQA)
                </Typography>
                <Typography style={{ marginLeft: "auto", marginRight: "5px" }} >
                    {props.UserInfo}
                </Typography>

                <IconButton aria-label="account info"
                    onClick={handleClick}
                    aria-controls="account-menu"
                    color="inherit"
                    style={{ marginRight: "0px" }}
                >
                    <AccountCircle />
                </IconButton>

                <Menu
                    id="account-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleSwitchRole}>Switch Role</MenuItem>
                    <Link to="">
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Link>
                </Menu>
            </Toolbar>
        </AppBar>
    )
};

const mapStateToProps = state => {
    return {
        userLogoutTimer: state.auth.userLogoutTimer,
        loginRedirectToLoginPath: state.auth.loginRedirectToLoginPath,
        UserInfo: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.UserInfo : ""
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onAuthLogout: () => dispatch(actions.authLogout()),
        onAuthSwitchProfile: (loginPath) => dispatch(actions.authSwitchProfile(loginPath))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(header));
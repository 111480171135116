import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Row, Col, Form } from 'react-bootstrap';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { CustomCheckbox } from '../../../widgets/CustomCheckbox';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from '../../../ViewUtils';
import QCTOQualificationSetupSearch from './QCTOQualificationSetupSearch';
import OFOSearch from '../OFOSearch';
import { LookupField } from '../../../widgets/LookupField';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
export const QCTOQualificationForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleReplacementChange = form => event => {
        if (!event.target.checked) {
            form.values['qualification'] = null;
            form.setLookupSelection("qualification", null);
        }

        form.updateValue('IsReplacement', event.target.checked);
    };

    const handleReRegisteredChange = form => event => {
        if (!event.target.checked) {
            form.values['NewRegistrationStartDate'] = null;
            form.values['NewRegistrationEndDate'] = null;
            form.values['NewLastEnrolmentDate'] = null;
            form.values['NewLastAchievementDate'] = null;
        }

        form.updateValue('IsReregistered', event.target.checked);
    };

    function offsetDate(date, yearOffset, monthOffset, dayOffset) {
        let year = date.getFullYear() + yearOffset;
        let day = date.getDate() + dayOffset;
        let month = date.getMonth() + monthOffset;

        return new Date(year, month, day);
    }

    function dateChange(form, date) {

        let newregend = offsetDate(date, 4, 0, 0);
        let newlastenrolment = offsetDate(date, 6, 0, 0);
        let newlastachievement = offsetDate(date, 9, 0, 0);

        form.values['NewRegistrationEndDate'] = newregend;
        form.values['NewLastEnrolmentDate'] = newlastenrolment;
        form.values['NewLastAchievementDate'] = newlastachievement;
    }

    function CompMainQualCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Part Qualification") {
                return true
            }
            else {
                return false
            }
        }
    }

    return (
        <EntityForm
            ref={ref}
            viewId="QCTOqualificationSetup"
            dataURL='api/sims/QCTOqualificationsetupdetails/getfind'
            values={props.data}
            entityLookupController={props.entityLookupController}
            org="saseta"
            entityLookupId={props.id}
            useCustomSettings={false}
            defaultHandlerObj={props.defaultHandlerObj}
            valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <Form id="QCTOqualificationSetupForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="QCTO Qualification Setup Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <Form.Row>
                                <Col xs lg="6">
                                    <CustomTextField
                                        id="SAQAQualificationID"
                                        key={tabValue}
                                        validationRegex={/^[0-9]+$/g}
                                        validationMessage="This field must be numeric"
                                        label="SAQA Qualification ID"
                                        className="w-100"
                                        required
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                        disabled={props.id}
                                    />
                                </Col>
                                <Col xs lg="6">
                                    <CustomTextField
                                        id="SAQAQualificationTitle"
                                        key={tabValue}
                                        required={true}
                                        label="SAQA Qualification Title"
                                        className="w-100 "
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                        disabled={!props.editPermission}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs lg="6">
                                    <CustomTextField
                                        id="MinimumElectiveCredits"
                                        key={tabValue}
                                        label="Minimum Elective Credits"
                                        className="w-100 "
                                        required
                                        validationRegex={/^[0-9]+$/g}
                                        validationMessage="This field must be numeric"
                                        validator={{
                                            script: (formValues) => {
                                                return !utils.isNull(formValues)
                                                    && formValues.MinimumElectiveCredits <= 1000
                                            }, message: "This value must be less than 1000"
                                        }}
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                                <Col xs lg="6">
                                    <CustomTextField
                                        id="Credits"

                                        key={tabValue}
                                        label="Credits"
                                        className="w-100 "
                                        required
                                        validationRegex={/^[0-9]+$/g}
                                        validationMessage="This field must be numeric"
                                        validator={{
                                            script: (formValues) => {
                                                return !utils.isNull(formValues)
                                                    && formValues.Credits <= 1000
                                            }, message: "This value must be less than 1000"
                                        }}
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs lg="6">
                                    <CustomDatePicker
                                        id="RegistrationStartDate"
                                        validationGroup="registrationDates"
                                        key={tabValue}
                                        required
                                        InputProp={{
                                            readOnly: true
                                        }}
                                        label="Registration Start Date"
                                        className='{classes.textField} w-100 '
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                                <Col xs lg="6">
                                    <CustomDatePicker
                                        id="RegistrationEndDate"
                                        validationGroup="registrationDates"
                                        required
                                        key={tabValue}
                                        InputProp={{
                                            readOnly: true
                                        }}
                                        validator={{
                                            script: (formValues) => {
                                                return !utils.isNull(formValues)
                                                    && !utils.isNull(formValues.RegistrationStartDate)
                                                    && formValues.RegistrationStartDate.getTime() < formValues.RegistrationEndDate.getTime()
                                            }, message: "Start date must be before the end date"
                                        }}
                                        label="Registration End Date"
                                        className='{classes.textField} w-100 '
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs lg="6">
                                    <CustomDatePicker
                                        id="LastEnrolmentDate"
                                        inputProps={{
                                            readOnly: true
                                        }}
                                        required
                                        key={tabValue}
                                        label="Last Enrolment Date"
                                        className='{classes.textField} w-100 '
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                                <Col xs lg="6">
                                    <CustomDatePicker
                                        id="LastAchievementDate"
                                        required
                                        key={tabValue}
                                        inputProps={{
                                            readOnly: true
                                        }}
                                        label="Last Achievement Date"
                                        validator={{
                                            script: (formValues) => {
                                                return !utils.isNull(formValues)
                                                    && !utils.isNull(formValues.LastEnrolmentDate)
                                                    && formValues.LastEnrolmentDate.getTime() < formValues.LastAchievementDate.getTime()
                                            }, message: "Last achievement date must be greater than last enrolment date"
                                        }}
                                        className='{classes.textField} w-100 '
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs lg="6">
                                    <SelectItem
                                        dataUrl="api/sims/QCTOqualificationsetupdetails/GetLookupList?listId=QCTOQualificationType"
                                        id="QCTOQualificationTypeID"
                                        key={tabValue}
                                        label="Qualification Type"
                                        required={true}
                                        className="w-100"
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                                <Col xs lg="6">
                                    <LookupField
                                        id="OFOOccupationID"
                                        label="OFO Code"
                                        className="w-100"
                                        form={form} disabled={!props.editPermission}
                                    >
                                        <OFOSearch showTitle={true} mode='lookup'
                                            dataUrl='api/sims/QCTOqualificationsetupdetails/getofooccupationsearch'
                                            onLookupValueChange={(value) => {
                                                form.setLookupSelection("OFOOccupationID", value)
                                            }} autoFetchData={false} selection={props.selection} />
                                    </LookupField>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs lg="6">
                                    <SelectItem
                                        dataUrl="api/sims/QCTOqualificationsetupdetails/GetLookupList?listId=nqfLevel"
                                        id="NQFLevelID"
                                        key={tabValue}

                                        required
                                        label="NQF Level"
                                        className="w-100"
                                        form={form} disabled={!props.editPermission} />
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col xs lg="6">
                                    <CustomCheckbox
                                        id="IsReregistered"
                                        key={tabValue}
                                        form={form} disabled={!props.editPermission}
                                        onChange={handleReRegisteredChange}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                        label="Is Re-registered?"
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs lg="6">
                                    <CustomDatePicker
                                        id="NewRegistrationStartDate"
                                        key={tabValue}
                                        validationGroup="newRegistrationDates"
                                        label="New Registration Start Date"
                                        required={form.values.IsReregistered}
                                        disabled={!form.values.IsReregistered}
                                        onChange={dateChange}
                                        className='{classes.textField} w-100 '
                                        InputProps={{
                                            readOnly: true,
                                            disabled: !form.values.IsReregistered
                                        }}
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                                <Col xs lg="6">
                                    <CustomDatePicker
                                        id="NewRegistrationEndDate"
                                        key={tabValue}
                                        validationGroup="newRegistrationDates"
                                        label="New Registration End Date"
                                        className='{classes.textField} w-100 '
                                        required={form.values.IsReregistered}
                                        disabled={!form.values.IsReregistered}
                                        InputProps={{
                                            readOnly: true,
                                            disabled: !form.values.IsReregistered
                                        }}

                                        validator={{
                                            script: (formValues) => {
                                                return !formValues.IsReregistered || (formValues.IsReregistered && !utils.isNull(formValues)
                                                    && !utils.isNull(formValues.NewRegistrationStartDate)
                                                    && formValues.NewRegistrationStartDate.getTime() < formValues.NewRegistrationEndDate.getTime())
                                            }, message: "New registration end date  must be greater than new registration start date"
                                        }}
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs lg="6">
                                    <CustomDatePicker
                                        id="NewLastEnrolmentDate"
                                        key={tabValue}
                                        validationGroup="newEnrolmentDates"
                                        label="New Last Enrolment Date"
                                        className='{classes.textField} w-100 '
                                        required={form.values.IsReregistered}
                                        disabled={!form.values.IsReregistered}
                                        InputProps={{
                                            readOnly: true,
                                            disabled: !form.values.IsReregistered
                                        }}
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                                <Col xs lg="6">
                                    <CustomDatePicker
                                        id="NewLastAchievementDate"
                                        key={tabValue}
                                        validationGroup="newEnrolmentDates"
                                        label="New Last Achievement Date"
                                        className='{classes.textField} w-100 '
                                        InputProps={{
                                            readOnly: true,
                                            disabled: !form.values.IsReregistered
                                        }}
                                        validator={{
                                            script: (formValues) => {
                                                return !formValues.IsReregistered || (!utils.isNull(formValues)
                                                    && !utils.isNull(formValues.NewRegistrationStartDate)
                                                    && formValues.NewLastEnrolmentDate.getTime() < formValues.NewLastAchievementDate.getTime())
                                            }, message: "New achievement end date must be greater than new enrolment last enrolment date"
                                        }}

                                        required={form.values.IsReregistered}
                                        disabled={!form.values.IsReregistered}
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs lg="6">
                                    <CustomCheckbox
                                        id="IsReplacement"
                                        key={tabValue}
                                        form={form} disabled={!props.editPermission}
                                        onChange={handleReplacementChange}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                        label="Is Replacement?"
                                    />
                                </Col>
                                <Col xs lg="6">
                                    <LookupField
                                        id="QualificationToBeReplacedID"
                                        label="QCTO Qualification To Be Replaced"
                                        className="w-100"
                                        form={form} disabled={!props.editPermission}
                                        required={form.values.IsReplacement}
                                        disabled={!form.values.IsReplacement}
                                    >
                                        <QCTOQualificationSetupSearch
                                            showTitle={true} mode='lookup'
                                            onLookupValueChange={(value) => {
                                                form.setLookupSelection("QualificationToBeReplacedID", value)
                                            }} autoFetchData={true} selection={props.selection} />
                                    </LookupField>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs lg="6">
                                    <LookupField
                                        id="QualificationID"
                                        label="Main Qualification"
                                        className="w-100"
                                        form={form} disabled={!props.editPermission}
                                        required={CompMainQualCheck(form.values["QCTOQualificationTypeID"])}
                                        disabled={!CompMainQualCheck(form.values["QCTOQualificationTypeID"])}
                                    >
                                        <QCTOQualificationSetupSearch
                                            showTitle={true} mode='lookup'
                                            onLookupValueChange={(value) => {
                                                form.setLookupSelection("QualificationID", value)
                                            }} autoFetchData={true} selection={props.selection} />
                                    </LookupField>
                                </Col>
                                <Col xs lg="6">
                                    <SelectItem
                                        dataUrl="api/sims/QCTOqualificationsetupdetails/GetLookupList?listId=YesNo"
                                        id="ArtisanQualificationYesNoID"
                                        key={tabValue}

                                        required
                                        label="Artisan Qualification"
                                        className="w-100"
                                        form={form} disabled={!props.editPermission} />
                                </Col>
                            </Form.Row>
                        </TabPanel>
                    </FormTabContainer>
                </Form>
            }
        </EntityForm>
    );
})

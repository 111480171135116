import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../../widgets/Toolbar';
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import "./LevyTransaction.css";
import ViewUtils from '../../../ViewUtils';
import AlertItem from "../../../widgets/AlertItem";
import { ViewContainer } from "./../../../View.jsx";
import withMenuPermission from "../../../widgets/withMenuPermission";
import EnhancedTable from '../../../widgets/ResponsiveTable';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Typography } from "antd";
import { withStyles, makeStyles } from '@material-ui/core/styles';


const utils = new ViewUtils();
const formRef = React.createRef();
const toolbarRef = React.createRef();
const headCells = [
    { id: 'TransactionDate', numeric: false, disablePadding: false, label: 'Transaction Date' },
    { id: 'MainAccountNumber', numeric: false, disablePadding: false, label: 'Main Account Number' },
    { id: 'MainDebitAmount', numeric: false, disablePadding: false, label: 'Main Debit Amount' },
    { id: 'MainCreditAmount', numeric: false, disablePadding: false, label: 'Main Credit Amount' },
    { id: 'ContraAccountNumber', numeric: false, disablePadding: false, label: 'Contra Account Number' },
    { id: 'ContraDebitAmount', numeric: false, disablePadding: false, label: 'Contr Debit Amount' },
    { id: 'ContraCreditAmount', numeric: false, disablePadding: false, label: 'Contra Credit Amount' }
];
const processheadCells = [
    { id: 'ProcessDate', numeric: false, disablePadding: false, label: 'Process Date' },
    { id: 'ProcessID', numeric: false, disablePadding: false, label: 'Process Number' },
    { id: 'Progress', numeric: false, disablePadding: false, label: 'Progress' },
    { id: 'StatusCode', numeric: false, disablePadding: false, label: 'Status Code' },
    { id: 'Status', numeric: false, disablePadding: false, label: 'Status' }
];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        color: 'white',
    },
    table: {
        minWidth: 700,
    },
});

class LevyTransaction extends Component {

    state = {
        message: "",
        messageStyle: "",
        title: "",
        id: null,
        searchParameters: [{ Name: "ID", Value: 0 }],
        OpenDialog: false,
        showTable: true
    };

    toolbarConfig = {
        items: [
            {
                type: "buttonGroup",
                buttons: [
                    { id: 'save', label: 'SAVE', tooltip: 'Process to GP', icon: 'SendIcon', visible: utils.isNull(this.state.id) ? this.props.menuPermissionItem.Edit : this.props.menuPermissionItem.Add },
                    { id: 'view', label: 'VIEW', tooltip: 'View GP Process Progress', icon: 'InfoIcon', visible: utils.isNull(this.state.id) ? this.props.menuPermissionItem.Edit : this.props.menuPermissionItem.Add },
                    { id: 'report', label: 'REPORT', tooltip: 'Open Levy Summary Report', icon: 'LibraryBooksIcon', visible: utils.isNull(this.state.id) ? this.props.menuPermissionItem.Edit : this.props.menuPermissionItem.Add }
                ]
            }
        ]
    };

    componentDidMount() {
            this.getFormHead("1");
        }

    save = () => {

        let url = "api/sims/LevyTransaction/LevyProcess";
            utils.invokeUrl(url, (response) => {
                this.successCallback(response);
            },
                (response) => {
                    this.error(response);
                },
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    }
                }
            )
        }

    getFormHead = (pageLoad) => {
            let url = "api/sims/LevyTransaction/CheckProcessStatus";
            utils.invokeUrl(url, (response) => {
                if (response.Result === "In Progress") {
                    toolbarRef.current.setButtonDisabled("save", true);
                }
                else if (response.Result === "Completed") {
                    toolbarRef.current.setButtonDisabled("save", false);
                }
            },
                null,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    }
                }
            )
            if(pageLoad === "0")
            {
            this.setState({
                showTable: false
            }, () => {
                this.setState({ showTable: true })
            });
        }
            
    }

    successCallback(response) {
        this.setState({
            message: response.Message, messageStyle: response.MessageType
        });
        this.getFormHead("0");    
    }
    errorCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
        this.getFormHead("0");
    }

    handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        }
        else if ("view" === buttonId) {
            this.OpenViewDialog();
        }
        else if ("report" === buttonId) {
            this.DownloadOpenLevyReport();
        }   
    }

    CheckProgress = () => {
        this.setState({message: "", messageStyle: ""})
        this.getFormHead("0");
    }

    handleSelectionChange = (selection) => {
    }

    DownloadOpenLevyReport = () => {
        let url = "api/sims/LevyTransaction/DownloadOpenLevyReport";
        utils.invokeUrl(url, (response) => {

            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };
    
    OpenViewDialog() {
        this.setState({
            OpenDialog: true
        });
    }

    CloseViewDialog = () => {
        this.setState({
            OpenDialog: false,
            message: "",
            messageStyle: "",
        });
        this.getFormHead("0");
    };
    render() {
        const { classes } = this.props;
        return (

                <ViewContainer  title="">
                <p className="breadcrumbs">
                    {"Levy Transactions"}
                </p>
                    <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                
                <td><EnhancedToolbar 
                ref={toolbarRef} highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} 
                enabled={true} config={this.toolbarConfig} />
                </td>
                
                <div className="searchResults"> 
                 {this.state.showTable === true && ( 
                        <EnhancedTable
                        headCells={headCells}
                        dataUrl={`api/sims/LevyTransaction/GetSearch`}
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        searchParameters={this.state.searchParameters} paged={false} 
                        makeRowBold={true}/>

                        )}
                        </div>  

                <Dialog fullScreen open={this.state.OpenDialog} onClose={this.CloseViewDialog} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.CloseViewDialog} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h1" className={classes.title}>
                                Levy Process to Great Plains Progress
                            </Typography>

                        </Toolbar >
                    </AppBar >
                    <br /><br />
                    <EnhancedTable
                        headCells={processheadCells}
                        dataUrl={`api/sims/LevyTransaction/GetLevyProcessSearch`}
                        makeRowBold={true}
                        searchParameters={this.state.searchParameters} paged={true} />
                </Dialog >

                </ViewContainer>
        );
    }
}
export default withRouter(withStyles(useStyles())(withMenuPermission(LevyTransaction)));
import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../../widgets/Toolbar';
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from "../../../ViewUtils";
import AlertItem from "../../../widgets/AlertItem";
import { AddEditAllocationForm } from './AddEditAllocationForm';



const AllocationRef = React.createRef();
const utils = new ViewUtils();
const toolbarRef = React.createRef();

class AddEditAllocation extends Component {

    state = {
        /*Deciding on unique identifier for existing allocation details title*/
        AllocationDetails: {
            Description: "",
            Code: ""
        },
        messageStyle: "message",
        id: null,
        showTable: true,
        AllocationID: this.props.location.state.AllocationID,
        EmployerID: this.props.location.state.EmployerID,
        TrancheCodeID: this.props.location.state.TrancheCodeID,
        FinancialYearID: this.props.location.state.FinancialYearID,
        hideFields: this.props.location.state.hideFields,
        disableFields: this.props.location.state.disableFields
    };

    toolbarConfig = {
        items: [

            {
                type: "buttonGroup",
                buttons: [
                    { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                ]
            },
            {
                type: "devider"
            },
            {

                type: "buttonGroup",
                buttons: [
                    { id: 'save', label: 'Submit', tooltip: 'Submit', icon: 'SaveIcon' }
                ]
            }

        ]
    };

    handleFormValueChange = (values) => {
        if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
            this.setState({ model: values });
        }
    }


    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("allocationdetail" === buttonId) {
            this.props.history.push({
                pathname: '/GrantAllocationDetails',
                state: {
                    GrantAllocationID: this.state.AllocationID
                }
            })
        }
    }

    successCallback(response)  {
        if (response.MessageType === "SUCCESS") {
            this.setState({
                showTable: false
            }, () => {
                this.setState({
                    showTable: true,
                    message: response.Message,
                    messageStyle: response.MessageType,
                    AllocationID: response.EntityId
                })
            });
            toolbarRef.current.setButtonDisabled("allocationdetail", false);
        }
        else {
            this.setState({
                message: response.Message, messageStyle: response.MessageType
            });
        }
    }

    save() {
        let formValue = AllocationRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {

            formValue.ID = this.props.location.state.AllocationID;
            let input = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/GrantAllocation/grantAllocationDetailsSubmit", (response) => {
                utils.showMessage(this, response.Message, response.MessageType);
                this.successCallback(response)
            },
                null,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    },
                    body: input
                }
            );
        }
    }

    closeView() {
        this.props.history.push({
            pathname: '/GrantAllocationSearch'
        });
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
            <p className="breadcrumbs">
                {"Grant Allocation Details"}
            </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    title=""
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    ref={toolbarRef}
                                    config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "80vh" }}>
                                    {this.state.showTable === true && (
                                        <AddEditAllocationForm ref={AllocationRef}
                                            viewRef={this}
                                            id={this.state.AllocationID}
                                            data={this.state.model}
                                            hideFields={this.state.hideFields}
                                            valueChangeHandler={(values) => this.handleFormValueChange(values)} 
                                            disableFields={this.state.disableFields}/>
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(AddEditAllocation);

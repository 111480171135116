import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import SearchView from "../../../widgets/SearchView";
import SCPPISetupSearchForm from './../SCPPI/SCPPISetupSearchForm';
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from "./../../../ViewUtils";
import withMenuPermission from "../../../widgets/withMenuPermission";


const headCells = [
    { id: 'SCPPICode', numeric: false, disablePadding: true, label: 'Programme Code' },
    { id: 'SCPPIDescription', numeric: false, disablePadding: true, label: 'Programme Description' }
];

const utils = new ViewUtils();

class SCPPISetupSearch extends Component {
    constructor(props) {
        super(props)

        let model = {
            SCPPICode: "",
            SCPPIDescription: ""
        }

        this.searchParameters = !utils.isNull(this.props.programmeId) ?
            [
                { Name: "id", Value: this.props.programmeId },
                { Name: "SCPPICode", Value: "" },
                { Name: "SCPPIDescription", Value: "" }
            ]
            :
            [
                { Name: "SCPPICode", Value: "" },
                { Name: "SCPPIDescription", Value: "" },
            ]

        this.state = {
            model: model,
            selectedSCPPIProgramme: null,
            keyUpSwitch: false
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: "delete", label: '', tooltip: 'Remove', icon: 'DeleteIcon', disabled: true, visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        }
    }

    onkeyup(e) {
        //console.log(e.charCode)
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer title="">
                <SearchView headCells={headCells} dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/SCPPIsetup/getsearch"}
                    entityViewPath='/SCPPIsetup'
                    title='Short Courses Provided by Public Institution'
                    model={this.state.model} toolbarConfig={this.toolbarConfig}
                    searchParameters={this.searchParameters} onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="SCPPIDescription" mode={this.props.mode}
                    keyUpSwitch={this.state.keyUpSwitch}
                    history={this.props.history}
                    multiSelect={this.props.multiSelect}
                    deleteUrl={"api/sims/SCPPISetup/unlinkSCPPI"} useDeletePost={true}
                    promptTitle={"Remove SCPPI Programme"} selectionCallbackMode={this.props.selectionCallbackMode}
                    promptContent={"Removing the programme is a permanent action and the programme will not be available going forward. Are you sure you want to remove this programme?"}
                >
                    <SCPPISetupSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>

        );
    }
}

export default withRouter(withMenuPermission(SCPPISetupSearch));


import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import Checkbox from '@material-ui/core/Checkbox';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import TabPanel from '../../widgets/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Organisation from '../organisation/Organisation';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();

export const WSPandATRCreateForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        

        <EntityForm ref={ref} viewId="organisation" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="wspatrcreate">

                    <div className="row w-100">
                        <div className="col w-100">
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                parentId={0}
                                                dataUrl={'api/sims/wspatr/GetFinancialYearForCreate?parameter=[{"Name":"OrganisationID","Value":' + props.id + '}]'}
                                                id="FinancialYearID"
                                                key={tabValue}
                                                label="Financial Year"
                                                className="w-100"
                                                form={form}
                                                required
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/wspatr/GetLookupList?listId=formType"
                                                id="formTypeID"
                                                key={tabValue}
                                                label="Form Type"
                                                className="w-100"
                                                form={form}
                                                required
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form >
            }
        </EntityForm >
    );
})
import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import LevyAccountSearchForm from './LevyAccountSearchForm';
import withMenuPermission from "../../../widgets/withMenuPermission";
import { ViewContainer } from "../../../View.jsx";
import ViewUtils from "../../../ViewUtils";
import EnhancedToolbar from '../../../widgets/Toolbar';
import EnhancedTable from '../../../widgets/ResponsiveTable';
import { LinkAccountForm } from "./LinkAccountForm";
import FormDialog from "../../../etqa/FormDialog";
import AlertItem from "../../../widgets/AlertItem";
import SearchView from "../../../widgets/SearchView";

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const updateFormRef = React.createRef();
const addFormRef = React.createRef();

const headCells = [
    { id: 'FinancialYear', numeric: false, disablePadding: false, label: 'Financial Year' },
    { id: 'LevyField', numeric: false, disablePadding: false, label: 'Levy Field' },
    { id: 'MainGLAccountNumber', numeric: false, disablePadding: false, label: 'Main GL Account Number' },
    { id: 'ContraGLAccountNumber', numeric: false, disablePadding: false, label: 'Contra GL Account Number' }
];


class LevyAccountSearch extends Component {

    constructor(props) {
        super(props);

        

        this.state = {
            selectedLevyAccount: null,
            model: {
                FinancialYearID: null
            },
            keyUpSwitch: false,
            showTable: true,
            updateDialogOpen: false,
            addDialogOpen: false,
            propmtOpen: false,
            Message: null,
            MessageStyle: null,
            searchParameters: [{ Name: "FinancialYearID", Value: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.FinancialYearID)) ? this.props.location.state.FinancialYearID : null }]

        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', visible: this.props.menuPermissionItem.Edit, disabled: utils.isNull(this.state.selectedLevyAccount) }
                    ]
                }
            ]
        };

    }

    GetDefaultFinYear()
    {
        let url = "api/sims/LevyAccount/GetDefaultFinYearFind?Id=0";
        utils.invokeUrl(url, (response) => {

            this.setState({
                model: {
                    FinancialYearID: {Id : response.FinancialYearID.Id , Description : response.FinancialYearID.Description}
                },
                searchParameters: [{ Name: "FinancialYearID", Value: response.FinancialYearID.Id} ],
                FinancialYearID: response.FinancialYearID.Id
            });
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    componentDidMount()
    {
        this.GetDefaultFinYear()
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedLevyAccount: selection[0].Id })
        }
        else {
            this.setState({ selectedLevyAccount: null })
        }
        toolbarRef.current.setButtonDisabled("edit", selection === null || selection.length === 0);
    }

    handleFormValueChange(values) {
        
                let FinancialYearID = values.FinancialYearID.Id;
                let newsearchParameters = [...this.state.searchParameters];
                let temp_element = { ...newsearchParameters[0] };
                temp_element.Value = FinancialYearID
                newsearchParameters[0] = temp_element;
        
                this.setState({
                    searchParameters: JSON.parse(JSON.stringify(newsearchParameters)),
                    FinancialYearID: FinancialYearID
                });
            }
        

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.setState({ addDialogOpen: true,Message: null,MessageStyle: null });
        }
        else if (buttonId === "edit") {
            this.setState({ updateDialogOpen: true,Message: null,MessageStyle: null });
        }
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                selectedLevyAccount: null, addDialogOpen: false, updateDialogOpen: false,
                clearSelectedRow: true
            }, () => {
                this.setState({ showTable: true })
            });
        }
        else if (isCancel === true) {
            this.setState({
                addDialogOpen: false,
                updateDialogOpen: false,
                selectedLevyAccount: null,
                clearSelectedRow: true
            })
        }
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    onClearSelectedRow = () => {
        this.setState({ clearSelectedRow: false });
    }

    render() {

        return (
            <ViewContainer  title="">
            <p className="breadcrumbs">
                {"Levy Account"}
            </p>
                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />
                <div className="searchResults">
                <LevyAccountSearchForm
                    valueChangeHandler={(values) => {
                        console.log(values);
                        if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                            this.setState({ 
                                model: values,
                                searchParameters: [{ Name: "FinancialYearID", Value: values.FinancialYearID.Id  }],
                                FinancialYearID: values.FinancialYearID.Id
                            });
                        }
                        
                    }}
                    data={this.state.model}
                />

                <EnhancedToolbar highlight={false}
                        title="Levy Account"
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig}
                    />

                    <EnhancedTable
                        headCells={headCells}
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        dataUrl={!utils.isNull(this.state.FinancialYearID) ? "api/sims/LevyAccount/GetSearch" : null}
                        searchParameters={this.state.searchParameters}
                        paged={false} />

            </div>
                <FormDialog open={this.state.addDialogOpen}
                            title={'Add Levy Account'}
                            formRef={addFormRef}
                            viewRef={this}
                            closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                            dialogTitle={'Add Levy Account'}
                            linkName={"Account"} saveUrl={"api/sims/LevyAccount/LevyAccountInsert"}
                            usePost={true}
                        >

                            <LinkAccountForm ref={addFormRef} id={this.state.selectedLevyAccount} data={this.state.model}/>
                        </FormDialog>

                    <FormDialog open={this.state.updateDialogOpen}
                            title={'Update Levy Account'}
                            formRef={updateFormRef}
                            viewRef={this}
                            closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                            dialogTitle={'Update Levy Account'}
                            linkName={"Account"} saveUrl={"api/sims/LevyAccount/LevyAccountUpdate"}
                            usePut={true} id={this.state.selectedLevyAccount} disableFields={true}
                        >

                            <LinkAccountForm ref={updateFormRef} id={this.state.selectedLevyAccount} />
                        </FormDialog>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(LevyAccountSearch));




import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "../../../View.css";
import "../../../Grid.css";
import "../../../Form.css";
import "../../../App.css";
import "./Moderator.css";
import { ViewContainer } from "../../../View.jsx";
import ViewUtils from '../../../ViewUtils';
import withMenuPermission from "../../../widgets/withMenuPermission";
import EnhancedTable from '../../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../../widgets/Toolbar';


const utils = new ViewUtils();

const lowertoolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'back', label: 'BACK', tooltip: 'Back to skills programme scope', theme: true },
                { id: 'next', label: 'NEXT', tooltip: 'Proceed to verify uploaded documents', theme: true }
            ]
        }
    ]
};

const headCells = [
    { id: 'DocumentType', numeric: false, disablePadding: true, label: 'Document Type' },
    { id: 'DocumentName', numeric: false, disablePadding: true, label: 'Document Name', contentType: 'document' },
    { id: 'UploadDate', numeric: false, disablePadding: true, label: 'Upload Date' }
]

class OnlineModeratorApprovalDocuments extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showTable: false
        }
    }

    handleCellSelect = (rowid, headCell) => {
        let url = "api/sims/OnlineModeratorApproval/DownloadApprovalDocument?documentID=" + rowid;
        utils.invokeUrl(url, (response) => {
            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();

        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    handleLowerToolbarButtonClick(buttonId) {
        if ("next" === buttonId) {
            this.props.history.push({
                pathname: '/OnlineModeratorDetailsVerification',
                state: {
                    selection: this.props.location.state.selection,
                    selectedQualificationRows: !utils.isNull(this.props.location.state.selectedQualificationRows) ? this.props.location.state.selectedQualificationRows : [],
                    selectedSkillsProgrammeRows: !utils.isNull(this.props.location.state.selectedSkillsProgrammeRows) ? this.props.location.state.selectedSkillsProgrammeRows : []
                }
            });
        } else if ("back" === buttonId) {
            this.props.history.push({
                pathname: '/OnlineModeratorApprovalSkillsProgrammeScope',
                state: {
                    selection: this.props.location.state.selection,
                    selectedQualificationRows: !utils.isNull(this.props.location.state.selectedQualificationRows) ? this.props.location.state.selectedQualificationRows : [],
                    selectedSkillsProgrammeRows: !utils.isNull(this.props.location.state.selectedSkillsProgrammeRows) ? this.props.location.state.selectedSkillsProgrammeRows : []
                }
            });
        }
    }

    render() {
        return (
            <ViewContainer title="">
                <p className="breadcrumbs">
                    {"Moderator Approval > Uploaded Documents"}
                </p>
                <br></br>
                <EnhancedTable autoFetchData={true} headCells={headCells} onCellSelectionChange={(rowid, columnid) => { this.handleCellSelect(rowid, columnid) }} searchParameters={[{ Name: `OnlineModeratorAppID`, Value: this.props.location.state.selection }]} paged={false}
                    dataUrl="api/sims/OnlineModeratorApproval/GetUploadedDocuments" 
                />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="90%">
                            </td>
                            <td width="20%" align="right">
                                <div style={{ width: "120px", marginTop: "16px" }}>
                                    <EnhancedToolbar highlight={false} buttonClickHandler={(buttonId) => this.handleLowerToolbarButtonClick(buttonId)} config={lowertoolbarConfig} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(OnlineModeratorApprovalDocuments));


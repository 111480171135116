import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { AddressCodeItem } from '../../widgets/AddressCodeItem';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { makeStyles } from '@material-ui/core/styles';
import { LookupField } from '../../widgets/LookupField';
import QualificationSearch from './QualificationSearch';
import BursaryInstitutionSearch from './BursaryInstitutionSearch';
import BursaryUniversityOrTVETSearch from './BursaryUniversityOrTVETSearch';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    MuiSelectSelectMenu: {
        '& .MuiSelect-selectMenu': { paddingRight: "150px !important" }
    }
}));

const utils = new ViewUtils();
export const BursaryApplicationContinuationEvaluationForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const classes = useStyles();

    return (
        <EntityForm ref={ref} viewId="bursaryapplication" values={props.data} dataURL='api/sims/BursaryApplicationEvaluation/GetBursaryApplicationFind'
            entityLookupController={props.entityLookupController} org="saseta" IsExternal={props.IsExternal}
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="BursaryApplicationContinuationForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Continuation Details: MQA Bursary" wrapped {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                                <Tab label="Declaration" wrapped {...a11yProps(1)} style={{ color: form.fieldParentErrors[1] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel className="w-100" value={tabValue} index={0}>
                            <table cellPadding="2" width="80%" index={0}>
                                <tbody>
                                    <tr>
                                        <td width="50%">
                                            <LookupField
                                                id="BursaryInstitutionID"
                                                key={4}
                                                parentId={4}
                                                label="Name of University/University of Technology or TVET College"
                                                className="w-100"
                                                form={form}
                                                disabled={true}
                                            >
                                                <BursaryUniversityOrTVETSearch showTitle={true} mode='lookup' checkRefreshToken={!props.IsExternal} dataUrl="api/sims/BursaryApplicationEvaluation/GetBursaryUniversityOrTVET"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("BursaryInstitutionID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td width="50%">
                                            <LookupField
                                                id="QualificationID"
                                                key={4}
                                                parentId={4}
                                                label="Qualification"
                                                className="w-100"
                                                form={form}
                                                disabled={true}
                                            >
                                                <QualificationSearch showTitle={true} mode='lookup' checkRefreshToken={!props.IsExternal} dataUrl="api/sims/BursaryApplicationEvaluation/GetBursaryQualification"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("QualificationID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/BursaryApplicationEvaluation/GetLookupList?listId=SocioEconomicStatus"
                                                id="InitialSocioEconomicStatusID"
                                                parentId={2}
                                                key={2}
                                                label="Initial Application Socio Economic Status"
                                                className="w-100"
                                                form={form}
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={true} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/BursaryApplicationEvaluation/GetLookupList?listId=SocioEconomicStatus"
                                                id="ContinuationSocioEconomicStatusID"
                                                parentId={2}
                                                key={2}
                                                label="Continuation Application Socio Economic Status"
                                                className="w-100"
                                                form={form}
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={true} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/BursaryApplicationEvaluation/GetLookupList?listId=BursaryDiscipline"
                                                id="BursaryDisciplineID"
                                                key={2}
                                                label="Discipline"
                                                className="w-100"
                                                parentId={2}
                                                form={form}
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={true} />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="OtherBursaryDiscipline"
                                                key={1}
                                                parentId={1}
                                                label="Other Discipline"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="StudentNumber"
                                                key={1}
                                                parentId={1}
                                                label="Student Number"
                                                className="w-100"
                                                form={form}
                                                disabled={true}
                                            />
                                        </td>
                                        <td colSpan="2">
                                            <SelectItem
                                                dataUrl="api/sims/BursaryApplicationEvaluation/GetLookupList?listId=BursaryApplicationStatus"
                                                id="BursaryApplicationStatusID"
                                                key={2}
                                                parentId={2}
                                                label="Application Status"
                                                className="w-100"
                                                form={form}
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={true} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/BursaryApplicationEvaluation/GetLookupList?listId=BursaryFollowingYrYearOfStudy"
                                                id="FollowingYrYearOfStudyID"
                                                parentId={2}
                                                key={2}
                                                label="Year Of Study in Following Year"
                                                className="w-100"
                                                form={form}
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={true} />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="YearFirstAwardedBursary"
                                                key={1}
                                                parentId={1}
                                                label="In which year were you first awarded an MQA bursary?"
                                                className="w-100"
                                                form={form}
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td colSpan="2">
                                            <b><u>DECLARATION</u><br /><br />

                                            I, as the MQA Bursar by completing and submitting this form to MQA, understand and accept that for the 2021 academic year that:</b><br /><br />

                                            I note that the re-awarding of the bursary is based on my academic performance for the 2020 academic year and at the sole discretion of the MQA, in conjunction with the MQA Bursary Policy.<br />

                                            I will not be accepting additional financial assistances towards my studies for the 2021 academic year whilst I am an MQA bursar. I will advise MQA timeously should any payment be made towards my studies for the 2021 academic year by any other funder, including NSFAS. Failure to inform the MQA, will result in my bursary being cancelled.<br />

                                            I further authorise the MQA to access from my university/college all academic, financial information and other relevant information that has a bearing on the relationship between myself and the MQA; and<br />

                                            I also authorise the MQA to share my personal and academic information with other relevant authorities for reporting purposes.

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="DeclarationName"
                                                key={tabValue}
                                                label="Name"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={true}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="DeclarationSurname"
                                                key={tabValue}
                                                label="Surname"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <CustomDatePicker
                                                id="DeclarationDate"
                                                key={tabValue}
                                                label="Declaration Date"
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})


import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';



const utils = new ViewUtils();
export const OutComeStatusForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);

    return (
        <EntityForm ref={ref} viewId="CoachMentorOutComeStatusForm" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="CoachMentorOutComeStatusForm">
                            <table cellPadding="2" width="100%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl={"api/sims/" + props.controllerName + "/GetLookupList?listId=ProgramOutcome"}
                                                id="ProgramOutcomeID"
                                                key={tabValue}
                                                label="Outcome"
                                                className="w-100"
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl={"api/sims/" + props.controllerName + "/GetLookupList?listId=ActiveStatus"}
                                                id="ActiveStatusID"
                                                key={tabValue}
                                                label="Status"
                                                className="w-100"
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                            </tr>
                                </tbody>
                            </table>

                </form>
            }
        </EntityForm>
    );
})

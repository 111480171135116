import { withRouter } from 'react-router-dom';
import React, { Component } from "react";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "../../View.jsx";
import ViewUtils from '../../ViewUtils';
import EnhancedToolbar from '../../widgets/Toolbar';
import EnhancedTable from '../../widgets/ResponsiveTable';
import AlertItem from "../../widgets/AlertItem";
import Prompt from '../../widgets/Prompt';
import { StakeholderOrganisationDetailsForm } from './StakeholderOrganisationDetailsForm';
import FormDialog from "../../etqa/FormDialog";
import withMenuPermission from "../../widgets/withMenuPermission";
import LoadingIndicator from '../../LoadingIndicator';

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

const headCells = [
    { id: 'OrganisationName', numeric: false, disablePadding: true, label: 'Organisation Name' },
    { id: 'SDLNumber', numeric: false, disablePadding: true, label: 'SDL Number' },
    { id: 'FinancialYear', numeric: false, disablePadding: true, label: 'Financial Year' },
    { id: 'Status', numeric: false, disablePadding: true, label: 'Status' },
    { id: 'Document', numeric: false, disablePadding: true, label: 'Document', contentType: 'document' },
];

class StakeholderOrganisations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: {
                Id: this.props.location.state.selection
            },
            addDialogOpen: false,
            editDialogOpen: false,
            selectedId: null,
            searchParameters: [{ Name: "StakeholderID", Value: this.props.location.state.StakeholderID }],
            clearSelectedRow: false,
            promptOpen: false,
            promptTitle: "Remove Organisation",
            promptContent: "Are you sure you want to remove this Organisation?",
            promptFinishOpen: false,
            promptFinishTitle: "Finalize Application",
            promptFinishContent: "A pending login status indicated that the SETA needs to accept your registration before you are granted access to an organizations workplace skills plan.",
            showTable: true,
            StakeholderID: this.props.location.state.StakeholderID,
            IsExternal: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.IsExternal)) ? this.props.location.state.IsExternal : false,
            IsDashboard: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.IsDashboard)) ? this.props.location.state.IsDashboard : false
        }

        this.toolbarConfig = {
            items: [

                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', visible: !this.state.IsExternal, icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },

                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add Request', icon: 'AddIcon', visible: this.state.IsExternal || this.props.menuPermissionItem.Add },
                        { id: 'remove', label: '', tooltip: 'Remove Request', disabled: utils.isNull(this.state.selectedId), icon: 'DeleteIcon', visible: this.state.IsExternal || this.props.menuPermissionItem.Delete },
                        { id: 'finish', label: 'Finish', tooltip: 'Finalize Application', visible: this.state.IsExternal }
                    ]
                }
            ]
        }
    }


    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.setState({ addDialogOpen: true });

        }
        else if (buttonId === "close") {
            let Url = "/stakeholder";
            if (this.state.IsExternal) { Url = "/stakeholderRegistrationForm"; }
            if (this.state.IsDashboard) { Url = "/stakeholderdashboard"; }
            this.props.history.push({
                pathname: Url,
                state: { selection: this.props.location.state.StakeholderID }
            })
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
        else if ("finish" === buttonId) {
            this.setState({ promptFinishOpen: true })
        }
        else {
        }
    }

    Finalize() {
        this.setState({ promptFinishOpen: false })
        this.props.history.push({

            pathname: "/login",
            state: { selection: null }
        })
    }

    handleFormValueChange(values) {

        let newsearchParameters = [...this.state.searchParameters];

        this.setState({
            searchParameters: JSON.parse(JSON.stringify(newsearchParameters)),
        });
    }

    FileDownload = (rowid, headCell) => {
        if (headCell.contentType === "document") {
            //console.log(headCell);
            utils.invokeUrl(this.props.location.state.LookupUrl + "downloaddocument?id=" + rowid, (response) => {

                var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
                var url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = response.Name;
                link.target = '_blank';
                link.click();
            }, (e) => {
                this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
            }
            );
        }
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedId: selection[0].Id, Message: "", clearSelectedRow: false });
        }
        else {
            this.setState({ selectedId: null, Message: "", clearSelectedRow: false });
        }
        
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("edit", selection === null || selection.length === 0);
    }

    postAddHandler(cancel, save) {
        if (save === true) {
            this.setState({
                addDialogOpen: false,
                selectedId: null,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                showTable: false,
                clearSelectedRow: true
            }, () => {
                this.setState({ showTable: true })
            });
        }
        else if (cancel === true) {
            this.setState({
                addDialogOpen: false,
                selectedId: null,
                Message: "",
                MessageStyle: "",
                clearSelectedRow: true
            })
        }
        else {
            this.setState({
                addDialogOpen: false,
                selectedId: null,
                clearSelectedRow: true
            })
        }
    }

    remove(url) {
        this.setState({ promptOpen: false, clearSelectedRow: true, selectedId: null }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedId}`;
        if (this.state.IsExternal) {
            utils.invokeUrlDeleteExternal(fullUrl, this.successfulRemoveCallback, this.unsuccessfulRemoveCallback);
        }
        else {
            utils.invokeUrlDelete(fullUrl, this.successfulRemoveCallback, this.unsuccessfulRemoveCallback);
        }
    }

    closePrompt = () => {
        this.setState({ promptOpen: false, clearSelectedRow: true, selectedId: null })
    }

    successfulRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedId: null, Message: response.Message, MessageStyle: response.MessageType.toLowerCase(),
                clearSelectedRow: true
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ Message: response.Message, MessageStyle: response.MessageType.toLowerCase() })
        }
    }

    onClearSelectedRow = () => {
        this.setState({ clearSelectedRow: false });
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                {this.state.IsExternal ? <LoadingIndicator /> : null}
                <p className="breadcrumbs">
                    {"Stakeholder Details > Organisations Details > " + (this.props.location.state.stakeholderName !== null ? this.props.location.state.stakeholderName + " - " + this.props.location.state.IDNo : "")}
                </p>

                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />

                <div className="searchResults">

                    <EnhancedToolbar highlight={false}
                        title="Organisations"
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig}
                    />
                    {this.state.showTable === true && (
                        <EnhancedTable
                            headCells={headCells}
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            dataUrl={this.props.location.state.LookupUrl + "GetStakeholderOrganisation"}
                            searchParameters={this.state.searchParameters}
                            onCellSelectionChange={(rowid, headCell) => { this.FileDownload(rowid, headCell) }}
                            clearSelectedRow={this.state.clearSelectedRow} onClearSelectedRow={this.onClearSelectedRow}
                            paged={true}
                            checkRefreshToken={!this.state.IsExternal}
                        />
                    )}
                </div>

                <FormDialog
                    open={this.state.addDialogOpen}
                    dialogTitle={"Link Organisation"}
                    viewRef={this}
                    entityInfo={{ StakeholderID: this.props.location.state.StakeholderID }}
                    formRef={addFormRef}
                    saveUrl={this.props.location.state.LookupUrl + "AddStakeholderOrganisation"}
                    usePost={true}
                    closeHandler={(cancel, save) => this.postAddHandler(cancel, save)}
                    IsExternal={this.state.IsExternal}
                >
                    <StakeholderOrganisationDetailsForm ref={addFormRef} valueChangeHandler={(values) => this.handleFormValueChange(values)} open={this.state.addDialogOpen} StakeholderID={this.props.location.state.StakeholderID}
                        LookupUrl={this.props.location.state.LookupUrl}
                        IsExternal={this.state.IsExternal}
                    />
                </FormDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.remove(this.props.location.state.LookupUrl + "RemoveStakeholderOrganisation") }}
                    closePrompt={this.closePrompt}
                />

                <Prompt open={this.state.promptFinishOpen}
                    promptTitle={this.state.promptFinishTitle}
                    promptContent={this.state.promptFinishContent}
                    handleOk={() => { this.Finalize() }}
                    okPrompt={true}
                />

            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(StakeholderOrganisations));
import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { AddressCodeItem } from '../../widgets/AddressCodeItem';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { makeStyles } from '@material-ui/core/styles';
import { LookupField } from '../../widgets/LookupField';
import BursaryQualificationSearch from '../program/BursaryQualificationSearch';
import BursaryInstitutionSearch from '../program/BursaryInstitutionSearch';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    MuiSelectSelectMenu: {
        '& .MuiSelect-selectMenu': { paddingRight: "150px !important" }
    }
}));

const utils = new ViewUtils();
export const BursaryApplicationEmployedForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [bursaryNSFASFlag, setBursaryNSFASFlag] = React.useState(null);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function OtherDisciplineCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Other") {
                return true
            }
            else {
                return false
            }
        }
    }

    const classes = useStyles();

    return (
        <EntityForm ref={ref} viewId="bursaryapplication" values={props.data} dataURL='api/sims/BursaryApplication/getBursaryApplicationDetails'
            entityLookupController={props.entityLookupController} org="saseta" IsExternal={props.IsExternal}
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="BursaryApplicationEmplyedForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Personal Details of Applicant" wrapped {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                                <Tab label="Tertiary Education: 2025 Year" wrapped {...a11yProps(1)} style={{ color: form.fieldParentErrors[1] === true ? "red" : null }} />
                                <Tab label="Declaration" wrapped {...a11yProps(2)} style={{ color: form.fieldParentErrors[2] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel className="w-100" value={tabValue} index={0}>
                            <table cellPadding="2" width="80%" index={0}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="EmployerName"
                                                required
                                                key={1}
                                                parentId={1}
                                                label="Name of Employer"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="EmployerSDLNumber"
                                                required
                                                key={1}
                                                parentId={1}
                                                label="Employer SDL Number"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="EmployerAddress"
                                                required
                                                key={1}
                                                parentId={1}
                                                label="Employer Address"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="EmployerStreet"
                                                required
                                                key={1}
                                                parentId={1}
                                                label="Employer Street"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="EmployerArea"
                                                required
                                                key={1}
                                                parentId={1}
                                                label="Employer Area"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="EmployerCityTown"
                                                required
                                                key={1}
                                                parentId={1}
                                                label="Employer City/Town"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <CustomTextField
                                                id="EmployerPostalCode"
                                                required
                                                key={1}
                                                parentId={1}
                                                label="Employer Postal Code"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ImmediateSupervisorName"
                                                required
                                                key={1}
                                                parentId={1}
                                                label="Name (Immediate Supervisor)"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ImmediateSupervisorSurname"
                                                required
                                                key={1}
                                                parentId={1}
                                                label="Surname (Immediate Supervisor)"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ImmediateSupervisorContactPhone"
                                                required
                                                key={1}
                                                parentId={1}
                                                label="Contact Telephone (Immediate Supervisor)"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ImmediateSupervisorContactEmail"
                                                required
                                                key={1}
                                                parentId={1}
                                                label="Email Address (Immediate Supervisor)"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td colSpan="2">
                                            <b>SECTION B - TERTIARY EDUCATION: 2025 YEAR</b><br /><br />
                                            <b>Name of University or TVET College which you have applied to or will continue to study at during 2025 academic year as well as your qualification</b>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="50%">
                                            <LookupField
                                                id="BursaryInstitutionID"
                                                required
                                                key={4}
                                                parentId={4}
                                                label="Name of Institution"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            >
                                                <BursaryInstitutionSearch showTitle={true} mode='lookup' IsExternal={true} dataUrl="api/sims/BursaryApplication/GetBursaryInstitution"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("BursaryInstitutionID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td width="50%">
                                            <LookupField
                                                id="QualificationID"
                                                required
                                                key={4}
                                                parentId={4}
                                                label="Qualification"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            >
                                                <BursaryQualificationSearch showTitle={true} mode='lookup' IsExternal={true} dataUrl="api/sims/BursaryApplication/GetBursaryQualification"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("QualificationID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/BursaryApplication/GetLookupList?listId=BursaryDiscipline"
                                                id="BursaryDisciplineID"
                                                key={2}
                                                label="Discipline"
                                                className="w-100"
                                                required={true}
                                                parentId={2}
                                                form={form}
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={!props.editPermission} />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="OtherBursaryDiscipline"
                                                key={1}
                                                parentId={1}
                                                required={OtherDisciplineCheck(form.values["BursaryDisciplineID"])}
                                                label="Other Discipline"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/BursaryApplication/GetLookupList?listId=BursaryQualificationType"
                                                id="BursaryQualificationTypeID"
                                                key={2}
                                                label="Qualification Type"
                                                className="w-100"
                                                required={true}
                                                parentId={2}
                                                form={form}
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={!props.editPermission} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/BursaryApplication/GetLookupList?listId=BursaryFollowingYrYearOfStudy"
                                                id="FollowingYrYearOfStudyID"
                                                required={true}
                                                parentId={2}
                                                key={2}
                                                label="Year Of Study in Following Year"
                                                className="w-100"
                                                form={form}
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={!props.editPermission} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            {/*<SelectItem*/}
                                                {/*dataUrl="api/sims/BursaryApplication/GetLookupList?listId=BursaryApplicationStatus"*/}
                                                {/*id="BursaryApplicationStatusID"*/}
                                                {/*key={2}*/}
                                                {/*parentId={2}*/}
                                                {/*required={true}*/}
                                                {/*label="Application Status"*/}
                                                {/*className="w-100"*/}
                                                {/*form={form}*/}
                                                {/*checkRefreshToken={!props.IsExternal}*/}
                                                {/*disabled={!props.editPermission} />*/}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td colSpan="2">
                                            <b><u>SECTION D: DECLARATION</u></b><br /><br />

                                            By submitting this completed form, I confirm that all the information provided is <b>TRUE AND CORRECT</b>, and I am aware that providing incorrect information and documents will result in the disqualification of my application. I also acknowledge that the awarding of a bursary is at the absolute sole discretion of the MQA, and the MQA reserves the right not to award me the bursary. I further authorise the MQA to access, from the university/ university of technology/ TVET College, all necessary academic, financial and any other relevant information that has a bearing on this application and continue to access the said information/documents should my bursary application be successful. The MQA may also share my personal information with all relevant organisations/institutions for reporting purposes.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="DeclarationName"
                                                key={tabValue}
                                                label="Name"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="DeclarationSurname"
                                                key={tabValue}
                                                label="Surname"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <CustomDatePicker
                                                id="DeclarationDate"
                                                key={tabValue}
                                                label="Declaration Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})


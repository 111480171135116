import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import LinkDialog from "../LinkDialog";
import Prompt from '../../widgets/Prompt';
import LearnershipSetupSearch from '../program/LearnershipSetupSearch';
import AlertItem from "../../widgets/AlertItem";

export const learnershipHeadCells = [
    { id: 'LearnershipCode', numeric: false, disablePadding: true, label: 'Learnership Code' },
    { id: "LearnershipTitle", numeric: false, disablePadding: true, label: "Learnership Title" },
    { id: "AssessorAssessmentEndDate", numeric: false, disablePadding: true, label: "Assessor Assessment End Date" }
   
]

const utils = new ViewUtils();
const toolbarRef = React.createRef();

class AssessorLearnershipSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                LearnershipCode: "",
                LearnershipTitle: "",
                AssessorID: this.props.location.state.AssessorID
            },

            linkDialogOpen: false,
            selectedLearnership: null,
            propmtOpen: false,
            promptTitle: "Unlink Learnership From moderator",
            promptContent: "Are you sure you want to unlink this learnership from the moderator?",
            unlinkMessage: "",
            unlinkMessageStyle: "",
            searchParameters: [{ Name: "moderatorID", Value: this.props.location.state.AssessorID}]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'link', label: 'Link moderator', tooltip: 'link the selected learnership(s) to the moderator' },
                        { id: 'unlink', label: 'Unlink moderator', tooltip: 'Un-link the selected learnership(s) from the moderator', disabled: utils.isNull(this.state.selectedQualification) }
                    ]
                }
            ]
        };

    }

    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/assessor',
                state: { selection: this.props.location.state.AssessorID }
            });
        }
        else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, unlinkMessage: "" })
        }
        else if ("unlink" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    unlinkQualification(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("unlink", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedLearnership}&assessorId=${this.props.location.state.AssessorID}`
        fetch(fullUrl)
            .then(res => res.json())
            .then((responseJson) => {
                let response = JSON.parse(responseJson);
                if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
                    this.setState({ selectedQualification: null, unlinkMessage: response.Message, unlinkMessageStyle: response.MessageType.toLowerCase() });
                    //Force a refresh
                    this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
                }
                else if (!utils.isNull(response.Message) && response.Message !== "") {
                    this.setState({ unlinkMessage: response.Message, unlinkMessageStyle: response.MessageType.toLowerCase() })
                }
            });
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                linkDialogOpen: false, selectedQualification: null,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters))
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedQualification: selection[0].Id });
        }
        else {
            this.setState({ selectedQualification: null });
        }
        toolbarRef.current.setButtonDisabled("unlink", selection === null || selection.length === 0);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    render() {
        return(
            <ViewContainer mode={this.props.mode} title={`Assessors > ${this.props.location.state.AssessorName} > Qualifications`}>

                <AlertItem message={this.state.unlinkMessage} alertStyle={this.state.unlinkMessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        ref={toolbarRef}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />
                    <EnhancedTable
                        headCells={learnershipHeadCells}
                        dataUrl={`api/indicium/QualificationSetup/getAssessorQualification`}
                        height="50vh"
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        searchParameters={this.state.searchParameters} paged={false} />
                </div>

                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title={this.props.location.state.qualificationTitle}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={`Link Qualification to Assessor: ${this.props.location.state.AssessorName}`}
                    linkName={"Qualifications"} postUrl={"api/indicium/QualificationSetup/linkAssessorQualification"}
                    selectionId={this.props.location.state.AssessorID}
                >
                    <LearnershipSetupSearch //TODO: Enter dataurl prop for linkable qualifications
                        dataUrl={"api/indicium/QualificationSetup/getLinkableAssessorQualification"}
                        multiSelect={true} showTitle={false}
                        programmeId={this.props.location.state.AssessorID} programmeType="Q"
                        mode="lookup"
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => this.unlinkQualification(`api/indicium/QualificationSetup/unlinkAssessorQualification`)}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}


export default withRouter(
    connect()(AssessorLearnershipSearch)
);
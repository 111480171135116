import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EmployerSearch from "./etqa/employer/EmployerSearch";
import Employer from "./etqa/employer/Employer";
import AssessorSearch from "./etqa/assessor/AssessorSearch";
import AuditTrial from "./etqa/assessor/AuditTrial";
import Assessor from "./etqa/assessor/Assessor";
import BDASearch from "./etqa/bda/BDASearch";
import PersonSearch from "./etqa/person/PersonSearch";
import LearnerSearch from "./etqa/learner/LearnerSearch";
import Learner from "./etqa/learner/Learner";
import LearnerCRM from "./etqa/learner/LearnerCRM";
import LearnerProgrammeGrants from "./etqa/learner/LearnerProgrammeGrants";
import Person from "./etqa/person/Person";
import LearnerMineCommunitySearch from "./etqa/learner/LearnerMineCommunity";
import LearnerMineCommunityDocumentSearch from "./etqa/learner/LearnerMineCommunityDocumentSearch";
import LearnerSmallScaleSearch from "./etqa/learner/LearnerSmallScale";
import LearnerSmallScaleDocumentSearch from "./etqa/learner/LearnerSmallScaleDocumentSearch";
import LearnerSkillsProgrammeSearch from "./etqa/learner/LearnerSkillsProgram";
import LearnerBursarySearch from "./etqa/learner/LearnerBursary";
import LearnerBursaryDocumentSearch from "./etqa/learner/LearnerBursaryDocumentSearch";
import LearnerUnitStandardSearch from "./etqa/learner/LearnerUnitStandard";
import LearnerMEDPSearch from "./etqa/learner/LearnerMEDP";
import LearnerMEDPDocumentSearch from "./etqa/learner/LearnerMEDPDocumentSearch";
import LearnerCandidacySearch from "./etqa/learner/LearnerCandidacy";
import LearnerCandidacyDocumentSearch from "./etqa/learner/LearnerCandidacyDocumentSearch";
import LearnerHETLecturerSupportSearch from "./etqa/learner/LearnerHETLecturerSupport";
import LearnerHETLecturerSupportDocumentSearch from "./etqa/learner/LearnerHETLecturerSupportDocumentSearch";
import LearnerWorkExperienceSearch from "./etqa/learner/LearnerWorkExperience";
import LearnerWorkExperienceDocumentSearch from "./etqa/learner/LearnerWorkExperienceDocumentSearch";
import LearnerWorkplaceCoachesSearch from "./etqa/learner/LearnerWorkplaceCoaches";
import LearnerWorkplaceCoachesDocumentSearch from "./etqa/learner/LearnerWorkplaceCoachesDocumentSearch";
import LearnerUnemployedYouthDevelopmentSearch from "./etqa/learner/LearnerUnemployedYouthDevelopment";
import LearnerUnemployedYouthDevelopmentDocumentSearch from "./etqa/learner/LearnerUnemployedYouthDevelopmentDocumentSearch";
import LearnerTVETNCVSearch from "./etqa/learner/LearnerTVETNCV";
import LearnerInternshipsSearch from "./etqa/learner/LearnerInternships";
import LearnerInternshipDocumentSearch from "./etqa/learner/LearnerInternshipDocumentSearch";
import LearnerSCPPISearch from "./etqa/learner/LearnerSCPPI";
import LearnerSCPPIDocumentSearch from "./etqa/learner/LearnerSCPPIDocumentSearch";
import LearnerAETSearch from "./etqa/learner/LearnerAET";
import LearnerAETAssessments from "./etqa/learner/LearnerAETAssessment";
import LearnerAETDocumentSearch from "./etqa/learner/LearnerAETDocumentSearch";
import LearnerFETSearch from "./etqa/learner/LearnerFET";
import LearnerQualificationSearch from "./etqa/learner/LearnerQualification";
import LearnerQualificationUSSearch from "./etqa/learner/LearnerQualificationUSSearch";
import LearnerTradeSearch from "./etqa/learner/LearnerTrade";
import LearnerTradeAssessments from "./etqa/learner/LearnerTradeAssessment";
import LearnerTVETNCVAssessments from "./etqa/learner/LearnerTVETNCVAssessment";
import LearnerLearnershipSearch from "./etqa/learner/LearnerLearnership";
import LearnerLearnershipAchievements from "./etqa/learner/LearnerLearnershipAchievement";
import LearnerLearnershipDocumentSearch from "./etqa/learner/LearnerLearnershipDocumentSearch";
import LearnerUnitStandardAchievement from "./etqa/learner/LearnerUnitStandardAchievement";
import LearnerQualificationAchievement from "./etqa/learner/LearnerQualificationAchievement";
import LearnerSkillsProgrammeAchievement from "./etqa/learner/LearnerSkillsProgrammeAchievement";
import LearnerMineCommunityAchievement from "./etqa/learner/LearnerMineCommunityAchievement";
import LearnerSmallScaleAchievement from "./etqa/learner/LearnerSmallScaleAchievement";
import LearnerUnemployedYouthDevelopmentAchievement from "./etqa/learner/LearnerUnemployedYouthDevelopmentAchievement";
import LearnerLearnershipAssessments from "./etqa/learner/LearnerLearnershipAssessment";
import ViewLearnershipAchievementUS from "./etqa/learner/ViewLearnershipAchievementUS";
import ViewSkillsProgrammeAchievementUS from "./etqa/learner/ViewSkillsProgrammeAchievementUS";
import ViewMineCommunityAchievementUS from "./etqa/learner/ViewMineCommunityAchievementUS";
import ViewSmallScaleAchievementUS from "./etqa/learner/ViewSmallScaleAchievementUS";
import ViewQualificationAchievementUS from "./etqa/learner/ViewQualificationAchievementUS";
import ViewUnemployedYouthDevelopmentAchievementUS from "./etqa/learner/ViewUnemployedYouthDevelopmentAchievementUS";
import ViewLearnershipAssessment from "./etqa/learner/ViewLearnershipAssessment";
import ViewSkillsProgrammeAssessment from "./etqa/learner/ViewSkillsProgrammeAssessment";
import ViewUnemployedYouthDevelopmentAssessment from "./etqa/learner/ViewUnemployedYouthDevelopmentAssessment";
import ViewQualificationAssessment from "./etqa/learner/ViewQualificationAssessment";
import ViewUnitStandardAssessment from "./etqa/learner/ViewUnitStandardAssessment";
import ViewTradeAssessment from "./etqa/learner/ViewTradeAssessment";
import ViewTVETNCVAssessment from "./etqa/learner/ViewTVETNCVAssessment";
import ViewAETAssessment from "./etqa/learner/ViewAETAssessment";
import ViewTradeTest from "./etqa/learner/ViewTradeTest";
import ViewTVETNCVTradeTest from "./etqa/learner/ViewTVETNCVTradeTest";
import ViewMineCommunityAssessment from "./etqa/learner/ViewMineCommunityAssessment";
import ViewSmallScaleAssessment from "./etqa/learner/ViewSmallScaleAssessment";
import ViewNAMB from "./etqa/learner/ViewNAMB";
import ViewTVETNCVNAMB from "./etqa/learner/ViewTVETNCVNAMB";
import LearnerUnitStandardAssessment from "./etqa/learner/LearnerUnitStandardAssessment";
import LearnerQualificationAssessment from "./etqa/learner/LearnerQualificationAssessment";
import LearnerSkillsProgrammeAssessment from "./etqa/learner/LearnerSkillsProgrammeAssessment";
import LearnerMineCommunityAssessment from "./etqa/learner/LearnerMineCommunityAssessment";
import LearnerSmallScaleAssessment from "./etqa/learner/LearnerSmallScaleAssessment";
import LearnerUnemployedYouthDevelopmentAssessment from "./etqa/learner/LearnerUnemployedYouthDevelopmentAssessment";
import LearnerSkillsProgrammeDocumentSearch from "./etqa/learner/LearnerSkillsProgrammeDocumentSearch";
import Moderator from "./etqa/moderator/Moderator";
import ModeratorSearch from "./etqa/moderator/ModeratorSearch";
import ModeratorSkillsProgramme from "./etqa/moderator/ModeratorSkillsProgramme";
import ModeratorQualification from "./etqa/moderator/ModeratorQualification";
import ModeratorTradeQualification from "./etqa/moderator/ModeratorTradeQualification";
import Moderatorlearnership from "./etqa/moderator/ModeratorLearnerships";
import ModeratorUnitStandards from "./etqa/moderator/ModeratorUnitStandards";
import ModeratorProviderSearch from "./etqa/moderator/ModeratorProviders";
import ModeratorEmployerSearch from "./etqa/moderator/ModeratorEmployer";
import ModeratorRegistrationDetails from "./etqa/moderator/ModeratorRegistrationDetails";
import AssessorSkillsProgrammeSearch from "./etqa/assessor/AssessorSkillsProgramme";
import AssessorQualificationSearch from "./etqa/assessor/AssessorQualifications";
import AssessorTradeQualificationSearch from "./etqa/assessor/AssessorTradeQualifications";
import AssessorLearnershipSearch from "./etqa/assessor/AssessorLearnerships";
import AssessorUnitStandardSearch from "./etqa/assessor/AssessorUnitStandards";
import AssessorLearnershipUSSearch from "./etqa/assessor/AssessorLearnershipUSSearch";
import ModeratorRegistrationHistory from "./etqa/moderator/RegistrationHistory";
import ModeratorCRM from "./etqa/moderator/ModeratorCRM";
import UploadPerson from "./etqa/bulkupload/uploadPerson";
import UploadLearnership from "./etqa/bulkupload/uploadLearnership";
import UploadSkills from "./etqa/bulkupload/uploadSkills";
import UploadBursaries from "./etqa/bulkupload/uploadBursaries";
import UploadInternship from "./etqa/bulkupload/uploadInternship";
import UploadAET from "./etqa/bulkupload/uploadAET";
import UploadCandidacy from "./etqa/bulkupload/uploadCandidacy";
import UploadFET from "./etqa/bulkupload/uploadFET";
import UploadQualification from "./etqa/bulkupload/uploadQualification";
import UploadUnitStandard from "./etqa/bulkupload/uploadUnitStandard";
import UploadAchievement from "./etqa/bulkupload/uploadAchievement";
import AssessorRegistrationHistory from "./etqa/assessor/RegistrationHistory";
import LearnerSkillsProgram from "./etqa/learner/LearnerSkillsProgram";
import AddLearnerMineCommunity from "./etqa/learner/AddLearnerMineCommunity";
import AddLearnerSmallScale from "./etqa/learner/AddLearnerSmallScale";
import AddLearnerSkillsProgram from "./etqa/learner/AddLearnerSkillsProgram";
import AddLearnerBursary from "./etqa/learner/AddLearnerBursary";
import AddLearnerInternships from "./etqa/learner/AddLearnerInternships";
import AddLearnerWorkExperience from "./etqa/learner/AddLearnerWorkExperience";
import AddLearnerLearnership from "./etqa/learner/AddLearnerLearnership";
import AddLearnerQualification from "./etqa/learner/AddLearnerQualification";
import AddLearnerUnitStandard from "./etqa/learner/AddLearnerUnitStandard";
import AddLearnerSCPPI from "./etqa/learner/AddLearnerSCPPI";
import AddLearnerAET from "./etqa/learner/AddLearnerAET";
import AddLearnerFET from "./etqa/learner/AddLearnerFET";
import AddLearnerMEDP from "./etqa/learner/AddLearnerMEDP";
import AddLearnerCandidacy from "./etqa/learner/AddLearnerCandidacy";
import AddLearnerHETLecturerSupport from "./etqa/learner/AddLearnerHETLecturerSupport";
import AddLearnerTrade from "./etqa/learner/AddLearnerTrade";
import AddLearnerWorkplaceCoaches from "./etqa/learner/AddLearnerWorkplaceCoaches";
import AddLearnerTVETNCV from "./etqa/learner/AddLearnerTVETNCV";
import LearnerTVETNCVDocumentSearch from "./etqa/learner/LearnerTVETNCVDocumentSearch";
import AddLearnerUnemployedYouthDevelopment from "./etqa/learner/AddLearnerUnemployedYouthDevelopment";
import LearnerTradeDocumentSearch from "./etqa/learner/LearnerArtisanDocumentSearch";
import AssessorUnitStandards from "./etqa/assessor/AssessorUnitStandards";
import AssessorProviderSearch from "./etqa/assessor/AssessorProvider";
import AssessorEmployerSearch from "./etqa/assessor/AssessorEmployer";
import AssessorRegistrationDetails from "./etqa/assessor/AssessorRegistrationDetails";
import AssessorCRM from "./etqa/assessor/AssessorCRM";
import AssessorSDProviderSearch from "./etqa/assessor/AssessorSDProvider";
import EmployerCRM from "./etqa/employer/EmployerCRM";

import OrganisationCRM from "./skills/organisation/OrganisationCRM";

import AddEditAllocation from "./etqa/grants/grantallocation/AddEditAllocation";
import GrantAllocationSearch from "./etqa/grants/grantallocation/GrantAllocationSearch";
import GrantBudgetSearch from "./etqa/grants/grantbudget/GrantBudgetSearch";
import GrantSetupSearch from "./etqa/grants/grantsetup/GrantSetupSearch";
import AddEditTranche from "./etqa/grants/grantsetup/AddEditTranche";
import TrancheDetails from "./etqa/grants/grantsetup/TrancheDetails";
import ProcessingNav from "./etqa/grants/grantprocessing/ProcessingNav";
import GenerateClaimSearch from "./etqa/grants/grantprocessing/GenerateClaimSearch";
import ApproveClaimSearch from "./etqa/grants/grantprocessing/ApproveClaimSearch";
import GrantClaimFormReversalSearch from "./etqa/grants/grantprocessing/GrantClaimFormReversalSearch";
import DownloadClaimSearch from "./etqa/grants/grantprocessing/DownloadClaimSearch";
import GenerateBatchSearch from "./etqa/grants/grantprocessing/GenerateBatchSearch";
import BatchSearch from "./etqa/grants/grantprocessing/BatchSearch";
import DownloadBatchSearch from "./etqa/grants/grantprocessing/DownloadBatchSearch";
import AXProcessorSearch from "./etqa/grants/grantprocessing/AXProcessorSearch";
import GrantReversalsSearch from "./etqa/grants/grantprocessing/GrantReversalsSearch";
import BulkGrantAssociation from "./etqa/grants/grantassociations/BulkGrantAssociation";
import BulkGrantDeAssociation from "./etqa/grants/grantassociations/BulkGrantDiAssociation";

import ProviderCRM from "./etqa/provider/ProviderCRM";
import AssessorIDDocument from "./etqa/assessor/AssessorIDDocument";

import OnlineRegistrationTypeSelect from "./etqa/onlineRegistration/OnlineRegistrationTypeSelect";
import OnlineRegistrationSecondryTypeSelect from "./etqa/onlineRegistration/OnlineRegistrationSecondryTypeSelect";
import AddOnlineAssessorFlow from "./etqa/onlineRegistration/assessor/AddOnlineAssessorFlow";
import OnlineAssessorApprovalSearch from "./etqa/onlineRegistration/assessor/OnlineAssessorApprovalSearch";
import AssessorApproval from "./etqa/onlineRegistration/assessor/AssessorApproval";
import OnlineAssessorApprovalQualificationScope from "./etqa/onlineRegistration/assessor/OnlineAssessorApprovalQualificationScope";
import OnlineAssessorApprovalSkillsProgrammeScope from "./etqa/onlineRegistration/assessor/OnlineAssessorApprovalSkillsProgrammeScope";
import OnlineAssessorApprovalDocuments from "./etqa/onlineRegistration/assessor/OnlineAssessorApprovalDocuments";
import OnlineAssessorDetailsVerification from "./etqa/onlineRegistration/assessor/OnlineAssessorDetailsVerification";

import AddOnlineModeratorFlow from "./etqa/onlineRegistration/moderator/AddOnlineModeratorFlow";
import OnlineModeratorApprovalSearch from "./etqa/onlineRegistration/moderator/OnlineModeratorApprovalSearch";
import ModeratorApproval from "./etqa/onlineRegistration/moderator/ModeratorApproval";
import OnlineModeratorApprovalQualificationScope from "./etqa/onlineRegistration/moderator/OnlineModeratorApprovalQualificationScope";
import OnlineModeratorApprovalSkillsProgrammeScope from "./etqa/onlineRegistration/moderator/OnlineModeratorApprovalSkillsProgrammeScope";
import OnlineModeratorApprovalDocuments from "./etqa/onlineRegistration/moderator/OnlineModeratorApprovalDocuments";
import OnlineModeratorDetailsVerification from "./etqa/onlineRegistration/moderator/OnlineModeratorDetailsVerification";

import ProviderSearch from "./etqa/provider/ProviderSearch";
import Provider from "./etqa/provider/Provider";
import Outcome from "./etqa/program/Outcome";
import ProviderMonitoringAndEvaluation from "./etqa/provider/ProviderMonitoringAndEvaluation";
import ProviderTrade from "./etqa/provider/ProviderTrade";
import ProviderContact from "./etqa/provider/ProviderContact";
import ProviderUsers from "./etqa/provider/ProviderUsers";
import ProviderSiteVisitsSearch from "./etqa/provider/ProviderSiteVisits";
import ProviderAccreditation from "./etqa/provider/ProviderAccreditation";
import ProviderAccreditationHistory from "./etqa/provider/ProviderAccreditationHistory";
import ProviderLearner from "./etqa/provider/ProviderLearner";
import ProviderUnitStandards from "./etqa/provider/ProviderUnitStandards";
import ProviderQualificationSearch from "./etqa/provider/ProviderQualificationsSearch";
import ProviderTradeQualificationSearch from "./etqa/provider/ProviderTradeQualificationsSearch";
import ProviderSkillsProgrammeSearch from "./etqa/provider/ProviderSkillsProgrammeSearch";
import ProviderSkillsProgrammeUSSearch from "./etqa/provider/ProviderSkillsProgrammeUSSearch";
import ProviderLearnershipUSSearch from "./etqa/provider/ProviderLearnershipUSSearch";
import ProviderQualificationUSSearch from "./etqa/provider/ProviderQualificationUSSearch";
import ProviderLearnershipSearch from "./etqa/provider/ProviderLearnershipSearch";
import ProviderAssessorSearch from "./etqa/provider/ProviderAssessors";
import ProviderModeratorSearch from "./etqa/provider/ProviderModeratorSearch";
import ExternalProviderSearch from "./etqa/externalmoderator/ExternalModeratorSearch";
import UnitStandardSetupSearch from "./etqa/program/UnitStandardSetupSearch";
import UnitStandardSetup from "./etqa/program/UnitStandardSetup";
import UnitStandardDocumentSearch from "./etqa/program/UnitStandardDocumentSearch";
import QualificationSetupSearch from "./etqa/program/QualificationSetupSearch";
import QualificationSetup from "./etqa/program/QualificationSetup";
import QualificationDocumentSearch from "./etqa/program/QualificationDocumentSearch";
import QualificationLearnershipSearch from "./etqa/program/QualificationLearnershipSearch";
import QualificationSkillsProgrammeSearch from "./etqa/program/QualificationSkillsProgrammeSearch";
import LearnershipSetupSearch from "./etqa/program/LearnershipSetupSearch";
import ModuleSetupSearch from "./etqa/program/Module/ModuleSetupSearch";
import ModuleDocumentSearch from "./etqa/program/Module/ModuleDocumentSearch";
import ModuleSetup from "./etqa/program/Module/ModuleSetup";
import TradeQualificationSetupSearch from "./etqa/program/TradeQualification/TradeQualificationSetupSearch";
import TradeQualificationSetup from "./etqa/program/TradeQualification/TradeQualificationSetup";
import TradeQualificationDocumentSearch from "./etqa/program/TradeQualification/TradeQualificationDocumentSearch";
import SCPPISetupSearch from "./etqa/program/SCPPI/SCPPISetupSearch";
import SCPPISetup from "./etqa/program/SCPPI/SCPPISetup";
import SCPPIDocumentSearch from "./etqa/program/SCPPI/SCPPIDocumentSearch";
import AETSetupSearch from "./etqa/program/AET/AETSetupSearch";
import AETSetup from "./etqa/program/AET/AETSetup";
import AETLearningAreaSearch from "./etqa/program/AET/AETLearningAreaSearch";
import AETDocumentSearch from "./etqa/program/AET/AETDocumentSearch";
import InternshipSetupSearch from "./etqa/program/Internship/InternshipSetupSearch";
import InternshipSetup from "./etqa/program/Internship/InternshipSetup";
import InternshipDocumentSearch from "./etqa/program/Internship/InternshipDocumentSearch";
import WorkplaceCoachesSetupSearch from "./etqa/program/WorkplaceCoaches/WorkplaceCoachesSetupSearch";
import WorkplaceCoachesSetup from "./etqa/program/WorkplaceCoaches/WorkplaceCoachesSetup";
import WorkplaceCoachesDocumentSearch from "./etqa/program/WorkplaceCoaches/WorkplaceCoachesDocumentSearch";
import CandidacySetupSearch from "./etqa/program/Candidacy/CandidacySetupSearch";
import CandidacySetup from "./etqa/program/Candidacy/CandidacySetup";
import CandidacyDocumentSearch from "./etqa/program/Candidacy/CandidacyDocumentSearch";
import HETLecturerSupportSetupSearch from "./etqa/program/HETLecturerSupport/HETLecturerSupportSetupSearch";
import HETLecturerSupportSetup from "./etqa/program/HETLecturerSupport/HETLecturerSupportSetup";
import HETLecturerSupportDocumentSearch from "./etqa/program/HETLecturerSupport/HETLecturerSupportDocumentSearch";
import MEDPSetupSearch from "./etqa/program/MEDP/MEDPSetupSearch";
import MEDPSetup from "./etqa/program/MEDP/MEDPSetup";
import MEDPDocumentSearch from "./etqa/program/MEDP/MEDPDocumentSearch";
import WorkExperienceSetupSearch from "./etqa/program/WorkExperience/WorkExperienceSetupSearch";
import WorkExperienceSetup from "./etqa/program/WorkExperience/WorkExperienceSetup";
import WorkExperienceDocumentSearch from "./etqa/program/WorkExperience/WorkExperienceDocumentSearch";
import LearningAreaSetupSearch from "./etqa/program/LearningArea/LearningAreaSetupSearch";
import LearningAreaSetup from "./etqa/program/LearningArea/LearningAreaSetup";
import TradeSetupSearch from "./etqa/program/Trade/TradeSetupSearch";
import TradeSetup from "./etqa/program/Trade/TradeSetup";
import TradeDocumentSearch from "./etqa/program/Trade/TradeDocumentSearch";
import TradeModuleSearch from "./etqa/program/Trade/TradeModuleSearch"; 
import LearnershipUnitStandardSearch from "./etqa/program/LearnershipUnitStandardSearch";
import LearnershipDocumentSearch from "./etqa/program/LearnershipDocumentSearch";
import LearnershipAgreementSearch from "./etqa/program/LearnershipAgreementSearch";
import LearnershipSetup from "./etqa/program/LearnershipSetup";
import SAQAXMLImport from "./etqa/program/SAQAXMLImport";
import ShortCourseSetupSearch from "./etqa/program/ShortCourseSetupSearch";
import SkillsProgramSetupSearch from "./etqa/program/SkillsProgramSetupSearch";
import SkillsProgramSetup from "./etqa/program/SkillsProgramSetup";
import SkillsProgrammeDocumentSearch from "./etqa/program/SkillsProgrammeDocumentSearch";
import ArtisansSetupSearch from "./etqa/program/ArtisansSetupSearch";
import EmployerLearnerSearch from "./etqa/employer/EmployerLearnerSearch";
import EmployerProviderSearch from "./etqa/employer/EmployerProviderSearch";
import EmployerLearnerProgrammes from "./etqa/employer/EmployerLearnerProgrammes";
import EmployerUserSearch from "./etqa/employer/EmployerUserSearch";
import EmployerContactSearch from "./etqa/employer/EmployerContactSearch";
import AddLearnerFlow from "./etqa/learner/AddLearnerFlow";
import AddPersonFlow from "./etqa/person/AddPersonFlow";
import AddAssessorFlow from "./etqa/assessor/AddAssessorFlow";
import AddModeratorFlow from "./etqa/moderator/AddModeratorFlow";
import Dashboard from "./widgets/Dashboard";
import SDFDashboard from "./Dashboards/SDFDashboard";
import StakeholderDashboard from "./Dashboards/StakeholderDashboard";
import SkillsDashboard from "./Dashboards/SkillsDashboard";
import { Route } from "react-router-dom";
import QualificationUnitStandardSearch from "./etqa/program/QualificationUnitStandardSearch";
import TradeQualificationModuleSearch from "./etqa/program/TradeQualification/TradeQualificationModuleSearch"; 
import SkillsUnitStandardSearch from "./etqa/program/SkillsUnitStandardSearch";
import SDFSearch from "./skills/sdf/SDFSearch";
import RoleMaintenanceSearch from "./maintenance/Role/RoleSearch";
import UserMaintenanceSearch from "./maintenance/User/UserSearch";
import UserDetailsMaintenanceSearch from "./maintenance/User/UserDetail";

import UserRoleSearch from "./maintenance/User/UserRoles";
import ClientsEmailsSearch from "./maintenance/Clients/ClientsEmailsSearch";
import EmailAdd from "./maintenance/Clients/EmailAdd";

import InstitutionSearch from "./maintenance/ETQA/Institution/InstitutionSearch";

import SkillsLetterCreationSearch from "./maintenance/Skills/SkillsLetterCreationSearch";
import SkillsLetterCreation from "./maintenance/Skills/SkillsLetterCreation";
import SkillsQueryReasonSearch from "./maintenance/Skills/SkillsQueryReasonSearch";
import SkillsQueryReason from "./maintenance/Skills/SkillsQueryReason";
import SkillsRejectionReasonSearch from "./maintenance/Skills/SkillsRejectionReasonSearch";
import SkillsRejectionReason from "./maintenance/Skills/SkillsRejectionReason";
import SkillsTNumberConversionSearch from "./maintenance/Skills/SkillsTNumberConversionSearch";
import SkillsTNumberConversion from "./maintenance/Skills/SkillsTNumberConversion";

import CreateBenefitsDescriptionSearch from "./maintenance/Benefits/CreateBenefitsDescriptionSearch";
import BenefitsDescriptionsSearch from "./maintenance/Benefits/BenefitsDescriptionsSearch";
import CreateInterventionTitles from "./maintenance/Benefits/CreateInterventionTitles";
import BenefitsTargetAddFormSearch from "./maintenance/Benefits/BenefitsTargetAddFormSearch";
import BenefitsTargetsSearch from "./maintenance/Benefits/BenefitsTargetsSearch";
import InterventionTitlesSearch from "./maintenance/Benefits/InterventionTitlesSearch";

import LinkingSearch from "./maintenance/Benefits/LinkingSearch";
import BenefitsUpload from "./maintenance/Benefits/BenefitsUpload";
import CreateLinkingSearch from "./maintenance/Benefits/CreateLinkingSearch";

import DGLetterCreation from "./maintenance/DG/DGLetterCreation";
import DGLetterCreationSearch from "./maintenance/DG/DGLetterCreationSearch";
import DGQueryReasonSearch from "./maintenance/DG/DGQueryReasonSearch";
import DGQueryReason from "./maintenance/DG/DGQueryReason";
import DGRejectionReasonSearch from "./maintenance/DG/DGRejectionReasonSearch";
import DGRejectionReason from "./maintenance/DG/DGRejectionReason";

import Role from "./maintenance/Role/Role";
import RolePermissionSearch from "./maintenance/Role/RolePermissionSearch";
import RolePermission from "./maintenance/Role/RolePermission";
import SDF from "./skills/sdf/SDF";
import SDFOrganisationDetails from "./skills/sdf/SDFOrganisations";

import DGOrganisationSearch from "./dg/organisation/DGOrganisationSearch";
import DGOrganisation from "./dg/organisation/DGOrganisation";
import StakeholderSearch from "./dg/stakeholder/StakeholderSearch";
import Stakeholder from "./dg/stakeholder/Stakeholder";
import StakeholderOrganisationDetails from "./dg/stakeholder/StakeholderOrganisationDetails";
import DGOrganisationContact from "./dg/organisation/DGOrganisationContact";
import DGDocumentsUpload from "./dg/documentuploads/DGDocumentUploads";
import OrganisationStakeholder from "./dg/organisation/OrganisationStakeholder"; 
import DGOrganisationCRM from "./dg/organisation/DGOrganisationCRM";

import Organisation from "./skills/organisation/Organisation";
import OrganisationSearch from "./skills/organisation/OrganisationSearch";
import OrganisationContact from "./skills/organisation/OrganisationContact";
import OrganisationBankingDetails from "./skills/organisation/OrganisationBankingDetails";
import ChildOrganisationSearch from "./skills/organisation/ChildOrganisationSearch";
import ChildOrganisation from "./skills/organisation/ChildOrganisation";
import OrganisationCFODetails from "./skills/organisation/OrganisationCFODetails";
import TrainingCommitteeSearchRecord from "./skills/organisation/TrainingCommitteeRecords";
import TrainingCommittee from "./skills/organisation/TrainingCommittee";
import UnionSearchRecord from "./skills/organisation/UnionRecords";

import WspAtrForms from "./skills/WspAtrForms/WspAtrForms";
import EmploymentNumberForm2022 from "./skills/WspAtrForms/Forms/2022/EmploymentNumber";
import EmploymentNumberSmallForm2022 from "./skills/WspAtrForms/Forms/2022/EmploymentNumberSmall";
import AnnualTrainingReportForm2022 from "./skills/WspAtrForms/Forms/2022/AnnualTrainingReport"; 
import ActualPivotalTrainingForm2022 from "./skills/WspAtrForms/Forms/2022/ActualPivotalTraining";
import WorkplaceSkillsPlanForm2022 from "./skills/WspAtrForms/Forms/2022/WorkplaceSkillsPlan";
import QualificationProfileForm2022 from "./skills/WspAtrForms/Forms/2022/QualificationProfile";
import PlannedPivotalTrainingForm2022 from "./skills/WspAtrForms/Forms/2022/PlannedPivotalTraining";
import ActualBeneficiariesTrainingForm2022 from "./skills/WspAtrForms/Forms/2022/ActualBeneficiariesTraining";
import ActualAETForm2022 from "./skills/WspAtrForms/Forms/2022/ActualAET";
import PlannedBeneficiariesTrainingForm2022 from "./skills/WspAtrForms/Forms/2022/PlannedBeneficiariesTraining";
import ProvincialDetailsForm2022 from "./skills/WspAtrForms/Forms/2022/ProvincialDetails";
import PlannedAETForm2022 from "./skills/WspAtrForms/Forms/2022/PlannedAET";
import ImpactAssessmentForm2022 from "./skills/WspAtrForms/Forms/2022/ImpactAssessment";
import HardToFillVacanciesForm2022 from "./skills/WspAtrForms/Forms/2022/HardToFillVacancies";
import CheckListForm2022 from "./skills/WspAtrForms/Forms/2022/CheckList";
import WspAtrDashboard from "./skills/WspAtrForms/WspAtrDashboard";
import WspAtrDocumentsUpload from "./skills/WspAtrForms/DocumentsUpload";
import WspAtrVerification from "./skills/WspAtrForms/WSPATRVerificationSearch";
import WSPATRVerificationMain from "./skills/WspAtrForms/WSPATRVerificationMain";
import WSPATREvaluation from "./skills/WspAtrForms/WSPATREvaluationSearch";
import WSPATREvaluationMain from "./skills/WspAtrForms/WSPATREvaluationMain";
import Approval from "./skills/WspAtrForms/Approval";
import WspAtrApproval from "./skills/WspAtrForms/WSPATRApprovalSearch";
import WSPATRApprovalMain from "./skills/WspAtrForms/WSPATRApprovalMain";
import Verification from "./skills/WspAtrForms/Verification";
import Evaluation from "./skills/WspAtrForms/Evaluation";
import Feedback from "./skills/organisation/Stackholderfeedback";
import RequestExtension from "./skills/WspAtrForms/Extension";
import LodgeApeal from "./skills/WspAtrForms/LodgeApeal";

import OrganisationSDF from "./skills/organisation/OrganisationSDF";
import OrganisationGrantsLevies from "./skills/organisation/OrganisationGrantsLevies";
import Levies from "./skills/organisation/Levies";
import Grants from "./skills/organisation/Grants";
import DiscretionaryGrants from "./skills/organisation/DiscretionaryGrants";
import InterSetaTransfer from "./skills/organisation/InterSetaTransfer";
import SDFComplianceLetter from "./skills/organisation/OrganisationSDFComplianceLetter";

import EmployerFileImport from "./preprocessor/employer/EmployerFileImport/EmployerFileImport";

import ETQAReportSearch from "./reports/etqa/ETQAReportSearch";
import ETQAReport from "./reports/etqa/ETQAReport";
import SkillsReportSearch from "./reports/skills/SkillsReportSearch";
import SkillsReport from "./reports/skills/SkillsReport";
import MasterFileSearch from "./reports/masterfiles/MasterFileSearch";
import MasterFile from "./reports/masterfiles/MasterFile";
import SETMISFileSearch from "./reports/setmisfiles/SETMISFileSearch";
import SETMISFile from "./reports/setmisfiles/SETMISFile";
import DGReportSearch from "./reports/dg/DGReportSearch";
import DGReport from "./reports/dg/DGReport";
import CustomReports from "./reports/customreports/CustomReports";
import AdminReportSearch from "./reports/admin/AdminReportSearch";
import AdminReport from "./reports/admin/AdminReport";
import ProviderReportSearch from "./reports/provider/ProviderReportSearch";
import ProviderReport from "./reports/provider/ProviderReport";
import EmployerReportSearch from "./reports/employer/EmployerReportSearch";
import EmployerReport from "./reports/employer/EmployerReport";

import ProviderDocuments from "./etqa/approval and certification/ProviderDocuments";
import AssessorDocuments from "./etqa/approval and certification/AssessorDocuments";
import ModeratorDocuments from "./etqa/approval and certification/ModeratorDocuments";
import LearnerDocuments from "./etqa/approval and certification/LearnerDocuments";
import BulkApproval from "./etqa/approval and certification/BulkApproval";
import ExpiryNotification from "./etqa/expiry notification/ExpiryNotification";
import LearnerEndorsementLetter from "./etqa/approval and certification/LearnerEndorsementLetter";
import POR from "./etqa/proof of registration/ProofOfRegistrationSearch";

import Test from "./Test";
import TestTableDemo from "./test components/TestTableDemo";

import DGApplication from "./dg/application/DGApplication";
import DGApplicationDashboard from "./dg/application/DGApplicationDashboard";
import DGForms from "./dg/application/Forms/DGForms";
import DGVerificationSearch from "./dg/verification/DGVerificationSearch";
import DGVerificationMain from "./dg/verification/DGVerificationMain";
import DGEvaluationSearch from "./dg/evaluation/DGEvaluationSearch";
import DGEvaluationMain from "./dg/evaluation/DGEvaluationMain";
import DGApprovalSearch from "./dg/approval/DGApprovalSearch";
import DGApprovalMain from "./dg/approval/DGApprovalMain";
import DGContracts from "./dg/contracts/DGContracts";

//DG Forms 2022
import ArtisanForm2022 from "./dg/application/Forms/2022/Artisan";
import AETForm2022 from "./dg/application/Forms/2022/AET";
import BursaryForm2022 from "./dg/application/Forms/2022/Bursary";
import LearnershipForm2022 from "./dg/application/Forms/2022/Learnership";
import CandidacyForm2022 from "./dg/application/Forms/2022/Candidacy";
import TVETForm2022 from "./dg/application/Forms/2022/TVET";
import LearnershipRPLForm2022 from "./dg/application/Forms/2022/LearnershipRPL";
import InternshipForm2022 from "./dg/application/Forms/2022/Internship";
import SkillsProgrammeForm2022 from "./dg/application/Forms/2022/SkillsProgramme";
import WorkExperienceForm2022 from "./dg/application/Forms/2022/WorkExperience";

//Helpdesk
import HelpdeskRequestSearch from "./helpdesk/Request/HelpdeskRequestSearch";
import HelpdeskRequest from "./helpdesk/Request/HelpdeskRequest";

import AudienceOptions from "./maintenance/CRM/AudienceOptions";

//Agent
import AgentSearch from "./etqa/agent/AgentSearch";
import Agent from "./etqa/agent/Agent";
import AgentContact from "./etqa/agent/AgentContact";
import AgentUsers from "./etqa/agent/AgentUsers";
import AgentAET from "./etqa/agent/AgentAET";
import AgentWorkExperience from "./etqa/agent/AgentWorkExperience";
import AgentInternship from "./etqa/agent/AgentInternship";
import AgentUnemployedYouthDevelopment from "./etqa/agent/AgentUnemployedYouthDevelopment";
import AgentCandidacy from "./etqa/agent/AgentCandidacy";
import AgentLearner from "./etqa/agent/AgentLearner";
import AgentMEDP from "./etqa/agent/AgentMEDP";
import AgentMineCommunity from "./etqa/agent/AgentMineCommunity";
import AgentSmallScale from "./etqa/agent/AgentSmallScale";
import AgentWorkplaceCoaches from "./etqa/agent/AgentWorkplaceCoaches";
import AgentLearnership from "./etqa/agent/AgentLearnership";
import AgentTVETNCV from "./etqa/agent/AgentTVETNCV";
import AgentQCTOLearnership from "./etqa/agent/AgentQCTOLearnership";
import AgentSCPPI from "./etqa/agent/AgentSCPPI";

//Pre-Processor
import GrantTypeSearch from "./preprocessor/application/granttype/GrantTypeSearch";
import GrantTypeDetails from "./preprocessor/application/granttype/GrantTypeDetails";
import GrantTypeAccountDetails from "./preprocessor/application/granttype/GrantTypeAccountDetails";
import LevyAccountSearch from "./preprocessor/application/levyaccount/LevyAccountSearch";
import GrantImport from "./preprocessor/grant/grantimport/GrantImport";
import LevyImport from "./preprocessor/levy/levyimport/LevyImport";
import LevyTransaction from "./preprocessor/levy/levytransaction/LevyTransaction";
import GrantTransaction from "./preprocessor/grant/granttransaction/GrantTransaction";

//Grant Due Reports
import GrantDueReports from "./reports/grantduereports/GrantDueReports";

/*QALP Dashboard*/
import QALPDashboard from "./Dashboards/QALPDashboard";

/*MG DASHBOARD*/
import MGDashboard from "./Dashboards/MGDashboard";

import HelpDeskReport from "./helpdesk/Report/HelpDeskReport";

//WSPATR Evalaution Sheet

import WspAtrEvaluationSheet from "./skills/WspAtrForms/WSPATREvaluationSheetSearch";

/*Bursary Setup*/
import BursarySetupSearch from "./etqa/program/Bursary/BursarySetupSearch";
import BursarySetup from "./etqa/program/Bursary/BursarySetup";
import BursaryDocumentSearch from "./etqa/program/Bursary/BursaryDocumentSearch";

//Bursary Application

import AddBursaryApplicationFlow from "./etqa/bursaryApplication/AddBursaryApplicationFlow";
import BursaryApplicationEvaluationSearch from "./etqa/bursaryApplication/BursaryApplicationEvaluationSearch";
import BursaryApplicationEvaluation from "./etqa/bursaryApplication/BursaryApplicationEvaluation";
import BursaryApplicationTypeEvaluation from "./etqa/bursaryApplication/BursaryApplicationTypeEvaluation";
import BursaryApplicationEvaluationAll from "./etqa/bursaryApplication/BursaryApplicationEvaluationAll";
import BursaryApplicationEvaluationDetail from "./etqa/bursaryApplication/BursaryApplicationEvaluationDetail";

/* QCTO Module */

import QCTOModuleSetupSearch from "./etqa/program/QCTOModule/QCTOModuleSetupSearch";
import QCTOModuleSetup from "./etqa/program/QCTOModule/QCTOModuleSetup";
import QCTOModuleDocumentSearch from "./etqa/program/QCTOModule/QCTOModuleDocumentSearch";
import QCTOModuleQualificationSearch from "./etqa/program/QCTOModule/QCTOModuleQualificationSearch";
import QCTOModuleSkillsProgrammeSearch from "./etqa/program/QCTOModule/QCTOModuleSkillsProgrammeSearch";
/* QCTO Qualification */
import QCTOQualificationSetupSearch from "./etqa/program/QCTOQualification/QCTOQualificationSetupSearch";
import QCTOQualificationSetup from "./etqa/program/QCTOQualification/QCTOQualificationSetup";
import QCTOQualificationDocumentSearch from "./etqa/program/QCTOQualification/QCTOQualificationDocumentSearch";
import QCTOQualificationModuleSearch from "./etqa/program/QCTOQualification/QCTOQualificationModuleSearch";
import QCTOQualificationSkillsProgrammeSearch from "./etqa/program/QCTOQualification/QCTOQualificationSkillsProgrammeSearch";
import QCTOQualificationLearnershipSearch from "./etqa/program/QCTOQualification/QCTOQualificationLearnershipSearch";
/* QCTO Learnership */
import QCTOLearnershipSetupSearch from "./etqa/program/QCTOLearnership/QCTOLearnershipSetupSearch";
import QCTOLearnershipSetup from "./etqa/program/QCTOLearnership/QCTOLearnershipSetup";
import QCTOLearnershipDocumentSearch from "./etqa/program/QCTOLearnership/QCTOLearnershipDocumentSearch";
import QCTOLearnershipModuleSearch from "./etqa/program/QCTOLearnership/QCTOLearnershipModuleSearch";
import QCTOLearnershipAgreementSearch from "./etqa/program/QCTOLearnership/QCTOLearnershipAgreementSearch";
/* QCTO SkillsProgramme */
import QCTOSkillsProgrammeSetupSearch from "./etqa/program/QCTOSkillsProgramme/QCTOSkillsProgramSetupSearch";
import QCTOSkillsProgrammeSetup from "./etqa/program/QCTOSkillsProgramme/QCTOSkillsProgramSetup";
import QCTOSkillsProgrammeDocumentSearch from "./etqa/program/QCTOSkillsProgramme/QCTOSkillsProgrammeDocumentSearch";
import QCTOSkillsProgrammeModuleSearch from "./etqa/program/QCTOSkillsProgramme/QCTOSkillsProgrammeModuleSearch";
/* Skills Development Provider */
import SDProviderSearch from "./etqa/sdprovider/SDProviderSearch";
import SDProvider from "./etqa/sdprovider/SDProvider";
import SDProviderContact from "./etqa/sdprovider/SDProviderContact";
import SDProviderUsers from "./etqa/sdprovider/SDProviderUsers";
import SDProviderLearner from "./etqa/sdprovider/SDProviderLearner";
import SDProviderAssessorSearch from "./etqa/sdprovider/SDProviderAssessors";
import SDProviderModeratorSearch from "./etqa/sdprovider/SDProviderModeratorSearch";
import SDProviderQCTOQualificationSearch from "./etqa/sdprovider/SDProviderQCTOQualificationsSearch";
import SDProviderQCTOQualificationModuleSearch from "./etqa/sdprovider/SDProviderQCTOQualificationModuleSearch";
import SDProviderQCTOLearnershipSearch from "./etqa/sdprovider/SDProviderQCTOLearnershipSearch";
import SDProviderQCTOSkillsProgrammeSearch from "./etqa/sdprovider/SDProviderQCTOSkillsProgrammeSearch";
/*Workplace Approval*/
import WorkplaceApprovalSearch from "./etqa/workplaceapproval/WorkplaceApprovalSearch";
import WorkplaceApproval from "./etqa/workplaceapproval/WorkplaceApproval";
import WorkplaceApprovalUsers from "./etqa/workplaceapproval/WorkplaceApprovalUsers";
import WorkplaceApprovalContact from "./etqa/workplaceapproval/WorkplaceApprovalContact";
import WorkplaceApprovalLearner from "./etqa/workplaceapproval/WorkplaceApprovalLearner";
import WorkplaceApprovalQCTOQualificationSearch from "./etqa/workplaceapproval/WorkplaceApprovalQCTOQualificationSearch";
import WorkplaceApprovalQCTOQualificationModuleSearch from "./etqa/workplaceapproval/WorkplaceApprovalQCTOQualificationModuleSearch";
import WorkplaceApprovalQCTOLearnershipSearch from "./etqa/workplaceapproval/WorkplaceApprovalQCTOLearnershipSearch";
import WorkplaceApprovalQCTOSkillsProgrammeSearch from "./etqa/workplaceapproval/WorkplaceApprovalQCTOSkillsProgrammeSearch";
import WorkplaceApprovalCoachMentorSearch from "./etqa/workplaceapproval/WorkplaceApprovalCoachMentorSearch";
import WorkplaceApprovalDocumentSearch from "./etqa/workplaceapproval/WorkplaceApprovalDocumentSearch";
/*Assessment Centre*/
import AssessmentCentreSearch from "./etqa/assessmentcentre/AssessmentCentreSearch";
import AssessmentCentre from "./etqa/assessmentcentre/AssessmentCentre";
import AssessmentCentreContact from "./etqa/assessmentcentre/AssessmentCentreContact";
import AssessmentCentreUsers from "./etqa/assessmentcentre/AssessmentCentreUsers";
import AssessmentCentreLearner from "./etqa/assessmentcentre/AssessmentCentreLearner";
import AssessmentCentreAssessorSearch from "./etqa/assessmentcentre/AssessmentCentreAssessors";
import AssessmentCentreModeratorSearch from "./etqa/assessmentcentre/AssessmentCentreModeratorSearch";
import AssessmentCentreQCTOQualificationSearch from "./etqa/assessmentcentre/AssessmentCentreQCTOQualificationSearch";
import AssessmentCentreQCTOQualificationModuleSearch from "./etqa/assessmentcentre/AssessmentCentreQCTOQualificationModuleSearch";
import AssessmentCentreQCTOLearnershipSearch from "./etqa/assessmentcentre/AssessmentCentreQCTOLearnershipSearch";
import AssessmentCentreDocumentSearch from "./etqa/assessmentcentre/AssessmentCentreDocumentSearch";

/*Coach Mentor*/
import CoachMentor from "./etqa/coachmentor/CoachMentor";
import CoachMentorSearch from "./etqa/coachmentor/CoachMentorSearch";
import AddCoachMentorFlow from "./etqa/coachmentor/AddCoachMentorFlow";
import CoachMentorSkillsProgramme from "./etqa/coachmentor/CoachMentorSkillsProgramme";
import CoachMentorQualification from "./etqa/coachmentor/CoachMentorQualification";
import CoachMentorTradeQualification from "./etqa/coachmentor/CoachMentorTradeQualification";
import CoachMentorlearnership from "./etqa/coachmentor/CoachMentorLearnerships";
import CoachMentorUnitStandards from "./etqa/coachmentor/CoachMentorUnitStandards";
import CoachMentorWorkplaceApprovalSearch from "./etqa/coachmentor/CoachMentorWorkplaceApproval";
import CoachMentorRegistrationDetails from "./etqa/coachmentor/CoachMentorRegistrationDetails";
import CoachMentorRegistrationHistory from "./etqa/coachmentor/RegistrationHistory";
import CoachMentorCRM from "./etqa/coachmentor/CoachMentorCRM";

/*Learner AC/CM/SDP */
import LearnerAC from "./etqa/learner/LearnerAssessmentCentreSearch";
import LearnerWA from "./etqa/learner/LearnerWorkplaceApprovalSearch";
import LearnerSDProvider from "./etqa/learner/LearnerSDProviderSearch";

/*EISA Enrolment*/
import LearnerEISAEnrolmentSearch from "./etqa/learner/LearnerEISAEnrolment";
/*Learner QCTO Registration*/
import LearnerQCTOLearnershipSearch from "./etqa/learner/LearnerQCTOLearnership";
import AddLearnerQCTOLearnership from "./etqa/learner/AddLearnerQCTOLearnership";
import LearnerEISAReadinessSearch from "./etqa/learner/LearnerEISAReadinessSearch";
import LearnerQCTOLearnershipDocumentSearch from "./etqa/learner/LearnerQCTOLearnershipDocumentSearch";
import LearnerQCTOArtisanSearch from "./etqa/learner/LearnerQCTOArtisan";
import LearnerQCTOLearnershipAssessments from "./etqa/learner/LearnerQCTOLearnershipAssessment";
import ViewQCTOLearnershipAssessment from "./etqa/learner/ViewQCTOLearnershipAssessment";
import AddLearnerQCTOArtisan from "./etqa/learner/AddLearnerQCTOArtisan";
import ViewEISADetails from "./etqa/learner/ViewEISADetails";
import LearnerQCTOArtisanDocumentSearch from "./etqa/learner/LearnerQCTOArtisanDocumentSearch";
import ViewQCTONAMB from "./etqa/learner/ViewQCTONAMB";
import LearnerQCTOLearnershipAchievements from "./etqa/learner/LearnerQCTOLearnershipAchievement";
import ViewQCTOLearnershipAchievementModule from "./etqa/learner/ViewQCTOLearnershipAchievementModule";
import LearnerQCTOArtisanAssessments from "./etqa/learner/LearnerQCTOArtisanAssessment";
import ViewQCTOArtisanAssessment from "./etqa/learner/ViewQCTOArtisanAssessment"; 
import ViewLearnerQCTOArtEISADetails from "./etqa/learner/ViewLearnerQCTOArtisanEISADetails";
import ViewLearnerQCTOArtNAMB from "./etqa/learner/ViewLearnerQCTOArtisanNAMB";
import LearnerQCTOSkillsProgrammeSearch from "./etqa/learner/LearnerQCTOSkillsProgramme";
import AddLearnerQCTOSkillsProgramme from "./etqa/learner/AddLearnerQCTOSkillsProgramme";
import LearnerQCTOSkillsProgrammeDocumentSearch from "./etqa/learner/LearnerQCTOSkillsProgrammeDocumentSearch";
import LearnerQCTOSkillsProgrammeAssessment from "./etqa/learner/LearnerQCTOSkillsProgrammeAssessment";
import ViewQCTOSkillsProgrammeAssessment from "./etqa/learner/ViewQCTOSkillsProgrammeAssessment";
import LearnerQCTOSkillsProgrammeAchievement from "./etqa/learner/LearnerQCTOSkillsProgrammeAchievement";
import ViewQCTOSkillsProgrammeAchievementModule from "./etqa/learner/ViewQCTOSkillsProgrammeAchievementModule";

/* ID VERIFICATION */
import IDVerificationPortal from "./etqa/IDVerificationPortal/IDVerificationPortal";

/* CUSTOM REPORTS V2 */
import CustomReportsV2 from "./reports/customreports/CustomReportsV2";

/*Inhouse/Industry/Based Training Course Setup*/
import IIBTCPSetupSearch from "./etqa/program/IIBTCP/IIBTCPSetupSearch";
import IIBTCPSetup from "./etqa/program/IIBTCP/IIBTCPSetup";
import IIBTCPDocumentSearch from "./etqa/program/IIBTCP/IIBTCPDocumentSearch";
/*Provider Inhouse/Industry/Based Training Course Setup*/
import ProviderIIBTCPSearch from "./etqa/provider/ProviderIIBTCPSearch";

/*Learner Inhouse/Industry/Based Training Course */
import LearnerIIBTCPSearch from "./etqa/learner/LearnerIIBTCP";
import LearnerIIBTCPDocumentSearch from "./etqa/learner/LearnerIIBTCPDocumentSearch";
import AddLearnerIIBTCP from "./etqa/learner/AddLearnerIIBTCP";

class Routes extends Component {
    render() {
        return (
            <span>
                <Route path='/dashboard' component={Dashboard} />
                <Route path='/sdfdashboard' component={SDFDashboard} />
                <Route path='/stakeholderdashboard' component={StakeholderDashboard} />
                <Route path='/skillsdashboard' component={SkillsDashboard} />
                <Route path='/employers' component={EmployerSearch} />
                <Route path='/employer' component={Employer} />
                <Route path='/employerLearners' component={EmployerLearnerSearch} />
                <Route path='/employerProviders' component={EmployerProviderSearch} />
                <Route path='/employerLearnerProgrammes' component={EmployerLearnerProgrammes} />
                <Route path='/employerUsers' component={EmployerUserSearch} />
                <Route path='/employerContacts' component={EmployerContactSearch} />
                <Route path='/learners' component={LearnerSearch} />
                <Route path='/learner' component={Learner} />
                <Route path='/addLearnerMineCommunity' component={AddLearnerMineCommunity} />
                <Route path='/addLearnerSmallScale' component={AddLearnerSmallScale} />
                <Route path='/addLearnerSkillsProgram' component={AddLearnerSkillsProgram} />
                <Route path='/addLearnerBursary' component={AddLearnerBursary} />
                <Route path='/addLearnerWorkExperience' component={AddLearnerWorkExperience} />
                <Route path='/learnerWorkExperienceDocuments' component={LearnerWorkExperienceDocumentSearch} />
                <Route path='/addLearnerInternships' component={AddLearnerInternships} />
                <Route path='/learnerInternshipDocuments' component={LearnerInternshipDocumentSearch} />
                <Route path='/addLearnerLearnership' component={AddLearnerLearnership} />
                <Route path='/addLearnerQualification' component={AddLearnerQualification} />
                <Route path='/LearnerQualificationUSSearch' component={LearnerQualificationUSSearch} />
                <Route path='/addLearnerUnitStandard' component={AddLearnerUnitStandard} />
                <Route path='/addLearnerTrade' component={AddLearnerTrade} />
                <Route path='/learnerTradeDocuments' component={LearnerTradeDocumentSearch} />
                <Route path='/addLearnerTVETNCV' component={AddLearnerTVETNCV} />
                <Route path='/learnerTVETNCVDocuments' component={LearnerTVETNCVDocumentSearch} />
                <Route path='/addLearnerSCPPI' component={AddLearnerSCPPI} />
                <Route path='/addLearnerAET' component={AddLearnerAET} />
                <Route path='/addLearnerFET' component={AddLearnerFET} />
                <Route path='/addLearnerMEDP' component={AddLearnerMEDP} />
                <Route path='/addLearnerCandidacy' component={AddLearnerCandidacy} />
                <Route path='/addLearnerWorkplaceCoaches' component={AddLearnerWorkplaceCoaches} />
                <Route path='/LearnerMineCommunitySearch' component={LearnerMineCommunitySearch} />
                <Route path='/learnerMineCommunityDocuments' component={LearnerMineCommunityDocumentSearch} />
                <Route path='/LearnerSmallScaleSearch' component={LearnerSmallScaleSearch} />
                <Route path='/learnerSmallScaleDocuments' component={LearnerSmallScaleDocumentSearch} />
                <Route path='/addLearnerUnemployedYouthDevelopment' component={AddLearnerUnemployedYouthDevelopment} />
                <Route path='/learnerUnemployedYouthDevelopmentDocuments' component={LearnerUnemployedYouthDevelopmentDocumentSearch} />
                <Route path='/learnerCandidacyDocuments' component={LearnerCandidacyDocumentSearch} />
                <Route path='/addLearnerHETLecturerSupport' component={AddLearnerHETLecturerSupport} />
                <Route path='/learnerHETLecturerSupportDocuments' component={LearnerHETLecturerSupportDocumentSearch} />
                <Route path='/learnerMEDPDocuments' component={LearnerMEDPDocumentSearch} />
                <Route path='/LearnerSkillsProgrammeSearch' component={LearnerSkillsProgrammeSearch} />
                <Route path='/LearnerBursarySearch' component={LearnerBursarySearch} />
                <Route path='/learnerBursaryDocuments' component={LearnerBursaryDocumentSearch} />
                <Route path='/LearnerSCPPISearch' component={LearnerSCPPISearch} />
                <Route path='/learnerSCPPIDocuments' component={LearnerSCPPIDocumentSearch} />
                <Route path='/LearnerAETSearch' component={LearnerAETSearch} />
                <Route path='/learnerAETDocuments' component={LearnerAETDocumentSearch} />
                <Route path='/LearnerAETAssessments' component={LearnerAETAssessments} />
                <Route path='/LearnerFETSearch' component={LearnerFETSearch} />
                <Route path='/LearnerLearnershipSearch' component={LearnerLearnershipSearch} />
                <Route path='/learnerLearnershipDocuments' component={LearnerLearnershipDocumentSearch} />
                <Route path='/LearnerTradeSearch' component={LearnerTradeSearch} />
                <Route path='/LearnerWorkExperienceSearch' component={LearnerWorkExperienceSearch} />
                <Route path='/LearnerInternshipsSearch' component={LearnerInternshipsSearch} />
                <Route path='/LearnerWorkplaceCoachesSearch' component={LearnerWorkplaceCoachesSearch} />
                <Route path='/learnerWorkplaceCoachesDocuments' component={LearnerWorkplaceCoachesDocumentSearch} />
                <Route path='/LearnerUnemployedYouthDevelopmentSearch' component={LearnerUnemployedYouthDevelopmentSearch} />
                <Route path='/LearnerTVETNCVSearch' component={LearnerTVETNCVSearch} />
                <Route path='/LearnerQualificationSearch' component={LearnerQualificationSearch} />
                <Route path='/LearnerMEDPSearch' component={LearnerMEDPSearch} />
                <Route path='/LearnerCandidacySearch' component={LearnerCandidacySearch} />
                <Route path='/LearnerHETLecturerSupportSearch' component={LearnerHETLecturerSupportSearch} />
                <Route path='/LearnerUnitStandardSearch' component={LearnerUnitStandardSearch} />
                <Route path='/LearnerLearnershipAchievements' component={LearnerLearnershipAchievements} />
                <Route path='/ViewLearnershipAssessment' component={ViewLearnershipAssessment} />
                <Route path='/ViewSkillsProgrammeAssessment' component={ViewSkillsProgrammeAssessment} />
                <Route path='/ViewUnemployedYouthDevelopmentAssessment' component={ViewUnemployedYouthDevelopmentAssessment} />
                <Route path='/ViewQualificationAssessment' component={ViewQualificationAssessment} />
                <Route path='/ViewUnitStandardAssessment' component={ViewUnitStandardAssessment} />
                <Route path='/ViewTradeAssessment' component={ViewTradeAssessment} />
                <Route path='/ViewTVETNCVAssessment' component={ViewTVETNCVAssessment} />
                <Route path='/ViewAETAssessment' component={ViewAETAssessment} />
                <Route path='/ViewMineCommunityAssessment' component={ViewMineCommunityAssessment} />
                <Route path='/ViewSmallScaleAssessment' component={ViewSmallScaleAssessment} />
                <Route path='/ViewTradeTest' component={ViewTradeTest} />
                <Route path='/ViewTVETNCVTradeTest' component={ViewTVETNCVTradeTest} />
                <Route path='/ViewNAMB' component={ViewNAMB} />
                <Route path='/ViewTVETNCVNAMB' component={ViewTVETNCVNAMB} />
                <Route path='/LearnerUnitStandardAssessment' component={LearnerUnitStandardAssessment} />
                <Route path='/LearnerSkillsProgrammeAssessment' component={LearnerSkillsProgrammeAssessment} />
                <Route path='/LearnerUnemployedYouthDevelopmentAssessment' component={LearnerUnemployedYouthDevelopmentAssessment} />
                <Route path='/learnerSkillsProgrammeDocuments' component={LearnerSkillsProgrammeDocumentSearch} />
                <Route path='/LearnerQualificationAssessment' component={LearnerQualificationAssessment} />
                <Route path='/LearnerLearnershipAssessments' component={LearnerLearnershipAssessments} />
                <Route path='/LearnerTradeAssessments' component={LearnerTradeAssessments} />
                <Route path='/LearnerMineCommunityAssessment' component={LearnerMineCommunityAssessment} />
                <Route path='/LearnerSmallScaleAssessment' component={LearnerSmallScaleAssessment} />
                <Route path='/LearnerTVETNCVAssessments' component={LearnerTVETNCVAssessments} />
                <Route path='/ViewSkillsProgrammeAchievementUS' component={ViewSkillsProgrammeAchievementUS} />
                <Route path='/ViewMineCommunityAchievementUS' component={ViewMineCommunityAchievementUS} />
                <Route path='/ViewSmallScaleAchievementUS' component={ViewSmallScaleAchievementUS} />
                <Route path='/ViewLearnershipAchievementUS' component={ViewLearnershipAchievementUS} />
                <Route path='/ViewQualificationAchievementUS' component={ViewQualificationAchievementUS} />
                <Route path='/ViewUnemployedYouthDevelopmentAchievementUS' component={ViewUnemployedYouthDevelopmentAchievementUS} />
                <Route path='/LearnerUnitStandardAchievements' component={LearnerUnitStandardAchievement} />
                <Route path='/LearnerQualificationAchievements' component={LearnerQualificationAchievement} />
                <Route path='/LearnerSkillsProgrammeAchievements' component={LearnerSkillsProgrammeAchievement} />
                <Route path='/LearnerMineCommunityAchievements' component={LearnerMineCommunityAchievement} />
                <Route path='/LearnerSmallScaleAchievements' component={LearnerSmallScaleAchievement} />
                <Route path='/LearnerUnemployedYouthDevelopmentAchievements' component={LearnerUnemployedYouthDevelopmentAchievement} />
                <Route path='/learnerCRM' component={LearnerCRM} />
                <Route path='/learnergrants' component={LearnerProgrammeGrants} />
                <Route path='/persons' component={PersonSearch} />
                <Route path='/person' component={Person} />

                <Route path='/clientSearch' component={ClientsEmailsSearch} />

                <Route path='/moderatorSearch' component={ModeratorSearch} />
                <Route path='/Moderatorlearnership' component={Moderatorlearnership} />
                <Route path='/ModeratorSkillsProgramme' component={ModeratorSkillsProgramme} />
                <Route path='/moderatorQualification' component={ModeratorQualification} />
                <Route path='/moderatorTradeQualification' component={ModeratorTradeQualification} />
                <Route path='/moderatorUnitStandard' component={ModeratorUnitStandards} />
                <Route path='/moderatorProviderSearch' component={ModeratorProviderSearch} />
                <Route path='/moderatorEmployerSearch' component={ModeratorEmployerSearch} />
                <Route path='/moderatorCRM' component={ModeratorCRM} />
                <Route path='/uploadPerson' component={UploadPerson} />
                <Route path='/uploadLearnership' component={UploadLearnership} />
                <Route path='/uploadSkills' component={UploadSkills} />
                <Route path='/uploadBursaries' component={UploadBursaries} />
                <Route path='/uploadInternship' component={UploadInternship} />
                <Route path='/uploadAET' component={UploadAET} />
                <Route path='/uploadCandidacy' component={UploadCandidacy} />
                <Route path='/uploadFET' component={UploadFET} />
                <Route path='/uploadQualification' component={UploadQualification} />
                <Route path='/uploadUnitStandard' component={UploadUnitStandard} />
                <Route path='/uploadAchievement' component={UploadAchievement} />
                <Route path='/EmployerCRM' component={EmployerCRM} />

                <Route path='/OrganisationCRM' component={OrganisationCRM} />

                <Route path='/providerCRM' component={ProviderCRM} />

                <Route path='/AssessorSkillsProgrammeSearch' component={AssessorSkillsProgrammeSearch} />
                <Route path='/AssessorQualificationSearch' component={AssessorQualificationSearch} />
                <Route path='/AssessorTradeQualificationSearch' component={AssessorTradeQualificationSearch} />
                <Route path='/AssessorRegistrationHistory' component={AssessorRegistrationHistory} />
                <Route path='/assessorLearnershipUnitStandards' component={AssessorLearnershipUSSearch} />
                <Route path='/Assessorlearnerships' component={AssessorLearnershipSearch} />
                <Route path='/Assessorunitstandards' component={AssessorUnitStandardSearch} />
                <Route path='/moderatorRegistrationHistory' component={ModeratorRegistrationHistory} />
                <Route path='/AssessorUnitStandard' component={AssessorUnitStandards} />
                <Route path='/AssessorProviderSearch' component={AssessorProviderSearch} />
                <Route path='/AssessorEmployerSearch' component={AssessorEmployerSearch} />
                <Route path='/AssessorRegistrationDetails' component={AssessorRegistrationDetails} />
                <Route path='/assessorCRM' component={AssessorCRM} />
                <Route path='/assessorIDDocument' component={AssessorIDDocument} />
                <Route path='/AssessorSDProviderSearch' component={AssessorSDProviderSearch} />

                <Route path='/DGLetterCreationSearch' component={DGLetterCreationSearch} />
                <Route path='/DGLetterCreation' component={DGLetterCreation} />
                <Route path='/DGQueryReasonSearch' component={DGQueryReasonSearch} />
                <Route path='/DGQueryReason' component={DGQueryReason} />
                <Route path='/DGRejectionReasonSearch' component={DGRejectionReasonSearch} />
                <Route path='/DGRejectionReason' component={DGRejectionReason} />

                <Route path='/OnlineRegistrationTypeSelect' component={OnlineRegistrationTypeSelect} />
                <Route path='/OnlineRegistrationSecondryTypeSelect' component={OnlineRegistrationSecondryTypeSelect} />
                <Route path='/AddOnlineAssessorFlow' component={AddOnlineAssessorFlow} />
                <Route path='/OnlineAssessorApprovalSearch' component={OnlineAssessorApprovalSearch} />
                <Route path='/assessorapproval' component={AssessorApproval} />
                <Route path='/OnlineAssessorApprovalQualificationScope' component={OnlineAssessorApprovalQualificationScope} />
                <Route path='/OnlineAssessorApprovalSkillsProgrammeScope' component={OnlineAssessorApprovalSkillsProgrammeScope} />
                <Route path='/OnlineAssessorApprovalDocuments' component={OnlineAssessorApprovalDocuments} />
                <Route path='/OnlineAssessorDetailsVerification' component={OnlineAssessorDetailsVerification} />
                <Route path='/AddOnlineModeratorFlow' component={AddOnlineModeratorFlow} />
                <Route path='/OnlineModeratorApprovalSearch' component={OnlineModeratorApprovalSearch} />
                <Route path='/moderatorapproval' component={ModeratorApproval} />
                <Route path='/OnlineModeratorApprovalQualificationScope' component={OnlineModeratorApprovalQualificationScope} />
                <Route path='/OnlineModeratorApprovalSkillsProgrammeScope' component={OnlineModeratorApprovalSkillsProgrammeScope} />
                <Route path='/OnlineModeratorApprovalDocuments' component={OnlineModeratorApprovalDocuments} />
                <Route path='/OnlineModeratorDetailsVerification' component={OnlineModeratorDetailsVerification} />

                <Route path='/InstitutionSearch' component={InstitutionSearch} />

                <Route path='/SkillsLetterCreationSearch' component={SkillsLetterCreationSearch} />
                <Route path='/SkillsLetterCreation' component={SkillsLetterCreation} />
                <Route path='/SkillsQueryReasonSearch' component={SkillsQueryReasonSearch} />
                <Route path='/SkillsQueryReason' component={SkillsQueryReason} />
                <Route path='/SkillsRejectionReasonSearch' component={SkillsRejectionReasonSearch} />
                <Route path='/SkillsRejectionReason' component={SkillsRejectionReason} />
                <Route path='/SkillsTNumberConversionSearch' component={SkillsTNumberConversionSearch} />
                <Route path='/SkillsTNumberConversion' component={SkillsTNumberConversion} />

                <Route path='/maintenanceTargets' component={BenefitsTargetsSearch} />
                <Route path='/CreateInterventionTitles' component={CreateInterventionTitles} />
                <Route path='/maintenanceInterventionTitles' component={InterventionTitlesSearch} />
                <Route path='/maintenanceBenefitDescriptions' component={BenefitsDescriptionsSearch} />
                <Route path='/BenefitsDescriptionSearchForm' component={CreateBenefitsDescriptionSearch} />
                <Route path='/Benefits' component={BenefitsTargetAddFormSearch} />

                <Route path='/maintenanceLinking' component={LinkingSearch} />
                <Route path='/maintenanceBenefitsUploading' component={BenefitsUpload} />

                <Route path='/EmailAdd' component={EmailAdd} />
                <Route path='/maintenanceEmail' component={ClientsEmailsSearch} />

                <Route path='/moderator' component={Moderator} />
                <Route path='/moderatorRegistrationDetails' component={ModeratorRegistrationDetails} />


                <Route path='/providerSearch' component={ProviderSearch} />
                <Route path='/provider' component={Provider} />
                <Route path='/outcome' component={Outcome} />
                <Route path="/providerMonitoringAndEvaluation" component={ProviderMonitoringAndEvaluation} />
                <Route path="/providerTrade" component={ProviderTrade} />
                <Route path='/providerContacts' component={ProviderContact} />
                <Route path='/providerSiteVisits' component={ProviderSiteVisitsSearch} />
                <Route path='/providerUsers' component={ProviderUsers} />
                <Route path='/providerAccreditationDetails' component={ProviderAccreditation} />
                <Route path='/providerLearner' component={ProviderLearner} />
                <Route path='/providerUnitStandardSearch' component={ProviderUnitStandards} />
                <Route path='/providerQualificationUSSearch' component={ProviderQualificationUSSearch} />
                <Route path='/providerQualificationsSearch' component={ProviderQualificationSearch} />
                <Route path='/providerTradeQualificationsSearch' component={ProviderTradeQualificationSearch} />
                <Route path='/providerSkillsProgrammeSearch' component={ProviderSkillsProgrammeSearch} />
                <Route path='/providerSkillsProgrammeUSSearch' component={ProviderSkillsProgrammeUSSearch} />
                <Route path='/providerLearnershipSearch' component={ProviderLearnershipSearch} />
                <Route path='/providerLearnershipUSSearch' component={ProviderLearnershipUSSearch} />
                <Route path='/providerAssessorSearch' component={ProviderAssessorSearch} />
                <Route path='/providerModeratorSearch' component={ProviderModeratorSearch} />
                <Route path="/providerAccreditationHistory" component={ProviderAccreditationHistory} />


                <Route path='/learnerSkillsProgram' component={LearnerSkillsProgram} />

                <Route path='/bda' component={BDASearch} />
                <Route path='/personSearch' component={PersonSearch} />
                <Route path='/externalmoderators' component={ExternalProviderSearch} />
                <Route path='/assessorSearch' component={AssessorSearch} />
                <Route path='/auditTrial' component={AuditTrial} />
                <Route path='/assessor' component={Assessor} />
                <Route path='/unitstandardsetupSearch' component={UnitStandardSetupSearch} />
                <Route path='/unitStandardSetup' component={UnitStandardSetup} />
                <Route path='/unitstandarddocuments' component={UnitStandardDocumentSearch} />
                <Route path='/qualificationsetupSearch' component={QualificationSetupSearch} />
                <Route path='/qualificationsetup' component={QualificationSetup} />
                <Route path='/qualunitstandardsearch' component={QualificationUnitStandardSearch} />
                <Route path='/qualificationDocuments' component={QualificationDocumentSearch} />
                <Route path='/qualificationLearnerships' component={QualificationLearnershipSearch} />
                <Route path='/qualificationSkillsProgrammes' component={QualificationSkillsProgrammeSearch} />
                <Route path='/skillsProgramestandardsearch' component={SkillsUnitStandardSearch} />
                <Route path='/learnershipsetupSearch' component={LearnershipSetupSearch} />
                <Route path='/moduleSetupSearch' component={ModuleSetupSearch} />
                <Route path='/moduleSetup' component={ModuleSetup} />
                <Route path='/moduleDocuments' component={ModuleDocumentSearch} />
                <Route path='/tradeQualificationSetupSearch' component={TradeQualificationSetupSearch} />
                <Route path='/tradeQualificationSetup' component={TradeQualificationSetup} />
                <Route path='/tradeQualificationDocuments' component={TradeQualificationDocumentSearch} />
                <Route path='/tradeQualificationModuleSearch' component={TradeQualificationModuleSearch} />
                <Route path='/SCPPISetupSearch' component={SCPPISetupSearch} />
                <Route path='/SCPPIsetup' component={SCPPISetup} />
                <Route path='/scppidocuments' component={SCPPIDocumentSearch} />
                <Route path='/aetSetupSearch' component={AETSetupSearch} />
                <Route path='/AETsetup' component={AETSetup} />
                <Route path='/aetLearningAreaSearch' component={AETLearningAreaSearch} />
                <Route path='/aetdocuments' component={AETDocumentSearch} />
                <Route path='/internshipSetupSearch' component={InternshipSetupSearch} />
                <Route path='/internshipSetup' component={InternshipSetup} />
                <Route path='/internshipdocuments' component={InternshipDocumentSearch} />
                <Route path='/workplaceCoachesSetupSearch' component={WorkplaceCoachesSetupSearch} />
                <Route path='/workplaceCoachesSetup' component={WorkplaceCoachesSetup} />
                <Route path='/workplaceCoachesdocuments' component={WorkplaceCoachesDocumentSearch} />
                <Route path='/candidacySetupSearch' component={CandidacySetupSearch} />
                <Route path='/candidacySetup' component={CandidacySetup} />
                <Route path='/candidacydocuments' component={CandidacyDocumentSearch} />
                <Route path='/hetLecturerSupportSetupSearch' component={HETLecturerSupportSetupSearch} />
                <Route path='/hetLecturerSupportSetup' component={HETLecturerSupportSetup} />
                <Route path='/hetLecturerSupportdocuments' component={HETLecturerSupportDocumentSearch} />
                <Route path='/medpSetupSearch' component={MEDPSetupSearch} />
                <Route path='/medpSetup' component={MEDPSetup} />
                <Route path='/medpdocuments' component={MEDPDocumentSearch} />
                <Route path='/workExperienceSetupSearch' component={WorkExperienceSetupSearch} />
                <Route path='/workExperienceSetup' component={WorkExperienceSetup} />
                <Route path='/workExperiencedocuments' component={WorkExperienceDocumentSearch} />
                <Route path='/learningareasetupSearch' component={LearningAreaSetupSearch} />
                <Route path='/learningareaSetup' component={LearningAreaSetup} />
                <Route path='/tradeSetupSearch' component={TradeSetupSearch} />
                <Route path='/Tradesetup' component={TradeSetup} />
                <Route path='/tradeDocuments' component={TradeDocumentSearch} />
                <Route path='/learnershipUnitStandardSearch' component={LearnershipUnitStandardSearch} />
                <Route path='/learnershipdocuments' component={LearnershipDocumentSearch} />
                <Route path='/learnershipagreements' component={LearnershipAgreementSearch} />
                <Route path='/learnershipsetup' component={LearnershipSetup} />
                <Route path='/saqaxmlimport' component={SAQAXMLImport} />
                <Route path='/shortcoursesetup' component={ShortCourseSetupSearch} />
                <Route path='/skillsProgrammeSetupSearch' component={SkillsProgramSetupSearch} />
                <Route path='/skillsprogrammedocuments' component={SkillsProgrammeDocumentSearch} />
                <Route path='/skillsProgrammeSetup' component={SkillsProgramSetup} />
                <Route path='/artisansetup' component={ArtisansSetupSearch} />
                <Route path='/tradeModuleSearch' component={TradeModuleSearch} />
                <Route path='/addLearnerFlow' component={AddLearnerFlow} />
                <Route path='/addPersonFlow' component={AddPersonFlow} />
                <Route path='/addAssessorFlow' component={AddAssessorFlow} />
                <Route path='/addModderatorFlow' component={AddModeratorFlow} />
                <Route path='/roleMaintenanceSearch' component={RoleMaintenanceSearch} />
                <Route path='/userMaintenanceSearch' component={UserMaintenanceSearch} />

                <Route path='/userRole' component={UserRoleSearch} />


                <Route path='/userDetails' component={UserDetailsMaintenanceSearch} />

                <Route path='/LinkingSearch' component={CreateLinkingSearch} />

                <Route path='/roleMaintenance' component={Role} />
                <Route path='/rolePermissionSearch' component={RolePermissionSearch} />
                <Route path='/rolePermissionMaintenance' component={RolePermission} />
                <Route path='/SDFSearch' component={SDFSearch} />
                <Route path='/sdf' component={SDF} />
                <Route path='/sdfOrgDetails' component={SDFOrganisationDetails} />

                {/*DG Application*/}

                <Route path='/DGOrganisationSearch' component={DGOrganisationSearch} />
                <Route path='/DGOrganisation' component={DGOrganisation} />
                <Route path='/DGApplication' component={DGApplication} />
                <Route path='/DGApplicationDashboard' component={DGApplicationDashboard} />
                <Route path='/DGOrganisationContacts' component={DGOrganisationContact} />
                <Route path='/DGDocumentUploads' component={DGDocumentsUpload} />
                <Route path='/dgForms' component={DGForms} />
                <Route path='/DGVerificationSearch' component={DGVerificationSearch} />
                <Route path='/DGVerificationMain' component={DGVerificationMain} />
                <Route path='/DGEvaluationSearch' component={DGEvaluationSearch} />
                <Route path='/DGEvaluationMain' component={DGEvaluationMain} />
                <Route path='/DGApprovalSearch' component={DGApprovalSearch} />
                <Route path='/DGApprovalMain' component={DGApprovalMain} />
                <Route path="/DGContracts" component={DGContracts} />
                <Route path="/Artisan2022" component={ArtisanForm2022} />
                <Route path="/AET2022" component={AETForm2022} />
                <Route path="/Bursary2022" component={BursaryForm2022} />
                <Route path="/Learnership2022" component={LearnershipForm2022} />
                <Route path="/Candidacy2022" component={CandidacyForm2022} />
                <Route path="/TVET2022" component={TVETForm2022} />
                <Route path="/LearnershipRPL2022" component={LearnershipRPLForm2022} />
                <Route path="/Internship2022" component={InternshipForm2022} />
                <Route path="/SkillsProgramme2022" component={SkillsProgrammeForm2022} />
                <Route path="/WorkExperience2022" component={WorkExperienceForm2022} />

                <Route path='/StakeholderSearch' component={StakeholderSearch} />
                <Route path='/Stakeholder' component={Stakeholder} />
                <Route path='/StakeholderOrganisationDetails' component={StakeholderOrganisationDetails} />
                <Route path='/DGOrganisationStakeholder' component={OrganisationStakeholder} />
                <Route path='/DGOrganisationCRM' component={DGOrganisationCRM} />
            
                {/*End DG Application*/}

                <Route path='/skillsOrganisationSearch' component={OrganisationSearch} />
                <Route path='/skillsOrganisation' component={Organisation} />
                <Route path='/skillsChildOrganisation' component={ChildOrganisation} />
                <Route path='/skillsOrganisationContacts' component={OrganisationContact} />
                <Route path='/skillsOrganisationBankingDetails' component={OrganisationBankingDetails} />
                <Route path='/skillsChildOrganisationSearch' component={ChildOrganisationSearch} />
                <Route path='/skillsOrganisationCFODetails' component={OrganisationCFODetails} />
                <Route path='/skillsTrainingCommitteeSearch' component={TrainingCommitteeSearchRecord} />
                <Route path='/skillsTrainingCommittee' component={TrainingCommittee} />
                <Route path='/skillsUnion' component={UnionSearchRecord} />
                <Route path='/skillsOrganisationSDF' component={OrganisationSDF} />
                <Route path='/skillsOrganisationLevies' component={OrganisationGrantsLevies} />
                <Route path='/Levies' component={Levies} />
                <Route path='/skillsOrganisationGrants' component={Grants} />
                <Route path='/skillsOrganisationDiscretionaryGrants' component={DiscretionaryGrants} />
                <Route path='/skillsOrganisationInterSetaTransfer' component={InterSetaTransfer} />
                <Route path='/skillsOrganisationLetter' component={SDFComplianceLetter} />

                <Route path='/EmployerFileImport' component={EmployerFileImport} />

                <Route path="/WspAtrForms" component={WspAtrForms} />
                <Route path="/EmploymentNumber2022" component={EmploymentNumberForm2022} />
                <Route path="/EmploymentNumberSmall2022" component={EmploymentNumberSmallForm2022} />
                <Route path="/AnnualTrainingReport2022" component={AnnualTrainingReportForm2022} />
                <Route path="/ActualPivotalTraining2022" component={ActualPivotalTrainingForm2022} />
                <Route path="/WorkplaceSkillsPlan2022" component={WorkplaceSkillsPlanForm2022} />
                <Route path="/QualificationProfile2022" component={QualificationProfileForm2022} />
                <Route path="/PlannedPivotalTraining2022" component={PlannedPivotalTrainingForm2022} />
                <Route path="/ActualBeneficiariesTraining2022" component={ActualBeneficiariesTrainingForm2022} />
                <Route path="/ActualAET2022" component={ActualAETForm2022} />
                <Route path="/PlannedBeneficiariesTraining2022" component={PlannedBeneficiariesTrainingForm2022} />
                <Route path="/ProvincialDetails2022" component={ProvincialDetailsForm2022} />
                <Route path="/PlannedAET2022" component={PlannedAETForm2022} />
                <Route path="/ImpactAssessment2022" component={ImpactAssessmentForm2022} />
                <Route path="/HardToFillVacancies2022" component={HardToFillVacanciesForm2022} />
                <Route path="/CheckList2022" component={CheckListForm2022} />
                <Route path="/wspAtrDashboard" component={WspAtrDashboard} />
                <Route path="/WspAtrDocumentsUpload" component={WspAtrDocumentsUpload} />
                <Route path="/WSPATRVerificationMain" component={WSPATRVerificationMain} />
                <Route path="/WspAtrVerification" component={WspAtrVerification} />
                <Route path="/WSPATREvaluation" component={WSPATREvaluation} />
                <Route path="/WSPATREvaluationMain" component={WSPATREvaluationMain} />
                <Route path="/WSPATRApprovalMain" component={WSPATRApprovalMain} />
                <Route path="/WspAtrApproval" component={WspAtrApproval} />
                <Route path="/Approval" component={Approval} />
                <Route path="/Evaluation" component={Evaluation} />
                <Route path="/Verification" component={Verification} />
                <Route path="/StackhoderFeedback" component={Feedback} />
                <Route path="/WspAtrLodgeApeal" component={LodgeApeal} />
                <Route path="/WspAtrExtensionRequest" component={RequestExtension} />


                <Route path='/ETQAReportSearch' component={ETQAReportSearch} />
                <Route path='/ETQAReport' component={ETQAReport} />
                <Route path='/SkillsReportSearch' component={SkillsReportSearch} />
                <Route path='/SkillsReport' component={SkillsReport} />
                <Route path='/MasterFileSearch' component={MasterFileSearch} />
                <Route path='/MasterFile' component={MasterFile} />
                <Route path='/SETMISFileSearch' component={SETMISFileSearch} />
                <Route path='/SETMISFile' component={SETMISFile} />
                <Route path='/DGReportSearch' component={DGReportSearch} />
                <Route path='/DGReport' component={DGReport} />
                <Route path='/CustomReports' component={CustomReports} />
                <Route path='/AdminReportSearch' component={AdminReportSearch} />
                <Route path='/AdminReport' component={AdminReport} />
                <Route path='/ProviderReportSearch' component={ProviderReportSearch} />
                <Route path='/ProviderReport' component={ProviderReport} />
                <Route path='/EmployerReportSearch' component={EmployerReportSearch} />
                <Route path='/EmployerReport' component={EmployerReport} />

                <Route path="/providerDocuments" component={ProviderDocuments} />
                <Route path="/assessorDocuments" component={AssessorDocuments} />
                <Route path="/moderatorDocuments" component={ModeratorDocuments} />
                <Route path="/learnerDocuments" component={LearnerDocuments} />
                <Route path="/bulkApproval" component={BulkApproval} />
                <Route path="/expiryNotification" component={ExpiryNotification} />
                <Route path="/learnerendorsementletter" component={LearnerEndorsementLetter} />
                <Route path="/ProofOfRegistration" component={POR} />

                <Route path='/AddEditAllocation' component={AddEditAllocation} />
                <Route path='/GrantAllocationSearch' component={GrantAllocationSearch} />
                <Route path='/GrantBudgetSearch' component={GrantBudgetSearch} />
                <Route path='/GrantSetupSearch' component={GrantSetupSearch} />
                <Route path='/AddEditTranche' component={AddEditTranche} />
                <Route path='/TrancheDetails' component={TrancheDetails} />
                <Route path='/ProcessingNav' component={ProcessingNav} />
                <Route path='/GenerateClaim' component={GenerateClaimSearch} />
                <Route path='/ApproveClaim' component={ApproveClaimSearch} />
                <Route path='/GrantClaimFormReversal' component={GrantClaimFormReversalSearch} />
                <Route path='/DownloadClaim' component={DownloadClaimSearch} />
                <Route path='/GenerateBatch' component={GenerateBatchSearch} />
                <Route path='/BatchSearch' component={BatchSearch} />
                <Route path='/DownloadBatch' component={DownloadBatchSearch} />
                <Route path='/AXProcessor' component={AXProcessorSearch} />
                <Route path='/GrantReversals' component={GrantReversalsSearch} />
                <Route path='/BulkGrantAssociation' component={BulkGrantAssociation} />
                <Route path='/BulkGrantDeAssociation' component={BulkGrantDeAssociation} />

                {/*Helpdesk*/}
                <Route path='/HelpdeskRequestSearch' component={HelpdeskRequestSearch} />
                <Route path='/HelpdeskRequest' component={HelpdeskRequest} />
                <Route path='/HelpDeskReport' component={HelpDeskReport} />
                {/*End Helpdesk*/}

                <Route path='/AudienceOptions' component={AudienceOptions} />

                {/*Agent*/}
                <Route path='/agentSearch' component={AgentSearch} />
                <Route path='/agent' component={Agent} />
                <Route path='/agentContacts' component={AgentContact} />
                <Route path='/agentUsers' component={AgentUsers} />
                <Route path='/agentAETSearch' component={AgentAET} />
                <Route path='/agentWorkExperienceSearch' component={AgentWorkExperience} />
                <Route path='/agentInternshipSearch' component={AgentInternship} />
                <Route path='/agentUnemployedYouthDevelopmentSearch' component={AgentUnemployedYouthDevelopment} />
                <Route path='/agentCandidacySearch' component={AgentCandidacy} />
                <Route path='/agentLearner' component={AgentLearner} />
                <Route path='/agentMEDPSearch' component={AgentMEDP} />
                <Route path='/agentMineCommunitySearch' component={AgentMineCommunity} />
                <Route path='/agentSmallScaleSearch' component={AgentSmallScale} />
                <Route path='/agentWorkplaceCoachesSearch' component={AgentWorkplaceCoaches} />
                <Route path='/agentLearnershipSearch' component={AgentLearnership} />
                <Route path='/agentTVETNCVSearch' component={AgentTVETNCV} />
                <Route path='/agentQCTOLearnershipSearch' component={AgentQCTOLearnership} />
                <Route path='/agentSCPPISearch' component={AgentSCPPI} />
                {/*End Agent*/}

                {/*Pre-Processor*/}
                <Route path='/GrantTypeSearch' component={GrantTypeSearch} />
                <Route path='/GrantTypeDetails' component={GrantTypeDetails} />
                <Route path='/GrantTypeAccountDetails' component={GrantTypeAccountDetails} />
                <Route path='/LevyAccountSearch' component={LevyAccountSearch} />
                <Route path='/GrantImport' component={GrantImport} />
                <Route path='/LevyImport' component={LevyImport} />
                <Route path='/LevyTransaction' component={LevyTransaction} />
                <Route path='/GrantTransactions' component={GrantTransaction} />
                {/*Pre-Processor*/}

                {/*GrantDueReports*/}
                <Route path='/GrantDueReports' component={GrantDueReports} />

                {/*QALPDashboard*/}
                <Route path='/qalpdashboard' component={QALPDashboard} />

                {/*MG DASHBOARD*/}
                <Route path='/mgdashboard' component={MGDashboard} />

                {/*WSP ATR Evaluation Sheet*/}
                <Route path='/WspAtrEvaluationSheet' component={WspAtrEvaluationSheet} />


                {/*Bursary Setup*/}
                <Route path='/bursarySetupSearch' component={BursarySetupSearch} />
                <Route path='/bursarySetup' component={BursarySetup} />
                <Route path='/bursarydocuments' component={BursaryDocumentSearch} />

                {/*Bursary Application*/}
                <Route path='/AddBursaryApplicationFlow' component={AddBursaryApplicationFlow} />
                <Route path='/BursaryApplicationEvaluationSearch' component={BursaryApplicationEvaluationSearch} />
                <Route path='/bursaryApplicationEvaluation' component={BursaryApplicationEvaluation} />
                <Route path='/bursaryApplicationTypeEvaluation' component={BursaryApplicationTypeEvaluation} />
                <Route path='/bursaryApplicationEvaluationAll' component={BursaryApplicationEvaluationAll} />
                <Route path='/bursaryApplicationEvaluationDetail' component={BursaryApplicationEvaluationDetail} />


                {/*QCTO Module */}
                <Route path='/qctomoduleSetupSearch' component={QCTOModuleSetupSearch} />
                <Route path='/qctomoduleSetup' component={QCTOModuleSetup} />
                <Route path='/qctomoduleDocuments' component={QCTOModuleDocumentSearch} />
                <Route path='/qctoModuleQualifications' component={QCTOModuleQualificationSearch} />
                <Route path='/qctoModuleSkillsProgrammes' component={QCTOModuleSkillsProgrammeSearch} />

                {/*QCTO Qualification */}
                <Route path='/qctoQualificationSetupSearch' component={QCTOQualificationSetupSearch} />
                <Route path='/qctoQualificationSetup' component={QCTOQualificationSetup} />
                <Route path='/qctoQualificationDocuments' component={QCTOQualificationDocumentSearch} />
                <Route path='/qctoQualModuleSearch' component={QCTOQualificationModuleSearch} />
                <Route path='/qctoQualificationSkillsProgrammes' component={QCTOQualificationSkillsProgrammeSearch} />
                <Route path='/qctoQualificationLearnerships' component={QCTOQualificationLearnershipSearch} />

                {/*QCTO Learnership */}
                <Route path='/qctoLearnershipSetupSearch' component={QCTOLearnershipSetupSearch} />
                <Route path='/qctoLearnershipSetup' component={QCTOLearnershipSetup} />
                <Route path='/qctoLearnershipDocuments' component={QCTOLearnershipDocumentSearch} />
                <Route path='/qctoLearnershipModuleSearch' component={QCTOLearnershipModuleSearch} />
                <Route path='/qctolearnershipagreements' component={QCTOLearnershipAgreementSearch} />

                {/*QCTO SkillsProgramme */}
                <Route path='/qctoSkillsProgrammeSetupSearch' component={QCTOSkillsProgrammeSetupSearch} />
                <Route path='/qctoSkillsProgrammeSetup' component={QCTOSkillsProgrammeSetup} />
                <Route path='/qctoSkillsProgrammeDocuments' component={QCTOSkillsProgrammeDocumentSearch} />
                <Route path='/qctoSkillsProgrammeModuleSearch' component={QCTOSkillsProgrammeModuleSearch} />

                {/*Skills Development Provider*/}
                <Route path='/sdproviderSearch' component={SDProviderSearch} />
                <Route path='/sdprovider' component={SDProvider} />
                <Route path='/sdproviderContacts' component={SDProviderContact} />
                <Route path='/sdproviderUsers' component={SDProviderUsers} />
                <Route path='/sdproviderLearner' component={SDProviderLearner} />
                <Route path='/sdproviderAssessorSearch' component={SDProviderAssessorSearch} />
                <Route path='/sdproviderModeratorSearch' component={SDProviderModeratorSearch} />
                <Route path='/sdproviderQCTOQualificationsSearch' component={SDProviderQCTOQualificationSearch} />
                <Route path='/sdproviderQCTOQualificationModuleSearch' component={SDProviderQCTOQualificationModuleSearch} />
                <Route path='/sdproviderQCTOLearnershipSearch' component={SDProviderQCTOLearnershipSearch} />
                <Route path='/sdproviderQCTOSkillsProgrammeSearch' component={SDProviderQCTOSkillsProgrammeSearch} />

                {/*Workplace Approval*/}
                <Route path='/workplaceApprovalSearch' component={WorkplaceApprovalSearch} />
                <Route path='/workplaceapproval' component={WorkplaceApproval} />
                <Route path='/workplaceApprovalUsers' component={WorkplaceApprovalUsers} />
                <Route path='/workplaceApprovalContacts' component={WorkplaceApprovalContact} />
                <Route path='/workplaceApprovalLearner' component={WorkplaceApprovalLearner} />
                <Route path='/workplaceApprovalQCTOQualificationsSearch' component={WorkplaceApprovalQCTOQualificationSearch} />
                <Route path='/workplaceApprovalQCTOQualificationModuleSearch' component={WorkplaceApprovalQCTOQualificationModuleSearch} />
                <Route path='/workplaceApprovalQCTOLearnershipSearch' component={WorkplaceApprovalQCTOLearnershipSearch} />
                <Route path='/workplaceApprovalQCTOSkillsProgrammeSearch' component={WorkplaceApprovalQCTOSkillsProgrammeSearch} />
                <Route path='/workplaceApprovalCoachMentorSearch' component={WorkplaceApprovalCoachMentorSearch} />
                <Route path='/workplaceApprovalDocuments' component={WorkplaceApprovalDocumentSearch} />

                {/*Assessment Centre*/}
                <Route path='/assessmentcentreSearch' component={AssessmentCentreSearch} />
                <Route path='/assessmentcentre' component={AssessmentCentre} />
                <Route path='/assessmentcentreContacts' component={AssessmentCentreContact} />
                <Route path='/assessmentcentreUsers' component={AssessmentCentreUsers} />
                <Route path='/assessmentcentreLearner' component={AssessmentCentreLearner} />
                <Route path='/assessmentcentreAssessorSearch' component={AssessmentCentreAssessorSearch} />
                <Route path='/assessmentcentreModeratorSearch' component={AssessmentCentreModeratorSearch} />
                <Route path='/assessmentcentreQCTOQualificationsSearch' component={AssessmentCentreQCTOQualificationSearch} />
                <Route path='/assessmentcentreQCTOQualificationModuleSearch' component={AssessmentCentreQCTOQualificationModuleSearch} />
                <Route path='/assessmentcentreQCTOLearnershipSearch' component={AssessmentCentreQCTOLearnershipSearch} />
                <Route path='/assessmentcentreDocuments' component={AssessmentCentreDocumentSearch} />

                {/*Coach Mentor*/}
                <Route path='/coachmentor' component={CoachMentor} />
                <Route path='/coachmentorSearch' component={CoachMentorSearch} />
                <Route path='/addCoachMentorFlow' component={AddCoachMentorFlow} />
                <Route path='/CoachMentorlearnership' component={CoachMentorlearnership} />
                <Route path='/CoachMentorSkillsProgramme' component={CoachMentorSkillsProgramme} />
                <Route path='/coachmentorQualification' component={CoachMentorQualification} />
                <Route path='/coachmentorTradeQualification' component={CoachMentorTradeQualification} />
                <Route path='/coachmentorUnitStandard' component={CoachMentorUnitStandards} />
                <Route path='/coachmentorWorkplaceApproval' component={CoachMentorWorkplaceApprovalSearch} />
                <Route path='/coachmentorRegistrationDetails' component={CoachMentorRegistrationDetails} />
                <Route path='/coachmentorRegistrationHistory' component={CoachMentorRegistrationHistory} />
                <Route path='/coachmentorCRM' component={CoachMentorCRM} />

                {/*Learner AC/CM/SDP*/}
                <Route path='/learnerAssessmentCentre' component={LearnerAC} />
                <Route path='/learnerWorkplaceApproval' component={LearnerWA} />
                <Route path='/learnerSDProvider' component={LearnerSDProvider} />

                {/*EISA Enrolment*/}
                <Route path='/learnerEISAEnrolment' component={LearnerEISAEnrolmentSearch} />
                {/*Learner QCTO Registrations*/}
                <Route path='/LearnerQCTOLearnershipSearch' component={LearnerQCTOLearnershipSearch} />
                <Route path='/addLearnerQCTOLearnership' component={AddLearnerQCTOLearnership} />
                <Route path='/learnerEISAReadinessSearch' component={LearnerEISAReadinessSearch} />
                <Route path='/learnerQCTOLearnershipDocuments' component={LearnerQCTOLearnershipDocumentSearch} />
                <Route path='/LearnerQCTOArtisanSearch' component={LearnerQCTOArtisanSearch} />
                <Route path='/LearnerQCTOLearnershipAssessments' component={LearnerQCTOLearnershipAssessments} />
                <Route path='/ViewQCTOLearnershipAssessment' component={ViewQCTOLearnershipAssessment} />
                <Route path='/addLearnerQCTOArtisan' component={AddLearnerQCTOArtisan} />
                <Route path='/ViewEISADetails' component={ViewEISADetails} />
                <Route path='/learnerQCTOArtisanDocuments' component={LearnerQCTOArtisanDocumentSearch} />
                <Route path='/ViewQCTONAMB' component={ViewQCTONAMB} />
                <Route path='/LearnerQCTOLearnershipAchievements' component={LearnerQCTOLearnershipAchievements} />
                <Route path='/ViewQCTOLearnershipAchievementModule' component={ViewQCTOLearnershipAchievementModule} />
                <Route path='/LearnerQCTOArtisanAssessments' component={LearnerQCTOArtisanAssessments} />
                <Route path='/ViewQCTOArtisanAssessment' component={ViewQCTOArtisanAssessment} />
                <Route path='/ViewLearnerQCTOArtisanEISADetails' component={ViewLearnerQCTOArtEISADetails} />
                <Route path='/ViewLearnerQCTOArtisanNAMB' component={ViewLearnerQCTOArtNAMB} />
                <Route path='/LearnerQCTOSkillsProgrammeSearch' component={LearnerQCTOSkillsProgrammeSearch} />
                <Route path='/addLearnerQCTOSkillsProgramme' component={AddLearnerQCTOSkillsProgramme} />
                <Route path='/learnerQCTOSkillsProgrammeDocuments' component={LearnerQCTOSkillsProgrammeDocumentSearch} />
                <Route path='/LearnerQCTOSkillsProgrammeAssessment' component={LearnerQCTOSkillsProgrammeAssessment} />
                <Route path='/ViewQCTOSkillsProgrammeAssessment' component={ViewQCTOSkillsProgrammeAssessment} />
                <Route path='/LearnerQCTOSkillsProgrammeAchievements' component={LearnerQCTOSkillsProgrammeAchievement} />
                <Route path='/ViewQCTOSkillsProgrammeAchievementModule' component={ViewQCTOSkillsProgrammeAchievementModule} />

                {/*ID VERIFICATION*/}
                <Route path='/IDVerificationPortal' component={IDVerificationPortal} />

                {/*CUSTOM REPORTS*/}
                <Route path='/CustomReportsV2' component={CustomReportsV2} />
                {/*Inhouse/Industry/Based Training Course Setup*/}
                <Route path='/iibtcpSetupSearch' component={IIBTCPSetupSearch} />
                <Route path='/IIBTCPSetup' component={IIBTCPSetup} />
                <Route path='/iibtcpdocuments' component={IIBTCPDocumentSearch} />
                {/*Provider Inhouse/Industry/Based Training Course Setup*/}
                <Route path='/providerIIBTCPSearch' component={ProviderIIBTCPSearch} />
                {/*Learner Inhouse/Industry/Based Training Course */}
                <Route path='/LearnerIIBTCPSearch' component={LearnerIIBTCPSearch} />
                <Route path='/learnerIIBTCPDocuments' component={LearnerIIBTCPDocumentSearch} />
                <Route path='/addLearnerIIBTCP' component={AddLearnerIIBTCP} />

            </span>
        );
    }
}

export default withRouter(connect()(Routes));

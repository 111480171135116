import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import { CustomTextField } from '../../widgets/CustomTextField';
import { SelectItem } from '../../widgets/SelectItem';
import { Row, Col } from 'react-bootstrap';
import InstitutionSearch from '../program/InstitutionSearch';
import { LookupField } from '../../widgets/LookupField';
import NonNQFQualificationSearch from '../program/LearnerNonNQFQualificationSearch';
import BursaryInstitutionSearch from '../program/BursaryInstitutionSearch';

export default function BursaryApplicationEvaluationSearchForm(props) {
    return (
        <EntityForm ref={React.createRef()} viewId="bursEval" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="BursaryApplicationEvaluationSearchForm">
                    <div className="row search-form-container">
                        <div className="col form-col">
                            <SelectItem
                                parentId={0}
                                dataUrl="api/sims/BursaryApplicationEvaluation/GetLookupList?listId=AcademicYearOfApplication"
                                id="AcademicApplicationYearID"
                                required={true}
                                label="Academic Application Year"
                                className="w-100"
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <SelectItem
                                parentId={0}
                                dataUrl="api/sims/BursaryApplicationEvaluation/GetLookupList?listId=BursaryApplicationStatus"
                                id="BursaryApplicationStatusID"
                                label="Status"
                                className="w-100"
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <SelectItem
                                parentId={0}
                                dataUrl="api/sims/BursaryApplicationEvaluation/GetLookupList?listId=BursaryApplicationType"
                                id="BursaryApplicationTypeID"
                                label="Bursary Application Type"
                                className="w-100"
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <CustomTextField
                                id="IDNo"
                                label="ID Number"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <LookupField
                                id="BursaryInstitutionID"
                                label="Institution"
                                className="w-100"
                                form={form}
                            >
                                <BursaryInstitutionSearch showTitle={true} mode='lookup'
                                    dataUrl='api/sims/BursaryApplicationEvaluation/GetBursaryInstitution'
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("BursaryInstitutionID", value)
                                    }} autoFetchData={true} selection={props.selection} />
                            </LookupField>
                        </div>
                        <div className="col form-col">
                            <LookupField
                                id="BursaryQualificationID"
                                label="Qualification"
                                className="w-100"
                                form={form}
                            >
                                <NonNQFQualificationSearch showTitle={true} mode='lookup'
                                    dataUrl='api/sims/BursaryApplicationEvaluation/NonNQFQualificationSearch'
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("BursaryQualificationID", value)
                                    }} autoFetchData={true} selection={props.selection} />
                            </LookupField>
                        </div>
                    </div>
                </form>
            }
        </EntityForm>
    );
}

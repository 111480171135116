import React, { useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { LookupField } from '../../../widgets/LookupField';
import SDProviderSearch from '../../sdprovider/SDProviderSearch';
import WASearch from '../../workplaceapproval/WorkplaceApprovalSearch';
import ACSearch from '../../assessmentcentre/AssessmentCentreSearch';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from "../../../ViewUtils";
import QCTOArtisanSetupSearch from '../../program/QCTOLearnership/QCTOArtisanSetupSearch';


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
export const LearnerQCTOArtisanForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function TerminationCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Terminated") {
                return true
            }
            else if (value.Description !== "Terminated") {
                return false
            }
        }
    }

    function SponsorCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "SETA Funded") {
                return true
            }
            else if (value.Description !== "Industry Funded") {
                return false
            }
        }
    }

    function SocioCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Employed") {
                return true
            }
            else if (value.Description !== "Employed") {
                return false
            }
        }
    }

    function terminationReasonCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Other") {
                return true
            }
            else if (value.Description !== "Other") {
                return false
            }
        }
    }

    function YesNoCompulsoryCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Yes") {
                return true
            }
            else if (value.Description !== "Yes") {
                return false
            }
        }
    }

    function GetIDFromValue(value) {
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }

    useEffect(() => {

    }, [])

    return (
        <EntityForm ref={ref} values={props.data} viewId="TradeSetup" dataURL="api/sims/LearnerQCTOArtisan/GetLearnerLinkedQCTOArtisanDetails"
            entityLookupId={props.id} org="saseta" useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="learnerQCTOArtisanForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="QCTO Artisan Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="AgreementReferenceNumber"
                                                key={tabValue}
                                                label="Artisan Agreement Reference Number"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <LookupField
                                                required={true}
                                                id="QCTOLearnershipID"
                                                label="Artisan"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <QCTOArtisanSetupSearch showTitle={false} mode='lookup' dataUrl="api/sims/LearnerQCTOArtisan/QCTOLearnershipSearch"
                                                    onLookupValueChange={(value) => form.setLookupSelection("QCTOLearnershipID", value)} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="LeadSDProviderID"
                                                label="Lead Skills Development Provider"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                                required
                                            >
                                                <SDProviderSearch showTitle={false} mode='lookup' dataUrl="api/sims/LearnerQCTOArtisan/SDProviderSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("LeadSDProviderID", value)
                                                        props.onChangeSDProviderIDHandler(form, "LeadSDProviderID", null);
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <LookupField
                                                id="LeadWAID"
                                                label="Lead Workplace Approval"
                                                className="w-100"
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                required
                                            >
                                                <WASearch showTitle={false} mode='lookup' dataUrl="api/sims/LearnerQCTOArtisan/WASearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("LeadWAID", value)
                                                        props.onChangeWAIDHandler(form, "LeadWAID", null);
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/LearnerQCTOArtisan/GetLookupList?listId=YesNo"
                                                id="SDProviderSkillsLevyID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Lead SDP Skills Levy"
                                                className="{classes.select} w-100"
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />

                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/LearnerQCTOArtisan/GetLookupList?listId=YesNo"
                                                id="WASkillsLevyID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Lead Workplace Approval Skills Levy"
                                                className="{classes.select} w-100"
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl={'api/sims/LearnerQCTOArtisan/GetLearnerQCTOProgrammeContacts?parameter=[{"Name":"ID","Value":' + GetIDFromValue(form.values["LeadSDProviderID"]) + '},{"Name":"Section","Value":"SDP"}]'}
                                                id="SDProviderContactID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Lead SDP Contact"
                                                className="{classes.select} w-100"
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                clearOptions={props.clearOptions}
                                                clearOptionsHandler={props.clearOptionsHandler}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl={'api/sims/LearnerQCTOArtisan/GetLearnerQCTOProgrammeContacts?parameter=[{"Name":"ID","Value":' + GetIDFromValue(form.values["LeadWAID"]) + '},{"Name":"Section","Value":"WA"}]'}
                                                id="WAContactID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Lead Workplace Approval Contact"
                                                className="{classes.select} w-100"
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                clearOptions={props.clearOptions}
                                                clearOptionsHandler={props.clearOptionsHandler}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="SecondarySDProviderID"
                                                label="Secondary Skills Development Provider"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <SDProviderSearch showTitle={false} mode='lookup' dataUrl="api/sims/LearnerQCTOArtisan/SDProviderSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("SecondarySDProviderID", value)
                                                        props.onChangeSDProviderIDHandler(form, "SecondarySDProviderID", null);
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <LookupField
                                                id="SecondaryWAID"
                                                label="Secondary Workplace Approval"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <WASearch showTitle={false} mode='lookup' dataUrl="api/sims/LearnerQCTOArtisan/WASearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("SecondaryWAID", value)
                                                        props.onChangeWAIDHandler(form, "SecondaryWAID", null);
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/LearnerQCTOArtisan/GetLookupList?listId=YesNo"
                                                id="SecondarySDProviderSkillsLevyID"
                                                parentId={0}
                                                required={!utils.isNull(form.values['SecondarySDProviderID']) ? form.values['SecondarySDProviderID'] !== "0" : false}
                                                key={tabValue}
                                                label="Secondary SDP Skills Levy"
                                                className="{classes.select} w-100"
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />

                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/LearnerQCTOArtisan/GetLookupList?listId=YesNo"
                                                id="SecondaryWASkillsLevyID"
                                                parentId={0}
                                                required={!utils.isNull(form.values['SecondaryWAID']) ? form.values['SecondaryWAID'] !== "0" : false}
                                                key={tabValue}
                                                label="Secondary Workplace Approval Skills Levy"
                                                className="{classes.select} w-100"
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl={'api/sims/LearnerQCTOArtisan/GetLearnerQCTOProgrammeContacts?parameter=[{"Name":"ID","Value":' + GetIDFromValue(form.values["SecondarySDProviderID"]) + '},{"Name":"Section","Value":"SDP"}]'}
                                                id="SecondarySDProviderContactID"
                                                parentId={0}
                                                required={!utils.isNull(form.values['SecondarySDProviderID']) ? form.values['SecondarySDProviderID'] !== "0" : false}
                                                key={tabValue}
                                                label="Secondary SDP Contact"
                                                className="{classes.select} w-100"
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                clearOptions={props.clearOptions}
                                                clearOptionsHandler={props.clearOptionsHandler}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl={'api/sims/LearnerQCTOArtisan/GetLearnerQCTOProgrammeContacts?parameter=[{"Name":"ID","Value":' + GetIDFromValue(form.values["SecondaryWAID"]) + '},{"Name":"Section","Value":"WA"}]'}
                                                id="SecondaryWAContactID"
                                                parentId={0}
                                                required={!utils.isNull(form.values['SecondaryWAID']) ? form.values['SecondaryWAID'] !== "0" : false}
                                                key={tabValue}
                                                label="Secondary Workplace Approval Contact"
                                                className="{classes.select} w-100"
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                clearOptions={props.clearOptions}
                                                clearOptionsHandler={props.clearOptionsHandler}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1">
                                            <SelectItem
                                                dataUrl="api/sims/LearnerQCTOArtisan/GetLookupList?listId=QualificationEntryRequirements"
                                                id="QualificationRequirementsID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Qualification Entry Requirements"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <LookupField
                                                id="ACID"
                                                label="Assessment Centre"
                                                className="w-100"
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                            >
                                                <ACSearch showTitle={false} mode='lookup' dataUrl="api/sims/LearnerQCTOArtisan/ACSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("ACID", value)
                                                        props.onChangeACIDHandler(form, "ACID", null);
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/LearnerQCTOArtisan/GetLookupList?listId=YesNo"
                                                id="ACSkillsLevyID"
                                                parentId={0}
                                                required={!utils.isNull(form.values['ACID']) ? form.values['ACID'] !== "0" : false}
                                                key={tabValue}
                                                label="Assessment Centre Skills Levy"
                                                className="{classes.select} w-100"
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl={'api/sims/LearnerQCTOArtisan/GetLearnerQCTOProgrammeContacts?parameter=[{"Name":"ID","Value":' + GetIDFromValue(form.values["ACID"]) + '},{"Name":"Section","Value":"AC"}]'}
                                                id="ACContactID"
                                                parentId={0}
                                                required={!utils.isNull(form.values['ACID']) ? form.values['ACID'] !== "0" : false}
                                                key={tabValue}
                                                label="Assessment Centre Contact"
                                                className="{classes.select} w-100"
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                clearOptions={props.clearOptions}
                                                clearOptionsHandler={props.clearOptionsHandler}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1">
                                            <SelectItem
                                                dataUrl="api/sims/LearnerQCTOArtisan/GetLookupList?listId=SocioEconomicStatus"
                                                id="SocioEconomicStatusID"
                                                parentId={0}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                required
                                                key={tabValue}
                                                label="Socio Economic Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td colSpan="1">
                                            <SelectItem
                                                dataUrl="api/sims/LearnerQCTOArtisan/GetLookupList?listId=Sponsorship"
                                                id="SponsorshipID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Sponsorship"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                required
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ContractNumber"
                                                key={tabValue}
                                                label="Contract Number"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td colSpan="1">
                                            <SelectItem
                                                dataUrl="api/sims/LearnerQCTOArtisan/GetLookupList?listId=FinancialYear"
                                                id="FinancialYearID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Financial Year"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="CommencementDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="Commencement Date"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="CompletionDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Completion Date"
                                                className='{classes.textField} w-100 '
                                                required={!TerminationCheck(form.values["QCTOProgrammeStatusID"])}
                                                validator={{
                                                    script: (formValues) => {
                                                        return !utils.isNull(formValues)
                                                            && !utils.isNull(formValues.CommencementDate)
                                                            && formValues.CommencementDate.getTime() < formValues.CompletionDate.getTime()
                                                    }, message: "Start date must be before the end date"
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="CompletionProcessedDate"
                                                key={tabValue}
                                                label="Completion Processed Date"
                                                disabled
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td colSpan="1">
                                            <SelectItem
                                                dataUrl="api/sims/LearnerQCTOArtisan/GetLookupList?listId=QCTOArtisanType"
                                                id="QCTOArtisanTypeID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="QCTO Artisan Type"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <SelectItem
                                                        dataUrl={'api/sims/LearnerQCTOArtisan/GetQCTOProgrammeStatusByRole?parameter=[{"Name":"QCTOProgrammeStatusId","Value":' + GetIDFromValue(form.values["QCTOProgrammeStatusID"]) + '},{"Name":"ProgrammeID","Value":' + props.id + '}]'}
                                                        id="QCTOProgrammeStatusID"
                                                        parentId={0}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !props.editPermission.Edit}
                                                        key={tabValue}
                                                        label="Programme Status"
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="MostRecentRegistrationDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Most Recent Registration Date"
                                                        className='{classes.textField} w-100 '
                                                        required
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }

                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/LearnerQCTOArtisan/GetLookupList?listId=YesNo"
                                                id="PreviousLearnership"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Have you previously undertaken a learnership?"
                                                className="{classes.select} w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="PreviousLearnershipCode"
                                                key={tabValue}
                                                label="Learnership Code"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                                required={YesNoCompulsoryCheck(form.values["PreviousLearnership"])}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="PreviousLearnershipTitle"
                                                key={tabValue}
                                                label="Learnership Title"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                                required={YesNoCompulsoryCheck(form.values["PreviousLearnership"])}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/LearnerQCTOArtisan/GetLookupList?listId=YesNo"
                                                id="PreviousEmployed"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Were you employed by your employer before concluding this agreement?"
                                                className="{classes.select} w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="LearnerEmployed"
                                                key={tabValue}
                                                label="When did the learner start work with the employer"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                                required={YesNoCompulsoryCheck(form.values["PreviousEmployed"])}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/LearnerQCTOArtisan/GetLookupList?listId=YesNo"
                                                id="WPAgreement"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Were you party to a workplace-based learning programme agreement at any time in the past before concluding this agreement?"
                                                className="{classes.select} w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="DurationLearneremployed"
                                                key={tabValue}
                                                label="How long was the learner unemployed"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                                required={!YesNoCompulsoryCheck(form.values["PreviousEmployed"])}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/LearnerQCTOArtisan/GetLookupList?listId=YesNo"
                                                id="IsTermsEmployment"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Are the learners terms of employment determined by a document of general application"
                                                className="{classes.select} w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="TermsEmployment"
                                                key={tabValue}
                                                label="Please specify terms of employment"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                                required
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/LearnerQCTOArtisan/GetLookupList?listId=YesNo"
                                                id="EmpContract"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Is the learner’s contract of employment specific to the period of the agreement?"
                                                className="{classes.select} w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/LearnerQCTOArtisan/GetLookupList?listId=YesNo"
                                                id="EmpContractCopy"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Does the learner have a copy of the contract of employment?"
                                                className="{classes.select} w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ResponsibleSETA"
                                                key={tabValue}
                                                label="SETA responsible for agreement"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                                required
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="AssPartner"
                                                key={tabValue}
                                                label="Assessment Quality Partner"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                                required
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="RegSAQA"
                                                key={tabValue}
                                                label="Designation registered with SAQA if applicable"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                            /*required*/
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="CurRegNumber"
                                                key={tabValue}
                                                label="Curriculum registration number associated with the agreement"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                            /*required*/
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="QCTO"
                                                key={tabValue}
                                                label="QCTO appointed Assessment Quality Partner (AQP) associated with the workplace-based agreement"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                            /*required*/
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="Occupation"
                                                key={tabValue}
                                                label="Occupation that this qualification or part qualification is related to (as per the Organizing Framework of Occupations(OFO))"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                            /*required*/
                                            />
                                        </td>
                                        {
                                            (!props.hideFields) ?
                                                <td>
                                                    <SelectItem
                                                        dataUrl={'api/sims/LearnerQCTOArtisan/GetLearnerARTInterventionGrantType?parameter=[{"Name":"GrantTypeID","Value":' + GetIDFromValue(form.values["GrantTypeID"]) + '}]'}
                                                        id="GrantTypeID"
                                                        parentId={0}
                                                        key={tabValue}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !props.editPermission.Edit}
                                                        label="Grant Type"
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                                :
                                                ""
                                        }
                                    </tr>
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="ExtensionDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Extension Date"
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="DateExtensionCaptured"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Extension Captured Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="ExtensionReason"
                                                        key={tabValue}
                                                        label="Extension Reason"
                                                        className="w-100 "
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="TerminationDate"
                                                        key={tabValue}
                                                        label="Termination Date"
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["QCTOProgrammeStatusID"])}
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td >
                                                    <CustomTextField
                                                        id="TerminatedBy"
                                                        key={tabValue}
                                                        label="Terminated By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td >
                                                    <SelectItem
                                                        dataUrl="api/sims/LearnerQCTOArtisan/GetLookupList?listId=TerminationReason"
                                                        id="TerminationReasonID"
                                                        parentId={0}
                                                        key={tabValue}
                                                        label="Termination Reason"
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        required={TerminationCheck(form.values["QCTOProgrammeStatusID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["QCTOProgrammeStatusID"])}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="OtherTerminationReason"
                                                        key={tabValue}
                                                        label="Other Termination Reason"
                                                        className="w-100 "
                                                        required={terminationReasonCheck(form.values["TerminationReasonID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !terminationReasonCheck(form.values["TerminationReasonID"])}
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="ActualTerminationDate"
                                                        key={tabValue}
                                                        label="Actual Termination Date"
                                                        disabled
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td >
                                                    <SelectItem
                                                        dataUrl="api/sims/LearnerQCTOArtisan/GetLookupList?listId=EnrolmentStatusReason"
                                                        id="EnrolmentStatusReasonID"
                                                        parentId={0}
                                                        key={tabValue}
                                                        label="Enrolment Status Reason"
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="StatusEffectiveDate"
                                                        key={tabValue}
                                                        label="Status Effective Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="CaptureDate"
                                                        key={tabValue}
                                                        label="Capture Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="CaptureByUser"
                                                        key={tabValue}
                                                        label="Capture By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="LastDateUpdated"
                                                        key={tabValue}
                                                        label="Last Date Updated"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="LastUpdatedByUser"
                                                        key={tabValue}
                                                        label="Last Updated By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="RegistrationDate"
                                                        key={tabValue}
                                                        label="Registration Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="RegisteredBy"
                                                        key={tabValue}
                                                        label="Registered By"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})

import React from 'react';
import { EntityForm } from '../../../widgets/Forms';
import { SelectItem } from '../../../widgets/SelectItem';
import { LookupField } from '../../../widgets/LookupField';
import EmployerSearch from '../../employer/EmployerSearch';
import BursaryInstitutionSearch from '../../program/BursaryInstitutionSearch';
import WorkplaceApprovalSearch from '../../workplaceapproval/WorkplaceApprovalSearch';


export default function GrantClaimFormReversalSearchForm(props) {
    function EmployerInstitutionWACheck(value, value2) {
        if ((value !== undefined && value !== null) || (value2 !== undefined && value2 !== null)) {
            return true
        }
        else {
            return false
        }
    }

    function EmpReqCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Bursary")  {
                return false
            }
            else  {
                return true
            }
        }
    }

    function InstReqCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Bursary")  {
                return true
            }
            else  {
                return false
            }
        }
    }

    return (
        <EntityForm ref={React.createRef()} viewId="GrantClaimFormReversalSearchForm" values={props.data}
            useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="GrantClaimFormReversalSearchForm">
                    <td>
                        <SelectItem
                            dataUrl={"api/sims/GrantClaimFormReversal/GetLookupList?listId=TrancheCode"}
                            id="TrancheCodeID"
                            label="Intervention Type"
                            className="w-150"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                            required
                        />
                    </td>
                    <td>
                        <LookupField
                            id="EmployerID"
                            label="Employer"
                            className="w-100"
                            form={form}
                            disabled={EmployerInstitutionWACheck(form.values["BursaryInstitutionID"], form.values["WAID"])}
                            dohandleChangeClose={true}
                            //required={EmpReqCheck(form.values["TrancheCodeID"])}
                        >
                            <EmployerSearch showTitle={false} mode='lookup' dataUrl="api/sims/GrantClaimFormReversal/EmployerSearch"
                                onLookupValueChange={(value) => {
                                    form.setLookupSelection("EmployerID", value)
                                }} autoFetchData={false} selection={props.selection} />
                        </LookupField>
                    </td>
                    <td>
                        <LookupField
                            id="WAID"
                            label="Workplace Approval"
                            className="w-100"
                            form={form}
                            disabled={EmployerInstitutionWACheck(form.values["BursaryInstitutionID"], form.values["EmployerID"])}
                            dohandleChangeClose={true}
                        >
                            <WorkplaceApprovalSearch showTitle={false} mode='lookup' dataUrl="api/sims/GenerateClaim/WorkplaceApprovalSearch"
                                onLookupValueChange={(value) => {
                                    form.setLookupSelection("WAID", value)
                                }} autoFetchData={false} selection={props.selection} />
                        </LookupField>
                    </td>
                    <td>
                        <LookupField
                            id="BursaryInstitutionID"
                            label="Bursary Institution"
                            className="filterContainer"
                            form={form}
                            disabled={EmployerInstitutionWACheck(form.values["EmployerID"], form.values["WAID"])}
                            dohandleChangeClose={true }
                            //required={InstReqCheck(form.values["TrancheCodeID"])}
                        >
                            <BursaryInstitutionSearch showTitle={false} mode='lookup' dataUrl={"api/sims/BursaryApplication/GetBursaryInstitution"}
                                onLookupValueChange={(value) => {
                                    form.setLookupSelection("BursaryInstitutionID", value)
                                }} autoFetchData={false} selection={props.selection} />
                        </LookupField>
                    </td>
                    <br>
                    </br>
                </form>
            }
        </EntityForm>
    );
}

import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import GrantClaimFormReversalSearchForm from './GrantClaimFormReversalSearchForm';
import AlertItem from "../../../widgets/AlertItem";
import SearchView from "../../../widgets/SearchView";
import { GrantClaimReversalDetailForm } from "./GrantClaimReversalDetailForm";
import FormDialog from "../../FormDialog";
import withMenuPermission from "../../../widgets/withMenuPermission";
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from "../../../ViewUtils";

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

const roleSRU = "SRU Administrator";

const headCells = [
    { id: 'ClaimNumber', numeric: false, disablePadding: false, label: 'Claim Number' },
    { id: 'ClaimDate', numeric: false, disablePadding: false, label: 'Claim Date' },
    { id: 'OrganisationSDLNumber', numeric: false, disablePadding: false, label: 'Vendor Number' },
    { id: 'RequestedBy', numeric: false, disablePadding: false, label: 'Requested By' },
    { id: 'RequestedDate', numeric: false, disablePadding: false, label: 'Requested Date' }
];


class GrantClaimFormReversalSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            model: {
                TrancheCodeID: null,
                EmployerID: null,
                BursaryInstitutionID: null,
                WAID: null,
            },
            IDs: null,
            keyUpSwitch: false,
            clearGrid: false,
            formDialogOpen: false,
            message: null,
            messageStyle: null,
            searchParameters: [{ Name: "TrancheCodeID", Value: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.TrancheCodeID)) ? this.props.location.state.TrancheCodeID : null }],
            TrancheCodeID: null
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        {id: 'claimReversal', label: 'Reverse Claim', tooltip: 'Reverse Claim',disabled: utils.isNull(this.state.IDs)},
                        {id: 'downloadClaimForm', label: 'Download Claim Form', icon: 'DownloadIcon', tooltip: 'Download claim form for selected record',disabled: utils.isNull(this.state.selectedClaims)}
                    ]
                }
            ]
        };

    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    downloadClaim = () => {

        let data = {
            Id: this.state.IDs[0].Id,
            TrancheCodeID: this.state.model.TrancheCodeID.Id
        }
        let url = "api/sims/GrantClaimFormReversal/GrantClaimReveralDownload";
        utils.invokeUrl(url, (response) => {
            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
            toolbarRef.current.setButtonDisabled("downloadClaimForm", true);
        },
            null,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        )
    };

    handleSelections = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ IDs: selection, Message: "" },
                () => {
                    if (utils.getCurrentRole() === roleSRU) {
                        if (this.state.TrancheCodeID !== "60" && this.state.TrancheCodeID !== "61" && this.state.TrancheCodeID !== "62" && this.state.TrancheCodeID !== "66") {
                            toolbarRef.current.setButtonDisabled("claimReversal", true);
                            toolbarRef.current.setButtonDisabled("downloadClaimForm", true);
                        }
                        else {
                            toolbarRef.current.setButtonDisabled("claimReversal", false);
                            toolbarRef.current.setButtonDisabled("downloadClaimForm", false);
                        }
                    }
                    else {
                        toolbarRef.current.setButtonDisabled("claimReversal", selection.length === 0);
                        toolbarRef.current.setButtonDisabled("downloadClaimForm", selection.length === 0);
                    }
                }
            )
        }
        else {
            this.setState({ IDs: null, Message: "" },
                () => {
                    toolbarRef.current.setButtonDisabled("claimReversal", selection.length === 0);
                    toolbarRef.current.setButtonDisabled("downloadClaimForm", selection.length === 0);
                }
            )
        }
    }

    handleFormValueChange = (values) => {

        let TrancheCodeID = null;
        let newsearchParameters = [...this.state.searchParameters];

        if (!utils.isNull(values.TrancheCodeID)) {
            TrancheCodeID = values.TrancheCodeID.Id;
            let temp_element = { ...newsearchParameters[0] };
            temp_element.Value = TrancheCodeID
            newsearchParameters[0] = temp_element;
        }

        this.setState({
            searchParameters: JSON.parse(JSON.stringify(newsearchParameters)),
            TrancheCodeID: TrancheCodeID
        });

        if (utils.getCurrentRole() === roleSRU) {
            if (TrancheCodeID !== "60" && TrancheCodeID !== "61" && TrancheCodeID !== "62" && TrancheCodeID !== "66") {
                toolbarRef.current.setButtonDisabled("approveClaim", true);
            }
            else { toolbarRef.current.setButtonDisabled("approveClaim", false); }
        }

        if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
            this.setState({ model: values, selectedGrant: null });
        }
    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "close") {
            this.props.history.push({
                pathname: '/ProcessingNav'
            })
        } else if ("claimReversal" === buttonId) {
            this.setState({ formDialogOpen: true, removeMessage: "", TrancheCodeID: this.state.model.TrancheCodeID })
        } else if ("downloadClaimForm" === buttonId) {
            this.downloadClaim();
            toolbarRef.current.setButtonDisabled("downloadClaimForm", true);
        }
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            toolbarRef.current.setButtonDisabled("claimReversal", true);
            toolbarRef.current.setButtonDisabled("downloadClaimForm", true);
            this.setState({
                formDialogOpen: false, IDs: null
            });
        }
        else if (isCancel === true) {
            this.setState({
                formDialogOpen: false
            })
        }
    }

    successCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    render() {

        return (
            <ViewContainer title={''}>
                <p className="breadcrumbs">
                    {"Grant Processing > Grant Claim Reversals"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <SearchView
                    headCells={headCells}
                    dataUrl="api/sims/GrantClaimFormReversal/GrantGetClaimedReversalsSearch"
                    model={this.state.model}
                    toolbarConfig={this.toolbarConfig}
                    mode={this.props.mode}
                    history={this.props.history}
                    multiSelect={true}
                    onLookupValueChange={this.props.onLookupValueChange}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelections(selection) }}
                    autoFetchData={false}
                    toolbarRef={toolbarRef}
                    searchLabel={"SEARCH"}
                    handleObject={true}
                >
                    <GrantClaimFormReversalSearchForm data={this.state.model}
                        valueChangeHandler={(values) => this.handleFormValueChange(values)} />

                    <FormDialog
                        open={this.state.formDialogOpen}
                        dialogTitle={"Grant Claim Form Reversal"}
                        viewRef={this}
                        saveUrl={"api/sims/GrantClaimFormReversal/GrantClaimReverse"}
                        usePut={true}
                        entityInfo={this.state.model}
                        formRef={addFormRef}
                        multiEntity={this.state.IDs}
                        closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    >
                        <GrantClaimReversalDetailForm ref={addFormRef} />

                    </FormDialog>

                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(GrantClaimFormReversalSearch));




import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../../widgets/Toolbar';
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import "./GrantImport.css";
import { GrantImportForm } from './GrantImportForm';
import ViewUtils from '../../../ViewUtils';
import AlertItem from "../../../widgets/AlertItem";
import { ViewContainer } from "./../../../View.jsx";
import withMenuPermission from "../../../widgets/withMenuPermission";
import EnhancedTable from '../../../widgets/ResponsiveTable';


const utils = new ViewUtils();
const formRef = React.createRef();
const toolbarRef = React.createRef();
const headCells = [
    { id: 'ImportDate', numeric: false, disablePadding: false, label: 'Import Date' },
    { id: 'FinancialYear', numeric: false, disablePadding: false, label: 'Financial Year' },
    { id: 'GrantType', numeric: false, disablePadding: false, label: 'Grant Type' },
    { id: 'GrantDescription', numeric: false, disablePadding: false, label: 'Grant Description' },
    { id: 'ImportStatusCode', numeric: false, disablePadding: false, label: 'Import Status Code' },
    { id: 'ImportStatus', numeric: false, disablePadding: false, label: 'Import Status' }
];


class GrantImport extends Component {

    state = {
        message: "",
        messageStyle: "",
        title: "",
        id: null,
        model: {
            MinimumAmount: 0,
            FinancialYearID: null,
            GrantTypeID: null
        },
        dependency: [{ Name: "FinancialYearID", Value: 24 }],
        showTable: true,
        searchParameters: [{ Name: "ID", Value: 0 }]
    };
    

    toolbarConfig = {
        items: [
            {
                type: "buttonGroup",
                buttons: [
                    { id: 'save', label: 'SAVE', tooltip: 'Import Approved Grants', icon: 'ImportExportIcon', visible: utils.isNull(this.state.id) ? this.props.menuPermissionItem.Edit : this.props.menuPermissionItem.Add },
                    { id: 'refresh', label: 'REFRESH', tooltip: 'Check Import Progress', icon: 'RefreshIcon', visible: utils.isNull(this.state.id) ? this.props.menuPermissionItem.Edit : this.props.menuPermissionItem.Add }
                ]
            }
        ]
    };

    GetDefaultValues()
    {
        let url = "api/sims/GrantImport/GetDefaultValues?Id=0";
        utils.invokeUrl(url, (response) => {

            this.setState({
                model: {
                    FinancialYearID: {Id : response.FinancialYearID.Id , Description : response.FinancialYearID.Description}
                }
            });

            this.setState(prevState => ({
                model: {
                    ...prevState.model,  
                    FinancialYearID: {Id : response.FinancialYearID.Id , Description : response.FinancialYearID.Description},
                    GrantTypeID: !utils.isNull(response.GrantTypeID)? {Id : response.GrantTypeID.Id , Description : response.GrantTypeID.Description} : null
                },
                dependency: [{ Name: "FinancialYearID", Value: response.FinancialYearID.Id }]
            }));
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    componentDidMount() {
        this.GetDefaultValues();
    }

    save = () => {
        let formValue = formRef.current.getValue();

        if (formValue !== null && typeof formValue !== 'undefined') {
                const url = `api/sims/GrantImport/GrantImport`;
                utils.saveForm(this, formRef, url, (response) => {
                    this.setState({ id: response.EntityId });
                    utils.showMessage(this, response.Message, response.MessageType);
                    response.MessageType === "ERROR" ? this.errorCallback(response) : this.successCallback(response);
                }, this.errorCallback);
            }
        }  

    refresh = () => {
            this.setState({
                showTable: false
            }, () => {
                this.setState({ showTable: true })
            });
        }

    successCallback(response) {
        this.setState({
            message: response.Message, messageStyle: response.MessageType
        });
        this.refresh();

    }
    errorCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
        this.refresh();
    }

    handleFormValueChange = (values) => {
        let newValues = null;
        if (!utils.isNull(values)) {
            newValues = { ...values };
        }
        if (!utils.isNull(newValues)) {
            if (JSON.stringify(this.state.model) !== JSON.stringify(newValues)) {
                this.setState({
                    model: newValues,
                    Message: ""
                });
                if (!utils.isNull(newValues.FinancialYearID) && JSON.stringify(this.state.dependency) !== JSON.stringify(newValues.FinancialYearID)) 
                {
                    const finYearID = [{ Name: "FinancialYearID", Value: newValues.FinancialYearID.Id }]      
                    if (!utils.isNull(finYearID[0].Value)) {
                        this.setState({ dependency: finYearID })
                    }
                }
                else
                {
                    this.setState(prevState => ({dependency: {...prevState.dependency}}));
                };
            }
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        }
        else if ("refresh" === buttonId) {
            this.CheckProgress();
        }  
    }

    CheckProgress = () => {
        this.setState({message: "", messageStyle: ""});
        this.refresh();
    }

    handleSelectionChange = (selection) => {
    }


    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
            <div className="view w-100 h-100">
                <p className="breadcrumbs">
                {"Import Approved Grants"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={this.toolbarConfig} ref={toolbarRef}  />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="formContainer">
                                <GrantImportForm
                                        ref={formRef} id={this.state.id} data={this.state.model}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)} 
                                        dependency={this.state.dependency} />
                            </td>
                        </tr>

                        <div className="searchResults"> 
                        {this.state.showTable === true && ( 
                        <EnhancedTable
                        headCells={headCells}
                        dataUrl={`api/sims/GrantImport/GetSearch`}
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        searchParameters={this.state.searchParameters} paged={false} />
                        )}
                    </div> 
                    </tbody>
                </table>
            </div>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(GrantImport));
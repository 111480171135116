import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./AssessmentCentre.css";
import { AssessmentCentreForm } from './AssessmentCentreForm';
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";



const assessmentcentreRef = React.createRef();
const utils = new ViewUtils();

class AssessmentCentre extends Component {

    constructor(props) {
        super(props);

        this.state = {
            AssessmentCentreDetails: {
                LegalName: "",
                SDLNumber: "",
                AccreditationCheck: false
            },
            RequiredFields: null,
            showTable: true,
            toolbarConfig:{
                items: [
                    {
                        type: "buttonGroup",
                        buttons: [
                            { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                        ]
                    },
            
                    {
                        type: "devider"
                    },
            
                    {
                        type: "buttonGroup",
                            buttons: [
                                { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.menuPermissionItem.Edit},
                                { id: 'contacts', label: 'Contacts', tooltip: '', disabled: !this.props.onMenuSpecificPermissionItem('/assessmentcentreContacts').View },
                                { id: 'users', label: 'Users', tooltip: '', disabled: !this.props.onMenuSpecificPermissionItem('/assessmentcentreUsers').View },
                                {
                                    contextMenu: [
                                        { id: "qualification", description: "QCTO Qualification", disabled: !this.props.onMenuSpecificPermissionItem('/assessmentCentreQCTOQualificationsSearch').View },
                                        { id: "learnerships", description: "QCTO Learnerships", disabled: !this.props.onMenuSpecificPermissionItem('/assessmentCentreQCTOLearnershipSearch').View },
            
                                    ],
                                    id: 'programs', label: 'Programmes'
                                },
                                { id: 'learners', label: 'Learners', tooltip: '', disabled: !this.props.onMenuSpecificPermissionItem('/assessmentcentreLearner').View },
                                { id: 'assesors', label: 'Assessor', tooltip: '', disabled: !this.props.onMenuSpecificPermissionItem('/assessmentcentreAssessorSearch').View },
                                { id: 'moderator', label: 'Moderator', tooltip: '', disabled: !this.props.onMenuSpecificPermissionItem('/assessmentcentreModeratorSearch').View },
                                { id: 'documents', label: 'Documents', tooltip: '', disabled: !this.props.onMenuSpecificPermissionItem('/assessmentcentreDocuments').View }
            
                            ]
                    }
                ]
            }
            
        };
    }

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ AssessmentCentreDetails: values});
        }
    }

    dateChange = (form, date) => {

        if (!utils.isNull(form.values.AssessmentCentreAccreditationStatusID) && !utils.isNull(form.values.AccreditationStartDate)) {
            if (!utils.isNull(form.values.AccreditationStartDate)) {
                
                if (form.values.AssessmentCentreAccreditationStatusID.Id === "2") {
                    utils.autoSetDate(this, assessmentcentreRef, form.values.AccreditationStartDate, 2, 0, 1, "AccreditationEndDate", "AccreditationStartDate");
                }
                else if (form.values.AssessmentCentreAccreditationStatusID.Description === "Accredited") {
                    utils.autoSetDate(this, assessmentcentreRef, form.values.AccreditationStartDate, 5, 0, 1, "AccreditationEndDate", "AccreditationStartDate");
                }
            }
            else {
                this.setState({ RequiredFields: null });
            }
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        }
        else if ("close" === buttonId) {
            this.closeView();
        }
        else if ("contacts" === buttonId) {
            this.props.history.push({
                pathname: '/assessmentcentreContacts',
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.AssessmentCentreDetails.LegalName,
                    providerSDLNumber: this.state.AssessmentCentreDetails.SDLNumber
                }
            });
        }
        else if ("users" === buttonId) {
            this.props.history.push({
                pathname: '/assessmentcentreUsers',
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.AssessmentCentreDetails.LegalName,
                    providerSDLNumber: this.state.AssessmentCentreDetails.SDLNumber
                }
            })
        } else if ("learners" === buttonId) {
            this.props.history.push({
                pathname: '/assessmentcentreLearner',
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.AssessmentCentreDetails.LegalName,
                    providerSDLNumber: this.state.AssessmentCentreDetails.SDLNumber
                }
            })
        }
        else if ("assesors" === buttonId) {
            this.props.history.push({
                pathname: "/assessmentcentreAssessorSearch",
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.AssessmentCentreDetails.LegalName,
                    providerSDLNumber: this.state.AssessmentCentreDetails.SDLNumber
                }
            })
        }
        else if ("moderator" === buttonId) {
            this.props.history.push({
                pathname: "/assessmentcentreModeratorSearch",
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.AssessmentCentreDetails.LegalName,
                    providerSDLNumber: this.state.AssessmentCentreDetails.SDLNumber
                }
            })
        }
        else if ("documents" === buttonId) {
            this.props.history.push({
                pathname: "/assessmentcentreDocuments",
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.AssessmentCentreDetails.LegalName,
                    providerSDLNumber: this.state.AssessmentCentreDetails.SDLNumber
                }
            })
        }
    }

    handleToolbarButtonMenuClick(menu) {
       
         if ("learnerships" === menu.id) {
            this.props.history.push({
                pathname: "/assessmentcentreQCTOLearnershipSearch",
                state: {
                    selection: this.props.location.state.selection,
                    AssessmentCentreName: this.state.AssessmentCentreDetails.LegalName,
                    isAccredited: this.state.AssessmentCentreDetails.AccreditationCheck,
                    AssessmentCentreSDLNumber: this.state.AssessmentCentreDetails.SDLNumber
                }
            })
        }
        else if ("qualification" === menu.id) {
            this.props.history.push({
                pathname: "/assessmentcentreQCTOQualificationsSearch",
                state: {
                    selection: this.props.location.state.selection,
                    AssessmentCentreName: this.state.AssessmentCentreDetails.LegalName,
                    isAccredited: this.state.AssessmentCentreDetails.AccreditationCheck,
                    AssessmentCentreSDLNumber: this.state.AssessmentCentreDetails.SDLNumber
                }
            })
        }
    }

    successCallback = (response) => {

        this.setState({
            message: response.Message, messageStyle: response.MessageType,
            showTable: false
        }, () => {
            this.setState({ message: response.Message, messageStyle: response.MessageType, showTable: true })
        });
    }

    save() {
        let formValue = assessmentcentreRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {

            utils.invokeUrl("api/sims/assessmentcentredetails/UpdateAssessmentCentre", this.successCallback, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(formValue)
            });
        }
    }

    closeView() {
        this.props.history.push({
            pathname: '/assessmentcentreSearch',
            state: { selection: this.props.location.state.providerID }
        });
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">

                <p className="breadcrumbs">
                    {"Assessment Centre " + (this.state.AssessmentCentreDetails.LegalName !== null ? "> " + this.state.AssessmentCentreDetails.LegalName + (this.state.AssessmentCentreDetails.SDLNumber !== null ? " - " + this.state.AssessmentCentreDetails.SDLNumber : "") : "> Add")}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Assessment Centre" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={true} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "55vh", maxHeight: "55vh" }}>
                                    {this.state.showTable === true && (
                                        <AssessmentCentreForm
                                            ref={assessmentcentreRef}
                                            id={this.props.location.state.selection}
                                            valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                            requiredFields={this.state.RequiredFields}
                                            editPermission={this.props.menuPermissionItem.Edit}
                                            dateChange={this.dateChange}
                                        />
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(AssessmentCentre));


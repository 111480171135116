import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import SearchView from "../../widgets/SearchView";
import WSPATRApprovalSearchForm from './WSPATRApprovalSearchForm';
import WSPATRApprovalBulkForm from './WSPATRApprovalBulkForm';
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from '../../ViewUtils';
import Button from '@material-ui/core/Button';
import BulkDialog from './WSPATRApprovalBulkDialog';

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const formRef = React.createRef();

class WSPATRApprovalSearch extends Component {

    constructor(props) {
        super(props);


        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'view', label: '', tooltip: 'View', icon: 'ViewIcon', disabled: true },
                        { id: 'bulk', label: 'Bulk Approve / Reject', tooltip: '', disabled: true },
                    ]
                }
            ]
        }

        this.searchParameters =
            [
                { Name: "SDLNumber", Value: "" },
                { Name: "FinancialYearID", Value: "" },

            ]

        this.headCells =
            [
                { id: 'SDLNumber', numeric: false, disablePadding: true, label: 'SDL Number' },
                { id: 'CompanyName', numeric: false, disablePadding: true, label: 'Company Name' },
                { id: 'FinancialYear', numeric: false, disablePadding: true, label: 'Financial Year' },
                { id: 'VerificationDate', numeric: false, disablePadding: true, label: 'Verification Date' },
                { id: 'VerifiedBy', numeric: false, disablePadding: true, label: 'Verification By' },
                { id: 'VerificationStatus', numeric: false, disablePadding: true, label: 'Verification Status' },
                { id: 'DateEvaluated', numeric: false, disablePadding: true, label: 'DateEvaluated' },
                { id: 'EvaluatedBy', numeric: false, disablePadding: true, label: 'Evaluated By' },
                { id: 'EvaluationStatus', numeric: false, disablePadding: true, label: 'Evaluation Status' },
                { id: 'ApprovalStatus', numeric: false, disablePadding: true, label: 'Approval Status' },

            ]

        let model =
        {
            SDLNumber: "",
            FinancialYearID: ""
        }

        this.state = {
            model: model,
            keyUpSwitch: false,
            selectedWSPATR: null,
            selectedWSPATRs: null,
            showBulk: false,
            showTable: true
        }
    }

    GetDefaultFinYear()
    {
        let url = "api/sims/WSPATRApproval/GetDefaultFinYearFind?Id=0";
        utils.invokeUrl(url, (response) => {

            this.setState(prevState => ({
                model: {
                    ...prevState.model,  
                    FinancialYearID: {Id : response.FinancialYearID.Id , Description : response.FinancialYearID.Description}
                }
            }));
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    componentDidMount()
    {
        this.GetDefaultFinYear()
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            if (selection.length > 1) {
                this.setState({ selectedWSPATR: null, selectedWSPATRs: selection });
                toolbarRef.current.setButtonDisabled("bulk", false);
                toolbarRef.current.setButtonDisabled("view", true);
            }
            else if (selection.length === 1) {
                this.setState({ selectedWSPATR: selection[0].Id, selectedWSPATRs: null });
                toolbarRef.current.setButtonDisabled("bulk", true);
                toolbarRef.current.setButtonDisabled("view", false);
            }
            else {
                this.setState({ selectedWSPATR: null, selectedWSPATRs: null });
                toolbarRef.current.setButtonDisabled("bulk", true);
                toolbarRef.current.setButtonDisabled("view", true);
            }
        }
        else {
            this.setState({ selectedWSPATR: null, selectedWSPATRs: null });
            toolbarRef.current.setButtonDisabled("bulk", true);
            toolbarRef.current.setButtonDisabled("view", true);
        }

    }

    handleToolbarButtonClick = (buttonId) => {
        
        if ("bulk" === buttonId) {
            if (this.state.selectedWSPATRs !== null) { this.setState({ showBulk: true }); }
        }
        else if ("view" === buttonId) {
            this.props.history.push({
                pathname: "/WSPATRApprovalMain",
                state: {
                    WSPATRID: this.state.selectedWSPATR
                }
            })
        }        
    }

    postAddHandler(cancel, save, response) {
        if (save === true) {
            if (response.MessageType === "SUCCESS") {
                this.setState({
                    showTable: false
                }, () => {
                    this.setState({
                        showTable: true,
                        searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                        model: {
                            SDLNumber: "",
                            FinancialYearID: ""
                        },
                        showBulk: false,
                        selectedWSPATR: null,
                        selectedWSPATRs: null,
                        Message: response.Message,
                        MessageStyle: "success",
                        clearSelectedRow: true
                    })
                });
                toolbarRef.current.setButtonDisabled("view", true);
                toolbarRef.current.setButtonDisabled("bulk", true);
            }
            else if (response.MessageType === "ERROR") {
                this.setState({
                    showTable: false
                }, () => {
                    this.setState({
                        showTable: true,
                        searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                        model: {
                            SDLNumber: "",
                            FinancialYearID: ""
                        },
                        showBulk: false,
                        selectedWSPATR: null,
                        selectedWSPATRs: null,
                        Message: response.Message,
                        MessageStyle: "warning"
                    })
                });
            }
        }
        else if (cancel === true) {
            this.setState({
                showBulk: false,
                selectedWSPATR: null,
                selectedWSPATRs: null,
                clearSelectedRow: true
            })
            toolbarRef.current.setButtonDisabled("view", true);
            toolbarRef.current.setButtonDisabled("bulk", true);
        }
    }


    //BulkApproveReject = () => {
    //    let formValue = formRef.current.getValue();
    //    let WSTATRIDList = "";
    //    let selection = this.state.selectedWSPATRs;
    //    if (selection !== null) {
    //        for (let i = 0; i < selection.length; i++) {
    //            if (i === 0) { WSTATRIDList += selection[i].Id; }
    //            else { WSTATRIDList += "," + selection[i].Id; }

    //        }
    //    }

    //    if (formValue !== null) {
    //        formValue.WSPATRIDs = WSTATRIDList;
    //        let url = "api/sims/WSPATRApproval/UpdateBulkApproval";
    //        if (formValue.BoardApprovalStatusID.Id === "4" || formValue.BoardApprovalStatusID.Id === 4) { url = "api/sims/WSPATRApproval/UpdateBulkRejection" }
    //    utils.invokeUrl(url, (response) => {
    //        this.successCallback(response);
    //    },
    //        (response) => {
    //            this.error(response);
    //        },
    //        {
    //            method: 'PUT',
    //            headers: {
    //                'Accept': 'application/json',
    //                'Content-Type': 'application/json',
    //                'Authorization': "Bearer " + localStorage.getItem("token")
    //            },
    //            body: JSON.stringify(formValue)
    //        }
    //        )
    //    }
    //}

    render() {
        return (

            <ViewContainer title={""}>
                {this.state.showTable === true && (
                <SearchView headCells={this.headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/WSPATRApproval/getsearch"}
                    model={this.state.model}
                    title='WSP/ATR Approval'
                    toolbarConfig={this.toolbarConfig}
                    searchParameters={this.searchParameters}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    multiSelect={true}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="LegalName"
                    selectionCallbackMode={this.props.selectionCallbackMode}
                    history={this.props.history}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                    toolbarRef={toolbarRef}
                >
                    <WSPATRApprovalSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                    </SearchView>
                )}
                <BulkDialog
                    open={this.state.showBulk}
                    selectedWSPATRs={this.state.selectedWSPATRs}
                    closeHandler={(cancel, save, response) => this.postAddHandler(cancel, save, response)}
                    viewRef={this}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(WSPATRApprovalSearch)
);



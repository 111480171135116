import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "../../View.css";
import "../../Grid.css";
import "../../Form.css";
import "../../App.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import { BursaryApplicationUnemployedForm } from './BursaryApplicationUnemployedForm';
import { BursaryApplicationEmployedForm } from './BursaryApplicationEmployedForm';
import { BursaryApplicationContinuationForm } from './BursaryApplicationContinuationForm';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "../../View.jsx";

const utils = new ViewUtils();

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'back', label: 'BACK', tooltip: 'Back to document upload', theme: true },
                { id: 'next', label: 'NEXT', tooltip: 'Proceed to add application details', theme: true }
            ]
        }
    ]
};

const formRef = React.createRef();
class AddBursaryApplication extends Component {
    constructor(props) {
        super(props)
        let data = {};
        if (!utils.isNull(props.data) && !utils.isNull(props.data.bursaryApplication)) {
            if (!utils.isNull(props.data.bursaryApplication)) {
                data = props.data.bursaryApplication;
            }
            else {
                data = props.data.bursaryApplication;
            }
        } else if (!utils.isNull(props.data) && !utils.isNull(props.data.identityDetails)) {
            data = props.data.identityDetails.bursaryApplication ? props.data.identityDetails.bursaryApplication : props.data.identityDetails.formData
        } else {
            data.bursaryApplication = {};
        }

        this.state = {
            model: data,
            ID: props.data.bursaryApplication ? props.data.bursaryApplication.ID : null,
            BursaryApplicationTypeID: props.data.bursaryApplicationType.BursaryApplicationTypeID ? props.data.bursaryApplicationType.BursaryApplicationTypeID.Id : 0
        }
    }

    handleFormValueChange() {
    }

    handleToolbarButtonClick(buttonId) {
        let formValue = formRef.current.getValue();
        if ("back" === buttonId) {
            this.props.stepHandler(3, false, formValue);
        } else if ("next" === buttonId) {
            if (!utils.isNull(formValue)) {
                this.props.stepHandler(3, true, formValue);
            }
        }
    }

    render() {
        if (this.state.BursaryApplicationTypeID === "1") {
            return (
                <ViewContainer topPadding="0">
                    <p className="legend">
                        Unemployed Bursary Application Details
                    </p>
                    <div style={{ height: "50%", maxHeight: "42vh" }}>
                        <BursaryApplicationUnemployedForm ref={formRef} id={this.state.ID} IDNumber={this.props.data.personRoleDetails.IDNo} data={this.state.model} IsExternal={this.props.IsExternal} editPermission={true} valueChangeHandler={(values) => this.handleFormValueChange(values)} dataURL='api/sims/BursaryApplication/getBursaryApplicationDetails' /> 
                    </div>
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td width="90%">
                                </td>
                                <td width="20%" align="right">
                                    <div style={{ width: "120px", marginTop: "16px" }}>
                                        <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} config={toolbarConfig} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ViewContainer>
            );
        }
        else if (this.state.BursaryApplicationTypeID === "2") {
        return (
            <ViewContainer topPadding="0">
                <p className="legend">
                    Employed Bursary Application Details
                </p>
                <div style={{ height: "50%", maxHeight: "42vh" }}>
                    <BursaryApplicationEmployedForm ref={formRef} id={this.state.ID} data={this.state.model} IsExternal={this.props.IsExternal} editPermission={true} valueChangeHandler={(values) => this.handleFormValueChange(values)} dataURL='api/sims/BursaryApplication/getBursaryApplicationDetails' />
                   
                </div>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="90%">
                            </td>
                            <td width="20%" align="right">
                                <div style={{ width: "120px", marginTop: "16px" }}>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} config={toolbarConfig} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
            );
        }
        else if (this.state.BursaryApplicationTypeID === "3" || this.state.BursaryApplicationTypeID === "4") {
            return (
                <ViewContainer topPadding="0">
                    <p className="legend">
                        Continuation Bursary Application Details
                    </p>
                    <div style={{ height: "50%", maxHeight: "42vh" }}>
                        <BursaryApplicationContinuationForm ref={formRef} id={this.state.ID} data={this.state.model} IsExternal={this.props.IsExternal} editPermission={true} valueChangeHandler={(values) => this.handleFormValueChange(values)} dataURL='api/sims/BursaryApplication/getBursaryApplicationDetails' />
                    </div>
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td width="90%">
                                </td>
                                <td width="20%" align="right">
                                    <div style={{ width: "120px", marginTop: "16px" }}>
                                        <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} config={toolbarConfig} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ViewContainer>
            );
        }
        else return null;
    }
}

export default withRouter(
    connect()(AddBursaryApplication)
);

import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";
import Prompt from '../../widgets/Prompt';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import Dialog from '@material-ui/core/Dialog';
import { Typography } from "antd";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const headCells = [
    { id: 'LearnershipCode', numeric: false, disablePadding: false, label: 'Learnership Code' },
    { id: 'LearnershipTitle', numeric: false, disablePadding: false, label: 'Leanership Title' },
    { id: 'CommencementDate', numeric: false, disablePadding: false, label: 'Commencement Date' },
    { id: 'CompletionDate', numeric: false, disablePadding: false, label: 'Completion Date' },
    { id: 'QualificationTitle', numeric: false, disablePadding: false, label: 'Learnership Qualification Title' },
    { id: 'QualificationID', numeric: false, disablePadding: false, label: 'Learnership Qualification ID' },
];
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const USheadCells = [
    { id: 'ModuleCode', numeric: false, disablePadding: true, label: 'Module Code' },
    { id: 'ModuleTitle', numeric: false, disablePadding: true, label: 'Module Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' },
    { id: 'LearningType', numeric: false, disablePadding: true, label: 'Learning Type' },
    { id: 'NQFLevel', numeric: false, disablePadding: true, label: 'NQF Level' },
    { id: 'ModuleType', numeric: false, disablePadding: true, label: 'Module Type' }
];


const useStyles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        color: 'white',
    },
    table: {
        minWidth: 700,
    },
});

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class SDProviderQCTOLearnershipSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showTable: true,
            selectedLearnership: null,
            linkDialogOpen: false,
            selectedLearnershipTitle: null,
            auditDialogOpen: false,
            formDialogOpen: false,
            searchParameters: [{ Name: "SkillsDevelopmentProviderID", Value: this.props.location.state.selection }],
            propmtOpen: false,
            promptTitle: "Remove Leanership from Provider",
            promptContent: "Are you sure you want to remove this leanership from the provider?",
            removeMessage: "",
            removeMessageStyle: "",
            searchParameters2: [{ Name: "Id", Value: 0 }],
            OpenDialog: false,

        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' },
                        { id: 'view', label: '', tooltip: 'View Modules', icon: 'ViewIcon', disabled: utils.isNull(this.state.selectedLearnership) }
                    ]
                }
            ]
        };

    }
    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/sdprovider',
                state: { selection: this.props.location.state.selection }
            });
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
        else if ("view" === buttonId) {
            this.setState({ OpenDialog: true, message: "" })
        }
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                formDialogOpen: false, auditDialogOpen: false, selectedSiteVisit: null, linkDialogOpen: false
            }, () => this.setState({ showTable: true }));
        }
        else if (isCancel === true) {
            this.setState({
                formDialogOpen: false,
                linkDialogOpen: false,
                auditDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            let newsearchParameters2 = [...this.state.searchParameters2];
            let temp_element = { ...newsearchParameters2[0] };
            temp_element.Value = selection[0].Id
            newsearchParameters2[0] = temp_element;
    
            this.setState({ searchParameters2: JSON.parse(JSON.stringify(newsearchParameters2)), selectedLearnership: selection[0].Id, removeMessage: "", selectedLearnershipTitle: selection[0].LearnershipTitle });
        }
        else {
    
            this.setState({ searchParameters2: [{ Name: "Id", Value: 0 }], selectedLearnership: null, removeMessage: "", selectedLearnershipTitle: null });
        }
        toolbarRef.current.setButtonDisabled("view", selection === null || selection.length === 0);
    }

    viewRecords = () => {
        this.setState({ formDialogOpen: true })
    }


    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    CloseForm = () => {
        this.setState({
            OpenDialog: false,
            selectedId: null,
            clearSelectedRow: true,
            selectedLearnership: null
        });
        toolbarRef.current.setButtonDisabled("view", true);
    };

    render() {
        const { classes } = this.props;
        return (
            <ViewContainer mode={this.props.mode} title="">

                <p className="breadcrumbs">
                    {"Skills Development Provider > QCTO Learnerships " + (this.props.location.state.providerName !== null ? "> " + this.props.location.state.providerName + (this.props.location.state.providerSDLNumber !== null ? " - " + this.props.location.state.providerSDLNumber : "") : "> Add")}
                </p>

                <AlertItem message={this.state.removeMessage} alertStyle={this.state.removeMessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />
                    {this.state.showTable === true && (


                        <EnhancedTable
                            headCells={headCells}
                            dataUrl={`api/sims/skillsdevelopmentproviderlearnership/GetProviderQCTOLearnerships`}
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}

                </div>

                <Dialog fullScreen open={this.state.OpenDialog} onClose={this.CloseForm} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.CloseForm} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h1" className={classes.title}>
                                {"QCTO Learnership Modules"}
                            </Typography>

                        </Toolbar >
                    </AppBar >
                    <br /><br />
                    <EnhancedTable
                        headCells={USheadCells}
                        dataUrl={"api/sims/skillsdevelopmentproviderlearnership/GetProviderLModule"}
                        makeRowBold={true}
                        searchParameters={this.state.searchParameters2} paged={false} autoFetchData={true} />
                </Dialog >
            </ViewContainer>
        );
    }
}

export default withRouter(withStyles(useStyles())(connect()(SDProviderQCTOLearnershipSearch)));

import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";
import Prompt from '../../widgets/Prompt';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import SCPPISetupSearch from "../program/SCPPI/SCPPISetupSearch";
import LinkDialog from "../LinkDialog";
import withMenuPermission from "../../widgets/withMenuPermission";

const headCells = [
    { id: 'SCPPICode', numeric: false, disablePadding: false, label: 'Programme Code' },
    { id: 'SCPPIDescription', numeric: false, disablePadding: false, label: 'Programme Description' },
    { id: 'RegistrationStartDate', numeric: false, disablePadding: false, label: 'Registration Start Date' },
    { id: 'RegistrationEndDate', numeric: false, disablePadding: false, label: 'Registration End Date' }


];

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class AgentSCPPISearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedSCPPI: null,
            linkDialogOpen: false,
            formDialogOpen: false,
            auditDialogOpen: false,
            searchParameters: [{ Name: "AgentID", Value: this.props.location.state.selection }],
            propmtOpen: false,
            promptTitle: "Remove Programme from Agent",
            promptContent: "Are you sure you want to remove this Programme from the agent?",
            removeMessage: "",
            removeMessageStyle: "",
            Message: "",
            showTable: true

        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: "link", label: '', icon: 'LinkIcon', tooltip: "Link Programme to agent", visible: this.props.menuPermissionItem.Add },
                        {
                            id: 'remove', label: '', icon: 'DeleteIcon', tooltip: 'Remove Programme from Agent',
                            disabled: utils.isNull(this.state.selectedSCPPI), visible: this.props.menuPermissionItem.Delete
                        }
                    ]
                }
            ]
        };

    }
    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/agent',
                state: { selection: this.props.location.state.selection }
            });
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
        else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, removeMessage: "" })

        }
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                formDialogOpen: false, selectedSiteVisit: null, auditDialogOpen: false, linkDialogOpen: false, selection: this.props.location.state.selection, selectedSCPPI: null
            }, () => {
                this.setState({ showTable: true })
            });
        }
        else if (isCancel === true) {
            this.setState({
                formDialogOpen: false,
                linkDialogOpen: false,
                auditDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedSCPPI: selection[0].Id, removeMessage: "" });
        }
        else {
            this.setState({ selectedSCPPI: null, removeMessage: "" })
        }
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
    }

    successfulRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedSCPPI: null,
                removeMessage: response.Message,
                removeMessageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                removeMessage: response.Message,
                removeMessageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    removeSCPPIs(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
            toolbarRef.current.setButtonDisabled("view", true);
        })


        let fullUrl = `${url}?id=${this.state.selectedSCPPI}&agentId=${this.props.location.state.selection}`;
        utils.invokeUrlDelete(fullUrl, this.successfulRemoveCallback, this.unsuccessfulRemoveCallback);
    }


    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">

                <p className="breadcrumbs">
                    {"Agent > Short Courses Provided by Public Institution Programme " + (this.props.location.state.agentName !== null ? "> " + this.props.location.state.agentName + (this.props.location.state.agentSDLNumber !== null ? " - " + this.props.location.state.agentSDLNumber : "") : "> Add")}
                </p>

                <AlertItem message={this.state.removeMessage} alertStyle={this.state.removeMessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    {this.state.showTable === true && (

                        <EnhancedTable
                            headCells={headCells}
                            dataUrl={`api/sims/agentSCPPI/GetAgentSCPPIs`}
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}
                </div>

                <LinkDialog
                    open={this.state.linkDialogOpen}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    dialogTitle={`Link Programme/s to Agent > ${this.props.location.state.agentName} - ${this.props.location.state.agentSDLNumber}`}
                    linkName={"SCPPI"} postUrl={"api/sims/agentSCPPI/LinkAgentSCPPI"} usePost={true}
                    selectionId={this.props.location.state.selection}
                >
                    <SCPPISetupSearch
                        dataUrl="api/sims/agentSCPPI/GetSearchLinkableSCPPIs"
                        mode="lookup"
                        multiSelect={true} showTitle={false}
                        programmeId={this.props.location.state.selection}
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeSCPPIs("api/sims/agentSCPPI/RemoveAgentSCPPI") }}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(AgentSCPPISearch));

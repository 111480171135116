import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import { CustomTextField } from '../../widgets/CustomTextField';
import { Row, Col } from 'react-bootstrap';
import { SelectItem } from '../../widgets/SelectItem';

export default function SDProviderSearchForm(props) {
    return (
        <EntityForm ref={React.createRef()} viewId="sdprovider" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="SDProviderSearchForm">
                    <div className="row search-form-container">
                        <div className="col form-col">
                            <CustomTextField
                                id="LegalName"
                                label="Legal Name"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <CustomTextField
                                id="TradeName"
                                label="Trade Name"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <CustomTextField
                                id="SDLNumber"
                                label="SDL Number"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                    </div>
                    <br />
                    <div className="row search-form-container">
                        <div className="col form-col">
                            <CustomTextField
                                id="CompanyRegistrationNumber"
                                label="Company Registration Number"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form} />
                        </div>
                        <div className="col form-col">
                                <SelectItem
                                    dataUrl="api/sims/skillsdevelopmentprovider/GetLookupList?listId=Province"
                                    id="ProvinceID"
                                    label="Province"
                                    className="w-100"
                                    form={form} />
                        </div>
                    </div>
                </form>
            }
        </EntityForm >
    );
}
import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';

const utils = new ViewUtils();
export const WorkplaceApprovalAddContactForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="WorkplaceApprovalAddContact" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="WorkplaceApprovalAddContact">
                    <table cellPadding="2" className="w-100">
                        <tbody>
                            <tr>
                                <td>
                                    <CustomTextField
                                        id="IDNo"
                                        key={tabValue}
                                        label="Contact ID Number"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>

                                <td>
                                    <CustomTextField id="FirstName"
                                        key={tabValue}
                                        label="Contact First Name"
                                        className="w-100"
                                        required
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                                <td>
                                    <CustomTextField id="Surname"
                                        key={tabValue}
                                        label="Contact Surname"
                                        className="w-100"
                                        required
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField id="Designation"
                                        key={tabValue}
                                        label="Contact Designation"
                                        className="w-100"
                                        required
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                                <td>
                                    <CustomTextField id="TelephoneNumber"
                                        key={tabValue}
                                        label="Contact Telephone Number"
                                        className="w-100"
                                        required
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        validationGroup="contactNumbers"
                                        validator={{
                                            script: (formValues) => {

                                                if (!utils.isNull(formValues)) {
                                                    return !utils.isNull(formValues.CellphoneNumber) && !utils.isStringEmpty(formValues.CellphoneNumber) ||
                                                        !utils.isNull(formValues.FaxNumber) && !utils.isStringEmpty(formValues.FaxNumber) ||
                                                        !utils.isNull(formValues.TelephoneNumber) && !utils.isStringEmpty(formValues.TelephoneNumber)
                                                }
                                            },
                                            message: "At least one contact number needs to be filled in"
                                        }}
                                        form={form} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField id="CellphoneNumber"
                                        key={tabValue}
                                        validationGroup="contactNumbers"
                                        label="Contact Cellphone Number"
                                        className="w-100"
                                        required
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                                <td>
                                    <CustomTextField id="FaxNumber"
                                        key={tabValue}
                                        validationGroup="contactNumbers"
                                        label="Contact Fax Number"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField id="Email"
                                        key={tabValue}
                                        label="Contact E-mail"
                                        className="w-100"
                                        required
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                                <td>
                                    <SelectItem
                                        parentId={0}
                                        dataUrl="api/sims/WorkplaceApprovalcontact/GetLookupList?listId=ContactPersonStatus"
                                        id="ContactPersonStatusId"
                                        required={true}
                                        key={tabValue}
                                        label="Contact Person Status"
                                        className="w-100"
                                        form={form}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})

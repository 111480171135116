import React from 'react';
import { CustomTextField } from '../../widgets/CustomTextField';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { SelectItem } from '../../widgets/SelectItem';
import OrganisationSearch from './SDFOrganisationSearch';
import { LookupField } from '../../widgets/LookupField';
import DocumentUpload from '../../widgets/DocumentUpload';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();

export const SDFOrganisationForm = React.forwardRef((props, ref) => {

    const [filename, setFilename] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [tabValue, setTabValue] = React.useState(0);
    const [Bankfilename, setBankFilename] = React.useState("");
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);


    const onUploadHandler = (data) => {
        if (data) {
            setFilename(data.name);
        }
    };

    function disableSecondaryPrimarySDFCheck(value) {
        if (value) {
            return true;
        }
        else {
            return false;
        }
    }

    const onBankUploadHandler = (data) => {
        if (data) {
            setBankFilename(data.name);
        }
    };

    return (
        <EntityForm ref={ref} viewId="organisation" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}
            dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}>
            {form =>
                <form className={form.classes.container} id="union" style={{ overflowY: "hidden" }}>
                    <div className="row w-100">
                        <div className="col w-100">
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <span style={{ fontSize: "18px" }}>Organisation Details</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="OrganisationID"
                                                label="Organisation"
                                                className="w-100"
                                                form={form}
                                                required={true}
                                                parentId={0}
                                            >
                                                <OrganisationSearch showTitle={true} mode='lookup'
                                                    dataUrl={props.LookupUrl + "GetSearchLinkableOrganisations"}
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("OrganisationID", value)
                                                    }} autoFetchData={false} selection={props.selection} SDFID={props.SDFID} isLinkSearch={true}
                                                    IsExternal={props.IsExternal}
                                                />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomCheckbox
                                                id="ActingForEmployer"
                                                key={tabValue}
                                                form={form}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Consultant acting for Employer?"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl={props.LookupUrl + "GetLookupList?listId=SDFFunction"}
                                                id="SDFFunctionID"
                                                label="Will you perform your SDF functions in respect of"
                                                className="w-100"
                                                form={form}
                                                required={true}
                                                parentId={0}
                                                checkRefreshToken={!props.IsExternal}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl={props.LookupUrl + "GetLookupList?listId=SDFAppointmentProcedure"}
                                                id="AppointmentProcedureID"
                                                label="Please indicate method of appointment to SDF position"
                                                className="w-100"
                                                form={form}
                                                required={true}
                                                parentId={0}
                                                checkRefreshToken={!props.IsExternal}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="AppointmentProcedureOther"
                                                key={tabValue}
                                                label="Other method of appointment"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomCheckbox
                                                id="ReplacingPrimarySDF"
                                                key={tabValue}
                                                form={form}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                disabled={disableSecondaryPrimarySDFCheck(form.values["SecondarySDF"])}
                                                label="Are you replacing the previous primary SDF of this Company?"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomCheckbox
                                                id="SecondarySDF"
                                                key={tabValue}
                                                form={form}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                disabled={disableSecondaryPrimarySDFCheck(form.values["ReplacingPrimarySDF"])}
                                                label="Are you registering as secondary SDF for this Company?"
                                            />
                                        </td>
                                    </tr>
                                    <tr>

                                        <td>

                                            <DocumentUpload
                                                showViewer={false}
                                                onUploadHandler={onUploadHandler}
                                                buttonLabel="Upload Letter of Appointment"
                                                filename={filename}
                                                form={form}
                                                id="Document"
                                                parentId={0}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span style={{ fontSize: "18px" }}>Banking Details</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <DocumentUpload
                                                showViewer={false}
                                                onUploadHandler={onBankUploadHandler}
                                                buttonLabel="Upload Bank Details"
                                                filename={Bankfilename}
                                                form={form}
                                                id="BankDetailDocument"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span style={{
                                                color: "red",
                                                fontSize: "12px"
                                            }}>
                                                Banking details uploaded must be signed off by the Organisation CFO as true and correct.
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl={props.LookupUrl + "GetLookupList?listId=BankName"}
                                                id="BankNameID"
                                                key={tabValue}
                                                label="Bank Name"
                                                className="w-100"
                                                form={form}
                                                required
                                                parentId={0}
                                                checkRefreshToken={!props.IsExternal}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="BranchName"
                                                key={tabValue}
                                                label="Branch Name"
                                                className="w-100"
                                                required
                                                parentId={0}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="BranchCode"
                                                key={tabValue}
                                                label="Branch Code"
                                                className="w-100"
                                                required
                                                parentId={0}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="AccountHolder"
                                                key={tabValue}
                                                label="Account Holder"
                                                className="w-100"
                                                required
                                                parentId={0}
                                                form={form}
                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl={props.LookupUrl + "GetLookupList?listId=AccountType"}
                                                id="AccountTypeID"
                                                key={tabValue}
                                                required
                                                label="Account Type"
                                                className="w-100"
                                                form={form}
                                                parentId={0}
                                                checkRefreshToken={!props.IsExternal}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="AccountNumber"
                                                key={tabValue}
                                                label="Account Number"
                                                className="w-100"
                                                required
                                                parentId={0}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl={props.LookupUrl + "GetLookupList?listId=YesNo"}
                                                id="AdminDetailsCorrectID"
                                                key={tabValue}
                                                label="The Administrative Details Given Above Are Correct"
                                                className="w-100"
                                                form={form}
                                                parentId={0}
                                                required
                                                checkRefreshToken={!props.IsExternal}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl={props.LookupUrl + "GetLookupList?listId=YesNo"}
                                                id="BankDetailsCorrectID"
                                                key={tabValue}
                                                label="The Banking Details Given Above Are Correct"
                                                className="w-100"
                                                form={form}
                                                parentId={0}
                                                required
                                                checkRefreshToken={!props.IsExternal}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl={props.LookupUrl + "GetLookupList?listId=YesNo"}
                                                id="BankDetailsChangedID"
                                                key={tabValue}
                                                label="The Banking Details Given Above Have Changed"
                                                className="w-100"
                                                form={form}
                                                parentId={0}
                                                required
                                                checkRefreshToken={!props.IsExternal}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl={props.LookupUrl + "GetLookupList?listId=YesNo"}
                                                id="NewRegCompanyID"
                                                key={tabValue}
                                                label="Newly Registered Company?"
                                                className="w-100"
                                                form={form}
                                                parentId={0}
                                                required
                                                checkRefreshToken={!props.IsExternal}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="RegistrationDate"
                                                key={tabValue}
                                                label="Registration Date"
                                                className='{classes.textField} w-100'
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form >
            }
        </EntityForm >
    );
})
import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import "../SkillsProgramSetup.css";
import SearchView from "../../../widgets/SearchView";
import QCTOSkillsProgramSetupSearchForm from './QCTOSkillsProgramSetupSearchForm';
import ViewUtils from '../../../ViewUtils';
import CardLayout from '../../../widgets/Card';
import { ViewContainer } from "./../../../View.jsx";
import withMenuPermission from "../../../widgets/withMenuPermission";


const utils = new ViewUtils();
const headCells = [
    { id: 'SkillsProgrammeCode', numeric: false, disablePadding: true, label: 'Skills Programme Code' },
    { id: 'SkillsProgrammeTitle', numeric: false, disablePadding: true, label: 'Skills Programme Title' }

];

class QCTOSkillsProgramSetupSearch extends Component {

    constructor(props) {
        super(props)


        this.searchParameters = !utils.isNull(this.props.programmeId) ?
            [
                { Name: "id", Value: this.props.programmeId },
                { Name: "SkillsProgrammeCode", Value: "" },
                { Name: "SkillsProgrammeTitle", Value: "" }
            ]
            :
            [
                { Name: "SkillsProgrammeCode", Value: "" },
                { Name: "SkillsProgrammeTitle", Value: "" },
            ]

        let model = !utils.isNull(this.props.programmeId) ?
            {
                SkillsProgrammeCode: "",
                SkillsProgrammeTitle: "",
                id: this.props.programmeId
            }
            :
            {
                SkillsProgrammeCode: "",
                SkillsProgrammeTitle: ""
            }


        if (!utils.isNull(props.exclusions)) {
            model.Exclusions = props.exclusions;
            this.searchParameters.push({ Name: "Exclusions", Value: props.exclusions });
        }

        if (!utils.isNull(props.personId)) {
            model.PersonID = props.personId;
            this.searchParameters.push({ Name: "PersonID", Value: props.personId })
        }

        this.state = {
            model: model,
            keyUpSwitch: false
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: "delete", label: '', tooltip: 'Remove Skills Programme', icon: 'DeleteIcon', disabled: true, visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        }
    }

    onkeyup(e) {
        //console.log(e.charCode)
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    clearSearch(e) {
        this.setState({
            model: {
                SkillsProgrammeCode: "",
                SkillsProgrammeTitle: "",
                id: this.props.programmeId
            }
        });
    }

    render() {
        return (
            <ViewContainer title="">
                <SearchView headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/QCTOSkillsProgrammeSetup/GetSearch"}
                    entityViewPath='/qctoSkillsProgrammeSetup'
                    title='QCTO Skills Programmes'
                    model={this.state.model} toolbarConfig={this.toolbarConfig} searchParameters={this.searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="SkillsProgrammeTitle" mode={this.props.mode}
                    keyUpSwitch={this.state.keyUpSwitch} history={this.props.history}
                    multiSelect={this.props.multiSelect} selectionCallbackMode={this.props.selectionCallbackMode}
                    deleteUrl={"api/sims/QCTOSkillsProgrammeSetup/RemoveSkillsProgramme"} useDeletePost={true}
                    promptTitle={"Remove QCTO Skills Programme"}
                    checkRefreshToken={!this.props.IsExternal}
                    promptContent={"Removing the QCTO skills programme is a permanent action and the QCTO skills programme will not be available going forward. Are you sure you want to remove this QCTO skills programme?"}
                    editPermission={this.props.menuPermissionItem.Edit}
                    allowClear={this.props.allowClear}
                    clearPress={(e) => { this.clearSearch(e) }}
                >
                    <QCTOSkillsProgramSetupSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>

            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(QCTOSkillsProgramSetupSearch));

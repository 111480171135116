import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTextField } from '../../widgets/CustomTextField';
import { EntityForm } from '../../widgets/Forms';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    MuiSelectSelectMenu: {
        '& .MuiSelect-selectMenu': { paddingRight: "150px !important" }
    }
}));

function BursaryUniversityOrTVETSearchForm(props) {
    const [tabValue, setTabValue] = React.useState(0);
    const classes = useStyles();

    return (
        <EntityForm viewId="organisation" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={classes.container} >
                    <table cellPadding="2">
                        <tbody>
                            <tr>
                                <td width="50%">
                                    <CustomTextField
                                        id="UniversityOrTVET"
                                        label="University/University of Technology/TVET College"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: props.disableFields,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form >
            }
        </EntityForm>
    );
}

export default BursaryUniversityOrTVETSearchForm;
import React from 'react';
import { EntityForm } from '../../../widgets/Forms';
import { SelectItem } from '../../../widgets/SelectItem';
import { LookupField } from '../../../widgets/LookupField';
import EmployerSearch from '../../employer/EmployerSearch';
import *  as InterventionSearch from './InterventionIndex';
import BursaryInstitutionSearch from '../../program/BursaryInstitutionSearch';
import WorkplaceApprovalSearch from '../../workplaceapproval/WorkplaceApprovalSearch';


export default function GenerateClaimSearchForm(props) {


    //const PaymentType = [
    //    { Id: "institution", Name: "Institution" },
    //    { Id: "learner", Name: "Learner" }
    //]

    //function InterventionTypeChange(value, form) {
    //    if (typeof value !== 'undefined' && value !== null) {
    //        if (value.Description === "Learnership") {
    //            var type = 'LearnershipSetupSearch';
    //            const ComponentToRender = InterventionSearch[type];
    //            return <ComponentToRender showTitle={false} mode='lookup'
    //                onLookupValueChange={(value) => form.setLookupSelection("InterventionID", value)} dataUrl="api/sims/GenerateClaim/LearnershipSearch" autoFetchData={false} selection={props.selection} />
    //        } else if (value.Description === "Skills Programme") {
    //            var type = 'SkillsProgramSetupSearch';
    //            const ComponentToRender = InterventionSearch[type];
    //            return <ComponentToRender showTitle={false} mode='lookup'
    //                onLookupValueChange={(value) => form.setLookupSelection("InterventionID", value)} dataUrl="api/sims/GenerateClaim/SkillsProgrammeSearch" autoFetchData={false} selection={props.selection} />
    //        } else if (value.Description === "AET") {
    //            var type = 'AETSetupSearch';
    //            const ComponentToRender = InterventionSearch[type];
    //            return <ComponentToRender showTitle={false} mode='lookup'
    //                onLookupValueChange={(value) => form.setLookupSelection("InterventionID", value)} dataUrl="api/sims/GenerateClaim/AETSearch" autoFetchData={false} selection={props.selection} />
    //        }
    //    }
    //}

    function GetIDFromValue(value) {
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }

    function EmployerInstitutionWACheck(value, value2) {
        if ((value !== undefined && value !== null) || (value2 !== undefined && value2 !== null)) {
            return true
        }
        else {
            return false
        }
    }

    function BursaryInstitutionCheck(value) {
        if (value !== undefined && value !== null) {
            return true
        }
        else {
            return false
        }
    }

    function EmpReqCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Bursary")  {
                return false
            }
            else  {
                return true
            }
        }
    }

    function InstReqCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Bursary")  {
                return true
            }
            else  {
                return false
            }
        }
    }

    return (
        <EntityForm ref={React.createRef()} viewId="GenerateClaimSearchForm" values={props.data}
            useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="GenerateClaimSearchForm">
                    <tr>
                        <td>
                            <SelectItem
                                    dataUrl={"api/sims/GenerateClaim/GetLookupList?listId=FinancialYear"}
                                id="FinancialYearID"
                                label="Financial Year"
                                className="w-150"
                                form={form}
                                InputProps={{
                                    readOnly: props.disableFields
                                }}
                                required
                            />
                        </td>
                        <td>
                            <SelectItem
                                    dataUrl={"api/sims/GenerateClaim/GetLookupList?listId=TrancheCode"}
                                    id="TrancheCodeID"
                                label="Intervention Type"
                                className="w-150"
                                form={form}
                                InputProps={{
                                    readOnly: props.disableFields
                                }}
                                required
                            />
                        </td>
                        {/*<td>*/}
                        {/*    <SelectItem*/}
                        {/*            dataUrl={"api/sims/GenerateClaim/GetLookupList?listId=SocioEconomicStatus"}*/}
                        {/*        id="SocioEconomicStatusID"*/}
                        {/*        label="Socio Economic Status"*/}
                        {/*        className="w-150"*/}
                        {/*        form={form}*/}
                        {/*        InputProps={{*/}
                        {/*            readOnly: props.disableFields*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</td>*/}
                    </tr>
                    <tr>
                        {/*<td>*/}
                        {/*    <SelectItem*/}
                        {/*        dataUrl={'api/sims/GenerateClaim/GetSchedule?parameter=[{"Name":"TrancheCodeID","Value":' + GetIDFromValue(form.values["TrancheCodeID"]) + '}]'}*/}
                        {/*        id="ScheduleID"*/}
                        {/*        parentId={0}*/}
                        {/*        required*/}
                        {/*        label="Schedule"*/}
                        {/*        className="{classes.select} w-100"*/}
                        {/*        allowEmptyValue={true}*/}
                        {/*        form={form}*/}
                        {/*        required*/}
                        {/*        InputProps={{*/}
                        {/*            readOnly: props.disableFields*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</td>*/}
                        <td>
                            <LookupField
                                id="EmployerID"
                                label="Employer"
                                className="w-100"
                                form={form}
                                disabled={EmployerInstitutionWACheck(form.values["BursaryInstitutionID"], form.values["WAID"])}
                                //required={EmpReqCheck(form.values["TrancheCodeID"])}
                                dohandleChangeClose={true}
                            >
                                    <EmployerSearch showTitle={false} mode='lookup' dataUrl="api/sims/GenerateClaim/EmployerSearch"
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("EmployerID", value)
                                    }} autoFetchData={false} selection={props.selection} />
                            </LookupField>
                        </td>
                        <td>
                            <LookupField
                                id="WAID"
                                label="Workplace Approval"
                                className="w-100"
                                form={form}
                                disabled={EmployerInstitutionWACheck(form.values["BursaryInstitutionID"], form.values["EmployerID"])}
                                dohandleChangeClose={true}
                            >
                                <WorkplaceApprovalSearch showTitle={false} mode='lookup' dataUrl="api/sims/GenerateClaim/WorkplaceApprovalSearch"
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("WAID", value)
                                    }} autoFetchData={false} selection={props.selection} />
                            </LookupField>
                        </td>
                        <td>
                            <LookupField
                                id="BursaryInstitutionID"
                                label="Bursary Institution"
                                className="filterContainer"
                                form={form}
                                disabled={EmployerInstitutionWACheck(form.values["EmployerID"], form.values["WAID"])}
                                //required={InstReqCheck(form.values["TrancheCodeID"])}
                                dohandleChangeClose={true}
                            >
                                <BursaryInstitutionSearch showTitle={false} mode='lookup' dataUrl={"api/sims/BursaryApplication/GetBursaryInstitution"}
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("BursaryInstitutionID", value)
                                    }} autoFetchData={false} selection={props.selection} />
                            </LookupField>
                        </td>
                        <td>
                            <SelectItem
                                dataUrl={"api/sims/GenerateClaim/GetLookupList?listId=BursaryPaymentType"}
                                id="BursaryPaymentTypeID"
                                label="Payment Type"
                                className="w-150"
                                disabled={EmployerInstitutionWACheck(form.values["EmployerID"], form.values["WAID"])}
                                //required={InstReqCheck(form.values["TrancheCodeID"])}
                                form={form}
                                InputProps={{
                                    readOnly: props.disableFields
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        {/*<td>*/}
                        {/*    <LookupField*/}
                        {/*        required={true}*/}
                        {/*        id="InterventionID"*/}
                        {/*        label="Intervention"*/}
                        {/*        className="w-100"*/}
                        {/*        form={form}*/}
                        {/*    >*/}
                        {/*        {InterventionTypeChange(form.values["TrancheCodeID"], form)}*/}
                        {/*    </LookupField>*/}
                        {/*</td>*/}
                    </tr>
                    <br>
                    </br>
                </form>
            }
        </EntityForm>
    );
}

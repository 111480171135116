import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import SearchView from "../../widgets/SearchView";
import OrganisationSearchForm from './OrganisationSearchForm';
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from '../../ViewUtils';
import withMenuPermission from "../../widgets/withMenuPermission";


const utils = new ViewUtils();
class OrganisationSearch extends Component {

    constructor(props) {
        super(props);
            

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: "delete", label: "", tooltip: "Delete", icon: "Delete", disabled: true, visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        }

        this.searchParameters = !utils.isNull(this.props.OrganisationID) ?
            [
                { Name: "LegalName", Value: "" },
                { Name: "SDLNumber", Value: "" },
                { Name: "TradeName", Value: "" },
                { Name: "OrganisationID", Value: this.props.OrganisationID },
            ]
            :
            [
                { Name: "LegalName", Value: "" },
                { Name: "SDLNumber", Value: "" },
                { Name: "TradeName", Value: "" },
              
            ]

        this.headCells = this.props.isLinkSearch ? 
            [
                { id: 'LegalName', numeric: false, disablePadding: true, label: 'Legal Name' },
                { id: 'TradeName', numeric: false, disablePadding: true, label: 'Trade Name' },
                { id: 'SDLNumber', numeric: false, disablePadding: true, label: 'SDL Number' },
            ]
            :
            [
                { id: 'LegalName', numeric: false, disablePadding: true, label: 'Legal Name' },
                { id: 'TradeName', numeric: false, disablePadding: true, label: 'Trade Name' },
                { id: 'SDLNumber', numeric: false, disablePadding: true, label: 'SDL Number' },
      
            ]

        let model = !utils.isNull(this.props.OrganisationID) ?
            {
                LegalName: "",
                SDLNumber: "",
                TradeName: "",
                OrganisationID: this.props.OrganisationID
            }
            :
            {
                LegalName: "",
                SDLNumber: "",
                TradeName: "",
            }

        this.state = {
            model: model,
            keyUpSwitch: false,
            selectedOrganisation: null,
        }
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedOrganisation: selection })
        }
        else {
            this.setState({ selectedOrganisation: null })
        }
    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.setState({ selectedOrganisation: null })
        }
    }

    render() {
        return (
            
            <ViewContainer title={""}>
                <SearchView headCells={this.headCells}  
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl :"api/sims/skillsorganisation/getsearch"}
                    entityViewPath='/skillsOrganisation'
                    model={this.state.model}
                    title='Skills Organisations' 
                    toolbarConfig={this.toolbarConfig}
                    searchParameters={this.searchParameters}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    mode={this.props.mode}
                    multiSelect={this.props.multiSelect}
                    onLookupValueChange={this.props.onLookupValueChange}
                    deleteUrl={"api/sims/skillsorganisation/RemoveOrganisation"} useDeletePost={true}
                    descriptionProp="LegalName"
                    selectionCallbackMode={this.props.selectionCallbackMode} 
                    history={this.props.history}
                    keyUpSwitch={this.state.keyUpSwitch} 
                    setSelection={(selection) => { this.handleSelection(selection) }}
                    promptTitle={"Remove Organisation"}
                    promptContent={"Are you sure you want to remove this organisation?"}
                >
                    <OrganisationSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(OrganisationSearch));
import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomCheckbox } from '../../../widgets/CustomCheckbox';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { LookupField } from '../../../widgets/LookupField';
import ProviderSearch from '../../provider/ProviderSearch';
import EmployerSearch from '../../employer/EmployerSearch';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EntityForm } from '../../../widgets/Forms';
import SkillsProgramSetupSearch from '../../program/SkillsProgramSetupSearch';
import ViewUtils from "../../../ViewUtils";
import AgentSearch from '../../agent/AgentSearch';


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const utils = new ViewUtils();
export const LearnerMineCommunityForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [completionDateCalc, setcompletionDateCalc] = React.useState(null);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function GetProgrammeStatusID(value) {
        console.clear();
        console.log(value);
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }

    function TerminationCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Terminated") {
                return true
            }
            else if (value.Description !== "Terminated") {
                return false
            }
        }
    }

    function terminationReasonCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Other") {
                return true
            }
            else if (value.Description !== "Other") {
                return false
            }
        }
    }

    function SocioCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Employed") {
                return true
            }
            else if (value.Description !== "Unemployed") {
                return false
            }
        }
    }

    function NonSETACheck(value, type) {
        if (type === 'A') {
            if (value !== undefined && value !== null) {
                if (value.Description.indexOf("Non") !== -1) {
                    return true
                }
                else {
                    return false
                }
            }
            else {
                return false
            }
        }
        else if (type === 'P') {
            if (value !== undefined && value !== null) {
                if (value.Description.indexOf("Non") !== -1) {
                    return true
                }
                else {
                    return false
                }
            }
            else {
                return true
            }
        }
    }

    function GetIDFromValue(value) {
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }


    return (
        <EntityForm ref={ref} values={props.data} viewId="MineCommunitymeSetup" dataURL="api/sims/learnerMineCommunity/GetLearnerLinkedMineCommunityDetails"
            entityLookupId={props.id} org="saseta" useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="learnerMineCommunityForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Mine Community Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td colSpan="1">
                                            <SelectItem
                                                dataUrl={'api/sims/learnerMineCommunity/GetSpecificFinancialYear?parameter=[{"Name":"FinancialYearID","Value":' + GetIDFromValue(form.values["FinancialYearID"]) + '}]'}
                                                id="FinancialYearID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Financial Year"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td colSpan="1">
                                            <SelectItem
                                                dataUrl="api/sims/learnerMineCommunity/GetLookupList?listId=SETASkillsProgrammeType"
                                                id="SETASkillsProgrammeTypeID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Programme Type"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1">
                                            <SelectItem
                                                dataUrl="api/sims/learnerMineCommunity/GetLookupList?listId=QualityAssuranceBody"
                                                id="QualityAssuranceBodyID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Quality Assuring SETA"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <LookupField
                                                id="AgentID"
                                                label="Agent"
                                                className="w-100"
                                                required={NonSETACheck(form.values["SETASkillsProgrammeTypeID"], 'A')}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !NonSETACheck(form.values["SETASkillsProgrammeTypeID"], 'A')}
                                                form={form}
                                            >
                                                <AgentSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerMineCommunity/AgentSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("AgentID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="LeadProviderID"
                                                label="Lead Provider"
                                                className="w-100"
                                                required={!NonSETACheck(form.values["SETASkillsProgrammeTypeID"], 'P')}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : NonSETACheck(form.values["SETASkillsProgrammeTypeID"], 'P')}
                                                form={form}
                                            >
                                                <ProviderSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerMineCommunity/ProviderSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("LeadProviderID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <LookupField
                                                id="LeadEmployerID"
                                                label="Lead Employer"
                                                className="w-100"
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                            >
                                                <EmployerSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerMineCommunity/EmployerSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("LeadEmployerID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerMineCommunity/GetLookupList?listId=YesNo"
                                                id="LeadProviderLevyYesNoID"
                                                parentId={0}
                                                key={tabValue}
                                                label="Lead Provider Skills Levy"
                                                className="{classes.select} w-100"
                                                form={form}
                                                required={!NonSETACheck(form.values["SETASkillsProgrammeTypeID"], 'P')}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : NonSETACheck(form.values["SETASkillsProgrammeTypeID"], 'P')}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />

                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerMineCommunity/GetLookupList?listId=YesNo"
                                                id="LeadEmployerLevyYesNoID"
                                                parentId={0}
                                                key={tabValue}
                                                label="Lead Employer Skills Levy"
                                                className="{classes.select} w-100"
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="SecondaryProviderID"
                                                label="Secondary Provider"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <ProviderSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerMineCommunity/ProviderSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("SecondaryProviderID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <LookupField
                                                id="SecondaryEmployerID"
                                                label="Secondary Employer"
                                                className="w-100"
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                            >
                                                <EmployerSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerMineCommunity/EmployerSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("SecondaryEmployerID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerTrade/GetLookupList?listId=YesNo"
                                                id="SecondaryProviderLevyYesNoID"
                                                parentId={0}
                                                key={tabValue}
                                                label="Secondary Provider Skills Levy"
                                                className="{classes.select} w-100"
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />

                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerTrade/GetLookupList?listId=YesNo"
                                                id="SecondaryEmployerLevyYesNoID"
                                                parentId={0}
                                                key={tabValue}
                                                label="Secondary Employer Skills Levy"
                                                className="{classes.select} w-100"
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />

                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <LookupField
                                                required={true}
                                                id="SkillsProgrammeID"
                                                label="Skills Programme"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <SkillsProgramSetupSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerMineCommunity/SkillsProgrammeSearch"
                                                    onLookupValueChange={(value) => form.setLookupSelection("SkillsProgrammeID", value)} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerMineCommunity/GetLookupList?listId=YesNo"
                                                id="ProxyYesNoID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Proxy"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="CommencementDate"
                                                key={tabValue}
                                                label="Commencement Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="EstimateCompletionDate"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Envisaged Completion Date"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                required
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="CompletionDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="Actual Completion Date"
                                                className='{classes.textField} w-100 '
                                                disabled
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="StatusEffectiveDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="Status Effective Date"
                                                className='{classes.textField} w-100 '
                                                disabled={true}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1">
                                            <SelectItem
                                                dataUrl="api/sims/learnerMineCommunity/GetLookupList?listId=SocioEconomicStatusMCSS"
                                                id="SocioEconomicStatusID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Socio Economic Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td colSpan="1">
                                            <SelectItem
                                                dataUrl="api/sims/learnerMineCommunity/GetLookupList?listId=ProgrammeStatusReason"
                                                id="ProgrammeStatusReasonID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Programme Status Reason"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <SelectItem
                                                        dataUrl={'api/sims/learnerMineCommunity/GetMineCommunityStatusByRole?parameter=[{"Name":"MCSSProgrammeStatusID","Value":' + GetProgrammeStatusID(form.values["MCSSProgrammeStatusID"]) + '},{"Name":"ProgrammeID","Value":' + props.id + '}]'}
                                                        id="MCSSProgrammeStatusID"
                                                        parentId={0}
                                                        key={tabValue}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !props.editPermission.Edit}
                                                        label="Programme Status"
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="ReferenceNumber"
                                                        key={tabValue}
                                                        label="Reference Number"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>

                                            </tr>
                                            :
                                            ""
                                    }
                                    <tr>
                                        {
                                            (!props.hideFields) ?
                                                <td>
                                                    <CustomTextField
                                                        id="CertificateNumber"
                                                        key={tabValue}
                                                        label="Certificate Number"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                :
                                                ""
                                        }
                                        {
                                            (!props.hideFields) ?
                                                <td>
                                                    <CustomDatePicker
                                                        id="DateCertificateCreated"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Date Certificate Created"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                :
                                                ""
                                        }
                                    </tr>
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="ExtensionDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Extension Date"
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="ExtensionCapturedDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Extension Captured Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="ExtensionReason"
                                                        key={tabValue}
                                                        label="Extension Reason"
                                                        className="w-100 "
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="ExtensionBy"
                                                        key={tabValue}
                                                        label="Extension By"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="TerminationDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Termination Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["MCSSProgrammeStatusID"])}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td >
                                                    <SelectItem
                                                        dataUrl="api/sims/learnerMineCommunity/GetLookupList?listId=TerminationReason"
                                                        id="TerminationReasonID"
                                                        parentId={0}
                                                        key={tabValue}
                                                        label="Termination Reason"
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        required={TerminationCheck(form.values["MCSSProgrammeStatusID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["MCSSProgrammeStatusID"])}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="OtherTerminationReason"
                                                        key={tabValue}
                                                        label="Other Termination Reason"
                                                        className="w-100 "
                                                        required={terminationReasonCheck(form.values["TerminationReasonID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !terminationReasonCheck(form.values["TerminationReasonID"])}
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="TerminatedBy"
                                                        key={tabValue}
                                                        label="Terminated Captured By"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="CaptureDate"
                                                        key={tabValue}
                                                        label="Capture Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="CaptureByUser"
                                                        key={tabValue}
                                                        label="Capture By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>

                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="LastDateUpdated"
                                                        key={tabValue}
                                                        label="Last Date Updated"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="LastUpdatedByUser"
                                                        key={tabValue}
                                                        label="Last Updated By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="RegistrationDate"
                                                        key={tabValue}
                                                        label="Registration Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="RegisteredBy"
                                                        key={tabValue}
                                                        label="Registered By"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="DateApproved"
                                                        key={tabValue}
                                                        label="Approval Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="ApprovedBy"
                                                        key={tabValue}
                                                        label="Approved By"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields && !props.IsExternalRole) ?
                                            <tr>
                                                <td>
                                                    <CustomCheckbox
                                                        id="Tranche1DocumentConfirmationID"
                                                        key={tabValue}
                                                        form={form}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        InputProps={{
                                                            'aria-label': 'primary checkbox'

                                                        }}
                                                        label="Tranche 1 Document Confirmation"
                                                    />
                                                </td>
                                                <td>
                                                    <CustomCheckbox
                                                        id="Tranche2DocumentConfirmationID"
                                                        key={tabValue}
                                                        form={form}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        InputProps={{
                                                            'aria-label': 'primary checkbox'

                                                        }}
                                                        label="Tranche 2 Document Confirmation"
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields && !props.IsExternalRole) ?
                                            <tr>
                                                <td>
                                                    <CustomCheckbox
                                                        id="Tranche3DocumentConfirmationID"
                                                        key={tabValue}
                                                        form={form}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        InputProps={{
                                                            'aria-label': 'primary checkbox'

                                                        }}
                                                        label="Tranche 3 Document Confirmation"
                                                    />
                                                </td>
                                                <td>
                                                    <CustomCheckbox
                                                        id="Tranche4DocumentConfirmationID"
                                                        key={tabValue}
                                                        form={form}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        InputProps={{
                                                            'aria-label': 'primary checkbox'

                                                        }}
                                                        label="Tranche 4 Document Confirmation"
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})

import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "../../View.css";
import "../../Grid.css";
import "../../Form.css";
import "../../App.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import { BursaryApplicationUnemployedEvaluationForm } from './BursaryApplicationUnemployedEvaluationForm';
import { BursaryApplicationEmployedEvaluationForm } from './BursaryApplicationEmployedEvaluationForm';
import { BursaryApplicationContinuationEvaluationForm } from './BursaryApplicationContinuationEvaluationForm';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "../../View.jsx";

const utils = new ViewUtils();

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'back', label: 'BACK', tooltip: 'Back to document upload', theme: true },
                { id: 'next', label: 'NEXT', tooltip: 'Proceed to add application details', theme: true }
            ]
        }
    ]
};

const formRef = React.createRef();
class BursaryApplicationEvaluationAll extends Component {
    constructor(props) {
        super(props)
        let data = {};

        if (!utils.isNull(props.data) && !utils.isNull(props.data.bursaryApplication)) {
            if (!utils.isNull(props.data.bursaryApplication)) {
                data = props.data.bursaryApplication;
            }
            else {
                data = props.data.bursaryApplication;
            }
        } else if (!utils.isNull(props.data) && !utils.isNull(props.data.identityDetails)) {
            data = props.data.identityDetails.bursaryApplication ? props.data.identityDetails.bursaryApplication : props.data.identityDetails.formData
        } else {
            data.bursaryApplication = {};
        }

        this.state = {
            model: data,
            BursaryApplicationTypeID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.BursaryApplicationTypeID)) ? this.props.location.state.BursaryApplicationTypeID : null,
        }

    }

    handleFormValueChange() {
    }

    handleLowerToolbarButtonClick(buttonId) {
        if ("next" === buttonId) {
            this.props.history.push({
                pathname: '/BursaryApplicationEvaluationDetail',
                state: {
                    selection: this.props.location.state.selection
                }
            });
        } else if ("back" === buttonId) {
            this.props.history.push({
                pathname: '/BursaryApplicationTypeEvaluation',
                state: {
                    selection: this.props.location.state.selection
                }
            });
        }
    }

    render() {
        if (this.state.BursaryApplicationTypeID === "1" || this.state.BursaryApplicationTypeID === 1) {
            return (
                <ViewContainer topPadding="0">
                    {/*<p className="breadcrumbs">*/}
                    {/*    {"Bursary Application Evaluation " + (this.state.PersonDetails.FirstName !== null ? "> " + this.state.PersonDetails.FirstName + " " + this.state.PersonDetails.Surname + (this.state.PersonDetails.IDNo !== null ? " - " + this.state.PersonDetails.IDNo : "") : "> Add")}*/}
                    {/*</p>*/}

                    <div style={{ height: "50%", maxHeight: "42vh" }}>
                        <BursaryApplicationUnemployedEvaluationForm ref={formRef} id={this.props.location.state.selection} data={this.state.model} IsExternal={this.props.IsExternal} editPermission={true} valueChangeHandler={(values) => this.handleFormValueChange(values)} dataURL='api/sims/BursaryApplicationEvaluation/GetBursaryApplicationFind' /> 
                    </div>
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td width="90%">
                                </td>
                                <td width="20%" align="right">
                                    <div style={{ width: "120px", marginTop: "16px" }}>
                                        <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleLowerToolbarButtonClick(buttonId)} config={toolbarConfig} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ViewContainer>
            );
        }
        else if (this.state.BursaryApplicationTypeID === "2" || this.state.BursaryApplicationTypeID === 2) {
        return (
            <ViewContainer topPadding="0">
                {/*<p className="breadcrumbs">*/}
                {/*    {"Bursary Application Evaluation " + (this.state.PersonDetails.FirstName !== null ? "> " + this.state.PersonDetails.FirstName + " " + this.state.PersonDetails.Surname + (this.state.PersonDetails.IDNo !== null ? " - " + this.state.PersonDetails.IDNo : "") : "> Add")}*/}
                {/*</p>*/}

                <div style={{ height: "50%", maxHeight: "42vh" }}>
                    <BursaryApplicationEmployedEvaluationForm ref={formRef} id={this.props.location.state.selection} data={this.state.model} IsExternal={this.props.IsExternal} editPermission={true} valueChangeHandler={(values) => this.handleFormValueChange(values)} dataURL='api/sims/BursaryApplicationEvaluation/getBursaryApplicationDetails' />
                   
                </div>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="90%">
                            </td>
                            <td width="20%" align="right">
                                <div style={{ width: "120px", marginTop: "16px" }}>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleLowerToolbarButtonClick(buttonId)} config={toolbarConfig} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
            );
        }
        else if (this.state.BursaryApplicationTypeID === "3" || this.state.BursaryApplicationTypeID === 3 || this.state.BursaryApplicationTypeID === "4" || this.state.BursaryApplicationTypeID === 4) {
            return (
                <ViewContainer topPadding="0">
                    {/*<p className="breadcrumbs">*/}
                    {/*    {"Bursary Application Evaluation " + (this.state.PersonDetails.FirstName !== null ? "> " + this.state.PersonDetails.FirstName + " " + this.state.PersonDetails.Surname + (this.state.PersonDetails.IDNo !== null ? " - " + this.state.PersonDetails.IDNo : "") : "> Add")}*/}
                    {/*</p>*/}

                <div style={{ height: "50%", maxHeight: "42vh" }}>
                        <BursaryApplicationContinuationEvaluationForm ref={formRef} id={this.props.location.state.selection} data={this.state.model} IsExternal={this.props.IsExternal} editPermission={true} valueChangeHandler={(values) => this.handleFormValueChange(values)} dataURL='api/sims/BursaryApplicationEvaluation/getBursaryApplicationDetails' />
                    </div>
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td width="90%">
                                </td>
                                <td width="20%" align="right">
                                    <div style={{ width: "120px", marginTop: "16px" }}>
                                        <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleLowerToolbarButtonClick(buttonId)} config={toolbarConfig} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ViewContainer>
            );
        }
        else return null;
    }
}

export default withRouter(
    connect()(BursaryApplicationEvaluationAll)
);

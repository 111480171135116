import React from 'react';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from '../../../ViewUtils';
import { makeStyles } from '@material-ui/core/styles';

const utils = new ViewUtils();
export const LinkTrancheForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function getTrancheType(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Progress") {
                return false
            }
            else {
                return true
            }
        }
    }

    return (
        <EntityForm ref={ref}
            dataURL='api/sims/tranchedetail/getfind'
            values={props.data}
            entityLookupId={props.id}
            entityLookupController={props.entityLookupController} org="saseta"
            useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="LinkTrancheDetailForm">

                    <table cellPadding="2" width="80%">
                        <tbody>
                            <tr>
                                <td>
                                    <CustomTextField
                                        id="ScheduleItem"
                                        key={tabValue}
                                        label="Schedule Item"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        form={form}
                                    />
                                </td>
                                <td>
                                    <CustomTextField
                                        id="MonthOffset"
                                        key={tabValue}
                                        label="Month Offset"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        form={form}
                                    />
                                </td>
                                <td>
                                    <CustomTextField
                                        id="TrancheAmount"
                                        key={tabValue}
                                        label="Tranche Amount"
                                        className="w-100 "
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})

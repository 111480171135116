import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";
import Prompt from '../../widgets/Prompt';
import ViewUtils from '../../ViewUtils';
import FormDialog from "../FormDialog";
import { WorkplaceApprovalAddContactForm } from "./WorkplaceApprovalAddContactForm";
import { ViewContainer } from "./../../View.jsx";
import withMenuPermission from "../../widgets/withMenuPermission";

const headCells = [
    { id: 'FirstName', numeric: false, disablePadding: true, label: 'First Name' },
    { id: 'Surname', numeric: false, disablePadding: true, label: 'Surname' },
    { id: 'Designation', numeric: false, disablePadding: true, label: 'Designation' },
    { id: 'TelephoneNumber', numeric: false, disablePadding: true, label: 'Telephone Number' },
    { id: 'CellPhoneNumber', numeric: false, disablePadding: true, label: 'CellPhone Number' },
    { id: 'FaxNumber', numeric: false, disablePadding: true, label: 'Fax Number' },
    { id: 'EMail', numeric: false, disablePadding: true, label: 'EMail' }

];

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class WorkplaceApprovalContactSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {

            formDialogOpen: false,
            selectedContact: null,
            propmtOpen: false,
            promptTitle: "Remove contact from Workplace Approval",
            promptContent: "Are you sure you want to remove this contact from the Workplace Approval?",
            Message: "",
            MessageStyle: "",
            searchParameters: [{ Name: "WorkplaceApprovalID", Value: this.props.location.state.selection }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add contact to Workplace Approval', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'remove', label: '', tooltip: 'Remove contact from Workplace Approval', icon: 'DeleteIcon', disabled: utils.isNull(this.state.selectedContact), visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        };

    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/WorkplaceApproval',
                state: { selection: this.props.location.state.selection }
            });
        }
        else if ("add" === buttonId) {
            this.setState({ formDialogOpen: true, Message: "" })
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    postAddHandler(cancel, save) {
        if (save === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                formDialogOpen: false,
                Message: "Workplace Approval Contact successfully saved",
                MessageStyle: "success"
            });
        }
        else if (cancel === true) {
            this.setState({
                formDialogOpen: false,
                Message: ""
            })
        }
        else {
            this.setState({
                formDialogOpen: false,
                Message: "A system error has occured, please try again later or contact your administrator",
                MessageStyle: "warning"
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedContact: selection[0].Id, Message: "" });
        }
        else {
            this.setState({ selectedContact: null, Message: "" });
        }
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
    }

    successfulContactRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedContact: null, Message: response.Message, MessageStyle: response.MessageType.toLowerCase() });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulContactRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ Message: response.Message, MessageStyle: response.MessageType.toLowerCase() })
        }
    }

    removeContact(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedContact}&WorkplaceApprovalId=${this.props.location.state.selection}`;
        utils.invokeUrlDelete(fullUrl, this.successfulContactRemoveCallback, this.unsuccessfulContactRemoveCallback);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">

                <p className="breadcrumbs">
                    {"Workplace Approval > Contacts " + (this.props.location.state.WorkplaceApprovalName !== null ? "> " + this.props.location.state.WorkplaceApprovalName + (this.props.location.state.WorkplaceApprovalSDLNumber !== null ? " - " + this.props.location.state.WorkplaceApprovalSDLNumber : "") : "> Add")}
                </p>

                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    <EnhancedTable
                        headCells={headCells}
                        dataUrl={`api/sims/WorkplaceApprovalcontact/GetWorkplaceApprovalContacts`}
                        height="50vh"
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        searchParameters={this.state.searchParameters} paged={true} />
                </div>

                <FormDialog
                    open={this.state.formDialogOpen}
                    dialogTitle={"Add Workplace Approval Contact"}
                    viewRef={this}
                    formRef={addFormRef}
                    saveUrl={"api/sims/WorkplaceApprovalcontact/InsertWorkplaceApprovalContact"}
                    usePost={true}
                    closeHandler={(cancel, save) => this.postAddHandler(cancel, save)}
                >
                    <WorkplaceApprovalAddContactForm ref={addFormRef} />
                </FormDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeContact("api/sims/WorkplaceApprovalcontact/RemoveWorkplaceApprovalContact") }}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(connect()(withMenuPermission(WorkplaceApprovalContactSearch)));

import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import ViewUtils from '../../ViewUtils';
import AuditTrial from './AuditTrial';

import { ViewContainer } from "./../../View.jsx";
import LinkDialog from "../LinkDialog";
import EnhancedTable from '../../widgets/ResponsiveTable';
import Prompt from '../../widgets/Prompt';
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";

export const registrationHistoryHeadCells = [
    { id: 'CoachMentorRegistrationStartDate', numeric: false, disablePadding: true, label: 'Coach/Mentor Registration Start Date' },
    { id: 'CoachMentorRegistrationEndDate', numeric: false, disablePadding: true, label: 'Coach/Mentor Registration End Date' },
    { id: 'CoachMentorRegistrationStatus', numeric: false, disablePadding: true, label: 'Coach/Mentor Registration Status' },
    { id: 'DateUpdated', numeric: false, disablePadding: true, label: 'Date Updated' },
    { id: 'UpdatedBy', numeric: false, disablePadding: true, label: 'Updated By' }
];

const utils = new ViewUtils();
const toolbarRef = React.createRef();

class CoachMentorRegistrationHistory  extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                SAQAUnitStandardID: "",
                SAQAUnitStandardTitle: "",
                CoachMentorID: this.props.location.state.CoachMentorID
            },

            linkDialogOpen: false,
            selectedAuditTrial: null,
            propmtOpen: false,
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "CoachMentorID", Value: this.props.location.state.CoachMentorID }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'ViewAudit', label: '', tooltip: 'View Scope', disabled: utils.isNull(this.state.selectedAuditTrial), icon: 'ViewIcon' }

                    ]
                }
            ]
        };

    }

    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/CoachMentor',
                state: { selection: this.props.location.state.CoachMentorID }
            });
        }
        else if ("ViewAudit" === buttonId) {
            this.setState({ linkDialogOpen: true, message: "" })
        }

    }

    successCallback = (response) => {
        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedAuditTrial: null,
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
        else if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false, selectedAuditTrial: null
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({
                selectedAuditTrial: selection[0].Id,
                message: "",
                messageStyle: ""
            });
        }
        else {
            this.setState({
                selectedAuditTrial: null,
                message: "",
                messageStyle: ""
            });
        }
        toolbarRef.current.setButtonDisabled("ViewAudit", selection === null || selection.length === 0);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    render() {
        return (
            <ViewContainer mode={""}>

                <p className="breadcrumbs">
                    {"Coach/Mentor > Audit Trail " + (this.props.location.state.CoachMentorName !== null ? "> " + this.props.location.state.CoachMentorName : "> Add")}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    <EnhancedTable
                        headCells={registrationHistoryHeadCells}
                        dataUrl={`api/sims/CoachMentorRegistrationHistory/getCoachMentorRegistrationHistory`} 
                        height="50vh"
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection)}}
                        searchParameters={this.state.searchParameters} paged={true} />
                </div>

                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title={""}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={`Scope > ${this.props.location.state.CoachMentorName}`}
                    linkName={"Audit Trail"} postUrl={"api/indicium/UnitStandardSetup/linkCoachMentorUnitStandards"}
                    selectionId={this.props.location.state.CoachMentorID}
                >

                    <AuditTrial dataUrl="api/sims/CoachMentorRegistrationHistory/searchRegistrationAuditTrail"
                        multiSelect={false} showTitle={false}
                        Id={this.state.selectedAuditTrial}
                    />
                </LinkDialog>

            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(CoachMentorRegistrationHistory )
);

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { EntityForm } from '../../../widgets/Forms';
import { SelectItem } from '../../../widgets/SelectItem';
import ViewUtils from '../../../ViewUtils';

const utils = new ViewUtils();

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    MuiSelectSelectMenu: {
        '& .MuiSelect-selectMenu': { paddingRight: "150px !important" }
    }
}));

export default function GrantBudgetSearchForm(props) {
    const [labelWidth, setLabelWidth, tabValue] = React.useState(0);
    const labelRef = React.useRef(null);
    const classes = useStyles();

    React.useEffect(() => {
        if (labelRef.current !== null) {
            setLabelWidth(labelRef.current.offsetWidth);
        }
    }, []);

    return (
        <EntityForm ref={React.createRef()} viewId="GrantBudget" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} >
                    <table cellPadding="2">
                        <tbody>
                            <td>
                                <FormControl className={classes.formControl} variant="outlined">
                                    <SelectItem
                                        dataUrl={"api/sims/GrantBudget/GetLookupList?listId=FinancialYear"}
                                        id="FinancialYear"
                                        label="Financial Year"
                                        className="w-150"
                                        form={form}
                                        InputProps={{
                                            readOnly: props.disableFields
                                        }}
                                    />
                                </FormControl>
                            </td>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
}

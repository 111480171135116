import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";

import { ViewContainer } from "../../../View.jsx";
import ViewUtils from "../../../ViewUtils";
import GrantBudgetSearchForm from './GrantBudgetSearchForm';
import EnhancedToolbar from '../../../widgets/Toolbar';
import EnhancedTable from '../../../widgets/ResponsiveTable';
import EditDialog from './GrantBudgetEditDialog';
import withMenuPermission from "../../../widgets/withMenuPermission";

const utils = new ViewUtils();
const toolbarRef = React.createRef();

const roleSRU = "SRU Administrator";

const headCells = [
    { id: 'GrantCode', numeric: false, disablePadding: false, label: 'Grant Code' },
    { id: 'GrantDescription', numeric: false, disablePadding: false, label: 'Grant Description' },
    { id: 'Amount', numeric: false, disablePadding: false, label: 'Budget Amount' }
];


class GrantBudgetSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            //selectedGrant: null,
            model: {
                FinancialYearID: null
            },
            searchParameters: [{ Name: "FinancialYearID", Value: 0 }],
            editDialogOpen: false,
            keyUpSwitch: false

        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', visible: this.props.menuPermissionItem.Edit, disabled: utils.isNull(this.state.selectedGrant) }
                    ]
                }
            ]
        };

    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedGrant: selection })
            if (utils.getCurrentRole() === roleSRU) {
                console.log(selection.GrantCode)
                if (selection.GrantCode !== "MCOM" && selection.GrantCode !== "SSC" && selection.GrantCode !== "UNEMYD" && selection.GrantCode !== "TVETNCV") {
                    toolbarRef.current.setButtonDisabled("edit", true);
                }
                else { toolbarRef.current.setButtonDisabled("edit", false); }
            }
        }
        else {
            this.setState({ selectedGrant: null })
        }
        if (utils.getCurrentRole() !== roleSRU) { toolbarRef.current.setButtonDisabled("edit", selection === null || selection.length === 0); }
    }

    handleFormValueChange = (values) => {

        let FinancialYearID = values.FinancialYear.Id;
        let newsearchParameters = [...this.state.searchParameters];
        let temp_element = { ...newsearchParameters[0] };
        temp_element.Value = FinancialYearID
        newsearchParameters[0] = temp_element;

        this.setState({
            searchParameters: JSON.parse(JSON.stringify(newsearchParameters)),
            FinancialYearID: FinancialYearID
        });

        //if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
        //    this.setState({ model: values, selectedGrant: null });
        //    toolbarRef.current.setButtonDisabled("edit", true);
        //}
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedId: selection[0].Id, Message: "", clearSelectedRow: false });
            if (utils.getCurrentRole() === roleSRU) {
                console.log(selection[0].GrantCode)
                if (selection[0].GrantCode !== "MCOM" && selection[0].GrantCode !== "SSC" && selection[0].GrantCode !== "UNEMYD" && selection[0].GrantCode !== "TVETNCV") {
                    toolbarRef.current.setButtonDisabled("edit", true);
                }
                else { toolbarRef.current.setButtonDisabled("edit", false); }
            }
        }
        else {
            this.setState({ selectedId: null, Message: "", clearSelectedRow: false })
        }
        if (utils.getCurrentRole() !== roleSRU) { toolbarRef.current.setButtonDisabled("edit", selection === null || selection.length === 0); }
    }

    postAddHandler(cancel, save, response) {
        if (save === true) {
            if (response.MessageType === "SUCCESS") {
                this.setState({
                    searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                    addDialogOpen: false,
                    editDialogOpen: false,
                    selectedId: null,
                    Message: response.Message,
                    MessageStyle: "success",
                    clearSelectedRow: true
                });
            }
            else if (response.MessageType === "ERROR") {
                this.setState({
                    searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                    addDialogOpen: false,
                    editDialogOpen: false,
                    selectedId: null,
                    Message: response.Message,
                    MessageStyle: "warning"
                });
            }
        }
        else if (cancel === true) {
            this.setState({
                addDialogOpen: false,
                editDialogOpen: false,
                selectedId: null,
                clearSelectedRow: true
            })
        }
        //toolbarRef.current.setButtonDisabled("edit", selectedId === null || selectedId.length === 0);
    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "edit") {
            this.setState({ editDialogOpen: true });
        }
    }

    onClearSelectedRow = () => {
        this.setState({ clearSelectedRow: false });
    }

    render() {

        return (
            <ViewContainer title="">

                <div className="searchResults">
                    < GrantBudgetSearchForm
                        // data={this.state.model}
                        valueChangeHandler={(values) => this.handleFormValueChange(values)}
                    />

                    <EnhancedToolbar highlight={false}
                        title="Grant Budget"
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig}
                    />

                    <EnhancedTable
                        headCells={headCells}
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        dataUrl="api/sims/grantbudget/GetSearch"
                        searchParameters={this.state.searchParameters}
                        clearSelectedRow={this.state.clearSelectedRow} onClearSelectedRow={this.onClearSelectedRow}
                        paged={true} />
                </div>

                <EditDialog
                    open={this.state.editDialogOpen}
                    id={this.state.selectedId}
                    closeHandler={(cancel, save, response) => this.postAddHandler(cancel, save, response)}
                    viewRef={this}
                />

            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(GrantBudgetSearch));
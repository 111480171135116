import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import SearchView from "../../../widgets/SearchView";
import WorkplaceCoachesSetupSearchForm from './../WorkplaceCoaches/WorkplaceCoachesSetupSearchForm';
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from "./../../../ViewUtils";
import withMenuPermission from "../../../widgets/withMenuPermission";


const headCells = [
    { id: 'WorkplaceCoachesCode', numeric: false, disablePadding: true, label: 'Workplace Coaches Programme Code' },
    { id: 'WorkplaceCoachesTitle', numeric: false, disablePadding: true, label: 'Workplace Coaches Programme Title' },
    { id: 'RegistrationStartDate', numeric: false, disablePadding: true, label: 'Registration Start Date' },
    { id: 'RegistrationEndDate', numeric: false, disablePadding: true, label: 'Registration End Date' }
];


const utils = new ViewUtils();

class WorkplaceCoachesSetupSearch extends Component {
    constructor(props) {
        super(props)

        let model = {
            WorkplaceCoachesCode: "",
            WorkplaceCoachesTitle: ""
        }

        this.searchParameters = !utils.isNull(this.props.programmeId) ?
            [
                { Name: "id", Value: this.props.programmeId },
                { Name: "WorkplaceCoachesCode", Value: "" },
                { Name: "WorkplaceCoachesTitle", Value: "" }
            ]
            :
            [
                { Name: "WorkplaceCoachesCode", Value: "" },
                { Name: "WorkplaceCoachesTitle", Value: "" },
            ]

        this.state = {
            model: model,
            selectedWorkplaceCoachesProgramme: null,
            keyUpSwitch: false
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: "delete", label: '', tooltip: 'Remove WorkplaceCoaches', icon: 'DeleteIcon', disabled: true, visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        }
    }

    onkeyup(e) {
        //console.log(e.charCode)
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer title="">
                <SearchView headCells={headCells} dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/WorkplaceCoachessetup/getsearch"}
                    entityViewPath='/WorkplaceCoachessetup'
                    title='Workplace Coaches'
                    model={this.state.model} toolbarConfig={this.toolbarConfig}
                    searchParameters={this.searchParameters} onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="WorkplaceCoachesTitle" mode={this.props.mode}
                    keyUpSwitch={this.state.keyUpSwitch}
                    history={this.props.history}
                    multiSelect={this.props.multiSelect}
                    deleteUrl={"api/sims/WorkplaceCoachesSetup/removeWorkplaceCoaches"} useDeletePost={true}
                    promptTitle={"Remove WorkplaceCoaches Programme"} selectionCallbackMode={this.props.selectionCallbackMode}
                    promptContent={"Removing the WorkplaceCoaches programme is a permanent action and the WorkplaceCoaches programme will not be available going forward. Are you sure you want to remove this WorkplaceCoaches programme?"}
                >
                    <WorkplaceCoachesSetupSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>

        );
    }
}

export default withRouter(withMenuPermission(WorkplaceCoachesSetupSearch));


import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../../widgets/Toolbar';
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import "../QualificationSetup.css";
import { QCTOQualificationForm } from './QCTOQualificationSetupForm';
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from "./../../../ViewUtils";
import AlertItem from "../../../widgets/AlertItem";
import withMenuPermission from "../../../widgets/withMenuPermission";


const qualificationRef = React.createRef();
const utils = new ViewUtils();

const toolbarRef = React.createRef();
class QCTOQualification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            qualificationSetupDetails: {
                SAQAQualificationID: "",
                SAQAQualificationTitle: ""
            },
            model: {
                OFOOccupationID: null,
                QualificationToBeReplacedID: null
            },
            initialModuleType: null,
            title: "",
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
            Document: {
                OriginalFileName: "",
                EntityID: "",
                Document: {
                    name: "",
                    size: "",
                    type: "",
                    base64: ""
                }
            },
        };

        this.defaultModel = {
            NQFLevelID: { Id: 1, Description: "Level 01" }
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.menuPermissionItem.Edit },
                        { id: 'modules', label: '', icon: 'LinkIcon', tooltip: 'Link QCTO Modeules', disabled: utils.isNull(this.state.id) },
                        { id: 'documents', label: 'DOCUMENTS', tooltip: 'QCTO Qualification Documents', disabled: utils.isNull(this.state.id) },
                        { id: 'learnership', label: 'QCTO LEARNERSHIPS', tooltip: 'QCTO Qualification Learnerships', disabled: utils.isNull(this.state.id) },
                        { id: 'skillsprogramme', label: 'QCTO SKILLS PROGRAMMES', tooltip: 'QCTO Qualification Skills Programmes', disabled: utils.isNull(this.state.id) }
                    ]
                }
            ]
        };
    }

    handleFormValueChange = (values) => {

        if (!utils.isNull(values)) {
            if (this.state.initialModuleType === null && !utils.isNull(values.QualificationTypeID)) {
                this.setState({ initialModuleType: values.QualificationTypeID.Id });
                toolbarRef.current.setButtonDisabled("modules", utils.isNull(this.state.id) || values.QualificationTypeID.Id === 2);
            }

            //TODO: To Be Refactored

            if (!utils.isNull(values.RegistrationStartDate)) {
                utils.autoSetDate(this, qualificationRef, values.RegistrationStartDate, 6, 0, -1, "LastEnrolmentDate", "RegistrationEndDate")
            }

            if (!utils.isNull(values.LastEnrolmentDate)) {
                utils.autoSetDate(this, qualificationRef, values.LastEnrolmentDate, 3, 0, -1, "LastAchievementDate", "LastEnrolmentDate")
            }

            this.setState({
                qualificationSetupDetails: {
                    SAQAQualificationID: values.SAQAQualificationID,
                    SAQAQualificationTitle: values.SAQAQualificationTitle,
                    QualificationTypeID: values.QualificationTypeID
                },
                Document: { ...Document, OriginalFileName: values.OriginalFileName },
            });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("modules" === buttonId) {
            this.props.history.push({
                pathname: '/qctoQualModulesearch',
                state: {
                    qualificationId: this.props.location.state.selection,
                    qualificationTitle: this.state.qualificationSetupDetails.SAQAQualificationTitle,
                    credits: this.state.qualificationSetupDetails.Credits
                }
            });
        }
        else if ("learnership" === buttonId) {
            this.props.history.push({
                pathname: '/qctoQualificationLearnerships',
                state: {
                    qualificationId: this.props.location.state.selection,
                    qualificationTitle: this.state.qualificationSetupDetails.SAQAQualificationTitle
                }
            })
        }
        else if ("skillsprogramme" === buttonId) {
            this.props.history.push({
                pathname: '/qctoQualificationSkillsProgrammes',
                state: {
                    qualificationId: this.props.location.state.selection,
                    qualificationTitle: this.state.qualificationSetupDetails.SAQAQualificationTitle
                }
            })
        } else if ("documents" === buttonId) {
            this.props.history.push({
                pathname: '/qctoQualificationDocuments',
                state: {
                    qualificationId: this.props.location.state.selection
                }
            });
        }
    }

    successCallback = (response) => {
        let qualType = response.QualificationType;
        if (!utils.isNull(qualType) && qualType !== 0) {
            toolbarRef.current.setButtonDisabled("Modules", qualType === 2);
        }

        if (!utils.isNull(response.EntityId) && response.EntityId !== 0) {
            this.setState({ id: response.EntityId }, () => {
                this.props.history.push({
                    pathname: "/QCTOQualificationsetup",
                    state: { selection: this.state.id }
                })
                toolbarRef.current.setButtonDisabled("Modules", this.state.id === null || response.MessageType === "ERROR" || qualType === 2 || qualType === 0)
                toolbarRef.current.setButtonDisabled("learnership", this.state.id === null || response.MessageType === "ERROR")
            })
        }
    }

    save() {
        let formValue = qualificationRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            let isAdd = true;
            if (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) {
                formValue.id = this.props.location.state.selection;
                isAdd = false;
            }
            formValue.Document = this.state.Document;
            const data = JSON.stringify(formValue);
            let url = "api/sims/QCTOqualificationsetupdetails/updatequalification";
            if (!isAdd) {
                utils.invokeUrl(url, (response) => {
                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response)
                },
                    null,
                    {
                        method: 'PUT',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        },
                        body: data
                    }
                )
            } else {
                url = "api/sims/QCTOqualificationsetupdetails/insertqualification";
                utils.invokeUrl(url, (response) => {
                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response)
                },
                    null,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        },
                        body: data
                    }
                )
            }
        }
        //utils.saveForm(this, qualificationRef, `api/indicium/QualificationSetup/updateQualificationSetup`,this.successCallback, null);
    }

    closeView() {
        this.props.history.push('/QCTOqualificationsetupSearch');
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    QCTO Qualification
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    ref={toolbarRef}
                                    title="QCTO Qualification"
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={this.toolbarConfig}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    <QCTOQualificationForm ref={qualificationRef} data={this.state.model} id={this.state.id !== null ? this.state.id : typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)} editPermission={this.props.menuPermissionItem.Edit} defaultHandlerObj={this.defaultModel} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(QCTOQualification));


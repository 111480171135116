import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();

export const AssessorRegistrationDetailsForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {

            //if (!utils.isNull(values.RegistrationStartDate)) {
            //    utils.autoSetDate(props.viewRef, ref, values.RegistrationStartDate, 3, 0, -1, "RegistrationEndDate", "RegistrationStartDate")
            //}

            if (!utils.isNull(props.valueChangeHandler)) {
                props.valueChangeHandler(values);
            }
        }
    }

    const dateChange = (form, date) => {

        if (!utils.isNull(form.values.RegistrationStartDate)) {
            utils.autoSetDate(props.viewRef, ref, form.values.RegistrationStartDate, 3, 0, -1, "RegistrationEndDate", "RegistrationStartDate")
        }
    }

    return (
        <EntityForm ref={ref} viewId="assessor" values={props.data} org="saseta"
            dataURL='api/sims/assessorregistrationdetails/GetAssessorRegistrationDetails'
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={(values) => { handleFormValueChange(values) }}>
            {form =>
                <form className={form.classes.container} id="assessorRegistrationDetailsForm">
                    <FormTabContainer>
                        <table cellPadding="2" width="80%">
                            <tbody>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="ETDPRegistrationNumber"
                                            key={tabValue}
                                            label="ETDP Registration Number"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form} 
                                            disabled={!props.editPermission}
                                        />
                                    </td>
                                    <td>
                                        <SelectItem
                                            dataUrl="api/sims/assessorregistrationdetails/GetLookupList?listId=AssessorType"
                                            id="AssessorTypeID"
                                            required
                                            key={tabValue}
                                            label="Assessor Type"
                                            className="w-100"
                                            form={form} 
                                            disabled={!props.editPermission} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomDatePicker
                                            required={true}
                                            id="RegistrationStartDate"
                                            key={tabValue}
                                            label="Registration Start Date"
                                            className='{classes.textField} w-100 '
                                            InputProp={{
                                                readOnly: true
                                            }}
                                            form={form} 
                                            disabled={!props.editPermission}
                                            onChange={dateChange}
                                        />
                                    </td>
                                    <td>
                                        <CustomDatePicker
                                            id="RegistrationEndDate"
                                            InputProp={{
                                                readOnly: true
                                            }}
                                            disabled={true}
                                            key={tabValue}
                                            label="Registration End Date"
                                            validator={{
                                                script: (formValues) => {
                                                    return !utils.isNull(formValues)
                                                        && !utils.isNull(formValues.RegistrationStartDate)
                                                        && formValues.RegistrationStartDate.getTime() < formValues.RegistrationEndDate.getTime()
                                                }, message: "Start date must be before the end date"
                                            }}
                                            className='{classes.textField} w-100'
                                            form={form} 
                                            disabled={!props.editPermission}
                                        />

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <SelectItem
                                            dataUrl="api/sims/assessorregistrationdetails/GetLookupList?listId=AssessorRegistrationStatus"
                                            id="AssessorRegistrationStatusID"
                                            required
                                            key={tabValue}
                                            label="Assessor Registration Status"
                                            className="{classes.select} w-100"
                                            allowEmptyValue={true}
                                            form={form} 
                                            disabled={!props.editPermission} />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="RegistrationNumber"
                                            key={tabValue}
                                            label="Registration Number"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: true
                                        }}
                                        disabled={true}
                                            form={form} 
                                            disabled={!props.editPermission}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </FormTabContainer>
                </form>
            }
        </EntityForm>

    );
})

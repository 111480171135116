import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import SearchView from "../../widgets/SearchView";
import LearnershipSetupSearchForm from '../program/LearnershipSetupSearchForm';
import ViewUtils from '../../ViewUtils';
import withMenuPermission from "../../widgets/withMenuPermission";
import AlertItem from "../../widgets/AlertItem";
import { LearnerEISAEnrolmentUpdateForm } from "../learner/LearnerEISAEnrolmentUpdateForm";
import FormDialog from "../FormDialog";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';


const headCells = [
    { id: 'QCTOLearnershipCode', numeric: false, disablePadding: true, label: 'QCTO Learnership Code' },
    { id: 'QCTOLearnershipTitle', numeric: false, disablePadding: true, label: 'QCTO Learnership Title' },
    { id: 'AssessmentCentre', numeric: false, disablePadding: true, label: 'Assessment Centre' },
    { id: 'EISASerialNumber', numeric: false, disablePadding: true, label: 'EISA Serial Number' },
    { id: 'EISAAchievementValue', numeric: false, disablePadding: true, label: 'EISA Achievement Value' },
    { id: 'DateAssessed', numeric: false, disablePadding: true, label: 'Date Assessed' },
    { id: 'EISAPercentageObtained', numeric: false, disablePadding: true, label: 'EISA Percentage Obtained' }
];


const utils = new ViewUtils();
const toolbarRef = React.createRef();
const updateFormRef = React.createRef();

class LearnerEISAEnrolment extends Component {
    constructor(props) {
        super(props);

        console.log(this.props.programmeId);
        this.searchParameters = !utils.isNull(this.props.location.state.learnerID) ? [
            { Name: "LearnershipProgrammeCode", Value: "" },
            { Name: "LearnershipProgrammeDescription", Value: "" },
            { Name: "ProgrammeID", Value: this.props.programmeId },
            { Name: "LearnerID", Value: this.props.location.state.learnerID }
        ]
            :
            [
                { Name: "LearnershipProgrammeCode", Value: "" },
                { Name: "LearnershipProgrammeDescription", Value: "" },
                { Name: "LearnerID", Value: this.props.location.state.learnerID }
            ];

        let model = !utils.isNull(this.props.location.state.learnerID) ?
            {
                LearnershipProgrammeCode: "",
                LearnershipProgrammeDescription: "",
                ProgrammeID: this.props.programmeId,
                LearnerID: this.props.location.state.learnerID

            }
            :
            {
                LearnershipProgrammeCode: "",
                LearnershipProgrammeDescription: "",
                LearnerID: this.props.location.state.learnerID
            }


        if (!utils.isNull(props.exclusions)) {
            model.Exclusions = props.exclusions;
            this.searchParameters.push({ Name: "Exclusions", Value: props.exclusions });
        }



        this.state =
        {
            showTable: true,
            model: model,
            keyUpSwitch: false,
            selectedLearnerEISAEnrolment: null,
            clearSelectedRow: false,
            updateDateDialogOpen: false
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', visible: this.props.menuPermissionItem.Edit, disabled: utils.isNull(this.state.selectedLearnerEISAEnrolment) },
                    ]
                }
            ]
        }
    }


    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/learner',
                state: {
                    selection: this.props.location.state.learnerID
                }
            });
        }
        else if (buttonId === "edit") {
            this.setState({ updateDateDialogOpen: true, message: "" })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedLearnerEISAEnrolment: selection[0].Id })
            toolbarRef.current.setButtonDisabled("edit", false)
        }
        else {
            this.setState({ selectedLearnerEISAEnrolment: null })
            toolbarRef.current.setButtonDisabled("edit", true)
        }
    }


    onkeyup(e) {
        //console.log(e.charCode)
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    onClearSelectedRow = () => {
        this.setState({ clearSelectedRow: false });
    }

    dialogUpdateActionHandler = (isCancel, added) => {
        console.clear()
        console.log(isCancel)
        console.log(added)
        if (added === true) {
            this.setState({
                showTable: false,
                updateDateDialogOpen: false
            }, () => { this.setState({ showTable: true }) });
        }
        else if (isCancel === true) {
            this.setState({
                updateDateDialogOpen: false
            })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {`Learner > EISA Enrolments > ${this.props.location.state.learnerNames}`}
                </p>

                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />

                <EnhancedToolbar
                    highlight={false}
                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                    ref={toolbarRef}
                    config={this.toolbarConfig} />
                {this.state.showTable === true && (

                <EnhancedTable
                    headCells={headCells}
                    dataUrl={`api/sims/LearnerEISAEnrolment/GetLearnerEISAEnrolmentSearch`}
                    height="60vh"
                        onSelectionChange={(selection) => { this.handleSelection(selection) }}
                    searchParameters={this.searchParameters} paged={true} />
                )}



                <FormDialog
                    open={this.state.updateDateDialogOpen}
                    dialogTitle={"Update Learner EISA Enrolment"}
                    viewRef={this}
                    entityId={this.state.selectedLearnerEISAEnrolment}
                    formRef={updateFormRef}
                    usePut={true}
                    saveUrl={"api/sims/learnerEISAEnrolment/UpdateLearnerEISAEnrolment"}
                    closeHandler={(isCancelled, linked) => this.dialogUpdateActionHandler(isCancelled, linked)}
                >
                    <LearnerEISAEnrolmentUpdateForm ref={updateFormRef} id={this.state.selectedLearnerEISAEnrolment} dataURL='api/sims/learnerEISAEnrolment/GetFindLearnerEISAEnrolment' controllerName='learnerEISAEnrolment' />
                </FormDialog>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(LearnerEISAEnrolment));

import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";

import withMenuPermission from "../../../widgets/withMenuPermission";
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from "../../../ViewUtils";
import GrantAllocationSearchForm from './GrantAllocationSearchForm';
import SearchView from "../../../widgets/SearchView";

const utils = new ViewUtils();
const toolbarRef = React.createRef();

const headCells = [
    { id: 'SDLNumber', numeric: false, disablePadding: false, label: 'Employer SDL Number' },
    { id: 'TradeName', numeric: false, disablePadding: false, label: 'Employer Name' },
    { id: 'Province', numeric: false, disablePadding: false, label: 'Employer Province' },
    { id: 'AllocationAmount', numeric: false, disablePadding: false, label: 'Number of Learners Allocated' },
    { id: 'AdditionalAmount', numeric: false, disablePadding: false, label: 'Number of Learners Registered' },
    { id: 'Associated', numeric: false, disablePadding: false, label: 'Number of Learners Outstanding' }
];


class GrantAllocationSearch extends Component {

    constructor(props) {
        super(props);



        this.state = {
            selectGrantAllocation: null,
            model: {
                TrancheCodeID: null,
                FinancialYearID: null,
                EmployerID: null
            },
            searchParameters: [{ Name: "TrancheCodeID", Value: 0, Name: "FinancialYearID", Value: 0, Name: "EmployerID", Value: 0 }],
            keyUpSwitch: false

        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                          {id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add},
                          {id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', visible: this.props.menuPermissionItem.Edit, disabled: utils.isNull(this.state.selectGrantAllocation)} 
                    ]
                }
            ]
        };

    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectGrantAllocation: selection })
            let url = "api/sims/GrantAllocation/CheckCanEdit?Id=" + selection[0].Id;
            utils.invokeUrl(url, (response) => {
                toolbarRef.current.setButtonDisabled("edit", response.Result === "0" ? true : false)
            },
                (e) => {
                    toolbarRef.current.setButtonDisabled("edit", true)
                },
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    }
                }
            )
        }
        else {
            this.setState({ selectGrantAllocation: null })
        }
        toolbarRef.current.setButtonDisabled("edit", selection === null || selection.length === 0);
    }

    handleFormValueChange = (values) => {
        if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
            this.setState({ model: values, selectGrantAllocation: null });
            toolbarRef.current.setButtonDisabled("edit", true);
        }
    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.props.history.push({
                pathname: '/AddEditAllocation',
                state: {
                    AllocationID: 0,
                    hideFields: true
                }
            })
        }
        else if (buttonId === "edit") {
            this.props.history.push({
                pathname: '/AddEditAllocation',
                state: {
                    AllocationID: this.state.selectGrantAllocation[0].Id,
                    EmployerID: this.state.model.EmployerID.Id,
                    TrancheCodeID: this.state.model.TrancheCodeID.Id,
                    FinancialYearID: this.state.model.FinancialYearID.Id,
                    hideFields: false,
                    disableFields:true
                }
            })
        }
    }

    render() {

        return (
            <ViewContainer title="">

                <SearchView headCells={headCells} dataUrl="api/sims/GrantAllocation/GetSearch" entityViewPath='' addViewPath=''
                    model={this.state.model} toolbarConfig={this.toolbarConfig} searchParameters={this.searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange} 
                    useDeletePost={true}
                    toolbarRef={toolbarRef}
                    history={this.props.history}
                    searchLabel={"SEARCH"}
                    title='Grant Allocation'
                    handleObject={true}
                    autoFetchData={false}
                    keyUpSwitch={this.state.keyUpSwitch}
                    promptTitle={"Remove Grant Allocation"}
                    promptContent={"Are you sure you want to remove this grant allocation?"}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                >
                    <GrantAllocationSearchForm data={this.state.model} controllerName='grantallocation'
                        valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                </SearchView>

            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(GrantAllocationSearch));




import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuButton from './MenuButton'
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ViewUtils from '../ViewUtils';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EmailIcon from '@material-ui/icons/Email';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import CloseIcon from '@material-ui/icons/Backspace';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import PrintIcon from '@material-ui/icons/Print';
import UploadIcon from '@material-ui/icons/CloudUpload';
import AddCommentIcon from '@material-ui/icons/AddComment';
import TimelineIcon from '@material-ui/icons/Timeline';
import UpdateIcon from '@material-ui/icons/Update';
import SmsIcon from '@material-ui/icons/Sms';
import CreateIcon from '@material-ui/icons/Create';
import ApproveIcon  from '@material-ui/icons/VerifiedUser';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PasswordIcon from '@material-ui/icons/VpnKey';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import BusinessIcon from '@material-ui/icons/Business';
import MoneyIcon from '@material-ui/icons/Money';
import CancelIcon from '@material-ui/icons/Cancel';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ExtensionIcon from '@material-ui/icons/Extension';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SendIcon from '@material-ui/icons/Send';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '5px'
    },
    divider: {
        alignSelf: 'stretch',
        height: 'auto',
        width: '1px',
        margin: theme.spacing(1, 0.5),
        backgroundColor: theme.palette.primary.main
    },
    themeButton: {
        color: 'white',
        fontSize: '12px',
        height: '30px',
        backgroundColor: theme.palette.primary.main,
        "&:hover, &:focus": {
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            opacity: '0.7'
            
        }
    },
}));

const StyledToggleButtonGroup = withStyles(theme => ({
    grouped: {
        margin: theme.spacing(0.5),
        border: 'none',
        padding: theme.spacing(0, 1),
        '&:not(:first-child)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-child': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}))(ToggleButtonGroup);

const utils = new ViewUtils();

const EnhancedToolbar = React.forwardRef((props, ref) => {
    const [alignment, setAlignment] = React.useState('left');
    const [buttonDisability, setButtonDisability] = React.useState({});
    const [config, setConfig] = React.useState(props.config);
    let itemKeyCounter = 0;
    let buttonKeyCounter = 0;

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const setButtonDisabled = (name, disabled) => {
        buttonDisability[name] = disabled
        // Force a refresh
        //setButtonDisability(JSON.parse(JSON.stringify(buttonDisability)));
        setButtonDisability({ ...buttonDisability, ["key"]: Math.random() });
    };

    React.useImperativeHandle(ref, () => ({
        setButtonDisabled: (name, disabled) => {
            return setButtonDisabled(name, disabled);
        }
    }));

    React.useEffect(() => {
        if (!utils.isNull(props.mode)) {
            setConfig(utils.processToolbarConfig(props.mode, props.config));
        } else {
            setConfig(props.config);
        }

        let items = config.items;
        let temp = {};
        for (var i = 0; i < items.length; i++) {
            let type = items[i].type;
            if (type === 'buttonGroup') {
                let buttons = items[i].buttons;
                for (var j = 0; j < buttons.length; j++) {
                    let button = buttons[j];
                    temp[button.id] = !utils.isNull(button.disabled) && button.disabled;
                }
            }
        }

        setButtonDisability(temp);
    }, [props.mode, props.config]);

    const classes = useStyles();
    return (
        <div>
            <Paper elevation={0} className={classes.paper} ref={ref}>
                {config !== null ? config.items.map(item => (
                    item.type === 'buttonGroup' ?
                        <StyledToggleButtonGroup
                            size="small"
                            value={alignment}
                            exclusive
                            key={itemKeyCounter++}
                            onChange={handleAlignment}
                            aria-label="text alignment"
                        >
                            {
                                item.buttons.map(button => (
                                    button.visible === null || typeof button.visible === "undefined" || button.visible === true ?
                                        button.contextMenu === null || typeof button.contextMenu === "undefined" ?
                                            <ToggleButton key={buttonKeyCounter++} value={button.id} aria-label={button.Tooltip}
                                                disabled={buttonDisability[button.id]}
                                                // onClick={() => props.buttonClickHandler(button.id)} className={button.theme === true ? classes.themeButton : ""}
                                                onClick={() => props.buttonClickHandler(button.id, button.path===undefined?null:button.path)} className={button.theme === true ? classes.themeButton : ""}
                                                style={{ width: button.width !== null && typeof button.width !== 'undefined' ? button.width : 'auto' }}
                                            >
                                                <Tooltip title={button.tooltip} arrow>
                                                    {button.icon !== null && typeof button.icon !== 'undefined' ?
                                                        button.icon === "AddIcon" ? < AddIcon /> : 
                                                        (button.icon === "SaveIcon" ? < SaveIcon  /> :
                                                        (button.icon === "EditIcon" ? < EditIcon  /> : 
                                                        (button.icon === "InfoIcon") ? <InfoIcon  /> : 
                                                        (button.icon === "ViewIcon") ? <VisibilityIcon  /> : 
                                                        (button.icon === "EmailIcon") ? <EmailIcon  /> :
                                                        (button.icon === "DownloadIcon") ? <DownloadIcon  /> :
                                                        (button.icon === "CloseIcon") ? <CloseIcon /> :
                                                        (button.icon === "LinkIcon") ? <LinkIcon /> :
                                                        (button.icon === "LinkOffIcon") ? <LinkOffIcon /> :
                                                        (button.icon === "PrintIcon") ? <PrintIcon /> :
                                                        (button.icon === "UploadIcon") ? <UploadIcon  /> :
                                                        (button.icon === "AddCommentIcon") ? <AddCommentIcon  /> :
                                                        (button.icon === "AuditIcon") ? <TimelineIcon  /> :
                                                        (button.icon === "UpdateIcon") ? <UpdateIcon  /> :
                                                        (button.icon === "SmsIcon") ? <SmsIcon  /> :
                                                        (button.icon === "CreateIcon") ? <CreateIcon  /> :
                                                        (button.icon === "ApproveIcon") ? <ApproveIcon  /> :
                                                        (button.icon === "LockOpenIcon") ? <LockOpenIcon  /> :
                                                        (button.icon === "PasswordIcon") ? <PasswordIcon  /> :
                                                        (button.icon === "AccountTreeOutlinedIcon") ? <AccountTreeOutlinedIcon  /> :
                                                        (button.icon === "ImportExportIcon") ? <ImportExportIcon  /> :
                                                        (button.icon === "BusinessIcon") ? <BusinessIcon  /> :
                                                        (button.icon === "MoneyIcon") ? <MoneyIcon  /> :
                                                        (button.icon === "CancelIcon") ? <CancelIcon  /> :
                                                        (button.icon === "AssessmentIcon") ? <AssessmentIcon /> :
                                                        (button.icon === "ExtensionIcon") ? <ExtensionIcon /> :
                                                        (button.icon === "GetAppIcon") ? <GetAppIcon /> :
                                                        (button.icon === "RefreshIcon") ? <RefreshIcon /> :
                                                        (button.icon === "ReceiptIcon") ? <ReceiptIcon /> :
                                                        (button.icon === "SendIcon") ? <SendIcon /> :
                                                        (button.icon === "LibraryBooksIcon") ? <LibraryBooksIcon /> :
                                                        <DeleteIcon  />)) :
                                                        <div>{button.label}</div>
                                                    }
                                                </Tooltip>
                                            </ToggleButton>
                                            :
                                            <div>
                                                <MenuButton label={button.label} width={button.width} defaultStyle={true} disabled={buttonDisability[button.id]} contextIcon={button.contextIcon} tooltip={button.tooltip}>
                                                    <MenuList style={{ background: "#ffffff", opacity: "1" }}>
                                                        {button.contextMenu
                                                            .filter(menu => { return menu.visible === null || typeof menu.visible === "undefined" || menu.visible === true ? true : false; })
                                                            .map(menu => (
                                                            <MenuItem disabled={menu.disabled} onClick={() => props.buttonMenuClickHandler(menu)}>{menu.description}</MenuItem>
                                                        ))}
                                                    </MenuList>
                                                </MenuButton>
                                                
                                                
                                                
                                            </div>
                                        :
                                        ""
                                ))
                            }
                        </StyledToggleButtonGroup> :
                        item.type === 'devider' && item.visible === null || typeof item.visible === "undefined" || item.visible === true ?
                            <Divider orientation="vertical" key={buttonKeyCounter++} className={classes.divider} /> : null
                )) : null}
            </Paper>
        </div>
    );
})

export default EnhancedToolbar;


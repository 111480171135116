import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { AddressCodeItem } from '../../../widgets/AddressCodeItem';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { CustomCheckbox } from '../../../widgets/CustomCheckbox';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from '../../../ViewUtils';
import { makeStyles } from '@material-ui/core/styles';
import { LookupField } from '../../../widgets/LookupField';
import SchoolEMISSearch from '../../SchoolEMISSearch';
import STATSSAAreaCodeSearch from '../../STATSSAAreaCodeSearch';
import { GPSCoordinatesAPI } from '../../../widgets/GPSCoordinatesAPI';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const utils = new ViewUtils();
export const OnlineModeratorDetailsVerificationForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="moderator" values={props.data} dataURL='api/sims/OnlineModeratorApproval/GetApprovalVerificationFind'
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="ModeratorForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="General Details" wrapped {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel className="w-100" value={tabValue} index={0}>
                            <table cellPadding="2" width="80%" index={0}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineModeratorApproval/GetLookupList?listId=YesNo"
                                                id="DetailsCompletedID"
                                                key={tabValue}
                                                label="Moderator Details Complete"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineModeratorApproval/GetLookupList?listId=YesNo"
                                                id="ScopeCompleteID"
                                                key={tabValue}
                                                label="Moderator Scope Complete"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineModeratorApproval/GetLookupList?listId=YesNo"
                                                id="IDUploadedID"
                                                key={tabValue}
                                                label="Moderator ID Uploaded"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineModeratorApproval/GetLookupList?listId=YesNo"
                                                id="CertificateUploadedID"
                                                key={tabValue}
                                                label="Moderator Certificate endorsed by ETDP SETA uploaded"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineModeratorApproval/GetLookupList?listId=YesNo"
                                                id="HighestQualificationUploadedID"
                                                key={tabValue}
                                                label="Moderator Copy of Highest Qualification uploaded"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineModeratorApproval/GetLookupList?listId=YesNo"
                                                id="CVUploadedID"
                                                key={tabValue}
                                                label="Record of Service Uploaded"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form} />
                                        </td>
                                    </tr>
                                    <tr>
                                        {
                                            (utils.getCurrentRole() == "ETQA Manager") ?
                                                <td>
                                                    <SelectItem
                                                        dataUrl="api/sims/OnlineModeratorApproval/GetLookupList?listId=ORAMApplicationStatus"
                                                        id="ORAMApplicationStatusID"
                                                        key={tabValue}
                                                        label="Status"
                                                        parentId={0}
                                                        required
                                                        className="w-100"
                                                        form={form} 
                                                        filterOptions={option => {
                                                            return option.Id !== 1 && option.Description !== "Pending";
                                                        }}
                                                    />
                                                </td>
                                                :
                                                ""
                                        }
                                        {
                                            (utils.getCurrentRole() == "QA Administrator") ?
                                                <td>
                                                    <SelectItem
                                                        dataUrl="api/sims/OnlineModeratorApproval/GetLookupList?listId=ORAMApplicationStatus"
                                                        id="ORAMApplicationStatusID"
                                                        key={tabValue}
                                                        label="Status"
                                                        parentId={0}
                                                        required
                                                        className="w-100"
                                                        form={form} 
                                                        filterOptions={option => {
                                                            return option.Id !== 2 && option.Description !== "Approved";
                                                        }}
                                                    />
                                                </td>
                                                :
                                                ""
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})


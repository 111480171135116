import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";
import Prompt from '../../widgets/Prompt';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import UserSearch from "../UserSearch";
import { WorkplaceApprovalAddUserForm } from "./WorkplaceApprovalAddUserForm";
import LinkDialog from "../LinkDialog";
import FormDialog from "../FormDialog";
import withMenuPermission from "../../widgets/withMenuPermission";


const headCells = [
    { id: 'FirstName', numeric: false, disablePadding: true, label: 'First Name' },
    { id: 'Surname', numeric: false, disablePadding: true, label: 'Surname' },
    { id: 'IDNo', numeric: false, disablePadding: true, label: 'ID No' },
    { id: 'TelephoneNumber', numeric: false, disablePadding: true, label: 'Telephone Number' },
    { id: 'CellphoneNumber', numeric: false, disablePadding: true, label: 'CellPhone Number' },
    { id: 'FaxNumber', numeric: false, disablePadding: true, label: 'Fax Number' },
    { id: 'Email', numeric: false, disablePadding: true, label: 'Email' }

];

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class WorkplaceApprovalUsersSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            linkDialogOpen: false,
            formDialogOpen: false,
            selectedUser: null,
            propmtOpen: false,
            promptTitle: "Remove User from Workplace Approval",
            promptContent: "Are you sure you want to remove this user from the Workplace Approval?",
            unlinkMessage: "",
            unlinkMessageStyle: "",
            searchParameters: [{ Name: "WorkplaceApprovalID", Value: this.props.location.state.selection }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: "link", label: '', tooltip: 'Link existing user to Workplace Approval', icon: 'LinkIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'add', label: '', tooltip: 'Add new user to Workplace Approval', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'remove', label: '', tooltip: 'Remove user from Workplace Approval', icon: 'DeleteIcon', disabled: utils.isNull(this.state.selectedUser), visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        };

    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/WorkplaceApproval',
                state: { selection: this.props.location.state.selection }
            });
        }
        else if ("add" === buttonId) {
            this.setState({ formDialogOpen: true, removeMessage: "" })
        }
        else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, removeMessage: "" })
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                formDialogOpen: false, selectedUser: null
            });
        }
        else if (isCancel === true) {
            this.setState({
                formDialogOpen: false,
                linkDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedUser: selection[0].Id, removeMessage: "" });
        }
        else {
            this.setState({ selectedUser: null, removeMessage: "" });
        }
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    successfulUserRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedUser: null, removeMessage: response.Message, removeMessageStyle: response.MessageType.toLowerCase() });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulUserRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ removeMessage: response.Message, removeMessageStyle: response.MessageType.toLowerCase() })
        }
    }

    removeUser(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedUser}&WorkplaceApprovalId=${this.props.location.state.selection}`;
        utils.invokeUrlDelete(fullUrl, this.successfulUserRemoveCallback, this.unsuccessfulUserRemoveCallback);
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">

                <p className="breadcrumbs">
                    {"Workplace Approval > Users " + (this.props.location.state.WorkplaceApprovalName !== null ? "> " + this.props.location.state.WorkplaceApprovalName + (this.props.location.state.WorkplaceApprovalSDLNumber !== null ? " - " + this.props.location.state.WorkplaceApprovalSDLNumber : "") : "> Add")}
                </p>

                <AlertItem message={this.state.removeMessage} alertStyle={this.state.removeMessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    <EnhancedTable
                        headCells={headCells}
                        dataUrl={`api/sims/WorkplaceApprovaluser/GetWorkplaceApprovalUsers`}
                        height="60vh"
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        searchParameters={this.state.searchParameters} paged={true} />
                </div>

                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title={this.props.location.state.qualificationTitle}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    dialogTitle={`Link Users to Workplace Approval > ${this.props.location.state.WorkplaceApprovalName} - ${this.props.location.state.WorkplaceApprovalSDLNumber}`}
                    linkName={"Users"} postUrl={"api/sims/WorkplaceApprovaluser/LinkWorkplaceApprovalUser"} usePost={true}
                    selectionId={this.props.location.state.selection}
                >

                    <UserSearch
                        multiSelect={true} showTitle={false}
                        programmeId={this.props.location.state.selection} programmeType="Q"
                        mode="lookup"
                        dataUrl="api/sims/WorkplaceApprovaluser/GetSearchLinkableUsers"
                    />
                </LinkDialog>

                <FormDialog
                    open={this.state.formDialogOpen}
                    dialogTitle={"Add Workplace Approval User"}
                    viewRef={this}
                    formRef={addFormRef}
                    saveUrl={"api/sims/WorkplaceApprovaluser/InsertWorkplaceApprovalUser"}
                    usePost={true}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                >
                    <WorkplaceApprovalAddUserForm ref={addFormRef} />
                </FormDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeUser("api/sims/WorkplaceApprovaluser/RemoveWorkplaceApprovalUser") }}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(WorkplaceApprovalUsersSearch));

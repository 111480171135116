import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import LinkDialog from "../LinkDialog";
import Prompt from '../../widgets/Prompt';
import AlertItem from "../../widgets/AlertItem";
import LearnershipSetupSearch from '../program/LearnershipSetupSearch';
import ViewUtils from '../../ViewUtils';
import { OutComeStatusForm } from '../coachmentor/OutComeStatusForm';
/*import CoachMentorQCTOLearnershipSearchModule from "../program/QCTOLearnership/CoachMentorQCTOLearnershipSearchModule";*/
import FormDialog from "../FormDialog";
import CoachMentorInterventionAudit from '../coachmentor/CoachMentorInterventionAudit';
import Dialog from '@material-ui/core/Dialog';
import { Typography } from "antd";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';


const headCells = [
    { id: 'LearnershipCode', numeric: false, disablePadding: true, label: 'QCTO Learnership ID' },
    { id: 'LearnershipTitle', numeric: false, disablePadding: true, label: 'QCTO Learnership Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' },
    { id: 'LearnershipRegistrationStartDate', numeric: false, disablePadding: true, label: 'Registration Start Date' },
    { id: 'LearnershipRegistrationEndDate', numeric: false, disablePadding: true, label: 'Registration End Date' },
    { id: 'Outcome', numeric: false, disablePadding: true, label: 'Outcome' },
    { id: 'Status', numeric: false, disablePadding: true, label: 'Status' }
];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const LUSheadCells = [
    { id: 'ModuleCode', numeric: false, disablePadding: true, label: 'SAQA Module ID' },
    { id: 'ModuleTitle', numeric: false, disablePadding: true, label: 'SAQA Module Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' },
    { id: 'ModuleType', numeric: false, disablePadding: true, label: 'Module Type' },
    { id: 'LearningType', numeric: false, disablePadding: true, label: 'Learning Type' },
    { id: 'NQFLevel', numeric: false, disablePadding: true, label: 'NQF Level' }
];

const useStyles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        color: 'white',
    },
    table: {
        minWidth: 700,
    },
});

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class CoachMentorLearnershipSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                LearnershipCode: "",
                LearnershipTitle: "",
                CoachMentorID: this.props.location.state.CoachMentorID
            },
            showTable: true,
            linkDialogOpen: false,
            auditDialogOpen: false,
            updateDialogOpen: false,
            selectedLearnership: null,
            propmtOpen: false,
            promptTitle: "Unlink Learnership From Coach/Mentor",
            promptContent: "Are you sure you want to unlink this Learnership from the coachmentor?",
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "CoachMentorID", Value: this.props.location.state.CoachMentorID }],
            searchParameters2: [{ Name: "Id", Value: 0 }],
            OpenDialog: false,
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' },
                        { id: 'view', label: '', tooltip: 'View Modules', icon: 'ViewIcon', disabled: utils.isNull(this.state.selectedLearnership) },
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/CoachMentor',
                state: { selection: this.props.location.state.CoachMentorID }
            });
        }
        else if ("view" === buttonId) {
            this.setState({ OpenDialog: true })
        }
    }

    successCallback = (response) => {
        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedLearnership: null,
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
            })
        }
        else if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    successUnlinkCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedLearnership: null, removeMessage: response.Message, removeMessageStyle: response.MessageType.toLowerCase() });
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unlinkLearnership(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("unlink", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedLearnership}&coachmentorId=${this.props.location.state.CoachMentorID}`;
        utils.invokeUrlDelete(fullUrl, this.successUnlinkCallback, this.unsuccessfulUnlinkCallback);
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false, auditDialogOpen: false, selectedLearnership: null
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false,
                auditDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            let newsearchParameters2 = [...this.state.searchParameters2];
            let temp_element = { ...newsearchParameters2[0] };
            temp_element.Value = selection[0].Id
            newsearchParameters2[0] = temp_element;

            this.setState({
                searchParameters2: JSON.parse(JSON.stringify(newsearchParameters2)),
                selectedLearnership: selection[0].Id,
                message: "",
                messageStyle: ""
            });
        }
        else {
            this.setState({
                selectedLearnership: null,
                message: "",
                messageStyle: ""
            });
        }
        toolbarRef.current.setButtonDisabled("view", selection === null || selection.length === 0);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    unsuccessfulUnlinkCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ removeMessage: response.Message, removeMessageStyle: response.MessageType.toLowerCase() })
        }
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                updateDialogOpen: false, selectedSiteVisit: null, linkDialogOpen: false, auditDialogOpen: false, selection: this.props.location.state.selection, selectedLearnership: null
            }, () => {
                this.setState({ showTable: true })
            });
        }
        else if (isCancel === true) {
            this.setState({
                updateDialogOpen: false,
                linkDialogOpen: false,
                auditDialogOpen: false
            })
        }
    }

    CloseForm = () => {
        this.setState({
            OpenDialog: false,
            selectedId: null,
            clearSelectedRow: true,
            selectedLearnership: null
        });
        toolbarRef.current.setButtonDisabled("view", true);
    };

    render() {
        const { classes } = this.props;
        return (
            <ViewContainer title="">

                <p className="breadcrumbs">
                    {"Coach/Mentor > QCTO Learnerships " + (this.props.location.state.CoachMentorName !== null ? "> " + this.props.location.state.CoachMentorName : "> Add")}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        ref={toolbarRef}
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />

                    {this.state.showTable === true && (

                        <EnhancedTable
                            height="50vh"
                            headCells={headCells}
                            dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/CoachMentorLearnership/GetCoachMentorLearnerships"}
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}
                </div>

                {/*<LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}*/}
                {/*    title={this.props.location.state.LearnershipTitle}*/}
                {/*    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}*/}
                {/*    dialogTitle={`Link Learnerships to Coach/Mentor > ${this.props.location.state.CoachMentorName}`}*/}
                {/*    linkName={"Learnerships"} postUrl={"api/sims/CoachMentorLearnership/linkCoachMentorLearnerships"}*/}
                {/*    selectionId={this.props.location.state.CoachMentorID} usePost={true}*/}
                {/*>*/}

                {/*    <LearnershipSetupSearch dataUrl="api/sims/CoachMentorLearnership/GetCoachMentorLinkableLearnerships"*/}
                {/*        multiSelect={true} showTitle={false}*/}
                {/*        programmeId={this.props.location.state.CoachMentorID}*/}
                {/*        mode="lookup"*/}
                {/*    />*/}
                {/*</LinkDialog>*/}

                {/*<FormDialog*/}
                {/*    open={this.state.updateDialogOpen}*/}
                {/*    dialogTitle={"Update Outcome and Status"}*/}
                {/*    viewRef={this}*/}
                {/*    entityId={this.state.selectedLearnership}*/}
                {/*    formRef={addFormRef}*/}
                {/*    usePut={true}*/}
                {/*    saveUrl={"api/sims/CoachMentorLearnership/updateCoachMentorLearnership"}*/}
                {/*    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}*/}
                {/*>*/}
                {/*    <OutComeStatusForm*/}
                {/*        ref={addFormRef}*/}
                {/*        id={this.state.selectedLearnership}*/}
                {/*        controllerName={"CoachMentorLearnership"}*/}
                {/*        dataURL='api/sims/CoachMentorLearnership/GetFindLearnershipOutcome'*/}
                {/*    />*/}
                {/*</FormDialog>*/}

                {/*<LinkDialog open={this.state.auditDialogOpen}*/}
                {/*    title={"Audit Trail"}*/}
                {/*    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}*/}
                {/*    dialogTitle={"Audit Trail"}*/}
                {/*    selectionId={this.props.location.state.selectedLearnership}*/}
                {/*>*/}

                {/*    <CoachMentorInterventionAudit dataUrl={"api/sims/CoachMentorLearnership/getCoachMentorLearnershipAudit"}*/}
                {/*        multiSelect={false} showTitle={false}*/}
                {/*        Id={this.state.selectedLearnership}*/}
                {/*    />*/}
                {/*</LinkDialog>*/}

                {/*<Prompt open={this.state.promptOpen}*/}
                {/*    promptTitle={this.state.promptTitle}*/}
                {/*    promptContent={this.state.promptContent}*/}
                {/*    handleYes={() => this.unlinkLearnership("api/sims/CoachMentorLearnership/RemoveCoachMentorLearnership")}*/}
                {/*    closePrompt={this.closePrompt}*/}
                {/*/>*/}

                <Dialog fullScreen open={this.state.OpenDialog} onClose={this.CloseForm} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.CloseForm} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h1" className={classes.title}>
                                {"QCTO Learnership Modules"}
                            </Typography>

                        </Toolbar >
                    </AppBar >
                    <br /><br />
                    <EnhancedTable
                        headCells={LUSheadCells}
                        dataUrl={"api/sims/CoachMentorLearnership/GetCoachMentorLearnershipUS"}
                        makeRowBold={true}
                        searchParameters={this.state.searchParameters2} paged={false} autoFetchData={true} />
                </Dialog >
            </ViewContainer>
        );
    }
}

export default withRouter(withStyles(useStyles())(connect()(CoachMentorLearnershipSearch)));

import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "../../View.css";
import "../../Grid.css";
import "../../Form.css";
import "../../App.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import { BursaryApplicationTypeEvaluationForm } from './BursaryApplicationTypeEvaluationForm';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "../../View.jsx";

const utils = new ViewUtils();

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'back', label: 'BACK', tooltip: 'Back to document upload', theme: true },
                { id: 'next', label: 'NEXT', tooltip: 'Proceed to add application details', theme: true }
            ]
        }
    ]
};

const formRef = React.createRef();
class BursaryApplicationTypeEvaluation extends Component {
    constructor(props) {
        super(props)
        let data = {};

        if (!utils.isNull(props.data) && !utils.isNull(props.data.bursaryApplicationType)) {
            if (!utils.isNull(props.data.bursaryApplicationType)) {
                data = props.data.bursaryApplicationType;
            }
            else {
                data = props.data.bursaryApplicationType;
            }
        } else if (!utils.isNull(props.data) && !utils.isNull(props.data.identityDetails)) {
            data = props.data.identityDetails.bursaryApplicationType ? props.data.identityDetails.bursaryApplicationType : props.data.identityDetails.formData
        } else {
            data.bursaryApplicationType = {};
        }

        this.state = {
            model: data,
            BursaryApplicationTypeID: null
        }
    }

    handleFormValueChange(values) {
        if (!utils.isNull(values.BursaryApplicationTypeID)) {
            let BursaryApplicationTypeID = values.BursaryApplicationTypeID.Id;
            this.setState({
                BursaryApplicationTypeID: BursaryApplicationTypeID
            });
        }

    }

    //handleToolbarButtonClick(buttonId) {
    //    let formValue = formRef.current.getValue();
    //    if ("back" === buttonId) {
    //        this.props.stepHandler(2, false, formValue);
    //    } else if ("next" === buttonId) {
    //        if (!utils.isNull(formValue)) {
    //            this.props.stepHandler(2, true, formValue);
    //        }
    //    }
    //}
    handleLowerToolbarButtonClick(buttonId) {
        if ("next" === buttonId) {
            this.props.history.push({
                pathname: '/BursaryApplicationEvaluationAll',
                state: {
                    selection: this.props.location.state.selection,
                    BursaryApplicationTypeID: this.state.BursaryApplicationTypeID
                }
            });
        } else if ("back" === buttonId) {
            this.props.history.push({
                pathname: '/BursaryApplicationEvaluation',
                state: {
                    selection: this.props.location.state.selection
                }
            });
        }
    }

    render() {
        return (
            <ViewContainer topPadding="0">
                <p className="legend">
                    Bursary Application Type Details
                </p>
                <div style={{ height: "50%", maxHeight: "42vh" }}>
                    <BursaryApplicationTypeEvaluationForm ref={formRef} id={this.props.location.state.selection} data={this.state.model} IsExternal={this.props.IsExternal} editPermission={true} valueChangeHandler={(values) => this.handleFormValueChange(values)} dataUrl={"api/sims/BursaryApplication/getBursaryApplicationTypeSearch"} />
                </div>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="90%">
                            </td>
                            <td width="20%" align="right">
                                <div style={{ width: "120px", marginTop: "16px" }}>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleLowerToolbarButtonClick(buttonId)} config={toolbarConfig} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(BursaryApplicationTypeEvaluation)
);

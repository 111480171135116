import React from 'react';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';



const utils = new ViewUtils();
export const LearnerEISAEnrolmentUpdateForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);

    function AchievementCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Competent") {
                return true
            }
            else if (value.Description !== "Competent") {
                return false
            }
        }
    }

    return (
        <EntityForm ref={ref} viewId="LearnerEISAEnrolmentUpdateForm"
            dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="LearnerEISAEnrolmentUpdateForm">
                    <table cellPadding="2" width="100%">
                        <tbody>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/sims/LearnerEISAEnrolment/GetLookupList?listId=EISAReadiness"
                                        id="EISAReadinessID"
                                        key={tabValue}
                                        label="Learner Readiness for EISA"
                                        className="w-100"
                                        form={form}
                                        InputProps={{
                                            readOnly: props.disableFields
                                        }}
                                        required
                                    />
                                </td>
                                <td>
                                    <SelectItem
                                        dataUrl="api/sims/LearnerEISAEnrolment/GetLookupList?listId=EISAAchievementValue"
                                        id="EISAAchievementValueID"
                                        key={tabValue}
                                        label="EISA Achievement Value"
                                        className="w-100"
                                        form={form}
                                        InputProps={{
                                            readOnly: props.disableFields
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomDatePicker
                                        id="DateAssessed"
                                        key={tabValue}
                                        label="Date Assessed"
                                        className='{classes.textField} w-100'
                                        form={form}
                                        required={AchievementCheck(form.values["EISAAchievementValueID"])}
                                    />
                                </td>
                                <td>
                                    <CustomTextField
                                        id="EISAPercentageObtained"
                                        parentId={0}
                                        key={tabValue}

                                        label="EISA Percentage Obtained"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                        required={AchievementCheck(form.values["EISAAchievementValueID"])}
                                        validationRegex={/^(?:[0-9]{1,2}|(?:100))$/g}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </form>
            }
        </EntityForm>
    );
})

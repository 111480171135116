export const AUTH_USER_START = 'AUTH_USER_START';
export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const AUTH_USER_FAIL = 'AUTH_USER_FAIL';
export const AUTH_USER_REDIRECTTOLOGIN = 'AUTH_USER_REDIRECTTOLOGIN';

export const AUTH_OTP_START = 'AUTH_OTP_START';
export const AUTH_OTP_SUCCESS = 'AUTH_OTP_SUCESS';
export const AUTH_OTP_FAIL = 'AUTH_OTP_FAIL';

export const AUTH_ROLEGET_START = 'AUTH_ROLEGET_START';
export const AUTH_ROLEGET_SUCCESS = 'AUTH_ROLEGET_SUCESS';
export const AUTH_ROLEGET_FAIL = 'AUTH_ROLEGET_FAIL';

export const AUTH_ROLESELECT_START = 'AUTH_ROLESELECT_START';
export const AUTH_ROLESELECT_SUCCESS = 'AUTH_ROLESELECT_SUCESS';
export const AUTH_ROLESELECT_FAIL = 'AUTH_ROLESELECT_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const AUTH_PROVIDERSELECT_START = 'AUTH_PROVIDERSELECT_START';
export const AUTH_PROVIDERSELECT_SUCCESS = 'AUTH_PROVIDERSELECT_SUCESS';
export const AUTH_PROVIDERSELECT_FAIL = 'AUTH_PROVIDERSELECT_FAIL';

export const AUTH_SKILLSDEVELOPMENTPROVIDERSELECT_START = 'AUTH_SKILLSDEVELOPMENTPROVIDERSELECT_START';
export const AUTH_SKILLSDEVELOPMENTPROVIDERSELECT_SUCCESS = 'AUTH_SKILLSDEVELOPMENTPROVIDERSELECT_SUCESS';
export const AUTH_SKILLSDEVELOPMENTPROVIDERSELECT_FAIL = 'AUTH_SKILLSDEVELOPMENTPROVIDERSELECT_FAIL';

export const AUTH_AGENTSELECT_START = 'AUTH_AGENTSELECT_START';
export const AUTH_AGENTSELECT_SUCCESS = 'AUTH_AGENTSELECT_SUCESS';
export const AUTH_AGENTSELECT_FAIL = 'AUTH_AGENTSELECT_FAIL';

export const AUTH_EMPLOYERSELECT_START = 'AUTH_EMPLOYERSELECT_START';
export const AUTH_EMPLOYERSELECT_SUCCESS = 'AUTH_EMPLOYERSELECT_SUCESS';
export const AUTH_EMPLOYERSELECT_FAIL = 'AUTH_EMPLOYERSELECT_FAIL';

export const AUTH_WORKPLACEAPPROVALSELECT_START = 'AUTH_WORKPLACEAPPROVALSELECT_START';
export const AUTH_WORKPLACEAPPROVALSELECT_SUCCESS = 'AUTH_WORKPLACEAPPROVALSELECT_SUCESS';
export const AUTH_WORKPLACEAPPROVALSELECT_FAIL = 'AUTH_WORKPLACEAPPROVALSELECT_FAIL';

export const AUTH_ASSESSMENTCENTRESELECT_START = 'AUTH_ASSESSMENTCENTRESELECT_START';
export const AUTH_ASSESSMENTCENTRESELECT_SUCCESS = 'AUTH_ASSESSMENTCENTRESELECT_SUCESS';
export const AUTH_ASSESSMENTCENTRESELECT_FAIL = 'AUTH_ASSESSMENTCENTRESELECT_FAIL';

export const AUTH_SWITCHPROFILE_START = 'AUTH_SWITCHPROFILE_START';
export const AUTH_SWITCHPROFILE_SUCCESS = 'AUTH_SWITCHPROFILE_SUCESS';
export const AUTH_SWITCHPROFILE_FAIL = 'AUTH_SWITCHPROFILE_FAIL';

export const AUTH_FORGOTPASSWORD_START = 'AUTH_FORGOTPASSWORD_START';
export const AUTH_FORGOTPASSWORD_SUCCESS = 'AUTH_FORGOTPASSWORD_SUCESS';
export const AUTH_FORGOTPASSWORD_FAIL = 'AUTH_FORGOTPASSWORD_FAIL';

export const AUTH_RESETPASSWORD_START = 'AUTH_RESETPASSWORD_START';
export const AUTH_RESETPASSWORD_SUCCESS = 'AUTH_RESETPASSWORD_SUCESS';
export const AUTH_RESETPASSWORD_FAIL = 'AUTH_RESETPASSWORD_FAIL';

export const AUTH_MENUPERMISSION_START = 'AUTH_MENUPERMISSION_START';
export const AUTH_MENUPERMISSION_SUCCESS = 'AUTH_MENUPERMISSION_SUCESS';
export const AUTH_MENUPERMISSION_FAIL = 'AUTH_MENUPERMISSION_FAIL';

export const INTERFACE_VERSION_CHECK = 'INTERFACE_VERSION_CHECK';
export const INTERFACE_VERSION_CHECK_UPDATE = 'INTERFACE_VERSION_CHECK_UPDATE';


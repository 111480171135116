import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import DocumentUpload from "../../widgets/DocumentUpload";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();


export const WSPATRImportForm = React.forwardRef((props, ref) => {
    const [dependency, setDependency] = React.useState(props.dependency);
    const [WSPATRfilename, setWSPATRFilename] = React.useState("");

    React.useEffect(() => {
        setDependency(props.dependency);
    }, [props.dependency]);

    function DocRequiredCheck (value){
        if (value !== undefined && value !== null && value !== "") {
            return false
        }
        else if (value === undefined || value === null || value === "") {
            return true
        }
    };

    const onUploadHandler = (data) => {
        if (data) {
            setWSPATRFilename(data.name);
        }
    };

    return (
        <EntityForm ref={ref}
            viewId="WSPATRImport"
            values={props.data}
            entityLookupId={props.id}
            entityLookupController={props.entityLookupController} org="saseta"
            useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="WSPATRImportForm">
                        <table cellPadding="2" width="80%">
                            <tbody>
                                <tr>
                                    <td>
                                        <DocumentUpload
                                            showViewer={false}
                                            onUploadHandler={onUploadHandler}
                                            buttonLabel="Upload WSPATR File"
                                            filename={WSPATRfilename}
                                            form={form}
                                            required={DocRequiredCheck(form.values["WSPATRImportDocumentName"])}
                                            id="Document"/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                </form>
            }
        </EntityForm>
    );
})

export default WSPATRImportForm;

import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "../Helpdesk.css";
import SearchView from "../../widgets/SearchView";
import FormDialog from "../../etqa/FormDialog";
import { ViewContainer } from "./../../View.jsx";
import HelpdeskSearchForm from './HelpdeskRequestSearchForm';
import { HelpdeskRequestAddForm } from "./HelpdeskRequestAddForm";
import ViewUtils from '../../ViewUtils';
import withMenuPermission from "../../widgets/withMenuPermission";
const utils = new ViewUtils();

const filterParameters = [

]
const headCells = [
    { id: 'ReferenceNumber', numeric: false, disablePadding: true, label: 'Reference Number' },
    { id: 'Title', numeric: false, disablePadding: true, label: 'Title' },
    { id: 'RequestType', numeric: false, disablePadding: true, label: 'Request Type' },
    { id: 'Module', numeric: false, disablePadding: true, label: 'Module' },
    { id: 'Priority', numeric: false, disablePadding: true, label: 'Priority' },
    { id: 'Status', numeric: false, disablePadding: true, label: 'Status' },
    { id: 'DateCreated', numeric: false, disablePadding: true, label: 'Date Created' },
    { id: 'DateUpdated', numeric: false, disablePadding: true, label: 'Date Updated' },
    { id: 'UpdatedBy', numeric: false, disablePadding: true, label: 'Updated By' }

];



const formRef = React.createRef();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class HelpdeskRequestSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                ReferenceNumber: "",
                RequestTypeID: "",
                StatusID: "",
                PriorityID: ""

            },
            keyUpSwitch: false,
            addDialogOpen: false,
            showTable: true,
            selectedRequest: null,
            Message: "",
            MessageStyle: "",
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'new', label: 'Add Request', tooltip: 'Add HelpDesk Request', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: 'Edit Request', tooltip: 'Edit HelpDesk Request', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: 'delete', label: 'Remove Request', tooltip: 'Remove HelpDesk Request record', icon: "DeleteIcon", disabled: true, visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        };

        this.searchParameters = [
            { Name: "ReferenceNumber", Value: "" },
            { Name: "RequestTypeID", Value: "" },
            { Name: "StatusID", Value: "" },
            { Name: "PriorityID", Value: "" }

        ]
    }


    handleToolbarButtonMenuClick = (menu) => {
        this.setState({ linkDialogOpen: true, selectedLinkType: menu });
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("new" === buttonId) {
            this.setState({ addDialogOpen: true, Message: "", selectedRequest: null })
        }
        else if ("edit" === buttonId) {
            this.props.history.push({
                pathname: '/HelpdeskRequest',
                state: { selection: this.state.selectedRequest }
            })
        }
    }

    handleFormValueChange = (values) => {
        this.setState({ model: values });
    }
    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedRequest: selection[0].Id, Message: "" })
        }
        else {
            this.setState({ selectedRequest: null, Message: "" })
        }
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    postAddHandler = (cancel, save) => {

        if (save === true) {
            this.setState({
                showTable: false,
                addDialogOpen: false,
                selectedRequest: null,
                Message: "Helpdesk Request Added Successfully",
                MessageStyle: "success"
            }, () => {
                this.setState({ showTable: true })
            });
        }
        else if (cancel === true) {
            this.setState({
                addDialogOpen: false,
                selectedRequest: null,
                Message: ""
            })
        }
        else {
            this.setState({
                addDialogOpen: false,
                selectedRequest: null,
                Message: "A system error has occured, please try again later or contact your administrator",
                MessageStyle: "warning"
            })
        }
    }

    render() {
        return (
            <ViewContainer title={""}>
                <p className="breadcrumbs">
                    {"Helpdesk > Requests"}
                </p>
                {this.state.showTable === true && (
                    <SearchView headCells={headCells}
                        dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/HelpDeskRequest/getsearch"}
                        model={this.state.model}
                        toolbarConfig={this.toolbarConfig}
                        searchParameters={this.searchParameters}
                        filterParameters={filterParameters}
                        onLookupValueChange={this.props.onLookupValueChange}
                        descriptionProp="HeaderA"
                        mode={this.props.mode}
                        history={this.props.history}
                        buttonClickHandler={this.handleToolbarButtonClick}
                        keyUpSwitch={this.state.keyUpSwitch}
                        deleteUrl={"api/sims/HelpDeskRequest/removerequest"}
                        useDeletePost={true}
                        promptTitle={"Remove Request"}
                        promptContent={"Are you sure you want to remove this Request ?"}
                        setSelection={(selection) => { this.handleSelection(selection) }}
                    >
                        <HelpdeskSearchForm model={this.state.model} onKeyUp={(e) => { this.onkeyup(e) }} ref={formRef} id={typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null}
                            valueChangeHandler={(values) => this.handleFormValueChange(values)}
                        />
                    </SearchView>
                )}
                <FormDialog
                    open={this.state.addDialogOpen}
                    dialogTitle={"Add HelpDesk Request"}
                    viewRef={this}
                    formRef={addFormRef}
                    saveUrl={"api/sims/HelpDeskRequest/AddHelpdeskRequest"}
                    usePost={true}
                    closeHandler={(cancel, save) => this.postAddHandler(cancel, save)}
                >
                    <HelpdeskRequestAddForm ref={addFormRef} />
                </FormDialog>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(HelpdeskRequestSearch));

import React, { useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from "../../../ViewUtils";
import { LookupField } from '../../../widgets/LookupField';
import EmployerSearch from '../../employer/EmployerSearch';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
export const AddEditAllocationForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function handleSelectionCallbackMode(value) {
        let objectReference = {};
        objectReference.Id = value[0].Id;
        objectReference.Description = value[0].Description;
        objectReference.toString = () => { return value[0].Description };
        return objectReference
    }

    function LookupValidation(value) {
        if (value !== undefined && value !== null) {
            return false
        }
        else {
            return true
        }
    }

    //useEffect(() => {

    //}, [])

    function GetIDFromValue(value) {
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }

    return (
        <EntityForm ref={ref} values={props.data} viewId="GrantAllocation" dataURL="api/sims/GrantAllocation/GetGrantAllocationDetails"
            entityLookupId={props.id} org="saseta" useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="addEditAllocatrionForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Grant Allocation Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/GrantAllocation/GetLookupListTrancheCode"
                                                id="TrancheCodeID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Tranche Code"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!utils.isNull(props.disableFields) ? props.disableFields : false}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/GrantAllocation/GetLookupList?listId=FinancialYear"
                                                id="FinancialYearID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Financial Year"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                filterOptions={option => {
                                                    return option.Id >= 24;
                                                }}
                                                disabled={!utils.isNull(props.disableFields) ? props.disableFields : false}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        
                                        <td>
                                            <LookupField
                                                id="EmployerID"
                                                label="Employer"
                                                className="filterContainer"
                                                form={form}
                                                required
                                                disabled={!utils.isNull(props.disableFields) ? props.disableFields : false}
                                            >
                                                <EmployerSearch showTitle={false} mode='lookup' dataUrl={"api/sims/grantallocation/EmployerSearch"}
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("EmployerID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="AllocationAmount"
                                                key={tabValue}
                                                label="Number of Learners Allocated"
                                                className="w-100 "
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                required
                                                form={form}
                                                validationRegex={/^((?!0)\d{1,18}|0|\.\d{1,2})($|\.$|\.\d{1,2}$)/g}
                                                validationMessage="Please enter a valid amount."
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})

import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import LinkDialog from "../LinkDialog";
import Prompt from '../../widgets/Prompt';
import AlertItem from "../../widgets/AlertItem";
import UnitStandardSetupSearch from '../program/UnitStandardSetupSearch';
import ViewUtils from '../../ViewUtils';
import { OutComeStatusForm } from '../coachmentor/OutComeStatusForm';
import FormDialog from "../FormDialog";
import CoachMentorInterventionAudit from '../coachmentor/CoachMentorInterventionAudit';
import withMenuPermission from "../../widgets/withMenuPermission";


const headCells = [
    { id: 'SAQAUnitStandardID', numeric: false, disablePadding: true, label: 'SAQA UnitStandard ID' },
    { id: 'MQAUnitStandardID', numeric: false, disablePadding: true, label: 'MQA UnitStandard ID' },
    { id: 'SAQAUnitStandardTitle', numeric: false, disablePadding: true, label: 'SAQA UnitStandard Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' },
    { id: 'UnitStandardRegistrationStartDate', numeric: false, disablePadding: true, label: 'Registration Start Date' },
    { id: 'UnitStandardRegistrationEndDate', numeric: false, disablePadding: true, label: 'Registration End Date' },
    { id: 'UnitStandardLastEnrolmentDate', numeric: false, disablePadding: true, label: 'Last Date For Enrolment' },
    { id: 'UnitStandardLastAchievementDate', numeric: false, disablePadding: true, label: 'Last Date For Achievement' },
    { id: 'Outcome', numeric: false, disablePadding: true, label: 'Outcome' },
    { id: 'Status', numeric: false, disablePadding: true, label: 'Status' }
];

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class UnitStandardSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                SAQAUnitStandardID: "",
                SAQAUnitStandardTitle: "",
                CoachMentorID: this.props.location.state.CoachMentorID
            },
            showTable: true,
            linkDialogOpen: false,
            auditDialogOpen: false,
            updateDialogOpen: false,
            selectedUnitStandard: null,
            propmtOpen: false,
            promptTitle: "Unlink Unit Standard From CoachMentor",
            promptContent: "Are you sure you want to unlink this unit standard from the coachmentor?",
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "CoachMentorID", Value: this.props.location.state.CoachMentorID }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'link', label: '', tooltip: 'Link Unit Standard/s to the coachmentor', icon: 'LinkIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'update', label: '', tooltip: 'Update the status and outcome of the selected unit standard', disabled: utils.isNull(this.state.selectedUnitStandard), icon: 'UpdateIcon', visible: this.props.menuPermissionItem.Edit },
                        { id: 'audit', label: '', tooltip: 'View Record Audit Trail', disabled: utils.isNull(this.state.selectedUnitStandard) , icon: 'AuditIcon' },
                        { id: 'unlink', label: '', tooltip: 'Un-link selected unit standard from the coachmentor', disabled: utils.isNull(this.state.selectedUnitStandard), icon: 'LinkOffIcon', visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/CoachMentor',
                state: { selection: this.props.location.state.CoachMentorID }
            });
        }
        else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, message: "" })
        }
        else if ("update" === buttonId) {
            this.setState({ updateDialogOpen: true, message: "" })
        }
        else if ("audit" === buttonId) {
            this.setState({ auditDialogOpen: true, message: "" })
        }
        else if ("unlink" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    successCallback = (response) => {
        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedUnitStandard: null,
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
            })
        }
        else if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    successUnlinkCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedUnitStandard: null, removeMessage: response.Message, removeMessageStyle: response.MessageType.toLowerCase() });
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unlinkUnitstandard(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("unlink", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedUnitStandard}&coachmentorId=${this.props.location.state.CoachMentorID}`;
        utils.invokeUrlDelete(fullUrl, this.successUnlinkCallback, this.unsuccessfulUnlinkCallback);
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false, auditDialogOpen: false, selectedUnitStandard: null
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false,
                auditDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({
                selectedUnitStandard: selection[0].Id,
                message: "",
                messageStyle: ""
            });
        }
        else {
            this.setState({
                selectedUnitStandard: null,
                message: "",
                messageStyle: ""
            });
        }
        toolbarRef.current.setButtonDisabled("unlink", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("update", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("audit", selection === null || selection.length === 0);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    unsuccessfulUnlinkCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ removeMessage: response.Message, removeMessageStyle: response.MessageType.toLowerCase() })
        }
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                updateDialogOpen: false, selectedSiteVisit: null, linkDialogOpen: false, auditDialogOpen: false , selection: this.props.location.state.selection, selectedUnitStandard: null
            }, () => {
                this.setState({ showTable: true })
            });
        }
        else if (isCancel === true) {
            this.setState({
                updateDialogOpen: false,
                linkDialogOpen: false,
                auditDialogOpen: false
            })
        }
    }

    render() {
        return (

            <ViewContainer title="">

                <p className="breadcrumbs">
                    {"CoachMentor > Unit Standards " + (this.props.location.state.CoachMentorName !== null ? "> " + this.props.location.state.CoachMentorName : "> Add")}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        ref={toolbarRef}
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />

                    {this.state.showTable === true && (

                    <EnhancedTable
                        headCells={headCells}
                        dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/CoachMentorUnitStandard/GetCoachMentorUnitStandards"} 
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}
                </div>

                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title={this.props.location.state.qualificationTitle}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={`Link Unit Standards to CoachMentor > ${this.props.location.state.CoachMentorName}`}
                    linkName={"UnitStandards"} postUrl={"api/sims/CoachMentorUnitStandard/linkCoachMentorUnitStandards"}
                    selectionId={this.props.location.state.CoachMentorID} usePost={true}
                >

                    <UnitStandardSetupSearch dataUrl="api/sims/CoachMentorUnitStandard/GetCoachMentorLinkableUnitStandards"
                        multiSelect={true} showTitle={false}
                        programmeId={this.props.location.state.CoachMentorID}
                        mode="lookup"
                    />
                </LinkDialog>

                <FormDialog
                    open={this.state.updateDialogOpen}
                    dialogTitle={"Update Outcome and Status"}
                    viewRef={this}
                    entityId={this.state.selectedUnitStandard}
                    formRef={addFormRef}
                    usePut={true}
                    saveUrl={"api/sims/CoachMentorUnitStandard/updateCoachMentorUnitStandard"}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                >
                    <OutComeStatusForm ref={addFormRef}
                        id={this.state.selectedUnitStandard}
                        controllerName={"CoachMentorUnitStandard"}
                        dataURL='api/sims/CoachMentorUnitStandard/GetFindUnitStandardOutcome'
                    />
                </FormDialog>

                <LinkDialog open={this.state.auditDialogOpen}
                    title={"Audit Trail"}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={"Audit Trail"}
                    selectionId={this.props.location.state.selectedUnitStandard}
                >

                    <CoachMentorInterventionAudit dataUrl={"api/sims/CoachMentorUnitStandard/getCoachMentorUSAudit"}
                        multiSelect={false} showTitle={false}
                        Id={this.state.selectedUnitStandard}
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => this.unlinkUnitstandard("api/sims/CoachMentorUnitStandard/RemoveCoachMentorUnitStandard")}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(connect()(withMenuPermission(UnitStandardSearch)));

import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import { LearnerMineCommunityForm } from '../../etqa/program/registration/LearnerMineCommunityForm';


const MineCommunityRef = React.createRef();
const utils = new ViewUtils();

class AddLearnerMineCommunity extends Component {


    constructor(props) {
        super(props);
        this.state = {
            model: {
                QualityAssuranceBodyID: { Id: 57, Description: "MQA" }
            },
            MineCommunityDetails: {
                SkillsProgramTitle: "",
                SkillsProgramCode: ""
            },
            messageStyle: "message",
            id: null,
            showTable: true,
            CurrentProgramme: this.props.location.state.LearnerMineCommunityID,
            CreateNewFlag: this.props.location.state.hideFields,
            Learner: this.props.location.state.LearnerID,
            NavPerm: this.props.location.state.permissions,
            IsExternalRole: false
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: '', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: '', tooltip: 'Save', icon: 'SaveIcon' },
                        { id: 'documents', label: 'DOCUMENTS', tooltip: 'Documents', disabled: (this.props.location.state.hideFields), visible: !(this.props.location.state.hideFields) }
                    ]
                }
            ]
        }
    };

    componentDidMount() {
        if (utils.isNull(this.props.location.state.LearnerMineCommunityID) || this.props.location.state.LearnerMineCommunityID === 0)
        {
            this.defaultSponsorship()
        }
    }

    handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {

            this.setState({
                MineCommunitySetupDetails: {
                    SkillsProgramCode: values.SkillsProgrammeID,
                    SkillsProgramTitle: values.SkillsProgramTitle
                },
                Learner: this.props.location.state.LearnerID
            });

            this.ECCheck(values);
        }
    }

    ECCheck(values) {
        if (!utils.isNull(values)) {
            if (!utils.isNull(values.LeadEmployerID)) {
                let url = "api/sims/learner/EmployerContactCheck?EmployerID=" + values.LeadEmployerID.Id;
                utils.invokeUrl(url, (response) => {
                    if (response.Result != "") {
                        this.setState({
                            message: response.Result,
                            messageStyle: "ERROR",
                        })
                    }
                },
                    null,
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        }
                    }
                )
            }
        }
    }


    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("documents" === buttonId) {
            this.props.history.push({
                pathname: '/learnerMineCommunityDocuments',
                state: {
                    LearnerID: this.props.location.state.LearnerID,
                    LearnerMineCommunityID: this.props.location.state.LearnerMineCommunityID,
                    hideFields: false,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.location.state.permissions
                }
            });
        }
    }

    save() {
        let formValue = MineCommunityRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            formValue.id = this.props.location.state.LearnerID;
            let input = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/learnerMineCommunity/LinkLearnerMineCommunity", (response) => {
                this.successCallback(response);
            }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: input
            });
        }
    }

    successCallback = (response) => {
        if (response.MessageType === "SUCCESS") {
            this.setState({
                showTable: false
            }, () => {
                this.setState({
                    showTable: true,
                    message: response.Message,
                    messageStyle: response.MessageType,
                    CurrentProgramme: response.EntityId,
                    CreateNewFlag: false
                })
            });
        }
        else {
            this.setState({
                message: response.Message, messageStyle: response.MessageType
            });
        }
    }

    defaultSponsorship = () => {
        if (utils.getCurrentRole() === "Provider" || utils.getCurrentRole() === "AET Agent" || utils.getCurrentRole() === "Agent") {
            this.setState({
                model: {
                    SponsorshipID: { Id: 2, Description: "Industry Funded" }
                },
                IsExternalRole: true
            })
        }
    }

    closeView() {

        this.props.history.push({
            pathname: '/LearnerMineCommunitySearch',
            state: {
                learnerID: this.props.location.state.LearnerID,
                learnerNames: this.props.location.state.learnerNames
            }
        });
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {
                        (this.props.location.state.hideFields) ?
                            `Learner > Link New Mine Community > ${this.props.location.state.learnerNames}`
                            :
                            `Learner > Update Mine Community > ${this.props.location.state.learnerNames}`
                    }
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    title=""
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    {this.state.showTable === true && (
                                        <LearnerMineCommunityForm ref={MineCommunityRef}
                                            viewRef={this}
                                            id={this.state.CurrentProgramme}
                                            editPermission={this.state.NavPerm}
                                            viewOnly={this.props.location.state.viewOnly}
                                            hideFields={this.state.CreateNewFlag}
                                            lid={this.props.location.state.LearnerID}
                                            valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                            model={this.state.model}
                                            data={this.state.model}
                                            IsExternalRole={this.state.IsExternalRole } />
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(AddLearnerMineCommunity);

import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import SearchView from "../../widgets/SearchView";
import ViewUtils from '../../ViewUtils';
import SkillsProgramSetupSearchForm from '../program/SkillsProgramSetupSearchForm';
import withMenuPermission from "../../widgets/withMenuPermission";
import AlertItem from "../../widgets/AlertItem";


const headCells = [
    { id: 'ProgrammeCode', numeric: false, disablePadding: true, label: 'Skills Programme Code' },
    { id: 'ProgrammeTitle', numeric: false, disablePadding: true, label: 'Skills Programme Title' },
    { id: 'StudentNumber', numeric: false, disablePadding: true, label: 'Student Number' },
    { id: 'CommencementDate', numeric: false, disablePadding: true, label: 'Commencement Date' },
    { id: 'CompletionDate', numeric: false, disablePadding: true, label: 'Completion Date' },
    { id: 'ContractNumber', numeric: false, disablePadding: true, label: 'Contract Number' },
    { id: 'ProgrammeStatus', numeric: false, disablePadding: true, label: 'Programme Status' },
    { id: 'CertificateNumber', numeric: false, disablePadding: true, label: 'Certificate Number' },
    { id: 'DateCertificateCreated', numeric: false, disablePadding: true, label: 'Date Certificate Created' }
];



const utils = new ViewUtils();
const toolbarRef = React.createRef();

class LearnerSkillsProgrammeAssessment extends Component {
    constructor(props) {
        super(props);

        this.searchParameters =  [
            { Name: "SkillsProgrammeCode", Value: "" },
            { Name: "SkillsProgrammeTitle", Value: "" },
            { Name: "LearnerID", Value: this.props.location.state.learnerID }
        ]

        let model = 
            {
                SkillsProgrammeCode: "",
                SkillsProgrammeTitle: "",
                LearnerID: this.props.location.state.learnerID

            }

        this.state = !utils.isNull(this.props.location.state.learnerID) ?
            {
                model: model,
                keyUpSwitch: false,
                toolbarConfig: {
                    items: [
                        {
                            type: "buttonGroup",
                            buttons: [
                                { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                            ]
                        }
                        ,
                        {
                            type: "devider"
                        },
                        {
                            type: "buttonGroup",
                            buttons: [
                                { id: 'edit', label: '', tooltip: 'View', icon: 'EditIcon', visible: this.props.menuPermissionItem.View }
                            ]
                        }
                    ]
                }
            }
            :
            {
                model: model,
                keyUpSwitch: false
            };

        //TODO: Add url for Candidacy removal
        this.deleteUrl = ""
    }


    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/learner',
                state: {
                    selection: this.props.location.state.learnerID
                }
            });
        }
        else if (buttonId === "edit") {
            this.props.history.push({
                pathname: '/ViewSkillsProgrammeAssessment',
                state: {
                    LearnerID: this.props.location.state.learnerID,
                    SkillsProgrammeID: this.state.selectedLearnerSkillsProgramme[0].Id,
                    learnerNames: this.props.location.state.learnerNames,
                    permissionItem: this.props.menuPermissionItem
                }
            })
        }


    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
        this.setState({ selectedLearnerSkillsProgramme: selection, message: "", messageStyle: "" })

        let url = "api/sims/LearnerSkillsProgrammeAssessment/CheckCanAssessProgramme?Id=" + selection[0].Id;
        utils.invokeUrl(url, (response) => {
            toolbarRef.current.setButtonDisabled("edit", response.Result !== "" ? true : false)
            this.setState({
                message: response.Result,
                messageStyle: "WARNING"
            })
        },
            (e) => {
                toolbarRef.current.setButtonDisabled("edit", true)
            },
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
        }
        else{
            this.setState({ selectedLearnerSkillsProgramme: null, message: "", messageStyle: "" })
            toolbarRef.current.setButtonDisabled("edit", true)
        }
    }


    onkeyup(e) {
        //console.log(e.charCode)
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {`Learner > Skills Programme Assessments > ${this.props.location.state.learnerNames}`}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <SearchView headCells={headCells} dataUrl={utils.isNull(this.props.dataUrl) ? "api/sims/LearnerSkillsProgrammeAssessment/getLearnerSkillsProgrammeAssessments" : this.props.dataUrl}
                    entityViewPath='/ViewSkillsProgrammeAssessment'
                    model={this.state.model} toolbarConfig={this.state.toolbarConfig}
                    toolbarRef={toolbarRef}
                    searchParameters={this.searchParameters} onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="SkillsProgrammeProgrammeDescription" mode={this.props.mode}
                    history={this.props.history} multiSelect={this.props.multiSelect}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                >
                    <SkillsProgramSetupSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(LearnerSkillsProgrammeAssessment));

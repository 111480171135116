import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import EnhancedTable from '../../widgets/ResponsiveTable';
import ViewUtils from '../../ViewUtils';
import { TradeNAMBHeaderForm } from './TradeNAMBHeaderForm';
import { TradeNAMBCaptureForm } from './TradeNAMBCaptureForm';
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";


const utils = new ViewUtils();
const toolbarRef = React.createRef();
const learnerRef = React.createRef();

class ViewTradeNAMB extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                LearnerArtisanID: null
            },
            keyUpSwitch: false,
            messsageAlert: null,
            messageStyle: null,
            showTable: true,
            searchParameters: [{ Name: "LearnerArtisanID", Value: this.props.location.state.TradeID }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'submit', label: '', tooltip: 'Submit NAMB Confirmation', icon: 'UpdateIcon', disabled: true, visible: this.props.menuPermissionItem.Edit }
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("submit" === buttonId) {
            this.updateModules();
        }
        else if (buttonId === "close") {
            this.props.history.push({
                pathname: '/ViewTradeTest',
                state: {
                    learnerID: this.props.location.state.LearnerID,
                    TradeID: this.props.location.state.TradeID,
                    learnerNames: this.props.location.state.learnerNames
                }
            })
        }
    }

    handleToolbarButtonClickClose = (buttonId) => {
        if ("close" === buttonId) {

        }
    }

    getData = () => {

        utils.invokeUrl("api/sims/LearnerTradeNAMB/GetNAMBDetails?ID=" + this.props.location.state.TradeID, (response) => {
            this.setState({
                model: response,
                message: "",
                messageStyle: ""
            });

        }, this.error);
        toolbarRef.current.setButtonDisabled("submit", false);
    }

    CheckCanCaptureNAMB = () => {
        let url = "api/sims/LearnerTradeNAMB/CheckCanCaptureNAMB?ID=" + this.props.location.state.TradeID;
        utils.invokeUrl(url, (response) => {
            if (response.Result === "1") {
                toolbarRef.current.setButtonDisabled("submit", false);
            }
            else {
                toolbarRef.current.setButtonDisabled("submit", true);
            }

        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    componentDidMount() {
        this.CheckCanCaptureNAMB();
    }

    successCallback = (response) => {
        this.setState({
            showTable: false,
            message: response.Message, messageStyle: response.MessageType
        }, () => {
            this.setState({ showTable: true, message: response.Message, messageStyle: response.MessageType })
        });
        this.getData();
        this.CheckCanCaptureNAMB();
    }

    updateModules() {
        let data = {
            ...this.state.model,
            LearnerArtisansID: this.props.location.state.TradeID
        }
        let input = JSON.stringify(data);

        utils.invokeUrl("api/sims/LearnerTradeNAMB/SubmitNAMBDetails", (response) => {
            this.successCallback(response);
        }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: input
        });
    }

    handleFormValueChange = (values) => {
        let newValues = null;
        if (!utils.isNull(values)) {
            newValues = { ...values };
        }
        if (!utils.isNull(newValues)) {
            if (JSON.stringify(this.state.model) !== JSON.stringify(newValues)) {
                console.clear();
                console.log(newValues);
                console.log(this.state.model);
                this.setState({
                    model: newValues,
                    Message: ""
                });
            }
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {`Learner > NAMB Confirmation > ${this.props.location.state.learnerNames}`}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                    <TradeNAMBHeaderForm
                        ref={learnerRef}
                        viewRef={this}
                        id={this.props.location.state.TradeID}
                        controllerName={"LearnerTradeNAMB"}
                        dataURL='api/sims/LearnerTradeNAMB/GetLearnerTradeHeaderDetails' />

                {this.state.showTable === true && (
                    <TradeNAMBCaptureForm
                        ref={learnerRef}
                        viewRef={this}
                        id={this.props.location.state.TradeID}
                        valueChangeHandler={(values) => this.handleFormValueChange(values)}
                        controllerName={"LearnerTradeNAMB"}
                        data={this.state.model}
                        dataURL='api/sims/LearnerTradeNAMB/GetNAMBDetails' />
                )}
                <td>
                    <EnhancedToolbar
                        ref={toolbarRef}
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />
                </td>
            </ViewContainer>
        );
    }
}

export default withRouter(connect()(withMenuPermission(ViewTradeNAMB)));
import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Learner.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import { LearnerForm } from './LearnerForm';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";

const utils = new ViewUtils();
const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'back', label: 'BACK', tooltip: 'Back to Documents', theme: true },
                { id: 'next', label: 'NEXT', tooltip: 'Proceed to Programs', theme: true }
            ]
        }
    ]
};

const formRef = React.createRef();
class AddLearnerDetails extends Component {

    state = {
        model: {}
    };

    handleToolbarButtonClick(buttonId) {
        if ("back" === buttonId) {
            this.props.stepHandler(1, false, this.state.model);
        } else if ("next" === buttonId) {
            let formValue = formRef.current.getValue();
            if (!utils.isNull(formValue)) {
                this.props.stepHandler(1, true, formValue);
            }
        }
    }

    onChangePersonIDHandler = (form, value, event) => {
        let newModel = {};
        console.log(this.props.data);
        if (!utils.isNull(value)) {
            let url = "api/sims/LearnerDetails/GetFindPersonParentDetails?id=" + value.Id;
            utils.invokeUrl(url, (response) => {
                newModel.ParentPersonFirstName = response.FirstName;
                newModel.ParentPersonMiddleName = response.MiddleName;
                newModel.ParentPersonSurname = response.Surname;
                newModel.ParentPersonInitials = response.Initials;
                newModel.ParentPersonTitleID = response.TitleID;
                newModel.ParentPersonIDNo = response.IDNo;
                newModel.ParentPersonAlternateIDTypeID = response.AlternateIDTypeID;
                newModel.ParentPersonDateofBirth = response.DateofBirth;
                newModel.ParentPersonGenderID = response.GenderID;
                newModel.ParentPersonEquityID = response.EquityID;
                newModel.ParentPersonSocioEconomicStatusID = response.SocioEconomicStatusID;
                newModel.ParentPersonHomeLanguageID = response.HomeLanguageID;
                newModel.ParentPersonNationalityID = response.NationalityID
                newModel.ParentPersonCitizenResidentialStatusID = response.CitizenResidentialStatusID;
                newModel.ParentPersonTelephoneNumber = response.TelephoneNumber;
                newModel.ParentPersonCellPhoneNumber = response.CellPhoneNumber;
                newModel.ParentPersonFaxNumber = response.FaxNumber;
                newModel.ParentPersonEMail = response.EMail;
                newModel.ParentPersonPhysicalAddress1 = response.PhysicalAddress1;
                newModel.ParentPersonPhysicalAddress2 = response.PhysicalAddress2;
                newModel.ParentPersonPhysicalAddress3 = response.PhysicalAddress3;
                newModel.ParentPersonPhysicalCode = response.PhysicalCode;
                newModel.ParentPersonPhysicalMunicipalityID = response.PhysicalMunicipalityID;
                newModel.ParentPersonPhysicalDistrictID = response.PhysicalDistrictID;
                newModel.ParentPersonPhysicalUrbanRuralID = response.PhysicalUrbanRuralID;
                newModel.ParentPersonPhysicalLocation = response.PhysicalLocation;
                newModel.ParentPersonPostalAddressLine1 = response.PostalAddressLine1;
                newModel.ParentPersonPostalAddressLine2 = response.PostalAddressLine2;
                newModel.ParentPersonPostalAddressLine3 = response.PostalAddressLine3;
                newModel.ParentPersonPostalCode = response.PostalCode;
                newModel.ParentPersonPostalMunicipalityID = response.PostalMunicipalityID;
                newModel.ParentPersonPostalDistrictID = response.PostalDistrictID;
                newModel.ParentPersonPostalUrbanRuralID = response.PostalUrbanRuralID;
                newModel.ParentPersonPostalLocation = response.PostalLocation;

                this.setState({ model: newModel, populateAddressDetails: true })
                this.props.UpdatePersonDetails(newModel);
            },
                null,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    }
                }
            )
        }
    }

    onChangePopulateAddressDetails = () => {
        this.setState({ populateAddressDetails: false })
    }

    handleFormValueChange(values) {
        this.setState({model: values})
    }

    componentDidMount() {
    }

    render() {
        return (
            <ViewContainer topPadding="0">
                <p className="breadcrumbs">
                    Learner Details
                </p>
                <div style={{height: "65%", maxHeight: "55vh"}}>
                    <LearnerForm ref={formRef} data={this.props.data} onChangePersonIDHandler={this.onChangePersonIDHandler} editPermission={this.props.permissionItem.Edit} valueChangeHandler={(values) => this.handleFormValueChange(values)}
                        populateAddressDetails={this.state.populateAddressDetails}
                        onChangePopulateAddressDetails={this.onChangePopulateAddressDetails} />
                </div>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="90%">
                            </td>
                            <td width="20%" align="right">
                                <div style={{ width: "120px", marginTop: "16px" }}>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} config={toolbarConfig} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(AddLearnerDetails)
);

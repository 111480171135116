import React from 'react';
import { CustomTextField } from '../../widgets/CustomTextField';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import TabPanel from '../../widgets/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { SelectItem } from '../../widgets/SelectItem';
import OrganisationSearch from './OrganisationSearch';
import { LookupField } from '../../widgets/LookupField';
import DocumentUpload from '../../widgets/DocumentUpload';


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();

export const LinkOrganisationForm = React.forwardRef((props, ref) => {

    const [filename, setFilename] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);


    const onUploadHandler = (data) => {
        if (data) {
            setFilename(data.name);
        }
    };

    return (
        <EntityForm ref={ref} viewId="organisation" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}
            dataArrivedHandler={props.dataArrivedHandler}
            dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
        >
            {form =>
                <form className={form.classes.container} id="union">
                    <div className="row w-100">
                        <div className="col w-100">
                            <table cellPadding="2" className="w-100">
                                <tbody>

                                    {!props.endlink ?
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/skillslinkedorganisations/GetLookupList?listId=FinancialYear"
                                                id="FinancialYearStartID"
                                                label="Link Start (Financial Year)"
                                                className="w-100"
                                                form={form}
                                                required={!props.edit}
                                                disabled={props.edit}
                                                parentId={0}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/skillslinkedorganisations/GetLookupList?listId=FinancialYearLinkEnd"
                                                id="FinancialYearEndID"
                                                label="Link End (Financial Year) if applicable"
                                                className="w-100"
                                                form={form}
                                                parentId={0}
                                                required={props.edit}
                                            />
                                        </td>
                                    </tr>
                                    : null
                                    }
                                    <tr>
                                        {!props.endlink ?
                                        <td>
                                            <LookupField
                                                id="ChildOrganisationID"
                                                label="Child Organisation"
                                                className="w-100"
                                                form={form}
                                                required={!props.edit}
                                                disabled={props.edit}
                                                parentId={0}
                                            >
                                                <OrganisationSearch showTitle={true} mode='lookup'
                                                    dataUrl="api/sims/skillslinkedorganisations/GetSearchLinkableOrganisations"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("ChildOrganisationID", value)
                                                    }} autoFetchData={false} selection={props.selection} OrganisationID={props.ParentOrganisationId} isLinkSearch={true} />
                                            </LookupField>
                                        </td>

                                            : null
                                        }
                                        <td>

                                            <DocumentUpload
                                                showViewer={false}
                                                onUploadHandler={onUploadHandler}
                                                buttonLabel="Upload Linking Request"
                                                filename={filename}
                                                form={form}
                                                id="Document"
                                                disabled={props.edit}
                                                parentId={0}
                                            />
                                        </td>
                                    </tr>





                                </tbody>
                            </table>
                        </div>
                    </div>
                </form >
            }
        </EntityForm >
    );
})
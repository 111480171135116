import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import SearchView from "../../widgets/SearchView";
import AssessorDocumentSearchForm from './AssessorDocumentSearchForm';
import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";

const headCells = [
    { id: "AssessorName", numeric: false, disablePadding: true, label: 'Assessor Name' },
    { id: "AssessorSurname", numeric: false, disablePadding: true, label: "Assessor Surname" },
    { id: "IDNo", numeric: false, disablePadding: true, label: "Assessor ID Number" },
    { id: "AssessorRegistrationNumber", numeric: false, disablePadding: true, label: "Assessor Registration Number" }
]

const searchParameters = [
    //{ Name: "DocumentType", Value: "" },
    //{ Name: "PrintType", Value: "" },
    //{ Name: "Provider", Value: "" },
    { Name: "AssessorID", Value: "" }
]

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const searchFormRef = React.createRef();

class AssessorDocuments extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                //DocumentType: null,
                //PrintType: null,
                //Provider: {Description: "", Id: null},
                AssessorID: null
            },
            selectedAssessors: null,
            keyUpSwitch: false,
            clearGrid: false,
            documentsGenerated: false,
            messsageAlert: null,
            messageStyle: null
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        {id: 'generate', label: 'Generate', icon: 'CreateIcon', tooltip: 'Generate letters / certificates',disabled: utils.isNull(this.state.selectedAssessors)}
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("generate" === buttonId) {
            this.generateDocuments();
            toolbarRef.current.setButtonDisabled("generate", true);
        }
    }

    FileDownload = (documentName) => {

        utils.invokeUrl("api/sims/assessorletters/DownloadAssessorLetters?FileName=" + documentName, (response) => {

            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
            toolbarRef.current.setButtonDisabled("download", true);
        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );

    };

    generateDocuments = () => {
        const AssessorIDs = this.state.selectedAssessors.map(e => e.Id);
        let data = {
            AssessorIDs: AssessorIDs,
            ...this.state.model
        }
        let url = "api/sims/assessorletters/GenerateAssessorLetters";
        utils.invokeUrl(url, (response) => {
            utils.showMessage(this, response.Message, response.MessageType);
            let documentInfo = JSON.parse(response.Message);
            this.FileDownload(documentInfo.OriginalFileName);
            toolbarRef.current.setButtonDisabled("download", !this.state.documentGenerated);
        },
            null,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        )
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };


    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelectionChange = (selection) => {
        this.setState({ selectedAssessors: selection, Message: "" },
            () => {
                toolbarRef.current.setButtonDisabled("generate", selection.length === 0);
            }
        )
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    handleFormValueChange = (values) => {
        let newValues = null;
        if (!utils.isNull(values)) {
            newValues = { ...values };
        }
        if (!utils.isNull(newValues)) {
            if (JSON.stringify(this.state.model) !== JSON.stringify(newValues)) {
                this.setState({
                    model: newValues,
                    clearGrid: true,
                    selectedAssessors: null,
                    Message: ""
                });
                toolbarRef.current.setButtonDisabled("generate", true);
            }
        }
    }

    onGridCleared = () => {
        this.setState({ clearGrid: false });
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <AlertItem
                    message={this.state.messsageAlert}
                    alertStyle={this.state.messageStyle}
                />
                <SearchView
                    headCells={headCells}
                    dataUrl="api/sims/assessorletters/getsearch"
                    model={this.state.model}
                    toolbarConfig={this.toolbarConfig}
                    searchParameters={searchParameters}
                    searchLabel="SEARCH"
                    mode={this.props.mode}
                    multiSelect={true}
                    onLookupValueChange={this.props.onLookupValueChange}
                    history={this.props.history}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelectionChange(selection) }}
                    autoFetchData={false}
                    toolbarRef={toolbarRef}
                    clearGrid={this.state.clearGrid}
                    onGridCleared={this.onGridCleared}
                    title={"Assessor Letters"}
                >
                    <AssessorDocumentSearchForm
                        ref={searchFormRef}
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        model={this.state.model}
                        valueChangeHandler={this.handleFormValueChange}
                        dependency={this.state.dependency}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(AssessorDocuments)
);
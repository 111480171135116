import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { SelectItem } from '../../widgets/SelectItem';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
export const UserRoleForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="userRole" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <table cellPadding="2" width="80%">
                <tbody>
                    <tr>
                        <td> 
                            <SelectItem
                             dataUrl={'api/sims/userRole/GetLookupListUserRoles?parameter=[{"Name":"UserID","Value":' + props.UserID + '}]'}
                             id="RoleID"
                             key={tabValue}
                             label="Role"
                             className="w-100"
                             form={form}
                             required
                             />
                        </td>
                    </tr>
                </tbody>
            </table>
            }
        </EntityForm>
    );
})


import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../../widgets/Toolbar';
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import "../LearnershipSetup.css";
import { QCTOLearnershipSetupForm } from './QCTOLearnershipSetupForm';
import ViewUtils from "./../../../ViewUtils";
import { ViewContainer } from "./../../../View.jsx";
import AlertItem from "../../../widgets/AlertItem";
import withMenuPermission from "../../../widgets/withMenuPermission";


const leanershipRef = React.createRef();
const toolbarRef = React.createRef();
const utils = new ViewUtils();

class QCTOLearnershipSetup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            learnershipSetupDetails: {
                LearnershipCode: "",
                LearnershipTitle: ""
            },
            model: {
                QualificationID: null,
                OFOOccupationID: null
            },
            initialLearnershipType: null,
            title: "",
            showTable: true,
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close' },
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.menuPermissionItem.Edit }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'modules', label: 'MODULES', tooltip: 'Link QCTO Modules', disabled: utils.isNull(this.state.id) },
                        { id: 'documents', label: 'DOCUMENTS', tooltip: 'QCTO Learnership Documents', disabled: utils.isNull(this.state.id) },
                        { id: 'agreements', label: 'AGREEMENTS', tooltip: 'QCTO Learnership Agreements', disabled: utils.isNull(this.state.id) }
                    ]
                }
            ]
        }
    }

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {

            if (this.state.initialLearnershipType === null && !utils.isNull(values.LearnershipTypeID)) {
                this.setState({ initialLearnershipType: values.LearnershipTypeID.Id })
            }

            this.setState({ learnershipSetupDetails: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("modules" === buttonId) {
            this.props.history.push({
                pathname: '/qctoLearnershipModuleSearch',
                state: {
                    LearnershipID: this.props.location.state.selection,
                    LearnershipTitle: this.state.learnershipSetupDetails.LearnershipTitle,
                    LearnershipType: this.state.initialLearnershipType
                }
            });
        } else if ("documents" === buttonId) {
            this.props.history.push({
                pathname: '/qctoLearnershipdocuments',
                state: {
                    LearnershipID: this.props.location.state.selection
                }
            });
        } else if ("agreements" === buttonId) {
            this.props.history.push({
                pathname: '/qctoLearnershipagreements',
                state: {
                    LearnershipID: this.props.location.state.selection
                }
            });
        }
    }

    successCallback = (response) => {
        //When it's a newly created learnership
        if (!utils.isNull(response.EntityId) && response.EntityId !== 0) {
            this.setState({ id: response.EntityId }, () => {
                this.props.history.push({
                    pathname: "/qctoLearnershipSetup",
                    state: { selection: this.state.id, initialLearnershipType: this.state.initialLearnershipType }
                })
                toolbarRef.current.setButtonDisabled("unitStandards", this.state.id === null || response.MessageType === "ERROR")
            })
        }
        //When it's an update of an already existing learnership
        else if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ showTable: false, initialLearnershipType: !utils.isNull(response.LearnershipType) && response.LearnershipType !== "" ? parseInt(response.LearnershipType) : null }, () => {
                this.setState({ showTable: true, message: response.Message, messageStyle: response.MessageType })
            });
        }
    }

    save() {
        let formValue = leanershipRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            let isAdd = true;
            if (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) {
                formValue.id = this.props.location.state.selection;
                isAdd = false;
            }
            formValue.Document = this.state.Document;
            const data = JSON.stringify(formValue);
            let url = "api/sims/QCTOlearnershipsetupdetails/updatelearnership";
            if (!isAdd) {
                utils.invokeUrl(url, (response) => {
                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response)
                },
                    null,
                    {
                        method: 'PUT',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        },
                        body: data
                    }
                )
            } else {
                url = "api/sims/QCTOlearnershipsetupdetails/insertlearnership";
                utils.invokeUrl(url, (response) => {
                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response)
                },
                    null,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        },
                        body: data
                    }
                )
            }
        }
    }

    closeView() {
        this.props.history.push('/qctoLearnershipSetupSearch');
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"QCTO Learnership Details"}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar
                                    ref={toolbarRef}
                                    highlight={false}
                                    title="Employer"
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    enabled={true} config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    {this.state.showTable === true && (
                                        <QCTOLearnershipSetupForm ref={leanershipRef} data={this.state.model}
                                            id={
                                                this.state.id !== null ? this.state.id :
                                                    typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null}
                                            valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                            editPermission={this.props.menuPermissionItem.Edit} />
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(QCTOLearnershipSetup));

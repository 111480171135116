import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { LookupField } from '../../../widgets/LookupField';
import EmployerSearch from '../../employer/EmployerSearch';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../../widgets/Forms';
import LearnerNonNQFQualificationSearch from '../../program/LearnerNonNQFQualificationSearch';
import ViewUtils from "../../../ViewUtils";
import { AddressCodeItem } from '../../../widgets/AddressCodeItem';
import { GPSCoordinatesAPI } from '../../../widgets/GPSCoordinatesAPI';
import CandidacySetupSearch from '../../program/Candidacy/CandidacySetupSearch';


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const utils = new ViewUtils();
export const LearnerCandidacyForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [physicalCodeValue, setPhysicalCodeValue] = React.useState(null);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    //const handleReplacementChange = form => event => {
    //    if (!event.target.checked) {
    //    }

    //    form.updateValue('IsVerified', event.target.checked);
    //};

    function TerminationCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Terminated") {
                return true
            }
            else if (value.Description !== "Terminated") {
                return false
            }
        }
    }

    const updateGPSAddress = (address, form) => {
        form.values["PhysicalAddress1"] = address.streetnumberpar + ' ' + address.routepar;
        form.values["PhysicalAddress2"] = address.sublocalitylevel1par;
        form.values["PhysicalAddress3"] = address.localitypar;
        form.values["PhysicalCode"] = address.postalcodepar;
        setPhysicalCodeValue(form.values["PhysicalCode"]);
    }


    function SponsorCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "SETA Funded") {
                return true
            }
            else if (value.Description !== "Industry Funded") {
                return false
            }
        }
    }

    function terminationReasonCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Other") {
                return true
            }
            else if (value.Description !== "Other") {
                return false
            }
        }
    }

    function GetIDFromValue(value) {
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }

    const handleReplacementChange = form => event => {
        if (!event.target.checked) {
        }

        form.updateValue('IsVerified', event.target.checked);
    };



    return (
        <EntityForm ref={ref} values={props.data} viewId="CandidacySetup" dataURL="api/sims/learnercandidacy/GetLearnerLinkedCandidacyDetails"
            entityLookupId={props.id} org="saseta" useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="learnerCandidacyForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Candidacy Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <LookupField
                                                required={true}
                                                id="CandidacyID"
                                                label="Candidacy Programme"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <CandidacySetupSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerCandidacy/CandidacySearch"
                                                    onLookupValueChange={(value) => form.setLookupSelection("CandidacyID", value)} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="CommencementDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Commencement Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                onChange={props.dateChange}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="CompletionDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="Completion Date"
                                                disabled
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <LookupField
                                                id="NonNQFQualificationID"
                                                label="Qualification"
                                                className="w-100"
                                                form={form}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                            >
                                                <LearnerNonNQFQualificationSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnercandidacy/NonNQFQualificationSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("NonNQFQualificationID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <LookupField
                                                id="EmployerID"
                                                label="Employer"
                                                className="w-100"
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form} 
                                            >
                                                <EmployerSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnercandidacy/EmployerSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("EmployerID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnercandidacy/GetLookupList?listId=YesNo"
                                                id="EmployedID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Were you employed by your employer before concluding this agreement?"
                                                className="{classes.select} w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnercandidacy/GetLookupList?listId=YesNo"
                                                id="WorkplaceAgreementID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Were you party to a workplace-based learning programme agreement at any time in the past before concluding this agreement?"
                                                className="{classes.select} w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="StatusEffectiveDate"
                                                key={tabValue}
                                                disabled={true}
                                                label="Status Effective Date"
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                                <td >
                                                    <SelectItem
                                                        dataUrl={'api/sims/learnercandidacy/GetCandidacyStatusByRole?parameter=[{"Name":"CandidacyStatusID","Value":' + GetIDFromValue(form.values["CandidacyStatusID"]) + '}]'}
                                                        id="CandidacyStatusID"
                                                    parentId={0}
                                                    disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        required
                                                        key={tabValue}
                                                        label="Candidacy Status"
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnercandidacy/GetLookupList?listId=GCCType"
                                                id="GCCTypeID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="GCC Type"
                                                className="{classes.select} w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="CaptureDate"
                                                        key={tabValue}
                                                        label="Capture Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="CaptureByUser"
                                                        key={tabValue}
                                                        label="Capture By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="LastDateUpdated"
                                                        key={tabValue}
                                                        label="Last Date Updated"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="LastUpdatedByUser"
                                                        key={tabValue}
                                                        label="Last Updated By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})

import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import ViewUtils from "./../../ViewUtils";
import { ViewContainer } from "./../../View.jsx";
import Prompt from '../../widgets/Prompt';
import AlertItem from "../../widgets/AlertItem";
import EnhancedTable from '../../widgets/ResponsiveTable';
import withMenuPermission from "../../widgets/withMenuPermission";

const utils = new ViewUtils();

const headCells = [
    { id: 'SDLNumber', numeric: false, disablePadding: true, label: 'SDL Number' },
    { id: 'LegalName', numeric: false, disablePadding: true, label: 'Legal Name' },
    { id: 'TradeName', numeric: false, disablePadding: true, label: 'Trade Name' },
];

const toolbarRef = React.createRef();
class LearnerSDProviderSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedDocument: null,
            searchParameters: [{ Name: "LearnerID", Value: props.location.state.LearnerID }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
            ]
        };
    }


    handleToolbarButtonClick(buttonId) {
        if (buttonId === "add") {
            this.setState({ addDialogOpen: true });

        } else if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/learner',
                state: {
                    selection: this.props.location.state.LearnerID
                }
            });
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({
                selectedDocument: selection[0].Id,
                message: "",
                messageStyle: ""
            })
        }
        else {
            this.setState({
                selectedDocument: null,
                message: "",
                messageStyle: ""
            })
        }
    }

    render() {
        return (
            <ViewContainer>
                <p className="breadcrumbs">
                    {"Learner > Skills Development Provider " + (this.props.location.state.LearnerName !== null ? "> " + this.props.location.state.LearnerName : "> Add")}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <div className="searchResults">
                    <EnhancedToolbar highlight={false} ref={toolbarRef}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)}
                        config={this.toolbarConfig} />
                    <EnhancedTable headCells={headCells} onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        dataUrl="api/sims/LearnerSDProvider/GetSearch"
                        searchParameters={this.state.searchParameters}
                        onCellSelectionChange={(rowid, headCell) => { this.FileDownload(rowid, headCell) }}
                        paged={true} />
                </div>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(LearnerSDProviderSearch));
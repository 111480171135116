import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";
import Prompt from '../../widgets/Prompt';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import FormDialog from "../FormDialog";
import { LearnerForm } from "../learner/LearnerForm";
import UserSearch from "../UserSearch";
import LinkDialog from "../LinkDialog";
import withMenuPermission from "../../widgets/withMenuPermission";

const headCells = [
    { id: 'FirstName', numeric: false, disablePadding: true, label: 'First Name' },
    { id: 'Surname', numeric: false, disablePadding: true, label: 'Surname' },
    { id: 'IDNo', numeric: false, disablePadding: true, label: 'ID Number' },
];

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class WorkplaceApprovalLearner extends Component {

    constructor(props) {
        super(props)
        this.state = {
            linkDialogOpen: false,
            formDialogOpen: false,
            selectedLearner: null,
            propmtOpen: false,
            promptTitle: "Remove Learner from Workplace Approval",
            promptContent: "Are you sure you want to remove this learner from the Workplace Approval?",
            removeMessage: "",
            removeMessageStyle: "",
            searchParameters: [{ Name: "WorkplaceApprovalID", Value: this.props.location.state.selection }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon', }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: "link", label: '', icon: 'LinkIcon', tooltip: "Link existing learner to Workplace Approval", visible: this.props.menuPermissionItem.Add },
                        {
                            id: 'view', label: '', icon: 'ViewIcon', tooltip: 'View Selected Learner Details',
                            disabled: utils.isNull(this.state.selectedLearner)
                        },
                        {
                            id: 'change', label: '', icon: 'UpdateIcon', tooltip: 'Update Selected Learner Details',
                            disabled: utils.isNull(this.state.selectedLearner), visible: this.props.menuPermissionItem.Edit
                        },
                        {
                            id: 'remove', label: '', icon: 'DeleteIcon', tooltip: 'Remove learner from Workplace Approval',
                            disabled: utils.isNull(this.state.selectedLearner), visible: this.props.menuPermissionItem.Delete
                        }
                    ]
                }
            ]
        };

    }
    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/WorkplaceApproval',
                state: { selection: this.props.location.state.selection }
            });
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
        else if ("view" === buttonId) {
            this.setState({ removeMessage: "" }, this.viewLearner());
        }
        else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, removeMessage: "" })

        }
        else if ("change" === buttonId) {
            this.props.history.push({
                pathname: '/learner',
                state: {
                    selection: this.state.selectedLearnerID
                }
            });
        }
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                formDialogOpen: false, selectedSiteVisit: null, linkDialogOpen: false
            });
        }
        else if (isCancel === true) {
            this.setState({
                formDialogOpen: false,
                linkDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedLearner: selection[0].Id, removeMessage: "", selectedLearnerID: selection[0].LearnerID });
        }
        else {
            this.setState({ selectedLearner: null, removeMessage: "", selectedLearnerID: null });
        }
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("view", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("change", selection === null || selection.length === 0);
    }

    successfulRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedLearner: null, removeMessage: response.Message,
                removeMessageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                removeMessage: response.Message,
                removeMessageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    removeLearner(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedLearner}&WorkplaceApprovalId=${this.props.location.state.selection}`;
        utils.invokeUrlDelete(fullUrl, this.successfulRemoveCallback, this.unsuccessfulRemoveCallback);
    }

    viewLearner = () => {
        this.setState({ formDialogOpen: true })
    }


    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">

                <p className="breadcrumbs">
                    {"Workplace Approval > Learners " + (this.props.location.state.WorkplaceApprovalName !== null ? "> " + this.props.location.state.WorkplaceApprovalName + (this.props.location.state.WorkplaceApprovalSDLNumber !== null ? " - " + this.props.location.state.WorkplaceApprovalSDLNumber : "") : "> Add")}
                </p>

                <AlertItem message={this.state.removeMessage} alertStyle={this.state.removeMessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    <EnhancedTable
                        headCells={headCells}
                        dataUrl={`api/sims/WorkplaceApprovallearner/GetWorkplaceApprovalLearners`}
                        height="60vh"
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        searchParameters={this.state.searchParameters} paged={true} />
                </div>

                <FormDialog
                    open={this.state.formDialogOpen}
                    dialogTitle={"View Workplace Approval Leaner Details"}
                    viewRef={this}
                    formRef={addFormRef}
                    isView={this.props.menuPermissionItem.Edit == 1 ? false : true}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                >
                    <LearnerForm
                        ref={addFormRef}
                        id={this.state.selectedLearnerID}
                        disableFields={true}
                    />
                </FormDialog>

                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    dialogTitle={`Link Learners to Workplace Approval > ${this.props.location.state.WorkplaceApprovalName} - ${this.props.location.state.WorkplaceApprovalSDLNumber}`}
                    linkName={"learners"} postUrl={"api/sims/WorkplaceApprovallearner/LinkWorkplaceApprovalLearner"} usePost={true}
                    selectionId={this.props.location.state.selection}
                >
                    <UserSearch
                        dataUrl={"api/sims/WorkplaceApprovallearner/GetSearchLinkableLearners"}
                        multiSelect={true} showTitle={false}
                        mode="lookup"
                        programmeId={this.props.location.state.selection} programmeType="Q"
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeLearner("api/sims/WorkplaceApprovallearner/RemoveWorkplaceApprovalLearner") }}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(connect()(withMenuPermission(WorkplaceApprovalLearner)));

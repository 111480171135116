import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import GrantReversalsSearchForm from './GrantReversalsSearchForm';
import SearchView from "../../../widgets/SearchView";
import withMenuPermission from "../../../widgets/withMenuPermission";
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from "../../../ViewUtils";
import AlertItem from "../../../widgets/AlertItem";

const utils = new ViewUtils();
const toolbarRef = React.createRef();

const headCells = [
    { id: 'LearnerName', numeric: false, disablePadding: false, label: 'Learner Name' },
    { id: 'LearnerIDNumber', numeric: false, disablePadding: false, label: 'Learner ID Number' },
    { id: 'TrancheType', numeric: false, disablePadding: false, label: 'Tranche Type' },
    { id: 'TrancheAmount', numeric: false, disablePadding: false, label: 'Tranche Amount' },
    { id: 'OrganisationSDLNumber', numeric: false, disablePadding: false, label: 'Organisation SDL Number' },
    { id: 'InterventionCode', numeric: false, disablePadding: false, label: 'Intervention Code' }
];


class GrantReversalsSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedClaims: null,
            message: null,
            messageStyle: null,
            model: {
                EmployerID: null,
                DiscretionaryGrantTypeID: null,
                InterventionID: null
            },
            keyUpSwitch: false

        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        {id: 'reverseGrant', label: 'Reverse Grant', tooltip: 'Reverse Grant',disabled: utils.isNull(this.state.selectedClaims)}
                    ]
                }
            ]
        };

    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "close") {
            this.props.history.push({
                pathname: '/ProcessingNav'
            })
        } else if ("reverseGrant" === buttonId) {
            this.reverseGrants();
            toolbarRef.current.setButtonDisabled("reverseGrant", true);
        }
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelections = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedClaims: selection, Message: "" },
                () => {
                    toolbarRef.current.setButtonDisabled("reverseGrant", selection.length === 0);
                }
            )
        }
        else {
            this.setState({ selectedClaims: null, Message: "" },
                () => {
                    toolbarRef.current.setButtonDisabled("reverseGrant", selection.length === 0);
                }
            )
        }
    }

    reverseGrants = () => {
        const IDs = this.state.selectedClaims.map(e => e.Id);
        let data = {
            IDs: IDs,
            DiscretionaryGrantTypeID: this.state.model.DiscretionaryGrantTypeID
        }
        let url = "api/sims/GrantReversals/AXReversalProcess";
        utils.invokeUrl(url, (response) => {
            this.successCallback(response);
        },
            null,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        )
    };

    handleFormValueChange = (values) => {
        if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
            this.setState({ model: values, selectedGrant: null });
        }
    }

    successCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    render() {

        return (
            <ViewContainer title={''}>
                <p className="breadcrumbs">
                    {"Grant Processing > Batch Reversals"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <SearchView headCells={headCells} dataUrl="api/sims/GrantReversals/GrantReversalGetDetails"
                    model={this.state.model} toolbarConfig={this.toolbarConfig} searchParameters={this.searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    toolbarRef={toolbarRef}
                    history={this.props.history}
                    searchLabel={"SEARCH"}
                    multiSelect={true}
                    handleObject={true}
                    autoFetchData={false}
                    keyUpSwitch={this.state.keyUpSwitch}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    setSelection={(selection) => { this.handleSelections(selection) }}
                >
                    <GrantReversalsSearchForm  data={this.state.model}
                        valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(GrantReversalsSearch));




import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../widgets/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import { EntityForm } from '../../widgets/Forms';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const AudienceOptionsForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="audienceoptions"
            dataURL='api/sims/audienceoptions/getfind'
            values={props.data}
            entityLookupController={props.entityLookupController} org="mqa"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="AudienceOptionsForm">
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Audience Selection" {...a11yProps(0)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0} >
                            <table cellPadding="2" width="100%">
                                <tbody>
                                <tr>
                                    <td>
                                        <CustomCheckbox
                                            id="PrimarySDF"
                                            key={tabValue}
                                            form={form}
                                            disabled={!props.canEdit}
                                            InputProps={{
                                                'aria-label': 'primary checkbox'

                                            }}
                                            label="PrimarySDF"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomCheckbox
                                            id="SecondarySDF"
                                            key={tabValue}
                                            form={form}
                                            disabled={!props.canEdit}
                                            InputProps={{
                                                'aria-label': 'primary checkbox'

                                            }}
                                            label="SecondarySDF"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomCheckbox
                                            id="TrainingProvider"
                                            key={tabValue}
                                            form={form}
                                            disabled={!props.canEdit}
                                            InputProps={{
                                                'aria-label': 'primary checkbox'

                                            }}
                                            label="TrainingProvider"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomCheckbox
                                            id="Assessors"
                                            key={tabValue}
                                            form={form}
                                            disabled={!props.canEdit}
                                            InputProps={{
                                                'aria-label': 'primary checkbox'

                                            }}
                                            label="Assessors"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomCheckbox
                                            id="Moderators"
                                            key={tabValue}
                                            form={form}
                                            disabled={!props.canEdit}
                                            InputProps={{
                                                'aria-label': 'primary checkbox'

                                            }}
                                            label="Moderators"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomCheckbox
                                            id="Employers"
                                            key={tabValue}
                                            form={form}
                                            disabled={!props.canEdit}
                                            InputProps={{
                                                'aria-label': 'primary checkbox'

                                            }}
                                            label="Employers"
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </TabPanel>
                </form>
            }
        </EntityForm>
    );
})


import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../../widgets/Toolbar';
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import "./GrantTransaction.css";
import ViewUtils from '../../../ViewUtils';
import AlertItem from "../../../widgets/AlertItem";
import { ViewContainer } from "./../../../View.jsx";
import withMenuPermission from "../../../widgets/withMenuPermission";
import EnhancedTable from '../../../widgets/ResponsiveTable';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Typography } from "antd";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { GrantTransactionForm } from './GrantTransactionForm';


const utils = new ViewUtils();
const formRef = React.createRef();
const toolbarRef = React.createRef();



const processheadCells = [
    { id: 'ProcessDate', numeric: false, disablePadding: false, label: 'Process Date' },
    { id: 'ProcessID', numeric: false, disablePadding: false, label: 'Process ID' },
    { id: 'ImportID', numeric: false, disablePadding: false, label: 'Batch Number' },
    { id: 'GrantCode', numeric: false, disablePadding: false, label: 'Grant Code' },
    { id: 'Progress', numeric: false, disablePadding: false, label: 'Progress' },
    { id: 'StatusCode', numeric: false, disablePadding: false, label: 'Status Code' },
    { id: 'Status', numeric: false, disablePadding: false, label: 'Status' }
];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        color: 'white',
    },
    table: {
        minWidth: 700,
    },
});

class GrantTransaction extends Component {

    state = {
        model: {
            GrantCodeID: null
        },
        message: "",
        messageStyle: "",
        title: "",
        id: null,
        searchParameters: [{ Name: "GrantCodeID", Value: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.GrantCodeID)) ? this.props.location.state.GrantCodeID : null }],
        OpenDialog: false,
        showTable: true,
        selectedGrant: null,
        selectedGrants: null,
        GrantCodeID : null,
        dropDownHeadCells : null
    };

    currentRole = utils.getCurrentRole(); 
    rolesAllowedToSeeColumn = ["Senior Manager Finance","COO","CFO","CEO","Skills Finance"];
    rolesAllowedProcess = ["Senior Manager Finance","Administrator", "SG Administrator", "Finance Manager"];
    rolesAllowedApprove = ["COO","CFO","CEO"];

    roleColumns = { 

        "COO": [{ id: 'COORecommended', numeric: false, disablePadding: false, label: 'COO Recommended' }], 
        
        "CFO": [{ id: 'COORecommended', numeric: false, disablePadding: false, label: 'COO Recommended' },{ id: 'CFORecommended', numeric: false, disablePadding: false, label: 'CFO Recommended' }], 
        
        "CEO": [{ id: 'COORecommended', numeric: false, disablePadding: false, label: 'COO Recommended' },{ id: 'CFORecommended', numeric: false, disablePadding: false, label: 'CFO Recommended' },  { id: 'CEOApproved', numeric: false, disablePadding: false, label: 'CEO Approved' }],

        "Skills Finance": [{ id: 'COORecommended', numeric: false, disablePadding: false, label: 'COO Recommended' },{ id: 'CFORecommended', numeric: false, disablePadding: false, label: 'CFO Recommended' },  { id: 'CEOApproved', numeric: false, disablePadding: false, label: 'CEO Approved' }] }; 
   
    model = this.rolesAllowedToSeeColumn.includes(this.currentRole) ?
    {
        GrantCodeID: { Id: 1, Description: "MANDATORY GRANTS" }
    }
    :
    {
        GrantCodeID: null
    };

    
    headCells = this.rolesAllowedToSeeColumn.includes(this.currentRole) ?
    [ 
        { id: 'ImportID', numeric: false, disablePadding: false, label: 'Batch Number' }, 

        { id: 'GrantCode', numeric: false, disablePadding: false, label: 'Grant Code' }, 

        { id: 'GrantDescription', numeric: false, disablePadding: false, label: 'Grant Description' },

        { id: 'ApprovalReport', numeric: false, disablePadding: false, label: 'Mandatory Grant Report', contentType: 'document' }, 
    ].concat(...(this.roleColumns[this.currentRole] || [])) 
    :
    [ 
        { id: 'ImportID', numeric: false, disablePadding: false, label: 'Batch Number' }, 

        { id: 'GrantCode', numeric: false, disablePadding: false, label: 'Grant Code' }, 

        { id: 'GrantDescription', numeric: false, disablePadding: false, label: 'Grant Description' },

        { id: 'ApprovalReport', numeric: false, disablePadding: false, label: 'Approval Report', contentType: 'document' } 
    ];

    hasDefault = this.rolesAllowedToSeeColumn.includes(this.currentRole) ?
    true
    :
    false;

    multi = this.rolesAllowedToSeeColumn.includes(this.currentRole) ?
    true
    :
    false;

    process = this.rolesAllowedProcess.includes(this.currentRole) ?
    true
    :
    false;

    approve = this.rolesAllowedApprove.includes(this.currentRole) ?
    true
    :
    false;

    toolbarConfig = {
        items: [
            {
                type: "buttonGroup",
                buttons: [
                    { id: 'save', label: 'SAVE', tooltip: 'Process to GP', icon: 'SendIcon', visible: this.process ? this.props.menuPermissionItem.Edit : false },
                    { id: 'approve', label: 'Approve/Recommend', tooltip: 'Approve/Recommend Mandatory Grants', visible: this.approve ? this.props.menuPermissionItem.Edit : false },
                    { id: 'decline', label: 'Decline', tooltip: 'Decline Mandatory Grants', visible: this.approve ? this.props.menuPermissionItem.Edit : false },
                    { id: 'view', label: 'VIEW', tooltip: 'View GP Process Progress', icon: 'InfoIcon', visible: this.props.menuPermissionItem.View }
                    
                ]
            }
        ]
    };

    save = () => {

        let url = "api/sims/GrantTransaction/GrantProcess?GrantCodeID=" + this.state.GrantCodeID + "&ImportID=" + this.state.selectedGrant;
            utils.invokeUrl(url, (response) => {
                this.successCallback(response);
            },
                (response) => {
                    this.error(response);
                },
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    }
                }
            )
        }

    approvegrant = (ApproveReject) => {
    
        let formValue = {};
        let ImportIDList = "";
        let ImportID = "";

        formValue.GrantCodeID = this.state.GrantCodeID;

        if (this.state.selectedGrants !== null)
        {
            let selection = this.state.selectedGrants;
            if (selection !== null) {
                for (let i = 0; i < selection.length; i++) {
                    if (i === 0) { ImportIDList += selection[i].Id; }
                    else { ImportIDList += "," + selection[i].Id; }
    
                }
            }     
        }

        if (this.state.selectedGrant !== null)
        {
            ImportID = this.state.selectedGrant    
        }

        formValue.ImportIDList = ImportIDList;
        formValue.ImportID = ImportID;

        if (formValue !== null && typeof formValue !== 'undefined') {


        let url = ApproveReject === 1 ?
        "api/sims/GrantTransaction/GrantApproval"
        :
        "api/sims/GrantTransaction/GrantRejection"    

        utils.invokeUrl(url, (response) => {
            this.successCallback(response);
        },
            (response) => {
                this.error(response);
            },
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(formValue)
            }
        )
    }
            }

        processgrant = () => {
                let formValue = {};
                let ImportIDList = "";
                let ImportID = "";
        
                formValue.GrantCodeID = this.state.GrantCodeID;

                if (this.state.selectedGrants !== null)
                {
                    let selection = this.state.selectedGrants;
                    if (selection !== null) {
                        for (let i = 0; i < selection.length; i++) {
                            if (i === 0) { ImportIDList += selection[i].Id; }
                            else { ImportIDList += "," + selection[i].Id; }
            
                        }
                    }     
                }

                if (this.state.selectedGrant !== null)
                {
                    ImportID = this.state.selectedGrant    
                }

                formValue.ImportIDList = ImportIDList;
                formValue.ImportID = ImportID;
        
                if (formValue !== null && typeof formValue !== 'undefined') {

                let url = "api/sims/GrantTransaction/GrantProcessApproval"
                utils.invokeUrl(url, (response) => {
                    this.successCallback(response);
                },
                    (response) => {
                        this.error(response);
                    },
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        },
                        body: JSON.stringify(formValue)
                    }
                )
            }
        }

    successCallback(response) {
        this.setState({
            message: response.Message, messageStyle: response.MessageType
        });
        this.setState({
            showTable: false
        }, () => {
            this.setState({ showTable: true })
        });

    }
    errorCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
        this.setState({
            showTable: false
        }, () => {
            this.setState({ showTable: true })
        });
    }

    handleFormValueChange = (values) => {
        let GrantCodeID = null;
        let newHeadCells = null;
        let newsearchParameters = [...this.state.searchParameters];

        if (!utils.isNull(values.GrantCodeID)) {
            GrantCodeID = values.GrantCodeID.Id;
            let temp_element = { ...newsearchParameters[0] };
            temp_element.Value = GrantCodeID
            newsearchParameters[0] = temp_element;
        }

         if (!utils.isNull(values.GrantCodeID) && GrantCodeID.toString() === "1")
         {
            newHeadCells = [ 
                { id: 'ImportID', numeric: false, disablePadding: false, label: 'Batch Number' }, 
        
                { id: 'GrantCode', numeric: false, disablePadding: false, label: 'Grant Code' }, 
        
                { id: 'GrantDescription', numeric: false, disablePadding: false, label: 'Grant Description' },
        
                { id: 'ApprovalReport', numeric: false, disablePadding: false, label: 'Mandatory Grant Report', contentType: 'document' }, 
            ].concat(...(this.roleColumns[this.currentRole] || [])) 
         }
         else
         {
            newHeadCells =[ 
                { id: 'ImportID', numeric: false, disablePadding: false, label: 'Batch Number' }, 
        
                { id: 'GrantCode', numeric: false, disablePadding: false, label: 'Grant Code' }, 
        
                { id: 'GrantDescription', numeric: false, disablePadding: false, label: 'Grant Description' },
        
                { id: 'ApprovalReport', numeric: false, disablePadding: false, label: 'Approval Report', contentType: 'document' } 
            ];
         }

        this.setState({
            searchParameters: JSON.parse(JSON.stringify(newsearchParameters)),
            GrantCodeID: GrantCodeID,
            dropDownHeadCells : newHeadCells
        });
        this.setState({
            showTable: false
        }, () => {
            this.setState({ showTable: true })
        });

            

    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            if(this.state.GrantCodeID === 1)
            {
               this.processgrant(); 
            }
            else
            {
                this.save();      
            }
            
        }if ("approve" === buttonId) {
            this.approvegrant(1);
        }if ("decline" === buttonId) {
            this.approvegrant(0);
        }
        else if ("view" === buttonId) {
            this.OpenViewDialog();
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            if (selection.length > 1) {
                this.setState({ selectedGrant: null, selectedGrants: selection, Message: "" });
            }
            else if (selection.length === 1) {
                this.setState({ selectedGrant: selection[0].Id, selectedGrants: null });
            }
            else {
                this.setState({ selectedGrant: null, selectedGrants: null });
            }
        }
        else {
            this.setState({ selectedGrant: null, selectedGrants: null, Message: "" });
        }

        toolbarRef.current.setButtonDisabled("save", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("approve", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("decline", selection === null || selection.length === 0);
    }

    FileDownload = (rowid, headCell) => {
        if (headCell.contentType === "document") {
            console.log(headCell);
            utils.invokeUrl("api/sims/GrantTransaction/DownloadGrantReport?GrantCodeID=" + this.state.GrantCodeID + "&ImportID=" + rowid, (response) => {

                var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
                var url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = response.Name;
                link.target = '_blank';
                link.click();
            }, (e) => {
                this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
            }
            );
        }
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };
    
    OpenViewDialog() {
        this.setState({
            OpenDialog: true
        });
    }

    CloseViewDialog = () => {
        this.setState({
            OpenDialog: false,
            message: "",
            messageStyle: ""
        });
        this.setState({
            showTable: false
        }, () => {
            this.setState({ showTable: true })
        });
    };
    render() {
        const { classes } = this.props;
        return (

                <ViewContainer  title="">
                <p className="breadcrumbs">
                    {"Grant Transactions"}
                </p>
                    <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <tr>
                <td><EnhancedToolbar 
                ref={toolbarRef} highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} 
                enabled={true} config={this.toolbarConfig} />
                </td>
                </tr>
                
                <tr>
                            <td colSpan="2" className="formContainer">
                                <GrantTransactionForm
                                        ref={formRef} id={this.state.id} data={this.model} hasDefault={this.hasDefault}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                            </td>
                        </tr>
                
                <div className="searchResults"> 
                 {this.state.showTable === true && ( 
                        <EnhancedTable
                        headCells={this.state.dropDownHeadCells === null ? this.headCells : this.state.dropDownHeadCells}
                        dataUrl={!utils.isNull(this.state.GrantCodeID) ? "api/sims/GrantTransaction/GetSearch" : null}
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        searchParameters={this.state.searchParameters} paged={true} 
                        makeRowBold={true}
                        onCellSelectionChange={(rowid, headCell) => { this.FileDownload(rowid, headCell) }}
                        multiSelect={this.multi}/>

                        )}
                        </div>  

                <Dialog fullScreen open={this.state.OpenDialog} onClose={this.CloseViewDialog} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.CloseViewDialog} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h1" className={classes.title}>
                                Grant Process to Great Plains Progress
                            </Typography>

                        </Toolbar >
                    </AppBar >
                    <br /><br />
                    <div className="searchResults"> 
                    <EnhancedTable
                        headCells={processheadCells}
                        dataUrl={`api/sims/GrantTransaction/GetGrantProcessSearch`}
                        makeRowBold={true}
                        searchParameters={this.state.searchParameters} paged={true} />
                        </div> 
                </Dialog >

                </ViewContainer>
        );
    }
}
export default withRouter(withStyles(useStyles())(withMenuPermission(GrantTransaction)));
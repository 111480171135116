import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import UploadDialog from './LearnerEISAUploadDialog';
import ViewUtils from "./../../ViewUtils";
import { ViewContainer } from "./../../View.jsx";
import Prompt from '../../widgets/Prompt';
import AlertItem from "../../widgets/AlertItem";
import EnhancedTable from '../../widgets/ResponsiveTable';
import withMenuPermission from "../../widgets/withMenuPermission";

const utils = new ViewUtils();

const headCells = [
    { id: 'EISAFLC', numeric: false, disablePadding: true, label: 'FLC' },
    { id: 'FLCDocument', numeric: false, disablePadding: true, label: 'FLC Document', contentType: 'document' },
    { id: 'SORDocument', numeric: false, disablePadding: true, label: 'SOR Document', contentType: 'document' },
    { id: 'FLCSORNumber', numeric: false, disablePadding: true, label: 'FLC SOR Number' },
    { id: 'FLCSORIssueDate', numeric: false, disablePadding: true, label: 'FLC SOR Issue Date' },
    { id: 'SOWEDocument', numeric: false, disablePadding: true, label: 'Statement of Work Experience Document', contentType: 'document' }
];

const toolbarRef = React.createRef();
class LearnerEISAReadinessSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            addDialogOpen: false,
            selectedDocument: null,
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "LearnerQCTOLearnershipID", Value: props.location.state.LearnerQCTOLearnershipID }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add EISA Readiness', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add }
                    ]
                }
            ]
        };
    }


    handleToolbarButtonClick(buttonId) {
        if (buttonId === "add") {
            this.setState({ addDialogOpen: true });

        } else if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/LearnerQCTOLearnershipSearch',
                state: {
                    learnerID: this.props.location.state.LearnerID,
                    hideFields: false,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.location.state.permissions
                }
            });
        }
    }

    successCallback = (response) => {
        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedDocument: null,
                message: response.Message, messageStyle: response.MessageType.toLowerCase()
            })
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
        else if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    handleToolbarButtonMenuClick(menu) {
        this.setState({ linkDialogOpen: true, selectedLinkType: menu });
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({
                selectedDocument: selection[0].Id,
                message: "",
                messageStyle: ""
            },
                () => {
                    toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0)
                }
            )
        }
        else {
            this.setState({
                selectedDocument: null
            },
                () => {
                    toolbarRef.current.setButtonDisabled("remove", true)
                }
            )
        }
    }

    postAddHandler(cancel, save, response) {
        if (save === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                addDialogOpen: false,
                selectedDocument: null,
                message: response.Message,
                messageStyle: response.MessageType
            });
        }
        else if (cancel === true) {
            this.setState({
                addDialogOpen: false,
                selectedDocument: null
            })
        }
    }

    FileDownload = (rowid, headCell) => {
        if (headCell.contentType === "document") {
            console.log(headCell);
            var docurl = "";
            if (headCell.id === "FLCDocument") {
                docurl = "api/sims/LearnerEISAReadiness/downloaddocumentFLC?id="
            }
            else if (headCell.id === "SORDocument") {
                docurl = "api/sims/LearnerEISAReadiness/downloaddocumentSOR?id="
            }
            else if (headCell.id === "SOWEDocument") {
                docurl = "api/sims/LearnerEISAReadiness/downloaddocumentSOWE?id="
            }
            utils.invokeUrl(docurl + rowid, (response) => {

                var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
                var url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = response.Name;
                link.target = '_blank';
                link.click();
            }, (e) => {
                this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
            }
            );
        }
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    CheckHasData = (results) =>  {
        if (results.TotalNumberOfRows > 0) {
            toolbarRef.current.setButtonDisabled("add", true)
        }
    }

    render() {
        return (
            <ViewContainer>
                <p className="breadcrumbs">
                    {`Learner QCTO Learnership > EISA Readiness > ${this.props.location.state.learnerNames} > ${this.props.location.state.QCTOLearnership}`}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <div className="searchResults">
                    <EnhancedToolbar highlight={false} ref={toolbarRef}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)}
                        config={this.toolbarConfig} />
                    <EnhancedTable headCells={headCells} onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        dataUrl="api/sims/LearnerEISAReadiness/GetSearch"
                        searchParameters={this.state.searchParameters}
                        onCellSelectionChange={(rowid, headCell) => { this.FileDownload(rowid, headCell) }}
                        paged={false} onDataArrive={this.CheckHasData} />
                </div>

                <UploadDialog
                    open={this.state.addDialogOpen}
                    closeHandler={(cancel, save, response) => this.postAddHandler(cancel, save, response)}
                    viewRef={this}
                    LearnerQCTOLearnershipID={this.props.location.state.LearnerQCTOLearnershipID}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(LearnerEISAReadinessSearch));
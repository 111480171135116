import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import SearchView from "../../widgets/SearchView";
import ViewUtils from "../../ViewUtils";
import { ViewContainer } from "../../View.jsx";
import withMenuPermission from "../../widgets/withMenuPermission";
import DGApprovalSearchForm from "./DGApprovalSearchForm";



const utils = new ViewUtils();
const toolbarRef = React.createRef();
class DGApprovalSearch extends Component {

  constructor(props) {
    super(props);

    this.toolbarConfig = {
      items: [
        {
    
          type: "buttonGroup",
          buttons: [
            { id: "close",label: "Close", tooltip: "Close", icon: "CloseIcon"},
          ]
        },
          {
            type: "devider"
          },
        {
         type: "buttonGroup",
          buttons: [  
            { id: "view", label: "", tooltip: "View", icon: "ViewIcon", disabled: true },
    
          ],
        },
      ],
    };

    this.headCells = [
      {id: "SubmissionNumber", numeric: false, disablePadding: true, label: "Submission Number"},
      {id: "DGIntervention",numeric: false,disablePadding: true,label: "DG Intervention"},
      {id: "DGApplicationStatus",numeric: false,disablePadding: true,label: "DG Application Status"},
      {id: "DGNumber",numeric: false,disablePadding: true,label: "DG Number"},
      {id: "ApprovedBy",numeric: false,disablePadding: true,label: "Approved By"},
        { id: "ApprovedDate", numeric: false, disablePadding: true, label: "Approved Date" },
        { id: "NotApprovedBy", numeric: false, disablePadding: true, label: "Rejected By" },
        { id: "NotApprovedDate", numeric: false, disablePadding: true, label: "Rejected Date" },
        { id: "VerificationDate", numeric: false, disablePadding: true, label: "Verification Date" },
        { id: "VerifiedBy", numeric: false, disablePadding: true, label: "Verified By" },
        { id: "VerificationStatus", numeric: false, disablePadding: true, label: "Verification Status" },
        { id: "EvaluationDate", numeric: false, disablePadding: true, label: "Evaluation Date" },
        { id: "EvaluatedBy", numeric: false, disablePadding: true, label: "Evaluated By" },
        { id: "EvaluationStatus", numeric: false, disablePadding: true, label: "Evaluation Status" },
    ];

    let model = {
      OrganisationID: this.props.location.state.organisationID,
      FinancialYearID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.FinancialYearID)) ? this.props.location.state.FinancialYearID : ""
    };

    this.searchParameters = [
      { Name: "OrganisationID", Value: this.props.location.state.organisationID },
      { Name: "FinancialYearID", Value: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.FinancialYearID)) ? this.props.location.state.FinancialYearID : "" } 
    ];

    this.state = {
      model: model,
      keyUpSwitch: false,
      selectedDG: null,
      OrganisationID: this.props.location.state.organisationID,
      FinancialYearID : null
    };
  }
  handleSelection = (selection) => {
      if (selection !== null && selection.length !== 0) {
        this.setState({ selectedDG: selection[0].Id });
      }
    else {
      this.setState({ selectedDG: null });
      }
      toolbarRef.current.setButtonDisabled("view", false);
  }
  
  handleFormValueChange = (values) => {
    let newValues = null;
    if (!utils.isNull(values)) {
        newValues = { ...values };
    }
    if (!utils.isNull(newValues)) {
        if (JSON.stringify(this.state.model) !== JSON.stringify(newValues)) {
            this.setState({
                model: newValues,
                clearGrid: true,
                selectedDG: null,
                Message: "",
                FinancialYearID: values.FinancialYearID.Id
            });
        }
    }
}


   handleToolbarButtonClick = (buttonId) => {
    if ("view" === buttonId) {
      this.props.history.push({
         pathname: "/DGApprovalMain",
          state: {
            OrganisationID: this.state.OrganisationID,
            DGApplicationID: this.state.selectedDG,
            FinancialYearID : this.state.model.FinancialYearID
          },
       });
     }
     else if ("close" === buttonId) {
      this.props.history.push({
        pathname: '/DGOrganisation',
        state: { selection: this.props.location.state.organisationID }
    });
    }
   }

  render() {
    return (
      <ViewContainer topPadding="0">
        <p className="breadcrumbs">
                    {"DG Approval " + (this.props.location.state.organisationName !== null ? "> " + this.props.location.state.organisationName + (this.props.location.state.sdlnumber !== null ? " - " + this.props.location.state.sdlnumber : "") : "> Add")}
                </p>

                <br />
        <SearchView
          headCells={this.headCells}
          dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/dgapproval/getsearch"}
          entityViewPath='/DGApprovalMain'
          model={this.state.model}
          toolbarConfig={this.toolbarConfig}
          searchParameters={this.searchParameters}
          buttonClickHandler={this.handleToolbarButtonClick}
          mode={this.props.mode}
          multiSelect={this.props.multiSelect}
          onLookupValueChange={this.props.onLookupValueChange}
          descriptionProp="LegalName"
          selectionCallbackMode={this.props.selectionCallbackMode}
          history={this.props.history}
          keyUpSwitch={this.state.keyUpSwitch}
          setSelection={(selection) => { this.handleSelection(selection) }}
          toolbarRef={toolbarRef}
        >
          <DGApprovalSearchForm
            data={this.state.model}
            onKeyUp={(e) => { this.onkeyup(e) }}
            valueChangeHandler={this.handleFormValueChange}
            dependency={this.state.dependency}
            />
        </SearchView>
      </ViewContainer>
    );
  }
}

export default withRouter(connect()(DGApprovalSearch));

import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./QualificationSetup.css";
import SearchView from "../../widgets/SearchView";
import BursaryQualificationSearchForm from './BursaryQualificationSearchForm';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import withMenuPermission from "../../widgets/withMenuPermission";

const utils = new ViewUtils();

const headCells = [
    { id: 'Code', numeric: false, disablePadding: true, label: 'Qualification Code' },
    { id: 'Description', numeric: false, disablePadding: true, label: 'Qualification Name' }
];

class BursaryQualificationSearch extends Component {

    constructor(props) {
        super(props)


        this.searchParameters = !utils.isNull(this.props.programmeId) ?
            [
                { Name: "id", Value: this.props.programmeId },
                { Name: "QualificationCode", Value: "" },
                { Name: "QualificationName", Value: "" }
            ]
            :
            [
                { Name: "QualificationCode", Value: "" },
                { Name: "QualificationName", Value: "" },
            ]

        let model = !utils.isNull(this.props.programmeId) ?
            {
                QualificationCode: "",
                QualificationName: "",
                id: this.props.programmeId
            }
            :
            {
                QualificationCode: "",
                QualificationName: ""
            }

        if (!utils.isNull(props.exclusions)) {
            model.Exclusions = props.exclusions;
            this.searchParameters.push({ Name: "Exclusions", Value: props.exclusions });
        }

        if (!utils.isNull(props.personId)) {
            model.PersonID = props.personId;
            this.searchParameters.push({Name: "PersonID", Value: props.personId})
        }

        this.state = {
            model: model,
            keyUpSwitch: false,
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: "delete", label: '', tooltip: 'Remove Qualification', icon: 'DeleteIcon', disabled: true, visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        };
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            e.preventDefault();
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
         }
    }

    clearSearch(e) {
        this.setState({
            model: {
                QualificationCode: "",
                QualificationName: "",
                id: this.props.programmeId
            }
        });
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <SearchView
                    headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/indicium/BursaryApplication/GetBursaryQualification"}
                    model={this.state.model}
                    toolbarConfig={this.toolbarConfig} 
                    searchParameters={this.searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp={"Code"}
                    SecondDescriptionProp={"Description"}
                    mode={this.props.mode}
                    history={this.props.history}
                    multiSelect={this.props.multiSelect}
                    keyUpSwitch={this.state.keyUpSwitch}
                    title='Qualifications' 
                    checkRefreshToken={!this.props.IsExternal}
                    promptTitle={"Remove Qualification"}
                    selectionCallbackMode={this.props.selectionCallbackMode}
                    promptContent={"Removing the qualification is a permanent action and the qualification will not be available going forward. Are you sure you want to remove this qualification?"}
                    clearPress={(e) => { this.clearSearch(e) }} allowClear={this.props.allowClear}
            >
                    <BursaryQualificationSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(BursaryQualificationSearch));


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTextField } from '../../widgets/CustomTextField';
import { EntityForm } from '../../widgets/Forms';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
}));

export default function BursaryInstitutionSearchForm(props) {
    const [labelWidth, setLabelWidth] = React.useState(0);
    const labelRef = React.useRef(null);
    const classes = useStyles();

    React.useEffect(() => {
        if (labelRef.current !== null) {
            setLabelWidth(labelRef.current.offsetWidth);
        }
    }, []);

    return (
        <EntityForm viewId="organisation" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={classes.container} >
                    <table cellPadding="2">
                        <tbody>
                            <tr>
                                <td>
                                    <CustomTextField
                                        id="InstitutionName"
                                        label="Institution Name"
                                        className="w-100"
                                        onKeyPress={props.onKeyUp}
                                        InputProps={{
                                            readOnly: props.disableFields,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form >
            }
        </EntityForm>
    );
}

import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import GrantSearchForm from './GrantSearchForm';
import SearchView from "../../../widgets/SearchView";
import withMenuPermission from "../../../widgets/withMenuPermission";
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from "../../../ViewUtils";

const utils = new ViewUtils();
const toolbarRef = React.createRef();

const roleSRU = "SRU Administrator";

const headCells = [
    { id: 'FinancialYear', numeric: false, disablePadding: false, label: 'Financial Year' },
    { id: 'TrancheCode', numeric: false, disablePadding: false, label: 'Tranche Code' },
    { id: 'TrancheCodeDescription', numeric: false, disablePadding: false, label: 'Tranche Code Description' },
    { id: 'TrancheBudget', numeric: false, disablePadding: false, label: 'Tranche Budget' },
    { id: 'Programme', numeric: false, disablePadding: false, label: 'Programme' }
];


class GrantSetupSearch extends Component {

    constructor(props) {
        super(props);

        

        this.state = {
            selectedGrant: null,
            model: {
                FinancialYearID: null,
                TrancheCodeID: null                
            },
            searchParameters: [{ Name: "TrancheCodeID", Value: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.TrancheCodeID)) ? this.props.location.state.TrancheCodeID : null }],
            keyUpSwitch: false

        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Create New', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', visible: this.props.menuPermissionItem.Edit, disabled: utils.isNull(this.state.selectedGrant) },
                        //{ id: 'delete', label: '', tooltip: 'Remove Grant', icon: 'DeleteIcon', visible: this.props.menuPermissionItem.Delete, disabled: utils.isNull(this.state.selectedGrant) }
                    ]
                }
            ]
        };

    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedGrant: selection })
        }
        else {
            this.setState({ selectedGrant: null })
        }
        toolbarRef.current.setButtonDisabled("edit", selection === null || selection.length === 0);
        //toolbarRef.current.setButtonDisabled("delete", selection === null || selection.length === 0);
    }

    handleFormValueChange = (values) => {

        let TrancheCodeID = null;
        let newsearchParameters = [...this.state.searchParameters];

        if (!utils.isNull(values.TrancheCodeID)) {
            TrancheCodeID = values.TrancheCodeID.Id;
            let temp_element = { ...newsearchParameters[0] };
            temp_element.Value = TrancheCodeID
            newsearchParameters[0] = temp_element;
        }

        this.setState({
            searchParameters: JSON.parse(JSON.stringify(newsearchParameters)),
            TrancheCodeID: TrancheCodeID
        });

        if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
            this.setState({ model: values, selectedGrant: null });
            toolbarRef.current.setButtonDisabled("edit", true);
        }

        if (utils.getCurrentRole() === roleSRU) {
            if (TrancheCodeID !== "60" && TrancheCodeID !== "61" && TrancheCodeID !== "62" && TrancheCodeID !== "66") {
                toolbarRef.current.setButtonDisabled("add", true);
            }
            else { toolbarRef.current.setButtonDisabled("add", false); }
        }
    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.props.history.push({
                pathname: '/AddEditTranche',
                state: {
                    TrancheID: null,
                    TrancheCodeID: this.state.TrancheCodeID,
                    hideFields: true
                }
            })
        }
        else if (buttonId === "edit") {
            this.props.history.push({
                pathname: '/AddEditTranche',
                state: {
                    TrancheID: this.state.selectedGrant[0].Id,
                    TrancheCodeID: this.state.TrancheCodeID,
                    hideFields: false
                }
            })
        }
    }

    render() {

        return (
            <ViewContainer  title="">
                <SearchView headCells={headCells} dataUrl="api/sims/GrantSetup/GetSearch" entityViewPath='/grant' addViewPath='/addGrant'
                    model={this.state.model} toolbarConfig={this.toolbarConfig} searchParameters={this.searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange} deleteUrl={"api/sims/GrantSetup/DeleteTrancheSetup"}
                    useDeletePost={true}
                    toolbarRef={toolbarRef}
                    history={this.props.history}
                    searchLabel={"SEARCH"}
                    title='Grant Setup' 
                    handleObject={true}
                    autoFetchData={false}
                    keyUpSwitch={this.state.keyUpSwitch}
                    promptTitle={"Remove Grant"}
                    promptContent={"Are you sure you want to remove this grant?"}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                >
                    <GrantSearchForm data={this.state.model}
                        valueChangeHandler={(values) => this.handleFormValueChange(values)}  />
                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(GrantSetupSearch));




import React, { Component, Fragment } from "react";
import { withRouter } from 'react-router-dom';
import { ViewContainer } from "../../View.jsx";
import ViewUtils from '../../ViewUtils';
import Button from '@material-ui/core/Button';
import withMenuPermission from "../../widgets/withMenuPermission";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import Item from "antd/lib/list/Item";
import AlertItem from "../../widgets/AlertItem";
import './Style.css'
import { SelectItem } from '../../widgets/SelectItem';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import WebIcon from '@material-ui/icons/Web';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from "antd";
import EnhancedTable from '../../widgets/ResponsiveTable';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';

const utils = new ViewUtils();

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'back', label: 'BACK', tooltip: 'Back to application details', theme: true },
                { id: 'next', label: 'Save', tooltip: 'Save application evaluation details', theme: true }
            ]
        }
    ]
};

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#01579B',
        color: theme.palette.common.white,
        border: '1px solid white',
    },
    body: {
        fontSize: 11,
    },
}))(TableCell);

const StyledTableTextCell = withStyles((theme) => ({
    body: {
        fontSize: 14,
        fontWeight: 500
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            height: theme.palette.action.main
        },
    },
}))(TableRow);

const useStyles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        color: 'white',
    },
    table: {
        minWidth: 700,
    },
});

class BursaryApplicationEvaluationDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            objectProps: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.object)) ? this.props.location.state.object : null,
            formObject: null,
            lookupArray: null,
            lookupVArray: null,
            ApplicationYear: '',
            ApplicantName: '',
            ApplicantSurname: '',
            IDNo: '',
            Status: '',
            model: {
                Name: "",
            },
            title: "",
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
            EvaluationPermission: "EvaluationEnabled",
            searchParameters: [{ Name: "BursaryApplicationID", Value: this.props.location.state.BursaryApplicationID }],
            OpenDialog: false,
            formViewURL: "",
            formName: "",
            linkDialogOpen: false,
        };

        console.log(this.props.location.state.selection)
    }

    componentDidMount() {

        this.getForms(this.state.id)
        this.getlookup(this.state.id)
        //this.CheckEvaluationPermissions(this.state.id)
    }

    getForms(id) {
        let url = "api/sims/BursaryApplicationEvaluation/GetBursaryApplicationEvaluation?Id=" + this.state.id;
        utils.invokeUrl(url, (response) => {
            this.setState({
                formObject: response,
                ApplicationYear: response.ApplicationYear,
                ApplicantName: response.ApplicantName,
                ApplicantSurname: response.ApplicantSurname,
                IDNo: response.IDNo
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }


    getlookup(id) {
        let url = "api/sims/BursaryApplicationEvaluation/GetLookupList?listId=YesNoNotApplicable";
        utils.invokeUrl(url, (response) => {
            this.setState({ lookupArray: response })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )

        let urlV = "api/sims/BursaryApplicationEvaluation/GetLookupListBursaryApplicationEvaluationStatus";
        utils.invokeUrl(urlV, (response) => {
            this.setState({ lookupVArray: response })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    //CheckEvaluationPermissions(id) {
    //    let url = "api/sims/BursaryApplicationEvaluation/GetBursaryApplicationEvaluationPermissionCheck?BursaryApplicationID=" + this.state.id;
    //    utils.invokeUrl(url, (response) => {
    //        this.setState({
    //            EvaluationPermission: response.Result
    //        })
    //    },
    //        null,
    //        {
    //            method: 'GET',
    //            headers: {
    //                'Accept': 'application/json',
    //                'Content-Type': 'application/json',
    //                'Authorization': "Bearer " + localStorage.getItem("token")
    //            }
    //        }
    //    )
    //}

    handleDocumentArrayIO = (event, index) => {
        const values = [...this.state.formObject.BursaryApplicationEvaluationDocCheck];
        const indexObject = { ...values[index] };
        indexObject.DocumentUploadedYesNoID = { Id: event.target.value };
        values[index] = indexObject;
        const finalObject = { ...this.state.formObject, BursaryApplicationEvaluationDocCheck: values }
        this.setState({ formObject: finalObject });
    }

    handleDocumentArrayC = (event, index) => {
        const values = [...this.state.formObject.BursaryApplicationEvaluationDocCheck];
        const indexObject = { ...values[index] };
        indexObject.Comments = event.target.value;
        values[index] = indexObject;
        const finalObject = { ...this.state.formObject, BursaryApplicationEvaluationDocCheck: values }
        this.setState({ formObject: finalObject });
    }

    handleStatus = (event) => {
        const finalObject = { ...this.state.formObject, BursaryApplicationEvaluationStatusID: { Id: event.target.value } }
        this.setState({ formObject: finalObject });
    }

    handleComment = (event) => {
        const finalObject = { ...this.state.formObject, ReasonComment: event.target.value }
        this.setState({ formObject: finalObject });
    }

    SaveDocument = () => {
        let url = "api/sims/BursaryApplicationEvaluation/UpdateEvaluationDocCheck";
        utils.invokeUrl(url, (response) => {
            this.successCallback(response);
        },
            (response) => {
                this.error(response);
            },
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(this.state.formObject)
            }
        )
    }

    CheckStatus = () => {
        if (this.state.formObject.BursaryApplicationEvaluationStatusID.Id === "3" || this.state.formObject.BursaryApplicationEvaluationStatusID.Id === 3) {
            this.setState({ linkDialogOpen: true, message: "" })
        }
        else { this.SaveEvaluation(null) }

    }

    SaveEvaluation = (selection) => {

        //let QueryReasonsList = "";
        //if (selection !== null) {
        //    for (let i = 0; i < selection.length; i++) {
        //        if (i === 0) { QueryReasonsList += selection[i].Id; }
        //        else { QueryReasonsList += "," + selection[i].Id; }

        //    }
        //}

        let SaveObject = { ...this.state.formObject };
        this.setState({ formObject: SaveObject });
        console.log(SaveObject);
        let url = "api/sims/BursaryApplicationEvaluation/UpdateFinalEvaluation";
        utils.invokeUrl(url, (response) => {
            this.successCallback(response);
        },
            (response) => {
                this.error(response);
            },
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(SaveObject)
            }
        )
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {

            this.setState({
                linkDialogOpen: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters))
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    successCallback(response) {
        let message = "";
        if (this.IsJsonString(response.Message)) {
            let errResult = JSON.parse(response.Message);
            errResult.map((item, index) => (
                message += item.Item1 + ' - ' + item.Item2 + '\n'
            ))
        }
        else {
            message = response.Message;
        }
        this.setState({ message: message, messageStyle: response.MessageType });
    }
    error(response) {
        let message = "";
        if (this.IsJsonString(response.Message)) {
            let errResult = JSON.parse(response.Message);
            errResult.map((item, index) => (
                message += item.Item1 + ' - ' + item.Item2 + '\n'
            ))
        }
        else {
            message = response.Message;
        }
        this.setState({ message: message, messageStyle: response.MessageType });
    }

    close() {
        this.props.history.push({
            pathname: './BursaryApplicationEvaluation'
        });
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            //this.save();
        } else {
            this.close();
        }
    }

    buttonSubmitClickHandler() {
        this.submit();
    }

    IsJsonString(str) {
        try {
            var json = JSON.parse(str);
            return (typeof json === 'object');
        } catch (e) {
            return false;
        }
    }

    FileDownload(DocId) {
        utils.invokeUrl("api/sims/BursaryApplicationEvaluation/DownloadEvaluationDocument?documentID=" + DocId, (response) => {

            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    render() {

        const { classes } = this.props;
        const tablerows = !utils.isNull(this.state.formObject) ? this.state.formObject.BursaryApplicationEvaluationDocCheck.map((item, index) => (
            <StyledTableRow key={item.Number}>
                <StyledTableCell component="th" scope="row">
                    {item.Number}
                </StyledTableCell>
                <StyledTableCell align="left">{item.DocumentType}</StyledTableCell>
                <StyledTableCell align="left" component="th" scope="row" >
                    <TextField className="w-30" id={"DocumentUploadedYesNoID" + item.Number} value={item.DocumentUploadedYesNoID !== null ? item.DocumentUploadedYesNoID.Id : 0} select margin="normal" size="small" variant="outlined" style={{ minWidth: "200px", width: "100% !important" }} onChange={(values) => this.handleDocumentArrayIO(values, index)} >
                        <MenuItem value={0} key={0} >  </MenuItem>
                        {this.state.lookupArray.map((lookupitem, index) => (
                            <MenuItem value={lookupitem.Id} key={lookupitem.Id} >{lookupitem.Description}</MenuItem>
                        ))}
                    </TextField>
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row" >
                    <TextField id={"Comments" + item.Number} multiline rows={2} value={item.Comments} variant="outlined" style={{ minWidth: "300px", width: "100% !important" }} onChange={(values) => this.handleDocumentArrayC(values, index)}> </TextField>
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row" >
                    {item.SavedFileName !== "" ?
                        <IconButton variant="text" color="primary" onClick={() => this.FileDownload(item.DocUploadID)} ><CloudDownloadIcon /></IconButton>
                        :
                        null
                    }
                </StyledTableCell>
            </StyledTableRow >
        ))
            :
            null;

        return (
            <ViewContainer topPadding="0">
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <br />
                <table width="100%" className="FormsTableHeader">
                    <tbody>
                        <tr>
                            <td>
                                {/*<EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={toolbarConfig} />*/}
                            </td>
                            <td align="center">
                                <b>Bursary Application Evaluation - {this.state.ApplicationYear}</b><br />
                                <b></b>
                            </td>
                            <td align="center">
                                <b>{this.state.IDNo} - {this.state.ApplicantName} </b>&nbsp;&nbsp;&nbsp;
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                <Table className={classes.table} size="small" >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">#</StyledTableCell>
                            <StyledTableCell>Document Name</StyledTableCell>
                            <StyledTableCell>Document Uploaded (Y/N)</StyledTableCell>
                            <StyledTableCell>Comment</StyledTableCell>
                            <StyledTableCell>Download</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tablerows}
                    </TableBody>
                </Table>
                <br />
                <br />
                <Button variant="outlined" size="small" id="btnSave" color="#01579B" disabled={this.state.EvaluationPermission === "ViewOnly" ? true : false} onClick={this.SaveDocument}>Save Document Uploaded Details</Button>
                <br />
                <br />
                <TableContainer component={Paper}>
                    <table className={classes.table} aria-label="simple table" size="small">
                        <tbody>
                            <TableRow>
                                <td align="left">
                                    <b>Evaluation Status</b><br />
                                </td>
                                <td align="left">
                                    <TextField className="w-30" id="EvaluationStatusID" disabled={this.state.EvaluationPermission === "EvaluationEnabled" ? false : true} value={!utils.isNull(this.state.formObject) && !utils.isNull(this.state.formObject.BursaryApplicationEvaluationStatusID) ? this.state.formObject.BursaryApplicationEvaluationStatusID.Id : 0} select margin="normal" size="small" variant="outlined" style={{ minWidth: "200px", width: "100% !important" }} onChange={(values) => this.handleStatus(values)}>
                                        <MenuItem value={0} key={0} >  </MenuItem>
                                        {!utils.isNull(this.state.lookupVArray) ? this.state.lookupVArray.map((lookupVitem, index) => (

                                            <MenuItem value={lookupVitem.Id} key={lookupVitem.Id} >{lookupVitem.Description}</MenuItem>

                                        )) : null}
                                    </TextField>
                                </td>
                            </TableRow>
                            <TableRow>
                                <td align="left">
                                    <b>Reason Comment</b><br />
                                </td>
                                <td align="left">
                                    <TextField id="ReasonComment" multiline rows={4} value={!utils.isNull(this.state.formObject) ? this.state.formObject.ReasonComment : ""} variant="outlined" style={{ minWidth: "300px", width: "100% !important" }} onChange={(values) => this.handleComment(values)}> </TextField>
                                </td>
                            </TableRow>
                            <TableRow>
                                <td align="left">
                                    <b>Administrator</b><br />
                                </td>
                                <td align="left">

                                    <TextField id="Administrator" disabled={true} value={!utils.isNull(this.state.formObject) ? this.state.formObject.Administrator : ""} variant="outlined" margin="dense" style={{ minWidth: "300px", width: "100% !important" }} > </TextField>
                                </td>
                            </TableRow>
                            <TableRow>
                                <td align="left">
                                    <b>Evaluation Date</b><br />
                                </td>
                                <td align="left">

                                    <TextField id="DateEvaluated" disabled={true} value={!utils.isNull(this.state.formObject) ? this.state.formObject.DateEvaluated : ""} variant="outlined" margin="dense" style={{ minWidth: "300px", width: "100% !important" }} > </TextField>
                                </td>
                            </TableRow>
                            <TableRow>
                                <td align="left">
                                    <b>Last Updated User</b><br />
                                </td>
                                <td align="left">

                                    <TextField id="LastUpdatedUser" disabled={true} value={!utils.isNull(this.state.formObject) ? this.state.formObject.LastUpdatedUser : ""} variant="outlined" margin="dense" style={{ minWidth: "300px", width: "100% !important" }} > </TextField>
                                </td>
                            </TableRow>
                            <TableRow>
                                <td align="left">
                                    <b>Date Last Updated</b><br />
                                </td>
                                <td align="left">

                                    <TextField id="DateLastUpdated" disabled={true} value={!utils.isNull(this.state.formObject) ? this.state.formObject.DateLastUpdated : ""} variant="outlined" margin="dense" style={{ minWidth: "300px", width: "100% !important" }} > </TextField>
                                </td>
                            </TableRow>
                        </tbody>
                    </table>
                </TableContainer>
                <br />
                <Button variant="outlined" size="small" id="btnSave" color="#01579B" disabled={this.state.EvaluationPermission === "EvaluationEnabled" ? false : true} onClick={this.CheckStatus}>Save</Button>&nbsp;

            </ViewContainer>
        );
    }
}

export default withRouter(withStyles(useStyles())(withMenuPermission(BursaryApplicationEvaluationDetail)));

import React, { Component, Fragment } from "react";
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import ViewUtils from '../../ViewUtils';
import { PDFDownloadLink, Document, Page, StyleSheet, Text, Image, View,Font } from '@react-pdf/renderer';
import mqaheader from '../../../content/images/MQA_Header_Logo.png';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { format } from 'date-fns'
import Arial from '../../../fonts/ARIAL.TTF'
import ArialItalic from '../../../fonts/ARIALI 1.TTF'
import ArialBold from '../../../fonts/ARIALBD.TTF'
import ArialBoldItalic from '../../../fonts/ARIALBI 1.TTF'

const utils = new ViewUtils();

Font.register({
    family: 'Arial',
    fonts: [
      { src: Arial }, 
      { src: ArialItalic, fontStyle: 'italic' },
      { src: ArialBold, fontWeight: 'bold' },
      { src: ArialBoldItalic, fontStyle: 'italic',fontWeight: 'bold' }
    ]
  })

  const BORDER_COLOR = '#000'
  const BORDER_STYLE = 'solid'
  const COL_HEAD1WIDTH = 40
  const COLN_HEADWIDTH = (100 - COL_HEAD1WIDTH)
  const COLN_SPAN = 100
  const ROW_OFFHEIGHT = 200
  const COLN_OFFWIDTH = 50
  const styles = StyleSheet.create({
    body: {
      flexDirection: 'column',
      padding: 25
    },
    table: { 
      display: "table", 
      width: "auto", 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderWidth: 0.5, 
      borderRightWidth: 0, 
      borderBottomWidth: 0 
    },
    tableCell: { 
      margin: 5, 
      fontSize: 9,
      fontFamily: 'Arial' 
    }, 
    tableRow: { 
      margin: "auto", 
      flexDirection: "row" 
    },   
    tableColSpan: { 
      width: COLN_SPAN + "%", 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderWidth: 0.5, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 
    },     
    tableColHeader: { 
      width: COLN_HEADWIDTH + "%", 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderBottomColor: '#000',
      borderWidth: 0.5, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
    }, 
    tableCol1Header: { 
      width: COL_HEAD1WIDTH + '%', 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderBottomColor: '#000',
      borderWidth: 0.5, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
    },
    tableCellHeader: {
      margin: 5, 
      fontSize: 12,
      fontWeight: 500,
      textAlign: 'center',
      fontFamily: 'Arial'
    }, 
    tableCellOffice: {
      margin: 5, 
      fontSize: 12,
      fontWeight: 500,
      fontFamily: 'Arial'
    },
    tableColOffice: { 
      width: COLN_OFFWIDTH + '%',
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderWidth: 0.5, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 
    },
    tableRowOffice: { 
      margin: "auto", 
      flexDirection: "row",
      height: ROW_OFFHEIGHT + 'px',
    },   
    tableColDetailSpan: { 
      width: COLN_SPAN + "%", 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderWidth: 0.5, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
      backgroundColor: '#D9D9D9' 
    },
    tableCellDetailHeader: {
      margin: 5, 
      fontSize: 12,
      fontWeight: 500,
      fontFamily: 'Arial'
    }, 
    tableCol1Detail: { 
      width: COL_HEAD1WIDTH + '%', 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderBottomColor: '#000',
      borderWidth: 0.5, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
      backgroundColor: '#D9D9D9'
    },     
    tableColListHeader: { 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderWidth: 0.5, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#D9D9D9'
    },
    tableCellListHeader: {
      margin: 5, 
      fontSize: 7.5,
      fontWeight: 'bold',
      fontFamily: 'Arial'
    },     
    tableColList: { 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderWidth: 0.5, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
    },     
    tableColListFill: { 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderWidth: 0.5, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
      backgroundColor: '#D9D9D9'
    },
    tableCellList: { 
      margin: 5, 
      fontSize: 7.5,
      fontFamily: 'Arial' 
    },
    tableCellListBold: { 
      margin: 5, 
      fontSize: 7.5,
      fontWeight: 'bold',
      fontFamily: 'Arial' 
    },
    tableCellListSpan: { 
        width: COLN_SPAN + "%", 
        borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderWidth: 0.5, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
      },
    image: {
          height: '90px',
          width: '550px'
      }
    
  }); 

export default function WSPATREvalautionSheet(props) {


    const initiateHeaderValue = (str) => {
        return str
            .replace(/(_|-)/g, ' ')
            .trim()
            .replace(/\w\S*/g, function (str) {
                return str.charAt(0).toUpperCase() + str.substr(1)
            })
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    }


    const WSPATREvaluationSheetData = props.data.WSPATREvaluationChecklistDto.map((innerItem, index) => {
        return {
            AdministratorCheck: innerItem.CheckDescription,
            Detail: innerItem.Detail,
            Comply: "",
            ReviewComments: "",
            Comments: ""
        };
    });

    const WSPATREvaluationSheetContents = WSPATREvaluationSheetData.length !== 0 ?
        (

            <Fragment key={1}>
                <View style={styles.table}>
                    <View key={"0"} style={styles.tableRow} fixed>
                        {Object.keys(WSPATREvaluationSheetData[0]).map((innerItem, index) => (
                            <View style={{ ...styles.tableColListHeader, width: (100 / Object.keys(WSPATREvaluationSheetData[0]).length).toString() + '%' }} key={index}>
                                <Text style={styles.tableCellListHeader}>{initiateHeaderValue(innerItem)}</Text>
                            </View>
                        ))}
                    </View>
                    {WSPATREvaluationSheetData.map((dataItem, index,array) => (
                        dataItem.Detail === "Heading" ?
                        <View key={index} style={styles.tableRow} wrap={false}>
                        <View style={{ ...styles.tableCellListSpan}} key={index}>
                                    <Text style={styles.tableCellListBold}>{dataItem.AdministratorCheck}</Text>
                                </View>
                        </View>
                        :
                        <View key={index} style={styles.tableRow} wrap={false}>
                            {Object.keys(dataItem).map((innerItem, index) => (
                            index < 2 ?    
                                <View style={{ ...styles.tableColListFill, width: (100 / Object.keys(WSPATREvaluationSheetData[0]).length).toString() + '%' }} key={index}>
                                    <Text style={styles.tableCellList}>{dataItem[innerItem]}</Text>
                                </View>
                                :
                                <View style={{ ...styles.tableColList, width: (100 / Object.keys(WSPATREvaluationSheetData[0]).length).toString() + '%' }} key={index}>
                                    <Text style={styles.tableCellList}>{dataItem[innerItem]}</Text>
                                </View>
                            ))}

                        </View>
                    ))}
                </View>
            </Fragment>

        ) :
        null;

        


        const MyDoc = () => (
            <Document>
                <Page orientation="portrait" style={styles.body} size="A4" wrap>
                <Image style={styles.image} src={mqaheader}></Image>
                <Text>{"\n"}</Text>
                <Text>{"\n"}</Text>
                <View style={styles.table}> 
                    <View style={styles.tableRow}> 
                    <View style={styles.tableColSpan}> 
                        <Text style={styles.tableCellHeader}>{props.data.FinancialYear} WORKPLACE SKILLS PLAN AND ANNUAL TRAINING REPORT</Text>
                        <Text style={styles.tableCellHeader}>Submitted to the Mining Qualifications Authority</Text>
                    </View> 
                    </View>
                    <View style={styles.tableRow}> 
                    <View style={styles.tableCol1Header}> 
                        <Text style={styles.tableCell}>Organisation Name</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                        <Text style={styles.tableCell}>{props.data.LegalName}</Text> 
                    </View> 
                    </View> 
                    <View style={styles.tableRow}> 
                    <View style={styles.tableCol1Header}> 
                        <Text style={styles.tableCell}>Trading Name</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                        <Text style={styles.tableCell}>{props.data.TradeName}</Text> 
                    </View> 
                    </View> 
                    <View style={styles.tableRow}> 
                    <View style={styles.tableCol1Header}> 
                        <Text style={styles.tableCell}>Organisation SDL</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                        <Text style={styles.tableCell}>{props.data.SDLNumber}</Text> 
                    </View> 
                    </View> 
                    <View style={styles.tableRow}> 
                    <View style={styles.tableCol1Header}> 
                        <Text style={styles.tableCell}>Date Document</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                        <Text style={styles.tableCell}>{new Date().toLocaleDateString('en-ZA') + ''}</Text> 
                    </View> 
                    </View>            
                </View>
                <Text>{"\n"}</Text>
                <Text>{"\n"}</Text>
                <View style={styles.table}> 
                    <View style={styles.tableRow}> 
                    <View style={styles.tableColSpan}> 
                        <Text style={styles.tableCellOffice}>FOR MQA OFFICE USE</Text>
                    </View> 
                    </View>
                    <View style={styles.tableRowOffice}> 
                    <View style={styles.tableColOffice}> 
                        <Text style={styles.tableCellOffice}>Admin Checked by:</Text> 
                    </View> 
                    <View style={styles.tableColOffice}> 
                        <Text style={styles.tableCellOffice}>Evaluated by:</Text> 
                    </View> 
                    </View> 
                    <View style={styles.tableRowOffice}> 
                    <View style={styles.tableColOffice}> 
                        <Text style={styles.tableCellOffice}>Admin checked by:</Text> 
                    </View> 
                    <View style={styles.tableColOffice}> 
                        <Text style={styles.tableCellOffice}>Approved/Not Approved by:</Text> 
                    </View> 
                    </View>            
                </View>
                <Text break></Text>
                <View style={styles.table}> 
                    <View style={styles.tableRow}> 
                    <View style={styles.tableColDetailSpan}> 
                        <Text style={styles.tableCellDetailHeader}>{props.data.FinancialYear} WSP-ATR EVALUATION APPROVAL SHEET</Text>
                    </View> 
                    </View>
                    <View style={styles.tableRow}> 
                    <View style={styles.tableCol1Detail}> 
                        <Text style={styles.tableCell}>Organisation Name</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                        <Text style={styles.tableCell}>{props.data.LegalName}</Text> 
                    </View> 
                    </View> 
                    <View style={styles.tableRow}> 
                    <View style={styles.tableCol1Detail}> 
                        <Text style={styles.tableCell}>SDL Number</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                        <Text style={styles.tableCell}>{props.data.SDLNumber}</Text> 
                    </View> 
                    </View> 
                    <View style={styles.tableRow}> 
                    <View style={styles.tableCol1Detail}> 
                        <Text style={styles.tableCell}>Sub SDL Number</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                        <Text style={styles.tableCell}>{props.data.ChildCompanies}</Text> 
                    </View> 
                    </View> 
                    <View style={styles.tableRow}> 
                    <View style={styles.tableCol1Detail}> 
                        <Text style={styles.tableCell}>Province/Municipality</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                        <Text style={styles.tableCell}>{props.data.Province}/{props.data.Municipality}</Text> 
                    </View> 
                    </View>  
                    <View style={styles.tableRow}> 
                    <View style={styles.tableCol1Detail}> 
                        <Text style={styles.tableCell}>SDF Name</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                        <Text style={styles.tableCell}>{props.data.PrimarySDF}</Text> 
                    </View> 
                    </View>           
                </View>
                <Text>{"\n"}</Text>
                {WSPATREvaluationSheetContents}
                </Page>
            </Document>
          );

    return (

            <div>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Generate Document for {props.Section}
                    </DialogContentText>
                <PDFDownloadLink document={<MyDoc />} fileName={props.data.LegalName + " - " + props.data.SDLNumber + " - Evaluation Sheet - " + props.data.FinancialYear} >
                {({ blob, url, loading, error }) =>
                    loading ? 'Generating Document...' : 'Download Document!'
                }
                </PDFDownloadLink>
                
                </DialogContent>
            </div>

    );
}
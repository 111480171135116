import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { AddressCodeItem } from '../../widgets/AddressCodeItem';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import { GPSCoordinatesAPI } from '../../widgets/GPSCoordinatesAPI';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();

export const ProviderForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [postalCodeValue, setPostalCodeValue] = React.useState(null);
    const [physicalCodeValue, setPhysicalCodeValue] = React.useState(null);
    const [autoQA, setautoQA] = React.useState(null);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleUsePhysicalAddressForPostal = form => event => {
        if (event.target.checked === true) {
            form.values['PostalAddressLine1'] = form.values["PhysicalAddress1"];
            form.values['PostalAddressLine2'] = form.values["PhysicalAddress2"];
            form.values['PostalAddressLine3'] = form.values["PhysicalAddress3"];
            form.values['PostalCode'] = form.values["PhysicalCode"];
            setPostalCodeValue(form.values["PhysicalCode"]);
        }
        form.updateValue('UsePhysicalAddressForPostalAddress', event.target.checked);
    }

    const updateGPSAddress = (address, form) => {
        console.log(address);
        form.values["GPSCoordinates"] = address.gpscoordinates.coordinate;
        form.values["PhysicalAddress1"] = address.streetnumberpar + ' ' + address.routepar;
        form.values["PhysicalAddress2"] = address.sublocalitylevel1par;
        form.values["PhysicalAddress3"] = address.localitypar;
        form.values["PhysicalCode"] = address.postalcodepar;
        setPhysicalCodeValue(form.values["PhysicalCode"]);
    }

    function isFieldRequired(fieldName) {
        if (!utils.isNull(props.requiredFields)) {
            return !utils.isNull(props.requiredFields[fieldName]);
        }
        return false
    }

    function AccreditationTypeCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Both" || value.Description === "Learnership" || value.Description === "Qualification") {
                return true
            }
            else if (value.Description !== "Both" || value.Description !== "Learnership" || value.Description !== "Qualification") {
                return false
            }
        }
    }

    function AccreditingCouncilCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Other") {
                return true
            }
            else {
                return false
            }
        }
    }

    function AttemptGetSAQAQAID(form, QABodyID) {
        if (!utils.isNull(QABodyID)) {
            
            let url = "api/sims/ProviderDetails/GetSAQAQAID?QualityAssuranceBodyID=" + QABodyID.Id;
            utils.invokeUrl(url, (response) => {
                setautoQA(response)
            },
                null,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    }
                }
            )
        }

        if (autoQA) {
            form.values['SAQACode'] = autoQA;
        }
    }

    return (
        <EntityForm ref={ref} viewId="provider"
            dataURL='api/sims/providerdetails/getfind'
            values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="providerForm">
                    <div className="row" style={{ width: "90%" }}>
                        <div className="col w-100">
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="TradeName"
                                                key={tabValue}
                                                required={true}
                                                label="Trade Name"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false
                                                }}
                                                form={form} 
                                                disabled={props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="LegalName"
                                                required={true}
                                                key={tabValue}
                                                label="Legal Name"
                                                className="w-100"
                                                required={true}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="SDLNumber"
                                                key={tabValue}
                                                label="SDL Number"
                                                required={true}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={props.id}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="General Details" wrapped {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }}/>
                                <Tab label="Address Details" wrapped {...a11yProps(1)} style={{ color: form.fieldParentErrors[1] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel className="w-100" value={tabValue} index={0}>
                            <table cellPadding="2" width="80%" index={0}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                parentId={0}
                                                dataUrl="api/sims/providerdetails/GetLookupList?listId=ProviderType"
                                                id="ProviderTypeID"
                                                required={true}
                                                key={tabValue}
                                                label="Provider Type"
                                                className="w-100"
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                parentId={0}
                                                dataUrl="api/sims/providerdetails/GetLookupList?listId=ProviderClass"
                                                id="ProviderClassID"
                                                required={true}
                                                key={tabValue}
                                                label="Provider Class"
                                                className="w-100"
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                parentId={0}
                                                dataUrl="api/sims/providerdetails/GetLookupList?listId=ProviderAccreditationStatus"
                                                id="ProviderAccreditationStatusID"
                                                required={true}
                                                key={tabValue}
                                                label="Provider Accreditation Status"
                                                className="w-100"
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                parentId={0}
                                                dataUrl="api/sims/providerdetails/GetLookupList?listId=AccreditationType"
                                                id="AccreditationTypeID"
                                                required={true}
                                                key={tabValue}
                                                label="Accreditation Type"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                parentId={0}
                                                id="AccreditationStartDate"
                                                key={tabValue}
                                                label="Accreditation Start Date"
                                                className='{classes.textField} w-100'
                                                required={true}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                parentId={0}
                                                id="AccreditationEndDate"
                                                key={tabValue}
                                                label="Accreditation End Date"
                                                className='{classes.textField} w-100'
                                                required={true}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                            <td>
                                                <CustomTextField
                                                parentId={0}
                                                id="ProviderAccreditationNumber"
                                                key={tabValue}
                                                label="Provider Accreditation Number"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={true}
                                                />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                parentId={0}
                                                id="QCTOProviderNumber"
                                                key={tabValue}
                                                label="QCTO Accreditation Provider Number"
                                                className="w-100"
                                                required={AccreditationTypeCheck(form.values["AccreditationTypeID"])}
                                                disabled={!AccreditationTypeCheck(form.values["AccreditationTypeID"])}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                   
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/providerdetails/GetLookupList?listId=QualityAssuranceBody"
                                                parentId={0}
                                                id="QualityAssuranceBodyID"
                                                required={true}
                                                onChange={AttemptGetSAQAQAID(form, form.values["QualityAssuranceBodyID"])}
                                                key={tabValue}
                                                label="Quality Assurance Body"
                                                className="w-100"
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                parentId={0}
                                                id="SAQACode"
                                                key={tabValue}
                                                label="SAQA Code"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>

                                    <tr>

                                        <td>

                                            <CustomTextField
                                                id="SAQAProviderCode"
                                                parentId={0}
                                                key={tabValue}
                                                label="SAQA Provider Code"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/providerdetails/GetLookupList?listId=ProviderApplication"
                                                parentId={0}
                                                id="ProviderApplicationID"
                                                required={true}
                                                key={tabValue}
                                                label="Provider Application"
                                                className="w-100"
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                parentId={0}
                                                id="WebAddress"
                                                key={tabValue}
                                                label="Web Address"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                validationRegex="^$|^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                parentId={0}
                                                id="RegistrationDate"
                                                key={tabValue}
                                                label="Registration Date"
                                                className='{classes.textField} w-100'
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                parentId={0}
                                                id="DHETRegistrationEndDate"
                                                key={tabValue}
                                                label="DHET Registration End Date"
                                                className='{classes.textField} w-100'
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                parentId={0}
                                                id="RegistrationNumber"
                                                key={tabValue}
                                                label="Registration Number"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                parentId={0}
                                                id="ApplicationReceivedDate"
                                                key={tabValue}
                                                label="Application Received Date"
                                                className='{classes.textField} w-100'
                                                required={true}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/providerdetails/GetLookupList?listId=ProviderInternalExternal"
                                                parentId={0}
                                                id="ProviderInternalExternalID"
                                                required={true}
                                                key={tabValue}
                                                label="Provider Int/Ext"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ProviderAlertEmail"
                                                key={tabValue}
                                                label="Provider Alert Email"
                                                validationRegex="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                                className="w-100"
                                                required
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/providerdetails/GetLookupList?listId=ProviderAccreditingCouncil"
                                                parentId={0}
                                                id="AccreditingCouncil"
                                                required={true}
                                                key={tabValue}
                                                label="Accrediting Council"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="AccreditingCouncilOther"
                                                key={tabValue}
                                                label="Accrediting Council Other"
                                                className="w-100"
                                                form={form}
                                                required={AccreditingCouncilCheck(form.values["AccreditingCouncil"])}
                                                disabled={!AccreditingCouncilCheck(form.values["AccreditingCouncil"])}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                parentId={0}
                                                id="AccreditationReviewDate"
                                                key={tabValue}
                                                label="Accreditation Review Date"
                                                className='{classes.textField} w-100'
                                                required={true}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/providerdetails/GetLookupList?listId=YesNo"
                                                parentId={0}
                                                id="Levy"
                                                required={true}
                                                key={tabValue}
                                                label="Liable for skills development levy?"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/providerdetails/GetLookupList?listId=SICCode"
                                                parentId={0}
                                                id="SICCodeID"
                                                required={true}
                                                key={tabValue}
                                                label="SIC Code"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel className="w-100" value={tabValue} index={1}>

                            <table cellPadding="2" width="80%" index={0}>

                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomCheckbox
                                                id="UsePhysicalAddressForPostalAddress"
                                                key={tabValue}
                                                form={form} 
                                                disabled={!props.editPermission}
                                                onChange={handleUsePhysicalAddressForPostal}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Use Physical Address for Postal Address?"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <GPSCoordinatesAPI
                                                id="Address"
                                                key={tabValue}
                                                className="w-100"
                                                label="Address"
                                                form={form} 
                                                disabled={!props.editPermission}
                                                updateGPSAddress={updateGPSAddress}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="GPSCoordinates"
                                                key={tabValue}
                                                label="GPS Coordinates"
                                                className="w-100"
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                parentId={1}
                                                id="PhysicalAddress1"
                                                key={tabValue}
                                                label="Physical Address 1"
                                                className="w-100"
                                                required={true}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="PostalAddressLine1"
                                                key={tabValue}
                                                required={true}
                                                label="Postal Address 1"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>       
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                parentId={1}
                                                id="PhysicalAddress2"
                                                required={true}
                                                key={tabValue}
                                                label="Physical Address 2"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="PostalAddressLine2"
                                                key={tabValue}
                                                required={true}
                                                label="Postal Address Line 2"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <CustomTextField
                                                parentId={1}
                                                id="PhysicalAddress3"
                                                key={tabValue}
                                                label="Physical Address 3"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="PostalAddressLine3"
                                                key={tabValue}
                                                label="Postal Address Line 3"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <AddressCodeItem
                                                id="PhysicalCode"
                                                required={true}
                                                addressId="Physical"
                                                value={physicalCodeValue}
                                                key={tabValue}
                                                validationRegex={/^[0-9]{4,5}$/g}
                                                validationMessage="The code must be 4 or 5 digits long"
                                                provinceId={!utils.isNull(form.values) ? form.values.PhysicalProvinceID : null}
                                                municipalityId={!utils.isNull(form.values) ? form.values.PhysicalMunicipalityID : null}
                                                districtId={!utils.isNull(form.values) ? form.values.PhysicalDistrictID : null}
                                                urbanRuralId={!utils.isNull(form.values) ? form.values.PhysicalUrbanRuralID : null}
                                                suburbId={!utils.isNull(form.values) ? form.values.PhysicalSuburbID : null}
                                                cityId={!utils.isNull(form.values) ? form.values.PhysicalCityID : null}
                                                className="w-100"
                                                label="Physical Code"
                                                hasMunicipalityID={true}
                                                hasProvinceID={true}
                                                hasCityID={true}
                                                hasDistrictID={false}
                                                hasUrbanRuralID={true}
                                                hasSuburbID={true}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>   

                                        <td>
                                            <AddressCodeItem
                                                id="PostalCode"
                                                required={true}
                                                addressId="Postal"
                                                value={postalCodeValue}
                                                key={tabValue}
                                                validationRegex={/^[0-9]{4,5}$/g}
                                                validationMessage="The code must be 4 or 5 digits long"
                                                provinceId={!utils.isNull(form.values) ? form.values.PostalProvinceID : null}
                                                municipalityId={!utils.isNull(form.values) ? form.values.PostalMunicipalityID : null}
                                                districtId={!utils.isNull(form.values) ? form.values.PostalDistrictID : null}
                                                urbanRuralId={!utils.isNull(form.values) ? form.values.PostalUrbanRuralID : null}
                                                suburbId={!utils.isNull(form.values) ? form.values.PostalSuburbID : null}
                                                cityId={!utils.isNull(form.values) ? form.values.PostalCityID : null}
                                                className="w-100"
                                                label="Postal Code"
                                                hasMunicipalityID={true}
                                                hasProvinceID={true}
                                                hasCityID={true}
                                                hasDistrictID={false}
                                                hasUrbanRuralID={true}
                                                hasSuburbID={true}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form >
            }
        </EntityForm >
    );
})
import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";
import Prompt from '../../widgets/Prompt';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import FormDialog from "../FormDialog";
import SDProviderQCTOSkillsProgramSearchModule from "../program/QCTOSkillsProgramme/SDProviderQCTOSkillsProgramSearchModule";
import LinkDialog from "../LinkDialog";
import SDProviderInterventionAudit from '../sdprovider/SDProviderInterventionAudit';
import { SDProviderTrainingEndDateForm } from "../sdprovider/SDProviderTrainingEndDateForm";
import Dialog from '@material-ui/core/Dialog';
import { Typography } from "antd";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { SkillsProgrammeOutcomeForm } from "../program/SkillsProgrammeOutcomeForm";
import withMenuPermission from "../../widgets/withMenuPermission";


const headCells = [
    { id: 'SkillsProgrammeCode', numeric: false, disablePadding: false, label: 'Skills Programme Code' },
    { id: 'SkillsProgrammeTitle', numeric: false, disablePadding: false, label: "Skills Programme Title" },
    { id: 'RegistrationStartDate', numeric: false, disablePadding: false, label: 'Registration Start Date' },
    { id: 'RegistrationEndDate', numeric: false, disablePadding: false, label: 'Registration End Date' },
    { id: 'ProgramOutcome', numeric: false, disablePadding: false, label: 'Program Outcome' },
    { id: 'ProviderTrainingEndDate', numeric: false, disablePadding: true, label: 'Training End Date' }
];
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const USheadCells = [
    { id: 'ModuleCode', numeric: false, disablePadding: true, label: 'Module Code' },
    { id: 'ModuleTitle', numeric: false, disablePadding: true, label: 'Module Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' },
    { id: 'ModuleType', numeric: false, disablePadding: true, label: 'Module Type' },
    { id: 'LearningType', numeric: false, disablePadding: true, label: 'Learning Type' },
    { id: 'NQFLevel', numeric: false, disablePadding: true, label: 'NQF Level' }
];


const useStyles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        color: 'white',
    },
    table: {
        minWidth: 700,
    },
});

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();
const updateFormRef = React.createRef();

class WorkplaceApprovalQCTOSkillsProgrammeSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showTable: true,
            selectedSkillsProgramme: null,
            selectedSkillsProgrammeTitle: null,
            linkDialogOpen: false,
            auditDialogOpen: false,
            updateDialogOpen: false,
            updateDateformDialogOpen: false,
            formDialogOpen: false,
            searchParameters: [{ Name: "WorkplaceApprovalID", Value: this.props.location.state.selection }],
            propmtOpen: false,
            promptTitle: "Remove QCTO Skills Programme from Workplace Approval",
            promptContent: "Are you sure you want to remove this QCTO Skills Programme from the Workplace Approval?",
            removeMessage: "",
            removeMessageStyle: "",
            searchParameters2: [{ Name: "Id", Value: 0 }],
            OpenDialog: false,

        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: "link", label: '', icon: 'LinkIcon', tooltip: "Link existing QCTO Skills Programme to Workplace Approval", visible: this.props.menuPermissionItem.Add },
                        {
                            id: 'update', label: '', icon: 'UpdateIcon', tooltip: 'Update Outcome',
                            disabled: utils.isNull(this.state.selectedSkillsProgramme), visible: this.props.menuPermissionItem.Edit
                        },
                        {
                            id: 'audit', label: '', icon: 'AuditIcon', tooltip: 'View Record Audit Trail',
                            disabled: utils.isNull(this.state.selectedSkillsProgramme)
                        },
                        {
                            id: 'remove', label: '', icon: 'DeleteIcon', tooltip: 'Remove QCTO Skills Programme from Workplace Approval',
                            disabled: utils.isNull(this.state.selectedSkillsProgramme), visible: this.props.menuPermissionItem.Delete
                        },
                        {
                            id: 'updateDate', label: 'Update Workplace Approval Training End Date', tooltip: 'Update Workplace Approval Training End Date',
                            disabled: utils.isNull(this.state.selectedSkillsProgramme), visible: this.props.menuPermissionItem.Edit
                        },
                        { id: 'view', label: '', tooltip: 'View Modules', icon: 'ViewIcon', disabled: utils.isNull(this.state.selectedSkillsProgramme) },
                    ]
                }
            ]
        };

    }
    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/WorkplaceApproval',
                state: { selection: this.props.location.state.selection }
            });
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
        else if ("update" === buttonId) {
            this.setState({ removeMessage: "" }, this.viewRecords());
        }
        else if ("audit" === buttonId) {
            this.setState({ auditDialogOpen: true, message: "" })
        }
        else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, removeMessage: "" })

        }
        else if ("updateDate" === buttonId) {
            this.setState({ updateDateDialogOpen: true, message: "" })

        }
        else if ("unitStandards" === buttonId) {
            this.props.history.push({
                pathname: '/providerSkillsProgrammeUSSearch',
                state: {
                    WorkplaceApprovalID: this.props.location.state.selection,
                    WorkplaceApprovalSkillsProgrammeID: this.state.selectedSkillsProgramme,
                    SkillsTitle: this.state.selectedSkillsProgrammeTitle,
                    WorkplaceApprovalName: this.props.location.state.WorkplaceApprovalName,
                    WorkplaceApprovalSDLNumber: this.props.location.state.WorkplaceApprovalSDLNumber
                }
            });
        }
        else if ("view" === buttonId) {
            this.setState({ OpenDialog: true, message: "" })
        }
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                formDialogOpen: false, updateDateDialogOpen: false, auditDialogOpen: false, selectedSiteVisit: null, linkDialogOpen: false
            }, () => { this.setState({ showTable: true }) });
        }
        else if (isCancel === true) {
            this.setState({
                formDialogOpen: false,
                linkDialogOpen: false,
                updateDateDialogOpen: false,
                auditDialogOpen: false
            })
        }
    }

    dialogUpdateActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                formDialogOpen: false, updateDateDialogOpen: false, auditDialogOpen: false, selectedSiteVisit: null, linkDialogOpen: false
            }, () => { this.setState({ showTable: true }) });
        }
        else if (isCancel === true) {
            this.setState({
                formDialogOpen: false,
                linkDialogOpen: false,
                updateDateDialogOpen: false,
                auditDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            let newsearchParameters2 = [...this.state.searchParameters2];
            let temp_element = { ...newsearchParameters2[0] };
            temp_element.Value = selection[0].Id
            newsearchParameters2[0] = temp_element;

            this.setState({ searchParameters2: JSON.parse(JSON.stringify(newsearchParameters2)), selectedSkillsProgramme: selection[0].Id, removeMessage: "", selectedSkillsProgrammeTitle: selection[0].SkillsProgrammeTitle });
        }
        else {

            this.setState({ searchParameters2: [{ Name: "Id", Value: 0 }], selectedSkillsProgramme: null, removeMessage: "", selectedSkillsProgrammeTitle: null });
        }
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("update", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("updateDate", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("audit", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("unitStandards", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("view", selection === null || selection.length === 0);
    }

    successfulRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedSkillsProgramme: null,
                removeMessage: response.Message,
                removeMessageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                removeMessage: response.Message,
                removeMessageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    removeSkillsProgramme(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
            toolbarRef.current.setButtonDisabled("update", true);
            toolbarRef.current.setButtonDisabled("audit", true);
            toolbarRef.current.setButtonDisabled("unitStandards", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedSkillsProgramme}&WorkplaceApprovalId=${this.props.location.state.selection}`;
        utils.invokeUrlDelete(fullUrl, this.successfulRemoveCallback, this.unsuccessfulRemoveCallback);
    }

    viewRecords = () => {
        this.setState({ formDialogOpen: true })
    }


    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    CloseForm = () => {
        this.setState({
            OpenDialog: false,
            selectedId: null,
            clearSelectedRow: true,
            selectedSkillsProgramme: null
        });
        toolbarRef.current.setButtonDisabled("audit", true);
        toolbarRef.current.setButtonDisabled("update", true);
        toolbarRef.current.setButtonDisabled("unlink", true);
        toolbarRef.current.setButtonDisabled("updateDate", true);
        toolbarRef.current.setButtonDisabled("view", true);
    };

    render() {
        const { classes } = this.props;
        return (
            <ViewContainer mode={this.props.mode} title="">

                <p className="breadcrumbs">
                    {"Workplace Approval > QCTO Skills Programmes " + (this.props.location.state.WorkplaceApprovalName !== null ? "> " + this.props.location.state.WorkplaceApprovalName + (this.props.location.state.WorkplaceApprovalSDLNumber !== null ? " - " + this.props.location.state.WorkplaceApprovalSDLNumber : "") : "> Add")}
                </p>

                <AlertItem message={this.state.removeMessage} alertStyle={this.state.removeMessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    {this.state.showTable === true && (

                        <EnhancedTable
                            headCells={headCells}
                            dataUrl={`api/sims/WorkplaceApprovalskillsprogramme/GetWorkplaceApprovalQCTOSkillsProgrammes`}
                            height="60vh"
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}
                </div>

                <FormDialog
                    open={this.state.formDialogOpen}
                    dialogTitle={"Update Outcome"}
                    viewRef={this}
                    formRef={addFormRef}
                    entityId={this.state.selectedSkillsProgramme}
                    saveUrl={"api/sims/WorkplaceApprovalskillsprogramme/UpdateSkillsProgrammeOutcome"} usePut={true}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                >
                    <SkillsProgrammeOutcomeForm
                        ref={addFormRef}
                        id={this.state.selectedSkillsProgramme}
                        dataURL='api/sims/WorkplaceApprovalskillsprogramme/GetFindSkillsProgrammeOutcome'
                    />
                </FormDialog>

                <FormDialog
                    open={this.state.updateDateDialogOpen}
                    dialogTitle={"Update Workplace Approval Training End Date"}
                    viewRef={this}
                    entityId={this.state.selectedSkillsProgramme}
                    formRef={updateFormRef}
                    usePut={true}
                    saveUrl={"api/sims/WorkplaceApprovalskillsprogramme/UpdateSkillsProgrammeTrainingEndDate"}
                    closeHandler={(isCancelled, linked) => this.dialogUpdateActionHandler(isCancelled, linked)}
                >
                    <SDProviderTrainingEndDateForm ref={updateFormRef} id={this.state.selectedSkillsProgramme} dataURL='api/sims/WorkplaceApprovalskillsprogramme/GetFindSkillsProgrammeTrainingEndDate' controllerName='WorkplaceApprovalskillsprogramme' />
                </FormDialog>



                <LinkDialog open={this.state.auditDialogOpen}
                    title={"Audit Trail"}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    dialogTitle={"Audit Trail"}
                    selectionId={this.props.location.state.selectedSkillsProgramme}
                >

                    <SDProviderInterventionAudit dataUrl={"api/sims/WorkplaceApprovalskillsprogramme/GetWorkplaceApprovalQCTOSkillsProgrammeAudit"}
                        multiSelect={false} showTitle={false}
                        Id={this.state.selectedSkillsProgramme}
                    />
                </LinkDialog>

                <LinkDialog
                    open={this.state.linkDialogOpen}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    dialogTitle={`Link QCTO Skills Programme to Workplace Approval > ${this.props.location.state.WorkplaceApprovalName} - ${this.props.location.state.WorkplaceApprovalSDLNumber}`}
                    linkName={"skillsprogrammes"} postUrl={"api/sims/WorkplaceApprovalskillsprogramme/LinkWorkplaceApprovalQCTOSkillsProgramme"} usePost={true}
                    selectionId={this.props.location.state.selection}
                >
                    <SDProviderQCTOSkillsProgramSearchModule
                        programmeId={this.props.location.state.selection}
                        dataUrl="api/sims/WorkplaceApprovalskillsprogramme/GetSearchLinkableQCTOSkillsProgrammes"
                        mode="lookup"
                        multiSelect={true} showTitle={false}
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeSkillsProgramme("api/sims/WorkplaceApprovalskillsprogramme/RemoveWorkplaceApprovalQCTOSkillsProgramme") }}
                    closePrompt={this.closePrompt}
                />

                <Dialog fullScreen open={this.state.OpenDialog} onClose={this.CloseForm} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.CloseForm} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h1" className={classes.title}>
                                {"QCTO Skills Programme Modules"}
                            </Typography>

                        </Toolbar >
                    </AppBar >
                    <br /><br />
                    <EnhancedTable
                        headCells={USheadCells}
                        dataUrl={"api/sims/WorkplaceApprovalskillsprogramme/GetWorkplaceApprovalSPModule"}
                        makeRowBold={true}
                        searchParameters={this.state.searchParameters2} paged={false} autoFetchData={true} />
                </Dialog >
            </ViewContainer>
        );
    }
}

export default withRouter(withStyles(useStyles())(connect()(withMenuPermission(WorkplaceApprovalQCTOSkillsProgrammeSearch))));

import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter } from "react-router-dom";
import ViewUtils from "../ViewUtils";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import BuildIcon from '@material-ui/icons/Build';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AssessmentIcon from '@material-ui/icons/Assessment';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import { Icon } from "@material-ui/core";
import { connect } from 'react-redux';
import PaymentIcon from '@material-ui/icons/Payment';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import TransformIcon from '@material-ui/icons/Transform';
import CategoryIcon from '@material-ui/icons/Category';
import BusinessIcon from '@material-ui/icons/Business';
import MoneyIcon from '@material-ui/icons/Money';
import ReceiptIcon from '@material-ui/icons/Receipt';

const useStyles = makeStyles(theme => ({

    textItemIconStyle: {
        fontWeight: 'bold',
        fontSize: "15px",
        paddingLeft: '5px',
        margin: '0',
        color: theme.palette.action.main
    },
    menuListItem: {
        color: theme.palette.action.main,
        fill: theme.palette.action.main
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    nestedMore: {
        paddingLeft: theme.spacing(8),
    },

}));

const utils = new ViewUtils();
/*const views = [
    {
        id : 1,
        name: "Dashboard",
        url: "/dashboard",
        icon: <DashboardIcon />,
        iconText: null,
        selected: false,
    },
    {
        id: 2,
        name: "ETQA",
        selected: false,
        isOpen: false,
        icon: <AccountTreeOutlinedIcon />,
        iconText: null,
        modules: [
            { id: 3, name: "Assessor", url: "/assessorSearch", selected: false, icon: null, iconText: 'A' },
            { id: 4, name: "Moderator", url: "/moderatorSearch", selected: false, icon: null, iconText: 'M' },
            { id: 5, name: "Learner", url: "/learners", selected: false, icon: null, iconText: 'LNR' },
            { id: 6, name: "Provider", url: "/providerSearch", selected: false, icon: null, iconText: 'PR' },
            { id: 7, name: "Employer", url: "/employers", selected: false, icon: null, iconText: 'E' },
            { id: 8, name: "Person", url: "/persons", selected: false, icon: null, iconText: 'P' },
            { id: 9, name: "Expiry Notification", url: "/expiryNotification", selected: false, icon: null, iconText: 'EX' },
            {
                id: 10,
                name: "Programme Setup",
                selected: false,
                isOpen: false,
                icon: null,
                iconText: 'PS',
                modules: [
                    { id: 11, name: "Qualification", url: "/qualificationsetupSearch", selected: false, icon: null, iconText: 'Q' },
                    { id: 12, name: "Unit Standard", url: "/unitstandardsetupSearch", selected: false, icon: null, iconText: 'US' },
                    { id: 13, name: "Skills Programme", url: "/skillsProgrammeSetupSearch", selected: false, icon: null, iconText: 'SP' },
                    { id: 14, name: "Learnership", url: "/learnershipsetupSearch", selected: false, icon: null, iconText: 'LS' },
                    { id: 15, name: "Trade", url: "/tradeSetupSearch", selected: false, icon: null, iconText: 'T' },
                    { id: 16, name: "AET", url: "/AETSetupSearch", selected: false, icon: null, iconText: 'A' }
                ]
            },
            {
                id: 17,
                name: "QA Approval And Certification",
                selected: false,
                isOpen: false,
                icon: null,
                iconText: 'QA',
                modules: [
                    { id: 18, name: "Bulk QA Approval", url: "/bulkApproval", selected: false, icon: null, iconText: 'QA' },
                    { id: 19, name: "Provider", url: "/providerDocuments", selected: false, icon: null, iconText: 'PR'  },
                    { id: 20, name: "Assessor", url: "/assessorDocuments", selected: false, icon: null, iconText: 'A' },
                    { id: 21, name: "Moderator", url: "/moderatorDocuments", selected: false, icon: null, iconText: 'M' },
                    { id: 22, name: "Learner", url: "/learnerDocuments", selected: false, icon: null, iconText: 'L' }
                ]
            },
            {
                id: 24,
                name: "Bulk Upload",
                selected: false,
                isOpen: false,
                icon: null,
                iconText: 'BU',
                modules: [
                    { id: 25, name: "Person Uploading", url: "/uploadPerson", selected: false, icon: null, iconText: 'PU' },
                    { id: 26, name: "Learnership Programme Registration Uploading", url: "/uploadLearnership", selected: false, icon: null, iconText: 'LP' },
                    { id: 27, name: "Skills Programme Registration Uploading", url: "/uploadSkills", selected: false, icon: null, iconText: 'SP' },
                    { id: 28, name: "Bursary Registration Uploading", url: "/uploadBursaries", selected: false, icon: null, iconText: 'BUR' },
                    { id: 29, name: "Internship Registration Uploading", url: "/uploadInternship", selected: false, icon: null, iconText: 'INT' },
                    { id: 30, name: "AET Registration Uploading", url: "/uploadAET", selected: false, icon: null, iconText: 'AET' },
                    { id: 31, name: "Candidacy Registration Uploading", url: "/uploadCandidacy", selected: false, icon: null, iconText: 'CAN' },
                    { id: 32, name: "FET Registration Uploading", url: "/uploadFET", selected: false, icon: null, iconText: 'FET' },
                    { id: 33, name: "Qualification Registration Uploading", url: "/uploadQualification", selected: false, icon: null, iconText: 'Q' },
                    { id: 34, name: "Unit Standard Registration Uploading", url: "/uploadUnitStandard", selected: false, icon: null, iconText: 'US' },
                    { id: 34, name: "Achievement Uploading", url: "/uploadAchievement", selected: false, icon: null, iconText: 'A' }
                ]
            }
        ]
    },
    {
        id: 24,
        name: "Skills",
        selected: false,
        isOpen: false,
        icon: <SettingsApplicationsIcon />,
        modules: []
    },
    {
        id: 25,
        name: "DG",
        selected: false,
        isOpen: false,
        icon: <AccountBalanceIcon />,
        modules: []
    },
    {
        id: 26,
        name: "Maintenance",
        selected: false,
        isOpen: false,
        icon: <BuildIcon />,
        modules: []
    },
    {
        id: 27,
        name: "Reports",
        url: "/CustomReports",
        selected: false,
        isOpen: false,
        icon: <AssessmentIcon />,
        modules: []
    },
];*/

const menu = (props) => {
    const [menuState, setMenuState] = React.useState(JSON.parse(JSON.stringify(props.menuList)));
    const [open, setOpen] = React.useState(props.open);
    const classes = useStyles();

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const toggleMenuItem = (arr, name) => {
        if (!utils.isNull(arr)) {
            const newMenuState = arr.map((item) => {
                if (item.Name === name) {
                    const updatedItem = {
                        ...item, isOpen: !item.isOpen
                    };
                    return updatedItem
                }
                else if (!utils.isNull(item.Modules)) {
                    const subMenu = item.Modules.map(subItem => {
                        if (subItem.Name === name) {
                            const updatedSub = {
                                ...subItem, isOpen: !subItem.isOpen
                            };
                            return updatedSub;
                        }
                        return subItem;
                    });

                    item.Modules = [...subMenu];
                }
                return item;
            });
            setMenuState(newMenuState);
        }
    }

    const getIcon = (iconText) => {
        if (iconText === 'DashboardIcon') {
            return <DashboardIcon />;
        }
        else if (iconText === 'AccountTreeOutlinedIcon') {
            return <AccountTreeOutlinedIcon />;
        }
        else if (iconText === 'AssessmentIcon') {
            return <AssessmentIcon />;
        }
        else if (iconText === 'SettingsApplicationsIcon') {
            return <SettingsApplicationsIcon />;
        }
        else if (iconText === 'BuildIcon') {
            return <BuildIcon />;
        }
        else if (iconText === 'AccountBalanceIcon') {
            return <AccountBalanceIcon />;
        }
        else if (iconText === 'HelpIcon') {
            return <HelpIcon />;
        }
        else if (iconText === 'PaymentIcon') {
            return <PaymentIcon />;
        }
        else if (iconText === 'ImportExportIcon') {
            return <ImportExportIcon />;
        }
        else if (iconText === 'TransformIcon') {
            return <TransformIcon />;
        }
        else if (iconText === 'CategoryIcon') {
            return <CategoryIcon />;
        }
        else if (iconText === 'BusinessIcon') {
            return <BusinessIcon />;
        }
        else if (iconText === 'MoneyIcon') {
            return <MoneyIcon />;
        }
        else if (iconText === 'ReceiptIcon') {
            return <ReceiptIcon />;
        }
        else {
            return <span className={classes.textItemIconStyle}>{iconText}</span>;
        }
    }

    const renderMenu = (menuItems, level) => {

        return (
            <List component="nav" className={classes.menuListItem}>
                {
                    menuItems.map((item) => {
                        if (!utils.isNull(item.Url) && !utils.isStringEmpty(item.Url)) {
                            if (open === true) {
                                
                                return (
                                    <ListItem button style={{ backgroundColor: props.location.pathname === item.Url ? '#00ACC1' : null }} component={Link} to={item.Url} className={level == 1 ? classes.nested : level == 2 ? classes.nestedMore : null}
                                    onClick={props.closeMenu}
                                    >
                                        <ListItemIcon className={classes.menuListItem}>
                                            {/*item.icon*/}
                                            {getIcon(item.IconText)}
                                        </ListItemIcon>
                                        <ListItemText disableTypography primary={item.Name} className={classes.menuListItem} />
                                    </ListItem>
                                )
                            }
                            else {
                                return (
                                    <Tooltip style={{ backgroundColor: props.location.pathname === item.Url ? '#00ACC1' : null }} title={item.Name} className={level == 1 ? classes.nested : level == 2 ? classes.nestedMore : null}>
                                        <ListItem button component={Link} to={item.Url}>
                                            <ListItemIcon className={classes.menuListItem}>
                                                {/*item.icon*/}
                                                {getIcon(item.IconText)}
                                            </ListItemIcon>
                                            <ListItemText disableTypography primary={item.Name} className={classes.menuListItem} />
                                        </ListItem>
                                    </Tooltip>
                                )
                            }
                        }
                        else {
                            if (open === true) {
                                return (
                                    <div>
                                        <ListItem button onClick={() => toggleMenuItem(menuState, item.Name)} className={level == 1 ? classes.nested : level == 2 ? classes.nestedMore : null}>
                                            <ListItemIcon className={classes.menuListItem}>
                                                {/*item.icon*/}
                                                {getIcon(item.IconText)}
                                            </ListItemIcon>
                                            <ListItemText disableTypography primary={item.Name} className={classes.menuListItem} />
                                            {item.isOpen ?
                                                <ExpandMore fontSize="inherit" />
                                                :
                                                <ExpandLess fontSize="inherit" />
                                            }
                                        </ListItem>
                                        <Collapse in={item.isOpen} timeout="auto" unmountOnExit className={classes.menuListItem}>
                                            {renderMenu(item.Modules, level + 1)}
                                        </Collapse>
                                    </div>
                                );
                            }
                            else {
                                return (
                                    <div>
                                        <Tooltip title={item.Name} className={level == 1 ? classes.nested : level == 2 ? classes.nestedMore : null}>
                                            <ListItem button onClick={() => toggleMenuItem(menuState, item.Name)}>
                                                <ListItemIcon className={classes.menuListItem}>
                                                    {/*item.icon*/}
                                                    {getIcon(item.IconText)}
                                                </ListItemIcon>
                                                <ListItemText disableTypography primary={item.Name} className={classes.menuListItem} />
                                                {item.isOpen ?
                                                    <ExpandMore fontSize="inherit" />
                                                    :
                                                    <ExpandLess fontSize="inherit" />
                                                }
                                            </ListItem>
                                        </Tooltip>
                                        <Collapse in={item.isOpen} timeout="auto" unmountOnExit className={classes.menuListItem}>
                                            {renderMenu(item.Modules, level + 1)}
                                        </Collapse>
                                    </div>
                                );
                            }
                        }
                    })
                }
            </List>
        )
    }

    return (renderMenu(menuState, 0)) /*(<div>{renderMenu(menuState)}</div>)*/
}

const mapStateToProps = state => {
    return {
        menuList: state.auth.menuPermissionsDetails.MenuList
    };
}


export default withRouter(connect(mapStateToProps, null)(menu));